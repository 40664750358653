const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
const GET_ALL_USERS_FAILURE = "GET_ALL_USERS_FAILURE";
const LOAD_USERS = "LOAD_USERS";
const UPDATE_USER_STATUS_SUCCESS = "UPDATE_USER_STATUS_SUCCESS";
const UPDATE_USER_STATUS_FAILURE = "UPDATE_USER_STATUS_FAILURE";
const LOAD_USER = "LOAD_USER";
const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";
const RETRIEVE_ONE_USER_FAILURE = "RETRIEVE_ONE_USER_FAILURE";
const RETRIEVE_ONE_USER_SUCCESS = "RETRIEVE_ONE_USER_SUCCESS";
const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
const CLEAR_USERS = "CLEAR_USERS";
const CLEAR_ONE_USER = "CLEAR_ONE_USER";

let userManagementTypes = {
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAILURE,
  LOAD_USERS,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_STATUS_FAILURE,
  LOAD_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  RETRIEVE_ONE_USER_SUCCESS,
  RETRIEVE_ONE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  CLEAR_USERS,
  CLEAR_ONE_USER
};
export default userManagementTypes;
