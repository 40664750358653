
const CREATE_RESULT_SUCCESS = "CREATE_RESULT_SUCCESS";
const CREATE_RESULT_FAILURE = "CREATE_RESULT_FAILURE";


const RETRIEVE_LAST_RESULT_SUCCESS = "RETRIEVE_LAST_RESULT_SUCCESS";
const RETRIEVE_LAST_RESULT_FAILURE = "RETRIEVE_LAST_RESULT_FAILURE";
const RETRIEVE_LAST_RESULT_LOADER = "RETRIEVE_LAST_RESULT_LOADER";

const UPDATE_RESULT_SUCCESS = "UPDATE_RESULT_SUCCESS";
const UPDATE_RESULT_FAILURE = "UPDATE_RESULT_FAILURE";


const GET_ONE_RESULT_SUCCESS = "GET_ONE_RESULT_SUCCESS";
const GET_ONE_RESULT_FAILURE = "GET_ONE_RESULT_FAILURE";
const GET_ONE_RESULT_LOADER = "GET_ONE_RESULT_LOADER";

let resultTypes = {
  CREATE_RESULT_SUCCESS,
  CREATE_RESULT_FAILURE,
  RETRIEVE_LAST_RESULT_SUCCESS,
  RETRIEVE_LAST_RESULT_FAILURE,
  RETRIEVE_LAST_RESULT_LOADER,
  UPDATE_RESULT_SUCCESS,
  UPDATE_RESULT_FAILURE,
  GET_ONE_RESULT_SUCCESS,
  GET_ONE_RESULT_FAILURE,
  GET_ONE_RESULT_LOADER
};
export default resultTypes;