const GET_ALL_CATEGORY_SUCCESS = "GET_ALL_CATEGORY_SUCCESS";
const GET_ALL_CATEGORY_FAILURE = "GET_ALL_CATEGORY_FAILURE";
const LOAD_CATEGORY = "LOAD_CATEGORY";
const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
const CREATE_CATEGORY_FAILURE = "CREATE_CATEGORY_FAILURE";
const RETRIEVE_ONE_CATEGORY_FAILURE = "RETRIEVE_ONE_CATEGORY_FAILURE";
const RETRIEVE_ONE_CATEGORY_SUCCESS = "RETRIEVE_ONE_CATEGORY_SUCCESS";
const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
const UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE";
const CLEAR_CATEGORYS = "CLEAR_CATEGORYS";

let categoryTypes = {
  LOAD_CATEGORY,
  GET_ALL_CATEGORY_SUCCESS,
  GET_ALL_CATEGORY_FAILURE,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAILURE,
  RETRIEVE_ONE_CATEGORY_SUCCESS,
  RETRIEVE_ONE_CATEGORY_FAILURE,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILURE,
  CLEAR_CATEGORYS,
};
export default categoryTypes;
