import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  ModalHeader,
  Modal,
  ModalBody,
  Row,
  Col,
  Badge,
  Label,
} from "reactstrap";

// import generatedTask from "../dumy-tasks";
import "./TaskRequest.css";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  retrieveFiltredTasksRequest,
  ApproveTask,
  RejectTask,
  retrieveFiltredTasks,
} from "../../../actions/taskAction";
import { useForm } from "react-hook-form";

export default function TaskRequest() {
  const [modalRequestTask, setmodalRequestTask] = useState(false);
  const [toggleDetails, settoggleDetails] = useState(false);
  const { register, watch, setValue, reset } = useForm();

  const toggleModalRequestTask = () => {
    setmodalRequestTask(!modalRequestTask);
  };
  const toggleDetailsSection = (task) => {
    reset();
    setselectedTask(task);
    setValue("description", task.description);
    if (task) {
      settoggleDetails(true);
    } else {
      settoggleDetails(false);
    }
  };

  const [selectedTask, setselectedTask] = useState();
  const dispatch = useDispatch();
  const currentTimeZone = useSelector((state) => state.user.timeZone);
  //console.log("currentTimeZone===>",currentTimeZone);

  const tasks = useSelector((state) => state.taskReducer.tasksRequest);
  //console.log("tasks===>",tasks);

  //   const isloading = useSelector((state) => state.taskReducer.isLoading);
  //   const error = useSelector((state) => state.taskReducer.listError);
  // const ErrorAction = useSelector((state) => state.taskReducer.error);

  // const [state /*setState*/] = useState(["To be validated"]);

  //console.log("state====>",state)

  const [type /*setType*/] = useState(["Other Monitoring", "Other"]);
  const [searchValue /*setSearchValue*/] = useState("");
  const [refreshData, setrefreshData] = useState(false);

  useEffect(() => {
    modalRequestTask &&
      dispatch(
        retrieveFiltredTasksRequest(
          JSON.stringify(type),
          JSON.stringify(["To be validated"]),
          currentTimeZone,
          searchValue
        )
      );
  }, [
    dispatch,
    type,
    currentTimeZone,
    searchValue,
    modalRequestTask,
    refreshData,
  ]);

  const Approve = async () => {
    let updatedTask = {};
    if (selectedTask) {
      updatedTask = {
        state: "To be validated",
        description: watch("description")
      };
      await dispatch(ApproveTask(selectedTask._id, updatedTask));
      await dispatch(
        retrieveFiltredTasksRequest(
          JSON.stringify(type),
          JSON.stringify(["To be validated"]),
          currentTimeZone,
          searchValue
        )
      );
      setrefreshData(!refreshData);
      setmodalRequestTask(!modalRequestTask);
      settoggleDetails(!settoggleDetails);
    }
  };

  const Reject = () => {
    let rejectTask = {};
    if (selectedTask) {
      rejectTask = {
        description: watch("description"),
        state: "Rejected",
      };
      dispatch(RejectTask(selectedTask._id, rejectTask));
      setmodalRequestTask(!modalRequestTask);
      settoggleDetails(!settoggleDetails);
    }
  };

  useEffect(() => {
    const state1 = ["Pending", "Done", "In progress", "Canceled", "Completed"];

    const tabType = ["Monitoring", "Other Monitoring", "Other"];

    dispatch(
      retrieveFiltredTasks(
        // JSON.stringify(type),
        JSON.stringify(tabType),
        JSON.stringify(state1),
        currentTimeZone
        //searchValue
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentTimeZone, refreshData]);
  return (
    <>
      <Button color="primary" outline onClick={toggleModalRequestTask}>
        Task Requests{" "}
        {tasks?.length !== 0 ? (
          <Badge color="danger">{tasks?.length}</Badge>
        ) : (
          ""
        )}
      </Button>
      <Modal
        className="Request-Task-Modal"
        centered
        backdrop="static"
        keyboard={true}
        isOpen={modalRequestTask}
        size="xl"
        toggle={toggleModalRequestTask}
        fade={false}
      >
        <ModalHeader className="headerContent" toggle={toggleModalRequestTask}>
          Task Requests
        </ModalHeader>
        <ModalBody className="TaskRequestModalBody">
          <Row className="ModalTaskBody">
            <Col className="task-card-section" xs={toggleDetails ? "7" : "12"}>
              {tasks ? (
                <>
                  {tasks?.map((task) => {
                    return (
                      <Row
                        className="TaskRow"
                        key={task._id}
                        onClick={() => toggleDetailsSection(task)}
                      >
                        <Col>
                          <Badge color="info">{task.title}</Badge>
                        </Col>
                        <Col>{task.systemName}</Col>
                        <Col>
                          {moment(task.estimatedStart)
                            .tz(currentTimeZone)
                            .format("LT")}
                        </Col>
                        <Col>
                          <Badge
                            color={task.assigneeName ? "dark" : "secondary"}
                          >
                            {task.assigneeName
                              ? task.assigneeName
                              : "unassigned"}
                          </Badge>
                        </Col>
                      </Row>
                    );
                  })}
                </>
              ) : (
                <Row>
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <span> No Tasks </span>
                      {/* <span>{error} </span> */}
                    </div>
                  </Col>
                </Row>
              )}
            </Col>
            {toggleDetails && (
              <Col className="task-details-section" xs="5">
                <div className="cardDetails">
                  <div>
                    <FormGroup>
                      <Row>
                        <Col className="statusBadgeleft">
                          <Badge color="info">{selectedTask?.title}</Badge>
                        </Col>
                        <Col className="statusBadgeright">
                          <Badge
                            color={
                              selectedTask?.assigneeName ? "dark" : "secondary"
                            }
                          >
                            {selectedTask?.assigneeName
                              ? selectedTask?.assigneeName
                              : "unassigned"}
                          </Badge>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Col>
                        <b>Customer : </b> {selectedTask?.customerName}
                      </Col>
                      <Col>
                        <b>System : </b>
                        {selectedTask?.systemName}
                      </Col>
                      <Col>
                        <b>Start time : </b>
                        {moment(selectedTask.estimatedStart)
                          .tz(currentTimeZone)
                          .format("LT")}
                        {/* {selectedTask?.start} */}
                      </Col>
                    </FormGroup>
                    <FormGroup className="descriptionPart">
                      <Label>Description </Label>
                      <textarea
                        className="form-control"
                        placeholder="Description"
                        {...register("description")}
                      ></textarea>
                    </FormGroup>
                  </div>
                  <div className="card-task-request-footer">
                    <Button color="success" onClick={Approve}>
                      Approve
                    </Button>{" "}
                    <Button color="danger" onClick={Reject}>
                      Reject
                    </Button>{" "}
                    <Button onClick={() => settoggleDetails(false)}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}
