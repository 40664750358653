const RETRIEVE_ALL_SHIFT_REPORT_SUCCESS = "RETRIEVE_ALL_SHIFT_REPORT_SUCCESS";
const RETRIEVE_ALL_SHIFT_REPORT_FAILURE = "RETRIEVE_ALL_SHIFT_REPORT_FAILURE";
const LOAD_SHIFT_REPORT = "LOAD_SHIFT_REPORT";

const RETRIEVE_ALL_MEMBERS_REPORT_SUCCESS = "RETRIEVE_ALL_MEMBERS_REPORT_SUCCESS";
const RETRIEVE_ALL_MEMBERS_REPORT_FAILURE = "RETRIEVE_ALL_MEMBERS_REPORT_FAILURE";
const LOAD_MEMBERS= "LOAD_MEMBERS";

const LOAD_AVAILABLE_SHIFT = "LOAD_AVAILABLE_SHIFT";
const RETRIEVE_AVAILABLE_SHIFT_SUCCESS = "RETRIEVE_AVAILABLE_SHIFT_SUCCESS";
const RETRIEVE_AVAILABLE_SHIFT_FAILURE= "RETRIEVE_AVAILABLE_SHIFT_FAILURE";

const LOAD_MEMBERS_BY_SHIFTS ="LOAD_MEMBERS_BY_SHIFTS";
const RETRIEVE_MEMBERS_BY_SHIFTS_SUCCESS = "RETRIEVE_MEMBERS_BY_SHIFTS_SUCCESS";
const RETRIEVE_MEMBERS_BY_SHIFTS_FAILURE = "RETRIEVE_MEMBERS_BY_SHIFTS_FAILURE";
const CLEAR_UPCOMING_SHIFTS = "CLEAR_UPCOMING_SHIFTS";




let shiftTypes = {
RETRIEVE_ALL_SHIFT_REPORT_SUCCESS,
RETRIEVE_ALL_SHIFT_REPORT_FAILURE,
LOAD_SHIFT_REPORT,
RETRIEVE_ALL_MEMBERS_REPORT_SUCCESS,
RETRIEVE_ALL_MEMBERS_REPORT_FAILURE,
LOAD_MEMBERS,
LOAD_AVAILABLE_SHIFT,
RETRIEVE_AVAILABLE_SHIFT_SUCCESS,
RETRIEVE_AVAILABLE_SHIFT_FAILURE,
LOAD_MEMBERS_BY_SHIFTS,
RETRIEVE_MEMBERS_BY_SHIFTS_SUCCESS,
RETRIEVE_MEMBERS_BY_SHIFTS_FAILURE,
CLEAR_UPCOMING_SHIFTS

};
export default shiftTypes;
