import types from "./types";
import userService from "../services/userService";
import { message } from 'antd';
const {
    LOAD_USER,
    GET_USER_SUCCESS,
    //GET_USER_FAILURE,
    UPDATE_INFO_USER_SUCCESS,
    //UPDATE_INFO_USER_FAILURE,
    UPDATE_PASSWORD_USER_SUCCESS,
    //UPDATE_PASSWORD_USER_FAILURE,
    CHANGE_TIME_ZONE,
    RESET_STATE_USER
} = types.userTypes;


// Get Current User
export const getCurrentUser = () => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_USER,
    });
    const res = await userService.getCurrentUser();
    dispatch({
      type: GET_USER_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    // dispatch({
    //   type: GET_USER_FAILURE,
    //   payload: err.response.data.message,
    // });
    console.log(err);
  }
};


export const changeInformationUser = (id, data) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_USER,
      });
      const res = await userService.changeInformationUser(id, data);
   message.success("Updated Successfully");
      dispatch({
        type: UPDATE_INFO_USER_SUCCESS,
        payload: res.data.data,
      });

      const resUser = await userService.getCurrentUser();
      dispatch({
        type: GET_USER_SUCCESS,
        payload: resUser.data.data,
      });


    } catch (err) {
   //message.error('An Error occurred while updating',3);    
      // dispatch({
      //   type: UPDATE_INFO_USER_FAILURE,
      //   payload: err?.response?.data?.message,
      // });
      console.log(err);
    }
  };

  export const changePasswordUser = (id, data) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_USER,
      });
      const res = await userService.changePassword(id, data);
      message.success("Updated Successfully",3);
      dispatch({
        type: UPDATE_PASSWORD_USER_SUCCESS,
        payload: res.data.data,
      });

      const resUser = await userService.getCurrentUser();
      //console.log("res user from actions",resUser)
      dispatch({
        type: GET_USER_SUCCESS,
        payload: resUser.data.data,
      });


    } catch (err) {
      message.error('An Error occurred while updating',3);
      //console.log("from actions ",err.response.data.message)
      // dispatch({
      //   type: UPDATE_PASSWORD_USER_FAILURE,
      //   payload: err?.response?.data?.message,
      // });
      console.log(err);
    }
  };

  export const changeTimeZone = (timeZone) => async (dispatch) => {
    try {
      dispatch({
        type: CHANGE_TIME_ZONE,
        payload : timeZone
      });
      localStorage.setItem("app-timezone", timeZone);
    } catch (err) {
      console.log(err);
    }
  };
  
  export const resetUser = () => async (dispatch) => {
    try {
      dispatch({
        type: RESET_STATE_USER,
      });
    } catch (err) {
      console.log(err);
    }
  };