import * as Yup from "yup";
const schema = Yup.object().shape({
  description: Yup.string().required("The description is required"),
  estimatedStart: Yup.object()
    .required("You need to choose a Time.")
    .nullable(),
  assignee: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required("This Value is required."),
    })
    .required("This Value is required."),
  title: Yup.string().when("isActive", {
    is: false,
    then: Yup.string().required("The Title is required."),
  }),
  system: Yup.object().when("isActive", {
    is: true,
    then: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.string().required("The System is required."),
    }),
  }),  
  map: Yup.object().when("isActive", {
    is: true,
    then: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.string().required("The Monitoring Activity is required."),
    }),
  }),

  customer: Yup.object().when("isActive", {
    is: true,
    then: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.string().required("The Customer is required."),
    }),
  }),  




});
export default schema;
