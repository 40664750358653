import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Label,
  Input,
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Spinner,
} from "reactstrap";
import { BsTrash, BsPencil } from "react-icons/bs";
import { processTableHeight, makeDelay } from "../../../../../assets/js/CoreJs";
import "../CustomerSystems.css";

//import { CustomerSystems } from "../systems-dummy-data";
import { useDispatch, useSelector } from "react-redux";
import {
  createHost,
  retrieveHosts,
  deleteHost,
  RetreiveOneHost,
  updateHost,
  clearHosts,
  clearOneHost
} from "../../../../../actions/hostAction";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./AddHostValidation";

const SystemHosts = function ({ selectedSystemID }) {
  //console.log("Id systeme selectionné =======>", selectedSystemID);

  //temporary to get index from fakeData json
  // const sysIndex = CustomerSystems.findIndex((s) => s.id === selectedSystemID);
  //const sysRow = CustomerSystems[sysIndex];

  const [addModal, setAddModal] = useState(false);
  const toggleAdd = () => {
    setAddModal(!addModal);
    reset();
  }
  const toggleResetEdit = () => {
    setEditModal(!editModal);
    reset();
  }
  const [editModal, setEditModal] = useState(false);
  // const toggleEdit = () => setEditModal(!editModal);

  // const [searchTerm, setSearchTerm] = useState("");

  const ErrorAction = useSelector((state) => state.host.error);
  const [refrechData, setrefrechData] = useState(false);
  const dispatch = useDispatch();

  const hosts = useSelector((state) => state.host.hosts);
  //console.log("Liste des HOSTS ===>", hosts);
  const selectedHost = useSelector((state) => state.host.host);
  //console.log("Selected HOST ===>", selectedHost);
  const error = useSelector((state) => state.host.listError);
  const isloading = useSelector((state) => state.host.isLoading);
  const [searchValue, setSearchValue] = useState("");

  const confirmDelete = (id) => {
    if (window.confirm("Are you sure you want to delete the Host ?")) {
      dispatch(deleteHost(id, selectedSystemID));
      //setrefrechData(!refrechData);
    }
  };

  const applyTabHeight = () => {
    processTableHeight(
      [
        "TitlePage",
        "customerDetailsTabsNav",
        "sysDetailHeader",
        "customerSystemDetailsTabsNav",
        "sysHostsHeader",
      ],
      "systemHostsTCONT"
    );
  };
  useEffect(() => {
    applyTabHeight();
    window.addEventListener("resize", applyTabHeight);

    //return fn must be last in useEffect
    return () => {
      window.removeEventListener("resize", applyTabHeight);
    };
  }, []);

  const {
   // control,
    handleSubmit,
    register,
    setValue,
    //watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  //Host hostAttribute
  // const [host, setHost] = useState("");
  // const handelHost = (e) => {
  //   setHost(e.target.value);
  //   // console.log(desc);
  // };

  //Add New Host
  // function handleCreateHost() {
  //   const newHost = {
  //     host: host,
  //     isActive: true,
  //     system: selectedSystemID,
  //   };
  //   dispatch(createHost(newHost));
  //   setHost(null);
  //   setrefrechData(!refrechData);
  //   setAddModal(!addModal);
  //   reset();
  //   if (!ErrorAction) {
  //     setAddModal(!addModal);
  //   }
  //   // console.log(newHost);
  // }

  const handelAddFormSubmit = (hostData) => {
    hostData.isActive=true
    hostData.system=selectedSystemID
    dispatch(createHost(hostData));
    // setHost(null);
    setrefrechData(!refrechData);
    setAddModal(!addModal);
    reset();
    if (!ErrorAction) {
      setAddModal(!addModal);
    }
  };

  useEffect(() => {
    //console.log("sys id in useeffect", selectedSystemID);
    if (selectedSystemID) {
      // console.log("Get ALL in use effect");
      dispatch(retrieveHosts(selectedSystemID, searchValue));
    }
  }, [dispatch, selectedSystemID, searchValue, refrechData]);
  // useEffect(() => {
  //   dispatch(RetreiveOneHost(selectedSystemID));
  // }, [dispatch, selectedSystemID]);

  const toggleEdit = async (e, id) => {
    e.preventDefault();
    if (id) {
      const res = await dispatch(RetreiveOneHost(id));
      if (res) {
       // setHost(res.host);
        setValue('host',res.host)
      }
    }
    setEditModal(!editModal);
    //reset();
  };

  // Edit HOST
  // function handleEdit(hostData) {
  //   setHost(host);

  //   if (host) {
  //     const updatedData = {
  //       host: host,
  //     };
  //     dispatch(updateHost(selectedHost._id, hostData));
  //   } 
  //   else {
  //     const updatedData = {
  //       host: host,
  //     };
  //     dispatch(updateHost(selectedHost._id, updatedData));
  //   }

  //   if (!error) {
  //     setrefrechData(!refrechData);
  //     setEditModal(!editModal);
  //   } else {
  //     //console.error(error);
  //   }
  // }

  const handelUpdateFormSubmit = (hostData) => {
    // hostData.isActive=true
    // hostData.system=selectedSystemID
    //console.log("hostData====>",hostData);
    dispatch(updateHost(selectedHost._id, hostData));
    // setHost(null);
    setrefrechData(!refrechData);
    setEditModal(!editModal);
    reset();
    if (!error) {
          setrefrechData(!refrechData);
          setEditModal(!editModal);
        } else {
          //console.error(error);
        }
  };

  const handelSearshQuerry = (e) => {
    makeDelay(() => {
      if (e.target.value.length >= 1) {
        setSearchValue(e.target.value);
      } else {
        setSearchValue("");
      }
    }, 750);
  };

  useEffect(() => {
    return () => clearTimeout(window.timerDelay);
  }, []);

   //Clear Data
   useEffect(() => {
    return () => {

      dispatch(clearHosts());
      dispatch(clearOneHost());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
    <div className="systemHosts">
      <Row className="sysHostsHeader">
        <Col xs="2">
          <Button color="primary" onClick={toggleAdd}>
            Add Host
          </Button>
        </Col>
        <Col xs={{ size: 4, offset: 6 }}>
          <Input
            type="search"
            className="form-control"
            placeholder="Search By Host"
            onChange={handelSearshQuerry}
          />
        </Col>
      </Row>
      <div className="sysHostsContent">
        <div id="systemHostsTCONT" className="avxTableContainer scrollTb">
          <Table className="systemHostsTable avxBsTable twoActs" striped>
            <thead>
              <tr>
                <th>Host</th>
                <th>
                  <span>
                    <BsPencil></BsPencil>
                  </span>
                </th>
                <th>
                  <span>
                    <BsTrash></BsTrash>
                  </span>
                </th>
              </tr>
            </thead>

            {isloading === false ? (
              <tbody>
                {hosts?.length>0 && error === null ? (
                  <>
                    {hosts
                      .map((h) => (
                        <tr key={h._id}>
                          <td>{h.host}</td>
                          <td>
                            <span
                              className="tbBtn"
                              onClick={(e) => toggleEdit(e, h._id)}
                            >
                              <BsPencil></BsPencil>
                            </span>
                          </td>
                          <td>
                            <span
                              className="tbBtn"
                              onClick={() => confirmDelete(h._id)}
                            >
                              <BsTrash></BsTrash>
                            </span>
                          </td>
                        </tr>
                      ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan="3">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                        }}
                      >
                        <span> No Host Exist For this System {error} </span>
                        {/* <span>{error} </span> */}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="3">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: 100,
                      }}
                    >
                      <Spinner color="dark" />
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
      </div>

      <Modal
        isOpen={addModal}
        toggle={toggleAdd}
        className="addSystemHostModal"
        centered
        fade={false}
        backdrop="static" 
        keyboard={true}
      >
        <Form onSubmit={handleSubmit(handelAddFormSubmit)} className="formModalAddHost">
        <ModalHeader toggle={toggleAdd}>Add new system Host</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Host
            <span className="requiredField"> *</span>
            </Label>
            <input type="text" name="hostName" id="hostName"  
            className={ "form-control" + (errors.host ? " is-invalid" : "") }
            {...register("host")}
            />
              <small className="invalid-feedback">
                    {errors.host && <>{errors.host.message}</>}
              </small>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" >  Add Host </Button>{" "}
          <Button color="secondary" onClick={toggleAdd}>
            Cancel
          </Button>
        </ModalFooter>
       </Form>             
      </Modal>

      <Modal
        isOpen={editModal}
        toggle={toggleEdit}
        className="editSystemHostModal"
        centered
        fade={false}
        backdrop="static" 
        keyboard={true}
      >
 <Form onSubmit={handleSubmit(handelUpdateFormSubmit)} className="formModalEditHost">
        <ModalHeader toggle={toggleEdit}>Update system Host</ModalHeader>
        <ModalBody>
          {/* <FormGroup>
            <Label>Host</Label>
            <Input type="text" defaultValue={host} onChange={handelHost} />
          </FormGroup> */}
           <FormGroup>
            <Label>Host
            <span className="requiredField"> *</span>
            </Label>
            <input type="text" name="hostName" id="hostName"  
            className={ "form-control" + (errors.host ? " is-invalid" : "") }
            {...register("host")}
            />
              <small className="invalid-feedback">
                    {errors.host && <>{errors.host.message}</>}
              </small>
          </FormGroup>
       
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit">
            Save Changes
          </Button>{" "}
          <Button color="secondary" onClick={toggleResetEdit}>
            Cancel
          </Button>
        </ModalFooter>
        </Form>  
      </Modal>
    </div>
  );
};

export default SystemHosts;
