import types from "../actions/types";
const {
    LOAD_RESULT_FORM,
    GET_ALL_RESULT_FORM_SUCCESS,
    GET_ALL_RESULT_FORM_FAILURE,
    CREATE_RESULT_FORM_SUCCESS,
    CREATE_RESULT_FORM_FAILURE,
    RETRIEVE_ONE_RESULT_FORM_SUCCESS,
    RETRIEVE_ONE_RESULT_FORM_FAILURE,
    UPDATE_RESULT_FORM_SUCCESS,
    UPDATE_RESULT_FORM_FAILURE,
    DELETE_RESULT_FORM_SUCCESS,
    DELETE_RESULT_FORM_FAILURE,

    GET_ALL_MONITORING_ACTIVITY_WITHOUT_RESULT_FORM_SUCCESS,
    GET_ALL_MONITORING_ACTIVITY_WITHOUT_RESULT_FORM_FAILURE,
    LOAD_MONITORING_ACTIVITY,

    CLEAR_RESULT_FORM
 
} = types.resultFormTypes;
const initialState = {
  resultForm: null,
  resultForms: [],
  isLoading: false,
  error: null,
  listError: null,
  monitoringActs: [],
};

function userReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    case GET_ALL_RESULT_FORM_SUCCESS:
      return {
        ...state,
        resultForms: payload,
        isLoading: false,
        error: null,
        listError: null,
      };

    case GET_ALL_RESULT_FORM_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: payload,
      };
    case LOAD_RESULT_FORM:
      return {
        ...state,
        isLoading: true,
      };

    case CREATE_RESULT_FORM_SUCCESS:
    state.resultForms.docs.push(payload);
    return {
        ...state,
        resultForms: state.resultForms,
        isLoading: false,
        error: null,
        listError: null,
    };
    case CREATE_RESULT_FORM_FAILURE:
    return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
    };

    case RETRIEVE_ONE_RESULT_FORM_SUCCESS:
      return {
        ...state,
        resultForm: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case RETRIEVE_ONE_RESULT_FORM_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

      case UPDATE_RESULT_FORM_SUCCESS:
        const updatedIndex = state.resultForms.docs.map((resultForm, index) => {
          if (payload._id === resultForm._id) {
            return index;
          } else return null;
        });
        if (updatedIndex) state.resultForms.docs[updatedIndex] = payload;
        return {
          ...state,
          resultForms: state.resultForms,
          isLoading: false,
          error: null,
          listError: null,
        };
  
      case UPDATE_RESULT_FORM_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: payload,
          listError: null,
        };

        case DELETE_RESULT_FORM_SUCCESS:
       
          return {
            ...state,
            isLoading: false,
          };
    
        case DELETE_RESULT_FORM_FAILURE:
          return {
            ...state,
            isLoading: false,
            error: payload,
            listError: null,
          };

        case GET_ALL_MONITORING_ACTIVITY_WITHOUT_RESULT_FORM_SUCCESS:
          return {
            ...state,
            monitoringActs: payload,
            isLoading: false,
          };
        case GET_ALL_MONITORING_ACTIVITY_WITHOUT_RESULT_FORM_FAILURE:
          return {
            ...state,
            isLoading: false,
            error: payload,
          };
        case LOAD_MONITORING_ACTIVITY:
          return {
            ...state,
            isLoading: true,
          };

        case CLEAR_RESULT_FORM:
          return {
            ...state,
            resultForms: [],
            resultForm: null,
            monitoringActs: [],
            isLoading: false,
            error: null,
            listError: null,
    };

    default:
      return state;
  }
}

export default userReducer;
