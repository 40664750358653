import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "antd/dist/antd.css";
import "./customer.css";
import { Link } from "react-router-dom";
import AddCustomerModal from "./components/Modal/AddCustomerModal";
import UpdateCustomerModal from "./components/Modal/UpdateCustomerModal";
import { Row, Col, Input, Table, Spinner } from "reactstrap";
import { TablePagination } from "@material-ui/core";
import { BsFillCircleFill, BsPencil } from "react-icons/bs";

import { retrieveCustomers,clearCustomers } from "../../actions/customerAction";
import { processTableHeight, makeDelay } from "../../assets/js/CoreJs";

const Customer = () => {
  const customers = useSelector((state) => state.customer.customers.docs);
  const customerLoading = useSelector((state) => state.customer.areLoading);
  const totalDocs = useSelector((state) => state.customer.customers.totalDocs);
  const [refrechData, setrefrechData] = React.useState(false);
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  // const [searchTerm, setSearchTerm] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchValue, setSearchValue] = React.useState("");

  const applyTabHeightCustomerList = () => {
    processTableHeight(
      ["TitlePage", "customerListHeader", "customerListPaginate"],
      "customerListTCONT",
      20
    );
  };

  useEffect(() => {
    applyTabHeightCustomerList();
    window.addEventListener("resize", applyTabHeightCustomerList);

    dispatch(retrieveCustomers(true, page, rowsPerPage, null, searchValue));

    //return fn must be last in useEffect
    return () => {
      window.removeEventListener("resize", applyTabHeightCustomerList);
      //dispatch(clearCustomers());
    };
  }, [dispatch, page, rowsPerPage, searchValue, refrechData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setrefrechData(!refrechData);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setrefrechData(!refrechData);
  };

  // const toggleTab = (tab) => {
  //   if (activeTab !== tab) setActiveTab(tab);
  // };

  // const handelAddFormSubmit = (event) => {
  //   event.preventDefault();
  //   const { isActive, label, address } = addFormData;

  //   dispatch(
  //     createCustomer(
  //       (isActive === "true" && true) || (isActive === "false" && false),
  //       label,
  //       selected,
  //       address,
  //       monitoringType
  //     )
  //   );
  //   setModal(!modal);
  // };

  // const toggleTab = (tab) => {
  //   if (activeTab !== tab) setActiveTab(tab);
  // };

  //if (customerLoading) return null;

  const handelSearshQuerry = (e) => {
    makeDelay(() => {
      if (e.target.value.length >= 2) {
        setSearchValue(e.target.value);
        setPage(0);
      } else {
        setSearchValue("");
      }
    }, 750);
  };

  useEffect(() => {
    return () => clearTimeout(window.timerDelay);
  }, []);

  //Clear Data
  useEffect(() => {
    return () => {
      dispatch(clearCustomers());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <>
      <section className="TitlePage">
        Customer
        {/* <div className="Page_Description">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eligendi
          reiciendis veniam veli
        </div> */}
      </section>

      <section className="avxWrpContentPage customerPage">
        <Row className="customerListHeader">
          <Col xs="2">
            <AddCustomerModal refrechData={refrechData} setrefrechData={setrefrechData} />
          </Col>
          <Col xs={{ size: 4, offset: 6 }}>
            <Input
              type="search"
              className="form-control"
              placeholder="Search by Customer "
              onChange={handelSearshQuerry}
            />
          </Col>
        </Row>

        <div className="customerListContent">
          <div id="customerListTCONT" className="avxTableContainer scrollTb">
            <Table
              className="customerListTable avxBsTable oneActs"
              striped
              hover
            >
              <thead>
                <tr>
                  <th>Customer</th>
                  <th>Time zones</th>

                  <th>
                    <span>
                      <BsPencil />
                    </span>
                  </th>
                </tr>
              </thead>
              {customerLoading ? (
                <tbody>
                  <tr>
                    <td colSpan="4">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: 100,
                        }}
                      >
                        <Spinner color="dark" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {customers?.length > 0 ? (
                    <>
                      {customers?.map((row, index) => (
                        <tr
                          key={index}
                          className={
                            "customerRow " +
                            (row.isActive ? "activeC" : "disabledC")
                          }
                        >
                          <td className="hasLink">
                            <Link to={"/app/customerDetails/" + row._id}>
                              <span className="stateFlag">
                                <BsFillCircleFill />
                              </span>
                              {row.label}
                            </Link>
                          </td>
                          <td className="hasLink">
                            <Link to={"/app/customerDetails/" + row._id}>
                              {row?.timeZone?.map((sTz, idx) => (
                                <span key={"tz-" + idx} className="singleTZ">
                                  {sTz}
                                </span>
                              ))}
                            </Link>
                          </td>

                          <td>
                            <UpdateCustomerModal
                              customerId={row._id}
                              page={page}
                              rowsPerPage={rowsPerPage}
                            />
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="3">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                          }}
                        >
                          <span> No customers found</span>
                          {/* <span>{error} </span> */}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </Table>
          </div>

          <TablePagination
            className="customerListPaginate"
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            component="div"
            count={totalDocs ? totalDocs : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </section>
    </>
  );
};

export default Customer;
