import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Label,
  Input,
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import {
  addCustomerContactToSystem,
  getCustomerContactUnassignedToSystem,
  unassignCustomerContactFromSystem,
  clearCustomerContacts,
  clearCustomerContactsToSystem
} from "../../../../../actions/customerContactAction";
import { getAssignedSystemContact } from "../../../../../actions/systemAction";
import { useDispatch, useSelector } from "react-redux";
import { BsPersonDash } from "react-icons/bs";
import Select from "../../../../../shared/select/Select";
import { makeDelay, processTableHeight } from "../../../../../assets/js/CoreJs";
import "../CustomerSystems.css";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./SystemContactsValidation";


const SystemContacts = function ({ selectedSystemID}) {
  const dispatch = useDispatch();
 
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [searchValue, setSearchValue] = useState("");

  const idCustomer = useSelector(
    (state) => state.customer.customer._id
  );

  const customerContacts = useSelector(
    (state) => state.customerContact.customerContacts
  );
  const customerContactList = customerContacts&&Array.isArray(customerContacts)
    ? customerContacts.map((item) => ({
        value: item._id,
        label: item.lastName,
      }))
    : [];
  useEffect(() => {
    dispatch(getAssignedSystemContact(selectedSystemID, searchValue));
  }, [dispatch, selectedSystemID, searchValue]);
  const customerContactsAssigned = useSelector(
    (state) => state.system.customerContactsAssigned
  );
  const [addModal, setAddModal] = useState(false);
  const toggleAdd = () => {
    setAddModal(!addModal);
    dispatch(getCustomerContactUnassignedToSystem(idCustomer,selectedSystemID));
    reset();
  };
  const handelAddFormSubmit = ({ contact }) => {
      dispatch(
        addCustomerContactToSystem(selectedSystemID, {
          id: contact.value,
        })
      );
      reset();
      setAddModal(!addModal);
    }
  // const [searchTerm, setSearchTerm] = useState("");
  const confirmDelete = (id) => {
    if (window.confirm("Are you sure you want to unassign the Contact ?")) {
      dispatch(
        unassignCustomerContactFromSystem(selectedSystemID, id)
      );
    }
  };
  const applyTabHeight = () => {
    processTableHeight(
      [
        "TitlePage",
        "customerDetailsTabsNav",
        "sysDetailHeader",
        "customerSystemDetailsTabsNav",
        "sysContactsHeader",
      ],
      "systemContactsTCONT"
    );
  };
  useEffect(() => {
    applyTabHeight();
    window.addEventListener("resize", applyTabHeight);

    //return fn must be last in useEffect
    return () => {
      window.removeEventListener("resize", applyTabHeight);
    };
  }, []);

  const selectInvalidFeedback = {
    width: "100%",
    marginTop: ".25rem",
    fontSize: "80%",
    color: "#dc3545",
  };

  if(errors.contact){
    document.querySelector('.customerSelectContact > div:nth-child(2)') &&
    document.querySelector('.customerSelectContact > div:nth-child(2)').classList.add('isInvalidSelect');
  }
  
  const handleSearchQuerry = (e) => {
    makeDelay(() => {
      if (e.target.value.length >= 2) {
        setSearchValue(e.target.value);
      } else {
        setSearchValue("");
      }
    }, 750);
  };

  useEffect(() => {
    return () => clearTimeout(window.timerDelay);
  }, []);
  

  //Clear Data
  useEffect(() => {
    return () => {
      dispatch(clearCustomerContacts());
      dispatch(clearCustomerContactsToSystem());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])



  return (
    <div className="systemContacts">
      <Row className="sysContactsHeader">
        <Col xs="2">
          <Button color="primary" onClick={toggleAdd}>
            Assign Contact
          </Button>
        </Col>
        <Col xs={{ size: 4, offset: 6 }}>
          <Input
            type="search"
            className="form-control"
            placeholder="Search By Name or Email"
            onChange={handleSearchQuerry}
            // onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Col>
      </Row>
      <div className="sysContactsContent">
        <div id="systemContactsTCONT" className="avxTableContainer scrollTb">
          <Table className="systemContactsTable avxBsTable oneActs" striped>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>
                  <span>
                    <BsPersonDash></BsPersonDash>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {customerContactsAssigned &&
              customerContactsAssigned.length > 0 ? (
                <>
                  {customerContactsAssigned
                    .map((cnt) => {
                      return (
                        <tr key={cnt._id}>
                          <td>{cnt.lastName}</td>
                          <td>{cnt.mail}</td>
                          <td>{cnt.phoneNumber}</td>
                          <td>
                            <span className="tbBtn" onClick={()=>confirmDelete(cnt._id)}>
                              <BsPersonDash></BsPersonDash>
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                </>
              ) : (
                <tr>
                  <td colSpan="4">
                  <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                              }}
                            >No assigned contact to this system
                            </div>
                            </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>

      <Modal
        isOpen={addModal}
        toggle={toggleAdd}
        backdrop="static" keyboard={true}
        className="addSystemContactModal"
        centered
        fade={false}
      >
        <Form onSubmit={handleSubmit(handelAddFormSubmit)} >

        <ModalHeader toggle={toggleAdd}>
          Assign new contact to system
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>
            Contact
              <span className="requiredField"> *</span>
            </Label>
            <Controller
              name="contact"
              control={control}
              render={({ field: { ref, ...rest }, fieldState }) => (
                <Select
                  className="customerSelectContact" //.......................
                  placeholder="Select contact"
                  options={customerContactList}
                  {...rest}
                  {...fieldState}
                />
              )}
            />
            <small style={selectInvalidFeedback}>
              {errors.contact && <>{errors.contact.value?.message}</>}
            </small>
          </FormGroup>
          
        </ModalBody>
        <ModalFooter>
          <Button color="primary"  type="submit">
            Assign Contact
          </Button>{" "}
          <Button color="secondary" onClick={toggleAdd}>
            Cancel
          </Button>
        </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default SystemContacts;
