import types from "../actions/types";
const { GET_ALL_SYSCLASSE_SUCCESS, GET_ALL_SYSCLASSE_FAILURE, LOAD_SYSCLASSE,  CREATE_SYSCLASSE_SUCCESS,
  CREATE_SYSCLASSE_FAILURE,
  RETRIEVE_ONE_SYSCLASSE_SUCCESS,
  RETRIEVE_ONE_SYSCLASSE_FAILURE,
  UPDATE_SYSCLASSE_SUCCESS,
  UPDATE_SYSCLASSE_FAILURE,
  CLEAR_SYSCLASSE
} =
  types.sysClassTypes;
const initialState = {
  sysClasse: null,
  sysClasses: [],
  isLoading: false,
  error: null,
  listError: null,
};

function userReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_SYSCLASSE_SUCCESS:
      return {
        ...state,
        sysClasses: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case GET_ALL_SYSCLASSE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: payload,
      };
    case LOAD_SYSCLASSE:
      return {
        ...state,
        isLoading: true,
      };

      case CREATE_SYSCLASSE_SUCCESS:
      state.sysClasses.docs.push(payload);
      return {
        ...state,
        sysClasses: state.sysClasses,
        isLoading: false,
        error: null,
        listError: null,
      };
    case CREATE_SYSCLASSE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

      case RETRIEVE_ONE_SYSCLASSE_SUCCESS:
      return {
        ...state,
        sysClasse: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case RETRIEVE_ONE_SYSCLASSE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

      case UPDATE_SYSCLASSE_SUCCESS:
        const updatedIndex = state.sysClasses.docs.map((sysClasse, index) => {
          if (payload._id === sysClasse._id) {
            return index;
          } else return null;
        });
        if (updatedIndex) state.sysClasses.docs[updatedIndex] = payload;
        return {
          ...state,
          sysClasses: state.sysClasses,
          isLoading: false,
          error: null,
          listError: null,
        };
  
      case UPDATE_SYSCLASSE_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: payload,
          listError: null,
        };

      case CLEAR_SYSCLASSE:
        return {
          ...state,
          sysClasses: [],
          sysClasse: null,
          isLoading: false,
          error: null,
          listError: null,
        };

    default:
      return state;
  }
}

export default userReducer;
