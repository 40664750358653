import PropTypes from 'prop-types';
import { useSelector } from "react-redux";

const DisplayForPermissionComponent = (props) => {
  const permissions = useSelector((state) => state.authentication.listPermissions);
    const couldDisplay = permissions.includes(props.permissionCode);
    return couldDisplay ? props.children : null;
};

DisplayForPermissionComponent.propTypes = {
    permissionCode: PropTypes.number.isRequired,
};
export default DisplayForPermissionComponent;