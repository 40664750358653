const CREATE_CUSTOMER_CONTACT_SUCCESS = "CREATE_CUSTOMER_CONTACT_SUCCESS";
const CREATE_CUSTOMER_CONTACT_FAILURE = "CREATE_CUSTOMER_CONTACT_FAILURE";
const GET_CUSTOMER_CONTACTS_BY_CUSTOMER_SUCCESS =
  "GET_CUSTOMER_CONTACTS_BY_CUSTOMER_SUCCESS";
const GET_CUSTOMER_CONTACTS_BY_CUSTOMER_FAILURE =
  "GET_CUSTOMER_CONTACTS_BY_CUSTOMER_FAILURE";
const GET_CUSTOMER_CONTACTS_BY_CUSTOMER_UNASSIGNED_SUCCESS =
  "GET_CUSTOMER_CONTACTS_BY_CUSTOMER_UNASSIGNED_SUCCESS";
const GET_CUSTOMER_CONTACTS_BY_CUSTOMER_UNASSIGNED_FAILURE =
  "GET_CUSTOMER_CONTACTS_BY_CUSTOMER_UNASSIGNED_FAILURE";
const UPDATE_CUSTOMER_CONTACT_SUCCESS = "UPDATE_CUSTOMER_CONTACT_SUCCESS";
const UPDATE_CUSTOMER_CONTACT_FAILURE = "UPDATE_CUSTOMER_CONTACT_FAILURE";
const GET_ONE_CUSTOMER_CONTACT_FAILURE = "GET_ONE_CUSTOMER_CONTACT_FAILURE";
const GET_ONE_CUSTOMER_CONTACT_SUCCESS = "GET_ONE_CUSTOMER_CONTACT_SUCCESS";
const DELETE_CUSTOMER_CONTACT_SUCCESS = "DELETE_CUSTOMER_CONTACT_SUCCESS";
const DELETE_CUSTOMER_CONTACT_FAILURE = "DELETE_CUSTOMER_CONTACT_FAILURE";
const ADD_CUSTOMER_CONTACT_TO_SYSTEM_FAILURE =
  "ADD_CUSTOMER_CONTACT_TO_SYSTEM_FAILURE";
const ADD_CUSTOMER_CONTACT_TO_SYSTEM_SUCCESS =
  "ADD_CUSTOMER_CONTACT_TO_SYSTEM_SUCCESS";
  const UNASSIGN_CUSTOMER_CONTACT_TO_SYSTEM_FAILURE =
  "UNASSIGN_CUSTOMER_CONTACT_TO_SYSTEM_FAILURE";
const UNASSIGN_CUSTOMER_CONTACT_TO_SYSTEM_SUCCESS =
  "UNASSIGN_CUSTOMER_CONTACT_TO_SYSTEM_SUCCESS";
const LOAD_CUSTOMER_CONTACT = "LOAD_CUSTOMER_CONTACT";
const CLEAR_CUSTOMER_CONTACTS = "CLEAR_CUSTOMER_CONTACTS";
const CLEAR_ONE_CUSTOMER_CONTACT = "CLEAR_ONE_CUSTOMER_CONTACT";
const CLEAR_CUSTOMER_CONTACTS_TO_SYSTEM = "CLEAR_CUSTOMER_CONTACTS_TO_SYSTEM";

let customerContactTypes = {
  CREATE_CUSTOMER_CONTACT_SUCCESS,
  CREATE_CUSTOMER_CONTACT_FAILURE,
  GET_CUSTOMER_CONTACTS_BY_CUSTOMER_SUCCESS,
  GET_CUSTOMER_CONTACTS_BY_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_CONTACT_SUCCESS,
  UPDATE_CUSTOMER_CONTACT_FAILURE,
  GET_ONE_CUSTOMER_CONTACT_FAILURE,
  GET_ONE_CUSTOMER_CONTACT_SUCCESS,
  DELETE_CUSTOMER_CONTACT_SUCCESS,
  DELETE_CUSTOMER_CONTACT_FAILURE,
  ADD_CUSTOMER_CONTACT_TO_SYSTEM_SUCCESS,
  ADD_CUSTOMER_CONTACT_TO_SYSTEM_FAILURE,
  GET_CUSTOMER_CONTACTS_BY_CUSTOMER_UNASSIGNED_SUCCESS,
  GET_CUSTOMER_CONTACTS_BY_CUSTOMER_UNASSIGNED_FAILURE,
  UNASSIGN_CUSTOMER_CONTACT_TO_SYSTEM_FAILURE,
  UNASSIGN_CUSTOMER_CONTACT_TO_SYSTEM_SUCCESS,
  LOAD_CUSTOMER_CONTACT,
  CLEAR_CUSTOMER_CONTACTS,
  CLEAR_ONE_CUSTOMER_CONTACT,
  CLEAR_CUSTOMER_CONTACTS_TO_SYSTEM
};
export default customerContactTypes;
