import types from "./types";
import resultService from "../services/resultService"

const {
    CREATE_RESULT_SUCCESS,
    CREATE_RESULT_FAILURE,
    RETRIEVE_LAST_RESULT_SUCCESS,
    RETRIEVE_LAST_RESULT_FAILURE,
    RETRIEVE_LAST_RESULT_LOADER,
    UPDATE_RESULT_SUCCESS,
    UPDATE_RESULT_FAILURE,
    GET_ONE_RESULT_SUCCESS,
    GET_ONE_RESULT_FAILURE,
    GET_ONE_RESULT_LOADER
} = types.resultTypes;

export const createNewResult = (newResult) => async (dispatch) => {
    try {
        const res = await resultService.addResult(newResult);
        dispatch({
            type: CREATE_RESULT_SUCCESS,
            payload: res.data.data
        })
        return res.data.data;
    } catch (err) {
        dispatch({
            type: CREATE_RESULT_FAILURE,
            payload: err.response?.data?.message
        });
        return null;
    }
}

export const GetLastResult = (TaskID) => async (dispatch) => {
    try {
        dispatch({
            type: RETRIEVE_LAST_RESULT_LOADER,
        });
        const res = await resultService.getLastResult(TaskID); // Change Service Get Result here 
        dispatch({
            type: RETRIEVE_LAST_RESULT_SUCCESS,
            payload: res.data.data
        })
        return res.data.data;
    } catch (err) {
        dispatch({
            type: RETRIEVE_LAST_RESULT_FAILURE,
            payload: err.response?.data?.message
        });
        return null;
    }
}

export const updateResult = (taskId,newResult) => async (dispatch) => {
    try {
        const res = await resultService.updateResult(taskId,newResult);
        dispatch({
            type: UPDATE_RESULT_SUCCESS,
            payload: res.data.data
        })
        return res.data.data;
    } catch (err) {
        dispatch({
            type: UPDATE_RESULT_FAILURE,
            payload: err.response?.data?.message
        });
        return null;
    }
}

export const getOneResult = (TaskID) => async (dispatch) => {
    try {
        dispatch({
            type: GET_ONE_RESULT_LOADER,
        });
        const res = await resultService.getOneResult(TaskID); // Change Service Get Result here 
        dispatch({
            type: GET_ONE_RESULT_SUCCESS,
            payload: res.data.data
        })
        return res.data.data;
    } catch (err) {
        dispatch({
            type: GET_ONE_RESULT_FAILURE,
            payload: err.response?.data?.message
        });
        return null;
    }
}