import http from "../api";

const getAllSlaContract = (customer_Id, page, size, searchValue) => {
  if(searchValue){
    return http.get(
      `/api/slaContract/AllSlaContractByCustomerID/${customer_Id}?page=${page}&size=${size}&searchValue=${searchValue}`
    );
  }
  else{
    return http.get(
      `/api/slaContract/AllSlaContractByCustomerID/${customer_Id}?page=${page}&size=${size}`
    );
  }

};
const createSlaContract = (slaContract) => {
  return http.post(`/api/slaContract`, slaContract);
};
const updateSlaContract = (id, slaContract) => {
  return http.patch(`/api/slaContract/update/${id}`, slaContract);
};
const deleteSlaContract = (id) => {
  return http.patch(`/api/slaContract/delete/${id}`);
};
const getOneSlaContract = (id) => {
  return http.get(`/api/slaContract/getone/${id}`);
};
const slaContractService = {
  getAllSlaContract,
  createSlaContract,
  updateSlaContract,
  deleteSlaContract,
  getOneSlaContract,
};
export default slaContractService;
