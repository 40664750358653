import types from "./types";
import typeService from "../services/typeService";
import { message } from "antd";
const {
  GET_ALL_TYPE_SUCCESS,
  GET_ALL_TYPE_FAILURE,
  LOAD_TYPE,
  CREATE_TYPE_SUCCESS,
  CREATE_TYPE_FAILURE,
  RETRIEVE_ONE_TYPE_SUCCESS,
  RETRIEVE_ONE_TYPE_FAILURE,
  UPDATE_TYPE_SUCCESS,
  UPDATE_TYPE_FAILURE,
  CLEAR_TYPES
} = types.typeTypes;

export const retrieveType = (page, size, isActive, searchValue) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_TYPE,
    });

    const res = await typeService.getAll(page, size, isActive, searchValue);
    dispatch({
      type: GET_ALL_TYPE_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data.docs;
  } catch (err) {
    dispatch({
      type: GET_ALL_TYPE_FAILURE,
      payload: err,
    });
  }
};

export const createType = (type) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_TYPE,
    });
    const res = await typeService.createType(type);
    dispatch({
      type: CREATE_TYPE_SUCCESS,
      payload: res.data.data,
    });
    message.success("Successfully created the type");
  } catch (err) {
    message.error("Failed to create the type");
    dispatch({
      type: CREATE_TYPE_FAILURE,
      payload: err,
    });
  }
};

export const RetreiveOneType = (id) => async (dispatch) => {
  try {
    const res = await typeService.getOneType(id);
    dispatch({
      type: RETRIEVE_ONE_TYPE_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {
    dispatch({
      type: RETRIEVE_ONE_TYPE_FAILURE,
      payload: err.response.data.message,
    });
  }
};

export const updateType= (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_TYPE,
    });
    const res = await typeService.updateType(id, data);
    message.success("Successfully updated the type");

    dispatch({
      type: UPDATE_TYPE_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    message.error("Failed to update the type");
    dispatch({
      type: UPDATE_TYPE_FAILURE,
      payload: err?.response?.data?.message,
    });
  }
};

 //clear Data
 export const clearTypes = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_TYPES,
  })
}

