import types from "./types";
import systemService from "../services/systemService";

const {
  RETRIEVE_SYSTEM_CATEGORIES_SUCCESS,
  RETRIEVE_SYSTEM_CATEGORIES_FAILURE,
  RETRIEVE_SYSTEM_TYPES_SUCCESS,
  RETRIEVE_SYSTEM_TYPES_FAILURE,
  LOAD_LANDSCAPE_CT,
  RETRIEVE_SYSTEMS_SUCCESS,
  RETRIEVE_SYSTEMS_FAILURE,
  LOAD_TYPES_CT,
  LOAD_SYSTEM,
  CLEAR_SYSTEMS_CT
} = types.landScapCtTypes;
export const RetreiveSystemCategoriesCT = (customerId) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_LANDSCAPE_CT,
    });
    const res = await systemService.getCategoriesByCustomerId(customerId);
    dispatch({
      type: RETRIEVE_SYSTEM_CATEGORIES_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: RETRIEVE_SYSTEM_CATEGORIES_FAILURE,
      payload: err.response.data.message,
    });
  }
};
export const RetreiveSystemTypes =
  (customerId, categoryId) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_TYPES_CT,
        payload: categoryId,
      });
      const res = await systemService.getTypesByCustomerIdByCategoryId(
        customerId,
        categoryId
      );
      dispatch({
        type: RETRIEVE_SYSTEM_TYPES_SUCCESS,
        payload: { data: res.data.data, categoryId: categoryId },
      });
    } catch (err) {
      dispatch({
        type: RETRIEVE_SYSTEM_TYPES_FAILURE,
        payload: categoryId,
      });
    }
  };
export const RetreiveSystemByCustomerByCatgeroyByTypesCT =
  (customerId, categoryId, typeId) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_SYSTEM,
        payload: {
          categoryId: categoryId,
          typeId: typeId,
        },
      });
      const res =
        await systemService.getSystemsByCustomerIdByTypeIdByCategoryId(
          customerId,
          categoryId,
          typeId
        );
      dispatch({
        type: RETRIEVE_SYSTEMS_SUCCESS,
        payload: {
          data: res.data.data,
          categoryId: categoryId,
          typeId: typeId,
        },
      });
      return res;
    } catch (err) {
      dispatch({
        type: RETRIEVE_SYSTEMS_FAILURE,
        payload: {
          categoryId: categoryId,
          typeId: typeId,
        },
      });
    }
  };

  //clear All
 export const clearSystemsCT = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_SYSTEMS_CT,
  })
}

