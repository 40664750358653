const GET_ALL_ROLE_SUCCESS = "GET_ALL_ROLE_SUCCESS";
const GET_ALL_ROLE_FAILURE = "GET_ALL_ROLE_FAILURE";
const LOAD_ROLE = "LOAD_ROLE";
const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
const CREATE_ROLE_FAILURE = "CREATE_ROLE_FAILURE";
const RETRIEVE_ONE_ROLE_FAILURE = "RETRIEVE_ONE_ROLE_FAILURE";
const RETRIEVE_ONE_ROLE_SUCCESS = "RETRIEVE_ONE_ROLE_SUCCESS";
const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
const UPDATE_ROLE_FAILURE = "UPDATE_ROLE_FAILURE";
const CLEAR_ROLES = "CLEAR_ROLES";

let roleTypes = {
  LOAD_ROLE,
  GET_ALL_ROLE_SUCCESS,
  GET_ALL_ROLE_FAILURE,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_FAILURE,
  RETRIEVE_ONE_ROLE_SUCCESS,
  RETRIEVE_ONE_ROLE_FAILURE,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAILURE,
  CLEAR_ROLES,
};
export default roleTypes;