import React, { useState } from "react";
import { FiCheckCircle } from "react-icons/fi";
import "./ManageOtherTaskModal.css"
import { Modal,Form,ModalHeader,ModalBody,ModalFooter,Button,FormGroup, Input ,Label} from 'reactstrap';
import { updateTaskMember, retrieveFiltredTasks } from "../../../actions/taskAction";
import { useSelector, useDispatch } from "react-redux";

export default function ManageOtherTaskModal({taskID}) {
  const [modalManageOtherTask, setmodalManageOtherTask] = useState(false)
  const toggleManageOtherTask=()=>{
    setmodalManageOtherTask(!modalManageOtherTask)
  }
  const dispatch = useDispatch();
  const currentTimeZone = useSelector((state) => state.user.timeZone);
  const [feedback, setFeedback] = useState();
  const [refreshData, setrefreshData] = useState(false);


  const Done = async () => {
    if( window.confirm('Are you sure to set the task as Done ?') ){
      let updatedTask = {};
      if (taskID) {
        updatedTask = {
          resultat: feedback,
          state: "Done",
        };
        await dispatch(updateTaskMember(taskID, updatedTask));

        const state1 = ["Pending", "Done", "In progress", "Canceled", "Completed"];
        const tabType = ["Monitoring", "Other Monitoring", "Other"];
    
        await dispatch(
          retrieveFiltredTasks(
            JSON.stringify(tabType),
            JSON.stringify(state1),
            currentTimeZone
          )
        );

        await setrefreshData(!refreshData);
        setmodalManageOtherTask(!modalManageOtherTask)
      }
    }
  };

  const Cancel = async () => {
    if( window.confirm('Are you sure to cancel the task ?') ){
      let updatedTask = {};
      if (taskID) {
        updatedTask = {
          resultat: feedback,
          state: "Canceled",
        };
        await dispatch(updateTaskMember(taskID, updatedTask));

        const state1 = ["Pending", "Done", "In progress", "Canceled", "Completed"];
        const tabType = ["Monitoring", "Other Monitoring", "Other"];
    
        await dispatch(
          retrieveFiltredTasks(
            JSON.stringify(tabType),
            JSON.stringify(state1),
            currentTimeZone
          )
        );

        await setrefreshData(!refreshData);
        setmodalManageOtherTask(!modalManageOtherTask)
      }
    }
  };


  return (
    <>
    <span className="tbBtn" onClick={toggleManageOtherTask}><FiCheckCircle/></span>
    <Modal
        className="ManageOtherTaskModal"
        centered
        backdrop="static" 
        keyboard={true}
        isOpen={modalManageOtherTask}
        size="md"
        toggle={toggleManageOtherTask}
        fade={false}
      >
        <Form>
          <ModalHeader toggle={toggleManageOtherTask}>
            Update Custom Task
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>Feedback</Label>
              <Input type="textarea" onChange={(e) => setFeedback(e.target.value)}></Input>
            </FormGroup>
          </ModalBody>
          <ModalFooter className="footerMenu">
            <Button size='sm' color="success" onClick={Done}>Set as Done</Button> 
            <Button size='sm' color="danger" onClick={Cancel}>Cancel Task</Button>
            <Button size='sm' color="secondary" onClick={toggleManageOtherTask}>Dismiss</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  )
}
