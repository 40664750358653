import React, { useState, } from 'react';
import {
    Row,
    Collapse,
    InputGroup,
    Form
} from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// import schema from "./ModuleApYupValidation";
import schema from "./commentYupValidation"
import { useSelector } from 'react-redux';
import CommentsList from './CommentsList';
export default function Comments({ dispatch, feed, AddComment, FeedPic, OpenComment, setrefreshComent
    , refreshComent, isFeedOwner, isAdmin, user }) {
    const {
        handleSubmit,
        register,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const [refreshData, setrefreshData] = useState(false);
    const comments = useSelector(state => state.CommentReducer.AllComments)
    const handleAddComment = async ({ Comment }) => {
        const newcomment = {
            content: Comment
        }
        await dispatch(AddComment(feed._id, newcomment))
        await setrefreshData(!refreshData)
        await setrefreshComent(!refreshComent)
        reset()
    }
    return (
        <div className="feedReplies">
            <Collapse isOpen={OpenComment}>
                <div className="collapseInner">
                    <Form onSubmit={handleSubmit(handleAddComment)}>
                        <InputGroup>
                            <input
                                type="text"
                                name="Comment"
                                placeholder={errors.Comment ? errors.Comment.message : "Enter comment..."}
                                className={
                                    "form-control" + (errors.Comment ? " is-invalid" : "")
                                }
                                {...register("Comment")}
                            />
                            <small className="invalid-feedback">
                                {errors.title && <>{errors.title.message}</>}
                            </small>
                        </InputGroup>
                        <Row>
                        </Row>
                    </Form>
                    <CommentsList
                        comments={comments}
                        FeedPic={FeedPic}
                        feed={feed}
                        OpenComment={OpenComment}
                        setValue={setValue}
                        handleSubmit={handleSubmit}
                        register={register}
                        reset={reset}
                        refreshData={refreshData}
                        setrefreshData={setrefreshData}
                        isFeedOwner={isFeedOwner}
                        isAdmin={isAdmin}
                        user={user}
                    />
                    <div>

                    </div>

                </div>
            </Collapse>
        </div>
    );
}
