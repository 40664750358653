import http from "../../../../../../api";

  const getResultHistory = (mapId, timeZone, data) => {
        return http.post(`/api/statistics/resultHistory/?mapId=${mapId}&timeZone=${timeZone}`, data);
  };

const resultHistoryService = {
    getResultHistory
};
export default resultHistoryService;
