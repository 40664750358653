import http from "../api";

const getAllSyncShift = () => {
  return http.get(`/api/shiftSyncLog/getLastShiftSyncLog`);
};

const createSyncShift = () => {
  return http.patch(`/api/shift/sync`);
  // if (httpReq) {
  //   cb(httpReq.data.message);
  //   return httpReq;
  // }
};

const syncShiftService = {
  getAllSyncShift,
  createSyncShift,
};

export default syncShiftService;
