import types from "../actions/types";
const {
  AUTH_LOADING,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  MICROSOFT_LOGIN_SUCCESS,
  MICROSOFT_LOGIN_FAILURE,
  REGISTER_FAILURE,
  REGISTER_SUCCESS,
  LOGOUT,
  GET_AUTHORIZATION_SUCCESS,
  GET_AUTHORIZATION_FAILURE
} = types.authTypes;

let token = localStorage.getItem('ma-access-token');

const initialState = token ?{loggedIn: true, isLoading: false, listModules: null, listPermissions: null }:
 {loggedIn: false, isLoading: false, listModules: null, listPermissions: null };

export default function authentication(state = initialState, action) {
  const { type,payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case REGISTER_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case AUTH_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loggedIn: true
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case MICROSOFT_LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loggedIn: true
      };
    case MICROSOFT_LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case GET_AUTHORIZATION_SUCCESS:
      return {
        ...state,
        listModules: payload.listModule,
        listPermissions: payload.listAction,
        isLoading: false,
        loggedIn: true
      };
    case GET_AUTHORIZATION_FAILURE:
      return {
        ...state,
        loggedIn: false,
        isLoading: false
      };
    case LOGOUT:
      return {
        ...state,
        loggedIn: false,
        isLoading: false,
        listModules: null,
        listPermissions: null
      };
    default:
      return state
  }
}