import types from "./types";
import mapService from "../services/mapService";
import { message } from 'antd';

const {
  LOAD_MAP,
  GET_ALL_MAPS_SUCCESS,
  GET_ALL_MAPS_FAILURE,
  CREATE_MAP_SUCCESS,
  CREATE_MAP_FAILURE,

  RETRIEVE_ONE_MAP_SUCCESS,
  RETRIEVE_ONE_MAP_FAILURE,
  UPDATE_MAP_SUCCESS,
  UPDATE_MAP_FAILURE,

  DELETE_MAP_SUCCESS,
  DELETE_MAP_FAILURE,

  CLEAR_MAPS,
  CLEAR_ONE_MAP

} = types.mapTypes;

export const retrieveMaps = (system_Id, timeZone, page, size, searchValue) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_MAP,
    });
    const res = await mapService.getAllMap(system_Id, timeZone, page, size, searchValue);
    dispatch({
      type: GET_ALL_MAPS_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {
      dispatch({
        type: GET_ALL_MAPS_FAILURE,
        payload: err,
      });
  }
};

export const createMap = (newMap, timeZone) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_MAP,
    });
    const res = await mapService.createMap(newMap, timeZone);
     message.success("Created Successfully",3);
    dispatch({
      type: CREATE_MAP_SUCCESS,
      payload: res?.data?.data,
    });
  } catch (err) {
    message.error('An Error occurred while Creating',3);
    dispatch({
      type: CREATE_MAP_FAILURE,
      payload: err,
    });
  }
};


export const RetreiveOneMap = (id, timeZone) => async (dispatch) => {
  try {
    const res = await mapService.getOneMap(id, timeZone);
    dispatch({
      type: RETRIEVE_ONE_MAP_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {
    dispatch({
      type: RETRIEVE_ONE_MAP_FAILURE,
      payload: err.response.data.message,
    });
  }
};

export const updateMap = (id, data, timeZone) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_MAP,
    });
    const res = await mapService.updateMap(id, data, timeZone);
     message.success("Updated Successfully",3);
    dispatch({
      type: UPDATE_MAP_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    message.error('An Error occurred while updating',3);
    dispatch({
      type: UPDATE_MAP_FAILURE,
      payload: err?.response?.data?.message,
    });
  }
};
export const deleteMap = (id, system_Id, page, size) => async (dispatch) => {
  try {
    // console.log("Action 111111");
    const res = await mapService.deleteMap(id);
    dispatch({
      type: LOAD_MAP,
    });
    message.success("Deleted Successfully",3);
    dispatch({
      type: DELETE_MAP_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    // console.log(err);
    message.error('An Error occurred while deleting',3);
    dispatch({
      type: DELETE_MAP_FAILURE,
      payload: err.response.data.message,

    });
  }
};

 //clear Maps
 export const clearMaps = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_MAPS,
  })
}

//clear One Map
export const clearOneMap = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_ONE_MAP,
  })
}
