import React, { useState,useEffect } from "react";

import Select from "../../../shared/select/Select";
import "./TaskManagementMAPmodal.css";
import { useDispatch, useSelector } from "react-redux";
import { Switch, TimePicker } from "antd";
import { getCreatedTask, retreiveCategories, retreiveCustomer, retreiveMembers, retreiveSystems, retreiveTypes, retrieveFiltredTasks, updateTask, getSystemStatsForHighlight } from "../../../actions/taskAction";
import moment from "moment"
import {
  Row,
  Col,
  Input,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Modal,
  Badge,
  Spinner
} from "reactstrap";

const UpdateMapTasks = ({type,state,currentTimeZone,searchValue}) => {
  const [ShowModal, setShowModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState([]);
  const [SwitchActivities, setSwitchActivities] = useState(false);
  const toggleSwitchActivities = () => {
    //reset the new Assignee field
    setnewAssigneeId(null);
    setSwitchActivities(!SwitchActivities);
  };
  // const { RangePicker } = TimePicker;

  const dispatch = useDispatch();
 

  let [optionsCustomers, setoptionsCustomers] = useState([])
  let [optionsSystems, setoptionsSystems] = useState([])
  let [customerId, setcustomerId] = useState(null)
  let [typeId, settypeId] = useState(null)
  let [categoryId, setcategoryId] = useState(null)
  let [systemId, setsystemId] = useState(null)
  let [AssigneeId, setAssigneeId] = useState()
  let [optionTypes, setoptionTypes] = useState([])
  let [optionCategories, setoptionCategories] = useState([])
  let [endDate, setendDate] = useState(null)
  let [startDate, setstartDate] = useState(null)
  let customer = useSelector(state => state.taskReducer.AllCustomers.docs)
  let isLoadingCustomer = useSelector(state => state.taskReducer.isLoadingCustomer)
  let systems = useSelector(state => state.taskReducer.AllSystems)
  let isLoadingSystem = useSelector(state => state.taskReducer.isLoadingSystem)
  let AllCategories = useSelector(state => state.taskReducer.AllCategories)
  let isLoadingCategories = useSelector(state => state.taskReducer.isLoadingCategories)
  let AllTypes = useSelector(state => state.taskReducer.AllTypes)
  let isLoadingTypes = useSelector(state => state.taskReducer.isLoadingTypes)
  let members = useSelector(state => state.taskReducer.AllMembers)
  let isLoadingMembers = useSelector(state => state.taskReducer.isLoadingMembers)
  let [optionMembers, setoptionMembers] = useState([])
  let teamLeaderId = useSelector(state => state.user.user?._id)
  let timeZone = useSelector(state => state.user.timeZone)
  let Tasks = useSelector(state => state.taskReducer.createdTasks)
  let isloadingTasks = useSelector(state => state.taskReducer.isloadingCreatedTasks)
  const userId = useSelector((state) => state.user.user?._id);
  const [AllTasks, setAllTasks] = useState([])
  const [refreshData, setrefreshData] = useState(false);
  const toggleAdd = () => {
    if (window.confirm("Are you sure you want to leave?")) 
      setShowModal(!ShowModal);
  };
  const handleSelectTask = (data) => {
    const values = [...selectedTask];
    if (selectedTask.findIndex((ts)=> 
    (ts?.map === data?.map )&&
    (ts?.estimatedStart === data?.estimatedStart)
    )!== -1) {
      values.splice(selectedTask.findIndex((ts)=> 
      (ts?.map === data?.map )&&
      (ts?.estimatedStart === data?.estimatedStart)
      
      ), 1);
    } else {
      values.push(data);
    }
    setSelectedTask(values);
 
  };
  const handleSelectAllTask = () => {
    const values = [...Tasks]
    if (selectedTask.length < Tasks.length) {
      setSelectedTask(values);
    } else {
      setSelectedTask([]);
    }
  };

  useEffect(() => {
    if(ShowModal===false){
    setSelectedTask([])
    setoptionsCustomers()
    setoptionsSystems()
    setcustomerId()
    settypeId()
    setcategoryId()
    setsystemId()
    setAssigneeId()
    setoptionTypes()
    setoptionCategories()
    setendDate()
    setstartDate()
    setAllTasks([])
    setnewAssigneeId()
    }
  }, [ShowModal])
useEffect(() => {
  dispatch(retreiveCustomer(false, null, null, true))
  dispatch(retreiveSystems())
  teamLeaderId && dispatch(retreiveMembers(teamLeaderId));
}, [dispatch, ShowModal, teamLeaderId]);

useEffect(() => {
  dispatch(retreiveSystems(customerId?.value,typeId?.value,categoryId?.value,systemId?.value))
}, [dispatch,customerId,typeId,categoryId,systemId])

useEffect(() => {
  if(customerId&&typeId) dispatch(retreiveCategories(customerId?.value,typeId?.value))
}, [dispatch,customerId,typeId])
useEffect(() => {
  customerId&& dispatch(retreiveTypes(customerId?.value))
}, [dispatch,customerId])
function handleToggleModal() {

  setoptionsCustomers(customer?.map((cust)=>({
    value : cust._id,
    label : cust.label
  })))
  setoptionsSystems(systems?.map((sys)=>({
    value : sys._id,
    label : sys.name
  })))
  setoptionMembers(members?.map((mem)=>({
    value:mem?._id,
    label : mem?.firstName + " " + mem?.lastName
  })))
  setShowModal(!ShowModal)
}

useEffect(() => {
 setoptionTypes(AllTypes.map((type)=>({
  value: type._id, 
  label :type.type
 })))
 setoptionCategories(AllCategories.map((cat)=>({
   value: cat._id,
   label: cat.category
 })))
 setoptionsCustomers(customer?.map((cust)=>({
  value : cust._id,
  label : cust.label
})))
setoptionsSystems(systems?.map((sys)=>({
  value : sys._id,
  label : sys.name
})))
}, [AllTypes,AllCategories,customer,systems])
function handleCustomer(e) {
  if(e){
    setcustomerId(e)
  
    if(customerId && e.value !== customerId.value){
      settypeId(null)
      setsystemId(null)
      setcategoryId(null)
      setSelectedTask([]);
      setAllTasks([])
    }
    
  }else{
    setcustomerId(null)
    settypeId(null)
    setsystemId(null)
    setcategoryId(null)
    setSelectedTask([]);
    setAllTasks([])
  }
}
function handleAssignee(e) {

  if(e){
    setAssigneeId(e)

    if(AssigneeId && e.value !== AssigneeId.value) {
      setSelectedTask([])
      setAllTasks([])
    }

  }else{
    setAssigneeId()
    setSelectedTask([])
    setAllTasks([])
  }
}
const [newAssigneeId, setnewAssigneeId] = useState()
function handleNewAssignee(e) {
  if(e){
    setnewAssigneeId(e)
  }else{
    setnewAssigneeId(null)
  }
}
function handleType(e) {
  if(e){
    settypeId(e)
    
    if(typeId && e.value !== typeId.value){
      setsystemId(null)
      setcategoryId(null)
      setSelectedTask([])
      setAllTasks([])
    }
  }else{
    settypeId(null)
    setsystemId(null)
    setcategoryId(null)
    setSelectedTask([])
    setAllTasks([])
  }
}
function handleCategory(e) {
  if(e){
    setcategoryId(e)

    if(categoryId && e.value !== categoryId.value){
      setsystemId(null)
      setSelectedTask([])
      setAllTasks([])
    }
  }else{
    setcategoryId()
    setsystemId(null)
    setSelectedTask([])
    setAllTasks([])
  }
}

const handleSystem = (e) => {
  if(e){
    setsystemId(e)

    if(systemId && e.value !== systemId.value){
      setSelectedTask([])
      setAllTasks([])
    }
  } else {
    setsystemId()
    setSelectedTask([])
    setAllTasks([])
  }
}
function handleStartDate(e) {
  setSelectedTask([])
  setAllTasks([])

  if(e){
    setstartDate(moment(e?._d).format("YYYY-MM-DDTHH:mm"))
  }else{
    setstartDate()
  }
}
function handleEndDate(e) {
  setSelectedTask([])
  setAllTasks([])
  
  if(e){
    setendDate(moment(e?._d).format("YYYY-MM-DDTHH:mm"))
  }else{
    setendDate()
  }
}
const handleGenerateTasks = ()=>{
  setSelectedTask([]);
  setAllTasks([])
  dispatch(getCreatedTask(customerId?.value,typeId?.value,categoryId?.value,systemId?.value,AssigneeId?.value,timeZone,teamLeaderId,startDate,endDate))
}
const [isloading, setisloading] = useState(false)
useEffect(() => {
  setAllTasks(Tasks)
  setisloading(isloadingTasks)
}, [Tasks,isloadingTasks])

const handleCreateTasks=()=>{
  const tasks = selectedTask.map((task)=>({taskId : task._id, estimatedStart: task.estimatedStart}))
  let updatedTask={};
  if(SwitchActivities===true){
    updatedTask = {
      taskObjects : tasks,
      toCancel : SwitchActivities
    }
  }else{
    updatedTask = {
      taskObjects : tasks,
      assigneeId : newAssigneeId?.value,
      teamLeaderId
    }
  }

dispatch(updateTask(updatedTask))
dispatch(getSystemStatsForHighlight(timeZone, userId));
dispatch(getCreatedTask(customerId?.value,typeId?.value,categoryId?.value,systemId?.value,AssigneeId?.value,timeZone,teamLeaderId,startDate,endDate))
dispatch(
  retrieveFiltredTasks(
    JSON.stringify([type]),
    JSON.stringify(state),
    currentTimeZone,
    searchValue
  )
);
setSelectedTask([])
setnewAssigneeId(null)
setSwitchActivities(false)
setrefreshData(!refreshData);
}
function handleCheckbox(data) {

  for (let i = 0; i < selectedTask.length; i++) {
if((selectedTask[i]?.map === data?.map )&&
      (selectedTask[i]?.estimatedStart === data?.estimatedStart)
  ){
    return true
  }
  }
  return false
}

useEffect(() => {
  const state1 = [
    "Pending",
    "Done",
    "In progress",
    "Canceled",
    "Completed",
  ];
  const tabType=[
    "Monitoring",
    "Other Monitoring",
    "Other"
  ]
  dispatch(
    retrieveFiltredTasks(
      // JSON.stringify(type),
      JSON.stringify(tabType),
      JSON.stringify(state1),
      timeZone,
      //searchValue
    )
  );
   // eslint-disable-next-line react-hooks/exhaustive-deps
}, [dispatch, timeZone, refreshData]);

  return (
    <>
      <Button
        color="primary"
        onClick={handleToggleModal}
      >
        Update Tasks
      </Button>

      <Modal
        centered
        isOpen={ShowModal}
        toggle={toggleAdd}
        backdrop="static"
        keyboard={true}
        size="xl"
        fade={false}
        className="TaskManagementMAPmodal TaskManagementMAPmodalUpdate"
      >
        <ModalHeader toggle={toggleAdd}>
           Update Tasks
          <Row className="modalRow">
            <Col xs="4">
            <FormGroup>
                <Select
                  isClearable
                  isLoading={isLoadingCustomer}
                  value={customerId}
                  options={optionsCustomers}
                  placeholder="Customer"
                  onChange={(e) => handleCustomer(e)}
                />
              </FormGroup>
            </Col>
            <Col xs="2">
            <FormGroup>
                <Select
                  isClearable
                  value={typeId}
                  isDisabled={customerId ? false : true}
                  isLoading={isLoadingTypes}
                  options={optionTypes}
                  placeholder="Type"
                  onChange={(e) => handleType(e)}
                />
              </FormGroup>
            </Col>
            <Col xs="3">
            <FormGroup>
                <Select
                  isClearable
                  isDisabled={typeId ? false : true}
                  isLoading={isLoadingCategories}
                  options={optionCategories}
                  placeholder="Category"
                  value={categoryId}
                  onChange={(e) => handleCategory(e)}
                />
              </FormGroup>
            </Col>
            <Col xs="3">
            <FormGroup>
                <Select
                  isClearable
                  options={optionsSystems}
                  isLoading={isLoadingSystem}
                  value={systemId}
                  placeholder="System"
                  onChange={handleSystem}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="modalRow">
            <Col xs="2">
            <FormGroup>
                <TimePicker
                  className="TimePickerWidth"
                  placeholder="Start Time"
                  format="HH:mm"
                  onChange={(e) => handleStartDate(e)}
                />
              </FormGroup>
            </Col>
            <Col xs="2">
            <FormGroup>
                <TimePicker
                  className="TimePickerWidth"
                  placeholder="End Time"
                  format="HH:mm"
                  onChange={(e) => handleEndDate(e)}
                />
              </FormGroup>
            </Col>
            <Col xs="5">
              <FormGroup>
              <FormGroup>
                <Select
                  isClearable
                  placeholder="Assignee"
                  isLoading={isLoadingMembers}
                  value={AssigneeId}
                  options={optionMembers}
                  onChange={(e) => handleAssignee(e)}
                />
              </FormGroup>
              </FormGroup>
            </Col>
            <Col xs="3">
            <FormGroup>
                <Button
                  disabled={
                    (AssigneeId && customerId) || (AssigneeId && systemId)
                      ? false
                      : true
                  }
                  className="generateTasksButton"
                  color="info"
                  outline
                  onClick={handleGenerateTasks}
                >
                  Get Created Tasks
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </ModalHeader>

        <ModalBody className="ModalBodyScrollable">
          <Row className="modalRow selectAllCheckbox">
            <Col xs="2">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={selectedTask?.length === AllTasks?.length}
                    onChange={() => handleSelectAllTask()}
                  />
                  Select All
                </Label>
              </FormGroup>
            </Col>

            <Col xs="4">
              <Label className="UpdateTaskSwitcher">
                <Switch
                  checked={SwitchActivities}
                  onChange={toggleSwitchActivities}
                />
                <span>Cancel Tasks</span>
              </Label>
            </Col>

            {!SwitchActivities && (
              <Col xs="6">
                <Select placeholder="New Assignee" 
                  isClearable
                
                  isLoading={isLoadingMembers}
                  value={newAssigneeId}
                  options={optionMembers}
                  onChange={(e) => handleNewAssignee(e)}
                />
              </Col>
            )}
          </Row>
          <div className="TaskManagementList">
            {isloading === false ? (
              <>
                {AllTasks && AllTasks?.length > 0 ? (
                  <>
                    {AllTasks.map((data, index) => {
                      return (
                        /* class distinction is useless for now (no MTP flag from db tasks) */
                        <div className={data?.isMTP && data.isMTP === true ? "isMTP_Tasks" : "tasksColor"} key={index}>
                          <Row
                            className="Card_task"
                            key={index}
                            onClick={(e) => handleSelectTask(data)}
                          >
                            <Col>
                              <FormGroup inline check>
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    checked={handleCheckbox(data)}
                                    onChange={(e) =>
                                      handleSelectTask(data)
                                    }
                                  />
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col><Badge>{data.title}</Badge> </Col>
                            <Col>{data.system ? data.system.customer.label : '--'}</Col>
                            <Col>{data.system ? data.system.name : '--'}</Col>
                            <Col>{data.description}</Col>
                            <Col>{moment(data.estimatedStart).tz(timeZone).format("LT")}</Col>
                           
                          </Row>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <span className="NoTasksAvailable">Tasks to update</span>
                )}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 100,
                }}
              >
                <Spinner color="dark" />
              </div>
            )}
          </div>
     
        </ModalBody>

        <ModalFooter>
          {SwitchActivities === true 
          ? 
              <Button color="primary" disabled={selectedTask.length>0  ? false : true} onClick={handleCreateTasks}>Save Changes</Button>
          :
              <Button color="primary" disabled={selectedTask.length>0  && newAssigneeId?.value !=null ? false : true} onClick={handleCreateTasks}>Save Changes</Button>
          }
          <Button color="secondary" onClick={toggleAdd}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default UpdateMapTasks;
