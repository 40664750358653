import types from "./types";
import categoryService from "../services/categoryService";
import { message } from "antd";
const {
  GET_ALL_CATEGORY_SUCCESS,
  GET_ALL_CATEGORY_FAILURE,
  LOAD_CATEGORY,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAILURE,
  RETRIEVE_ONE_CATEGORY_SUCCESS,
  RETRIEVE_ONE_CATEGORY_FAILURE,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILURE,
  CLEAR_CATEGORYS
} = types.categoryTypes;

export const retrieveCategory = (page, size, isActive, searchValue) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_CATEGORY,
    });

    const res = await categoryService.getAll(page, size, isActive, searchValue);
    dispatch({
      type: GET_ALL_CATEGORY_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data.docs;
  } catch (err) {
    dispatch({
      type: GET_ALL_CATEGORY_FAILURE,
      payload: err,
    });
  }
};

export const createCategory = (category) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_CATEGORY,
    });
    const res = await categoryService.createCategory(category);
    message.success("Successfully created the system category");
    dispatch({
      type: CREATE_CATEGORY_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    message.error("Failed to create the system category");
    dispatch({
      type: CREATE_CATEGORY_FAILURE,
      payload: err,
    });
  }
};

export const RetreiveOneCategory = (id) => async (dispatch) => {
  try {
    // dispatch({
    //   type: LOAD_CATEGORY,
    // });
    const res = await categoryService.getOneCategory(id);
    dispatch({
      type: RETRIEVE_ONE_CATEGORY_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {
    dispatch({
      type: RETRIEVE_ONE_CATEGORY_FAILURE,
      payload: err.response.data.message,
    });
  }
};

export const updateCategory= (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_CATEGORY,
    });
    const res = await categoryService.updateCategory(id, data);
    message.success("Successfully updated the system category");
    dispatch({
      type: UPDATE_CATEGORY_SUCCESS,
      payload: res.data.data,
    });

    // const resAll = await categoryService.getAll(page, size);
    // dispatch({
    //   type: GET_ALL_CATEGORY_SUCCESS,
    //   payload: resAll.data.data,
    // });

  } catch (err) {
    message.error("Failed to update the system category");
    dispatch({
      type: UPDATE_CATEGORY_FAILURE,
      payload: err?.response?.data?.message,
    });
  }
};

 //clear Data
 export const clearCategorys = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_CATEGORYS,
  })
}
