import types from "./types";
import globalReportService from "../services/globalReportService";
import resultService from "../services/resultService"
const {
    GET_REPORT_LAYOUT_SUCCESS,
    GET_REPORT_LAYOUT_FAILURE,
    LOAD_REPORT_LAYOUT,
    CLEAR_REPORT_LAYOUT,
    LOAD_GET_RESULT_REPORT,
    GET_RESULT_REPORT_SUCCESS,
    GET_RESULT_REPORT_FAILURE,
    GET_ONE_RESULT_LOADER_REPORT,
    GET_ONE_RESULT_REPORT_SUCCESS,
    GET_ONE_RESULT_REPORT_FAILURE,
    CLEAR_GLOBAL_REPORT
} = types.globalReportTypes;


export const retreiveLayoutReport = (customerId,types,categories) => async (dispatch) => {

  try {
    dispatch({
      type: LOAD_REPORT_LAYOUT,
    });
    const res = await globalReportService.getLayoutReport(customerId,types,categories);
    dispatch({
      type: GET_REPORT_LAYOUT_SUCCESS,
      payload: res.data.data,
    });
    // return res.data.data;
  } catch (err) {
    dispatch({
      type: GET_REPORT_LAYOUT_FAILURE,
      payload: err,
    });
  }
};
export const clearLayout = () => (dispatch) => {
  dispatch({
    type: CLEAR_REPORT_LAYOUT,
  });
  
};



export const getDataReport = (filter) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_GET_RESULT_REPORT,
    });
    const res = await globalReportService.getReportResult(filter);
    dispatch({
      type: GET_RESULT_REPORT_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_RESULT_REPORT_FAILURE,
      payload: err,
    });
  }
};


export const getOneResultForReport = (resultId) => async (dispatch) => {
  try {
      dispatch({
          type: GET_ONE_RESULT_LOADER_REPORT,
      });
      const res = await resultService.fetOneResultById(resultId); // Change Service Get Result here 
      dispatch({
          type: GET_ONE_RESULT_REPORT_SUCCESS,
          payload: res.data.data
      })
      return res.data.data;
  } catch (err) {
      dispatch({
          type: GET_ONE_RESULT_REPORT_FAILURE,
          payload: err.response?.data?.message
      });
      return null;
  }
}

export const clearGlobalReport = () => (dispatch) => {
  dispatch({
    type: CLEAR_GLOBAL_REPORT,
  });
  
};