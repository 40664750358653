const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS";
const CREATE_CUSTOMER_FAILURE = "CREATE_CUSTOMER_FAILURE";
const GET_ALL_CUSTOMERS_SUCCESS = "GET_ALL_CUSTOMERS_SUCCESS";
const GET_ALL_CUSTOMERS_FAILURE = "GET_ALL_CUSTOMERS_FAILURE";
const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
const UPDATE_CUSTOMER_FAILURE = "UPDATE_CUSTOMER_FAILURE";
const LOAD_CUSTOMER = "LOAD_CUSTOMER";
const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
const GET_CUSTOMER_FAILURE = "GET_CUSTOMER_FAILURE";
const LOAD_CUSTOMERS = "LOAD_CUSTOMERS";

const GET_CUSTOMERS_SYSTEMS_INFO_SUCCESS = "GET_CUSTOMERS_SYSTEMS_INFO_SUCCESS";
const GET_CUSTOMERS_SYSTEMS_INFO_FAILURE = "GET_CUSTOMERS_SYSTEMS_INFO_FAILURE";
const LOAD_CUSTOMERS_SYSTEM_INFO = "LOAD_CUSTOMERS_SYSTEM_INFO";
const CLEAR_CUSTOMERS = "CLEAR_CUSTOMERS";


let customerTypes = {
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAILURE,
  GET_ALL_CUSTOMERS_SUCCESS,
  GET_ALL_CUSTOMERS_FAILURE,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
  LOAD_CUSTOMER,
  LOAD_CUSTOMERS,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAILURE,
  GET_CUSTOMERS_SYSTEMS_INFO_SUCCESS,
  GET_CUSTOMERS_SYSTEMS_INFO_FAILURE,
  LOAD_CUSTOMERS_SYSTEM_INFO,
  CLEAR_CUSTOMERS
};
export default customerTypes;
