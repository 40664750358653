import React from "react";
import useGlobalReport from '../logic/useGlobalReport';
import './globalReport.css';
import Select from 'react-select';
import {Row, Col, Table, FormGroup, Card, CardBody, CardTitle, Spinner, Button} from "reactstrap";

import { FiDownload } from "react-icons/fi";

import { DatePicker } from "antd";
import ModalTaskDetails from "../components/ModalTaskDetails";



const GlobalReport = () => {
  
  const {
    moment,
    isLoadingDataReport,
    customer,
    DataReport,
    NewDataReport,
    globalReducerMonitoringActivites,
    DateNow,
    reRender,
    customerOptions,
    defaultSelectedValue,
    categoryIds,
    categoryOptions,
    typeIds,
    typesOptions,
    oneResult,
    IsLoadingResult,
    modalTaskDetails,
    TaskData,
    system,

    renderSystemRow,
    handlechangecustomer,
    handleDate,
    handlechangeCategory,
    handlechangeType,
    exportToCSV,
    toggleModalTaskDetails,
    setModalTaskDetails
  } = useGlobalReport();

  return (
    <>
      <section className="TitlePage">
        Global Report
      </section>
      
      <section className="avxWrpContentPage GlobalReportPage">
        <Row>
          <Col sm="10">
            <div id="GlobalReportTableTCONT" className="avxTableContainer scrollTb">
              {!isLoadingDataReport === false ? (
                <div style={{display:"flex", alignItems:"center", justifyContent:"center", height:"100%"}}>
                  <Spinner color="dark" />
                </div>
              ) : (
                customer ? (
                  DataReport?.length > 0 && NewDataReport?.length > 0 ? (
                    <Table className="GlobalReportTable avxBsTable" striped hover >
                      <thead>
                        <tr>
                          <th>System</th>
                          {globalReducerMonitoringActivites.map((monitoringActivity, idx) => <th style={{whiteSpace:"nowrap"}} key={idx}>{monitoringActivity.name}</th>)}
                        </tr>
                      </thead>
                      <tbody className="forPointer">
                        {NewDataReport?.map((data, i) => {
                          return (
                            <tr key={i}>
                              <td key={i+1} className="systemRow">{data.systemName}</td>
                              {renderSystemRow(data)}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  ) : (
                    <div style={{display:"flex", alignItems:"center", justifyContent:"center", height:"100%", opacity:.5}}>No matching data !</div>
                  )
                ) : (
                  <div style={{display:"flex", alignItems:"center", justifyContent:"center", height:"100%", opacity:.5}}>Select Customer to generate Report !</div>
                )
              )}
            </div>
          </Col>

          <Col xs="2" className="filter-section">
            <Card className="filterCardSection">
              <CardBody>
                <CardTitle tag="h5">{DateNow}</CardTitle>
                <FormGroup>
                  <Select key={reRender} placeholder="Customer" options={customerOptions} defaultValue={defaultSelectedValue ? defaultSelectedValue : null} value={customer} onChange={(e)=>handlechangecustomer(e)} />
                </FormGroup>
                <FormGroup>
                  <DatePicker className="DateRangePicker" format="YYYY-MM-DD HH:mm:ss" showTime={{defaultValue:moment("23:59:59", "HH:mm:ss")}} onChange={(e)=>handleDate(e)} />
                </FormGroup>
                <FormGroup>
                  <Select placeholder="Category" isMulti value={categoryIds} options={categoryOptions} onChange={(e)=>handlechangeCategory(e)} />
                </FormGroup>
                <FormGroup>
                  <Select placeholder="Type" isMulti value={typeIds} options={typesOptions} onChange={(e)=>handlechangeType(e)} />
                </FormGroup>
                <FormGroup>
                  { DataReport?.length > 0 && NewDataReport?.length > 0 && ( <Button color="primary" onClick={exportToCSV}><FiDownload/> {" "}Export data</Button> ) }
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {oneResult && <ModalTaskDetails
          oneResult={oneResult}
          IsLoadingResult={IsLoadingResult}
          toggleModalTaskDetails={toggleModalTaskDetails}
          modalTaskDetails={modalTaskDetails}
          setModalTaskDetails={setModalTaskDetails}
          TaskData={TaskData}
          system={system}
        />}

      </section>
    </>
  );
};

export default GlobalReport;
