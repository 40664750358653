import * as Yup from "yup";
const schema = Yup.object().shape({
    label: Yup.string().required("The Role Name is Required."),
    rank: Yup.number().typeError('The Rank is required and must specify a number'),
    isActive: Yup.boolean().required("The Status is required."),

    listModule: Yup.array()
    .min(2, "Minimum 2 Role Module is Required.")
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required("Value of Module is Required"),
      })
    )
    .nullable()
    .required("Role Module is Required."),

    // listModule: Yup.object().shape({
    //     label: Yup.string().required(),
    //     value: Yup.string().required("The Module is required."),
    //   }),
  
});
export default schema;
