import http from "../api";

const getAllUsers = (page, size, searchValue) => {
  return searchValue ?  http.get(
    `/api/user/getAllUsers/?page=${page}&size=${size}&searchValue=${searchValue}`
  )
  : 
  http.get(
    `/api/user/getAllUsers/?page=${page}&size=${size}`
  )

};

const changeStatusUser  = (id, status) => {
  return http.patch(
    `/api/user/activateUser/${id}?reqStatus=${status}`
  );
};

const deleteUser = (id) => {
  return http.patch(`/api/user/deleteUser/${id}`);
};

const getOneUser = (id) => {
  return http.get(`/api/user/getOne/${id}`);
};

const updateUser = (id, user) => {
  return http.patch(`/api/user/update/${id}`, user);
};

const userManagementService = {
    getAllUsers,
    changeStatusUser,
    deleteUser,
    getOneUser,
    updateUser,
  
};
export default userManagementService;