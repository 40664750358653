import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Input,
  Table,
  FormGroup,
  Label,
  Badge,
  // Button,
  Spinner,
} from "reactstrap";
import { TablePagination } from "@material-ui/core";
import "./AllMonitoringActivitiesDetaills.css";
import { processTableHeight, makeDelay } from "../../../../assets/js/CoreJs";
// import Select from "react-select";
import { DatePicker } from "antd";
import moment from "moment";
import { retrieveAllTasksByCriteria, clearTasksTracking } from "../../../../actions/taskAction";
import { getOneResultForReport } from "../../../../actions/globalReportAction";
import useTaskTracking from '../../logic/useTaskTracking';
import ModalTaskDetailsTaskTracking from "../ModalTaskDetailsTaskTracking";


export default function AllMonitoringActivitiesDetaills() {

  const {
    oneResult,
    IsLoadingResult,
    TaskData,
  } = useTaskTracking();


  const dispatch = useDispatch();

  const applyTabHeight = () => {
    processTableHeight(
      [
        "TitlePage",
        "taskTrackingTabsNav",
        "AllMonitoringActDetaillsHeader",
        "AllMonitoringActDetaillsPaginate",
      ],
      "MyTasksListTableTCONT",
      15
    );
  };

  useEffect(() => {
    applyTabHeight();
    window.addEventListener("resize", applyTabHeight);

    return () => {
      window.removeEventListener("resize", applyTabHeight);
    };
  }, []);
  const [refreshData, setrefreshData] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const currentTimeZone = useSelector((state) => state.user.timeZone);
  const allTasksByCriteria = useSelector(
    (state) => state.taskReducer.tasksByCriteria.docs
  );
  //console.log("allTasksByCriteria ===> ", allTasksByCriteria);

  const totalDocs = useSelector(
    (state) => state.taskReducer.tasksByCriteria?.totalDocs
  );
  //console.log("totalDocs ---->", totalDocs);

  const numberOfTasksPending = useSelector(
    (state) => state.taskReducer.tasksByCriteria.numberOfTasks?.Pending
  );
  const numberOfTasksDone = useSelector(
    (state) => state.taskReducer.tasksByCriteria.numberOfTasks?.Done
  );
  const numberOfTasksInProgress = useSelector(
    (state) => state.taskReducer.tasksByCriteria.numberOfTasks?.In_Progress
  );
  const numberOfTasksCompleted = useSelector(
    (state) => state.taskReducer.tasksByCriteria.numberOfTasks?.Completed
  );
  const numberOfTasksCanceled = useSelector(
    (state) => state.taskReducer.tasksByCriteria.numberOfTasks?.Canceled
  );
  //console.log("numberOfTasks Done ===> ", numberOfTasksDone);

  const isLoadingTasksByCriteria = useSelector(
    (state) => state.taskReducer.isLoadingTasksByCriteria
  );
  const error = useSelector((state) => state.taskReducer.listError);

  const { RangePicker } = DatePicker;

  // const [startDate, setStartDate] = useState("2022-01-28T14:00:00.000Z");
  // const [endDate, setEndDate] = useState("2022-01-28T16:00:00.000Z");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [state, setState] = useState([
    "Pending",
    "Done",
    "In progress",
    "Canceled",
    "Completed",
  ]);
  const [type, setType] = useState(["Monitoring"]);
  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    const filter = {
      type: type,
      state: state,
      timeZone: currentTimeZone,
      startDate: startDate,
      endDate: endDate,
    };
    startDate &&
      endDate &&
      dispatch(
        retrieveAllTasksByCriteria(
          page + 1,
          rowsPerPage,
          true,
          searchValue,
          filter
        )
      );
  }, [
    dispatch,
    type,
    state,
    currentTimeZone,
    startDate,
    endDate,
    page,
    rowsPerPage,
    searchValue,
    refreshData,
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setrefreshData(!refreshData);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setrefreshData(!refreshData);
  };

  const genPillColor = (taskStatus) => {
    const pillColor = {
      done: "success",
      pending: "warning",
      canceled: "danger",
      "in progress": "primary",
      completed: "info",
    }[taskStatus];
    return pillColor;
  };

  const genPillColorGS = (taskStatusGS) => {
    const pillColorGS = {
      good: "#28a745",
      warning: "#ff9c07",
      critical: "#dc3545",
    }[taskStatusGS];
    return pillColorGS;
  };

  const checkChange = () => {
    const newStates = [];
    const filters = document.querySelectorAll(".flCheck");
    filters.forEach((input) => {
      input.checked && newStates.push(input.value);
    });
    setState(newStates);
    setPage(0);
  };

  const handelSearshQuerry = (e) => {
    makeDelay(() => {
      if (e.target.value.length >= 2) {
        setSearchValue(e.target.value);
        setPage(0);
      } else {
        setSearchValue("");
      }
    }, 750);
  };

  function handleDate(e) {
    //console.log(e);
    //console.log("Event ===>", moment(e[0]));
    //console.log("Event2 ===>", moment(e[1]));
    if (e) {
      setStartDate(e[0]);
      setEndDate(e[1]);
    } else {
      setStartDate();
      setEndDate();
    }
  }

  //Clear Data
  useEffect(() => {
    return () => {
      dispatch(clearTasksTracking());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 

  const [TaskDataTitle, setTaskDataTitle] = useState("")
  const [system, setsystem] = useState("")
  const [modalTaskDetails, setModalTaskDetails] = useState(false)
  async function handleModalShow(e,data) {
      
    await setsystem(data.systemName)
    await setTaskDataTitle(data.title)
    //console.log(data);
    if(data.resultId!==false){
    await  dispatch(getOneResultForReport(data.resultId))
      toggleModalTaskDetails()
    }

    }
    function toggleModalTaskDetails() {
    setModalTaskDetails(!modalTaskDetails)
  }

  return (
    <>
      {/* <section className="TitlePage">Task Tracking</section> */}

      <section className="AllMonitoringActDetaills">
        <Row>
          <Col xs="10">
            <Row className="AllMonitoringActDetaillsHeader ">
              <Col xs="6">
                <RangePicker
                  className="DateRangePicker"
                  format="YYYY-MM-DD HH:mm"
                  showTime={{
                    defaultValue: [
                      moment("00:00:00", "HH:mm:ss"),
                      moment("00:00:00", "HH:mm:ss"),
                    ],
                  }}
                  onChange={(e) => handleDate(e)}
                />
              </Col>

              <Col xs={{ size: 5, offset: 1 }}>
                <Input
                  type="search"
                  className="searchBar"
                  placeholder="Search by task, assignee, customer, system, global status"
                  onChange={handelSearshQuerry}
                />
              </Col>
            </Row>

            <div className="TableContainer">
              <div
                id="MyTasksListTableTCONT"
                className="avxTableContainer scrollTb"
              >
                <Table
                  // id="resize"
                  className="MyTasksListTable avxBsTable"
                  striped
                  hover
                >
                  <thead>
                    <tr>
                      <th>Task</th>
                      <th>Assignee</th>
                      <th>Customer</th>
                      <th>System</th>
                      <th>Start Time</th>
                      <th>State</th>
                      <th>Result Time</th>
                      <th>Global Status</th>
                    </tr>
                  </thead>

                  {isLoadingTasksByCriteria === false ? (
                    <tbody className="forPointer">
                      {allTasksByCriteria && error === null ? (
                        <>
                          {allTasksByCriteria.map((task) => (
                            <tr
                              key={task._id}
                              className={`${
                                task.state === "Pending" ? "statusColor" : ""
                              }`}
                              onClick={(e) => handleModalShow(e, task)}
                              title = {
                                (task.createdByName ? 'Created by ' + task.createdByName : '') + (task.updatedByName ? '\nUpdated by ' + task.updatedByName : '')
                              }
                            >
                              <td>{task.title}</td>

                              <td>
                                {" "}
                                <Badge
                                  color={
                                    task.assigneeName ? "secondary" : "warning"
                                  }
                                >
                                  {task.assigneeName
                                    ? task.assigneeName + " "
                                    : "Unassigned"}
                                </Badge>
                              </td>

                              <td>{task.customerName}</td>
                              <td>{task.systemName}</td>
                              <td>
                                {/* {task.estimatedStart.split("T")[1].split("+")[0]} */}
                                {moment(task.estimatedStart)
                                  .tz(currentTimeZone)
                                  .format("DD-MM-YYYY - LT")}
                              </td>
                              <td>
                                <Badge
                                  color={genPillColor(task.state.toLowerCase())}
                                  pill
                                >
                                  {" "}
                                  {task.state}{" "}
                                </Badge>
                              </td>

                              {task.resultId ? (
                                <td>
                                  {moment(task.resultCreatedAt)
                                    .tz(currentTimeZone)
                                    .format("DD-MM-YYYY - LT")}
                                </td>
                              ) : (
                                <td>
                                  <span>---</span>
                                </td>
                              )}

                              {task.resultId ? (
                                <td>
                                  <span
                                    style={{
                                      color: genPillColorGS(
                                        task.globalStatus.toLowerCase()
                                      ),
                                      fontWeight: "700",
                                    }}
                                  >
                                    {task.globalStatus}
                                  </span>
                                </td>
                              ) : (
                                <td>
                                  <span>---</span>
                                </td>
                              )}

                              {/* <td>
                                {moment(task.resultCreatedAt)
                                  .tz(currentTimeZone)
                                  .format("LT")}
                              </td> */}
                            </tr>
                          ))}
                        </>
                      ) : (
                        <tr>
                          <td colSpan="8">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                              }}
                            >
                              <span> No Tasks </span>
                              {/* <span>{error} </span> */}
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan="8">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: 100,
                            }}
                          >
                            <Spinner color="dark" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </div>
              <TablePagination
                className="AllMonitoringActDetaillsPaginate"
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                component="div"
                count={totalDocs ? totalDocs : 0}
                // rowsPerPage={rowsPerPage ? rowsPerPage : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </Col>
          {/* <Col sm="2">
                <FormGroup>
                    <Select placeholder="Customer" />
                </FormGroup>
                <FormGroup>
                <Select placeholder="System" />
                </FormGroup>
                <FormGroup>
                <Select placeholder="Type" />
                </FormGroup>
                <FormGroup>
                <Select placeholder="Category" />
                </FormGroup> 
                <FormGroup>
                <DatePicker.RangePicker className="PickerRange"/>
                </FormGroup>
            </Col> */}
          <Col xs="2">
            <div className="AllMonitoringActDetaillsHighlights">
              <h5 className="AllMonitoringActDetaillsHighlightsTitle">
                Highlights
              </h5>
              <div className="AllMonitoringActDetaillsHighlightsInsideContent">
                <table className="tableHighlight">
                  <tbody>
                    <tr>
                      <td>
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              type="checkbox"
                              className="flCheck"
                              onChange={checkChange}
                              value="Pending"
                              checked={state.includes("Pending") ? true : false}
                              disabled={isLoadingTasksByCriteria ? true : false}
                            />{" "}
                            <Badge color="warning" pill>
                              Pending
                            </Badge>
                          </Label>
                        </FormGroup>
                      </td>
                      <td>
                        <p className="StatusNumber">
                          {numberOfTasksPending ? numberOfTasksPending : 0}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              type="checkbox"
                              className="flCheck"
                              onChange={checkChange}
                              value="In progress"
                              checked={
                                state.includes("In progress") ? true : false
                              }
                              disabled={isLoadingTasksByCriteria ? true : false}
                            />{" "}
                            <Badge color="primary" pill>
                              In progress
                            </Badge>
                          </Label>
                        </FormGroup>
                      </td>
                      <td>
                        <p className="StatusNumber">
                          {numberOfTasksInProgress
                            ? numberOfTasksInProgress
                            : 0}
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              type="checkbox"
                              className="flCheck"
                              onChange={checkChange}
                              value="Completed"
                              checked={
                                state.includes("Completed") ? true : false
                              }
                              disabled={isLoadingTasksByCriteria ? true : false}
                            />{" "}
                            <Badge color="info" pill>
                              Completed
                            </Badge>
                          </Label>
                        </FormGroup>
                      </td>
                      <td>
                        <p className="StatusNumber">
                          {numberOfTasksCompleted ? numberOfTasksCompleted : 0}
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              type="checkbox"
                              className="flCheck"
                              onChange={checkChange}
                              value="Done"
                              checked={state.includes("Done") ? true : false}
                              disabled={isLoadingTasksByCriteria ? true : false}
                            />{" "}
                            <Badge color="success" pill>
                              Done
                            </Badge>
                          </Label>
                        </FormGroup>
                      </td>
                      <td>
                        <p className="StatusNumber">
                          {numberOfTasksDone ? numberOfTasksDone : 0}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              type="checkbox"
                              className="flCheck"
                              onChange={checkChange}
                              value="Canceled"
                              checked={
                                state.includes("Canceled") ? true : false
                              }
                              disabled={isLoadingTasksByCriteria ? true : false}
                            />{" "}
                            <Badge color="danger" pill>
                              Canceled
                            </Badge>
                          </Label>
                        </FormGroup>
                      </td>
                      <td>
                        <p className="StatusNumber">
                          {numberOfTasksCanceled ? numberOfTasksCanceled : 0}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="AllMonitoringActDetaillsFilter">
              <h5 className="AllMonitoringActDetaillsFilterTitle">
                Task Types
              </h5>
              <div className="AllMonitoringActDetaillsFilterInsideContent">
                <FormGroup check inline>
                  <Label className="AllMonitoringActDetaillsFilterLabel">
                    <Input
                      type="radio"
                      name="taskTypeRadio"
                      value="Monitoring"
                      onClick={() => setType(["Monitoring"])}
                      defaultChecked
                    />{" "}
                    Monitoring Tasks
                  </Label>
                </FormGroup>

                <FormGroup>
                  <Label className="AllMonitoringActDetaillsFilterLabel">
                    Custom Tasks
                  </Label>
                  <FormGroup check inline>
                    <Label check>
                      <Input
                        type="radio"
                        name="taskTypeRadio"
                        value="Other Monitoring"
                        onClick={() => setType(["Other Monitoring"])}
                      />{" "}
                      Monitoring
                    </Label>
                  </FormGroup>

                  <FormGroup check inline>
                    <Label check>
                      <Input
                        type="radio"
                        name="taskTypeRadio"
                        value="Other"
                        onClick={() => setType(["Other"])}
                      />
                      Other
                    </Label>
                  </FormGroup>
                </FormGroup>
              </div>
            </div>
          </Col>
        </Row>

        <ModalTaskDetailsTaskTracking
          oneResult={oneResult}
          IsLoadingResult={IsLoadingResult}
          toggleModalTaskDetails={toggleModalTaskDetails}
          modalTaskDetails={modalTaskDetails}
          setModalTaskDetails={setModalTaskDetails}
          TaskData={TaskData}
          system={system}
          TaskDataTitle={TaskDataTitle}
        />
      </section>
    </>
  );
}
