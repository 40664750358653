import React, { useState } from "react";
import "../../adminPanel.css";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import ContractType from "./customerAPComponents/ContractTypeAP";

function CustomerAP() {
  const [activeTab, setActiveTab] = useState("CT");
  const toggleRender = (tab) => {
    activeTab !== tab && setActiveTab(tab);
  };

  return (
    <div className="customerAP">
      {/* <Row className="adminPanelHeader"></Row> */}

      <div className="customerAPBody">
        <Nav tabs className="adminPanelChildTabsNav childTabForCustomer">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "CT" })}
              onClick={() => {
                toggleRender("CT");
              }}
            >
              Contract Type
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent className="adminPanelTabsContent">
          {
            {
              CT: (
                <TabPane className="contractTypeTab">
                  <ContractType />
                </TabPane>
              ),
            }[activeTab]
          }
        </TabContent>
      </div>
    </div>
  );
}

export default CustomerAP;
