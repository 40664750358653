import http from "../api";

const getAllMap = (system_Id, timeZone, page, size, searchValue) => {
  if(searchValue){
    return http.get(`/api/map/getBySystemId/${system_Id}?page=${page}&size=${size}&searchValue=${searchValue}&timeZone=${timeZone}`);
  }
  else{
    return http.get(`/api/map/getBySystemId/${system_Id}?page=${page}&size=${size}&timeZone=${timeZone}`);
  }
};

const createMap = (map, timeZone) => {
  return http.post(`/api/map?timeZone=${timeZone}`, map);
};


const getOneMap = (id, timeZone) => {
  return http.get(`/api/map/${id}?timeZone=${timeZone}`);
};

const updateMap = (id, map, timeZone) => {
  return http.patch(`/api/map/${id}?timeZone=${timeZone}`, map);
}
const deleteMap = (id) => {
  return http.patch(`/api/map/delete/${id}`);

};

const mapService = {
  getAllMap,
  createMap,
  getOneMap,
  updateMap,
  deleteMap,
};
export default mapService;
