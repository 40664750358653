const GET_ACTIVE_USER_STAT_SUCCESS = "GET_ACTIVE_USER_STAT_SUCCESS";
const GET_ACTIVE_USER_STAT_FAILURE = "GET_ACTIVE_USER_STAT_FAILURE";
const ACTIVE_USER_LOAD = "ACTIVE_USER_LOAD";

const CLEAR_STORE_STAT = "CLEAR_STORE_STAT";




const GET_AV_RESPONSE_TIME_STAT_SUCCESS = "GET_AV_RESPONSE_TIME_STAT_SUCCESS";
const GET_AV_RESPONSE_TIME_STAT_FAILURE = "GET_AV_RESPONSE_TIME_STAT_FAILURE";
const AV_RESPONSE_TIME_LOAD = "AV_RESPONSE_TIME_LOAD";


const GET_DBGROWTH_STAT_SUCCESS = "GET_DBGROWTH_STAT_SUCCESS";
const GET_DBGROWTH_STAT_FAILURE = "GET_DBGROWTH_STAT_FAILURE";
const DBGROWTH_LOAD = "DBGROWTH_LOAD";

const RESULT_HISTORY_LOAD = "RESULT_HISTORY_LOAD";
const GET_RESULT_HISTORY_SUCCESS = "GET_RESULT_HISTORY_SUCCESS";
const GET_RESULT_HISTORY_FAILURE = "GET_RESULT_HISTORY_FAILURE";
const CLEAR_RESULT_HISTORY = "CLEAR_RESULT_HISTORY";





let statisticTypes = {
    GET_ACTIVE_USER_STAT_SUCCESS,
    GET_ACTIVE_USER_STAT_FAILURE,
    ACTIVE_USER_LOAD,
    CLEAR_STORE_STAT,
    GET_AV_RESPONSE_TIME_STAT_SUCCESS,
    GET_AV_RESPONSE_TIME_STAT_FAILURE,
    AV_RESPONSE_TIME_LOAD,
    GET_DBGROWTH_STAT_SUCCESS,
    GET_DBGROWTH_STAT_FAILURE,
    DBGROWTH_LOAD,
    RESULT_HISTORY_LOAD,
    GET_RESULT_HISTORY_SUCCESS,
    GET_RESULT_HISTORY_FAILURE,
    CLEAR_RESULT_HISTORY
};
export default statisticTypes;
