const LOAD_LANDSCAPE_CT = "LOAD_LANDSCAPE_CT";
const RETRIEVE_SYSTEM_CATEGORIES_SUCCESS =
  "RETRIEVE_SYSTEM_CATEGORIES_SUCCESS_CT";
const RETRIEVE_SYSTEM_CATEGORIES_FAILURE =
  "RETRIEVE_SYSTEM_CATEGORIES_FAILURE_CT";
const RETRIEVE_SYSTEM_TYPES_SUCCESS = "RETRIEVE_SYSTEM_TYPES_SUCCESS_CT";
const RETRIEVE_SYSTEM_TYPES_FAILURE = "RETRIEVE_SYSTEM_TYPES_FAILURE_CT";
const LOAD_TYPES_CT = "LOAD_TYPES_CT";
const RETRIEVE_SYSTEMS_SUCCESS = "RETRIEVE_SYSTEMS_SUCCESS_CT";
const RETRIEVE_SYSTEMS_FAILURE = "RETRIEVE_SYSTEMS_FAILURE_CT";
const LOAD_SYSTEM = "LOAD_SYSTEM_CT";
const CLEAR_SYSTEMS_CT = "CLEAR_SYSTEMS_CT";

let landScapCtTypes = {
  LOAD_LANDSCAPE_CT,
  RETRIEVE_SYSTEM_CATEGORIES_SUCCESS,
  RETRIEVE_SYSTEM_CATEGORIES_FAILURE,
  RETRIEVE_SYSTEM_TYPES_SUCCESS,
  RETRIEVE_SYSTEM_TYPES_FAILURE,
  LOAD_TYPES_CT,
  RETRIEVE_SYSTEMS_SUCCESS,
  RETRIEVE_SYSTEMS_FAILURE,
  LOAD_SYSTEM,
  CLEAR_SYSTEMS_CT
};
export default landScapCtTypes;
