import http from "../api";


const getLayoutReport = (customerId,types,categories) => {
  let queryPath = `/api/customer/getCustomerReportLayout/${customerId}?`;
  types.length>0 && (queryPath += `&type=${JSON.stringify(types)}`);
  categories.length>0 && (queryPath += `&category=${JSON.stringify(categories)}`)
  return http.get(queryPath,
    {
      headers: {
        "Content-type": "application/json",
        "x-auth-accessToken": localStorage.getItem("ma-access-token"),
        "x-auth-refreshToken": localStorage.getItem("ma-refresh-token"),
      },
    }
    );

};

const getReportResult = (filter) => {

  return http.post(`/api/result/resultByMapsIds`,filter)
};

const globalReportService = {
    getLayoutReport,
    getReportResult
};
export default globalReportService;
