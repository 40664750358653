import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Label,
  Form,
  ModalFooter,
  ModalHeader,
  Modal,
  ModalBody,
  Row,
  Col,
  
} from "reactstrap";
import "./planEditTask.css";

import { Switch, DatePicker } from "antd";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// import schema from "./PlanNewTaskYupValidation";
import { v4 } from "uuid";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  retreiveSystemsByCustomerId,
  retreiveAllTheSystems,
} from "../../../../../actions/systemAction";
import {
  
  retreiveSystemsForMaps, 
 
  retreiveCustomer,
  
} from "../../../../../actions/taskAction";
import { RetreiveOneMTP, updateMTP } from "../../../../../actions/mtpAction";
import schema from "./PlanEditTaskYupValidation";

export default function EditPlanTask({ setTaskId, mtpID , refreshData,
  setrefreshData}) {
  //console.log("mtpID ----> ", mtpID);
  

  const [ShowMyTaskModal, setShowMyTaskModal] = useState(false);
  const toggleModalMyTask = async ()  => {
    
    await setShowMyTaskModal(!ShowMyTaskModal);
    
      await setTaskId(null);
    
    
  };

  
  const dispatch = useDispatch();
  
  const currentTimeZone = useSelector((state) => state.user.timeZone);
  const mtp = useSelector((state) => state.mtpReducer.mtp);
  
  const [systemId, setsystemId] = useState();
  const [MonitoringActivity, setMonitoringActivity] = useState();
  const [optionsSystems, setoptionsSystems] = useState([]);
  const [optionsmonitoringActivities, setoptionsmonitoringActivities] =
    useState([]);
  const monitoringActivities = useSelector(
    (state) => state.taskReducer.AllSystemsFormMap.docs
  );
  const isloadingMaps = useSelector((state) => state.taskReducer.isloadingMaps);
  const systems = useSelector((state) => state.system.AllSystemsByCustomerId);
  const isLoadingSystem = useSelector(
    (state) => state.taskReducer.isLoadingSystem
  );
  let [optionsCustomers, setoptionsCustomers] = useState([]);
  let customers = useSelector((state) => state.taskReducer.AllCustomers.docs);
  const allTheSystems = useSelector((state) => state.system.allTheSystems);
  const [compKey, setcompKey] = useState(v4());
  const [compKeyCustomer, setcompKeyCustomer] = useState(v4());
  // const [refreshData, setrefreshData] = useState(false);
  const [systemSelected, setSystemSelected] = useState("");
  const [mapSelected, setMapSelected] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [eventCustomer, seteventCustomer] = useState();
  const [event, setevent] = useState();
  const isLoadingOne = useSelector((state) => state.mtpReducer.isLoadingOne);

  const {
    control,
    handleSubmit,
    register,
    setValue,
    watch,
    // reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  //Retrieving Customers
  useEffect(() => {
    dispatch(retreiveCustomer(false, null, null, true));
  }, [dispatch]);

  //Retrieving Systems
  useEffect(() => {
    if (eventCustomer) {
      dispatch(retreiveSystemsByCustomerId(eventCustomer));
    }
  }, [dispatch, eventCustomer, event]);

  //set the init systems (after choosing customer)
  useEffect(() => {
    let currentSystem = null;
    let customerObject = null;
    if (allTheSystems && allTheSystems.length > 0) {
      currentSystem =
        mtp?.system &&
        allTheSystems?.find(
          (system) => system._id.toString() === mtp?.system._id.toString()
        );
      customerObject =
        mtp?.system &&
        customers?.find(
          (customer) =>
            customer._id.toString() === currentSystem?.customer.toString()
        );
      if (customerObject) {
        setSelectedCustomer({
          value: customerObject?._id,
          label: customerObject?.label,
        });

        dispatch(retreiveSystemsByCustomerId(customerObject?._id));
      }
    }
    //console.log("object");
  }, [dispatch, allTheSystems, mtp, customers]);

  //set systems on change (by customer id)
  useEffect(() => {
    setoptionsSystems(
      systems?.map((sys) => ({
        value: sys._id,
        label: sys.name,
      }))
    );
  }, [systems]);

  //set maps on change (by system id)
  useEffect(() => {
    setoptionsmonitoringActivities(
      monitoringActivities?.map((act) => ({
        value: act._id,
        label: act.monitoringAct.activity,
      }))
    );
  }, [monitoringActivities]);

 

  function handleSystem(e) {
    setevent(e.value);

    if (e) {
      setsystemId(e);

      setMapSelected(null);
      setcompKey(v4());
    } else {
      setsystemId(null);

      setMonitoringActivity(null);
    }
  }

  function handleCustomer(e) {
    seteventCustomer(e.value);
    if (e) {
      // getSystem(e.value);
      setSystemSelected(null);
      setMapSelected(null);
      setoptionsmonitoringActivities([]);
      setMonitoringActivity(null);
      setcompKeyCustomer(v4());
      setcompKey(v4());
    }
  }

  //by systemIDs by event

  useEffect(() => {
    if (event) {
      dispatch(retreiveSystemsForMaps(event, currentTimeZone));
    }
  }, [dispatch, event, currentTimeZone]);

  function handleMonitoringActivity(e) {
    if (e) {
      setMonitoringActivity(e);
    } else {
      setMonitoringActivity(null);
    }
  }
  useEffect(() => {
    mtpID && toggleEdit();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mtpID]);

  const toggleEdit = async () => {
    await dispatch(retreiveAllTheSystems(false));
    const res = await dispatch(RetreiveOneMTP(mtpID));
    
    setValue("estimatedStart", moment(res.estimatedStart).tz(currentTimeZone));
    
    setValue("description", res.description);

    if (res) {
      if (res.type === "Other Monitoring") {
        setValue("isActive",true);

       
        await setoptionsCustomers(
          customers?.map((cust) => ({
            value: cust._id,
            label: cust.label,
          }))
        );
        await setSystemSelected({
          value: res.system._id,
          label: res.system.name,
        });

        await dispatch(retreiveSystemsForMaps(res.system._id, currentTimeZone));
        await setMapSelected({
          value: res.map,
          label: res.title,
        });
      } else {
        setValue("isActive",false);
        

        // setTitle(res.title);
        setValue("title", res.title);
      }

      // setDescription(res.description);
      
        
    }
    // console.log("hello");
    setShowMyTaskModal(!ShowMyTaskModal);

  };

  // Edit Module
  async function handleEdit({
    isActive,
    description,
    estimatedStart,
    title
  }) {
    
    //const formattedTime = moment(estimatedStart?._d).format("YYYY-MM-DD HH:mm");
    const formattedTime = estimatedStart.format('YYYY-MM-DD HH:mm');
   
    setsystemId(systemId);
    setMonitoringActivity(MonitoringActivity);
    let updatedData = {};

    if ( isActive === true) {
      updatedData = {
        title: MonitoringActivity?.label ? MonitoringActivity?.label : mapSelected?.label,
        description: description,
        type: "Other Monitoring",
        estimatedStart: formattedTime,
        system: systemId?.value ? systemId?.value : systemSelected?.value,
        map: MonitoringActivity?.value ? MonitoringActivity?.value : mapSelected?.value
      };
      await dispatch(updateMTP(mtp._id,currentTimeZone, updatedData));
      setrefreshData(!refreshData);
    } else {
       updatedData = {
        title: title,
        description: description,
        type: "Other",
        estimatedStart: formattedTime
      };
      
      await dispatch(updateMTP(mtp._id,currentTimeZone, updatedData));
      setrefreshData(!refreshData);
    }

    toggleModalMyTask();
  }

  

  if (errors.system) {
    document.querySelector(".customerSelectSystem > div:nth-child(2)") &&
      document
        .querySelector(".customerSelectSystem > div:nth-child(2)")
        .classList.add("isInvalidSelect");
  }

  if (errors.map) {
    document.querySelector(".customerSelectMap > div:nth-child(2)") &&
      document
        .querySelector(".customerSelectMap > div:nth-child(2)")
        .classList.add("isInvalidSelect");
  }

  const selectInvalidFeedback = {
    width: "100%",
    marginTop: ".25rem",
    fontSize: "80%",
    color: "#dc3545",
  };

  // function disabledDate(current) {
  //   // Can not select days before today and today
  //   return current && current < moment().startOf('day');
  // }

  // Can not select days and hours and minute before datenow
  function disabledDate(current)  {
    let customDate = moment().format("YYYY-MM-DD HH:mm");
    return current && current < moment(customDate, "YYYY-MM-DD HH:mm");
  } 

  return (
    <>
      {/* <span className="tbBtn" onClick={handletoggleModal}>
        <BsPencil onClick={toggleEdit}></BsPencil>
      </span> */}
      <Modal
        className="Edit-New-Task-Plan-Modal"
        centered
        backdrop="static"
        keyboard={true}
        isOpen={ShowMyTaskModal}
        size="lg"
        toggle={toggleModalMyTask}
        fade={false}
      >
      <Form onSubmit={handleSubmit(handleEdit)} >
        <ModalHeader toggle={toggleModalMyTask}>Update Plan</ModalHeader>
        <ModalBody>
          {mtp ? (
            <>
              {isLoadingOne ? (
                "Loading"
              ) : (
                <>
                  

<Row className="planNewTaskSwitcherTime">
            <Col>

                <FormGroup>
                  <Label className="LabelMonitoringTask">
                    <span className="mr-3">Monitoring Task</span>
                    <Controller
                      control={control}
                      name="isActive"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Switch
                          onBlur={onBlur}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          checked={value}
                          disabled={true}
                        />
                      )}
                    />
                  </Label>
                </FormGroup>

                
            </Col>
            <Col xs="5">
              <FormGroup>
                  <Controller
                    name="estimatedStart"
                    control={control}
                    render={({ field: { ref, ...rest }, fieldState }) => (
                      <DatePicker
                      showTime  
                      disabledDate={disabledDate}
                      format="YYYY-MM-DD HH:mm"
                      className={ "form-control " + (errors.estimatedStart ? " is-invalid" : "") }
                       placeholder="Estimated start *"    {...rest}  {...fieldState}
                      />
                    )}
                  />
                <small style={selectInvalidFeedback} >
                  {errors.estimatedStart && <>{errors.estimatedStart.message}</>}
                </small>
              </FormGroup>
            </Col>
          </Row>

                  



{watch("isActive") === true ? 
            <FormGroup className='formGroupSelect'>
              <Row>

              <Col xs="4">

              <Select
                  className="customerSelectCustomer"
                  options={optionsCustomers}
                  defaultValue={selectedCustomer}
                  onChange={(e) => handleCustomer(e)}
                />

               
              </Col>

              <Col xs="3">
              <Select
                  className="customerSelectSystem"
                  options={optionsSystems}
                  isLoading={isLoadingSystem}
                  key={compKeyCustomer}
                  defaultValue={systemSelected}
                  onChange={(e) => handleSystem(e)}
                />

               
              </Col>
              <Col xs="5">

              <Select
                  className="customerSelectMap"
                  isLoading={isloadingMaps}
                  options={optionsmonitoringActivities}
                  key={compKey}
                  defaultValue={mapSelected}
                  onChange={(e) => handleMonitoringActivity(e)}
                />


              </Col>
              </Row>
            </FormGroup>
            : 
            <FormGroup>
                <Label>
                  Title<span className="requiredField"> *</span>
                </Label>
                <input
                  {...register("title")}
                  className={
                    "form-control" + (errors.title ? " is-invalid" : "")
                  }
                />
                <small className="invalid-feedback">
                  {errors.title && <>{errors.title.message}</>}
                </small>
              </FormGroup>
          }


                

          <FormGroup>
              <Label>
                Description <span className="requiredField"> *</span>
              </Label>
              <textarea rows="5"
                {...register("description")}
                className={
                  "form-control " + (errors.description ? " is-invalid" : "")
                }
              ></textarea>
              <small className="invalid-feedback ">
                {errors.description && <>{errors.description.message}</>}
              </small>
            </FormGroup>
                </>
              )}
            </>
          ) : (
            <span>No data</span>
          )}
        </ModalBody>
       

        <ModalFooter>
          <Button color="primary"  type="submit">Save Changes</Button>
          <Button onClick={toggleModalMyTask}>Cancel</Button>
        </ModalFooter>
      </Form>  
      </Modal>
    </>
  );
}
