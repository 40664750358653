import http from "../api";

const getAllShiftReport = (startDate, endDate,assigned,unassigned,timeZone) => {
  return http.get(`/api/shift?startDate=${startDate}&endDate=${endDate}&assigned=${assigned}&unassigned=${unassigned}&paginate=false&timeZone=${timeZone}`);
};
const getAllNomenclature = () => {
  return http.get(`/api/nomenclature?intermediateShift=true`);
};
const getAllMembers= (startDate,endDate,shift) => {
  return http.get(`/api/shift/members?shift=${shift}&startDate=${startDate}&endDate=${endDate}&paginate=false`);
};

const getAvailableShift = (startDate, endDate,assigned,unassigned,shift,user,timeZone) => {
  return http.get(`/api/shift?startDate=${startDate}&endDate=${endDate}&assigned=${assigned}&unassigned=${unassigned}&paginate=false&timeZone=${timeZone}&user=${user}&shift=${shift}`);
};
const assignTeamLeader = (newShift) => {
  return http.post(`/api/shift/assignTeamLeader`,newShift);
};
const getCurrentMembers = (userID) => {
  return http.get(`/api/shift/currentMembers/?id=${userID}`);
};
const getAllMembersByShifts = (timeZone) => {
  return http.get(`/api/shift/getAllMembersByShifts/?timeZone=${timeZone}`);
}
const shiftService = {
    getAllShiftReport,
    getAllNomenclature,
    getAllMembers,
    getAvailableShift,
    assignTeamLeader,
    getCurrentMembers,
    getAllMembersByShifts
};
export default shiftService;
