const RESULT_HISTORY_LOAD = "RESULT_HISTORY_LOAD";
const GET_RESULT_HISTORY_SUCCESS = "GET_RESULT_HISTORY_SUCCESS";
const GET_RESULT_HISTORY_FAILURE = "GET_RESULT_HISTORY_SUCCESS";
const CLEAR_RESULT_HISTORY = "CLEAR_RESULT_HISTORY";

let resultHistoryTypes = {
	RESULT_HISTORY_LOAD,
	GET_RESULT_HISTORY_SUCCESS,
	GET_RESULT_HISTORY_FAILURE,
	CLEAR_RESULT_HISTORY
};

export default resultHistoryTypes;
