export const systemsList = [
  { _id: 1, name:'SYSTEM PRD' },
  { _id: 2, name: 'SYSTEM DEV' },
  { _id: 3, name: 'SYSTEM QAS' }
];

export const operationsList = [
  {
    _id: 1,
    system: 1,
    title: 'Alpha',
    startDate: '2022-02-03T03:00:00.000+00:00',
    endDate: '2022-02-03T05:00:00.000+00:00'
  },
  {
    _id: 2,
    system: 2,
    title: 'Beta',
    startDate: '2022-02-03T10:30:00.000+00:00',
    endDate: '2022-02-03T15:30:00.000+00:00'
  },
  {
    _id: 3,
    system: 1,
    title: 'Omega',
    startDate: '2022-02-03T06:00:00.000+00:00',
    endDate: '2022-02-03T16:00:00.000+00:00'
  },
  {
    _id: 4,
    system: 1,
    title: 'Alpha Prime',
    startDate: '2022-03-03T03:00:00.000+00:00',
    endDate: '2022-03-03T05:00:00.000+00:00'
  },
];