import types from "./types";
import authService from "../services/auth.service";
import api from "../api";

// import {
//   setAlert
//  } from "./alert.action";
const { LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
  AUTH_LOADING,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  MICROSOFT_LOGIN_FAILURE,
  MICROSOFT_LOGIN_SUCCESS,
  GET_AUTHORIZATION_SUCCESS,
  GET_AUTHORIZATION_FAILURE
 } =
  types.authTypes;

export const register =
  (firstName, lastName, email, password, phoneNumber) =>
  async (dispatch) => {
    try {
      dispatch({
        type: AUTH_LOADING,
      });
      const res = await authService.register({
        firstName, lastName, email, password, phoneNumber
      });
      dispatch({
        type: REGISTER_SUCCESS,
      });
    //dispatch(setAlert('Successfully Registered', 'success'));
      return Promise.resolve(res.data.message);
    } catch (err) {
      if(err.response){
      dispatch({
        type: REGISTER_FAILURE,
      });
      //dispatch(setAlert(err.response.data.message, 'error'));
      return Promise.reject(err.response.data.message);
    }else{
      dispatch({
        type: REGISTER_FAILURE,
      });
      //dispatch(setAlert("Couldn't connect to server", 'error'));
    }
  }
  };

  export const login =
  (email, password) =>
  async (dispatch) => {
    try {
      dispatch({
        type: AUTH_LOADING,
      });
      const res = await authService.login({
        email, password
      });

      localStorage.setItem('ma-access-token', res.data.data.accessToken);
      localStorage.setItem('ma-refresh-token', res.data.data.refreshToken);

      api.defaults.headers.common["x-auth-accessToken"] =
      localStorage.getItem("ma-access-token");
      api.defaults.headers.common["x-auth-refreshToken"] =
      localStorage.getItem("ma-refresh-token");

      dispatch({
        type: LOGIN_SUCCESS,
      });
      //dispatch(setAlert('Successfully loggedIn', 'success'));
      
      return Promise.resolve(res.data.data);
    } catch (err) {
      if(err.response){
        dispatch({
          type: LOGIN_FAILURE,
        });
        //dispatch(setAlert(err.response.data.message, 'error'));
        return Promise.reject(err.response.data.message);
      }else{
        dispatch({
          type: LOGIN_FAILURE,
        });
        //dispatch(setAlert("Couldn't connect to server", 'error'));
      }
    }
  };

  export const microsoftLogin =
  (email,accessToken)  =>
  async (dispatch) => {
    try {
      dispatch({
        type: AUTH_LOADING,
      });
      const res = await authService.microsoftLogin({
        email, accessToken
      });
      
      localStorage.setItem('ma-access-token', res.data.data.accessToken);
      localStorage.setItem('ma-refresh-token', res.data.data.refreshToken);

      api.defaults.headers.common["x-auth-accessToken"] =
      localStorage.getItem("ma-access-token");
      api.defaults.headers.common["x-auth-refreshToken"] =
      localStorage.getItem("ma-refresh-token");
      dispatch({
        type: MICROSOFT_LOGIN_SUCCESS,
      });
      //dispatch(setAlert('Successfully loggedIn', 'success'));
   
      return Promise.resolve(res.data.data);
    } catch (err) {
      if(err.response){
        dispatch({
          type: MICROSOFT_LOGIN_FAILURE,
        });
        //dispatch(setAlert(err.response.data.message, 'error'));
        return Promise.reject(err.response.data.message);
      }else{
        dispatch({
          type: MICROSOFT_LOGIN_FAILURE,
        });
        //dispatch(setAlert("Couldn't connect to server", 'error'));
      }
    }
  };
  export const getAuthorization =
  () =>
  async (dispatch) => {
    try {
      dispatch({
        type: AUTH_LOADING,
      });
      const res = await authService.getAuthorization();
      dispatch({
        type: GET_AUTHORIZATION_SUCCESS,
        payload: res.data.data
      });
      
      return Promise.resolve(res.data.data);
    } catch (err) {
      if(err.response){
        dispatch({
          type: GET_AUTHORIZATION_FAILURE,
        });
        //dispatch(setAlert(err.response.data.message, 'error'));
        dispatch(logout());
        return Promise.reject(err.response.data.message);
      }else{
        dispatch({
          type: GET_AUTHORIZATION_FAILURE,
        });
        //dispatch(setAlert("Couldn't connect to server", 'error'));
        dispatch(logout());
      }
    }
  };

  export const logout =
  () =>
  async (dispatch) => {
    try {
      //localStorage.clear();
      localStorage.removeItem("ma-access-token");
      localStorage.removeItem("ma-refresh-token");

      api.defaults.headers.common["x-auth-accessToken"] = null;
      api.defaults.headers.common["x-auth-refreshToken"] = null;
      
      dispatch({
        type: LOGOUT,
      });
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  };