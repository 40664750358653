import types from "./resultHistoryTypes";
import resultHistoryService from "./resultHistoryService";
import { message } from "antd";

const {
    RESULT_HISTORY_LOAD,
    GET_RESULT_HISTORY_SUCCESS,
    GET_RESULT_HISTORY_FAILURE,
    CLEAR_RESULT_HISTORY
} = types;

export const getResultHistory = (mapId, timeZone, data) => async (dispatch) => {
    try {
        dispatch({
            type: RESULT_HISTORY_LOAD,
        });
        const res = await resultHistoryService.getResultHistory(mapId, timeZone, data);
        message.success("Retreive Successfully", 3);
        dispatch({
            type: GET_RESULT_HISTORY_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        message.error('An Error occurred while retreiving data', 3);
        console.log("err",err)
        dispatch({
            type: GET_RESULT_HISTORY_FAILURE,
            payload: err,
        });
    }
};

export const clearResultHistory = () => (dispatch) => {
    dispatch({
        type: CLEAR_RESULT_HISTORY,
    })
}