import types from "./types";
import sysClassService from "../services/sysClassService";
import { message } from "antd";
const {
  GET_ALL_SYSCLASSE_SUCCESS,
  GET_ALL_SYSCLASSE_FAILURE,
  LOAD_SYSCLASSE,
  CREATE_SYSCLASSE_SUCCESS,
  CREATE_SYSCLASSE_FAILURE,
  RETRIEVE_ONE_SYSCLASSE_SUCCESS,
  RETRIEVE_ONE_SYSCLASSE_FAILURE,
  UPDATE_SYSCLASSE_SUCCESS,
  UPDATE_SYSCLASSE_FAILURE,
  CLEAR_SYSCLASSE
} = types.sysClassTypes;

export const retrieveSysClass = (page, size, isActive, searchValue) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_SYSCLASSE,
    });
    const res = await sysClassService.getAllsysClass(page, size, isActive, searchValue);
    dispatch({
      type: GET_ALL_SYSCLASSE_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data.docs;
  } catch (err) {
    dispatch({
      type: GET_ALL_SYSCLASSE_FAILURE,
      payload: err,
    });
  }
};

export const createSysClasse = (sysClasse) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_SYSCLASSE,
    });
    const res = await sysClassService.createSysClasse(sysClasse);
    message.success("Successfully created the system class");
    dispatch({
      type: CREATE_SYSCLASSE_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    message.error("Failed to create the system class");
    dispatch({
      type: CREATE_SYSCLASSE_FAILURE,
      payload: err,
    });
  }
};

export const RetreiveOneSysClasse = (id) => async (dispatch) => {
  try {
    // dispatch({
    //   type: LOAD_SYSCLASSE,
    // });
    const res = await sysClassService.getOneSysClasse(id);
    dispatch({
      type: RETRIEVE_ONE_SYSCLASSE_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {
    dispatch({
      type: RETRIEVE_ONE_SYSCLASSE_FAILURE,
      payload: err.response.data.message,
    });
  }
};

export const updateSysClasse= (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_SYSCLASSE,
    });
    const res = await sysClassService.updateSysClasse(id, data);
    message.success("Successfully updated the system class");
    dispatch({
      type: UPDATE_SYSCLASSE_SUCCESS,
      payload: res.data.data,
    });

    // const resAll = await categoryService.getAll(page, size);
    // dispatch({
    //   type: GET_ALL_SYSCLASSE_SUCCESS,
    //   payload: resAll.data.data,
    // });

  } catch (err) {
    message.error("Failed to update the system class");
    dispatch({
      type: UPDATE_SYSCLASSE_FAILURE,
      payload: err?.response?.data?.message,
    });
  }
};

 //clear Data
 export const clearSysClasse = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_SYSCLASSE,
  })
}
