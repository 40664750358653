import types from "./types";
import roleService from "../services/roleService";
import { message } from "antd";
const {  LOAD_ROLE,
    GET_ALL_ROLE_SUCCESS,
    GET_ALL_ROLE_FAILURE,
    CREATE_ROLE_SUCCESS,
    CREATE_ROLE_FAILURE,
    RETRIEVE_ONE_ROLE_SUCCESS,
    RETRIEVE_ONE_ROLE_FAILURE,
    UPDATE_ROLE_SUCCESS,
    UPDATE_ROLE_FAILURE,
    CLEAR_ROLES
  } =
  types.roleTypes;

export const retrieveAllRole = (page, size, isActive, paginate, searchValue) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_ROLE,
    });

    const res = await roleService.getAllRole(page, size, isActive, paginate, searchValue);
    dispatch({
      type: GET_ALL_ROLE_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data.docs;
  } catch (err) {
    dispatch({
      type: GET_ALL_ROLE_FAILURE,
      payload: err,
    });
  }
};

export const createRole = (role) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_ROLE,
    });
    const res = await roleService.createRole(role);
    message.success("Successfully created the role");
    dispatch({
      type: CREATE_ROLE_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    message.error("Failed to create the role");
    dispatch({
      type: CREATE_ROLE_FAILURE,
      payload: err,
    });
  }
};

export const RetreiveOneRole = (id) => async (dispatch) => {
  try {
    const res = await roleService.getOneRole(id);
    dispatch({
      type: RETRIEVE_ONE_ROLE_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {
    dispatch({
      type: RETRIEVE_ONE_ROLE_FAILURE,
      payload: err.response.data.message,
    });
  }
};

export const updateRole= (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_ROLE,
    });
    const res = await roleService.updateRole(id, data);
    message.success("Successfully updated the role");
    dispatch({
      type: UPDATE_ROLE_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    message.error('Failed to update the role');
    dispatch({
      type: UPDATE_ROLE_FAILURE,
      payload: err?.response?.data?.message,
    });
  }
};

 //clear Data
 export const clearRoles = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_ROLES,
  })
}
