import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Label } from "reactstrap";
import "./ProfileCustomer.css";
import { getCustomer } from "../../../../actions/customerAction";

function ProfileCustomer(props) {
  const { customer_ID } = props;
  //console.log(customer_ID);

  const dispatch = useDispatch();

  // const handelCuctomer = () => {
  //   dispatch(getCustomer(customer_ID));
  // };
  const customer = useSelector((state) => state.customer.customer);
  //const Listlicense = useSelector((state) => state.licences);
  const currentTimeZone= useSelector((state) => state.user.timeZone);

  //console.log("customer =====> ", customer);
  useEffect(() => {
    dispatch(getCustomer(customer_ID, currentTimeZone));
  }, [dispatch, customer_ID, currentTimeZone]);
  // console.log("Customer ", handelCuctomer);

  // useEffect(() => {
  //   if (customer_ID) {
  //     dispatch(retrieveLicence(customer_ID, 0, 5));
  //   }
  // }, [dispatch, 0, 5, customer_ID]);

  return (
    <div className="customerProfile">
      {customer ? (
        <Row>
          <Col xs="4" className="profileBadge">
            <p>
              {/* <img src={customer.logo} alt="logo" /> */}
              <img src={"data:image;base64," + customer.logo} alt="logo" />
            </p>
            <h2>{customer.label}</h2>
            {/* <h2>{props.customer_ID}</h2> */}
          </Col>
          <Col xs="8" className="profileInfo">
            <Row>
              <Col xs="4">
                <Label>Address</Label>
              </Col>
              <Col xs="8">
                <p>{customer.address}</p>
              </Col>
            </Row>
            <Row>
              <Col xs="4">
                <Label>License</Label>
              </Col>
              {customer.isActive ? (
                <Col xs="8">
                  <p>
                    <Label>From</Label>
                  </p>
                  {/* <p>{customer.licence.from}</p> */}
                  <p>
                    {customer.activeLicence
                      ? customer?.activeLicence?.startDate.split("T")[0]
                      : "No Active Licence"}
                  </p>
                  <p>
                    <Label>To</Label>
                  </p>
                  <p>
                    {customer.activeLicence
                      ? customer?.activeLicence?.endDate.split("T")[0]
                      : "No Active Licence"}
                  </p>
                </Col>
              ) : (
                <Col xs="8">
                  <p>No Licence Exist</p>
                </Col>
              )}
            </Row>
            <Row>
              <Col xs="4">
                <Label>First Report</Label>
              </Col>
              <Col xs="8">
                <p>{customer.firstReport ? customer?.firstReport : "---"}</p>
              </Col>
            </Row>
            <Row>
              <Col xs="4">
                <Label>Contract Types</Label>
              </Col>
              <Col xs="8">
                {/* {customer.listContract?.map((lc, index) => (
                  <p key={index}>{lc}</p>
                ))} */}

                {customer.listMonitoringType ? (
                  customer.listMonitoringType.map((type, index) => (
                    <p key={index}>{type.libelle}</p>
                  ))
                ) : (
                  <p> No Contarct Types</p>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs="4">
                <Label>Time zones</Label>
              </Col>
              <Col xs="8">
                {customer.timeZone.map((lc, index) => (
                  <p key={index}>{lc}</p>
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <span>No profile</span>
      )}
    </div>
  );
}

export default ProfileCustomer;
