import types from "./types";
import slaContractService from "../services/slaContractService";
import { message } from 'antd';
const {
  CREATE_SLACONTRACT_SUCCESS,
  GET_ALL_SLACONTRACTS_SUCCESS,
  UPDATE_SLACONTRACT_SUCCESS,
  CREATE_SLACONTRACT_FAILURE,
  GET_ALL_SLACONTRACTS_FAILURE,
  UPDATE_SLACONTRACT_FAILURE,
  LOAD_SLACONTRACT,
  DELETE_SLACONTRACT_SUCCESS,
  DELETE_SLACONTRACT_FAILURE,
  RETRIEVE_ONE_SLACONTRACT_SUCCESS,
  RETRIEVE_ONE_SLACONTRACT_FAILURE,
  CLEAR_ALL_SLACONTRACTS
} = types.slaContractTypes;

export const createSlaContract = (newSalContract) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_SLACONTRACT,
    });
    const res = await slaContractService.createSlaContract(newSalContract);
  message.success("Create Successfully",3);
    dispatch({
      type: CREATE_SLACONTRACT_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
  message.error('An Error occurred while creating',3);  
    dispatch({
      type: CREATE_SLACONTRACT_FAILURE,
      payload: err,
    });
  }
};

export const retrieveSlaContracts =
  (cutomer_Id, page, size, searchValue) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_SLACONTRACT,
      });
      const res = await slaContractService.getAllSlaContract(
        cutomer_Id,
        page,
        size,
        searchValue
      );
      dispatch({
        type: GET_ALL_SLACONTRACTS_SUCCESS,
        payload: res.data.data,
      });
      return res.data.data;
    } catch (err) {
      dispatch({
        type: GET_ALL_SLACONTRACTS_FAILURE,
        payload: err.response.data.message,
      });
      // dispatch({
      //   type: LOAD_SLACONTRACT,
      // });
    }
  };

export const updateSlaContract = (id, data) => async (dispatch) => {
  try {
    const res = await slaContractService.updateSlaContract(id, data);
  message.success("Updated Successfully",3);                      
    dispatch({
      type: UPDATE_SLACONTRACT_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
 message.error('An Error occurred while updating',3);
    dispatch({
      type: UPDATE_SLACONTRACT_FAILURE,
      payload: err.response.data.message,
    });
  }
};
export const deleteSlaConstract = (id) => async (dispatch) => {
  try {
    const res = await slaContractService.deleteSlaContract(id);
    dispatch({
      type: LOAD_SLACONTRACT,
    });
message.success("Deleted Successfully",3);
    dispatch({
      type: DELETE_SLACONTRACT_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
   message.error('An Error occurred while deleting',3); 
    dispatch({
      type: DELETE_SLACONTRACT_FAILURE,
      payload: err.response.data.message,
    });
  }
};

export const RetreiveOneSlaContract = (id) => async (dispatch) => {
  try {
    const res = await slaContractService.getOneSlaContract(id);
    dispatch({
      type: RETRIEVE_ONE_SLACONTRACT_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {
    dispatch({
      type: RETRIEVE_ONE_SLACONTRACT_FAILURE,
      payload: err.response.data.message,
    });
  }
};

//clear All SlaContracts
export const clearSlaContracts = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_ALL_SLACONTRACTS,
  })
}
