import React, { useEffect } from "react";
import { Table, Spinner } from "reactstrap";
import { BsTrash, BsPencil } from "react-icons/bs";
import "./CustomerSystems.css";
import { useDispatch, useSelector } from "react-redux";
import {
  RetreiveSystemByCustomerByCatgeroyByTypesCT,
  RetreiveSystemCategoriesCT,
  RetreiveSystemTypes,
  clearSystemsCT
} from "../../../../actions/landScapeCTAction";

const LandscapeAddonCT = function ({
  avxToggle,
  customer_ID,
  setSelectedSys,
  setScrollPosition,
  toggleEdit,
  handleOpenEditModal,
  confirmDelete,
  settypeId,
  categoryId,
  setcategoryId,
}) {
  const dispatch = useDispatch();
  const SystemsCategories = useSelector((state) => state.landScapeCTReducer);

  const isLoading = useSelector((state) => state.landScapeCTReducer.isLoading);

 //Clear Data
 useEffect(() => {
  return () => {
    dispatch(clearSystemsCT());
  };
  //eslint-disable-next-line react-hooks/exhaustive-deps
},[])

  useEffect(() => {
    if (customer_ID) dispatch(RetreiveSystemCategoriesCT(customer_ID));
  }, [dispatch, customer_ID]);

  function handletoggleTypes(e, categoryId) {
    avxToggle(e);
    setcategoryId(categoryId);
    if (customer_ID && categoryId)
      dispatch(RetreiveSystemTypes(customer_ID, categoryId));
  }

  function handletoggleSystems(e, typeId) {
    avxToggle(e);
    settypeId(typeId);
    if (typeId)
      dispatch(
        RetreiveSystemByCustomerByCatgeroyByTypesCT(
          customer_ID,
          categoryId,
          typeId
        )
      );
  }
  return (
    <div className="landscapeAddonTC">
      {isLoading === false ? (
        <>
          {SystemsCategories.SystemCategories.length > 0 ? (
            <>
              {SystemsCategories.SystemCategories.map((sCat) => (
                <div
                  key={"systemCateg" + sCat._id}
                  className="sysParentContainOne sysCategory"
                >
                  <h3 onClick={(e) => handletoggleTypes(e, sCat._id)}>
                    <span>{sCat.category}</span>
                  </h3>

                  <div className="sysChildrenContainOne sysChildHide">
                    {sCat.type.isLoadingTypes === false ? (
                      <>
                        {sCat.type.types.length !== 0 ? (
                          <>
                            {" "}
                            {sCat.type.types.map((cType) => (
                              <div
                                key={
                                  "systemCategoryType" +
                                  sCat._id +
                                  "-" +
                                  cType._id
                                }
                                className="sysParentContainTwo sysType"
                              >
                                <h5
                                  onClick={(e) =>
                                    handletoggleSystems(e, cType._id)
                                  }
                                >
                                  <span>{cType.type}</span>
                                </h5>

                                <div className="sysChildrenContainTwo sysChildHide avxTableContainer">
                                  <Table
                                    className="customerSystemsTable avxBsTable avxBsTableSmall twoActs"
                                    striped
                                    hover
                                  >
                                    <thead>
                                      <tr>
                                        <th>System</th>
                                        <th>Class</th>
                                        <th>Deployment</th>
                                        <th>FQDN</th>
                                        <th>
                                          <span>
                                            <BsPencil></BsPencil>
                                          </span>
                                        </th>
                                        <th>
                                          <span>
                                            <BsTrash></BsTrash>
                                          </span>
                                        </th>
                                      </tr>
                                    </thead>

                                    {cType.systems.isSystemLoading === false ? (
                                      <>
                                        {cType.systems.data.length !== 0 ? (
                                          <tbody>
                                            {cType.systems.data.map((cSys) => (
                                              <tr
                                                key={
                                                  "system" +
                                                  sCat._id +
                                                  "-" +
                                                  cType._id +
                                                  "-" +
                                                  cSys._id
                                                }
                                              >
                                                <td
                                                  onClick={() => {
                                                    setScrollPosition(
                                                      window.scrollY
                                                    );
                                                    setSelectedSys(cSys._id);
                                                  }}
                                                >
                                                  {cSys.name}
                                                </td>
                                                <td
                                                  onClick={() => {
                                                    setScrollPosition(
                                                      window.scrollY
                                                    );
                                                    setSelectedSys(cSys._id);
                                                  }}
                                                >
                                                  {cSys.class.libelle}
                                                </td>
                                                <td
                                                  onClick={() => {
                                                    setScrollPosition(
                                                      window.scrollY
                                                    );
                                                    setSelectedSys(cSys._id);
                                                  }}
                                                >
                                                  {cSys.deployDesc}
                                                </td>
                                                <td
                                                  onClick={() => {
                                                    setScrollPosition(
                                                      window.scrollY
                                                    );
                                                    setSelectedSys(cSys._id);
                                                  }}
                                                >
                                                  {cSys.fqdn}
                                                </td>
                                                <td>
                                                  <span
                                                    className="tbBtn"
                                                    onClick={(e) =>
                                                      handleOpenEditModal(
                                                        e,
                                                        cSys._id
                                                      )
                                                    }
                                                  >
                                                    <BsPencil></BsPencil>
                                                  </span>
                                                </td>
                                                <td>
                                                  <span
                                                    className="tbBtn"
                                                    onClick={(e) =>
                                                      confirmDelete(e, cSys._id)
                                                    }
                                                  >
                                                    <BsTrash></BsTrash>
                                                  </span>
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        ) : (
                                          <tbody>
                                            <tr>
                                              <td colSpan="6">
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  There is no system with this
                                                  type
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        )}
                                      </>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td colSpan="6">
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <Spinner color="dark" />
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </Table>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : (
                          <div>There is no type with this category</div>
                        )}
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: 30,
                        }}
                      >
                        <Spinner color="dark" />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="landscapTC_LoadingTypes_spinner">
              <span>No Category appears here !</span>
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner color="dark" />
        </div>
      )}
    </div>
  );
};

export default LandscapeAddonCT;
