
const GET_REPORT_LAYOUT_SUCCESS = "GET_REPORT_LAYOUT_SUCCESS";
const GET_REPORT_LAYOUT_FAILURE = "GET_REPORT_LAYOUT_FAILURE";
const LOAD_REPORT_LAYOUT = "LOAD_REPORT_LAYOUT";
const CLEAR_REPORT_LAYOUT = "CLEAR_REPORT_LAYOUT";



const LOAD_GET_RESULT_REPORT = "LOAD_GET_RESULT_REPORT";
const GET_RESULT_REPORT_SUCCESS = "GET_RESULT_REPORT_SUCCESS";
const GET_RESULT_REPORT_FAILURE = "GET_RESULT_REPORT_FAILURE";

const GET_ONE_RESULT_LOADER_REPORT = "GET_ONE_RESULT_LOADER_REPORT";
const GET_ONE_RESULT_REPORT_SUCCESS = "GET_ONE_RESULT_REPORT_SUCCESS";
const GET_ONE_RESULT_REPORT_FAILURE = "GET_ONE_RESULT_REPORT_FAILURE";

const CLEAR_GLOBAL_REPORT = "CLEAR_GLOBAL_REPORT";

let globalReportTypes = {
    GET_REPORT_LAYOUT_SUCCESS,
    GET_REPORT_LAYOUT_FAILURE,
    LOAD_REPORT_LAYOUT,
    CLEAR_REPORT_LAYOUT,
    LOAD_GET_RESULT_REPORT,
    GET_RESULT_REPORT_SUCCESS,
    GET_RESULT_REPORT_FAILURE,
    GET_ONE_RESULT_LOADER_REPORT,
    GET_ONE_RESULT_REPORT_SUCCESS,
    GET_ONE_RESULT_REPORT_FAILURE,
    CLEAR_GLOBAL_REPORT
};
export default globalReportTypes;
