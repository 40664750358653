import React, { useEffect, useRef } from "react";
import "./Rightbar.css";
import CloseIcon from "@material-ui/icons/Close";
import { Badge, Spinner } from "reactstrap";
import {
  getAllMembersByShifts,
  clearShiftMembers,
} from "../../../actions/shiftAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const Rightbar = ({ handleToggler, rightbarStatus, setRightbarStatus }) => {
  const dispatch = useDispatch();
  const timeZone = useSelector((state) => state.user.timeZone);
  const MemberShifts = useSelector(
    (state) => state.shiftReducer.MembersByShifts
  );
  const isLoadingShifts = useSelector(
    (state) => state.shiftReducer.isLoadingMembersByShifts
  );
  const handleTogglerClick = () => {
    handleToggler();
  };

  let rightbarRef = useRef();
  useEffect(() => {
    let handler = (event) => {
      if (!rightbarRef.current?.contains(event.target)) {
        setRightbarStatus(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (rightbarStatus) {
        document.body.classList.add("modal-open");
        await dispatch(getAllMembersByShifts(timeZone));
      } else {
        document.body.classList.remove("modal-open");
        dispatch(clearShiftMembers());
      }
    })();
  }, [rightbarStatus, dispatch, timeZone]);

  return (
    <div
      ref={rightbarRef}
      className={
        rightbarStatus ? "rightbar shiftmembersBar" : "rightbar collapsed"
      }
    >
      <div className="rightbar_header">
        <CloseIcon
          className="rightbar_close_icon"
          onClick={handleTogglerClick}
        />
        <h1 className="rightbar_close_icon_text">Upcoming Shifts</h1>
      </div>
      {isLoadingShifts === false ? (
        <>
          {MemberShifts?.length > 0 ? (
            <>
              <div className="AllItems">
                {MemberShifts?.map((shift, index) => {
                  return (
                    <div key={index}>
                      {shift.members.length > 0 && (
                        <div className="rightbar_items">
                          <Badge
                            className="shiftType"
                            style={{ background: shift?.shift?.color }}
                          >
                            <span className="pull-left">
                              {shift?.shift?.name}
                            </span>
                            <span className="pull-right">
                              {moment(shift?.shift?.startDate).tz(timeZone).format("LT")}{" "}
                              {" - "}
                              {moment(shift?.shift?.endDate).tz(timeZone).format("LT")}
                            </span>
                          </Badge>
                          {shift?.teamLeader != null ? (
                            <div className="item">
                              <Badge className="shiftmembersTL" color="success">
                                {shift?.teamLeader?.firstName}{" "}
                                {shift?.teamLeader?.lastName}
                              </Badge>
                            </div>
                          ) : (
                            <div className="item">
                              <Badge className="shiftmembersTL" color="secondary" style={{opacity:.45}}>No TeamLeader</Badge>
                            </div>
                          )}
                          {shift.members?.filter(
                            member => member._id !== shift.teamLeader?._id
                          ).map((member, index) => {
                            return (
                              <div className="item" key={index}>
                                <Badge className="shiftmembers  badgeoutline" color="light">
                                  {member?.firstName} {member?.lastName}
                                </Badge>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              No shifts Available
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Spinner color="dark" />
        </div>
      )}
    </div>
  );
};
export default Rightbar;
