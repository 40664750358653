import React,{ useEffect ,useState} from "react";
import { getSystemIfHasResult, genPillColor, mapExistHasStatus, resultIfExists} from './globalReportHelper';
import moment from "moment";
import {useSelector,useDispatch} from "react-redux";
import { clearLayout, getDataReport, getOneResultForReport, retreiveLayoutReport, clearGlobalReport } from "../../../actions/globalReportAction";
import { retrieveCustomers, getCustomer } from "../../../actions/customerAction";
import { retrieveType, clearTypes } from "../../../actions/typeAction";
import { retrieveCategory, clearCategorys } from "../../../actions/categoryAction";
import { useLocation } from "react-router-dom";
import { v4 } from "uuid";

import * as FileSaver from 'file-saver';
import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js';

import { processTableHeight } from "../../../assets/js/CoreJs";


const useGlobalReport = () => {


  const location = useLocation();
  const dispatch = useDispatch()
  const globalReducerMonitoringActivites = useSelector(state => state.globalReportReducer.monitoringActivities)
  const globalReportReducerSystem = useSelector(state => state.globalReportReducer.systems)
  const oneResult = useSelector(state => state.globalReportReducer.OneResult)
  const IsLoadingResult = useSelector(state => state.globalReportReducer.IsLoadingResult)
  const isLoadingDataReport = useSelector(state => state.globalReportReducer.isLoadingDataReport)
  const DataReport = useSelector(state => state.globalReportReducer.DataReport)
  const customers = useSelector(state => state.customer.customers.docs)

  const [customer, setcustomer] = useState()

  const types = useSelector(state => state.typeSystem.types.docs)
  const categories = useSelector(state => state.categorySystem.categorys.docs)

  const timezone = useSelector(state => state.user.timeZone);

  /// Change Wael ////
  const customerSelected = useSelector((state) => state.customer.customer);
  const [defaultSelectedValue, setdefaultSelectedValue] = useState(null)
  const [reRender, setreRender] = useState()

  const applyTabHeight = () => {   
    processTableHeight(
      ["TitlePage", "headerContent"],
      "GlobalReportTableTCONT"
    );
  };

  useEffect(() => {
    applyTabHeight();
    window.addEventListener("resize", applyTabHeight);

    return () => {
      window.removeEventListener("resize", applyTabHeight);
    };
  }, []);

  useEffect(() => {
    dispatch(retrieveCustomers(false, null, null, true))
    dispatch(retrieveType(0,999999999,true))
    dispatch(retrieveCategory(0,9999,true))
  }, [dispatch]);

  const [categoryOptions, setcategoryOptions] = useState([])
  const [customerOptions, setcustomerOptions] = useState([])
  const [typesOptions, settypesOptions] = useState([])

  useEffect(() => {
   dispatch(clearLayout())
  }, [dispatch]);

  useEffect(() => {
    setcustomerOptions(customers?.map((cust)=> ({
      value: cust._id,
      label: cust.label
    }))); 
    settypesOptions(types?.map((typ)=> ({
      value: typ._id,
      label: typ.type
    })));
    setcategoryOptions(categories?.map((cat)=> ({
      value: cat._id,
      label: cat.category
    })));
  }, [customers,types,categories]);

  const [typeIds, settypeIds] = useState([])
  const [categoryIds, setcategoryIds] = useState([])

  function handlechangeCategory(e){
    if(e){ setcategoryIds(e) }
    else{ setcategoryIds(null) }
  }

  function handlechangeType(e){
    if(e){ settypeIds(e) }
    else{ settypeIds(null) }
  }

  function handlechangecustomer(e){
    if(e){ setcustomer(e) }
    else{
      setcustomer(null)
      setdefaultSelectedValue(null)
      setreRender(v4())
    }
  }

  const [date, setdate] = useState()
  useEffect(() => {
    if (location?.data?.id){
      dispatch(getCustomer(location?.data?.id,timezone));
    }
  }, [dispatch,location?.data?.id,timezone,]);

  useEffect(() => {
    if(customerSelected){
      const data = {
        value: customerSelected?._id,
        label: customerSelected?.label
      }
      setdefaultSelectedValue(data)
      setreRender(v4())
      setcustomer(data)
    }else{
      setcustomer(null)
      setdefaultSelectedValue(null)
      setreRender(v4())
    }
  }, [dispatch,customerSelected ])

////// wael 

  useEffect(() => {
    let types = typeIds.map((ty)=>ty.value);
    let categories = categoryIds.map((ca)=>ca.value);
    if(defaultSelectedValue){
      dispatch(retreiveLayoutReport(defaultSelectedValue?.value,types,categories));
    }else{
      setdefaultSelectedValue(null)
    }  
  }, [dispatch,defaultSelectedValue,typeIds,categoryIds]);

///// wael


  useEffect(() => {
    let mapsIds = [];
    if(globalReducerMonitoringActivites && globalReducerMonitoringActivites.length>=0){
      globalReducerMonitoringActivites.map( (monitoringActivity) => {
        mapsIds = [...mapsIds, ...monitoringActivity.mapsIds];
        return mapsIds;
      });
    }

    const filter =  {
      mapsIds : mapsIds,
      date:date,
      timeZone:timezone
    }
      
    dispatch(getDataReport(filter))
  }, [dispatch,date,globalReducerMonitoringActivites, timezone]);


  const [NewDataReport, setNewDataReport] = useState([])

  useEffect(() => {
    if(DataReport){
      if(DataReport.length>0){
        const values = globalReportReducerSystem.map((system, idx) => 
          !getSystemIfHasResult(system.sysId, DataReport)
          ? {systemId : system.sysId, systemName: system.systemName, result: []}
          : getSystemIfHasResult(system.sysId, DataReport)
        );
        setNewDataReport(values)
      }else{
        setNewDataReport([])
      }
    }else{
      setNewDataReport()
    }
  }, [DataReport, globalReportReducerSystem]);

  useEffect(() => {
    let types = typeIds.map((ty)=>ty.value);
    let categories = categoryIds.map((ca)=>ca.value);
    if(customer){
      dispatch(retreiveLayoutReport(customer?.value,types,categories));
    }
  }, [dispatch,customer,typeIds,categoryIds]);

  const [system, setsystem] = useState("")
  
  const [TaskData, setTaskData] = useState("")
  const [modalTaskDetails, setModalTaskDetails] = useState(false)
  
  function handleModalShow(result, dataReport){
    setTaskData(result);
    setsystem(dataReport.systemName)
    if(result){
      dispatch(getOneResultForReport(result.resultId));
      toggleModalTaskDetails();
    }
  }
  
  function toggleModalTaskDetails() {
    setModalTaskDetails(!modalTaskDetails)
  }
  function handleDate(e) {
    if(e){ setdate(moment(e?._d).format()) }
    else{ setdate() }
  }

  const [DateNow, setDateNow] = useState(moment().format("DD/MM/YYYY LT"))
  useEffect(() => {
    setDateNow(moment().format("DD/MM/YYYY LT"),)
  },[])
  const [wb,setWb]=React.useState(new ExcelJS.Workbook())
  const user = useSelector(state => state.user)

  const createExcel = async () => {
    wb.creator = `${user.firstName} ${user.lastName}`;
    wb.lastModifiedBy = `${user.firstName} ${user.lastName}`;
    wb.created = new Date();
    wb.modified = new Date();
    wb.lastPrinted = new Date();

    var ws = wb.addWorksheet(customer?.label, {
      views: [{ showGridLines: false }],
    });

    ws.addRow([
      customer?.label +
        " Monitoring report "+
        moment().format("DD/MM/YYYY LT")
    ]);

    ws.mergeCells("A1", "F1");
    ws.getRow(1).font = { size: 13, bold: true };
    ws.getCell("A1").alignment = { horizontal: "center", vertical: "middle" };
    ws.getRow(1).height = 60;
    ws.getRow(1).getCell(1).fill=
    { type: 'pattern', pattern: 'solid', fgColor:{argb:'f0cd8c'}, bgColor:{argb:'f0cd8c'} };
    const headerExcel = globalReducerMonitoringActivites.map((monitoringActivity) => monitoringActivity.name);
    headerExcel.unshift("      ");
    ws.addRow(headerExcel);
    ws.getRow(2).font = { size: 10, bold: true};
    ws.getRow(2).height = 20;

    NewDataReport?.forEach((dataReportLine) => {
      let contentTableArray = globalReducerMonitoringActivites.map(
        (monitoringActivity) => {
          const foundResult = resultIfExists(dataReportLine, monitoringActivity.mapsIds);
          return foundResult ? foundResult.globalStatus : '-';
        }
      );

      //console.log("contant table array", contentTableArray);
      contentTableArray.unshift(dataReportLine.systemName);
      return ws.addRow(contentTableArray);
    });

    for (let i = 1; i <= ws.columnCount; i++) {
      for (let j = 2; j <= ws.rowCount; j++) {
        let data = ws.getRow(j).getCell(i).toString();
        if (data) {
          ws.getRow(j).getCell(i).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" }
          };
        }
        let filldata = {
          type: "pattern",
          pattern: "lightUp"
        };
        ws.getRow(j).getCell(i).font = {
          name: 'Arial Black',
          family: 2,
          size: 12
        };
        if(data==="-"){
          ws.getRow(j).getCell(i).alignment = {
            horizontal: "center",
            vertical: "middle"
          };
        }
        if(data === "Warning"){
          ws.getRow(j).getCell(i).fill = {
            ...filldata,
            fgColor: { argb: "fff3cd" },
            bgColor: { argb: "fff3cd" }
          };
          ws.getRow(j).getCell(i).alignment = {
            horizontal: "center",
            vertical: "middle"
          };
        }
        if(data === "Critical"){
          ws.getRow(j).getCell(i).fill = {
            ...filldata,
            fgColor: { argb: "f8d7da" },
            bgColor: { argb: "f8d7da" }
          };
          ws.getRow(j).getCell(i).alignment = {
            horizontal: "center",
            vertical: "middle"
          };
        }
        if(data === "Good"){
          ws.getRow(j).getCell(i).fill = {
            ...filldata,
            fgColor: { argb: "d4edda" },
            bgColor: { argb: "d4edda" }
          };
          ws.getRow(j).getCell(i).alignment = {
            horizontal: "center",
            vertical: "middle"
          };
        }
        if(data === "-"){
          ws.getRow(j).getCell(i).fill={ type: 'pattern', pattern: 'solid', fgColor:{argb:'d0def9'}, bgColor:{argb:'d0def9'} };
          ws.getRow(j).getCell(i).alignment = {
            horizontal: "center",
            vertical: "middle"
          };
        }
      }
    }
  
    // Get cell length 
    ws.columns.forEach(function (column, i){
      let maxLength = 0;
      if(column._number===1){
        column["eachCell"]({ includeEmpty: true }, function (cell) {
            let columnLength = cell.value ? cell.value.toString().length/2 : 5;
            if (columnLength > maxLength ) {
                maxLength = columnLength;
            }
        });
        column.width = maxLength < 10 ? 10 : maxLength;
      }
    });

    // create sheet by system 
    DataReport.forEach((sys) => {
      let wsDetails = wb.addWorksheet(sys.systemName, {
        views: [{ showGridLines: false }],
      });
      wsDetails.addRow([sys.systemName + "  Status Details "]);
      wsDetails.mergeCells("A1", "F1");
      wsDetails.getRow(1).font = { size: 12, bold: true };
      wsDetails.getRow(1).getCell(1).fill = { type: 'pattern', pattern: 'solid', fgColor:{argb:'f0cd8c'}, bgColor:{argb:'f0cd8c'} };
      wsDetails.getCell("A1").alignment = {
        horizontal: "center",
        vertical: "middle"
      };
      wsDetails.getRow(1).height = 50;
      wsDetails.addRow();

      sys.result.forEach((result) => {

        //result.activity === 'ST22' && console.log(result);

        let rowResult=wsDetails.addRow([result.activity]);
        rowResult.getCell(1).fill= { type: 'pattern', pattern: 'solid', fgColor:{argb:'f0cd8c'}, bgColor:{argb:'f0cd8c'} };
      
        wsDetails.addRow(Object.values(result.schema));
        let startRowindexResult;
        let endRowindexResult;
        result?.dataSet?.forEach((dataset) => {
          const orderedRow = Object.keys(result.schema).map(schKey => dataset[schKey] || '---' );
          wsDetails.addRow(orderedRow);
        });
        
        wsDetails.addRow([""]);
        wsDetails.addRow([""]);

        if(result.files.length>0){
          wsDetails.addRow(["Files"]);
          result.files.forEach((imageID)=>{
            startRowindexResult = wsDetails.addRow([""])
            wsDetails.addRow([""])
            wsDetails.addRow([""])
            endRowindexResult = wsDetails.addRow([""])
            const myBase64Image = `data:image/${imageID.extension};base64,${imageID.data}`;
            const image2=  wb.addImage({
              base64: myBase64Image,
              extension: imageID.extension,
              ext: { width: 100, height: 500 }
            })
            wsDetails.addImage(image2, `${startRowindexResult.getCell(1)._address}:${endRowindexResult.getCell(1)._address}`);
            wsDetails.addRow([])
          });        
        }

        wsDetails.addRow([""]);
        wsDetails.addRow([""]);
      });

      for (var i = 1; i <= wsDetails.columnCount; i++){
        let filldata = {
          type: "pattern",
          pattern: "lightUp"
        };
        for(var j = 2; j <= wsDetails.rowCount; j++){
          var data = wsDetails.getRow(j).getCell(i).toString();
          if(data){
            wsDetails.getRow(j).getCell(i).border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" }
            };
            wsDetails.getRow(j).getCell(i).fill = { ...filldata };
            wsDetails.getRow(j).getCell(i).fill = { ...filldata };
            wsDetails.getRow(j).getCell(i).alignment = {
              horizontal: "center",
              vertical: "middle"
            };
            wsDetails.getRow(j).font = { size: 9, bold: true };
          }
        }
      }

      wsDetails.columns.forEach(function (column, i){
        let maxLength = 0;
        column["eachCell"]({ includeEmpty: false }, function (cell,j) {
          let columnLength = cell.value ? cell.value.toString().length : 8;
          if(columnLength > maxLength){ maxLength = columnLength; }
        });
        column.width = maxLength < 10 ? 10 : maxLength;
      });

      for (let i = 1; i <= wsDetails.columnCount; i++) {
        for (let j = 2; j <= wsDetails.rowCount; j++) {
          let data = wsDetails.getRow(j).getCell(i).toString();
          if(data){
            wsDetails.getRow(j).getCell(i).fill=
            { type: 'pattern', pattern: 'solid', fgColor:{argb:'d0def9'}, bgColor:{argb:'d0def9'} };
          }
        }
      }
    });//end create sheet by system

    ws.columns.forEach((col,i)=>{
      ws.getRow(2).getCell(i+1).fill=
        { type: 'pattern', pattern: 'solid', fgColor:{argb:'8caff0'}, bgColor:{argb:'8caff0'} };
    });

    ws._rows.forEach((col,i)=>{
      if(i!==0) ws.getRow(i+1).getCell(1).fill=
        { type: 'pattern', pattern: 'solid', fgColor:{argb:'8caff0'}, bgColor:{argb:'8caff0'} };
    });
  };//end createExcel

  const exportToCSV = async function(){
    await createExcel();
    const buf = await wb.xlsx.writeBuffer();
    await FileSaver.saveAs(new Blob([buf]),`Monitoring report ${customer?.label} ${moment().format("DD/MM/YYYY_LT")}.xlsx`);
    setWb (new ExcelJS.Workbook());
  }

  const renderSystemRow = (dataReport) => {
    return globalReducerMonitoringActivites.map(
      (monitoringActivity, idxMoniActiv) => {
        const foundResult = resultIfExists(dataReport, monitoringActivity.mapsIds);
        const displayResult = foundResult ? genPillColor(mapExistHasStatus(foundResult.mapId, dataReport), date) : '-';

        return <td className="resultdetails" key={idxMoniActiv} onClick={()=>handleModalShow(foundResult, dataReport)}>{displayResult}</td>
      }
    );
  }

  //Clear Data
  useEffect(() => {
    return () => {
      dispatch(clearGlobalReport());
      dispatch(clearTypes());
      dispatch(clearCategorys());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])



  return {
    moment,
    isLoadingDataReport,
    customer,
    DataReport,
    NewDataReport,
    globalReducerMonitoringActivites,
    DateNow,
    reRender,
    customerOptions,
    defaultSelectedValue,
    categoryIds,
    categoryOptions,
    typeIds,
    typesOptions,
    oneResult,
    IsLoadingResult,
    modalTaskDetails,
    TaskData,
    system,

    renderSystemRow,
    handlechangecustomer,
    handleDate,
    handlechangeCategory,
    handlechangeType,
    exportToCSV,
    toggleModalTaskDetails,
    setModalTaskDetails
  }
}

export default useGlobalReport;