import types from "../actions/types";
const {
  GET_ALL_NOTIFICATION_SUCCESS,
  GET_ALL_NOTIFICATION_FAILURE,
  LOAD_ALL_NOTIFICATION
} = types.notificationTypes;
const initialState = {
  notif: null,
  allNotif: [],
  allNotifLoading: true,
  error: null,
};

function notificationReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        allNotif: payload,
        allNotifLoading: false,
        error: null,
      };
    case GET_ALL_NOTIFICATION_FAILURE:
      return {
        ...state,
        allNotifLoading: false,
        error: payload,
      };
    case LOAD_ALL_NOTIFICATION:
      return {
        ...state,
        allNotifLoading: true,
      };

    default:
      return state;
  }
}

export default notificationReducer;
