import types from "../actions/types";

const {
  RETRIEVE_CUSTOMERS_FAILURE,
  RETRIEVE_CUSTOMERS_SUCCESS,
  LOAD_CUSTOMERS_TASK,
  LOAD_SYSTEMS_TASK,
  RETRIEVE_SYSTEMS_SUCCESS,
  RETRIEVE_SYSTEMS_FAILURE,
  LOAD_CATEGORIES_TASK,
  RETRIEVE_CATEGORIES_SUCCESS,
  RETRIEVE_CATEGORIES_FAILURE,

  LOAD_TYPES_TASK,
  RETRIEVE_TYPES_SUCCESS,
  RETRIEVE_TYPES_FAILURE,

  LOAD_MEMBERS_TASK,
  RETRIEVE_MEMBERS_SUCCESS,
  RETRIEVE_MEMBERS_FAILURE,
  LOAD_TASK,
  RETRIEVE_TASKS_SUCCESS,
  RETRIEVE_TASKS_FAILURE,
  ADD_TASKS_SUCCESS,
  ADD_TASKS_FAILURE,
  ADD_ONE_TASK_SUCCESS,
  ADD_ONE_TASK_FAILURE,

  LOAD_SYSTEMS_FOR_MAPS,
  RETRIEVE_SYSTEMS_FOR_MAPS_SUCCESS,
  RETRIEVE_SYSTEMS_FOR_MAPS_FAILURE,

  LOAD_FILTRED_TASK,
  GET_FILTRED_TASKS_SUCCESS,
  GET_FILTRED_TASKS_FAILURE,

  GET_TASKS_BY_USER_ID_SUCCESS,
  GET_TASKS_BY_USER_ID_FAILURE,

  GET_FILTRED_TASKS_REQUEST_SUCCESS,
  GET_FILTRED_TASKS_REQUEST_FAILURE,

  GET_TASKS_REQUEST_BY_USER_ID_SUCCESS,
  GET_TASKS_REQUEST_BY_USER_ID_FAILURE,
  GET_CREATED_TASKS_SUCCESS,
  GET_CREATED_TASKS_FAILURE,
  LOAD_CREATED_TASK,
  UPDATE_TASK_FAILURE,
  UPDATE_TASK_SUCCESS,

  LOAD_SYSTEM_STATS,
  GET_SYSTEM_STATS_SUCCESS,
  GET_SYSTEM_STATS_FAILURE,

  LOAD_SYSTEM_FOR_HIGHTLIGHT_STATS,
  GET_SYSTEM_STATS_FOR_HIGHTLIGHT_SUCCESS,
  GET_SYSTEM_STATS_FOR_HIGHTLIGHT_FAILURE,

  GET_SYSTEM_STATS_FILTER_SUCCESS,
  GET_SYSTEM_STATS_FILTER_FAILURE,

  GET_ONE_TASK_REQUEST_BY_USER_ID_SUCCESS,
  GET_ONE_TASK_REQUEST_BY_USER_ID_FAILURE,

  LOAD_ONE_TASK,

  UPDATE_TASKS_REQUEST_BY_USER_ID_SUCCESS,
  UPDATE_TASKS_REQUEST_BY_USER_ID_FAILURE,

  GET_ALL_TASKS_BY_CRITERIA_SUCCESS,
  GET_ALL_TASKS_BY_CRITERIA_FAILURE,
  LOAD_TASKS_BY_CRITERIA,

  CLEAR_TASK_TRACKING,
  CLEAR_MY_TASKS,
  CLEAR_TASK_MANAGEMENT,

  GET_ALL_TASKS_ACTIVITY_LOG_SUCCESS,
  GET_ALL_TASKS_ACTIVITY_LOG_FAILURE,
  LOAD_TASKS_ACTIVITY_LOG,

  CREATE_SYNC_TASKS_ACTIVITY_LOG_SUCCESS,
  CREATE_SYNC_TASKS_ACTIVITY_LOG_FAILURE,
  LOAD_SYNC_TASKS_ACTIVITY_LOG_FOR_CREATE,
  CLEAR_TASKS_ACTIVITY_LOG

} = types.taskTypes;

const initialState = {
  AllCustomers: [],
  AllSystems: [],
  AllSystemsFormMap: [],
  AllCategories: [],
  AllTypes: [],
  AllMembers: [],
  AllTasks: [],

  createdTasks: [],
  systemStats: [],
  systemStatsForHighlight: [],
  isloadingSystemStatsForHighlight: false,

  error: null,
  isLoadingCustomer: false,
  isLoadingSystem: false,
  isLoadingCategories: false,
  isLoadingTypes: false,
  isLoadingMembers: false,
  isloadingTasks: false,
  isloadingMaps: false,

  isloadingCreatedTasks: false,
  isloadingSystemStats: false,

  //wael work

  task: null,
  myTasks: [],
  tasks: [],

  tasksRequest: [],
  isLoading: false,

  tasksListError: null,
  listError: null,
  tasksMemberRequest: [],
  isLoadingOneTaskReq: false,
  //msg:"",

  tasksByCriteria: [],
  isLoadingTasksByCriteria: false,
  tasksByCriteriaListError: null,

  taskActivityLogs: [],
  isLoadingTaskActivityLogs: false,
  taskActivityLogsListError: null,
  isLoadingForCreationSyncLog: false,
};

function taskReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_CUSTOMERS_SUCCESS:
      return {
        ...state,
        AllCustomers: payload,
        isLoadingCustomer: false,
      };
    case RETRIEVE_CUSTOMERS_FAILURE:
      return {
        ...state,
        error: payload,
        isLoadingCustomer: false,
      };
    // case VALIDATE_TASK_SUCCESS:
    //   return {
    //     ...state,
    //     isloadingTasks: false,
    //   };
    // case VALIDATE_TASK_FAILURE:
    //   return {
    //     ...state,
    //     error: payload,
    //     isloadingTasks: false,
    //   };
    // case REJECT_TASK_SUCCESS:
    //   return {
    //     ...state,
    //     isloadingTasks: false,
    //   };
    // case REJECT_TASK_FAILURE:
    //   return {
    //     ...state,
    //     error: payload,
    //     isloadingTasks: false,
    //   };
    case LOAD_CUSTOMERS_TASK:
      return {
        ...state,
        isLoadingCustomer: true,
      };

    case GET_SYSTEM_STATS_SUCCESS:
      return {
        ...state,
        systemStats: payload,
        isloadingSystemStats: false,
      };
    case GET_SYSTEM_STATS_FAILURE:
      return {
        ...state,
        error: payload,
        isloadingSystemStats: false,
      };

    case GET_SYSTEM_STATS_FOR_HIGHTLIGHT_SUCCESS:
      return {
        ...state,
        systemStatsForHighlight: payload,
        isloadingSystemStatsForHighlight: false,
      };
    case GET_SYSTEM_STATS_FOR_HIGHTLIGHT_FAILURE:
      return {
        ...state,
        error: payload,
        isloadingSystemStatsForHighlight: false,
      };

    case LOAD_SYSTEM_FOR_HIGHTLIGHT_STATS:
      return {
        ...state,
        isloadingSystemStatsForHighlight: true,
      };

    case GET_SYSTEM_STATS_FILTER_SUCCESS:
      return {
        ...state,
        systemStats: payload,
        isloadingSystemStats: false,
      };
    case GET_SYSTEM_STATS_FILTER_FAILURE:
      return {
        ...state,
        systemStats: [],
        error: payload,
        isloadingSystemStats: false,
      };

    case LOAD_SYSTEM_STATS:
      return {
        ...state,
        isloadingSystemStats: true,
      };

    case RETRIEVE_SYSTEMS_SUCCESS:
      return {
        ...state,
        AllSystems: payload,
        isLoadingSystem: false,
      };
    case RETRIEVE_SYSTEMS_FAILURE:
      return {
        ...state,
        error: payload,
        isLoadingSystem: false,
      };
    case LOAD_SYSTEMS_TASK:
      return {
        ...state,
        isLoadingSystem: true,
      };
    case RETRIEVE_CATEGORIES_SUCCESS:
      return {
        ...state,
        AllCategories: payload,
        isLoadingCategories: false,
      };
    case RETRIEVE_CATEGORIES_FAILURE:
      return {
        ...state,
        error: payload,
        isLoadingCategories: false,
      };
    case LOAD_CATEGORIES_TASK:
      return {
        ...state,
        isLoadingCategories: true,
      };
    case RETRIEVE_TYPES_SUCCESS:
      return {
        ...state,
        AllTypes: payload,
        isLoadingTypes: false,
      };
    case RETRIEVE_TYPES_FAILURE:
      return {
        ...state,
        error: payload,
        isLoadingTypes: false,
      };
    case LOAD_TYPES_TASK:
      return {
        ...state,
        isLoadingTypes: true,
      };
    case RETRIEVE_MEMBERS_SUCCESS:
      return {
        ...state,
        AllMembers: payload,
        isLoadingMembers: false,
      };
    case RETRIEVE_MEMBERS_FAILURE:
      return {
        ...state,
        error: payload,
        isLoadingMembers: false,
      };
    case LOAD_MEMBERS_TASK:
      return {
        ...state,
        isLoadingMembers: true,
      };
    case RETRIEVE_TASKS_SUCCESS:
      return {
        ...state,
        AllTasks: payload,
        isloadingTasks: false,
      };
    case RETRIEVE_TASKS_FAILURE:
      return {
        ...state,
        error: payload,
        isloadingTasks: false,
      };
    case ADD_TASKS_SUCCESS:
      return {
        ...state,
        isloadingSystemStatsForHighlight: false,
      };
    case ADD_TASKS_FAILURE:
      return {
        ...state,
        error: payload,
        isloadingTasks: false,
        isloadingSystemStatsForHighlight: false,
      };
    case ADD_ONE_TASK_SUCCESS:
      return {
        ...state,
      };
    case ADD_ONE_TASK_FAILURE:
      return {
        ...state,
        error: payload,
      };
    case LOAD_TASK:
      return {
        ...state,
        isloadingTasks: true,
      };
    case RETRIEVE_SYSTEMS_FOR_MAPS_SUCCESS:
      return {
        ...state,
        AllSystemsFormMap: payload,
        isloadingMaps: false,
      };
    case RETRIEVE_SYSTEMS_FOR_MAPS_FAILURE:
      return {
        ...state,
        error: payload,
        isloadingMaps: false,
      };
    case LOAD_SYSTEMS_FOR_MAPS:
      return {
        ...state,
        isloadingMaps: true,
      };
    case GET_FILTRED_TASKS_SUCCESS:
      return {
        ...state,
        tasks: payload,
        isLoading: false,
        error: null,

        tasksListError: null,
        listError: null,
      };
    case GET_FILTRED_TASKS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,

        tasksListError: payload,
        listError: payload,
      };
    case LOAD_FILTRED_TASK: //chnage this ****************************************
      return {
        ...state,
        isLoading: true,
      };
    case GET_TASKS_BY_USER_ID_SUCCESS:
      return {
        ...state,
        myTasks: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case GET_TASKS_BY_USER_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: payload,
      };

    case GET_FILTRED_TASKS_REQUEST_SUCCESS:
      return {
        ...state,
        tasksRequest: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case GET_FILTRED_TASKS_REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: payload,
      };

    case GET_TASKS_REQUEST_BY_USER_ID_SUCCESS:
      return {
        ...state,
        tasksMemberRequest: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case GET_TASKS_REQUEST_BY_USER_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: payload,
      };
    case GET_CREATED_TASKS_SUCCESS:
      return {
        ...state,
        createdTasks: payload,
        isloadingCreatedTasks: false,
        error: null,
      };
    case GET_CREATED_TASKS_FAILURE:
      return {
        ...state,
        isloadingCreatedTasks: false,
        error: payload,
      };
    case LOAD_CREATED_TASK: //chnage this ****************************************
      return {
        ...state,
        isloadingCreatedTasks: true,
      };
    case UPDATE_TASK_SUCCESS:
      return {
        ...state,
        isloadingSystemStatsForHighlight: false,
      };
    case UPDATE_TASK_FAILURE:
      return {
        ...state,
        error: payload,
        isloadingSystemStatsForHighlight: false,
      };
    // case UPDATE_TASKS_REQUEST_BY_USER_ID_SUCCESS:
    //   const updatedIndex = state.tasksMemberRequest.docs.map((task, index) => {
    //     if (payload._id === task._id) {
    //       return index;
    //     } else return null;
    //   });

    //   if (updatedIndex) state.tasksMemberRequest.docs[updatedIndex] = payload;
    //   return {
    //     ...state,
    //     tasksMemberRequest: state.tasksMemberRequest,
    //     isLoading: false,
    //     error: null,
    //     listError: null,
    //   };

    // case UPDATE_TASKS_REQUEST_BY_USER_ID_FAILURE:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     error: payload,
    //     listError: null,
    //   };

    case GET_ONE_TASK_REQUEST_BY_USER_ID_SUCCESS:
      return {
        ...state,
        task: payload,
        isLoadingOneTaskReq: false,
        error: null,
        listError: null,
      };
    case GET_ONE_TASK_REQUEST_BY_USER_ID_FAILURE:
      return {
        ...state,
        isLoadingOneTaskReq: false,
        error: payload,
        listError: null,
      };

    case LOAD_ONE_TASK: //chnage this ****************************************
      return {
        ...state,
        isLoadingOneTaskReq: true,
      };

    case UPDATE_TASKS_REQUEST_BY_USER_ID_SUCCESS:
      const updatedIndex = state.tasksMemberRequest.map((task, index) => {
        if (payload._id === task._id) {
          return index;
        } else return null;
      });
      if (updatedIndex) state.tasksMemberRequest[updatedIndex] = payload;
      return {
        ...state,
        tasksMemberRequest: state.tasksMemberRequest,
        isLoading: false,
        error: null,
        listError: null,
        //msg:"Updated Successfully",
      };

    case UPDATE_TASKS_REQUEST_BY_USER_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
        //msg:"An Error occurred while updating",
      };

    case GET_ALL_TASKS_BY_CRITERIA_SUCCESS:
      return {
        ...state,
        tasksByCriteria: payload,
        isLoadingTasksByCriteria: false,
        error: null,
        tasksByCriteriaListError: null,
        listError: null,
      };
    case GET_ALL_TASKS_BY_CRITERIA_FAILURE:
      return {
        ...state,
        isLoadingTasksByCriteria: false,
        error: payload,
        tasksByCriteriaListError: payload,
        listError: payload,
      };
    case LOAD_TASKS_BY_CRITERIA: 
      return {
        ...state,
        isLoadingTasksByCriteria: true,
      };

    //TASKS_ACTIVITY_LOG

    case GET_ALL_TASKS_ACTIVITY_LOG_SUCCESS:
      return {
        ...state,
        taskActivityLogs: payload,
        isLoadingTaskActivityLogs: false,
        error: null,
        taskActivityLogsListError: null,
        listError: null,
      };
    case GET_ALL_TASKS_ACTIVITY_LOG_FAILURE:
      return {
        ...state,
        isLoadingTaskActivityLogs: false,
        error: payload,
        taskActivityLogsListError: payload,
        listError: payload,
      };
    case LOAD_TASKS_ACTIVITY_LOG: 
      return {
        ...state,
        isLoadingTaskActivityLogs: true,
      };


    case CREATE_SYNC_TASKS_ACTIVITY_LOG_SUCCESS:
      return {
        ...state,
        //shift: payload,
        // isLoading: false,
        isLoadingForCreationSyncLog: false,
        error: null,
        listError: null,
      };
    case CREATE_SYNC_TASKS_ACTIVITY_LOG_FAILURE:
      return {
        ...state,
        //isLoading: false,
        isLoadingForCreationSyncLog: false,
        error: payload,
        listError: payload,
      };
  
    case LOAD_SYNC_TASKS_ACTIVITY_LOG_FOR_CREATE:
      return {
        ...state,
        isLoadingForCreationSyncLog: true,
      };

    case CLEAR_TASK_TRACKING:
      return {
        ...state,             
        tasksByCriteria: [],
        
      };

    case CLEAR_TASKS_ACTIVITY_LOG:
      return {
        ...state,             
        taskActivityLogs: [],
        
      };

    
    

          case CLEAR_MY_TASKS:
            return {
              ...state,             
              AllSystems: [],
              AllSystemsFormMap: [],
              AllMembers: [],
              myTasks: [],
              tasksMemberRequest: [],
              task: null,
             // isLoadingSystem: false,
            };

          case CLEAR_TASK_MANAGEMENT:
          return {
            ...state,             
            AllCustomers: [],
            AllSystems: [],
            AllSystemsFormMap: [],
            AllCategories: [],
            AllTypes: [],
            AllMembers: [],
            AllTasks: [],
            createdTasks:[],
            systemStats:[],
            systemStatsForHighlight:[],
            tasks: [],
            tasksRequest: [],
          };

    default:
      return state;
  }
}
export default taskReducer;
