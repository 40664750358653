const LOAD_LANDSCAPE_TC = "LOAD_LANDSCAPE_TC";
const RETRIEVE_SYSTEM_CATEGORIES_SUCCESS =
  "RETRIEVE_SYSTEM_CATEGORIES_SUCCESS_TC";
const RETRIEVE_SYSTEM_CATEGORIES_FAILURE =
  "RETRIEVE_SYSTEM_CATEGORIES_FAILURE_TC";
const RETRIEVE_SYSTEM_TYPES_SUCCESS = "RETRIEVE_SYSTEM_TYPES_SUCCESS_TC";
const RETRIEVE_SYSTEM_TYPES_FAILURE = "RETRIEVE_SYSTEM_TYPES_FAILURE_TC";
const LOAD_CATEGORIES_TC = "LOAD_CATEGORIES_TC";
const RETRIEVE_SYSTEMS_SUCCESS = "RETRIEVE_SYSTEMS_SUCCESS_TC";
const RETRIEVE_SYSTEMS_FAILURE = "RETRIEVE_SYSTEMS_FAILURE_TC";
const LOAD_SYSTEM = "LOAD_SYSTEM_TC";
const CLEAR_SYSTEMS_TC = "CLEAR_SYSTEMS_TC";


let landScaptcTypes = {
  LOAD_LANDSCAPE_TC,
  RETRIEVE_SYSTEM_CATEGORIES_SUCCESS,
  RETRIEVE_SYSTEM_CATEGORIES_FAILURE,
  RETRIEVE_SYSTEM_TYPES_SUCCESS,
  RETRIEVE_SYSTEM_TYPES_FAILURE,
  LOAD_CATEGORIES_TC,
  RETRIEVE_SYSTEMS_SUCCESS,
  RETRIEVE_SYSTEMS_FAILURE,
  LOAD_SYSTEM,
  CLEAR_SYSTEMS_TC,
  
};
export default landScaptcTypes;
