import types from "../actions/types";
const {
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_FAILURE,
  LOAD_CLIENT,
  GET_ALL_CLIENTS_SUCCESS,
  GET_ALL_CLIENTS_FAILURE,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILURE,
  RETRIEVE_ONE_CLIENT_SUCCESS,
  RETRIEVE_ONE_CLIENT_FAILURE,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  CLEAR_CLIENTS,
  CLEAR_ONE_CLIENT
} = types.clientTypes;
const initialState = {
  client: null,
  clients: [],
  isLoading: false,
  error: null,
  listError: null,
};

function userReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_CLIENT_SUCCESS:
      state.clients.push(payload);
      return {
        ...state,
        clients: state.clients,
        isLoading: false,
        error: null,
        listError: null,
      };
    case CREATE_CLIENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

    case GET_ALL_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case GET_ALL_CLIENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: payload,
      };

    case LOAD_CLIENT:
      return {
        ...state,
        isLoading: true,
      };

    case DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case DELETE_CLIENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

    case RETRIEVE_ONE_CLIENT_SUCCESS:
      return {
        ...state,
        client: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case RETRIEVE_ONE_CLIENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

    case UPDATE_CLIENT_SUCCESS:
      const updatedIndex = state.clients.map((client, index) => {
        if (payload._id === client._id) {
          return index;
        } else return null;
      });
      if (updatedIndex) state.clients[updatedIndex] = payload;
      return {
        ...state,
        clients: state.clients,
        isLoading: false,
        error: null,
        listError: null,
      };

    case UPDATE_CLIENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

    
    case CLEAR_CLIENTS:
      return {
        ...state,
        clients: [],
        isLoading: false,
        error: null,
        listError: null,
      };
    case CLEAR_ONE_CLIENT:
      return {
        ...state,
        client: null,
        isLoading: false,
        error: null,
        listError: null,
      };
    default:
      return state;
  }
}

export default userReducer;
