import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Form
} from "reactstrap";
import { TablePagination } from "@material-ui/core";
import { BsChevronDoubleLeft, BsTrash, BsPencil } from "react-icons/bs";
import Select from "../../../../shared/select/Select";
import { processTableHeight, makeDelay } from "../../../../assets/js/CoreJs";
import "./SlaContracts.css";

import { RetreiveOneSlaContract } from "../../../../actions/slaContractAction";
import {
  createSla,
  retrieveSlas,
  updateSla,
  RetreiveOneSla,
  deleteSla,
  clearSlas,
  clearOneSla
} from "../../../../actions/slaAction";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./SlaContractDetailsValidation";

const optionsKPI = [
  { value: "escalation", label: "escalation" },
  { value: "response", label: "response" },
  { value: "takeOver", label: "takeOver" },
  { value: "resolution", label: "resolution" },
];

const optionsUnits = [
  { value: "day", label: "day" },
  { value: "hour", label: "hour" },
  { value: "minute", label: "minute" },
  { value: "second", label: "second" },
];

const SlaContractDetails = function ({ selectedCont, handleGoBack }) {
  //temporary to get index from fakeData json
  // const slaIndex = Sla.findIndex((s) => s.slaId === selectedCont);

  // console.log(selectedCont);

  const ErrorAction = useSelector((state) => state.sla.error);
  const [addModal, setAddModal] = useState(false);
  const toggleAdd = () => {
    setAddModal(!addModal);
    reset();
  };
  const [editModal, setEditModal] = useState(false);
  //const toggleEdit = () => setEditModal(!editModal);
  const [refrechData, setrefrechData] = useState(false);
  // const [searchTerm, setSearchTerm] = useState("");

  const totalDocs = useSelector((state) => state.sla.slas.totalDocs);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const {
    control,
    handleSubmit,
    setValue,
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const selectInvalidFeedback = {
    width: "100%",
    marginTop: ".25rem",
    fontSize: "80%",
    color: "#dc3545",
  };

  if(errors.kpi){
    document.querySelector('.customerSelectTimeZone > div:nth-child(2)') &&
    document.querySelector('.customerSelectTimeZone > div:nth-child(2)').classList.add('isInvalidSelect');
  }


  if(errors.unit){
    document.querySelector('.customselectunit > div:nth-child(2)') &&
    document.querySelector('.customselectunit > div:nth-child(2)').classList.add('isInvalidSelect');
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setrefrechData(!refrechData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setrefrechData(!refrechData);
  };

  const confirmDelete = (id) => {
    if (window.confirm("Are you sure you want to delete the contract ?")) {
      dispatch(deleteSla(id));
      setrefrechData(!refrechData);
    }
  };

  const slas = useSelector((state) => state.sla.slas.docs);
  //console.log("Liste des SLA ===>", slas);
  const sla = useSelector((state) => state.sla.sla);
  //console.log("SLA Selected ===>", sla);
  //const [exempleSLA, setExempleSLA] = useState("61546b730364ec375020dd08");
  const isloading = useSelector((state) => state.sla.isLoading);
  const error = useSelector((state) => state.sla.listError);

  // console.log("total docs =====", totalDocs);

  const applyTabHeight = () => {
    processTableHeight(
      [
        "TitlePage",
        "customerDetailsTabsNav",
        "sysDetailHeader",
        "customerSystemDetailsTabsNav",
        "deTopHeader",
        "slaDetailsHeader",
        "kpiPagination",
      ],
      "kpiTCONT",
      50
    );
  };

  const dispatch = useDispatch();
  useEffect(() => {
    applyTabHeight();
    window.addEventListener("resize", applyTabHeight);

    dispatch(RetreiveOneSlaContract(selectedCont));

    //return fn must be last in useEffect
    return () => {
      window.removeEventListener("resize", applyTabHeight);
    };
  }, [dispatch, selectedCont]);

  const slaContractRow = useSelector((state) => state.slaContract.slaContract);
  //console.log(slaContractRow);

  const toggleEdit = async (e, id) => {
    e.preventDefault();
    if (id) {
      // const optionsClass = await dispatch(retrieveSysClass());

      const res = await dispatch(RetreiveOneSla(id));
      if (res) {

        setValue("description",res.desc);  

        setValue("kpi",{
          value: res.kpi,
          label: res.kpi,
        });
        
        setValue("unit",{
          value: res.unity,
          label: res.unity,
        });

        setValue("priority", res.priority);
        setValue("time", res.time);
 
        // setKpi({
        //   value: res.kpi,
        //   label: res.kpi,
        // });
        
        // setKpi(res.kpi);

        // setUnity({
        //   value: res.unity,
        //   label: res.unity,
        // });
        // setDesc(res.desc);

        // setPriority(res.priority);
        // setTime(res.time);

        // setstartDate(res.startDate);
        // setClass({
        //   value: res.class._id,
        //   label: res.class.libelle,
        // });
      }
    }
    setEditModal(!editModal);
  };

  //kpi Sla
  // const [kpi, setKpi] = useState();
  // const handelKpi = (e) => {
  //   setKpi(e.label);
  // };

  //Unity Sla
  // const [unity, setUnity] = useState();
  // const handelUnity = (e) => {
  //   setUnity(e.label);
  // };

  //Description Sla
  // const [desc, setDesc] = useState("");
  // const handelDesc = (e) => {
  //   setDesc(e.target.value);
  // };

  //priority Sla
  // const [priority, setPriority] = useState("");
  // const handelPriority = (e) => {
  //   setPriority(e.target.value);
  // };

  //time Sla
  // const [time, setTime] = useState("");
  // const handelTime = (e) => {
  //   setTime(e.target.value);
  // };

  //Add New Sla
  function handleCreateSla({unit,kpi,description,priority,time}) {
    const newSla = {
      kpi: kpi.value,
      unity: unit.value,
      desc: description,
      slaContract: selectedCont,
      priority: priority,
      time: time,
    };
    dispatch(createSla(newSla));
    setrefrechData(!refrechData);
    setAddModal(!addModal);
    if (!ErrorAction) {
      setAddModal(!addModal);
    }
    // console.log(newSla);
  }

  useEffect(() => {
    if (selectedCont) {
      dispatch(retrieveSlas(selectedCont, page, rowsPerPage, searchValue));
    }
    
  }, [dispatch, selectedCont, page, rowsPerPage,searchValue, refrechData]);

//Clear Data
  useEffect(() => {
    return () => {
      dispatch(clearSlas());
      dispatch(clearOneSla());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  // Edit SLA
  function handleEdit({unit,kpi,description,priority,time}) {
    // setKpi({
    //   value: kpi.value,
    //   label: kpi.label,
    // });
    // setUnity({
    //   value: unity.value,
    //   label: unity.label,
    // });
    // setDesc(desc);
    // setPriority(priority);
    // setTime(time);
    if (kpi.value) {
      const updatedData = {
        kpi: kpi.value,
        unity: unit.value,
        desc: description,
        //slaContract: selectedCont,
        priority: priority,
        time: time,
      };
      dispatch(updateSla(sla._id, updatedData));
    }
   else if (unit.value) {
      const updatedData = {
        kpi: kpi.value,
        unity: unit.value,
        desc: description,
        //slaContract: selectedCont,
        priority: priority,
        time: time,
      };
      dispatch(updateSla(sla._id, updatedData));
    }
    else if (unit.value && kpi.value) {
      const updatedData = {
        kpi: kpi.value,
        unity: unit.value,
        desc: description,
        //slaContract: selectedCont,
        priority: priority,
        time: time,
      };
      dispatch(updateSla(sla._id, updatedData));
    } else {
      const updatedData = {
        kpi: kpi,
        unity: unit,
        desc: description,
        //slaContract: selectedCont,
        priority: priority,
        time: time,
      };
      dispatch(updateSla(sla._id, updatedData));
    }

    if (!error) {
      setrefrechData(!refrechData);
      setEditModal(!editModal);
    } else {
      // console.error(error);
    }
  }

  const handelSearchQuerry = (e) => {
    makeDelay(() => {
      if (e.target.value.length >= 2) {
        setSearchValue(e.target.value);
        setPage(0);
      } else {
        setSearchValue("");
      }
    }, 750);
  };

  useEffect(() => {
    return () => clearTimeout(window.timerDelay);
  }, []);

  return (
    <div className="customerSlaContractDetails">
      <Row className="deTopHeader">
        <Col>
          <span onClick={handleGoBack}>
            <BsChevronDoubleLeft></BsChevronDoubleLeft>
          </span>
          <h2>{slaContractRow?.class?.libelle} SLA Contract Details</h2>
        </Col>
        <Col>
          <label>Start Date</label>
          <div>{slaContractRow?.startDate.split("T")[0]}</div>
          <label>End Date</label>
          <div>{slaContractRow?.endDate.split("T")[0]}</div>
        </Col>
      </Row>
      <hr className="mt-3 mb-4" />
      <Row className="slaDetailsHeader">
        <Col xs="2">
          <Button color="primary" onClick={toggleAdd}>
            Add KPI
          </Button>
        </Col>
        <Col xs={{ size: 4, offset: 6 }}>
          <Input
            type="search"
            className="form-control"
            placeholder="Search by KPI, Unit"
            onChange={handelSearchQuerry}
            />
        </Col>
      </Row>
      <div className="slaDetailsContent">
        <div id="kpiTCONT" className="avxTableContainer scrollTb">
          <Table
            className="slaContractsDetailsTable avxBsTable twoActs"
            striped
          >
            <thead>
              <tr>
                <th>KPI</th>
                <th>Unit</th>
                <th>Description</th>
                <th>Priority</th>
                <th>Time</th>
                <th>
                  <span>
                    <BsPencil></BsPencil>
                  </span>
                </th>
                <th>
                  <span>
                    <BsTrash></BsTrash>
                  </span>
                </th>
              </tr>
            </thead>
            {isloading === false ? (
              <tbody>
                {slas && error === null ? (
                  <>
                    {slas
                      .map((sl) => (
                        <tr key={sl._id}>
                          <td>{sl.kpi}</td>
                          <td>{sl.unity}</td>
                          <td>{sl.desc}</td>
                          <td>{sl.priority}</td>
                          <td>{sl.time}</td>
                          <td>
                            <span
                              className="tbBtn"
                              onClick={(e) => toggleEdit(e, sl._id)}
                            >
                              <BsPencil></BsPencil>
                            </span>
                          </td>
                          <td>
                            <span
                              className="tbBtn"
                              onClick={() => confirmDelete(sl._id)}
                            >
                              <BsTrash></BsTrash>
                            </span>
                          </td>
                        </tr>
                      ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan="7">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                        }}
                      >
                        <span> No SLA Exist For this Sla Contarct !!! </span>
                        {/* <span>{error} </span> */}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="7">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: 100,
                      }}
                    >
                      <Spinner color="dark" />
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
        <TablePagination
          className="kpiPagination"
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          component="div"
          count={totalDocs ? totalDocs : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>

      <Modal
        isOpen={addModal}
        toggle={toggleAdd}
        className="addKpiModal"
        centered
        fade={false}
        backdrop="static"
        keyboard={true}
      >

          <Form
          onSubmit={handleSubmit(handleCreateSla)}
          className="formModalAddCustomer"
        >
        <ModalHeader toggle={toggleAdd}>Add new KPI</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>KPI
            <span className="requiredField"> *</span>
            </Label>
            {/* <Select
              placeholder="Select KPI"
              options={optionsKPI}
              onChange={handelKpi}
            /> */}
              <Controller
                name="kpi"
                control={control}
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <Select
                    className="customerSelectTimeZone"
                    placeholder="Select KPI"
                    options={optionsKPI}
                    {...rest}
                    {...fieldState}
                  />
                )}
              />
              <small style={selectInvalidFeedback}>
                {errors.kpi && <>{errors.kpi.value.message}</>}
              </small>


          </FormGroup>
          <FormGroup>
            <Label>Unit
            <span className="requiredField"> *</span>
            </Label>
            {/* <Select
              placeholder="Select unit"
              options={optionsUnits}
              onChange={handelUnity}
            /> */}
          <Controller
                name="unit"
                control={control}
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <Select
                    className="customselectunit"
                    placeholder="Select unit"
                    options={optionsUnits}
                    {...rest}
                    {...fieldState}
                  />
                )}
              />
              <small style={selectInvalidFeedback}>
                {errors.unit && <>{errors.unit.value.message}</>}
              </small>


          </FormGroup>
          <FormGroup>
            <Label>Description
            <span className="requiredField"> *</span>
            </Label>



                <input
                    type="textarea" name="description" id="description" placeholder="description"
                    className={ "form-control" + (errors.description ? " is-invalid" : "") }
                    {...register("description")}
                  />
                  <small className="invalid-feedback">
                    {errors.description && <>{errors.description.message}</>}
                  </small>


{/* 
            <Input
              type="textarea"
              placeholder="Enter KPI description"
              onChange={handelDesc}
            /> */}


          </FormGroup>
          <Row>
            <Col>
              <FormGroup>
                <Label>Priority
                <span className="requiredField"> *</span>
                </Label>
                <input
                    type="number" name="priority" id="priority" placeholder="priority"
                    className={ "form-control" + (errors.priority ? " is-invalid" : "") }
                    {...register("priority")}
                  />
                  <small className="invalid-feedback">
                    {errors.priority && <>{errors.priority.message}</>}
                  </small>
                {/* <Input type="number" onChange={handelPriority} /> */}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Time
                <span className="requiredField"> *</span>
                </Label>

                <input
                    type="number" name="time" id="time" placeholder="time"
                    className={ "form-control" + (errors.time ? " is-invalid" : "") }
                    {...register("time")}
                  />
                  <small className="invalid-feedback">
                    {errors.time && <>{errors.time.message}</>}
                  </small>



                {/* <Input type="number" onChange={handelTime} /> */}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit">
            Add KPI
          </Button>{" "}
          <Button color="secondary" onClick={toggleAdd}>
            Cancel
          </Button>
        </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={editModal}
        toggle={toggleEdit}
        className="editKpiModal"
        centered
        fade={false}
        backdrop="static"
        keyboard={true}
      >
        <Form onSubmit={handleSubmit(handleEdit)} className="formModalAddCustomer">
        <ModalHeader toggle={toggleEdit}>Update KPI</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>KPI
            <span className="requiredField"> *</span>
            </Label>
 


            <Controller
                name="kpi"
                control={control}
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <Select
                    className="customerSelectTimeZone"
                    placeholder="Select KPI"
                    options={optionsKPI}
                    {...rest}
                    {...fieldState}
                  />
                )}
              />
              <small style={selectInvalidFeedback}>
                {errors.kpi && <>{errors.kpi.value.message}</>}
              </small>
          </FormGroup>
          <FormGroup>
            <Label>Unit
            <span className="requiredField"> *</span>
            </Label>


            <Controller
                name="unit"
                control={control}
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <Select
                    className="customselectunit"
                    placeholder="Select KPI"
                    options={optionsUnits}
                    {...rest}
                    {...fieldState}
                  />
                )}
              />
              <small style={selectInvalidFeedback}>
                {errors.unit && <>{errors.unit.value.message}</>}
              </small>
          </FormGroup>
          <FormGroup>
            <Label>Description
            <span className="requiredField"> *</span>
            </Label>

                  <input
                      type="textarea" name="description" id="description" placeholder="description"
                      className={ "form-control" + (errors.description ? " is-invalid" : "") }
                      {...register("description")}
                    />
                    <small className="invalid-feedback">
                      {errors.description && <>{errors.description.message}</>}
                    </small>
            {/* <Input
              type="textarea"
              //  placeholder="Enter KPI description"
              defaultValue={desc}
              onChange={handelDesc}
            /> */}
          </FormGroup>
          <Row>
            <Col>
              <FormGroup>
                <Label>Priority
                <span className="requiredField"> *</span>
                </Label>
                  

                  <input
                      type="number" name="priority" id="priority" placeholder="priority"
                      className={ "form-control" + (errors.priority ? " is-invalid" : "") }
                      {...register("priority")}
                    />
                    <small className="invalid-feedback">
                      {errors.priority && <>{errors.priority.message}</>}
                    </small>
             
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Time
                <span className="requiredField"> *</span>
                </Label>



                <input
                      type="number" name="time" id="time" placeholder="time"
                      className={ "form-control" + (errors.time ? " is-invalid" : "") }
                      {...register("time")}
                    />
                    <small className="invalid-feedback">
                      {errors.time && <>{errors.time.message}</>}
                    </small>
            
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit">
            Save Changes
          </Button>{" "}
          <Button color="secondary" onClick={() => setEditModal(!editModal)}>
            Cancel
          </Button>
        </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default SlaContractDetails;
