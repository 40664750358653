const GET_ALL_SYSCLASSE_SUCCESS = "GET_ALL_SYSCLASSE_SUCCESS";
const GET_ALL_SYSCLASSE_FAILURE = "GET_ALL_SYSCLASSE_FAILURE";
const LOAD_SYSCLASSE = "LOAD_SYSCLASSE";
const CREATE_SYSCLASSE_SUCCESS = "CREATE_SYSCLASSE_SUCCESS";
const CREATE_SYSCLASSE_FAILURE = "CREATE_SYSCLASSE_FAILURE";
const RETRIEVE_ONE_SYSCLASSE_FAILURE = "RETRIEVE_ONE_SYSCLASSE_FAILURE";
const RETRIEVE_ONE_SYSCLASSE_SUCCESS = "RETRIEVE_ONE_SYSCLASSE_SUCCESS";
const UPDATE_SYSCLASSE_SUCCESS = "UPDATE_SYSCLASSE_SUCCESS";
const UPDATE_SYSCLASSE_FAILURE = "UPDATE_SYSCLASSE_FAILURE";
const CLEAR_SYSCLASSE = "CLEAR_SYSCLASSE";
let sysClassTypes = {
  LOAD_SYSCLASSE,
  GET_ALL_SYSCLASSE_SUCCESS,
  GET_ALL_SYSCLASSE_FAILURE,
  CREATE_SYSCLASSE_SUCCESS,
  CREATE_SYSCLASSE_FAILURE,
  RETRIEVE_ONE_SYSCLASSE_SUCCESS,
  RETRIEVE_ONE_SYSCLASSE_FAILURE,
  UPDATE_SYSCLASSE_SUCCESS,
  UPDATE_SYSCLASSE_FAILURE,
  CLEAR_SYSCLASSE,
};
export default sysClassTypes;
