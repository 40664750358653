const CREATE_LICENCE_SUCCESS = "CREATE_LICENCE_SUCCESS";
const GET_ALL_LICENCES_SUCCESS = "GET_ALL_LICENCES_SUCCESS";
const UPDATE_LICENCE_SUCCESS = "UPDATE_LICENCE_SUCCESS";
const CREATE_LICENCE_FAILURE = "CREATE_LICENCE_FAILURE";
const GET_ALL_LICENCES_FAILURE = "GET_ALL_LICENCES_FAILURE";
const UPDATE_LICENCE_FAILURE = "UPDATE_LICENCE_FAILURE";
const LOAD_LICENCE = "LOAD_LICENCE";
const RETRIEVE_ONE_LICENCE_FAILURE = "RETRIEVE_ONE_LICENCE_FAILURE";
const RETRIEVE_ONE_LICENCE_SUCCESS = "RETRIEVE_ONE_LICENCE_SUCCESS";
const DELETE_LICENCE_SUCCESS = "DELETE_LICENCE_SUCCESS";
const DELETE_LICENCE_FAILURE = "DELETE_LICENCE_FAILURE";
const CLEAR_LICENCES = "CLEAR_LICENCES";
const CLEAR_ONE_LICENCE = "CLEAR_ONE_LICENCE";
let licenceTypes = {
  CREATE_LICENCE_SUCCESS,
  GET_ALL_LICENCES_SUCCESS,
  UPDATE_LICENCE_SUCCESS,
  CREATE_LICENCE_FAILURE,
  GET_ALL_LICENCES_FAILURE,
  UPDATE_LICENCE_FAILURE,
  LOAD_LICENCE,
  RETRIEVE_ONE_LICENCE_SUCCESS,
  RETRIEVE_ONE_LICENCE_FAILURE,
  DELETE_LICENCE_FAILURE,
  DELETE_LICENCE_SUCCESS,
  CLEAR_LICENCES,
  CLEAR_ONE_LICENCE
};
export default licenceTypes;
