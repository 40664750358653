import types from "../actions/types";

const {
  ADD_ONE_MTP_SUCCESS,
  ADD_ONE_MTP_FAILURE,
  GET_ALL_MTP_SUCCESS,
  GET_ALL_MTP_FAILURE,
  LOAD_MTP,
  DELETE_MTP_SUCCESS,
  DELETE_MTP_FAILURE,
  GET_ONE_MTP_SUCCESS,
  GET_ONE_MTP_FAILURE,
  UPDATE_MTP_SUCCESS,
  UPDATE_MTP_FAILURE,
  LOAD_MTP_ONE,
  CLEAR_MTP
} = types.mtpTypes;

const initialState = {
  mtp: null,
  mtps: [],
  isLoading: false,
  error: null,
  listError: null,
  isLoadingOne: false,
};

function mtpReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_ONE_MTP_SUCCESS:
      return {
        ...state,
      };
    case ADD_ONE_MTP_FAILURE:
      return {
        ...state,
        error: payload,
      };

    case GET_ALL_MTP_SUCCESS:
      return {
        ...state,
        mtps: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case GET_ALL_MTP_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: payload,
      };
    case LOAD_MTP:
      return {
        ...state,
        isLoading: true,
      };

      case DELETE_MTP_SUCCESS:
      
        return {
          ...state,
          isLoading: false,
        };
  
      case DELETE_MTP_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: payload,
          listError: null,
        };
      
      case GET_ONE_MTP_SUCCESS:
        return {
          ...state,
          mtp: payload,
          isLoadingOne: false,
          error: null,
          listError: null,
        };
      case GET_ONE_MTP_FAILURE:
        return {
          ...state,
          error: payload,
          listError: null,
        };

        case LOAD_MTP_ONE:
          return {
            ...state,
            isLoadingOne: true,
          };

      case UPDATE_MTP_SUCCESS:
        const updatedIndex = state.mtps.docs.map((mtp, index) => {
          if (payload._id === mtp._id) {
            return index;
          } else return null;
        });
        if (updatedIndex) state.mtps.docs[updatedIndex] = payload;
        return {
          ...state,
          mtps: state.mtps,
          isLoading: false,
          error: null,
          listError: null,
        };
  
      case UPDATE_MTP_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: payload,
          listError: null,
        };

      case CLEAR_MTP:
        return {
          ...state,             
          mtps: [],
          mtp: null,
          
        };

    default:
      return state;
  }
}
export default mtpReducer;
