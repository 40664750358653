import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Input,
  Table,
  FormGroup,
  Label,
  Badge,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from "reactstrap";
import "./components/TaskManagement.css";
//import { generatedTasks } from "./dumy-tasks";
import { processTableHeight, makeDelay } from "../../assets/js/CoreJs";
import TaskManagementMAPmodal from "./components/TaskManagementMAPmodal";
import NewCustomTask from "./components/NewCustomTask";
import UpdateMapTasks from "./components/UpdateMapTasks";
import TaskRequest from "./components/TaskRequest";
import classnames from "classnames";
import SideMenuSystemStats from "./components/SideMenuSystemStats";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  retrieveFiltredTasks,
  retrieveFiltredTasksRequest,
  //getSystemStatsForHighlight,
  clearTasksManagement
} from "../../actions/taskAction";
import ManageOtherTaskModal from "./components/ManageOtherTaskModal";

const TaskManagement = () => {
  const genPillColor = (taskStatus) => {
    const pillColor = {
      "done": "success",
      "pending": "warning",
      "canceled": "danger",
      "in progress": "primary",
      "completed": "info",
    }[taskStatus];
    return pillColor;
  };

  const applyTabHeight = () => {
    processTableHeight(
      ["TitlePage", "headerContent"],
      "ManagementListTableTCONT"
    );
  };
  const applySideMenuHeight = () => {
    processTableHeight(["TitlePage", "SideNavTabMenu"], "SideMenuContent", 35);
  };
  const [activeTab, setActiveTab] = useState("HL");
  useEffect(() => {
    applyTabHeight();
    applySideMenuHeight();
    window.addEventListener("resize", applyTabHeight);
    window.addEventListener("resize", applySideMenuHeight);
    return () => {
      window.removeEventListener("resize", applyTabHeight);
      window.removeEventListener("resize", applySideMenuHeight);
    };
  }, []);
  const [state, setState] = useState([
    "Pending",
    "Done",
    "In progress",
    "Canceled",
    "Completed",
  ]);

//   const [type, setType] = useState([
//   "Monitoring",
//   "Other Monitoring",
//   "Other"
// ]);

const tabType=[
  "Monitoring",
  "Other Monitoring",
  "Other"
]
  const [searchValue, setSearchValue] = useState("");

  const dispatch = useDispatch();
  const currentTimeZone = useSelector((state) => state.user.timeZone);
  //console.log("currentTimeZone===>",currentTimeZone);

  const tasks = useSelector((state) => state.taskReducer.tasks);
  // console.log("tasks in task management ===>",tasks);

  //const tasksReq = useSelector((state) => state.taskReducer.tasksRequest);
  //console.log("tasksReq===>",tasksReq);
  const isloading = useSelector((state) => state.taskReducer.isLoading);
  const error = useSelector((state) => state.taskReducer.tasksListError);
  // const ErrorAction = useSelector((state) => state.taskReducer.error);
  const systemStats = useSelector((state) => state.taskReducer.systemStatsForHighlight);
  //console.log(" systemStats ===> ",systemStats);
  const isloadingSystemStatsForHighlight = useSelector((state) => state.taskReducer.isloadingSystemStatsForHighlight);

  //const timeZone = useSelector((state) => state.user.timeZone);
  //const userId = useSelector((state) => state.user.user?._id);

  const [stateReqest /*setStateReqest*/] = useState(["To be validated"]);

  //console.log("state====>",state)

  const [typeReqest /*setTypeReqest*/] = useState([
    "Other Monitoring",
    "Other",
  ]);

  useEffect(() => {
    dispatch(
      retrieveFiltredTasksRequest(
        JSON.stringify(typeReqest),
        JSON.stringify(stateReqest),
        currentTimeZone
      )
    );
  }, [dispatch, typeReqest, stateReqest, currentTimeZone]);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    dispatch(
      retrieveFiltredTasks(
        // JSON.stringify(type),
        JSON.stringify(tabType),
        JSON.stringify(state),
        currentTimeZone,
        searchValue
      )
    );
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch,  state, currentTimeZone, searchValue]);

  const checkChange = () => {
    const newStates = [];
    const filters = document.querySelectorAll(".flCheck");
    filters.forEach((input) => {
      input.checked && newStates.push(input.value);
    });
    setState(newStates);
  };

  let tab = [];
  tasks?.map(
    (value) =>
      ({
        value: value._id,
        label: value.state,
      } && tab.push(value.state))
  );
  // console.log("Taaab ",tab)
  // console.log("Liste all tasks ex ====>",ex)

  const countOccurrences = (arr, val) =>
    arr.reduce((a, v) => (v === val ? a + 1 : a), 0);
  const pen = countOccurrences(tab, "Pending");
  const can = countOccurrences(tab, "Canceled");
  const don = countOccurrences(tab, "Done");
  const inp = countOccurrences(tab, "In progress");
  const cplt = countOccurrences(tab, "Completed");

  const handelSearshQuerry = (e) => {
    makeDelay(() => {
      if (e.target.value.length >= 2) {
        setSearchValue(e.target.value);
      } else {
        setSearchValue("");
      }
    }, 750);
  };

  useEffect(() => {
    return () => clearTimeout(window.timerDelay);
  }, []);

  // useEffect(() => {
  //   dispatch(getSystemStatsForHighlight(timeZone, userId));
  // }, [dispatch, timeZone, userId]);

 
let sumCreatedTasks = 0;
//let sumTargetTasks = 0;
if (isloadingSystemStatsForHighlight === false)
{for (let i = 0; i < systemStats.statistics?.length; i++) {
  //console.log(systemStats.statistics);
  sumCreatedTasks += systemStats.statistics[i].createdTasks;
  //sumTargetTasks += systemStats.statistics[i].targetTasks;
}
//console.log(sumCreatedTasks, sumTargetTasks);
}

//Clear Data
useEffect(() => {
  return () => {
    dispatch(clearTasksManagement());
  };
  //eslint-disable-next-line react-hooks/exhaustive-deps
},[])


  return (
    <>
      <section className="TitlePage">
        Task Management
        {/* <div className="Page_Description">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eligendi
          reiciendis veniam veli
        </div> */}
      </section>

      <section className="avxWrpContentPage TaskManagementpage">
        <Row>
          <Col xs="10">
            <Row className="headerContent">
              <Col xs="8">
                <TaskManagementMAPmodal 
                type={tabType}
                state={state}
                currentTimeZone={currentTimeZone}
                searchValue={searchValue}
                />
                <UpdateMapTasks 
                  type={tabType}
                  state={state}
                  currentTimeZone={currentTimeZone}
                  searchValue={searchValue}
                />
                <NewCustomTask 
                />
                <TaskRequest />
              </Col>

              <Col className="SearchBar" xs="4">
                <Input
                  type="search"
                  className="form-control"
                  placeholder="Search by Task, Assignee, System"
                  onChange={handelSearshQuerry}
                />
              </Col>
            </Row>

            <div className="TableContainer">
              <div
                id="ManagementListTableTCONT"
                className="avxTableContainer scrollTb"
              >
                <Table className="ManagementListTable avxBsTable" striped hover>
                  <thead>
                    <tr>
                      <th>Task</th>
                      <th>Assignee</th>
                      <th>Customer</th>
                      <th>System</th>
                      <th>Start Time</th>
                      <th>Status</th>
                    </tr>
                  </thead>

                  {isloading === false ? (
                    <tbody className="forPointer">
                      {tasks && error === null ? (
                        <>
                          {tasks.map((task) => (
                            <tr
                              key={task._id}
                              className={`${
                                task.state === "Pending" ? "statusColor" : ""
                              }`}
                            >
                              {/* <td>{task.title}</td> */}


                                {(() => {
                                  switch (task.type) {
                                    case 'Other Monitoring':
                                      return <td> {task.title} <span className="typeStyle"> CM </span> </td>
                                    case 'Other':
                                      return <td> {task.title} <span className="typeStyle"> CO </span> </td>
                                  
                                    default:
                                      return <td>{task.title}</td>
                                }
                                })()}

                              {/* {task.type === "Other Monitoring" ?
                                <td>{task.title + " " + "(OM)"} </td>
                                :
                                <td>{task.title}</td>} */}
                              <td>
                                {" "}
                                <Badge
                                  color={
                                    task.assigneeName ? "success" : "warning"
                                  }
                                >
                                  {task.assigneeName
                                    ? task.assigneeName + " "
                                    : "Unassigned"}
                                </Badge>
                              </td>
                              {task.customerName ?
                                <td>{task.customerName}</td>
                                :
                                <td><span>---</span></td>}

                              {task.systemName ?
                                <td>{task.systemName}</td>
                                :
                                <td><span>---</span></td>}
                              <td>
                                {/* {task.estimatedStart.split("T")[1].split("+")[0]} */}
                                {moment(task.estimatedStart)
                                  .tz(currentTimeZone)
                                  .format("LT")}
                              </td>

                              <td>
                                <Badge
                                  color={genPillColor(task.state.toLowerCase())}
                                  pill
                                >
                                  {" "}
                                  {task.state}{" "}
                                </Badge>

                                {(task.type ==="Other") && (task.state ==="In progress") ? <ManageOtherTaskModal taskID={task._id}/>:<span> {" "} </span>}
                                 {/* {(() => {
                                  switch (task.type) {
                                    case 'Other Monitoring':
                                      return <span> {" "} </span>
                                    case 'Other':
                                      
                                      return <ManageOtherTaskModal/>
                                    default:
                                      return <span>{" "}</span>
                                }
                                })()} */}
                              </td>
                          
                            </tr>
                          ))}
                        </>
                      ) : (
                        <tr>
                          <td colSpan="6">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                              }}
                            >
                              <span> No Tasks </span>
                              {/* <span>{error} </span> */}
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan="6">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: 100,
                            }}
                          >
                            <Spinner color="dark" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </div>
            </div>
          </Col>

          <Col xs="2">
            <div className="SideNavTabMenu">
              <Nav tabs className="SideNavTabMenu">
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "HL" })}
                    onClick={() => {
                      toggle("HL");
                    }}
                  >
                    Highlights
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "ST" })}
                    onClick={() => {
                      toggle("ST");
                    }}
                  >
                    Statistics
                  </NavLink>
                </NavItem>
              </Nav>
            </div>

            <TabContent>
              {
                {
                  HL: (
                    <TabPane>
                      <div className="CreatedBadge">
                       {isloadingSystemStatsForHighlight === false ? (<Badge size="small" color="secondary">
                          {" "}
                          Created tasks {" "+sumCreatedTasks}
                        </Badge>):("No Statistique")} 
                      </div>
                      <table className="tableHighlight">
                        <tbody>
                          <tr>
                            <td>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    className="flCheck"
                                    onChange={checkChange}
                                    value="Pending"
                                    checked={
                                      state.includes("Pending") ? true : false
                                    }
                                  />{" "}
                                  <Badge color="warning" pill>
                                    Pending
                                  </Badge>
                                </Label>
                              </FormGroup>
                            </td>
                            <td>
                              <p className="StatusNumber">{pen}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    className="flCheck"
                                    onChange={checkChange}
                                    value="In progress"
                                    checked={
                                      state.includes("In progress")
                                        ? true
                                        : false
                                    }
                                  />{" "}
                                  <Badge color="primary" pill>
                                    In progress
                                  </Badge>
                                </Label>
                              </FormGroup>
                            </td>
                            <td>
                              <p className="StatusNumber">{inp}</p>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    className="flCheck"
                                    onChange={checkChange}
                                    value="Completed"
                                    checked={
                                      state.includes("Completed") ? true : false
                                    }
                                  />{" "}
                                  <Badge color="info" pill>
                                    Completed
                                  </Badge>
                                </Label>
                              </FormGroup>
                            </td>
                            <td>
                              <p className="StatusNumber">{cplt}</p>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    className="flCheck"
                                    onChange={checkChange}
                                    value="Done"
                                    checked={
                                      state.includes("Done") ? true : false
                                    }
                                  />{" "}
                                  <Badge color="success" pill>
                                    Done
                                  </Badge>
                                </Label>
                              </FormGroup>
                            </td>
                            <td>
                              <p className="StatusNumber">{don}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    className="flCheck"
                                    onChange={checkChange}
                                    value="Canceled"
                                    checked={
                                      state.includes("Canceled") ? true : false
                                    }
                                  />{" "}
                                  <Badge color="danger" pill>
                                    Canceled
                                  </Badge>
                                </Label>
                              </FormGroup>
                            </td>
                            <td>
                              <p className="StatusNumber">{can}</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      {/* <div className="MyTaskMgtFilter">
                        <h5 className="MyTasksMgtFilterTitle">Task Types</h5>
                        <div className="MyTasksMgtFilterInsideContent">
                          <FormGroup check inline>
                            <Label className="MyTasksMgtFilterLabel">
                              <Input
                                type="radio"
                                name="taskMgtTypeRadio"
                                value="Monitoring"
                                onClick={() => setType("Monitoring")}
                                defaultChecked
                              />{" "}
                              Monitoring Tasks
                            </Label>
                          </FormGroup>

                          <FormGroup>
                            <Label className="MyTasksMgtFilterLabel">
                              Custom Tasks
                            </Label>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="taskMgtTypeRadio"
                                  value="Other Monitoring"
                                  onClick={() => setType("Other Monitoring")}
                                />{" "}
                                Monitoring
                              </Label>
                            </FormGroup>

                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="taskMgtTypeRadio"
                                  value="Other"
                                  onClick={() => setType("Other")}
                                />
                                Other
                              </Label>
                            </FormGroup>
                          </FormGroup>
                        </div>
                      </div> */}
                    </TabPane>
                  ),

                  ST: (
                    <TabPane>
                      <SideMenuSystemStats
                        applySideMenuHeight={applySideMenuHeight}
                        activeTab={activeTab}
                      />
                    </TabPane>
                  ),
                }[activeTab]
              }
            </TabContent>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default TaskManagement;
