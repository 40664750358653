import types from "./types";
import hostService from "../services/hostService";
import { message } from 'antd';

const {
  CREATE_HOST_SUCCESS,
  CREATE_HOST_FAILURE,
  LOAD_HOST,
  GET_ALL_HOSTS_SUCCESS,
  GET_ALL_HOSTS_FAILURE,
  DELETE_HOST_SUCCESS,
  DELETE_HOST_FAILURE,
  RETRIEVE_ONE_HOST_SUCCESS,
  RETRIEVE_ONE_HOST_FAILURE,
  UPDATE_HOST_SUCCESS,
  UPDATE_HOST_FAILURE,
  CLEAR_HOSTS,
  CLEAR_ONE_HOST
} = types.hostTypes;

export const createHost = (newHost) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_HOST,
    });
    const res = await hostService.createHost(newHost);
    message.success("Create Successfully",3);
    dispatch({
      type: CREATE_HOST_SUCCESS,
      payload: res.data.data,
    });
    //return Promise.resolve(res.data.data);
  } catch (err) {
    message.error('An Error occurred while creating',3);
    dispatch({
      type: CREATE_HOST_FAILURE,
      payload: err,
    });
  }
};

export const retrieveHosts = (system_Id, searchValue) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_HOST,
    });
    const res = await hostService.getAllHost(system_Id, searchValue);
    dispatch({
      type: GET_ALL_HOSTS_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {
    dispatch({
      type: GET_ALL_HOSTS_FAILURE,
      payload: err.response.data.message,
    });
  }
};

export const deleteHost = (id, systemId) => async (dispatch) => {
  try {
    // console.log("Action 111111");
    const res = await hostService.deleteHost(id);
    dispatch({
      type: LOAD_HOST,
    });
    message.success("Deleted Successfully",3);
    dispatch({
      type: DELETE_HOST_SUCCESS,
      payload: res.data.data,
    });
    // console.log("Action Delete", res.data);
    const resAll = await hostService.getAllHost(systemId);
    dispatch({
      type: GET_ALL_HOSTS_SUCCESS,
      payload: resAll.data.data,
    });
  } catch (err) {
    //console.log(err);
    message.error('An Error occurred while deleting',3);
    dispatch({
      type: DELETE_HOST_FAILURE,
      payload: err.response.data.message,
    });
  }
};

export const RetreiveOneHost = (id) => async (dispatch) => {
  try {
    const res = await hostService.getOneHost(id);
    dispatch({
      type: RETRIEVE_ONE_HOST_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {
    dispatch({
      type: RETRIEVE_ONE_HOST_FAILURE,
      payload: err.response.data.message,
    });
  }
};

export const updateHost = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_HOST,
    });
    const res = await hostService.updateHost(id, data);
    //console.log(res)
    message.success("Updated Successfully",3);
    dispatch({
      type: UPDATE_HOST_SUCCESS,
      payload: res.data.data,
    });
    
  } catch (err) {
    //console.log(err)
    message.error('An Error occurred while updating',3);
    dispatch({
      type: UPDATE_HOST_FAILURE,
      payload: err?.response?.data?.message,
    });
  }
};

 //clear Hosts
 export const clearHosts = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_HOSTS,
  })
}

//clear One Host
export const clearOneHost = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_ONE_HOST,
  })
}
