import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  FormGroup,
  Spinner,
} from "reactstrap";
import { BsTrash, BsPencil } from "react-icons/bs";
import { TablePagination } from "@material-ui/core";
import Select from "../../../../../shared/select/Select";
import { DatePicker, TimePicker } from "antd";
import { processTableHeight, makeDelay } from "../../../../../assets/js/CoreJs";
import "../CustomerSystems.css";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./MapYupValidation";

import { useDispatch, useSelector } from "react-redux";
import {
  retrieveMaps,
  createMap,
  RetreiveOneMap,
  updateMap,
  deleteMap,
  clearMaps,
  clearOneMap,
} from "../../../../../actions/mapAction";
import { retrieveMonitoringActivity } from "../../../../../actions/monitoringActivityAction";
import moment from "moment";
import "antd/dist/antd.css";
import { Cron } from "react-js-cron";
// import Cron, { CronError } from "react-js-cron";
import cronstrue from 'cronstrue';

const SystemMAPs = function ({ selectedSystemID }) {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    // defaultValues : {
    //   periodicity: ""
    // }
  });

  // const register  = useFormContext();

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  //const [searchTerm, setSearchTerm] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const dispatch = useDispatch();
  const [refrechData, setrefrechData] = useState(false);
  const maps = useSelector((state) => state.map.maps.docs);
  // console.log("List maps ------> " , maps);
  const isloading = useSelector((state) => state.map.isLoading);
  const error = useSelector((state) => state.map.listError);
  const totalDocs = useSelector((state) => state.map.maps.totalDocs);
  const ErrorAction = useSelector((state) => state.map.error);
  const TimeReport = useSelector(
    (state) => state.customer.customer.firstReport
  );
  const currentTimeZone = useSelector((state) => state.user.timeZone);
  // console.log("currentTimeZone selected For this User ---> ", currentTimeZone);
  const map = useSelector((state) => state.map.map);
  //console.log("Map selected For Update ====>", map);
  const [searchValue, setSearchValue] = useState("");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setrefrechData(!refrechData);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setrefrechData(!refrechData);
  };

  const [optionsMonitoringAct, setOptionMonitoringAct] = useState([
    {
      value: "none",
      label: "none",
    },
  ]);

  const defaultValuePeriodicity = "";
  const [valuePeriodicity, setValuePeriodicity] = useState(
    defaultValuePeriodicity
  );

  const defaultPeriod = "year";

  // console.log("defaultValuePeriodicity ----> ", defaultValuePeriodicity);
  // console.log("valuePeriodicity ----> ", valuePeriodicity);

  const toggleAdd = async () => {
    reset();
    const optionsMonitoringAct = await dispatch(
      retrieveMonitoringActivity(selectedSystemID)
    );
    if (optionsMonitoringAct)
      setOptionMonitoringAct(
        optionsMonitoringAct.map((value) => ({
          value: value._id,
          label: value.activity + " - [" + value.description + "]",
        }))
      );
    setValue("startTime", moment(TimeReport, format));
    // console.log("selectedSystemID ----> ",selectedSystemID)
    // console.log("optionsMonitoringAct ----> ",optionsMonitoringAct)
    setValuePeriodicity(defaultValuePeriodicity);
    setAddModal(!addModal);
  };

  function handleCreateMap({
    periodicity,
    estimation,
    startTime,
    monitoringAct,
  }) {
    const formattedTime = moment(startTime?._d).format("YYYY-MM-DD HH:mm");
    const newMap = {
      // periodicity: moment(periodicity._d.getTime()).format("HH:mm").toString(),
      periodicity: periodicity,
      estimation: moment(estimation._d.getTime()).format("HH:mm").toString(),
      // startTime: moment(startTime._d.getTime()).format("HH:mm").toString(),
      startTime: formattedTime,
      system: selectedSystemID,
      monitoringAct: monitoringAct.value,
    };
   

    if(window.confirm("The periodicity expression will be stored in " +  currentTimeZone + "\nAre you sure to continue ?")){
      dispatch(createMap(newMap, currentTimeZone));
      setrefrechData(!refrechData);
      setAddModal(!addModal);
    }else{
      setAddModal(true);
    }

    if (!ErrorAction) {
      // setAddModal(!addModal);
    }
  }

  const toggleEdit = async (e, id) => {
    e.preventDefault();
    reset();
    if (id) {
      const res = await dispatch(RetreiveOneMap(id, currentTimeZone));
      // console.log("MAP ----> ",res)
      if (res) {
        const oldMonitoringActivity = {
          value: res.monitoringAct._id,
          label:
            res.monitoringAct.activity +
            " - [" +
            res.monitoringAct.description +
            "]",
        };
        setValue("monitoringAct", oldMonitoringActivity);
        // setValue("periodicity", res.periodicity);
        setValuePeriodicity(res.periodicity);
        // setValue("timeZone", currentTimeZone);
        setValue("timeZone", res.timeZone);
        // setValuePeriodicity(setValue("periodicity", res.periodicity));
        setValue("estimation", moment(res.estimation, "hh:mm"));
        setValue("startTime", moment(res.startTime));
      }
    }
    setEditModal(!editModal);
  };

  // Edit MAP
  function handleEdit(editData) {
    const formattedTime = moment(editData.startTime?._d).format(
      "YYYY-MM-DD HH:mm"
    );
    const updatedData = {
      // periodicity: moment(editData.periodicity).format("HH:mm"),valuePeriodicity
      // periodicity: valuePeriodicity,periodicity
      periodicity: editData.periodicity,
      estimation: moment(editData.estimation).format("HH:mm"),
      // startTime: moment(editData.startTime).format("HH:mm"),
      startTime: formattedTime,
      monitoringAct: editData.monitoringAct.value,
      system: selectedSystemID,
    };
   

    if(window.confirm("The periodicity expression will be stored in " +  currentTimeZone + "\nAre you sure to continue ?")){
      dispatch(updateMap(map._id, updatedData, currentTimeZone));
      setrefrechData(!refrechData);
      setEditModal(!editModal);
    }else{
      setEditModal(true);
    }
    
    if (!error) {
      // reset();
      // setrefrechData(!refrechData);
      // setEditModal(!editModal);
    } else {
      console.error(error);
    }
  }

  const confirmDelete = (id) => {
    if (window.confirm("Are you sure you want to delete the Planning ?")) {
      dispatch(deleteMap(id, selectedSystemID, page + 1, rowsPerPage));
      // console.log("Map deleted", id);
      setrefrechData(!refrechData);
    }
  };

  const applyTabHeight = () => {
    processTableHeight(
      [
        "TitlePage",
        "customerDetailsTabsNav",
        "sysDetailHeader",
        "customerSystemDetailsTabsNav",
        "sysMapsHeader",
        "sysMapsPagination",
      ],
      "systemMapsTCONT",
      0
    );
  };
  useEffect(() => {
    if (selectedSystemID) {
      dispatch(
        retrieveMaps(
          selectedSystemID,
          currentTimeZone,
          page + 1,
          rowsPerPage,
          searchValue
        )
      );
    }

    applyTabHeight();
    window.addEventListener("resize", applyTabHeight);

    //return fn must be last in useEffect
    return () => {
      window.removeEventListener("resize", applyTabHeight);
    };
  }, [
    dispatch,
    selectedSystemID,
    page,
    rowsPerPage,
    searchValue,
    refrechData,
    currentTimeZone,
  ]);

  // useEffect(() => {
  //   if (selectedSystemID) {
  //     dispatch(retrieveMaps(selectedSystemID, page, rowsPerPage));
  //   }
  // }, [dispatch, selectedSystemID, page, rowsPerPage, refrechData]);

  const format = "HH:mm";

  if (errors.monitoringAct) {
    document.querySelector(".customerSelectMap > div:nth-child(2)") &&
      document
        .querySelector(".customerSelectMap > div:nth-child(2)")
        .classList.add("isInvalidSelect");
  }

  const selectInvalidFeedback = {
    width: "100%",
    marginTop: ".25rem",
    fontSize: "80%",
    color: "#dc3545",
  };

  // const cronInvalidFeedback = {
  //   width: "100%",
  //   marginTop: ".25rem",
  //   fontSize: "80%",
  //   color: "#dc3545",
  // };

  const handelSearshQuerry = (e) => {
    makeDelay(() => {
      if (e.target.value.length >= 2) {
        setSearchValue(e.target.value);
        setPage(0);
      } else {
        setSearchValue("");
      }
    }, 750);
  };

  useEffect(() => {
    return () => clearTimeout(window.timerDelay);
  }, []);

  //Clear Data
  useEffect(() => {
    return () => {
      dispatch(clearMaps());
      dispatch(clearOneMap());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log(errors);

  // console.log(watch("periodicity"));

  function handlePeriodicity(e) {
    // console.log("event in periodicity cron JS", e);
    setValue("periodicity", e);
    setValuePeriodicity(watch("periodicity"));
  }

  // handel periodicity for Add
  function handlePeriodicityAdd(e) {
    // console.log("event in periodicity Add cron JS ----> ", e);
    setValue("periodicity", e);
    setValuePeriodicity(watch("periodicity"));
  }

  // console.log("cron description ---> ",cronstrue.toString("* * * * *"))

  return (
    <div className="systemMaps">
      <Row className="sysMapsHeader">
        <Col xs="2">
          <Button color="primary" onClick={toggleAdd}>
            Add Planning
          </Button>
        </Col>
        <Col xs={{ size: 4, offset: 6 }}>
          <Input
            type="search"
            className="form-control"
            placeholder="Search by Activity"
            onChange={handelSearshQuerry}
            // onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Col>
      </Row>
      <div className="sysMapsContent">
        <div id="systemMapsTCONT" className="avxTableContainer scrollTb">
          <Table className="systemMapsTable avxBsTable twoActs" striped>
            <thead>
              <tr>
                <th>Activity</th>
                <th>Description</th>
                <th>Periodicity</th>
                <th>Time Zone</th>
                <th>Starting from</th>
                <th>Estimation</th>
                <th>
                  <span>
                    <BsPencil></BsPencil>
                  </span>
                </th>
                <th>
                  <span>
                    <BsTrash></BsTrash>
                  </span>
                </th>
              </tr>
            </thead>
            {isloading === false ? (
              <tbody>
                {maps?.length > 0 && error === null ? (
                  <>
                    {maps.map((m) => (
                      <tr key={m._id}>
                        <td>{m?.monitoringAct?.activity}</td>
                        <td>{m?.monitoringAct?.description}</td>
                        {/* <td>{m.periodicity}</td> */}
                        <td title={cronstrue.toString(m.periodicity)}>{m.periodicity}</td>
                        <td><span className="singleTZ">{m.timeZone} </span></td>

                        {/* <td>{m.startTime}</td> */}
                        <td>
                          {moment(m.startTime)
                            .tz(currentTimeZone)
                            .format("YYYY-MM-DD LT")}
                        </td>
                        <td>{m.estimation}</td>

                        <td>
                          <span
                            className="tbBtn"
                            onClick={(e) => toggleEdit(e, m._id)}
                          >
                            <BsPencil></BsPencil>
                          </span>
                        </td>
                        <td>
                          <span
                            className="tbBtn"
                            onClick={() => confirmDelete(m._id)}
                          >
                            <BsTrash></BsTrash>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan="7">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                        }}
                      >
                        <span> No MAP Exist For this System </span>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="7">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: 100,
                      }}
                    >
                      <Spinner color="dark" />
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
        <TablePagination
          className="sysMapsPagination"
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          component="div"
          count={totalDocs ? totalDocs : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>

      <Modal
        isOpen={addModal}
        toggle={toggleAdd}
        className="addSystemMapsModal"
        backdrop="static"
        keyboard={true}
        size="lg"
        centered
        fade={false}
      >
        <Form
          onSubmit={handleSubmit(handleCreateMap)}
          className="formModalAddCustomer"
        >
          <ModalHeader toggle={toggleAdd}>
            Add new Monitoring Activity Planning
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>
                Activity
                <span className="requiredField"> *</span>
              </Label>
              <Controller
                name="monitoringAct"
                control={control}
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <Select
                    className="customerSelectMap"
                    placeholder="Select activity"
                    options={optionsMonitoringAct}
                    {...rest}
                    {...fieldState}
                  />
                )}
              />
              <small style={selectInvalidFeedback}>
                {errors.monitoringAct && (
                  <>{errors.monitoringAct.value.message}</>
                )}
              </small>
            </FormGroup>

            <Row>
              <Col>
                <FormGroup>
                  <Label>
                    Periodicity
                    <span className="requiredField"> *</span>
                  </Label>

                  <Cron
                    className="cronMapStyle"
                    value={valuePeriodicity}
                    // setValue={(value) => setValue("periodicity", value)}
                    setValue={(e) => handlePeriodicityAdd(e)}
                    // setValue={(value) => watch("periodicity", value)}
                    defaultPeriod={defaultPeriod}
                    clearButton={false}
                    // allowEmpty="never"
                  />

                  <FormGroup>
                    <Label>Result Expression</Label>
                    <Row>
                      <Col xs="6">
                        <input
                          name="periodicity"
                          {...register("periodicity")}
                          type="text"
                          readOnly
                          className={
                            "form-control cronMapStylePeriodicity" +
                            (errors.periodicity ? " is-invalid" : "")
                          }
                        />
                        <small style={selectInvalidFeedback}>
                          {errors.periodicity && (
                            <>{errors.periodicity.message}</>
                          )}
                        </small>
                      </Col>
                    </Row>
                  </FormGroup>

                  {/* <Cron 
                 className="cronMapStyle"
                 value={valuePeriodicity}
                 setValue={setValuePeriodicity}
                 defaultPeriod={defaultPeriod}
                 allowEmpty='never'
                //  onError={onError}
                  />
                  {valuePeriodicity === "* * * * *" || valuePeriodicity === "" ? "" :<p>Periodicity Value : {valuePeriodicity}</p>}
                  
                  <small style={cronInvalidFeedback}>
                  {(valuePeriodicity === "* * * * *" ) ?  "You need to choose a periodicity.":""}
                </small>  */}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>
                    Starting from
                    <span className="requiredField"> *</span>
                  </Label>
                  <Controller
                    name="startTime"
                    control={control}
                    render={({ field: { ref, ...rest }, fieldState }) => (
                      <DatePicker
                        {...rest}
                        {...fieldState}
                        className={
                          "form-control" +
                          (errors.startTime ? " is-invalid" : "")
                        }
                        showTime
                        format="YYYY-MM-DD HH:mm"
                        placeholder="Starting from"
                      />
                    )}
                  />
                  <small style={selectInvalidFeedback}>
                    {errors.startTime && <>{errors.startTime.message}</>}
                  </small>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>
                    Estimation
                    <span className="requiredField"> *</span>
                  </Label>
                  <Controller
                    name="estimation"
                    control={control}
                    render={({ field }) => (
                      <TimePicker
                        {...field}
                        className={
                          "form-control" +
                          (errors.estimation ? " is-invalid" : "")
                        }
                        format={format}
                        minuteStep={15}
                        showNow={false}
                        placeholder="Select estimation"
                      />
                    )}
                  />
                  <small style={selectInvalidFeedback}>
                    {errors.estimation && <>{errors.estimation.message}</>}
                  </small>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              // disabled={(valuePeriodicity === "" || valuePeriodicity === "* * * * *") ? true : false}
              type="submit"
            >
              Add Planning
            </Button>{" "}
            <Button color="secondary" onClick={toggleAdd}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={editModal}
        toggle={toggleEdit}
        className="editSystemMapsModal"
        backdrop="static"
        keyboard={true}
        size="lg"
        centered
        fade={false}
      >
        <Form
          onSubmit={handleSubmit(handleEdit)}
          className="formModalAddCustomer"
        >
          <ModalHeader toggle={toggleEdit}>
            Update Monitoring Activity Planning
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>
                Activity
                <span className="requiredField"> *</span>
              </Label>
              <Controller
                name="monitoringAct"
                control={control}
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <Select
                    className="customerSelectMap"
                    placeholder="Select activity"
                    isDisabled={true}
                    {...rest}
                    {...fieldState}
                  />
                )}
              />
              <small style={selectInvalidFeedback}>
                {errors.monitoringAct && (
                  <>{errors.monitoringAct.value.message}</>
                )}
              </small>
            </FormGroup>

            <Row>
              <Col>
                <FormGroup>
                  <Label>
                    Periodicity
                    <span className="requiredField"> *</span>
                  </Label>

                  <Cron
                    className="cronMapStyle"
                    setValue={(e) => handlePeriodicity(e)}
                    value={valuePeriodicity}
                    defaultPeriod={defaultPeriod}
                    clearButton={false}
                  />

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Result Expression</Label>
                        <input
                          name="periodicity"
                          {...register("periodicity")}
                          type="text"
                          readOnly
                          className={
                            "form-control cronMapStylePeriodicity" +
                            (errors.periodicity ? " is-invalid" : "")
                          }
                        />
                        <small style={selectInvalidFeedback}>
                          {errors.periodicity && (
                            <>{errors.periodicity.message}</>
                          )}
                        </small>
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup>
                      <Label>Expression Timezone</Label>
                        <input
                          name="timeZone"
                          {...register("timeZone")}
                          type="text"
                          readOnly
                          className={
                            "form-control cronMapStylePeriodicity" +
                            (errors.timeZone ? " is-invalid" : "")
                          }
                        />
                        <small style={selectInvalidFeedback}>
                          {errors.timeZone && <>{errors.timeZone.message}</>}
                        </small>
                      </FormGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>
                    Starting from
                    <span className="requiredField"> *</span>
                  </Label>
                  <Controller
                    name="startTime"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        showTime
                        defaultValue={moment(TimeReport, "YYYY-MM-DD HH:mm")}
                        className={
                          "form-control" +
                          (errors.startTime ? " is-invalid" : "")
                        }
                        format="YYYY-MM-DD HH:mm"
                      />
                    )}
                  />
                  <small style={selectInvalidFeedback}>
                    {errors.startTime && <>{errors.startTime.message}</>}
                  </small>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>
                    Estimation
                    <span className="requiredField"> *</span>
                  </Label>
                  <Controller
                    name="estimation"
                    control={control}
                    render={({ field }) => (
                      <TimePicker
                        {...field}
                        className={
                          "form-control" +
                          (errors.estimation ? " is-invalid" : "")
                        }
                        format={format}
                        minuteStep={15}
                        showNow={false}
                        placeholder="Select estimation"
                      />
                    )}
                  />
                  <small style={selectInvalidFeedback}>
                    {errors.estimation && <>{errors.estimation.message}</>}
                  </small>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              // disabled={
              //   valuePeriodicity === "" || valuePeriodicity === "* * * * *"
              //     ? true
              //     : false
              // }
              type="submit"
            >
              Save Changes
            </Button>{" "}
            <Button color="secondary" onClick={toggleEdit}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default SystemMAPs;
