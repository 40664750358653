import { combineReducers } from "redux";
import authentication from "./auth.reducer";
import alert from "./alert.reducer";
import licences from "./licenceReducer";
import customer from "./customerReducer";
import monitoringType from "./monitoringTypeReducer";
import slaContract from "./slaContractReducer";
import customerContact from "./customerContactReducer";
import system from "./systemReducer";
import sla from "./slaReducer";
import host from "./hostReducer";
import client from "./clientReducer";
import map from "./mapReducer";
import landscapeTC from "./landscapeTCReducer";
import monitoringActivityTypes from "./monitoringActivityReducer";
import landScapeCTReducer from "./landScapeCTReducer";
import user from "./userReducer";
import userManagement from "./userManagementReducer";
import roleReducer from "./roleReducer";
import shiftReducer from "./shiftReducer"
import nomenclatureReducer from "./nomenclatureReducer";
import shiftCallendarReducer from "./shiftCallendarReducer";
import typeSystem from "./typeReducer";
import categorySystem from "./categoryReducer";
import sysClass from "./sysClassReducer";
import moduleReducer from "./moduleReducer";
import taskReducer from "./taskReducer";
import taskDynamicForm from './taskDynamicFormsReducer';
import resultReducer from "./resultReducer"
import taskWorkFlowReducer from "./taskWorkFlowReducer";
import resultFormReducer from "./resultFormReducer";
import syncShiftReducer from "./syncShiftReducer";
import globalReportReducer from "./globalReportReducer";
import feedReducer from "./feedReducer";
import CommentReducer from "./commentReducer";
import statisticReducer from "./statisticReducer";
import notificationReducer from "./notificationReducer"
import mtpReducer from "./mtpReducer";
import resultHistoryReducer from "../pages/reporting/components/resultHistory/logic/redux/resultHistoryReducer";

export default combineReducers({
  customer,
  customerContact,
  authentication,
  alert,
  licences,
  monitoringType,
  slaContract,
  system,
  sla,
  host,
  client,
  map,
  landscapeTC,
  monitoringActivityTypes,
  landScapeCTReducer,
  user,
  userManagement,
  roleReducer,
  shiftReducer,
  nomenclatureReducer,
  shiftCallendarReducer,
  typeSystem,
  categorySystem,
  sysClass,
  moduleReducer,
  taskReducer,
  taskDynamicForm,
  taskWorkFlowReducer,
  resultFormReducer,
  resultReducer,
  syncShiftReducer,
  globalReportReducer,
  feedReducer,
  CommentReducer,
  statisticReducer,
  notificationReducer,
  mtpReducer,
  resultHistoryReducer
});
