import http from "../api";

// const getAllsysClass = (page, size) => {
//   return http.get(`/api/sysclass/?page=${page}&size=${size}`);
// };

const getAllsysClass = (page, size, isActive, searchValue) => {
  if (isActive){
  return searchValue ? http.get(`/api/sysclass?page=${page}&size=${size}&isActive=${isActive}&searchValue=${searchValue}`) : http.get(`/api/sysclass?page=${page}&size=${size}&isActive=${isActive}`);}
  else{
    return searchValue ? http.get(`/api/sysclass?page=${page}&size=${size}&searchValue=${searchValue}`) : http.get(`/api/sysclass?page=${page}&size=${size}`);
  }
};

const createSysClasse = (sysClasse) => {
  return http.post(`/api/sysclass`, sysClasse);
};

const getOneSysClasse = (id) => {
  return http.get(`/api/sysclass/${id}`);
};

const updateSysClasse = (id, sysClasse) => {
  return http.patch(`/api/sysclass/${id}`, sysClasse);
};

const sysClassService = {
  getAllsysClass,
  createSysClasse,
  getOneSysClasse,
  updateSysClasse,
};
export default sysClassService;
