import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import React from "react";

import configureStore from "./store/configureStore";
import App from "./App";

import tokenInterceptor from './interceptors/token';
const store = configureStore();

const { dispatch } = store;

tokenInterceptor(dispatch);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
  , document.getElementById("root")
);
