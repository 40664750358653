import types from "../actions/types";
const {
  CREATE_CUSTOMER_CONTACT_SUCCESS,
  CREATE_CUSTOMER_CONTACT_FAILURE,
  GET_CUSTOMER_CONTACTS_BY_CUSTOMER_SUCCESS,
  GET_CUSTOMER_CONTACTS_BY_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_CONTACT_SUCCESS,
  UPDATE_CUSTOMER_CONTACT_FAILURE,
  GET_ONE_CUSTOMER_CONTACT_FAILURE,
  GET_ONE_CUSTOMER_CONTACT_SUCCESS,
  DELETE_CUSTOMER_CONTACT_SUCCESS,
  DELETE_CUSTOMER_CONTACT_FAILURE,
  ADD_CUSTOMER_CONTACT_TO_SYSTEM_SUCCESS,
  ADD_CUSTOMER_CONTACT_TO_SYSTEM_FAILURE,
  GET_CUSTOMER_CONTACTS_BY_CUSTOMER_UNASSIGNED_SUCCESS,
  GET_CUSTOMER_CONTACTS_BY_CUSTOMER_UNASSIGNED_FAILURE,
  UNASSIGN_CUSTOMER_CONTACT_TO_SYSTEM_FAILURE,
  UNASSIGN_CUSTOMER_CONTACT_TO_SYSTEM_SUCCESS,
  LOAD_CUSTOMER_CONTACT,
  CLEAR_CUSTOMER_CONTACTS,
  CLEAR_ONE_CUSTOMER_CONTACT,
  CLEAR_CUSTOMER_CONTACTS_TO_SYSTEM
} = types.customerContactTypes;
const initialState = {
  customerContacts: [],
  customerContactsToSystem: [],
  isLoading: false,
  error: null,
  customerContact: null,
  listError: null,
};

function customerContactReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_CUSTOMER_CONTACT_SUCCESS:
      state.customerContacts.docs.push(payload);
      return {
        ...state,
        customerContacts: state.customerContacts,
        isLoading: false,
      };
    case CREATE_CUSTOMER_CONTACT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case GET_CUSTOMER_CONTACTS_BY_CUSTOMER_SUCCESS:
      return {
        ...state,
        customerContacts: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case GET_CUSTOMER_CONTACTS_BY_CUSTOMER_FAILURE:
      return {
        ...state,
        error: payload,
        isLoading: false,
        listError: payload,
      };
    case UPDATE_CUSTOMER_CONTACT_SUCCESS:
      const updatedIndex = state.customerContacts.docs.map((contact, index) => {
        if (payload._id === contact._id) {
          return index;
        } else return null;
      });

      state.customerContacts.docs[updatedIndex] = payload;
      return {
        ...state,
        customerContacts: state.customerContacts,
        isLoading: false,
      };
    case UPDATE_CUSTOMER_CONTACT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case ADD_CUSTOMER_CONTACT_TO_SYSTEM_SUCCESS:
      return {
        ...state,
        customerContactsToSystem: payload,
        isLoading: false,
      };
    case ADD_CUSTOMER_CONTACT_TO_SYSTEM_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case DELETE_CUSTOMER_CONTACT_SUCCESS:
      // const Index = state.customerContacts.docs.map((contact, index) => {
      //   if (payload._id === contact._id) {
      //     return index;
      //   } else return null;
      // });
      // state.customerContacts.docs.splice(Index, 1);
      // return {
      //   ...state,
      //   customerContacts: state.customerContacts,
      //   isLoading: false,
      // };

      const Index = state.customerContacts.docs.map((contact, index) => {
        if (payload._id === contact._id) {
          return index;
        } else return null;
      });
      if (Index) state.customerContacts.docs[Index] = payload;
      return {
        ...state,
        customerContacts: state.customerContacts,
        isLoading: false,
        error: null,
        listError: null,
      };

    case DELETE_CUSTOMER_CONTACT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case GET_ONE_CUSTOMER_CONTACT_SUCCESS:
      return {
        ...state,
        customerContact: payload,
        loading: false,
      };
    case GET_ONE_CUSTOMER_CONTACT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case GET_CUSTOMER_CONTACTS_BY_CUSTOMER_UNASSIGNED_SUCCESS:
      return {
        ...state,
        customerContacts: payload,
        isLoading: false,
      };
    case GET_CUSTOMER_CONTACTS_BY_CUSTOMER_UNASSIGNED_FAILURE:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
      case UNASSIGN_CUSTOMER_CONTACT_TO_SYSTEM_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };
      case UNASSIGN_CUSTOMER_CONTACT_TO_SYSTEM_FAILURE:
        return {
          ...state,
          error: payload,
        };
    case LOAD_CUSTOMER_CONTACT:
      return {
        ...state,
        isLoading: true,
      };

    case CLEAR_CUSTOMER_CONTACTS:
      return {
        ...state,
        customerContacts: [],
        isLoading: false,
        error: null,
        listError: null,
      };

    case CLEAR_ONE_CUSTOMER_CONTACT:
      return {
        ...state,
        customerContact: null,
        loading: false,
      };

    case CLEAR_CUSTOMER_CONTACTS_TO_SYSTEM:
      return {
        ...state,
        customerContactsToSystem: [],
        isLoading: false,
      };
    default:
      return state;
  }
}

export default customerContactReducer;
