import * as Yup from "yup";
const schema = Yup.object().shape({
    kpi: Yup.object().shape({
        label: Yup.string().required("The kpi is required."),
        value: Yup.string().required("The kpi is required.")
    })
        .required("The kpi is required")
        .nullable(),
    unit: Yup.object().shape({
        label: Yup.string().required("The unit is required."),
        value: Yup.string().required("The unit is required.")
    })
        .required("The unit is required")
        .nullable(),

    description: Yup.string().required("The description is required."),
    priority: Yup.string().required("The priority is required."),
    time: Yup.string().required("The time is required."),
});
export default schema;
