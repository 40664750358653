import types from "../actions/types";
const {
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAILURE,
  LOAD_USERS,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_STATUS_FAILURE,
  LOAD_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  RETRIEVE_ONE_USER_SUCCESS,
  RETRIEVE_ONE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  CLEAR_USERS,
  CLEAR_ONE_USER

} = types.userManagementTypes;
const initialState = {
  user: null,
  users: [],
  isLoading: false,
  areLoading: false,
  error: null,
  listError: null,
};

function userReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        users: payload,
        areLoading: false,
      };
    case GET_ALL_USERS_FAILURE:
      return {
        ...state,
        areLoading: false,
        error: payload,
      };

    case LOAD_USERS:
      return {
        ...state,
        areLoading: true,
      };

    case LOAD_USER:
      return {
        ...state,
        isLoading: true,
      };

    case UPDATE_USER_STATUS_SUCCESS:
      return {
        ...state,
       // user: payload,
       status: action.payload.status,
        loading: false,
        isLoading: false,
      };

    case UPDATE_USER_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

      case DELETE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case DELETE_USER_FAILURE:

      return {
        ...state,
        isLoading: false,
        error: payload,
       // listError: null,
      };

      case RETRIEVE_ONE_USER_SUCCESS:
        return {
          ...state,
          user: payload,
          isLoading: false,
          error: null,
          listError: null,
        };
      case RETRIEVE_ONE_USER_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: payload,
          listError: null,
        };

        case UPDATE_USER_SUCCESS:
          // const updatedIndex = state.users.data.map((user, index) => {
          //   if (payload._id === user._id) {
          //     return index;
          //   } else return null;
          // });
          // if (updatedIndex) state.users.data[updatedIndex] = payload;
          // return {
          //   ...state,
          //   users: state.users,
          //   isLoading: false,
          //   error: null,
          //   listError: null,
          //  // areLoading: true,
          // };

          return {
            ...state,
            users: state.users,
            isLoading: false,
            error: null,
            listError: null,
            // areLoading: true,
          };
    
        case UPDATE_USER_FAILURE:
          return {
            ...state,
            isLoading: false,
            error: payload,
            listError: null,
          };

       case CLEAR_USERS:
            return {
              ...state,
              users: [],
              areLoading: false,
            };
       case CLEAR_ONE_USER:
              return {
                ...state,
                user: null,
                isLoading: false,
                error: null,
                listError: null,
              };

    default:
      return state;
  }
}

export default userReducer;
