import React, { useState, useEffect } from "react";

import Calendar from "tui-calendar";
import "tui-calendar/dist/tui-calendar.css";
import {selectViewOptions, CalendarCommonOptions, shiftNamingStructure, shiftStructure} from "./CalendarConfig";
import moment from "moment";
import {Button, CustomInput, Modal, ModalHeader, Form ,ModalBody, ModalFooter, Spinner, FormGroup, Label, Badge} from "reactstrap";
import "../TeamLeading.css";
import { processTableHeight } from "../../../assets/js/CoreJs";
import { useSelector, useDispatch } from "react-redux";

import { retreiveAllNomenclature } from "../../../actions/nomenclatureAction";
import { AssigneeTeamlead, ClearCallendarShift, retreiveShiftCallendar } from "../../../actions/shiftCallendarAction ";
import {retreiveShiftMembers} from "../../../actions/shiftAction";
import Select from "../../../shared/select/Select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./AssignTLYupValidation";

import DisplayForPermission from "../../../shared/DisplayForPermission/DisplayForPermission";

import { message } from 'antd';

const CalendarTUI = function ({ calRange, setCalRange, setDisplayRange }) {
  const dispatch = useDispatch();
  const currentTimeZone = useSelector((state) => state.user.timeZone);
  //const isLoadingNomenc = useSelector(state => state.nomenclatureReducer.isLoadingNomenclature);
  const shiftNamings = useSelector( (state) => state.nomenclatureReducer.AllNomenclatures );
  const isLoadingShift = useSelector( (state) => state.shiftCallendarReducer.isLoadingCallendar );
  const allShifts = useSelector( (state) => state.shiftCallendarReducer.AllshiftCallendar.docs );
  const AllMembers = useSelector((state) => state.shiftReducer.AllMembers.docs);
  const isLoadingMembers = useSelector(
    (state) => state.shiftReducer.isLoadingMembers
  );
  const [Cal, setCal] = useState();
  const [shownDate, setShownDate] = useState();
  

  const [allFiltersSelected, setAllFiltersSelected] = useState(false);
  
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  function ShiftHistory(member) {
    return (
      <>
        {member.user.firstName + " " + member.user.lastName + " "}
        <Badge color="primary">{member.total}</Badge>
      </>
    );
  }

  const changeCalendarView = async (e) => {
    await Cal?.changeView(e.target.value, true);
    if(e.target.value==='month' || e.target.value==='week'){
      setCalRange({
        start: moment(Cal.getDateRangeStart().toDate()).format("YYYY-MM-DD"),
        end: moment(Cal.getDateRangeEnd().toDate()).format("YYYY-MM-DD")
      });
      setDisplayRange({
        start: moment(Cal.getDateRangeStart().toDate()).format("YYYY-MM-DD"),
        end: moment(Cal.getDateRangeEnd().toDate()).format("YYYY-MM-DD")
      });
    }
  };

  const goStep = (where) => {
    new Promise((resolve) => {
      const navTo = {
        next: () => { Cal?.next(); },
        prev: () => { Cal?.prev(); },
        tday: () => { Cal?.today(); }
      };
      navTo[where]();
      resolve();
    }).then(() => {
      setShownDate(moment(Cal?.getDate().toDate()).format("MMMM YYYY"));
      setCalRange({
        start: moment(Cal.getDateRangeStart().toDate()).format("YYYY-MM-DD"),
        end: moment(Cal.getDateRangeEnd().toDate()).format("YYYY-MM-DD")
      });
      setDisplayRange({
        start: moment(Cal.getDateRangeStart().toDate()).format("YYYY-MM-DD"),
        end: moment(Cal.getDateRangeEnd().toDate()).format("YYYY-MM-DD")
      });
    });
  };

  const filterShiftNamings = (e) => {
    Cal?.toggleSchedules(e.target.value, !e.target.checked);
    //console.log(e);
    //console.log('ShiftNaming ID : ', e.target.value, ' isChecked : ', e.target.checked);
    setAllFiltersSelected(false);
  };

  const [daShift, setDaShift] = useState();
  const [singleAssModal, setSingleAssModal] = useState(false);
  const toggleSingle = () => {
    reset();
    setSingleAssModal(!singleAssModal);
  };

  useEffect(() => {
    calRange && dispatch( retreiveAllNomenclature() );
    calRange && dispatch( retreiveShiftCallendar(calRange.start, calRange.end, true, true, currentTimeZone) );

    const CalendarOptions = {
      ...CalendarCommonOptions,
      timezone: {
        zones: [{ timezoneName: currentTimeZone }],
      },
      calendars: [],
    
    };

    !Cal && setCal(new Calendar("#shiftsCalendar", CalendarOptions));
    Cal?.getDate() && calRange && setShownDate(moment(Cal.getDate().toDate()).format("MMMM YYYY"));

    if (Cal?.getDateRangeStart() && Cal?.getDateRangeEnd() && !calRange) {
      setCalRange({
        start: moment(Cal.getDateRangeStart().toDate()).format("YYYY-MM-DD"),
        end: moment(Cal.getDateRangeEnd().toDate()).format("YYYY-MM-DD")
      });
      setDisplayRange({
        start: moment(Cal.getDateRangeStart().toDate()).format("YYYY-MM-DD"),
        end: moment(Cal.getDateRangeEnd().toDate()).format("YYYY-MM-DD"),
      });
    }

    const applyCalendarHeight = () => {
      processTableHeight( ['TitlePage', 'shiftsCalendarHeader'], 'shiftsCalendar', 50.14 );
      Cal?.render();
    };
    applyCalendarHeight();
    window.addEventListener("resize", applyCalendarHeight);
    return () => {
      window.removeEventListener("resize", applyCalendarHeight);
        dispatch(ClearCallendarShift())
    };

  }, [dispatch, currentTimeZone, Cal, calRange, setCalRange, setDisplayRange]);

  useEffect(() => {
    if (Cal && shiftNamings && allShifts) {
      Cal.setCalendars(shiftNamingStructure(shiftNamings));
      Cal.clear();
      Cal.createSchedules(shiftStructure(allShifts), true);
      
      //ReApply Shiftnamings checkbox filters
      document.querySelectorAll('.sgShiftNaming').forEach((sgShiftNaming)=>{
        Cal?.toggleSchedules(sgShiftNaming.value, !sgShiftNaming.checked);
      });
    }
  }, [Cal, shiftNamings, allShifts, allFiltersSelected]);

  useEffect(() => {
    //Calendar custom events
    // Cal?.on('clickDayname', (event)=>{
    //   alert("DayName Clicked : "+event.date);
    // });
    //console.log(shiftNamingStructure(shiftNamings));

    Cal?.on("clickSchedule", async (event) => {
      await dispatch(retreiveShiftMembers(moment(event.schedule?.start._date).format("YYYY-MM-DD"), moment(event.schedule?.start._date).format("YYYY-MM-DD"), event.schedule?.raw.shiftName));
      await setDaShift(event.schedule);
      setSingleAssModal(true);
      const defaultValue = handleDefaultValue(event.schedule);
      if (defaultValue !== null) {
        setValue("teamLeader",defaultValue);
      }
      //console.log("Clicked schedule : ", event.schedule);
    });
  }, [Cal, setValue, dispatch]);

  function handleDefaultValue(daShift) {
    if (daShift?.raw.teamLeader) return {value : daShift?.raw.teamLeader?._id ,label : daShift?.raw.teamLeader?.name}
    return null
  }

  const handleAssignee = async ({ teamLeader })=>{
    const newValues = {
      user: teamLeader.value,
      shifts: [{
        name: daShift?.raw.shiftName,
        startDate: moment(daShift?.start._date).tz(currentTimeZone,true).format()
      }]
    };
    const res = await dispatch(AssigneeTeamlead(newValues));
    if(res?.status===200 ){
      calRange && dispatch( retreiveShiftCallendar(calRange.start, calRange.end, true, true, currentTimeZone) );
      toggleSingle();
      message.success("Teamleader successfully assigned",3);
    }else{
      message.error("An error has occured. Please try again",3);
    }
  }

  const selectInvalidFeedback = {
    width: "100%",
    marginTop: ".25rem",
    fontSize: "80%",
    color: "#dc3545",
  };

  if(errors.teamLeader){
    document.querySelector('.customerSelectTeamLeader > div:nth-child(2)') &&
    document.querySelector('.customerSelectTeamLeader > div:nth-child(2)').classList.add('isInvalidSelect');
  }

  const deselectAllFilters = () => {
    document.querySelectorAll(".sgShiftNaming").forEach((checkbox) => {
      if (checkbox.checked) {
      Cal?.toggleSchedules(checkbox.value, false);
      checkbox.checked = false;
      // const value = checkbox.value;
      // checkbox.value = value;
      setAllFiltersSelected(!allFiltersSelected);
    }
    });
  };

  return (
    <>
      <header className="shiftsCalendarHeader">
        <div className="selectView">
          <CustomInput type="select" id="changeViewSelect" onChange={changeCalendarView} >
            {selectViewOptions.map((option, ix) => (
              <option key={"opt" + ix} value={option.value}> {option.label} </option>
            ))}
          </CustomInput>
        </div>{" "}
        <Button outline onClick={ ()=>{goStep("tday");} } >Today</Button>{" "}
        <Button outline onClick={ ()=>{ goStep("prev");} } >{"<"}</Button>{" "}
        <Button outline onClick={ ()=>{ goStep("next");} }>{">"}</Button>{" "}
        <label className="shownDate">{shownDate}</label>
      </header>
      <div className="clearfix"></div>

      {shiftNamings && (
        <div className="lnb-calendars-d1">
          {shiftNamingStructure(shiftNamings).map((shNaming) => (
            <div key={shNaming.id} className="lnb-calendars-item">
              <label>
                <input type="checkbox" className="tui-full-calendar-checkbox-round sgShiftNaming" value={shNaming.id} defaultChecked onChange={filterShiftNamings} />
                <span className={shNaming.msTheme}></span>
                <span>{shNaming.name}</span>
              </label>
            </div>
          ))}
          <Button color="primary" size="sm" outline onClick={deselectAllFilters} disabled={allFiltersSelected} >Deselect all</Button>
        </div>
      )}

      <div id="shiftsCalendar" className="shiftsCalendar">
        { isLoadingShift && (<div className="calendarLoading"><Spinner /></div>) }
      </div>

      <Modal isOpen={singleAssModal} size="sm" toggle={toggleSingle} backdrop="static" keyboard={true}  className="singleTlAssignModal" centered fade={false} >
        <Form onSubmit={handleSubmit(handleAssignee)} >
        <ModalHeader toggle={toggleSingle}>Assign new Team Leader</ModalHeader>
        <ModalBody>
          <Badge className="shiftNamebadge" style={ {backgroundColor: daShift?.borderColor ? daShift.borderColor : "default"} }>
            {daShift?.raw.shiftName}
          </Badge>
          <FormGroup className="Shift Date details">
            <Label>{ moment(daShift?.start._date).format("YYYY-MM-DD") }</Label>
            <br></br>
            {"From "}
            <b>{moment(daShift?.start._date).format("LT")}</b>
            {" To"}{" "}
            <b>{moment(daShift?.end._date).format("LT")}</b>
          </FormGroup>
          <FormGroup className="shift Teamlead status">

          <Badge color={daShift?.raw.teamLeader ? "success" : "warning"}>
            {daShift?.raw.teamLeader ? daShift.raw.teamLeader.name : "Unassigned"}
          </Badge>
          </FormGroup>
          <DisplayForPermission permissionCode={1701}>
          {moment(daShift?.start._date) > moment().startOf("day") && (
              <FormGroup>
                <Label>
                  Team Leader
                  <span className="requiredField"> *</span>
                </Label>
                <Controller
                  name="teamLeader"
                  control={control}
                  render={({ field: { ref, ...rest }, fieldState }) => (
                    <Select
                      className="customerSelectTeamLeader"
                      placeholder="Select Team Leader"
                      options={AllMembers?.map((value) => ({
                        value: value.user._id,
                        label: ShiftHistory(value),
                      }))}         
                      isLoading={isLoadingMembers}            
                      {...rest}
                      {...fieldState}
                    />
                  )}
                />
                <small style={selectInvalidFeedback}>
                  {errors.teamLeader && <>{errors.teamLeader.value.message}</>}
                </small>
              </FormGroup>
            )}
            </DisplayForPermission>
        </ModalBody>
        <ModalFooter>
        <DisplayForPermission permissionCode={1701}>
          {moment(daShift?.start._date) > moment().startOf("day") ? 
            <>
              <Button color="primary" type="submit">Assign Team Leader</Button>
              <Button color="secondary" onClick={toggleSingle}>Cancel</Button>
            </>
            : <Button color="secondary" onClick={toggleSingle}>Close</Button>
          }
          </DisplayForPermission>
        </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default CalendarTUI;
