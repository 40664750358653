const CREATE_SYNC_SHIFT_SUCCESS = "CREATE_SYNC_SHIFT_SUCCESS";
const CREATE_SYNC_SHIFT_FAILURE = "CREATE_SYNC_SHIFT_FAILURE";
const LOAD_SYNC_SHIFT = "LOAD_SYNC_SHIFT";
const LOAD_SYNC_SHIFT_FOR_CREATE = "LOAD_SYNC_SHIFT_FOR_CREATE";
const GET_ALL_SYNC_SHIFTS_SUCCESS = "GET_ALL_SYNC_SHIFTS_SUCCESS";
const GET_ALL_SYNC_SHIFTS_FAILURE = "GET_ALL_SYNC_SHIFTS_FAILURE";
const CLEAR_SYNC_SHIFTS = "CLEAR_SYNC_SHIFTS";


let syncShiftTypes = {
  CREATE_SYNC_SHIFT_SUCCESS,
  CREATE_SYNC_SHIFT_FAILURE,
  LOAD_SYNC_SHIFT,
  GET_ALL_SYNC_SHIFTS_SUCCESS,
  GET_ALL_SYNC_SHIFTS_FAILURE,
  LOAD_SYNC_SHIFT_FOR_CREATE,
  CLEAR_SYNC_SHIFTS,

};
export default syncShiftTypes;
