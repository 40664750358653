
import { useEffect, useState } from 'react';
import { DatePicker } from "antd";
import { processTableHeight } from "../../../../../assets/js/CoreJs";
import { useDispatch, useSelector } from 'react-redux';
import { retrieveAllMonitoringActivity } from '../../../../../actions/monitoringActivityAction';
import { getResultHistory, clearResultHistory } from './redux/resultHistoryAction';
import { retreiveCustomer, retreiveSystems, retreiveSystemsForMaps } from "../../../../../actions/taskAction";
import { v4 } from "uuid";
import moment from 'moment';


const useResultHistory = () => {
    const dispatch = useDispatch();
    const [compKeyCustomer, setcompKeyCustomer] = useState(v4());
    const timeZone = useSelector((state) => state.user.timeZone);

		moment.tz.setDefault(timeZone);

    const AllResultHistory = useSelector(state => state.resultHistoryReducer.ResultHistory);
    let customer = useSelector(state => state.taskReducer.AllCustomers.docs);
    let allSystems = useSelector(state => state.taskReducer.AllSystems);
    let AllmonitoringActivities = useSelector(state => state.taskReducer.AllSystemsFormMap.docs);
    let isLoadingSystem = useSelector(state => state.taskReducer.isLoadingSystem);
    let isloadingMaps = useSelector(state => state.taskReducer.isloadingMaps);
    let isLoadingRH = useSelector(state => state.resultHistoryReducer.isLoadingResultHistory);
    let error = useSelector(state => state.statisticReducer.error);

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [monitoringActivities, setMonitoringActivities] = useState();
    const [MapId, setMapId] = useState();
    const [customerId, setCustomerId] = useState();
    const [systemId, setSystemId] = useState();
    const [systems, setSystems] = useState();
    const [customers, setCustomers] = useState();

    const { RangePicker } = DatePicker;

    useEffect(() => {
        dispatch(retrieveAllMonitoringActivity(0, 100000, false));
    }, [dispatch, timeZone]);


    useEffect(() => {
        dispatch(retreiveCustomer(false, null, null, true))
        dispatch(retreiveSystems())
    }, [dispatch]);

    useEffect(() => {
        setCustomers(customer?.map((cust) => ({
            value: cust._id,
            label: cust.label
        })))
        setSystems(allSystems?.map((sys) => ({
            value: sys._id,
            label: sys.name
        })))
        setMonitoringActivities(AllmonitoringActivities?.map((ma) => ({
            value: ma._id,
            label: ma.monitoringAct.activity
        })))
    }, [allSystems, customer, AllmonitoringActivities]);


    const handleChangeDate = (e) => {
        if (e) {
            setStartDate(moment(e[0]._d).format("YYYY-MM-DDTHH:mm"))
            setEndDate(moment(e[1]._d).format("YYYY-MM-DDTHH:mm"))
        } else {
            setStartDate();
            setEndDate();
        }
    }

    const getMonitoringActivity = (selectedSystemID) => {
        dispatch(retreiveSystemsForMaps(selectedSystemID, timeZone));
    }
    const getSystemByCustomerID = (selectedCustomerID) => {
        setcompKeyCustomer(v4());
        dispatch(retreiveSystems(selectedCustomerID))
    }

    useEffect(() => {
        const data = {
            startDate,
            endDate
        }
        if (MapId && startDate && endDate) {
            dispatch(getResultHistory(MapId.value, timeZone, data))
        }
    }, [dispatch, MapId, timeZone, startDate, endDate])


    const [resultMAP, setresultMAP] = useState([]);
    useEffect(() => {
			if (AllResultHistory && AllResultHistory.hasOwnProperty('results')) {
				const sortedResults = AllResultHistory.results.sort( (a,b) => new Date(b.createdAt) - new Date(a.createdAt) ).map(
					result => ({...result, createdAt: moment(result.createdAt).format('DD-MM-YYYY - LT')})
				);
				setresultMAP(sortedResults);
			}
    }, [AllResultHistory]);


    useEffect(() => {
        return () => dispatch(clearResultHistory());
    }, [dispatch])

    function handleCustomer(e) {
        if (e) {
            setCustomerId(e);
            setSystemId(null);
            setMapId(null);
        } else {
            setCustomerId(null);
            setSystemId(null);
            setMapId(null);
        }
    }

    function handleSystem(e) {
        if (e) {
            setSystemId(e);
            setMapId(null);
        } else {
            setSystemId(null);
            setMapId(null);
        }
    }

    function handleMonitoringActivity(e) {
        if (e) {
            setMapId(e);
        } else {
            setMapId(null);
        }
    }


    const applyTabHeight = () => {
			processTableHeight(
				[
					"TitlePage",
					"taskTrackingTabsNav",
					"ResultHistoryHeader",
				],
				"ResultHistoryListTableTCONT",
				15
			);
    };

    useEffect(() => {
        applyTabHeight();
        window.addEventListener("resize", applyTabHeight);

        return () => {
            window.removeEventListener("resize", applyTabHeight);
        };
    }, []);

    return {
        moment,
        RangePicker,
        AllResultHistory,
        isLoadingRH,
        error,
        monitoringActivities,
        handleMonitoringActivity,
        MapId,
        handleChangeDate,
        getMonitoringActivity,
        getSystemByCustomerID,
        handleCustomer,
        handleSystem,
        customerId,
        systemId,
        systems,
        customers,
        compKeyCustomer,
        isLoadingSystem,
        isloadingMaps,
        resultMAP

    }
}

export default useResultHistory;