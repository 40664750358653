import http from "../api";

const getAll = (page, size, isActive, searchValue) => {
  if (isActive){
  return searchValue ? http.get(`/api/monitoringType?page=${page}&size=${size}&isActive=${isActive}&searchValue=${searchValue}`) 
  : http.get(`/api/monitoringType?page=${page}&size=${size}&isActive=${isActive}`)}
  else{
    return searchValue ? http.get(`/api/monitoringType?page=${page}&size=${size}&searchValue=${searchValue}`) : http.get(`/api/monitoringType?page=${page}&size=${size}`);
  }
};

const createMonitoringType = (monitoringType) => {
  return http.post(`/api/monitoringType`, monitoringType);
};

const getOneMonitoringType = (id) => {
  return http.get(`/api/monitoringType/${id}`);
};

const updateMonitoringType = (id, monitoringType) => {
  return http.patch(`/api/monitoringType/update/${id}`, monitoringType);
};

const monitoringTypeService = {
  getAll,
  createMonitoringType,
  getOneMonitoringType,
  updateMonitoringType,
};
export default monitoringTypeService;
