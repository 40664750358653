import types from "../actions/types";
const {
  CREATE_HOST_SUCCESS,
  CREATE_HOST_FAILURE,
  LOAD_HOST,
  GET_ALL_HOSTS_SUCCESS,
  GET_ALL_HOSTS_FAILURE,
  DELETE_HOST_SUCCESS,
  DELETE_HOST_FAILURE,
  RETRIEVE_ONE_HOST_SUCCESS,
  RETRIEVE_ONE_HOST_FAILURE,
  UPDATE_HOST_SUCCESS,
  UPDATE_HOST_FAILURE,
  CLEAR_HOSTS,
  CLEAR_ONE_HOST
} = types.hostTypes;
const initialState = {
  host: null,
  hosts: [],
  isLoading: false,
  error: null,
  listError: null,
};

function userReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_HOST_SUCCESS:
      state.hosts.push(payload);
      return {
        ...state,
        hosts: state.hosts,
        isLoading: false,
        error: null,
        listError: null,
      };
    case CREATE_HOST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

    case GET_ALL_HOSTS_SUCCESS:
      return {
        ...state,
        hosts: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case GET_ALL_HOSTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: payload,
      };

    case LOAD_HOST:
      return {
        ...state,
        isLoading: true,
      };

    case DELETE_HOST_SUCCESS:
      // const Index = state.hosts.docs.map((host, index) => {
      //   if (payload._id === host._id) {
      //     return index;
      //   } else return null;
      // });
      // if (Index) state.hosts.docs[Index] = payload;
      // return {
      //   ...state,
      //   hosts: state.hosts,
      //   isLoading: false,
      //   error: null,
      //   listError: null,
      // };
      return {
        ...state,
        isLoading: false,
      };

    case DELETE_HOST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

    case RETRIEVE_ONE_HOST_SUCCESS:
      return {
        ...state,
        host: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case RETRIEVE_ONE_HOST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

    case UPDATE_HOST_SUCCESS:
    
      const updatedIndex = state.hosts.map((host, index) => {
        if (payload._id === host._id) {
          return index;
        } else return null;
      });
      if (updatedIndex) state.hosts[updatedIndex] = payload;
      return {
        ...state,
        hosts: state.hosts,
        isLoading: false,
        error: null,
        listError: null,
      };

    case UPDATE_HOST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

      case CLEAR_HOSTS:
        return {
          ...state,
          hosts: [],
          isLoading: false,
          error: null,
          listError: null,
        };
   case CLEAR_ONE_HOST:
        return {
          ...state,
          host: null,
          isLoading: false,
          error: null,
          listError: null,
        };

    default:
      return state;
  }
}

export default userReducer;
