import types from "../actions/types";
const {
  RETREIVE_COMMENT_SUCCESS,
  RETREIVE_COMMENT_FAILURE,
  ADD_COMMENT_SUCCESS,
  ADD_COMMENT_FAILURE,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_FAILURE,
  UPDATE_COMMENT_SUCCESS,
  UPDATE_COMMENT_FAILURE,
  BLOCK_COMMENT_SUCCESS,
  BLOCK_COMMENT_FAILURE,
  UNBLOCK_COMMENT_SUCCESS,
  UNBLOCK_COMMENT_FAILURE,
  LOAD_COMMENT
} = types.feedTypes;
const initialState = {
  AllComments: [],
  isloadingComment: false,
  errorComment: null,
};

function CommentReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case RETREIVE_COMMENT_SUCCESS:
      // const newComment = findPost(state.AllComments,payload.postId,payload.data)
      return {
        ...state,
        AllComments: payload,
        isloadingComment: false,
        errorComment: null,
      };
    case RETREIVE_COMMENT_FAILURE:
      return {
        ...state,
        isloadingComment: false,
        errorComment: payload,
      };
    case LOAD_COMMENT:
      return {
        ...state,
        isloadingComment: true,
      };
    case ADD_COMMENT_SUCCESS:
      return {
        ...state,
      };
    case ADD_COMMENT_FAILURE:
      return {
        ...state,
        errorPost: payload,
      };
    case DELETE_COMMENT_SUCCESS:
      return {
        ...state,
      };
    case DELETE_COMMENT_FAILURE:
      return {
        ...state,
        errorComment: payload,
      };
    case UPDATE_COMMENT_SUCCESS:
      return {
        ...state,
      };
    case UPDATE_COMMENT_FAILURE:
      return {
        ...state,
        errorComment: payload,
      };
    case BLOCK_COMMENT_SUCCESS:
      return {
        ...state,
      };
    case BLOCK_COMMENT_FAILURE:
      return {
        ...state,
        errorComment: payload,
      };
    case UNBLOCK_COMMENT_SUCCESS:
      return {
        ...state,
      };
    case UNBLOCK_COMMENT_FAILURE:
      return {
        ...state,
        errorComment: payload,
      };
    default:
      return state;
  }
}

export default CommentReducer;
