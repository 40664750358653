import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaBell, FaUsers } from "react-icons/fa";
import "./Topbar.css";
import Rightbar from "../rightBar/Rightbar";
import Notification from "../notification/Notification";
import { Link } from "react-router-dom";
import { getAllNotification, seeNotif } from "../../../actions/notifAction";
import socketIOClient from "socket.io-client";
import dotenv from "dotenv";

const Topbar = () => {
  const dispatch = useDispatch();
  const currentTimeZone = useSelector((state) => state.user.timeZone);
  const currentUser = useSelector(state => state.user);
  // console.log("currentUser ---->",currentUser.user?._id)
  const notificationResult = useSelector((state) => state.notificationReducer.allNotif);
  // console.log("notificationResult ------> ",notificationResult.Result)
  const notificationLoading = useSelector((state) => state.notificationReducer.allNotifLoading);
  const rightbarCollapsed = localStorage.getItem("rightbarStatus") === "false";
  const [rightbarStatus, setRightbarStatus] = useState(rightbarCollapsed);
  const [open, setOpen] = useState(false);
  const socket = useRef();
  dotenv.config();

  useEffect(() => {
    currentUser.user?._id && dispatch(getAllNotification(currentUser.user?._id));
  }, [dispatch, currentUser]);

  useEffect(() => {
    socket.current = socketIOClient(process.env.REACT_APP_SOCKET_SERVER_URL);
    socket.current.on(currentUser.user?._id, (notifType, data) => {
      notifType === 'notif' && dispatch(getAllNotification(currentUser.user?._id));
    })
    return () => socket.current?.disconnect();
  }, [dispatch, currentUser]);
  
  const handleToggler = () => {
    setRightbarStatus(!rightbarStatus);
  };
  const handleTogglerNotification = () => {
    if(notificationResult?.Count !== 0){
      dispatch(seeNotif(currentUser.user?._id));
    }
    setOpen(!open);
  }

  let dropdownIconRef = useRef();

  return (
    <>
      <div className={rightbarStatus ? "overlay_members_shift" : ""}></div>
      <div className="topbar">
        <div className="topbarWrapper">
          <div
            className="tzDisplay"
            title="You can change Timezone in profile page"
          >
            <i className="bx bx-globe"></i> <span>{currentTimeZone}</span>
          </div>
          <Link onClick={handleTogglerNotification} ref={dropdownIconRef} to="#">
            <FaBell className="FaBell_Class" />
            {<span className="notif_badge">{!notificationLoading && notificationResult && notificationResult.Result?.length}</span>}
          </Link>
          <Link onClick={handleToggler} to="#">
            <FaUsers className="FaUsers_Class" />
          </Link>
        </div>
      </div>
      <Rightbar
        handleToggler={handleToggler}
        rightbarStatus={rightbarStatus}
        setRightbarStatus={setRightbarStatus}
      />
      {open && <Notification open={open} setOpen={setOpen} dropdownIconRef={dropdownIconRef}/>}

    </>
  );
};

export default Topbar;
