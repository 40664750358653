import types from "../actions/types";
const {
  GET_ALL_MODULE_SUCCESS,
  GET_ALL_MODULE_FAILURE,
  LOAD_MODULE,
  RETRIEVE_ONE_MODULE_SUCCESS,
  RETRIEVE_ONE_MODULE_FAILURE,
  CREATE_MODULE_SUCCESS,
  CREATE_MODULE_FAILURE,
  UPDATE_MODULE_SUCCESS,
  UPDATE_MODULE_FAILURE,
  CLEAR_MODULE
} = types.moduleTypes;
const initialState = {
  module: null,
  modules: [],
  isLoading: false,
  error: null,
  listError: null,
};

function userReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_MODULE_SUCCESS:
      return {
        ...state,
        modules: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case GET_ALL_MODULE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: payload,
      };
    case LOAD_MODULE:
      return {
        ...state,
        isLoading: true,
      };

    case RETRIEVE_ONE_MODULE_SUCCESS:
      return {
        ...state,
        module: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case RETRIEVE_ONE_MODULE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

    case CREATE_MODULE_SUCCESS:
      state.modules.docs.push(payload);
      return {
        ...state,
        modules: state.modules,
        isLoading: false,
        error: null,
        listError: null,
      };
    case CREATE_MODULE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

      case UPDATE_MODULE_SUCCESS:
        const updatedIndex = state.modules.docs.map((module, index) => {
          if (payload._id === module._id) {
            return index;
          } else return null;
        });
        if (updatedIndex) state.modules.docs[updatedIndex] = payload;
        return {
          ...state,
          modules: state.modules,
          isLoading: false,
          error: null,
          listError: null,
        };
  
      case UPDATE_MODULE_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: payload,
          listError: null,
        };

      case CLEAR_MODULE:
        return {
          ...state,
          modules: [],
          module: null,
          isLoading: false,
          error: null,
          listError: null,
        };
    
    default:
      return state;
  }
}

export default userReducer;
