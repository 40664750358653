import http from "../api";

const getAll = (paginate, page=null, size=null, isActive=null, searchValue=null) => {
  let queryPath = `/api/customer?paginate=${paginate}`;
  if(paginate) queryPath += `&page=${page}&size=${size}`;
  if(isActive!==null) queryPath += `&isActive=${isActive}`;
  if(searchValue) queryPath += `&searchValue=${searchValue}`;
  return http.get(queryPath);
  //return http.get(`/customer?page=${page}&size=${size}`);
};
const create = (customer, timeZone) => {
  return http.post(`/api/customer?timeZone=${timeZone}`, customer);
};
const update = (id, customer, timeZone) => {
  return http.patch(`/api/customer/update/${id}?timeZone=${timeZone}`, customer);
};

const getOne = (id, timeZone) => {
  return http.get(`/api/customer/${id}?timeZone=${timeZone}`);
};

const getCustomerSystemsInfo = () => {
  return http.get(`/api/customer/getCustomersInfoForDashboard`);
};

const CustomerService = {
  getAll,
  create,
  update,
  getOne,
  getCustomerSystemsInfo
};
export default CustomerService;
