import http from "../api";

const getAllModule = (page, size, searchValue) => {
  return searchValue ? http.get(`/api/module/getAll/?page=${page}&size=${size}&searchValue=${searchValue}&paginate=true`) : http.get(`/api/module/getAll/?page=${page}&size=${size}&paginate=true`);
};

const getAllBasic = (page, size) => {
  return http.get(`/api/module/getAllBasic/?page=${page}&size=${size}`);
};

const getOneModule = (id) => {
  return http.get(`/api/module/getOne/${id}`);
};

const createModule = (module) => {
  return http.post(`/api/module`, module);
};

const updateModule = (id, module) => {
  return http.patch(`/api/module/${id}`, module);
};

const moduleService = {
    getAllModule,
    getAllBasic,
    getOneModule,
    createModule,
    updateModule,
};
export default moduleService;