const RETRIEVE_ALL_SHIFT_CALLENDAR_SUCCESS =
  "RETRIEVE_ALL_SHIFT_CALLENDAR_SUCCESS";
const RETRIEVE_ALL_SHIFT_CALLENDAR_FAILURE =
  "RETRIEVE_ALL_SHIFT_CALLENDAR_FAILURE";
const LOAD_SHIFT_CALLENDAR = "LOAD_SHIFT_CALLENDAR";

const ASSIGNEE_TEAMLEAD_SUCCESS = "ASSIGNEE_TEAMLEAD_SUCCESS";
const ASSIGNEE_TEAMLEAD_FAILURE = "ASSIGNEE_TEAMLEAD_FAILURE";


const CLEAR_STORE_CALLENDAR = "CLEAR_STORE_CALLENDAR";

let shiftCallendarTypes = {
  RETRIEVE_ALL_SHIFT_CALLENDAR_SUCCESS,
  RETRIEVE_ALL_SHIFT_CALLENDAR_FAILURE,
  LOAD_SHIFT_CALLENDAR,
  ASSIGNEE_TEAMLEAD_SUCCESS,
  ASSIGNEE_TEAMLEAD_FAILURE,
  CLEAR_STORE_CALLENDAR
};
export default shiftCallendarTypes;
