import types from "./types";
import slaService from "../services/slaService";
import { message } from 'antd';
const {
  CREATE_SLA_SUCCESS,
  CREATE_SLA_FAILURE,
  LOAD_SLA,
  GET_ALL_SLAS_SUCCESS,
  GET_ALL_SLAS_FAILURE,
  UPDATE_SLA_SUCCESS,
  UPDATE_SLA_FAILURE,
  RETRIEVE_ONE_SLA_SUCCESS,
  RETRIEVE_ONE_SLA_FAILURE,
  DELETE_SLA_SUCCESS,
  DELETE_SLA_FAILURE,
  CLEAR_ALL_SLAS,
  CLEAR_ONE_SLA
} = types.slaTypes;

export const createSla = (newSal) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_SLA,
    });
    const res = await slaService.createSla(newSal);
    message.success("Create Successfully",3);
    dispatch({
      type: CREATE_SLA_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    message.error('An Error occurred while creating',3);   
    dispatch({
      type: CREATE_SLA_FAILURE,
      payload: err,
    });
  }
};

export const retrieveSlas =
  (slaContract_Id, page, size,searchValue) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_SLA,
      });
      const res = await slaService.getAllSla(slaContract_Id, page, size, searchValue);
      dispatch({
        type: GET_ALL_SLAS_SUCCESS,
        payload: res.data.data,
      });
      return res.data.data;
    } catch (err) {
      dispatch({
        type: GET_ALL_SLAS_FAILURE,
        payload: err.response.data.message,
      });
    }
  };

export const updateSla = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_SLA,
    });
    const res = await slaService.updateSla(id, data);
    message.success("Updated Successfully",3);
    dispatch({
      type: UPDATE_SLA_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    message.error('An Error occurred while updating',3);    
    dispatch({
      type: UPDATE_SLA_FAILURE,
      payload: err?.response?.data?.message,
    });
  }
};

export const RetreiveOneSla = (id) => async (dispatch) => {
  try {
    const res = await slaService.getOneSla(id);
    dispatch({
      type: RETRIEVE_ONE_SLA_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {
    dispatch({
      type: RETRIEVE_ONE_SLA_FAILURE,
      payload: err.response.data.message,
    });
  }
};

export const deleteSla = (id) => async (dispatch) => {
  try {
   // const res = await slaService.deleteSla(id);
    dispatch({
      type: LOAD_SLA,
    });
    const res = await slaService.deleteSla(id);
    message.success("Deleted Successfully",3);
    dispatch({
      type: DELETE_SLA_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    message.error('An Error occurred while deleting',3);   
    dispatch({
      type: DELETE_SLA_FAILURE,
      payload: err.response.data.message,
    });
  }
};

//clear All SlaContracts
export const clearSlas = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_ALL_SLAS,
  })
}

//clear One SlaContract
export const clearOneSla = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_ONE_SLA,
  })
}
