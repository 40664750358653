import axios from "axios";
import dotenv from "dotenv";
dotenv.config();
const instance = axios.create({
  baseURL: process.env.REACT_APP_NOTIF_URL,
});

instance.defaults.headers.common["x-auth-accessToken"] =
  localStorage.getItem("ma-access-token");
instance.defaults.headers.common["x-auth-refreshToken"] =
  localStorage.getItem("ma-refresh-token");
export default instance;
