import * as Yup from "yup";
const schema = Yup.object().shape({
    selectclass: Yup.object().shape({
        label: Yup.string().required("The class is required."),
        value: Yup.string().required("The class is required.")    
      })
      .required("The class is required")
      .nullable(),
      
    duration: Yup.array()
    .of(Yup.object())
    .required("The duration is required.")
    .nullable(),

    
});
export default schema;
