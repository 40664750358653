import React from 'react';
import { Route,Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, modules, ...rest }) => {
  return (
    <Route 
    {...rest}
    render={(props) => {
        return (
         modules ? modules.includes(rest.path.replace("/app","").replace("/:id","")) ?
            <Component {...props}/> 
            :
            <Redirect to="/app/home" /> : null
 
        )
    }} 
/>
  )
}

export default ProtectedRoute;