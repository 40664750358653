import types from "../actions/types";

const {
    CREATE_RESULT_SUCCESS,
    CREATE_RESULT_FAILURE,
    RETRIEVE_LAST_RESULT_SUCCESS,
    RETRIEVE_LAST_RESULT_FAILURE,
    RETRIEVE_LAST_RESULT_LOADER,
    UPDATE_RESULT_SUCCESS,
    UPDATE_RESULT_FAILURE,
    GET_ONE_RESULT_SUCCESS,
    GET_ONE_RESULT_FAILURE,
    GET_ONE_RESULT_LOADER
} = types.resultTypes;

const initialState = {
    result: null,
    error: null,
    isLoading: false,
    LastResult: [],
    oneResult: null,
    isLoadingLastResult: false,
    isLoadingOneResult: false
};

function resultReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case CREATE_RESULT_SUCCESS:
            return {
                ...state,
                result: payload
            }
        case CREATE_RESULT_FAILURE:
            return {
                ...state,
                error: payload
            };

        case RETRIEVE_LAST_RESULT_SUCCESS:
            return {
                ...state,
                LastResult: payload,
                isLoadingLastResult: false,
                error: null
            }
        case RETRIEVE_LAST_RESULT_FAILURE:
            return {
                ...state,
                LastResult: [],
                error: payload,
                isLoadingLastResult: false
            };
        case RETRIEVE_LAST_RESULT_LOADER:
            return {
                ...state,
                isLoadingLastResult: true
            };
        case UPDATE_RESULT_SUCCESS:
            return {
                ...state,
                result: payload
            }
        case UPDATE_RESULT_FAILURE:
            return {
                ...state,
                error: payload
            };

        case GET_ONE_RESULT_SUCCESS:
            return {
                ...state,
                oneResult: payload,
                isLoadingOneResult: false,
                error: null
            }
        case GET_ONE_RESULT_FAILURE:
            return {
                ...state,
                oneResult: null,
                error: payload,
                isLoadingOneResult: false
            };
        case GET_ONE_RESULT_LOADER:
            return {
                ...state,
                isLoadingOneResult: true
            };
        default:
            return state;
    }
}
export default resultReducer;
