import types from "./types";
import mtpService from "../services/mtpService";
import { message } from "antd";

const {
  ADD_ONE_MTP_SUCCESS,
  ADD_ONE_MTP_FAILURE,
  GET_ALL_MTP_SUCCESS,
  GET_ALL_MTP_FAILURE,
  LOAD_MTP,
  DELETE_MTP_SUCCESS,
  DELETE_MTP_FAILURE,
  GET_ONE_MTP_SUCCESS,
  GET_ONE_MTP_FAILURE,
  UPDATE_MTP_SUCCESS,
  UPDATE_MTP_FAILURE,
  LOAD_MTP_ONE,
  CLEAR_MTP
} = types.mtpTypes;

export const AddOneMTP = (newMTP, timeZone) => async (dispatch) => {
  try {
    const res = await mtpService.addOneMTP(newMTP, timeZone);
    message.success("Successfully added the MTP");
    dispatch({
      type: ADD_ONE_MTP_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    message.error("Failed to add the MTP");
    dispatch({
      type: ADD_ONE_MTP_FAILURE,
      payload: err,
    });
  }
};

export const retrieveAllMTP = (page, size, isActive, searchValue) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_MTP,
    });

    const res = await mtpService.getAllMTP(page, size, isActive, searchValue);
    dispatch({
      type: GET_ALL_MTP_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data.docs;
  } catch (err) {
    dispatch({
      type: GET_ALL_MTP_FAILURE,
      payload: err,
    });
  }
};

export const deleteMTP = (id) => async (dispatch) => {
    try {
     // const res = await slaService.deleteSla(id);
      dispatch({
        type: LOAD_MTP,
      });
      const res = await mtpService.deleteMTP(id);
      message.success("Deleted Successfully",3);
      dispatch({
        type: DELETE_MTP_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      message.error('An Error occurred while deleting',3);   
      dispatch({
        type: DELETE_MTP_FAILURE,
        payload: err.response.data.message,
      });
    }
  };

  export const RetreiveOneMTP = (id) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_MTP_ONE,
      });
      const res = await mtpService.getOneMTP(id);
      // console.log("mtp in action ----> ",res)
      dispatch({
        type: GET_ONE_MTP_SUCCESS,
        payload: res.data.data,
      });
      return res.data.data;
    } catch (err) {
      dispatch({
        type: GET_ONE_MTP_FAILURE,
        payload: err.response.data.message,
      });
    }
  };

  export const updateMTP = (id,timeZone,data) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_MTP,
      });
      const res = await mtpService.updateMTP(id,timeZone,data);
      message.success("Updated Successfully",3);
      dispatch({
        type: UPDATE_MTP_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      message.error('An Error occurred while updating',3);    
      dispatch({
        type: UPDATE_MTP_FAILURE,
        payload: err?.response?.data?.message,
      });
    }
  };

  //clear Data For MTP
export const clearMTP = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_MTP,
  })
}
