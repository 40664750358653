import React, { useState,useEffect } from "react";
import {
  Button,
  FormGroup,
  Label,
  Form,
  ModalFooter,
  ModalHeader,
  Modal,
  ModalBody,
  Row,
  Col
} from "reactstrap";
import "./NewCustomTask.css"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux";
import {  Switch, TimePicker } from "antd";
import Select from "react-select";
import { AddOneTasks, retreiveSystemsForMaps,retrieveFiltredTasks,retreiveSystems } from "../../../actions/taskAction";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./NewCustomTaskYupValidation";
import { v4 } from "uuid";


export default function NewCustomTask() {
  const dispatch = useDispatch()
  const [modalAddNewCustomTask, setmodalAddNewCustomTask] = useState(false);
  const [optionMembers, setoptionMembers] = useState([])
  const timeZone = useSelector(state => state.user.timeZone)
  const members = useSelector(state => state.taskReducer.AllMembers)
  const [optionsSystems, setoptionsSystems] = useState([])

  const toggleModalAddNewCustomTask = () => {
    setValue("description", "");
    reset();
    setmodalAddNewCustomTask(!modalAddNewCustomTask);
  }
    const monitoringActivities = useSelector(state => state.taskReducer.AllSystemsFormMap.docs)
    let  isloadingMaps = useSelector(state => state.taskReducer.isloadingMaps)

  const systems = useSelector(state => state.taskReducer.AllSystems)

  let isLoadingSystem = useSelector(state => state.taskReducer.isLoadingSystem)
  const [optionsmonitoringActivities, setoptionsmonitoringActivities] = useState([])

  let [optionsCustomers, setoptionsCustomers] = useState([])
  let customers = useSelector(state => state.taskReducer.AllCustomers.docs)

  const [compKeyCustomer, setcompKeyCustomer] = useState(v4());

const {
  control,
  handleSubmit,
  register,
  setValue,
  watch,
  reset,
  formState: { errors },
} = useForm({
  resolver: yupResolver(schema),
});


const [refreshData, setrefreshData] = useState(false);

// const [state, setState] = useState([
//   "Pending",
//   "Done",
//   "In progress",
//   "Canceled",
//   "Completed",
// ]);



useEffect(() => {
  const state1 = [
    "Pending",
    "Done",
    "In progress",
    "Canceled",
    "Completed",
  ];

  const tabType=[
    "Monitoring",
    "Other Monitoring",
    "Other"
  ]
  
  dispatch(
    retrieveFiltredTasks(
      // JSON.stringify(type),
      JSON.stringify(tabType),
      JSON.stringify(state1),
      timeZone,
      //searchValue
    )
  );
   // eslint-disable-next-line react-hooks/exhaustive-deps
}, [dispatch, timeZone, refreshData]);

const getSystem =  (selectedCustomerID) => {
  dispatch(retreiveSystems(selectedCustomerID))
}


useEffect(() => {
  setoptionsmonitoringActivities(monitoringActivities?.map((act)=>({
    value: act._id,
    label: act.monitoringAct.activity
  })))
  setoptionsSystems(systems?.map((sys)=>({
    value : sys._id,
    label : sys.name
  })))
}, [monitoringActivities,systems])
function handletoggleModal(){
  setValue("isActive",true);
  // setoptionsSystems(systems?.map((sys)=>({
  //   value : sys._id,
  //   label : sys.name
  // })))
  setoptionMembers(members?.map((mem)=>({
    value:mem?._id,
    label : mem?.firstName + " " + mem?.lastName
  })))

  setoptionsCustomers(customers?.map((cust)=>({
    value : cust._id,
    label : cust.label
  })))

  setmodalAddNewCustomTask(!modalAddNewCustomTask)
}
function handleCreateTask({isActive, description, estimatedStart, assignee, title, system, map}) {
let newTask={}
const  formattedTime= moment(estimatedStart?._d).format("YYYY-MM-DDTHH:mm");

 isActive===false ?
 newTask={
    title: title,
    description: description,
    type: "Other",
    estimatedStart: formattedTime,
    assignee: assignee.value,
  }  
  : newTask={
    title: map.label,
    description: description,
    type: "Other Monitoring",
    estimatedStart: formattedTime,
    assignee: assignee.value,
    system:system.value,
    map:map.value,
  }
  dispatch(AddOneTasks(newTask,timeZone))
  setrefreshData(!refreshData);
  reset();
  setmodalAddNewCustomTask(!modalAddNewCustomTask)
}

if(errors.assignee){
  document.querySelector('.customerSelectAssignee > div:nth-child(2)') &&
  document.querySelector('.customerSelectAssignee > div:nth-child(2)').classList.add('isInvalidSelect');
}

if(errors.system){
  document.querySelector('.customerSelectSystem > div:nth-child(2)') &&
  document.querySelector('.customerSelectSystem > div:nth-child(2)').classList.add('isInvalidSelect');
}

if(errors.map){
  document.querySelector('.customerSelectMap > div:nth-child(2)') &&
  document.querySelector('.customerSelectMap > div:nth-child(2)').classList.add('isInvalidSelect');
}

const selectInvalidFeedback = {
  width: "100%",
  marginTop: ".25rem",
  fontSize: "80%",
  color: "#dc3545",
};

const getMonitoringActivity =  (selectedSystemID) => {
  dispatch(retreiveSystemsForMaps(selectedSystemID,timeZone));
}


  return (
    <>
      <Button color="primary" onClick={handletoggleModal}>
        New Custom Task
      </Button>
      <Modal
        className="Add-New-Custom-Task-Modal"
        centered
        backdrop="static" keyboard={true}
        isOpen={modalAddNewCustomTask}
        size="lg"
        toggle={toggleModalAddNewCustomTask}
        fade={false}
      >
        <Form onSubmit={handleSubmit(handleCreateTask)} >
        <ModalHeader toggle={toggleModalAddNewCustomTask}>
          New Custom Task
        </ModalHeader>
        <ModalBody>
          <Row className="newCustomTaskSwitcherTime">
            <Col>
            <FormGroup>
                  <Label className="LabelMonitoringTask">
                    <span className="mr-3">Monitoring Task</span>
                    <Controller
                      control={control}
                      name="isActive"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Switch
                          onBlur={onBlur}
                          onChange={(e) => {
                            onChange(e); // on change mta3 el react hook form
                            if (e === true) {
                              setValue("title", undefined);
                            } else {
                              setValue("system", undefined);
                              setValue("map", undefined);
                            }
                          }}
                          checked={value}
                          defaultChecked
                        />
                      )}
                    />
                  </Label>
                </FormGroup>
            </Col>
            <Col xs="5">
              <FormGroup>
                  <Controller
                    name="estimatedStart"
                    control={control}
                    render={({ field: { ref, ...rest }, fieldState }) => (
                      <TimePicker  
                      className={ "form-control " + (errors.estimatedStart ? " is-invalid" : "") }
                      format="HH:mm" placeholder="Estimated start *"    {...rest}  {...fieldState}
                      />
                    )}
                  />
                <small style={selectInvalidFeedback} >
                  {errors.estimatedStart && <>{errors.estimatedStart.message}</>}
                </small>
              </FormGroup>
            </Col>
          </Row>

          {watch("isActive") === true ? 
            <FormGroup className='formGroupSelect'>
              <Row>

              <Col xs="4">
                <Controller
                  name="customer"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      className="customerSelectSystem" 
                      placeholder="Select Customer *" 
                      options={optionsCustomers}
                      
                      onChange={
                        (e) => {
                          onChange(e); // on change mta3 el react hook form
                          getSystem(e.value);
                          setcompKeyCustomer(v4());
                          // setValue("system", {value: "", label:"Select System *"});
                        }
                      }
                      onBlur={onBlur}
                      selected={value}
                    />
                  )}
                />
                <small style={selectInvalidFeedback}>
                  {errors.customer && <>{errors.customer.value?.message}</>}
                </small>
              </Col>

              <Col xs="3">
                <Controller
                  name="system"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      className="customerSelectSystem" 
                      placeholder="Select System *" 
                      options={optionsSystems}
                      key={compKeyCustomer}
                      isLoading={isLoadingSystem}
                      isDisabled={watch("customer") === undefined}
                      onChange={
                        (e) => {
                          onChange(e); // on change mta3 el react hook form
                          getMonitoringActivity(e.value);
                          setValue("map", {value: "", label:"Select Monitoring Activity *"});
                        }
                      }
                      onBlur={onBlur}
                      selected={value}
                    />
                  )}
                />
                <small style={selectInvalidFeedback}>
                  {errors.system && <>{errors.system.value?.message}</>}
                </small>
              </Col>
              <Col xs="5">
                  <Controller
                    name="map"
                    control={control}
                    render={({ field: { ref, ...rest }, fieldState }) => (
                      <Select className="customerSelectMap"
                        placeholder="Select Monitoring Activity *"
                        options={optionsmonitoringActivities} 
                        isLoading={isloadingMaps}
                        
                        isDisabled={watch("system") === undefined}
                        {...rest}
                        {...fieldState}
                      />
                    )}
                  />
                  <small style={selectInvalidFeedback} >
                      {errors.map && <>{errors.map.value.message}</>}
                  </small>
              </Col>
              </Row>
            </FormGroup>
            : 
            <FormGroup>
                <Label>Title <span className="requiredField"> *</span></Label>
                <input
                  {...register("title")}
                  className={ "form-control" + (errors.title ? " is-invalid" : "")}
                />
                <small className="invalid-feedback">
                  {errors.title && <>{errors.title.message}</>}
                </small>
              </FormGroup>
          }
          
          <FormGroup>
            <Label>Description
              <span className="requiredField"> *</span>
            </Label>
            <textarea    {...register("description")}  className={ "form-control " + (errors.description ? " is-invalid" : "")}  />
            <small className="invalid-feedback ">
                  {errors.description && <>{errors.description.message}</>}
            </small>
          </FormGroup>
          
            <FormGroup>
                  <Label>
                    Assignee
                    <span className="requiredField"> *</span>
                  </Label>
                  <Controller
                    name="assignee"
                    control={control}
                    render={({ field: { ref, ...rest }, fieldState }) => (
                      <Select className="customerSelectAssignee"
                        placeholder="Select assignee"
                        options={optionMembers} 
                        {...rest}  
                        {...fieldState}
                      />
                    )}
                  />
                  <small style={selectInvalidFeedback} >
                      {errors.assignee && <>{errors.assignee.value.message}</>}
                  </small>
                </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary"  type="submit">Add Task</Button>
          <Button onClick={toggleModalAddNewCustomTask}>Cancel</Button>
        </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}
