import types from "../actions/types";
const {
  CREATE_SLA_SUCCESS,
  CREATE_SLA_FAILURE,
  LOAD_SLA,
  GET_ALL_SLAS_SUCCESS,
  GET_ALL_SLAS_FAILURE,
  UPDATE_SLA_SUCCESS,
  UPDATE_SLA_FAILURE,
  RETRIEVE_ONE_SLA_SUCCESS,
  RETRIEVE_ONE_SLA_FAILURE,
  DELETE_SLA_SUCCESS,
  DELETE_SLA_FAILURE,
  CLEAR_ALL_SLAS,
  CLEAR_ONE_SLA
} = types.slaTypes;
const initialState = {
  sla: null,
  slas: [],
  isLoading: false,
  error: null,
  listError: null,
};

function userReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_SLA_SUCCESS:
      state.slas.docs.push(payload);
      return {
        ...state,
        slas: state.slas,
        isLoading: false,
        error: null,
        listError: null,
      };
    case CREATE_SLA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

    case GET_ALL_SLAS_SUCCESS:
      return {
        ...state,
        slas: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case GET_ALL_SLAS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: payload,
      };

    case LOAD_SLA:
      return {
        ...state,
        isLoading: true,
      };

    case UPDATE_SLA_SUCCESS:
      const updatedIndex = state.slas.map((sla, index) => {
        if (payload._id === sla._id) {
          return index;
        } else return null;
      });
      if (updatedIndex) state.slas[updatedIndex] = payload;
      return {
        ...state,
        slas: state.slas,
        isLoading: false,
        error: null,
        listError: null,
      };

    case UPDATE_SLA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

    case RETRIEVE_ONE_SLA_SUCCESS:
      return {
        ...state,
        sla: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case RETRIEVE_ONE_SLA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

      case DELETE_SLA_SUCCESS:
      // return {
      //   ...state,
      //   slas: payload,
      //   isLoading: false,
      // };

      const Index = state.slas.docs.map((sla, index) => {
        if (payload._id === sla._id) {
          return index;
        } else return null;
      });
      if (Index) state.slas.docs[Index] = payload;
      return {
        ...state,
        slas: state.slas,
        isLoading: false,
        error: null,
        listError: null,
      };

    case DELETE_SLA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

    case CLEAR_ALL_SLAS:
      return {
        ...state,
        slas: [],
        isLoading: false,
        error: null,
        listError: null,
      };
      case CLEAR_ONE_SLA:
        return {
          ...state,
          sla: null,
          isLoading: false,
          error: null,
          listError: null,
        };
    default:
      return state;
  }
}

export default userReducer;
