const CREATE_SLA_SUCCESS = "CREATE_SLA_SUCCESS";
const CREATE_SLA_FAILURE = "CREATE_SLA_FAILURE";
const LOAD_SLA = "LOAD_SLA";
const GET_ALL_SLAS_SUCCESS = "GET_ALL_SLAS_SUCCESS";
const GET_ALL_SLAS_FAILURE = "GET_ALL_SLAS_FAILURE";
const UPDATE_SLA_SUCCESS = "UPDATE_SLA_SUCCESS";
const UPDATE_SLA_FAILURE = "UPDATE_SLA_FAILURE";
const RETRIEVE_ONE_SLA_FAILURE = "RETRIEVE_ONE_SLA_FAILURE";
const RETRIEVE_ONE_SLA_SUCCESS = "RETRIEVE_ONE_SLA_SUCCESS";
const DELETE_SLA_SUCCESS = "DELETE_SLA_SUCCESS";
const DELETE_SLA_FAILURE = "DELETE_SLA_FAILURE";
const CLEAR_ALL_SLAS = "CLEAR_ALL_SLAS";
const CLEAR_ONE_SLA = "CLEAR_ONE_SLA";

let slaTypes = {
  CREATE_SLA_SUCCESS,
  CREATE_SLA_FAILURE,
  LOAD_SLA,
  GET_ALL_SLAS_SUCCESS,
  GET_ALL_SLAS_FAILURE,
  UPDATE_SLA_SUCCESS,
  UPDATE_SLA_FAILURE,
  RETRIEVE_ONE_SLA_SUCCESS,
  RETRIEVE_ONE_SLA_FAILURE,
  DELETE_SLA_SUCCESS,
  DELETE_SLA_FAILURE,
  CLEAR_ALL_SLAS,
  CLEAR_ONE_SLA
};
export default slaTypes;
