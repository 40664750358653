import React, { useState, useEffect } from "react";

import { Row, Col, Label, Button, Input, FormGroup } from "reactstrap";
import "./userProfile.css";
//import { userData } from "./user-dummy-data";
import { BsPencil } from "react-icons/bs";
import { BiCheck, BiX } from "react-icons/bi";

import "antd/dist/antd.css";
import { Input as input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentUser,
  changeInformationUser,
  changePasswordUser,
  changeTimeZone
} from "../../actions/userAction";

import moment from "moment-timezone";
import Select from "../../shared/select/Select";

function UserProfile() {

  const timeZones = moment.tz.names().map((option)=>({value:option, label:option}));
  //const [selectedTimezone, setSelectedTimezone] = useState({value: 'Africa/Tunis', label: 'Africa/Tunis'});
  const currentTimeZone = useSelector(state => ({value: state.user.timeZone, label: state.user.timeZone}));

  const [showChangePwd, setShowChangePwd] = useState(false);
  const [showEditTel, setShowEditTel] = useState(true);
  const [showEditTelCheck, setShowEditTelCheck] = useState(false);
  const [changePasswordTest, setChangePasswordTest] = useState("0");
  const [test, setTest] = useState(false);
  //const [res, setRes] = useState("");

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);
  //console.log("User Connected ===>", user);
  const error = useSelector((state) => state.user.error);

  useEffect(() => {
    if(error){
  if (error==="Incorrect password") {
    setTest(false);
setChangePasswordTest("3");
      setTest(true);
  }else {
    setTest(false);
    setChangePasswordTest("1");
  setTest(true);
}
    }
    //console.log('my backend result', error);
  }, [error]);

  useEffect(() => {
    
    dispatch(getCurrentUser());
  },[dispatch])
  //User Phone Number
  const [phoneNumber, setPhoneNumber] = useState("");
  const handelPhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
    // console.log(phoneNumber);
  };

  // Edit User Profile
  function handleEdit() {
    setPhoneNumber(phoneNumber);

    if (phoneNumber) {
      const updatedData = {
        phoneNumber: phoneNumber,
      };
      dispatch(changeInformationUser(user._id, updatedData));
     // message.success("Updated Successfully",3);
    } else {
      const updatedData = {
        phoneNumber: phoneNumber,
      };
      dispatch(changeInformationUser(user._id, updatedData));
      //message.success("Updated Successfully",3);
    }

    // if (!error) {
    //   setrefrechData(!refrechData);
    //   setEditModal(!editModal);
    // } else {
    //   //console.error(error);
    // }
  }

  //User Old Password
  const [oldPassword, setOldPassword] = useState("");
  const handelOldPassword = (e) => {
    setOldPassword(e.target.value);
   // console.log(oldPassword);
  };

  //User New Password
  const [newPassword, setNewPassword] = useState("");
  const handelNewPassword = (e) => {
    setNewPassword(e.target.value);
   // console.log(newPassword);
  };

  //User Confirm Password
  const [confirmPassword, setConfirmPassword] = useState("");
  const handelConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    //console.log(confirmPassword);
  };

  // Edit Password Current User
  const handleEditPassword = (e) => {
    setOldPassword(oldPassword);
    setNewPassword(newPassword);
    setConfirmPassword(confirmPassword);

    if(newPassword !== confirmPassword){
      setTest(false);
      e.preventDefault();
      setChangePasswordTest("2");
      setTest(true);
      return false;
    }
    
    if  (newPassword === confirmPassword){
      setTest(false);
      setChangePasswordTest("0");

      const updatedDataPWD = {
        currentPassword: oldPassword,
        newPassword: newPassword,
      };

      dispatch(changePasswordUser(user._id, updatedDataPWD))
      
      return false;
      
      
    }

  
  }

  return (
    <>
      <section className="TitlePage">
        Profile
        {/* <div className="Page_Description">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eligendi
          reiciendis veniam veli
        </div> */}
      </section>
      <section className="avxWrpContentPage userProfilePage">
        <div className="userProfile">
          <Row>
            <Col xs="4" className="userProfileBadge">
              <p>
                <img src={user?.avatar} alt="logo" />
              </p>
              <h2>
                {user?.firstName} {user?.lastName}
              </h2>
              <FormGroup className="tzSelectContainer">
                <Label>Time Zone Display</Label>
                <Select
                  options={timeZones}
                  defaultValue={currentTimeZone}
                  onChange={(e)=>{dispatch(changeTimeZone(e.value))}}
                />
              </FormGroup>
            </Col>
            <Col xs="8" className="userProfileInfo">
              <Row>
                <Col xs="4">
                  <Label>First Name</Label>
                </Col>
                <Col xs="8">
                  <p>{user?.firstName}</p>
                </Col>
              </Row>
              <Row>
                <Col xs="4">
                  <Label>Last Name</Label>
                </Col>

                <Col xs="8">
                  <p>{user?.lastName}</p>
                </Col>
              </Row>
              <Row>
                <Col xs="4">
                  <Label>Email</Label>
                </Col>
                <Col xs="8">
                  <p>{user?.email}</p>
                </Col>
              </Row>
              <Row>
                <Col xs="4">
                  <Label>Phone Number</Label>
                </Col>
                <Col xs="4">
                  {showEditTel ? (
                    <p>
                      {user?.phoneNumber === "NaN" ? "---" : user?.phoneNumber}
                    </p>
                  ) : (
                    <Input
                      type="text"
                      defaultValue={
                        user?.phoneNumber === "NaN" ? "---" : user?.phoneNumber
                      }
                      maxLength="8"
                      onChange={handelPhoneNumber}
                    />
                  )}
                </Col>
                <Col xs="3">
                  <span>
                    {!showEditTelCheck ? (
                      <BsPencil
                        className="iconeBsPencil"
                        onClick={() => {
                          setShowEditTelCheck(!showEditTelCheck);
                          setShowEditTel(!showEditTel);
                        }}
                      ></BsPencil>
                    ) : (
                      <>
                      <BiCheck
                        className="iconeBiCheck"
                        onClick={() => {
                          setShowEditTelCheck(!showEditTelCheck);
                          setShowEditTel(!showEditTel);
                          handleEdit();
                         
                        }}
                      ></BiCheck>
                      
                      <BiX
                        className="iconeBiCheck"
                        onClick={() => {
                          setShowEditTelCheck(!showEditTelCheck);
                          setShowEditTel(!showEditTel);

                        }}
                      ></BiX>
                      </>
                    )}
                  </span>
                </Col>
              </Row>

              <Row>
                <Col xs="4">
                  <Label>Roles</Label>
                </Col>
                <Col xs="8">
                  <p>
                    { user?.Roles && user?.Roles.length !== 0
                      ?( user?.Roles.map((role, index) => (
                          <li key={index}>{role.label}</li>
                        )))
                      : (" No Role for this user ")}


                  
                  </p>
                </Col>
              </Row>
              {!user?.microsoftId && (
                <>
                  <Row>
                    <Col xs="4">
                      <Button
                        color="primary"
                        onClick={() => {
                          setShowChangePwd(!showChangePwd);
                        }}
                      >
                        Change Password
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {showChangePwd ? (
                        <div>
                          <FormGroup row>
                            <Label xs="4">Actual Password</Label>
                            <Col xs="4">
                              <input.Password
                                placeholder="Old Password"
                                onChange={handelOldPassword}
                                iconRender={(visible) =>
                                  visible ? (
                                    <EyeTwoTone />
                                  ) : (
                                    <EyeInvisibleOutlined />
                                  )
                                }
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label xs="4">New Password</Label>

                            <Col xs="4">
                              <input.Password
                                placeholder="New Password"
                                onChange={handelNewPassword}
                                iconRender={(visible) =>
                                  visible ? (
                                    <EyeTwoTone />
                                  ) : (
                                    <EyeInvisibleOutlined />
                                  )
                                }
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label xs="4">Confirm Password</Label>

                            <Col xs="4">
                              <input.Password
                                placeholder="Confirm Password"
                                onChange={handelConfirmPassword}
                                iconRender={(visible) =>
                                  visible ? (
                                    <EyeTwoTone />
                                  ) : (
                                    <EyeInvisibleOutlined />
                                  )
                                }
                              />
                            </Col>
                          </FormGroup>

                          {test && (
                            <Row>
                              {changePasswordTest === "1" && (
                                <p>Password successfully changed</p>
                               // message.success("Password successfully changed",3)
                              )}
                              {changePasswordTest === "2" && (
                                <p>Passwords don't match</p>
                                //message.error('Passwords don t match',3)
                              )}
                              {changePasswordTest === "3" && (
                                <p>Incorrect password</p>
                                //message.error('Incorrect password',3)
                              )}
                            </Row>
                          )}

                          <Row>
                            <Col
                              className="changePasswordFooter"
                              xs={{ size: 4, offset: 4 }}
                            >
                              <Button
                                color="primary"
                                onClick={handleEditPassword}
                              >
                                Save
                              </Button>
                              {"  "}
                              <Button
                                variant="outline-secondary"
                                onClick={() => {
                                  setShowChangePwd(false);
                                }}
                              >
                                Cancel
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </div>{" "}
      </section>
    </>
  );
}

export default UserProfile;
