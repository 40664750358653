import types from "./types";
import shiftService from "../services/shiftService"

const {
    RETRIEVE_ALL_NOMENCLATURE_SUCCESS,
    RETRIEVE_ALL_NOMENCLATURE_FAILURE,
    LOAD_NOMENCLATURE
}= types.nomenclatureTypes;

export const retreiveAllNomenclature=()=> async (dispatch)=> {
    try {
        dispatch({
            type : LOAD_NOMENCLATURE,
        })
        const res = await shiftService.getAllNomenclature();
        dispatch({
            type: RETRIEVE_ALL_NOMENCLATURE_SUCCESS,
            payload: res.data.data
        })
    } catch (err) {
        dispatch({
            type: RETRIEVE_ALL_NOMENCLATURE_FAILURE,
            payload: err,
          });
    }
}