import types from "./types";
import customerDataService from "../services/customerService";
import { message } from 'antd';
const {
  CREATE_CUSTOMER_SUCCESS,
  GET_ALL_CUSTOMERS_SUCCESS,
  UPDATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAILURE,
  GET_ALL_CUSTOMERS_FAILURE,
  UPDATE_CUSTOMER_FAILURE,
  LOAD_CUSTOMER,
  LOAD_CUSTOMERS,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAILURE,

  GET_CUSTOMERS_SYSTEMS_INFO_SUCCESS,
  GET_CUSTOMERS_SYSTEMS_INFO_FAILURE,
  LOAD_CUSTOMERS_SYSTEM_INFO,
  CLEAR_CUSTOMERS

} = types.customerTypes;

export const createCustomer = (customerData, timeZone) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_CUSTOMER,
    });
    const res = await customerDataService.create(customerData,timeZone);
    // console.log(res)
    message.success("Create Successfully",3);
    dispatch({
      type: CREATE_CUSTOMER_SUCCESS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);
  } catch (err) {
    // console.log(err)
    message.error('An Error occurred while creating',3);    
    //return Promise.reject(err.message);
    dispatch({
      type: CREATE_CUSTOMER_FAILURE,
      payload: err,
    });
  }
};

export const retrieveCustomers = (paginate, page, size, isActive, searchValue) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_CUSTOMERS,
    });
    const res = await customerDataService.getAll(paginate, page, size, isActive, searchValue);
    dispatch({
      type: GET_ALL_CUSTOMERS_SUCCESS,
      payload: res?.data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ALL_CUSTOMERS_FAILURE,
      payload: err.response?.data.message,
    });
  }
};

export const updateCustomer = (id, data, page, size, timeZone) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_CUSTOMER,
    });
    const res = await customerDataService.update(id, data, timeZone);
    message.success("Updated Successfully",3);
    dispatch({
      type: UPDATE_CUSTOMER_SUCCESS,
      payload: res.data.data,
    });

    dispatch({
      type: LOAD_CUSTOMERS,
    });
    const resAll = await customerDataService.getAll(true, page, size);
    dispatch({
      type: GET_ALL_CUSTOMERS_SUCCESS,
      payload: resAll.data.data,
    });
    //return Promise.resolve(res.data);
    // dispatch(setAlert("Customer Updated", "success"));
  } catch (err) {
    message.error('An Error occurred while updating',3);  
    dispatch({
      type: UPDATE_CUSTOMER_FAILURE,
      payload: err.response.data.message,
    });

    //return Promise.reject(err);
  }
};

// Get customer
export const getCustomer = (id, timeZone) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_CUSTOMER,
    });
    const res = await customerDataService.getOne(id, timeZone);
    dispatch({
      type: GET_CUSTOMER_SUCCESS,
      payload: res?.data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_CUSTOMER_FAILURE,
      payload: err.response?.data.message,
    });
  }
};

export const RetreiveCustomersSystemsInfo = () => async (dispatch) => {
 
  try {
    dispatch({
      type: LOAD_CUSTOMERS_SYSTEM_INFO,
    });
    const res = await customerDataService.getCustomerSystemsInfo();
    dispatch({
      type: GET_CUSTOMERS_SYSTEMS_INFO_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {
    dispatch({
      type: GET_CUSTOMERS_SYSTEMS_INFO_FAILURE,
      payload: err.response.data.message,
    });
  }
};

//clear Customers
export const clearCustomers = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_CUSTOMERS,
  })
}
