const CREATE_SLACONTRACT_SUCCESS = "CREATE_SLACONTRACT_SUCCESS";
const GET_ALL_SLACONTRACTS_SUCCESS = "GET_ALL_SLACONTRACTS_SUCCESS";
const UPDATE_SLACONTRACT_SUCCESS = "UPDATE_SLACONTRACT_SUCCESS";
const CREATE_SLACONTRACT_FAILURE = "CREATE_SLACONTRACT_FAILURE";
const GET_ALL_SLACONTRACTS_FAILURE = "GET_ALL_SLACONTRACTS_FAILURE";
const UPDATE_SLACONTRACT_FAILURE = "UPDATE_SLACONTRACT_FAILURE";
const LOAD_SLACONTRACT = "LOAD_SLACONTRACT";
const RETRIEVE_ONE_SLACONTRACT_FAILURE = "RETRIEVE_ONE_SLACONTRACT_FAILURE";
const RETRIEVE_ONE_SLACONTRACT_SUCCESS = "RETRIEVE_ONE_SLACONTRACT_SUCCESS";
const DELETE_SLACONTRACT_SUCCESS = "DELETE_SLACONTRACT_SUCCESS";
const DELETE_SLACONTRACT_FAILURE = "DELETE_SLACONTRACT_FAILURE";
const CLEAR_ALL_SLACONTRACTS = "CLEAR_ALL_SLACONTRACTS";

let slaContractTypes = {
  CREATE_SLACONTRACT_SUCCESS,
  GET_ALL_SLACONTRACTS_SUCCESS,
  UPDATE_SLACONTRACT_SUCCESS,
  CREATE_SLACONTRACT_FAILURE,
  GET_ALL_SLACONTRACTS_FAILURE,
  UPDATE_SLACONTRACT_FAILURE,
  LOAD_SLACONTRACT,
  RETRIEVE_ONE_SLACONTRACT_SUCCESS,
  RETRIEVE_ONE_SLACONTRACT_FAILURE,
  DELETE_SLACONTRACT_FAILURE,
  DELETE_SLACONTRACT_SUCCESS,
  CLEAR_ALL_SLACONTRACTS
};
export default slaContractTypes;
