import React, { useState, useEffect } from "react";
import "../../../adminPanel.css";
import {
  Row,
  Col,
  Input,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Form,
  Spinner,
} from "reactstrap";
import Select from "../../../../../shared/select/Select";
import { TablePagination } from "@material-ui/core";
import { BsPencil } from "react-icons/bs";
import { makeDelay, processTableHeight } from "../../../../../assets/js/CoreJs";

import { useDispatch, useSelector } from "react-redux";
import {retrieveAllModule, retrieveAllBasic, RetreiveOneModule, createModule, updateModule, clearModule} from "../../../../../actions/moduleAction";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./ModuleApYupValidation";


function Module() {
  const [page, setPage] = React.useState(0);
  //const [searchTerm, setSearchTerm] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  
  const [optionsBasicModule, setOptionsBasicModule] = useState([
    {
      value: "none",
      label: "none",
    },
  ]);  
  
  const [optionsBasicModuleEdit, setOptionsBasicModuleEdit] = useState([
    {
      value: "none",
      label: "none",
    },
  ]);

  const [refreshData, setrefreshData] = useState(false);
  const dispatch = useDispatch();
  const modules = useSelector((state) => state.moduleReducer.modules.docs);

  const isloading = useSelector((state) => state.moduleReducer.isLoading);
  const error = useSelector((state) => state.moduleReducer.listError);
  const ErrorAction = useSelector((state) => state.moduleReducer.error);
  const totalDocs = useSelector((state) => state.moduleReducer.modules.totalDocs);
  //const module = useSelector((state) => state.moduleReducer.module);
  //console.log("Selected module ====>",module)
  const [searchValue, setSearchValue] = useState("");

  const [permissions, setPermissions] = useState([]);
  const {
    control,
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });


  const applyTabHeightAdminPanelChildList = () => {
    processTableHeight(
      [
        "TitlePage",
        "adminPanelTabsNav",
        "moduleManagementAPListHeader",
        "moduleManagementAPPaginate",
      ],
      "moduleManagementAPListTCONT",
      15
    );
  };

  useEffect(() => {
    applyTabHeightAdminPanelChildList();
    window.addEventListener("resize", applyTabHeightAdminPanelChildList);

    //return fn must be last in useEffect
    return () => {
      window.removeEventListener("resize", applyTabHeightAdminPanelChildList);
    };
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setrefreshData(!refreshData);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setrefreshData(!refreshData);
  };

  useEffect(() => {
    dispatch(retrieveAllModule(page, rowsPerPage, searchValue));
  }, [dispatch, page, rowsPerPage, searchValue, refreshData]);

  const toggleAdd = async () => {
    reset();
    setPermissions([]);
    const optionsBasicModule = await dispatch(retrieveAllBasic());
    if (optionsBasicModule)
    setOptionsBasicModule(
        optionsBasicModule.map((value) => ({
          value: value._id,
          label: value.title,
        }))
      );
    setAddModal(!addModal);
  };
  // Module Path
  const [defaultPath, setDefaultPath] = useState("");

  const getOneModuleData = async (value) => {
    setValue("listModulePermission", []);
    const res = await dispatch(RetreiveOneModule(value));
    setDefaultPath(res.path)
    let listPermUpd = [];
    if (res) {
      for (let i = 0; i < res.listPermission.length; i++) {
        listPermUpd.push(res.listPermission[i]);
      }
      const permissionsSelect= listPermUpd?.map((value) => ({
        value: value?._id,
        label: value?.title,
      }))
      setPermissions(permissionsSelect);
    }
  };

  function handleCreateModule({title,path, listModulePermission}) {
    const dbPermissions = listModulePermission.map((permission) => permission.value);
    const newModule = {
      title: title,
      path: defaultPath,
      listPermission: dbPermissions,
    };
    dispatch(createModule(newModule));
    reset();
    setrefreshData(!refreshData);
    if (!ErrorAction) {
      setAddModal(!addModal);
    }
  }
  const [idModuleSelected, setIdModuleSelected] = useState("");

  //Edit Module
  const toggleEdit = async (e, id) => {
    reset();
    e.preventDefault();
    setIdModuleSelected(id)
    if (id) {
      const optionsBasicModule = await dispatch(retrieveAllBasic());
      const formattedOptionsBasicModule = optionsBasicModule.map((value) => ({
        value: value._id,
        label: value.title,
      }));
      setOptionsBasicModuleEdit(formattedOptionsBasicModule); // all basic modules
      const res = await dispatch(RetreiveOneModule(id));
      setDefaultPath(res.path);
      setValue("title", res.title);
      const listPermissions = res.listPermission.map((value) => ({
        value: value._id,
        label: value.title,
      }));
      setValue("listModulePermission", listPermissions); //default selected permissions
      let defaultPathValue = {};
      for (let i = 0; i < formattedOptionsBasicModule.length; i++) {
        if (optionsBasicModule[i].path === res.path){
          defaultPathValue ={value:optionsBasicModule[i]._id, label:optionsBasicModule[i].title};
        }
      }
      setValue("path", defaultPathValue); // default selected basic module
      const res2 = await dispatch(RetreiveOneModule(defaultPathValue.value));
      let listPermUpd = [];
      if (res2) {
        for (let i = 0; i < res2.listPermission.length; i++) {
          listPermUpd.push(res2.listPermission[i]);
        }
        const permissionsSelect= listPermUpd?.map((value) => ({
          value: value?._id,
          label: value?.title,
        }))
        setPermissions(permissionsSelect);
      }
    }
    setEditModal(!editModal);
  };

  async function handleEdit({title, listModulePermission}) {
    const dbPermissions = listModulePermission.map((permission) => permission.value);
      const updatedData = {
        title: title,
        path: defaultPath,
        listPermission: dbPermissions,
      };
      await dispatch(updateModule(idModuleSelected, updatedData));
      setrefreshData(!refreshData);
    if (!error) {
      setEditModal(!editModal);
    } else {
      //console.error(error);
    }
  }


  const handleSearchQuerry = (e) => {
    makeDelay(() => {
      if (e.target.value.length >= 2) {
        setSearchValue(e.target.value);
        setPage(0);
      } else {
        setSearchValue("");
      }
    }, 750);
  };

  useEffect(() => {
    return () => clearTimeout(window.timerDelay);
  }, []);

   //Clear Data
   useEffect(() => {
    return () => {
      dispatch(clearModule());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const selectInvalidFeedback = {
    width: "100%",
    marginTop: ".25rem",
    fontSize: "80%",
    color: "#dc3545",
  };

  if(errors.path){
    document.querySelector('.customerSelectPath > div:nth-child(2)') &&
    document.querySelector('.customerSelectPath > div:nth-child(2)').classList.add('isInvalidSelect');
  }
  if(errors.listModulePermission){
    document.querySelector('.customerSelectPermission > div:nth-child(2)') &&
    document.querySelector('.customerSelectPermission > div:nth-child(2)').classList.add('isInvalidSelect');
  }
  return (
    <>
      <section className="avxWrpContentPage moduleManagementAPPage">
        <Row className="moduleManagementAPListHeader">
          <Col xs="2">
            <Button color="primary" onClick={toggleAdd}>
              Add Module
            </Button>
          </Col>
          <Col xs={{ size: 4, offset: 6 }}>
            <Input
              type="search"
              className="form-control"
              placeholder="Search By Module Title"
              onChange={handleSearchQuerry}
            />
          </Col>
        </Row>

        <div className="adminPanelChildListContent">
          <div
            id="moduleManagementAPListTCONT"
            className="avxTableContainer scrollTb"
          >
            <Table
              className="apModuleListTable adminPanelChildListTable avxBsTable oneActs"
              striped
            >
              <thead>
                <tr>
                  <th>Title Module</th>
                  <th>Path</th>
                  <th>
                    <span>
                      <BsPencil />
                    </span>
                  </th>
                </tr>
              </thead>

              {isloading === false ? (
                <tbody>
                  {modules?.length > 0 && error === null ? (
                    <>
                      {modules
                        // .filter((val) => {
                        //   return val?.libelle
                        //     .toLowerCase()
                        //     .includes(searchTerm.toLowerCase());
                        // })
                        .map((m) => (
                          <tr key={m._id}>
                            {m.isBasic ? <td>
                              
                              <span >{m.title}{" "}<sup>[Basic] </sup> </span>
                              
                              </td>:<td>
                              
                              <span >{m.title} </span>
                              
                              </td>}
                           
                            <td>{m.path}</td>
                            <td>
                              <span
                                className="tbBtn"
                                onClick={(e) => toggleEdit(e, m._id)}
                              >
                                <BsPencil></BsPencil>
                              </span>
                            </td>
                          </tr>
                        ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="3">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                          }}
                        >
                          <span> No Module Exist </span>
                          {/* <span>{error} </span> */}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="3">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: 100,
                        }}
                      >
                        <Spinner color="dark" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}

            </Table>
          </div>

          <TablePagination
            className="moduleManagementAPPaginate"
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            component="div"
            count={totalDocs ? totalDocs : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>

        <Modal
          isOpen={addModal}
          toggle={toggleAdd}
          backdrop="static" keyboard={true}
          className="addAdminPanelChildModal"
          centered

          fade={false}
        >
        <Form onSubmit={handleSubmit(handleCreateModule)} >
          <ModalHeader toggle={toggleAdd}>Add new Module</ModalHeader>
          <ModalBody>
          <FormGroup>
                <Label>
                  Name
                  <span className="requiredField"> *</span>
                </Label>
                <input
                  type="text"
                  name="title"
                  placeholder="Module Title"
                  className={
                    "form-control" + (errors.title ? " is-invalid" : "")
                  }
                  {...register("title")}
                />
                <small className="invalid-feedback">
                  {errors.title && <>{errors.title.message}</>}
                </small>
              </FormGroup>

          <FormGroup>
                <Label>
                  Basic Module
                  <span className="requiredField"> *</span>
                </Label>
                <Controller
                  name="path"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      className="customerSelectPath"
                      placeholder="Select Basic Module"
                      options={optionsBasicModule}
                      onChange={
                        (e) => {
                          onChange(e); // on change mta3 el react hook form
                          getOneModuleData(e.value);
                        }
                      }
                      onBlur={onBlur}
                      selected={value}
                    />
                  )}
                />
                <small style={selectInvalidFeedback}>
                  {errors.path && <>{errors.path.value.message}</>}
                </small>
            </FormGroup>

            <FormGroup>
                <Label>
                    Add permission for this role module
                </Label>
                <Controller
                  name="listModulePermission"
                  control={control}
                  render={({ field: { ref, ...rest }, fieldState }) => (
                    <Select
                      className="customerSelectPermission"
                      placeholder="Choose Permissions"
                      isMulti
                      options={permissions}
                      {...rest}
                      {...fieldState}
                    />
                  )}
                />
                <small style={selectInvalidFeedback}>
                  {errors.listModulePermission && <>{errors.listModulePermission.message}</>}
                </small>
            </FormGroup>
            
          </ModalBody>
          <ModalFooter>
          <Button color="primary" type="submit">
              Add Module
          </Button>
            <Button color="secondary" onClick={toggleAdd}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
        </Modal>

        <Modal
          isOpen={editModal}
          toggle={toggleEdit}
          className="editAdminPanelChildModal"
          backdrop="static" keyboard={true}
          centered
          fade={false}
        >
        <Form onSubmit={handleSubmit(handleEdit)} >
          <ModalHeader toggle={toggleEdit}>Update Module</ModalHeader>
          <ModalBody>
          
          <FormGroup>
                <Label>
                  Name
                  <span className="requiredField"> *</span>
                </Label>
                <input
                  type="text"
                  placeholder="Module Title"
                  className={
                    "form-control" + (errors.title ? " is-invalid" : "")
                  }
                  {...register("title")}
                />
                <small className="invalid-feedback">
                  {errors.title && <>{errors.title.message}</>}
                </small>
              </FormGroup>

            <FormGroup>
                <Label>
                  Basic Module
                  <span className="requiredField"> *</span>
                </Label>
                <Controller
                    name="path"
                    control={control}
                    render={({ field: { ref, ...rest }, fieldState }) => (
                      <Select className="customerSelectPath"
                        placeholder="Select Basic Module"
                        options={optionsBasicModuleEdit} 
                        isDisabled={true}
                        {...rest}
                        {...fieldState}
                      />
                    )}
                  />
                <small style={selectInvalidFeedback}>
                  {errors.path && <>{errors.path.value.message}</>}
                </small>
            </FormGroup>

            <FormGroup>
                <Label>
                    Add permission for this role module
                </Label>
                <Controller
                  name="listModulePermission"
                  control={control}
                  render={({ field: { ref, ...rest }, fieldState }) => (
                    <Select
                      className="customerSelectPermission"
                      placeholder="Choose permissions"
                      isMulti
                      options={permissions}
                      {...rest}
                      {...fieldState}
                    />
                  )}
                />
                <small style={selectInvalidFeedback}>
                  {errors.listModulePermission && <>{errors.listModulePermission.message}</>}
                </small>
            </FormGroup>
          
          </ModalBody>
          <ModalFooter>
          <Button color="primary" type="submit">
              Save Changes
            </Button>
            <Button color="secondary" onClick={toggleEdit}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
        </Modal>
      </section>
    </>
  );
}

export default Module;
