import http from "../api";

const getAllLicence = (customer_Id, page, size) => {
  return http.get(
    `/api/licence/getbycustomer/${customer_Id}?page=${page}&size=${size}`
  );
};
const createLicence = (licence) => {
  return http.post(`/api/licence`, licence);
};
const updateLicence = (id, licence) => {
  return http.patch(`/api/licence/update/${id}`, licence);
};
const deleteLicence = (id) => {
  return http.patch(`/api/licence/delete/${id}`);
};
const getOneLicence = (id) => {
  return http.get(`/api/licence/getone/${id}`);
};
const licenceService = {
  getAllLicence,
  createLicence,
  updateLicence,
  deleteLicence,
  getOneLicence,
};
export default licenceService;
