import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Input,
  Table,
  FormGroup,
  Label,
  Badge,
  Button,
  Spinner,
} from "reactstrap";
import "./components/MyTasks.css";
//import { generatedTasks } from "./dumy-tasks";
import { processTableHeight, makeDelay } from "../../assets/js/CoreJs";
import NewTaskRequest from "./components/NewTaskRequest";
import MyTaskRequests from "./components/MyTaskRequests";
import MyTasksWorkflow from './components/MyTasksWorkflow';
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { retrieveTasksByUserId , clearTasks} from "../../actions/taskAction";
import ManageOtherTaskModal from "./components/ManageOtherTaskModal";

const MyTasks = () => {
  const genPillColor = (taskStatus) => {
    const pillColor = {
      "done": "success",
      "pending": "warning",
      "canceled": "danger",
      "in progress": "primary",
      "completed": "info",
    }[taskStatus];
    return pillColor;
  };

  const applyTabHeight = () => {
    processTableHeight(["TitlePage", "MyTasksHeader"], "MyTasksListTableTCONT");
  };

  useEffect(() => {
    applyTabHeight();
    window.addEventListener("resize", applyTabHeight);

    return () => {
      window.removeEventListener("resize", applyTabHeight);
    };
  }, []);

  const [state, setState] = useState([
    "Pending",
    "Done",
    "In progress",
    "Canceled",
    "Completed",
  ]);
  // const [type, setType] = useState("Monitoring");
  const [searchValue, setSearchValue] = useState("");
  const tabType=[
    "Monitoring",
    "Other Monitoring",
    "Other"
  ]

  const dispatch = useDispatch();
  const currentTimeZone = useSelector((state) => state.user.timeZone);
  //console.log("currentTimeZone===>",currentTimeZone);

  const myTasks = useSelector((state) => state.taskReducer.myTasks);
  // console.log("myTasks===>",myTasks);
  const isloading = useSelector((state) => state.taskReducer.isLoading);
  const error = useSelector((state) => state.taskReducer.listError);
  // const ErrorAction = useSelector((state) => state.taskReducer.error);

  useEffect(() => {
    
    dispatch(
      retrieveTasksByUserId(
        // JSON.stringify([type]),
        JSON.stringify(tabType),
        JSON.stringify(state),
        currentTimeZone,
        searchValue
      )
    );
        //eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [dispatch, state, currentTimeZone, searchValue]);

  const checkChange = () => {
    const newStates = [];
    const filters = document.querySelectorAll(".flCheck");
    filters.forEach((input) => {
      input.checked && newStates.push(input.value);
    });
    setState(newStates);
  };

  let tab = [];
  myTasks?.map(
    (value) =>
      ({
        value: value._id,
        label: value.state,
      } && tab.push(value.state))
  );
  // console.log("Taaab ",tab)
  // console.log("Liste all tasks ex ====>",ex)

  const countOccurrences = (arr, val) =>
    arr.reduce((a, v) => (v === val ? a + 1 : a), 0);
  const pen = countOccurrences(tab, "Pending");
  const can = countOccurrences(tab, "Canceled");
  const don = countOccurrences(tab, "Done");
  const inp = countOccurrences(tab, "In progress");
  const cplt = countOccurrences(tab, "Completed");

  const handelSearshQuerry = (e) => {
    makeDelay(() => {
      if (e.target.value.length >= 2) {
        setSearchValue(e.target.value);
      } else {
        setSearchValue("");
      }
    }, 750);
  };

  useEffect(() => {
    return () => clearTimeout(window.timerDelay);
  }, []);

  const [workflowState, setWorkflowState] = useState(false);
  const closeWorkflow = async()=>{
    await setWorkflowState(false);
    applyTabHeight();
  }

  //Clear Data
  useEffect(() => {
    return () => {
      dispatch(clearTasks());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <>
      <section className="TitlePage">
        My Tasks
        {/* <div className="Page_Description">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eligendi
          reiciendis veniam veli
        </div> */}
      </section>

      {
        workflowState ? <MyTasksWorkflow closeWorkflow={closeWorkflow} />
        : (
          <>
            <section className="avxWrpContentPage MyTasksPage">
              <Row>
                <Col xs="10">
                  <Row className="MyTasksHeader">
                    <Col xs="9">
                      <MyTaskRequests />
                      <NewTaskRequest />
                      <Button color="primary" onClick={()=>setWorkflowState(true)}>WorkFlow</Button>
                    </Col>

                    <Col className="SearchBar" xs="3">
                      <Input
                        type="search"
                        className="form-control"
                        placeholder="Search by Task, System"
                        onChange={handelSearshQuerry}
                      />
                    </Col>
                  </Row>

                  <div className="TableContainer">
                    <div
                      id="MyTasksListTableTCONT"
                      className="avxTableContainer scrollTb"
                    >
                      <Table className="MyTasksListTable avxBsTable" striped hover>
                        <thead>
                          <tr>
                            <th>Task</th>
                            <th>Customer</th>
                            <th>System</th>
                            <th>Start Time</th>
                            <th>Status</th>
                          </tr>
                        </thead>

                        {/* <tbody className="forCursorPointer">
                          {generatedTasks.map((task, index) => (
                            <tr
                              key={task._id}
                              className={`${
                                task.status === "Pending" ? "statusColor" : ""
                              }`}
                            >
                              <td>{task.label}</td>
                              <td>{task.customer}</td>
                              <td>{task.system}</td>
                              <td>{task.start}</td>

                              <td>
                                <Badge
                                  color={genPillColor(task.status.toLowerCase())}
                                  pill
                                >
                                  {" "}
                                  {task.status}{" "}
                                </Badge>
                              </td>
                            </tr>
                          ))}
                        </tbody> */}

                        {isloading === false ? (
                          <tbody className="forPointer">
                            {myTasks !==null && error === null ? (
                              <>
                                {myTasks?.map((task) => (
                                  <tr
                                    key={task._id}
                                    className={`${
                                      task.state === "Pending" ? "statusColor" : ""
                                    }`}
                                  >
                                    {(() => {
                                  switch (task.type) {
                                    case 'Other Monitoring':
                                      return <td> {task.title} <span className="typeStyle"> CM </span> </td>
                                    case 'Other':
                                      return <td> {task.title} <span className="typeStyle"> CO </span> </td>
                                  
                                    default:
                                      return <td>{task.title}</td>
                                }
                                })()}
                                    {task.customerName ?
                                      <td>{task.customerName}</td>
                                      :
                                      <td><span>---</span></td>}

                                    {task.systemName ?
                                      <td>{task.systemName}</td>
                                      :
                                      <td><span>---</span></td>}
                                    <td>
                                      {/* {task.estimatedStart.split("T")[1].split("+")[0]} */}
                                      {moment(task.estimatedStart)
                                        .tz(currentTimeZone)
                                        .format("LT")}
                                    </td>

                                    <td>
                                      <Badge
                                        color={genPillColor(task.state.toLowerCase())}
                                        pill
                                      >
                                        {" "}
                                        {task.state}{" "}
                                      </Badge>
                                      {(task.type ==="Other") && (task.state ==="In progress") ? <ManageOtherTaskModal taskID={task._id}/>:<span> {" "} </span>}
                                      {/* {(() => {
                                  switch (task.type) {
                                    case 'Other Monitoring':
                                      return <span> {" "} </span>
                                    case 'Other':
                                      return <ManageOtherTaskModal/>
                                    default:
                                      return <span>{" "}</span>
                                }
                                })()} */}
                                    </td>
                                  </tr>
                                ))}
                              </>
                            ) : (
                              <tr>
                                <td colSpan="5">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      height: "100%",
                                    }}
                                  >
                                    <span> No Tasks </span>
                                    {/* <span>{error} </span> */}
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan="5">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: 100,
                                  }}
                                >
                                  <Spinner color="dark" />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </Table>
                    </div>
                  </div>
                </Col>

                <Col xs="2">
                  <div className="MyTasksHighlights">
                    <h5 className="MyTasksHighlightsTitle">Highlights</h5>
                    <div className="MyTasksHighlightsInsideContent">
                      <table className="tableHighlight">
                        <tbody>
                          <tr>
                            <td>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    className="flCheck"
                                    onChange={checkChange}
                                    value="Pending"
                                    checked={state.includes("Pending") ? true : false}
                                  />{" "}
                                  <Badge color="warning" pill>
                                    Pending
                                  </Badge>
                                </Label>
                              </FormGroup>
                            </td>
                            <td>
                              <p className="StatusNumber">{pen}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    className="flCheck"
                                    onChange={checkChange}
                                    value="In progress"
                                    checked={
                                      state.includes("In progress") ? true : false
                                    }
                                  />{" "}
                                  <Badge color="primary" pill>
                                    In progress
                                  </Badge>
                                </Label>
                              </FormGroup>
                            </td>
                            <td>
                              <p className="StatusNumber">{inp}</p>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    className="flCheck"
                                    onChange={checkChange}
                                    value="Completed"
                                    checked={
                                      state.includes("Completed") ? true : false
                                    }
                                  />{" "}
                                  <Badge color="info" pill>
                                    Completed
                                  </Badge>
                                </Label>
                              </FormGroup>
                            </td>
                            <td>
                              <p className="StatusNumber">{cplt}</p>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    className="flCheck"
                                    onChange={checkChange}
                                    value="Done"
                                    checked={state.includes("Done") ? true : false}
                                  />{" "}
                                  <Badge color="success" pill>
                                    Done
                                  </Badge>
                                </Label>
                              </FormGroup>
                            </td>
                            <td>
                              <p className="StatusNumber">{don}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    className="flCheck"
                                    onChange={checkChange}
                                    value="Canceled"
                                    checked={
                                      state.includes("Canceled") ? true : false
                                    }
                                  />{" "}
                                  <Badge color="danger" pill>
                                    Canceled
                                  </Badge>
                                </Label>
                              </FormGroup>
                            </td>
                            <td>
                              <p className="StatusNumber">{can}</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/* <div className="MyTaskFilter">
                    <h5 className="MyTasksFilterTitle">Task Types</h5>
                    <div className="MyTasksFilterInsideContent">
                      <FormGroup check inline>
                        <Label className="MyTasksFilterLabel">
                          <Input
                            type="radio"
                            name="taskTypeRadio"
                            value="Monitoring"
                            onClick={() => setType("Monitoring")}
                            defaultChecked
                          />{" "}
                          Monitoring Tasks
                        </Label>
                      </FormGroup>

                      <FormGroup>
                        <Label className="MyTasksFilterLabel">Custom Tasks</Label>
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              type="radio"
                              name="taskTypeRadio"
                              value="Other Monitoring"
                              onClick={() => setType("Other Monitoring")}
                            />{" "}
                            Monitoring
                          </Label>
                        </FormGroup>

                        <FormGroup check inline>
                          <Label check>
                            <Input
                              type="radio"
                              name="taskTypeRadio"
                              value="Other"
                              onClick={() => setType("Other")}
                            />
                            Other
                          </Label>
                        </FormGroup>
                      </FormGroup>
                    </div>
                  </div> */}
                </Col>
              </Row>
            </section>
          </>
        ) 
      }

      
    </>
  );
};

export default MyTasks;
