import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Label,
  Input,
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Spinner,
} from "reactstrap";
import { BsTrash, BsPencil } from "react-icons/bs";
import { makeDelay, processTableHeight } from "../../../../../assets/js/CoreJs";
import "../CustomerSystems.css";

//import { CustomerSystems } from "../systems-dummy-data";

import { useDispatch, useSelector } from "react-redux";

import {
  createClient,
  retrieveClients,
  deleteClient,
  RetreiveOneClient,
  updateClient,
  clearClients,
  clearOneClient
} from "../../../../../actions/clientAction";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./AddClientValidation";

const SystemClients = function ({ selectedSystemID }) {
  //temporary to get index from fakeData json
  //const sysIndex = CustomerSystems.findIndex((s) => s.id === selectedSystemID);
  // const sysRow = CustomerSystems[sysIndex];

  const [addModal, setAddModal] = useState(false);
  //const toggleAdd = () => setAddModal(!addModal);
  const toggleAdd = () => {
    setAddModal(!addModal);
    reset();
  }
  const [editModal, setEditModal] = useState(false);
  //const toggleEdit = () => setEditModal(!editModal);
  const toggleResetEdit = () => {
    setEditModal(!editModal);
    reset();
  }
  const dispatch = useDispatch();
  const [refrechData, setrefrechData] = useState(false);
  const ErrorAction = useSelector((state) => state.client.error);
  const clients = useSelector((state) => state.client.clients);
  const error = useSelector((state) => state.client.listError);
  const isloading = useSelector((state) => state.client.isLoading);
  const selectedClient = useSelector((state) => state.client.client);
  // const [searchTerm, setSearchTerm] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const confirmDelete = (id) => {
    if (window.confirm("Are you sure you want to delete the Client ?")) {
      dispatch(deleteClient(id, selectedSystemID));
      //setrefrechData(!refrechData);
    }
  };

  const applyTabHeight = () => {
    processTableHeight(
      [
        "TitlePage",
        "customerDetailsTabsNav",
        "sysDetailHeader",
        "customerSystemDetailsTabsNav",
        "sysClientsHeader",
      ],
      "systemClientsTCONT"
    );
  };
  useEffect(() => {
    applyTabHeight();
    window.addEventListener("resize", applyTabHeight);

    //return fn must be last in useEffect
    return () => {
      window.removeEventListener("resize", applyTabHeight);
    };
  }, []);

  const {
    // control,
     handleSubmit,
     register,
     setValue,
     //watch,
     reset,
     formState: { errors },
   } = useForm({
     resolver: yupResolver(schema),
   });

  //Client hostAttribute
  // const [client, setClient] = useState("");
  // const handelClient = (e) => {
  //   setClient(e.target.value);
  //   //console.log(client);
  // };

  //Add New Client
  // function handleCreateClient() {
  //   const newClient = {
  //     clientNumber: client,
  //     isActive: true,
  //     system: selectedSystemID,
  //   };
  //   dispatch(createClient(newClient));
  //   setClient(null);
  //   setrefrechData(!refrechData);
  //   if (!ErrorAction) {
  //     setAddModal(!addModal);
  //   }
  // }

  //****Add New Client*****///

  // console.log(errors);
  // console.log("errors");
  const handelAddFormSubmit = (clientData) => {
    //console.log("clientData===>", clientData);
    clientData.isActive=true
    clientData.system=selectedSystemID
    dispatch(createClient(clientData));
    // setHost(null);
    setrefrechData(!refrechData);
    setAddModal(!addModal);
    reset();
    if (!ErrorAction) {
      setAddModal(!addModal);
    }
  };


  // Get All Clients
  useEffect(() => {
    if (selectedSystemID) {
      dispatch(retrieveClients(selectedSystemID, searchValue));
    }
  }, [dispatch, selectedSystemID, searchValue, refrechData]);

  const toggleEdit = async (e, id) => {
    e.preventDefault();
    if (id) {
      const res = await dispatch(RetreiveOneClient(id));
      if (res) {
        //setClient(res.clientNumber);
        setValue('clientNumber',res.clientNumber)
      }
    }
    setEditModal(!editModal);
  };

  // Edit CLIENT
  // function handleEdit() {
  //   setClient(client);

  //   if (client) {
  //     const updatedData = {
  //       clientNumber: client,
  //     };
  //     dispatch(updateClient(selectedClient._id, updatedData));
  //   } else {
  //     const updatedData = {
  //       clientNumber: client,
  //     };
  //     dispatch(updateClient(selectedClient._id, updatedData));
  //   }

  //   if (!error) {
  //     setrefrechData(!refrechData);
  //     setEditModal(!editModal);
  //   } else {
  //     //console.error(error);
  //   }
  // }

////*********************Update Client*****************////

  const handelUpdateFormSubmit = (clientData) => {
    // hostData.isActive=true
    // hostData.system=selectedSystemID
    //console.log("hostData====>",hostData);
    dispatch(updateClient(selectedClient._id, clientData));
    // setHost(null);
    setrefrechData(!refrechData);
    setEditModal(!editModal);
    reset();
    if (!error) {
          setrefrechData(!refrechData);
          setEditModal(!editModal);
        } else {
          //console.error(error);
        }
  };

  const handleSearch = (e) => {
    makeDelay(() => {
      if (e.target.value.length >= 1) {
        setSearchValue(e.target.value);
      } else {
        setSearchValue("");
      }
    }, 750);
  };

  useEffect(() => {
    return () => clearTimeout(window.timerDelay);
  }, []);

   //Clear Data
   useEffect(() => {
    return () => {
      dispatch(clearClients());
      dispatch(clearOneClient());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
    <div className="systemClients">
      <Row className="sysClientsHeader">
        <Col xs="2">
          <Button color="primary" onClick={toggleAdd}>
            Add Client
          </Button>
        </Col>
        <Col xs={{ size: 4, offset: 6 }}>
          <Input
            type="search"
            className="form-control"
            placeholder="Search"
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <div className="sysClientsContent">
        <div id="systemClientsTCONT" className="avxTableContainer scrollTb">
          <Table className="systemClientsTable avxBsTable twoActs" striped>
            <thead>
              <tr>
                <th>Client</th>
                <th>
                  <span>
                    <BsPencil></BsPencil>
                  </span>
                </th>
                <th>
                  <span>
                    <BsTrash></BsTrash>
                  </span>
                </th>
              </tr>
            </thead>

            {isloading === false ? (
              <tbody>
                {clients.length !== 0 ? (
                  <>
                    {clients
                      .map((c) => (
                        <tr key={c._id}>
                          <td>{c.clientNumber}</td>
                          <td>
                            <span
                              className="tbBtn"
                              onClick={(e) => toggleEdit(e, c._id)}
                            >
                              <BsPencil></BsPencil>
                            </span>
                          </td>
                          <td>
                            <span
                              className="tbBtn"
                              onClick={() => confirmDelete(c._id)}
                            >
                              <BsTrash></BsTrash>
                            </span>
                          </td>
                        </tr>
                      ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan="3">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                        }}
                      >
                        <span> No Client Exist For this System {error} </span>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="3">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: 100,
                      }}
                    >
                      <Spinner color="dark" />
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
      </div>

      <Modal
        isOpen={addModal}
        toggle={toggleAdd}
        className="addSystemClientModal"
        centered
        fade={false}
        backdrop="static" 
        keyboard={true}
      >
        <Form onSubmit={handleSubmit(handelAddFormSubmit)} className="formModalAddClient">
        <ModalHeader toggle={toggleAdd}>Add new system Client</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Client
            <span className="requiredField"> *</span>
            </Label>
            <input type="number" name="clientName" id="clientName"  
            className={ "form-control" + (errors.clientNumber ? " is-invalid" : "") }
            {...register("clientNumber")}/>
            <small className="invalid-feedback">
                    {errors.clientNumber && <>{errors.clientNumber.message}</>}
              </small>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
        <Button color="primary" type="submit" > 
            Add Client
          </Button>

          <Button color="secondary" onClick={toggleAdd}>
            Cancel
          </Button>
        </ModalFooter>
       </Form> 
      </Modal>

      <Modal
        isOpen={editModal}
        toggle={toggleEdit}
        className="editSystemClientModal"
        centered
        fade={false}
        backdrop="static"
        keyboard={true}
      >
        <Form onSubmit={handleSubmit(handelUpdateFormSubmit)} className="formModalEditClient">
        <ModalHeader toggle={toggleEdit}>Update system Client</ModalHeader>
        <ModalBody>
        <FormGroup>
            <Label>Client
            <span className="requiredField"> *</span>
            </Label>
            <input type="number" name="clientName" id="clientName"  
            className={ "form-control" + (errors.clientNumber ? " is-invalid" : "") }
            {...register("clientNumber")}/>
            <small className="invalid-feedback">
                    {errors.clientNumber && <>{errors.clientNumber.message}</>}
              </small>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit">
            Save Changes
          </Button>{" "}
          <Button color="secondary" onClick={toggleResetEdit}>
            Cancel
          </Button>
        </ModalFooter>
          </Form> 
      </Modal>
    </div>
  );
};

export default SystemClients;
