import React, { useState } from "react";
import "../../adminPanel.css";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import TypeSystemAP from "./SystemAPComponents/TypeSystemAP";
import ClassSystemAP from "./SystemAPComponents/ClassSystemAP";
import MonitoringActivityAP from "./SystemAPComponents/MonitoringActivityAP";
import CategorySystemAP from "./SystemAPComponents/CategorySystemAP";

function SystemAP() {
  const [activeTab, setActiveTab] = useState("TS");
  const toggleRender = (tab) => {
    activeTab !== tab && setActiveTab(tab);
  };

  return (
    <div className="systemAP">
      {/* <Row className="adminPanelHeader"></Row> */}

      <div className="systemAPBody">
        <Nav tabs className="adminPanelChildTabsNav childTabForSystem">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "TS" })}
              onClick={() => {
                toggleRender("TS");
              }}
            >
              Types
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "CS" })}
              onClick={() => {
                toggleRender("CS");
              }}
            >
              Categories
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "CLS" })}
              onClick={() => {
                toggleRender("CLS");
              }}
            >
              Classes
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "MA" })}
              onClick={() => {
                toggleRender("MA");
              }}
            >
              Monitoring Activity
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent className="adminPanelTabsContent">
          {
            {
              TS: (
                <TabPane className="typeSystemAPTab">
                  <TypeSystemAP />
                </TabPane>
              ),
              CS: (
                <TabPane className="categorySystemAPTab">
                  <CategorySystemAP />
                </TabPane>
              ),
              CLS: (
                <TabPane className="classSystemAPTab">
                  <ClassSystemAP />
                </TabPane>
              ),
              MA: (
                <TabPane className="monitoringActivityAPTab">
                  <MonitoringActivityAP />
                </TabPane>
              ),
            }[activeTab]
          }
        </TabContent>
      </div>
    </div>
  );
}

export default SystemAP;
