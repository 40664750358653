import types from "./types";
import shiftService from "../services/shiftService"

const {
RETRIEVE_ALL_SHIFT_REPORT_SUCCESS,
RETRIEVE_ALL_SHIFT_REPORT_FAILURE,
LOAD_SHIFT_REPORT,
RETRIEVE_ALL_MEMBERS_REPORT_SUCCESS,
RETRIEVE_ALL_MEMBERS_REPORT_FAILURE,
LOAD_MEMBERS,
LOAD_AVAILABLE_SHIFT,
RETRIEVE_AVAILABLE_SHIFT_SUCCESS,
RETRIEVE_AVAILABLE_SHIFT_FAILURE,

LOAD_MEMBERS_BY_SHIFTS,
RETRIEVE_MEMBERS_BY_SHIFTS_SUCCESS,
RETRIEVE_MEMBERS_BY_SHIFTS_FAILURE,
CLEAR_UPCOMING_SHIFTS

}= types.shiftTypes;

export const retreiveShiftReport=(startDate, endDate,assigned,unassigned,timeZone)=> async (dispatch)=> {
    try {
        dispatch({
            type : LOAD_SHIFT_REPORT,
        })
        const res = await shiftService.getAllShiftReport(startDate, endDate,assigned,unassigned,timeZone);
        let sortedShifts= res.data.data.docs.sort((a, b) => new Date(a.shift.startDate) - new Date(b.shift.startDate))

        dispatch({
            type: RETRIEVE_ALL_SHIFT_REPORT_SUCCESS,
            payload: {...res.data.data,docs: sortedShifts}
        })
    } catch (err) {
        dispatch({
            type: RETRIEVE_ALL_SHIFT_REPORT_FAILURE,
            payload: err,
          });
    }
}

export const retreiveShiftMembers=(startDate, endDate,shift)=> async (dispatch)=> {
    try {
        dispatch({
            type : LOAD_MEMBERS,
        })
        const res = await shiftService.getAllMembers(startDate, endDate,shift);
        dispatch({
            type: RETRIEVE_ALL_MEMBERS_REPORT_SUCCESS,
            payload: res.data.data
        })
    } catch (err) {
        dispatch({
            type: RETRIEVE_ALL_MEMBERS_REPORT_FAILURE,
            payload: err,
          });
    }
}

//retrieve shift members
export const getAllMembersByShifts=(timeZone)=> async (dispatch)=> {
    try {
        dispatch({
            type : LOAD_MEMBERS_BY_SHIFTS,
        })
        const res = await shiftService.getAllMembersByShifts(timeZone);
        dispatch({
            type: RETRIEVE_MEMBERS_BY_SHIFTS_SUCCESS,
            payload: res.data.data
        })
    } catch (err) {
        dispatch({
            type: RETRIEVE_MEMBERS_BY_SHIFTS_FAILURE,
            payload: err,
          });
    }
}
//clear shift members
export const clearShiftMembers = () =>  (dispatch) => {
    dispatch({
        type: CLEAR_UPCOMING_SHIFTS,
    })
}



export const retreiveAvailableShift=(startDate, endDate,assigned,unassigned,shift,user,timeZone)=> async (dispatch)=> {
    try {
        dispatch({
            type : LOAD_AVAILABLE_SHIFT,
        })
        const res = await shiftService.getAvailableShift(startDate, endDate,assigned,unassigned,shift,user,timeZone);
        let sortedShifts= res.data.data.docs
        .sort((a, b) => new Date(a.shift.startDate) - new Date(b.shift.startDate))
        
        dispatch({
            type: RETRIEVE_AVAILABLE_SHIFT_SUCCESS,
            payload: {...res.data.data,docs: sortedShifts}
        })
    } catch (err) {
        dispatch({
            type: RETRIEVE_AVAILABLE_SHIFT_FAILURE,
            payload: err,
          });
    }
}

