import React, { useEffect } from "react";
// import Select from 'react-select'
import { processTableHeight } from "../../assets/js/CoreJs";
import "./ReportingChart.css";
import { useDispatch } from "react-redux";
import "react-loading-skeleton/dist/skeleton.css";
import { Row, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import ActiveUsersChart from "./components/ActiveUsersChart";
import AverageResponseTimeChart from "./components/AverageResponseTimeChart";
import DbGrowthChart from "./components/DbGrowthChart";
import { clearStoreStat } from "../../actions/statisticAction";
import classnames from "classnames";
import ResultHistory from "../reporting/components/resultHistory/view/ResultHistory";

const Reporting = () => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = React.useState("CH");
  const toggle = (tab) => {
    if (tab === "CH") {
    }
    if (activeTab !== tab) setActiveTab(tab);
  };

  const applyTabHeight = () => {
    processTableHeight(
      ["TitlePage", "headerContent"],
      "GlobalReportTableTCONT"
    );
  };
  useEffect(() => {
    applyTabHeight();
    window.addEventListener("resize", applyTabHeight);
    return () => {
      window.removeEventListener("resize", applyTabHeight);
      dispatch(clearStoreStat());
    };
  }, [dispatch]);
  return (
    <>
      <section className="TitlePage">Statistics</section>
      <section className="avxWrpContentPage GlobalReportPage">

          <Nav tabs className="taskTrackingTabsNav">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "CH" })}
                onClick={() => {
                  toggle("CH");
                }}
              >
                Charts
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "RH" })}
                onClick={() => {
                  toggle("RH");
                }}
              >
                Result History
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent className="taskTrackingTabsContent">
            {
              {
                CH: (
                  <TabPane className="AllMonitoringActivitiesDetaillsTab">
                    <Row>
                      <ActiveUsersChart />
                      <AverageResponseTimeChart />
                    </Row>
                    <Row>
                      <DbGrowthChart />
                    </Row>
                  </TabPane>
                ),
                RH: (
                  <TabPane>
                    <ResultHistory />
                  </TabPane>
                ),
              }[activeTab]
            }
          </TabContent>

      </section>
    </>
  );
};
export default Reporting;
