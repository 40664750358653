import React, { useState, useEffect } from "react";
import {
  Button,
  FormGroup,
  Label,
  ModalFooter,
  ModalHeader,
  Form,
  Modal,
  ModalBody,
  Row,
  Col,
} from "reactstrap";
import "./NewTaskRequest.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Switch, TimePicker } from "antd";
import Select from "react-select";
import {
  AddOneTasks,
  retreiveSystemsForMaps,
  retreiveSystems,
  retreiveMembers,
  retreiveCustomer
} from "../../../actions/taskAction";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./NewTaskRequestYupValidation";
import { v4 } from "uuid";

function NewTaskRequest() {
  const dispatch = useDispatch();
  const [modalNewTaskRequest, setmodalNewTaskRequest] = useState(false);
  const timeZone = useSelector((state) => state.user.timeZone);
  const [optionsSystems, setoptionsSystems] = useState([]);
  const [optionsmonitoringActivities, setoptionsmonitoringActivities] =
    useState([]);
  const toggleModalNewTaskRequest = () => {
    setmodalNewTaskRequest(!modalNewTaskRequest);
  };
  const monitoringActivities = useSelector(
    (state) => state.taskReducer.AllSystemsFormMap.docs
  );
  const assignee = useSelector((state) => state.user.user?._id);
  const systems = useSelector((state) => state.taskReducer.AllSystems);

  let members = useSelector((state) => state.taskReducer.AllMembers);
  let isLoadingMembers = useSelector(
    (state) => state.taskReducer.isLoadingMembers
  );
  let [optionMembers, setoptionMembers] = useState([]);

  let isLoadingSystem = useSelector(state => state.taskReducer.isLoadingSystem)
  let [optionsCustomers, setoptionsCustomers] = useState([])
  let customers = useSelector(state => state.taskReducer.AllCustomers.docs)
  //console.log("List Customer--->",customers);
  let  isloadingMaps = useSelector(state => state.taskReducer.isloadingMaps)
  const [compKeyCustomer, setcompKeyCustomer] = useState(v4());

  const {
    control,
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  //Retrieving Systems
  // useEffect(() => {
  //   dispatch(retreiveSystems());
  // }, [dispatch]);

  useEffect(() => {
    dispatch(retreiveCustomer(false, null, null, true))
   
  }, [dispatch]);

  const getSystem =  (selectedCustomerID) => {
    dispatch(retreiveSystems(selectedCustomerID))
  }

  useEffect(() => {
    setoptionsmonitoringActivities(
      monitoringActivities?.map((act) => ({
        value: act._id,
        label: act.monitoringAct.activity,
      }))
    );
    setoptionsSystems(systems?.map((sys)=>({
      value : sys._id,
      label : sys.name
    })))
  }, [monitoringActivities,systems]);

  useEffect(() => {
    assignee && dispatch(retreiveMembers(assignee));
  }, [dispatch, assignee]);


  function handletoggleModal() {
    //  const handletoggleModal = async () => {
    reset();

    setValue("isActive", true);
    // setoptionsSystems(
    //   systems?.map((sys) => ({
    //     value: sys._id,
    //     label: sys.name,
    //   }))
    // );
    const myOptionMembers = members?.map((mem) => ({
      value: mem._id,
      label: mem.firstName + " " + mem.lastName,
    }));
    setoptionMembers(myOptionMembers);
    const defaultAssignee = myOptionMembers.filter(
      (el) => el.value === assignee
    );
    if (defaultAssignee) {
      setValue("assignee", defaultAssignee[0]);
    }

    setoptionsCustomers(customers?.map((cust)=>({
      value : cust._id,
      label : cust.label
    })))
    setmodalNewTaskRequest(!modalNewTaskRequest);
  }

  function handleCreateTask({
    isActive,
    description,
    estimatedStart,
    title,
    system,
    map,
    assignee,
  }) {
    const formattedTime = moment(estimatedStart?._d).format("YYYY-MM-DDTHH:mm");

    let newTask = {};
    isActive === false
      ? (newTask = {
          title: title,
          state: "To be validated",
          description: description,
          type: "Other",
          estimatedStart: formattedTime,
          assignee: assignee.value,
        })
      : (newTask = {
          title: map.label,
          state: "To be validated",
          description: description,
          type: "Other Monitoring",
          estimatedStart: formattedTime,
          system: system.value,
          map: map.value,
          assignee: assignee.value,
        });
    dispatch(AddOneTasks(newTask, timeZone));
    setmodalNewTaskRequest(!modalNewTaskRequest);
  }

  const selectInvalidFeedback = {
    width: "100%",
    marginTop: ".25rem",
    fontSize: "80%",
    color: "#dc3545",
  };

  if (errors.system) {
    document.querySelector(".customerSelectSystem > div:nth-child(2)") &&
      document
        .querySelector(".customerSelectSystem > div:nth-child(2)")
        .classList.add("isInvalidSelect");
  }

  if (errors.map) {
    document.querySelector(".customerSelectMap > div:nth-child(2)") &&
      document
        .querySelector(".customerSelectMap > div:nth-child(2)")
        .classList.add("isInvalidSelect");
  }

  const getMonitoringActivity = (selectedSystemID) => {
    dispatch(retreiveSystemsForMaps(selectedSystemID, timeZone));
  };

  return (
    <>
      <Button color="primary" onClick={handletoggleModal}>
        New Task Request
      </Button>
      <Modal
        className="NewTaskRequestModal"
        centered
        backdrop="static" 
        keyboard={true}
        isOpen={modalNewTaskRequest}
        size="lg"
        toggle={toggleModalNewTaskRequest}
        fade={false}
      >
        <Form onSubmit={handleSubmit(handleCreateTask)}>
          <ModalHeader toggle={toggleModalNewTaskRequest}>
            New Task Request
          </ModalHeader>
          <ModalBody>
            <Row className="NewTaskRequestSwitch">
              <Col>
                <FormGroup>
                  <Label className="LabelMonitoringTask">
                    <Controller
                      control={control}
                      name="isActive"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Switch
                          onBlur={onBlur}
                          onChange={(e) => {
                            onChange(e); // on change mta3 el react hook form
                            if (e === true) {
                              setValue("title", undefined);
                            } else {
                              setValue("system", undefined);
                              setValue("map", undefined);
                            }
                          }}
                          checked={value}
                          defaultChecked
                        />
                      )}
                    />
                    <span>Monitoring Task</span>
                  </Label>
                </FormGroup>
              </Col>
              <Col xs="5">
                <FormGroup>
                  <Controller
                    name="estimatedStart"
                    control={control}
                    render={({ field: { ref, ...rest }, fieldState }) => (
                      <TimePicker
                        className={
                          "form-control " +
                          (errors.estimatedStart ? " is-invalid" : "")
                        }
                        format="HH:mm"
                        placeholder="Estimated start *"
                        {...rest}
                        {...fieldState}
                      />
                    )}
                  />
                  <small style={selectInvalidFeedback}>
                    {errors.estimatedStart && (
                      <>{errors.estimatedStart.message}</>
                    )}
                  </small>
                </FormGroup>
              </Col>
            </Row>

            {watch("isActive") === true ? (
              <FormGroup className="NewTask_FormGroup">
                <Row>

                <Col xs="4">
                <Controller
                  name="customer"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      className="customerSelectSystem" 
                      placeholder="Select Customer *" 
                      options={optionsCustomers}
                      
                      onChange={
                        (e) => {
                          onChange(e); // on change mta3 el react hook form
                          getSystem(e.value);
                          setcompKeyCustomer(v4());
                          // setValue("system", {value: "", label:"Select System *"});
                        }
                      }
                      onBlur={onBlur}
                      selected={value}
                    />
                  )}
                />
                <small style={selectInvalidFeedback}>
                  {errors.customer && <>{errors.customer.value?.message}</>}
                </small>
              </Col>

              <Col xs="3">
                <Controller
                  name="system"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      className="customerSelectSystem" 
                      placeholder="Select System *" 
                      options={optionsSystems}
                      key={compKeyCustomer}
                      isLoading={isLoadingSystem}
                      isDisabled={watch("customer") === undefined}
                      onChange={
                        (e) => {
                          onChange(e); // on change mta3 el react hook form
                          getMonitoringActivity(e.value);
                          setValue("map", {value: "", label:"Select Monitoring Activity *"});
                        }
                      }
                      onBlur={onBlur}
                      selected={value}
                    />
                  )}
                />
                <small style={selectInvalidFeedback}>
                  {errors.system && <>{errors.system.value?.message}</>}
                </small>
              </Col>
                  {/* <Col xs="5">
                    <Controller
                      name="map"
                      control={control}
                      // render={({ field: { onChange, onBlur, value } }) => (
                        render={({ field: { ref, ...rest }, fieldState }) => (
                        <Select
                          className="customerSelectMap"
                          placeholder="Select Monitoring Activity *"
                          options={optionsmonitoringActivities}
                          isLoading={isloadingMaps}
                          isDisabled={watch("system") === undefined}
                          // onChange={onChange}
                          // onBlur={onBlur}
                          // selected={value}
                          {...rest}
                          {...fieldState}
                        />
                      )}
                    />
                    <small style={selectInvalidFeedback}>
                      {errors.map && <>{errors.map.value.message}</>}
                    </small>
                  </Col> */}
                   <Col xs="5">
                  <Controller
                    name="map"
                    control={control}
                    render={({ field: { ref, ...rest }, fieldState }) => (
                      <Select className="customerSelectMap"
                        placeholder="Select Monitoring Activity *"
                        options={optionsmonitoringActivities} 
                        isLoading={isloadingMaps}
                        
                        isDisabled={watch("system") === undefined}
                        {...rest}
                        {...fieldState}
                      />
                    )}
                  />
                  <small style={selectInvalidFeedback} >
                      {errors.map && <>{errors.map.value.message}</>}
                  </small>
              </Col>
                </Row>
              </FormGroup>
            ) : (
              <FormGroup>
                <Label>
                  Title<span className="requiredField"> *</span>
                </Label>
                <input
                  {...register("title")}
                  className={
                    "form-control" + (errors.title ? " is-invalid" : "")
                  }
                />
                <small className="invalid-feedback">
                  {errors.title && <>{errors.title.message}</>}
                </small>
              </FormGroup>
            )}

            <FormGroup>
              <Label>
                Description <span className="requiredField"> *</span>
              </Label>
              <textarea
                {...register("description")}
                className={
                  "form-control " + (errors.description ? " is-invalid" : "")
                }
              ></textarea>
              <small className="invalid-feedback ">
                {errors.description && <>{errors.description.message}</>}
              </small>
            </FormGroup>

            <FormGroup>
              <Label>
                Assignee
                <span className="requiredField"> *</span>
              </Label>
              <Controller
                name="assignee"
                control={control}
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <Select
                    className="customerSelectTimeZone"
                    placeholder="Select assignee"
                    isLoading={isLoadingMembers}
                    options={optionMembers}
                    {...rest}
                    {...fieldState}
                  />
                )}
              />
              <small style={selectInvalidFeedback}>
                {errors.assignee && (
                  <>{errors.assignee?.value.message}</>
                )}
              </small>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Add Request
            </Button>
            <Button onClick={toggleModalNewTaskRequest}>Cancel</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default NewTaskRequest;
