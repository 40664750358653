import http from "../api";
const jose = require('jose');

const getCurrentUser = async () => {
  const user = await http.get('/api/user/getCurrent',
    {
      headers: {
        "Content-type": "application/json",
        "x-auth-accessToken": localStorage.getItem('ma-access-token'),
        "x-auth-refreshToken": localStorage.getItem('ma-refresh-token')
      }
    }
  );
  
  const brokerage = user?.data.data._id;
  if(brokerage){
    let maBrokerArray = [];
    const maBrokerOldToken = localStorage.getItem('ma-broker-token');
    if(maBrokerOldToken){
      const {payload: brPayload } = await jose.jwtVerify(maBrokerOldToken, new TextEncoder().encode('XAXBXC123456XA'));
      maBrokerArray = brPayload.brokers;
    }
    const found = maBrokerArray.filter(elt => elt === brokerage);
    if(!found || !found.length) maBrokerArray.push(brokerage);
    const maBrokerNewToken = await new jose.SignJWT({'brokers': maBrokerArray}).setProtectedHeader({alg:'HS256'}).sign(new TextEncoder().encode('XAXBXC123456XA'));
    localStorage.setItem('ma-broker-token', maBrokerNewToken);
  }

  return user;
};

const changeInformationUser = (id, user) => {
    return http.patch(`/api/user/changeInformation/${id}`, user
    // ,{
    //   headers: {
    //     "Content-type": "application/json",
    //     "x-auth-accessToken": localStorage.getItem('ma-access-token'),
    //     "x-auth-refreshToken": localStorage.getItem('ma-refresh-token')
    //   }
    // }
    );
  };

  const changePassword = (id, user) => {
    return http.post(`/api/user/changePassword/${id}`, user
    
     ,{
      headers: {
        "Content-type": "application/json",
        "x-auth-accessToken": localStorage.getItem('ma-access-token'),
        "x-auth-refreshToken": localStorage.getItem('ma-refresh-token')
      }
    }
    );
  };

const UserService = {
    getCurrentUser,
    changeInformationUser,
    changePassword,
  
};
export default UserService;