const GET_ALL_TYPE_SUCCESS = "GET_ALL_TYPE_SUCCESS";
const GET_ALL_TYPE_FAILURE = "GET_ALL_TYPE_FAILURE";
const LOAD_TYPE = "LOAD_TYPE";
const CREATE_TYPE_SUCCESS = "CREATE_TYPE_SUCCESS";
const CREATE_TYPE_FAILURE = "CREATE_TYPE_FAILURE";
const RETRIEVE_ONE_TYPE_FAILURE = "RETRIEVE_ONE_TYPE_FAILURE";
const RETRIEVE_ONE_TYPE_SUCCESS = "RETRIEVE_ONE_TYPE_SUCCESS";
const UPDATE_TYPE_SUCCESS = "UPDATE_TYPE_SUCCESS";
const UPDATE_TYPE_FAILURE = "UPDATE_TYPE_FAILURE";
const CLEAR_TYPES = "CLEAR_TYPES";
let typeTypes = {
  LOAD_TYPE,
  GET_ALL_TYPE_SUCCESS,
  GET_ALL_TYPE_FAILURE,
  CREATE_TYPE_SUCCESS,
  CREATE_TYPE_FAILURE,
  RETRIEVE_ONE_TYPE_SUCCESS,
  RETRIEVE_ONE_TYPE_FAILURE,
  UPDATE_TYPE_SUCCESS,
  UPDATE_TYPE_FAILURE,
  CLEAR_TYPES
};
export default typeTypes;
