import types from "../actions/types";
const {
    GET_REPORT_LAYOUT_SUCCESS,
    GET_REPORT_LAYOUT_FAILURE,
    LOAD_REPORT_LAYOUT,
    CLEAR_REPORT_LAYOUT,
    LOAD_GET_RESULT_REPORT,
    GET_RESULT_REPORT_SUCCESS,
    GET_RESULT_REPORT_FAILURE,
    GET_ONE_RESULT_LOADER_REPORT,
    GET_ONE_RESULT_REPORT_SUCCESS,
    GET_ONE_RESULT_REPORT_FAILURE,
    CLEAR_GLOBAL_REPORT
 
} = types.globalReportTypes;
const initialState = {
  systems:[],
  monitoringActivities : [],
  DataReport:[],
  OneResult:[],
  IsLoadingResult: false,
  isLoadingDataReport:false,
  isLoadingLayout: false,
  error: null,
};

function globalReportReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
        case GET_REPORT_LAYOUT_SUCCESS:
        return {
            ...state,
            systems:payload.systems,
            monitoringActivities : payload.monitoringActivities,
            isLoadingLayout: false,
            error: null,
            listError: null,
        };
          case LOAD_REPORT_LAYOUT:
          return {
            ...state,
            isLoadingLayout: true,
          };
          case GET_REPORT_LAYOUT_FAILURE:
          return {
            ...state,
            isLoadingLayout: false,
            systems:[],
            monitoringActivities : [],
            error: payload,
            listError: null,
          }; 
          case CLEAR_REPORT_LAYOUT:
          return {
            systems:[],
            monitoringActivities : [],
            isLoadingLayout: false,
            error: null,
          };
          case LOAD_GET_RESULT_REPORT:
          return {
            ...state,
            isLoadingDataReport: true,
          };
          case GET_RESULT_REPORT_FAILURE:
          return {
            ...state,
            DataReport:[],
            isLoadingDataReport: false,
            error: payload,
          }; 
          case GET_RESULT_REPORT_SUCCESS:
          return {
            ...state,
            DataReport:payload,
            isLoadingDataReport:false,
          };
          case GET_ONE_RESULT_LOADER_REPORT:
          return {
            ...state,
            IsLoadingResult: true,
          };
          case GET_ONE_RESULT_REPORT_FAILURE:
          return {
            ...state,
            OneResult:[],
            IsLoadingResult: false,
            error: payload,
          }; 
          case GET_ONE_RESULT_REPORT_SUCCESS:
          return {
            ...state,
            OneResult:payload,
            IsLoadingResult:false,
          };

          case CLEAR_GLOBAL_REPORT:
            return {
              systems:[],
              Maps : [],
              DataReport:[],
              OneResult:[],
              isLoadingLayout: false,
              error: null,
            };

    default:
      return state;
  }
}

export default globalReportReducer;
