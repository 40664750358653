const CREATE_HOST_SUCCESS = "CREATE_HOST_SUCCESS";
const CREATE_HOST_FAILURE = "CREATE_HOST_FAILURE";
const LOAD_HOST = "LOAD_HOST";
const GET_ALL_HOSTS_SUCCESS = "GET_ALL_HOSTS_SUCCESS";
const GET_ALL_HOSTS_FAILURE = "GET_ALL_HOSTS_FAILURE";
const DELETE_HOST_SUCCESS = "DELETE_HOST_SUCCESS";
const DELETE_HOST_FAILURE = "DELETE_HOST_FAILURE";
const RETRIEVE_ONE_HOST_FAILURE = "RETRIEVE_ONE_HOST_FAILURE";
const RETRIEVE_ONE_HOST_SUCCESS = "RETRIEVE_ONE_HOST_SUCCESS";
const UPDATE_HOST_SUCCESS = "UPDATE_HOST_SUCCESS";
const UPDATE_HOST_FAILURE = "UPDATE_HOST_FAILURE";
const CLEAR_HOSTS = "CLEAR_HOSTS";
const CLEAR_ONE_HOST = "CLEAR_ONE_HOST";

let hostTypes = {
  CREATE_HOST_SUCCESS,
  CREATE_HOST_FAILURE,
  LOAD_HOST,
  GET_ALL_HOSTS_SUCCESS,
  GET_ALL_HOSTS_FAILURE,
  DELETE_HOST_SUCCESS,
  DELETE_HOST_FAILURE,
  RETRIEVE_ONE_HOST_SUCCESS,
  RETRIEVE_ONE_HOST_FAILURE,
  UPDATE_HOST_SUCCESS,
  UPDATE_HOST_FAILURE,
  CLEAR_HOSTS,
  CLEAR_ONE_HOST
};
export default hostTypes;
