import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "antd/dist/antd.css";
import "../../customer.css";
import { Upload } from "antd";
import { DatePicker } from "antd";
import { FiUploadCloud } from "react-icons/fi";
import moment from "moment-timezone";
import {
  Row,
  Col,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Form,
  Modal,
} from "reactstrap";
import Select from "../../../../shared/select/Select";
import { createCustomer } from "../../../../actions/customerAction";
import { getMonitoringType } from "../../../../actions/monitoringTypeAction";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./AddCustomerValidation";

const timeZones = moment.tz.names().map((timeZone) => ({
  value: timeZone,
  label: timeZone,
}));;

  
const Uploadprops = {
  listType: "picture",
  beforeUpload(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = document.createElement("img");
        img.src = reader.result;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = img.naturalWidth;
          canvas.height = img.naturalHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          canvas.toBlob(resolve);
        };
      };
    });
  },
};

const AddCustomerModal = (props) => {

  const {
    
    refrechData,
    setrefrechData,
  } = props;

  const currentTimeZone= useSelector((state) => state.user.timeZone);

    
  const contractTypes = useSelector(
    (state) => state.monitoringType.monitoringTypes.docs?state.monitoringType.monitoringTypes.docs:[]
  );
  const dispatch = useDispatch();
  const [modalAdd, setModalAdd] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const { RangePicker } = DatePicker;
  const { Dragger } = Upload;

  const {
    control,
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  if(errors.timeZone){
    document.querySelector('.customerSelectTimeZone > div:nth-child(2)') &&
    document.querySelector('.customerSelectTimeZone > div:nth-child(2)').classList.add('isInvalidSelect');
  }

  const fileHandlerAdd = (file) => {
    setFile(file.fileList[0]);
  };

  const toggleAdd = () => {
    if (!modalAdd) {
      dispatch(getMonitoringType(0,1000,true));
    }
    setModalAdd(!modalAdd);
    reset();
    setFile(null);
  };

  const handelAddFormSubmit = ({ label , address , firstReport , timeZone , status , range , types }) => {
    const data = new FormData();
    if (file) {
      data.set("logo", file.originFileObj);
    }
    data.set("label", label);
    data.set("address", address);
    data.set("firstReport", firstReport);
    const timeZoneData = timeZone.map((timeZone) => timeZone.value);
    data.set("timeZone", timeZoneData);
    data.set("isActive", status);
    if (status) {
      data.set("startDate", range[0]);
      data.set("endDate", range[1]);
    }
    data.set("listMonitoringType", types);
    dispatch(createCustomer(data, currentTimeZone));
    setrefrechData(!refrechData);
    setModalAdd(!modalAdd);
    reset();
    setFile(null);
  };

  const selectInvalidFeedback = {
    width: "100%",
    marginTop: ".25rem",
    fontSize: "80%",
    color: "#dc3545",
  };

  return (
    <>
      <Button color="primary" onClick={toggleAdd}>
        Add Customer
      </Button>

      <Modal className="modalAddCustomer"  backdrop="static" keyboard={true} centered isOpen={modalAdd} toggle={toggleAdd} fade={false} size="lg" >
        <Form onSubmit={handleSubmit(handelAddFormSubmit)} className="formModalAddCustomer">
          <ModalHeader toggle={toggleAdd}>Add new Customer</ModalHeader>
          <ModalBody>

            <Row>
              <Col>
              <FormGroup>
                
                  <Label>
                    Name 
                    <span className="requiredField"> *</span>
                  </Label>
                  <input
                    type="text" name="customer" id="customer" placeholder="Customer"
                    className={ "form-control" + (errors.label ? " is-invalid" : "") }
                    {...register("label")}
                  />
                  <small className="invalid-feedback">
                    {errors.label && <>{errors.label.message}</>}
                  </small>
                </FormGroup>

                <FormGroup>
                  <Label>
                    Address 
                    <span className="requiredField"> *</span>
                  </Label>
                  <textarea
                    className={ "form-control" + (errors.address ? " is-invalid" : "") }
                    name="address" id="address" placeholder="Address"
                    {...register("address")}
                  />
                  <small className="invalid-feedback">
                    {errors.address && <>{errors.address.message}</>}
                  </small>
                </FormGroup>

                <FormGroup>
                  <Label>
                    Time Zones 
                    <span className="requiredField"> *</span>
                  </Label>
                  <Controller
                    name="timeZone"
                    control={control}
                    render={({ field: { ref, ...rest }, fieldState }) => (
                      <Select className="customerSelectTimeZone"
                        placeholder="Select time zones" options={timeZones} isMulti={true}
                        {...rest}
                        {...fieldState}
                      />
                    )}
                  />
                  <small style={selectInvalidFeedback} >
                      {errors.timeZone && <>{errors.timeZone.message}</>}
                  </small>
                </FormGroup>

                <FormGroup>
                  <Label>First Report 
                    <span className="requiredField"> *</span>
                  </Label>
                  <input
                    type="time" name="firstReport" id="firstReport"
                    className={ "form-control" + (errors.firstReport ? " is-invalid" : "") }
                    {...register("firstReport")}
                  />
                  <small className="invalid-feedback">
                    {errors.firstReport && <>{errors.firstReport.message}</>}
                  </small>
                </FormGroup>

                <FormGroup>
                  <div><Label className="liceLabel">License Status</Label></div>
                  <FormGroup check inline>
                    <Label check>
                      <input type="radio" name="status" {...register("status")} value={true}  /> Active
                    </Label>{' '}
                    
                  </FormGroup>
                  <FormGroup check inline>
                    <Label check>
                      <input type="radio" name="status" {...register("status")}  defaultChecked  value={false} /> Disabled
                    </Label>
                  </FormGroup>
                </FormGroup>

                {
                  watch("status")==="true" ? (
                    <FormGroup className="customerDatepickerContainer visible">
                      <Controller
                          name="range"
                          control={control}
                          render={({ field: { ref, ...rest }, fieldState }) => (
                            <RangePicker
                              {...rest}
                              {...fieldState}
                              className={ "activateCustomerDatepicker " + (errors.range ? " isInvalidRange" : "") }
                          />
                          )}
                       />
                      <small style={selectInvalidFeedback} >
                          {errors.range && <>{errors.range.message}</>}
                      </small>
                    </FormGroup>
                  )
                  :
                  (
                    <FormGroup className="customerDatepickerContainer">
                      <RangePicker className="activateCustomerDatepicker" />
                    </FormGroup>
                  )
                }

              </Col>
              
              <Col>
                <FormGroup>
                  <Label>Logo</Label>
                  <Dragger className="customerLogoDragger" maxCount={1} {...Uploadprops} name="file" onChange={(e)=>fileHandlerAdd(e)} >
                    <FiUploadCloud max={1} />
                    <p>Click or drag file to this area to upload</p>
                  </Dragger>
                </FormGroup>
                
                <FormGroup>
                  <Label>Contract Types 
                    <span className="requiredField"> *</span>
                  </Label>
                  {contractTypes.map((contractType) => (
                    <FormGroup key={contractType._id} className="singleContractTypeCheck" check>
                      <Label check>
                        <input className="form-check-input" type="checkbox"  {...register("types")}   value={contractType._id} />
                        {contractType.libelle}
                      </Label>
                    </FormGroup>
                  ))}
                  <small style={selectInvalidFeedback} >
                    {errors.types && <>{errors.types.message}</>}
                  </small>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">Add Customer</Button>{' '}
            <Button color="secondary"  onClick={toggleAdd}>Cancel</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default AddCustomerModal;
