import types from "../actions/types";
const {
    CREATE_SYNC_SHIFT_SUCCESS,
    CREATE_SYNC_SHIFT_FAILURE,
    LOAD_SYNC_SHIFT,
    GET_ALL_SYNC_SHIFTS_SUCCESS,
    GET_ALL_SYNC_SHIFTS_FAILURE,
    LOAD_SYNC_SHIFT_FOR_CREATE,
    CLEAR_SYNC_SHIFTS,
    
} = types.syncShiftTypes;
const initialState = {
  shift: [],
  shifts: [],
  isLoading: false,
  isLoadingForCreation: false,
  error: null,
  listError: null,
};

function userReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    case CREATE_SYNC_SHIFT_SUCCESS:
      return {
        ...state,
        //shift: payload,
       // isLoading: false,
        isLoadingForCreation: false,
        error: null,
        listError: null,
      };
    case CREATE_SYNC_SHIFT_FAILURE:
      return {
        ...state,
        //isLoading: false,
        isLoadingForCreation: false,
        error: payload,
        listError: payload,
      };

    case LOAD_SYNC_SHIFT:
      return {
        ...state,
        isLoading: true,
      };

    
    case LOAD_SYNC_SHIFT_FOR_CREATE:
      return {
        ...state,
        isLoadingForCreation: true,
      };


    case GET_ALL_SYNC_SHIFTS_SUCCESS:
      return {
        ...state,
        shift: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case GET_ALL_SYNC_SHIFTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

    case CLEAR_SYNC_SHIFTS:
      return {
        ...state,
        shift: [],
        isLoading: false,
        error: null,
        listError: null,
    };

    default:
      return state;
  }
}

export default userReducer;
