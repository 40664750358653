import types from "./types";
import resultFormService from "../services/resultFormService"
import { message } from "antd";
const {
    LOAD_RESULT_FORM,
    GET_ALL_RESULT_FORM_SUCCESS,
    GET_ALL_RESULT_FORM_FAILURE,
    CREATE_RESULT_FORM_SUCCESS,
    CREATE_RESULT_FORM_FAILURE,
    RETRIEVE_ONE_RESULT_FORM_SUCCESS,
    RETRIEVE_ONE_RESULT_FORM_FAILURE,
    UPDATE_RESULT_FORM_SUCCESS,
    UPDATE_RESULT_FORM_FAILURE,
    DELETE_RESULT_FORM_SUCCESS,
    DELETE_RESULT_FORM_FAILURE,

    GET_ALL_MONITORING_ACTIVITY_WITHOUT_RESULT_FORM_SUCCESS,
    GET_ALL_MONITORING_ACTIVITY_WITHOUT_RESULT_FORM_FAILURE,
    LOAD_MONITORING_ACTIVITY,

    CLEAR_RESULT_FORM

} = types.resultFormTypes;

export const retrieveAllResultForms = (page, size, paginate, isActive, searchValue) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_RESULT_FORM,
    });

    const res = await resultFormService.getAllResultForm(page, size, paginate, isActive, searchValue);
    dispatch({
      type: GET_ALL_RESULT_FORM_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data.docs;
  } catch (err) {
    dispatch({
      type: GET_ALL_RESULT_FORM_FAILURE,
      payload: err,
    });
  }
};

export const createResultForm = (resultForm) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_RESULT_FORM,
      });
      const res = await resultFormService.createResultForm(resultForm);
      message.success("Successfully created  the result form");
      dispatch({
        type: CREATE_RESULT_FORM_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      message.error("Failed to create the result form");
      dispatch({
        type: CREATE_RESULT_FORM_FAILURE,
        payload: err,
      });
    }
  };

  export const RetreiveOneResultForm = (id) => async (dispatch) => {
    try {
      const res = await resultFormService.getOneResultForm(id);
      dispatch({
        type: RETRIEVE_ONE_RESULT_FORM_SUCCESS,
        payload: res.data.data,
      });
      return res.data.data;
    } catch (err) {
      dispatch({
        type: RETRIEVE_ONE_RESULT_FORM_FAILURE,
        payload: err.response.data.message,
      });
    }
  };
  
  export const updateResultForm= (id, data) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_RESULT_FORM,
      });
      const res = await resultFormService.updateResultForm(id, data);
      message.success("Successfully updated the result form");
      dispatch({
        type: UPDATE_RESULT_FORM_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      message.error("Failed to update the result form");
      dispatch({
        type: UPDATE_RESULT_FORM_FAILURE,
        payload: err?.response?.data?.message,
      });
    }
  };

  export const deleteResultForm = (id) => async (dispatch) => {
    try {
      const res = await resultFormService.deleteResultForm(id);
      message.success("Successfully deleted the result form");
      dispatch({
        type: LOAD_RESULT_FORM,
      });
      dispatch({
        type: DELETE_RESULT_FORM_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      message.error("Failed to delete the result form");
      dispatch({
        type: DELETE_RESULT_FORM_FAILURE,
        payload: err.response.data.message,
      });
    }
  };

  export const retrieveMonitoringActWithoutResultForm = () => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_MONITORING_ACTIVITY,
      });
      const res = await resultFormService.getMonitoringActWithoutResultForm();
      dispatch({
        type: GET_ALL_MONITORING_ACTIVITY_WITHOUT_RESULT_FORM_SUCCESS,
        payload: res.data.data,
      });
      return res.data.data;
    } catch (err) {
      dispatch({
        type: GET_ALL_MONITORING_ACTIVITY_WITHOUT_RESULT_FORM_FAILURE,
        payload: err.response.data.message,
      });
    }
  };

   //clear Data
 export const clearResultForm = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_RESULT_FORM,
  })
}
  


