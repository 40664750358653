import React, { useState } from "react";
import "../../adminPanel.css";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import ModuleAP from "./moduleManagementComponents/ModuleAP";

function ModuleManagementAP() {
  const [activeTab, setActiveTab] = useState("MO");
  const toggleRender = (tab) => {
    activeTab !== tab && setActiveTab(tab);
  };

  return (
    <div className="moduleManagementAP">
      {/* <Row className="adminPanelHeader"></Row> */}

      <div className="moduleManagementAPBody">
        <Nav tabs className="adminPanelChildTabsNav childTabForModule">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "MO" })}
              onClick={() => {
                toggleRender("MO");
              }}
            >
              Module
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent className="adminPanelTabsContent">
          {
            {
              MO: (
                <TabPane className="moduleAPTab">
                  <ModuleAP />
                </TabPane>
              ),
            }[activeTab]
          }
        </TabContent>
      </div>
    </div>
  );
}

export default ModuleManagementAP;
