import types from "../actions/types";
const {
    GET_POST_SUCCESS,
    GET_POST_FAILURE,
    LOAD_POST,
    PUBLISH_POST_SUCCESS,
    PUBLISH_POST_FAILURE,
    LIKE_POST_SUCCESS,
    LIKE_POST_FAILURE,
    DISLIKE_POST_SUCCESS,
    DISLIKE_POST_FAILURE,
    DELETE_POST_SUCCESS,
    DELETE_POST_FAILURE,
    UPDATE_POST_SUCCESS,
    UPDATE_POST_FAILURE,
    BLOCK_POST_SUCCESS,
    BLOCK_POST_FAILURE,
    UNBLOCK_POST_SUCCESS,
    UNBLOCK_POST_FAILURE

} = types.feedTypes;
const initialState = {
    AllPosts : [],
    isLoadingPost: false,
    errorPost : null,
    errorLike : null
};


function feedReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_POST_SUCCESS:

      return {
        ...state,
        AllPosts: payload,
        isLoadingPost: false,
        errorPost : null,
      };
    case GET_POST_FAILURE:
      return {
        ...state,
        isLoadingPost: false,
        errorPost: payload,
      };
    case LOAD_POST:
      return {
        ...state,
        isLoadingPost: true,
      };
      case PUBLISH_POST_SUCCESS:
        return {
          ...state,
        //   isLoadingPost: false,
        };
      case PUBLISH_POST_FAILURE:
        return {
        ...state,
        // isLoadingPost: false,
        errorPost: payload,
        };
    case LIKE_POST_SUCCESS:
       return {
        ...state,
       }
    case LIKE_POST_FAILURE:
        return {
         ...state,
         errorPost: payload,
      
        }
    case DISLIKE_POST_SUCCESS:
               return {
                ...state,
               }
    case DISLIKE_POST_FAILURE:
                return {
                 ...state,
                 errorPost: payload,
                }
 case DELETE_POST_SUCCESS:
               return {
                ...state,
               }
    case UPDATE_POST_SUCCESS:
                return {
                 ...state,
                 errorPost: payload,
                }
                case DELETE_POST_FAILURE:
               return {
                ...state,
                errorPost: payload,
               }
    case UPDATE_POST_FAILURE:
                return {
                 ...state,
                 errorPost: payload,
                }
        case BLOCK_POST_SUCCESS:
          return {
            ...state,
          }
        case BLOCK_POST_FAILURE:
          return {
            ...state,
            errorPost: payload,
          }
        case UNBLOCK_POST_SUCCESS:
          return {
            ...state,
          }
        case UNBLOCK_POST_FAILURE:
          return {
            ...state,
            errorPost: payload,
          }
        default:
      return state;
  }
}

export default feedReducer;
