import React, { useState, useEffect } from "react";
import "../../../adminPanel.css";
import {
  Row,
  Col,
  Input,
  Button,
  Table,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  //   Form,
  Spinner,
} from "reactstrap";
import { TablePagination } from "@material-ui/core";
import { BsFillCircleFill, BsPencil } from "react-icons/bs";
import { processTableHeight, makeDelay } from "../../../../../assets/js/CoreJs";
import { Switch } from "antd";

import { useDispatch, useSelector } from "react-redux";
import {
  getMonitoringType,
  createMonitoringType,
  RetreiveOneMonitoringType,
  updateMonitoringType,
  clearMonitoringTypes
} from "../../../../../actions/monitoringTypeAction";
//import { MnitoringType } from "../../../adminPanel-dummy-data";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./addContractTypeValidation";

const ContractTypeAP = () => {
  const [page, setPage] = React.useState(0);
  //const [searchTerm, setSearchTerm] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  
  const toggleAdd = () => {
    setAddModal(!addModal);
    // setStatus(true);
    reset();
    setValue("isActive", true);
  };

  const toggleResetEdit = () => {
    setEditModal(!editModal);
    reset();
  }
  //const toggleEdit = () => setEditModal(!editModal);
  //const [status, setStatus] = React.useState(MnitoringType.isActive);
  const totalDocs = useSelector(
    (state) => state.monitoringType.monitoringTypes.totalDocs
  );
  const [refreshData, setrefreshData] = useState(false);
  const dispatch = useDispatch();
  const monitoringTypes = useSelector(
    (state) => state.monitoringType.monitoringTypes.docs
  );
  // console.log("monitoringTypes ====>",monitoringTypes)

  const isloading = useSelector((state) => state.monitoringType.isLoading);
  const error = useSelector((state) => state.monitoringType.listError);
  const ErrorAction = useSelector((state) => state.monitoringType.error);
  const monitoringType = useSelector(
    (state) => state.monitoringType.monitoringType
  );
  const [searchValue, setSearchValue] = useState("");

  //console.log("monitoringType ====>",monitoringType)
  // console.log("totalDocs ====>",totalDocs)

  const applyTabHeightAdminPanelChildList = () => {
    processTableHeight(
      [
        "TitlePage",
        "adminPanelTabsNav",
        "contractTypeAPListHeader",
        "contractTypeAPPaginate",
      ],
      "contractTypeAPListTCONT",
      15
    );
  };

  useEffect(() => {
    applyTabHeightAdminPanelChildList();
    window.addEventListener("resize", applyTabHeightAdminPanelChildList);

    //return fn must be last in useEffect
    return () => {
      window.removeEventListener("resize", applyTabHeightAdminPanelChildList);
    };
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setrefreshData(!refreshData);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setrefreshData(!refreshData);
  };

  const {
    control,
    handleSubmit,
    register,
    setValue,
    //  watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    //  defaultValues: {
    //   isActive: true,
    // }
  });

  useEffect(() => {
    dispatch(getMonitoringType(page, rowsPerPage, null, searchValue));
  }, [dispatch, page, rowsPerPage, searchValue, refreshData]);

  //Monitoring Type Libelle
  // const [libelle, setLibelle] = useState("");
  // const handelLibelle = (e) => {
  //   setLibelle(e.target.value);
  //   //console.log(libelle);
  // };

  //Monitoring Type Status
  // const [status, setStatus] = useState(true);
  // const handelStatus = (e) => {
  //   setStatus(e);
  //   //console.log(e);
  // };

  //Monitoring Type Status for update
  // const [statusUpd, setStatusUpd] = useState();
  // const handelStatusUpd = (e) => {
  //   setStatusUpd(e);
  //   //console.log(statusUpd);
  // };

  //Add New Monitoring Type
  // function handleCreateMonitoringType() {
  //   const newMonitoringType = {
  //     libelle: libelle,
  //     isActive: status,
  //   };
  //   dispatch(createMonitoringType(newMonitoringType));
  //   setLibelle(null);
  //   setStatus(false);

  //   setrefreshData(!refreshData);
  //   if (!ErrorAction) {
  //     setAddModal(!addModal);
  //   }
  // }

  //****Add New Contract Type*****///

  const handelAddFormSubmit = (contractTypeData) => {
    // contractTypeData.isActive=status

    dispatch(createMonitoringType(contractTypeData));

    setrefreshData(!refreshData);
    setAddModal(!addModal);
    reset();
    if (!ErrorAction) {
      setAddModal(!addModal);
    }
  };

  const toggleEdit = async (e, id) => {
    e.preventDefault();
    if (id) {
      const res = await dispatch(RetreiveOneMonitoringType(id));
      if (res) {
        // setLibelle(res.libelle);
        // setStatusUpd(res.isActive);
        setValue("libelle", res.libelle);
        setValue("isActive", res.isActive);
      }
    }
    setEditModal(!editModal);
  };

  // Edit Monitoring Type
  // async function handleEdit() {
  //   setLibelle(libelle);
  //   setStatusUpd(statusUpd);

  //   if (libelle) {
  //     const updatedData = {
  //       libelle: libelle,
  //       isActive: statusUpd,
  //     };
  //     await dispatch(updateMonitoringType(monitoringType._id, updatedData));
  //     setrefreshData(!refreshData);
  //   }
  //   // else {
  //   //   const updatedData = {
  //   //     libelle: libelle,
  //   //     isActive: statusUpd,
  //   //   };
  //   //   dispatch(updateMonitoringType(monitoringType._id, updatedData));
  //   // }

  //   if (!error) {
  //     // setrefreshData(!refreshData);
  //     setEditModal(!editModal);
  //   } else {
  //     //console.error(error);
  //   }
  // }

                           //****Update Contract Type*****///
  async function handelUpdateFormSubmit(updatedData) {
    // hostData.isActive=true
    // hostData.system=selectedSystemID
    //console.log("hostData====>",hostData);
    await dispatch(updateMonitoringType(monitoringType._id, updatedData));
    // setHost(null);
    setrefreshData(!refreshData);
    setEditModal(!editModal);
    reset();
    if (!error) {
      setrefreshData(!refreshData);
      setEditModal(!editModal);
    } else {
      //console.error(error);
    }
  }

  const handleSearchQuerry = (e) => {
    makeDelay(() => {
      if (e.target.value.length >= 2) {
        setSearchValue(e.target.value);
        setPage(0);
      } else {
        setSearchValue("");
      }
    }, 750);
  };

  useEffect(() => {
    return () => clearTimeout(window.timerDelay);
  }, []);


 //Clear Data
 useEffect(() => {
  return () => {

    dispatch(clearMonitoringTypes());
   
  };
  //eslint-disable-next-line react-hooks/exhaustive-deps
},[])

  return (
    <>
      <section className="avxWrpContentPage contractTypeAPPage">
        <Row className="contractTypeAPListHeader">
          <Col xs="2">
            <Button color="primary" onClick={toggleAdd}>
              Add Contract Type
            </Button>
          </Col>
          <Col xs={{ size: 4, offset: 6 }}>
            <Input
              type="search"
              className="form-control"
              placeholder="Search By Contract Type "
              onChange={handleSearchQuerry}
             
            />
          </Col>
        </Row>

        <div className="adminPanelChildListContent">
          <div
            id="contractTypeAPListTCONT"
            className="avxTableContainer scrollTb"
          >
            <Table
              className="adminPanelChildListTable avxBsTable oneActs"
              striped
            >
              <thead>
                <tr>
                  <th>Contract Type</th>

                  <th>
                    <span>
                      <BsPencil />
                    </span>
                  </th>
                </tr>
              </thead>

              {/* <tbody>
                {MnitoringType.filter((val) =>
                  val.libelle.toLowerCase().includes(searchTerm.toLowerCase())
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <tr
                      key={index}
                      className={
                        "contractTypeRow " +
                        (row.isActive ? "activeC" : "disabledC")
                      }
                    >
                      <td>
                        <span className="stateFlag">
                          <BsFillCircleFill />
                        </span>
                        {row.libelle}
                      </td>

                      <td>
                        <span className="tbBtn" onClick={toggleEdit}>
                          <BsPencil></BsPencil>
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody> */}

              {isloading === false ? (
                <tbody>
                  {monitoringTypes?.length > 0 && error === null ? (
                    <>
                      {monitoringTypes
                        // .filter((val) => {
                        //   return val?.libelle
                        //     .toLowerCase()
                        //     .includes(searchTerm.toLowerCase());
                        // })
                        .map((m) => (
                          <tr
                            key={m._id}
                            className={
                              "contractTypeRow " +
                              (m.isActive ? "activeC" : "disabledC")
                            }
                          >
                            <td>
                              <span className="stateFlag">
                                <BsFillCircleFill />
                              </span>
                              {m.libelle}
                            </td>

                            <td>
                              <span
                                className="tbBtn"
                                onClick={(e) => toggleEdit(e, m._id)}
                              >
                                <BsPencil></BsPencil>
                              </span>
                            </td>
                          </tr>
                        ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="2">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                          }}
                        >
                          <span> No Monitoring Type Exist </span>
                          {/* <span>{error} </span> */}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="2">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: 100,
                        }}
                      >
                        <Spinner color="dark" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </Table>
          </div>

          <TablePagination
            className="contractTypeAPPaginate"
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            component="div"
            count={totalDocs ? totalDocs : 0}
            // rowsPerPage={rowsPerPage ? rowsPerPage : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>

        <Modal
          isOpen={addModal}
          toggle={toggleAdd}
          className="addAdminPanelChildModal"
          centered
          fade={false}
          backdrop="static"
          keyboard={true}
        >
          <Form
            onSubmit={handleSubmit(handelAddFormSubmit)}
            className="formAdminPanelChildModal"
          >
            <ModalHeader toggle={toggleAdd}>Add new Contract Type</ModalHeader>
            <ModalBody>
              {/* <FormGroup>
              <Label>Contract Type</Label>
              <Input type="text" onChange={handelLibelle} />
            </FormGroup> */}

              <FormGroup>
                <Label>
                  Contract Type
                  <span className="requiredField"> *</span>
                </Label>
                <input
                  type="text"
                  name="contractTypeName"
                  id="contractTypeName"
                  className={
                    "form-control" + (errors.libelle ? " is-invalid" : "")
                  }
                  {...register("libelle")}
                />
                <small className="invalid-feedback">
                  {errors.libelle && <>{errors.libelle.message}</>}
                </small>
              </FormGroup>

              <FormGroup>
                <Label className="statusLabel">Active</Label>

                {/* <h1>{watch("isActive")+""}</h1> */}
                <Controller
                  control={control}
                  name="isActive"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Switch
                      onBlur={onBlur} // notify when input is touched
                      onChange={onChange} // send value to hook form
                      checked={value}
                      defaultChecked
                    />
                  )}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                type="submit"
                // onClick={() => handleCreateMonitoringType()}
              >
                Add Contract Type
              </Button>{" "}
              <Button color="secondary" onClick={toggleAdd}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>

        <Modal
          isOpen={editModal}
          toggle={toggleEdit}
          className="editAdminPanelChildModal"
          centered
          fade={false}
          backdrop="static" 
          keyboard={true}
        >
          <Form
            onSubmit={handleSubmit(handelUpdateFormSubmit)}
            className="formAdminPanelChildModal"
          >
            <ModalHeader toggle={toggleEdit}>Update Contract Type</ModalHeader>
            <ModalBody>
              {/* <FormGroup>
              <Label>Contract Type</Label>
              <Input
                type="text"
                defaultValue={libelle}
                onChange={handelLibelle}
              />
            </FormGroup> */}

              <FormGroup>
                <Label>
                  Contract Type
                  <span className="requiredField"> *</span>
                </Label>
                <input
                  type="text"
                  name="contractTypeName"
                  id="contractTypeName"
                  className={
                    "form-control" + (errors.libelle ? " is-invalid" : "")
                  }
                  {...register("libelle")}
                />
                <small className="invalid-feedback">
                  {errors.libelle && <>{errors.libelle.message}</>}
                </small>
              </FormGroup>

              {/* <FormGroup>
              <Label className="statusLabel">Active</Label>
              <Switch checked={statusUpd} onChange={handelStatusUpd} />
            </FormGroup> */}

              <FormGroup>
                <Label className="statusLabel">Active</Label>

                {/* <h1>{watch("isActive")+""}</h1> */}
                <Controller
                  control={control}
                  name="isActive"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Switch
                      onBlur={onBlur} // notify when input is touched
                      onChange={onChange} // send value to hook form
                      checked={value}
                      defaultChecked
                    />
                  )}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                type="submit"
                // onClick={() => handleEdit()}
              >
                Save Changes
              </Button>{" "}
              <Button color="secondary" onClick={toggleResetEdit}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </section>
    </>
  );
};

export default ContractTypeAP;
