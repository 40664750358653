import React, { useState, useEffect } from "react";
import "../../../adminPanel.css";
import './monitoringActResultFormAP.css'
import {
  Row,
  Col,
  Input,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Form,
  Spinner,
} from "reactstrap";
import MySelect from "../../../../../shared/select/Select";
import { TablePagination } from "@material-ui/core";
import { BsPencil, BsTrash } from "react-icons/bs";
import { makeDelay, processTableHeight } from "../../../../../assets/js/CoreJs";
import { useDispatch, useSelector } from "react-redux";
import { retrieveAllMonitoringActivity } from "../../../../../actions/monitoringActivityAction";
import {
  retrieveAllResultForms,
  createResultForm,
  RetreiveOneResultForm,
  updateResultForm,
  deleteResultForm,
  retrieveMonitoringActWithoutResultForm,
  clearResultForm
} from "../../../../../actions/resultFormAction";

//import { ResultForm } from "../../../adminPanel-dummy-data";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./monitoringActResultFormValidationForm";

import ResultFormPreview from "./ResultFormPreview";


function MonitoringActResultFormAP() {
  const [page, setPage] = React.useState(0);
  // const [searchTerm, setSearchTerm] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  //const toggleAdd = () => setAddModal(!addModal);
  //const toggleEdit = () => setEditModal(!editModal);

  const [refreshData, setrefreshData] = useState(false);
  // const monitoringActivities = useSelector(
  //   (state) => state.monitoringActivityTypes.monitoringActs.docs
  // );

  const monitoringActivities = useSelector(
    (state) => state.resultFormReducer.monitoringActs
  );
  // console.log("Liste all monitoringActivities ====>", monitoringActivities);
  const dispatch = useDispatch();
  const resultForms = useSelector(
    (state) => state.resultFormReducer.resultForms.docs
  );
  //console.log("Liste all resultForms ====>", resultForms);
  const isloading = useSelector((state) => state.resultFormReducer.isLoading);
  const error = useSelector((state) => state.resultFormReducer.listError);
  const ErrorAction = useSelector((state) => state.resultFormReducer.error);
  const totalDocs = useSelector(
    (state) => state.resultFormReducer.resultForms.totalDocs
  );
  //console.log("totalDocs ====>", totalDocs);
  const selectedresultForm = useSelector(
    (state) => state.resultFormReducer.resultForm
  );
  //console.log("selectedresultForm ====>", selectedresultForm);

  const [optionsmonitoringActivities, setoptionsmonitoringActivities] =
    useState([]);
  const[searchValue, setSearchValue] = useState("");
  const applyTabHeightAdminPanelChildList = () => {
    processTableHeight(
      [
        "TitlePage",
        "adminPanelTabsNav",
        "resultFormAPListHeader",
        "resultFormAPPaginate",
      ],
      "resultFormAPListTCONT",
      15
    );
  };

  useEffect(() => {
    applyTabHeightAdminPanelChildList();
    window.addEventListener("resize", applyTabHeightAdminPanelChildList);

    //return fn must be last in useEffect
    return () => {
      window.removeEventListener("resize", applyTabHeightAdminPanelChildList);
    };
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setrefreshData(!refreshData);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setrefreshData(!refreshData);
  };

  const {
    control,
    handleSubmit,
    register,
    setValue,
    //  watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const selectInvalidFeedback = {
    width: "100%",
    marginTop: ".25rem",
    fontSize: "80%",
    color: "#dc3545",
  };

  useEffect(() => {
    dispatch(retrieveAllMonitoringActivity(page, rowsPerPage, false, true));
  }, [dispatch, page, rowsPerPage, refreshData]);

  useEffect(() => {
    dispatch(retrieveMonitoringActWithoutResultForm());
  }, [dispatch, refreshData]);

  useEffect(() => {
    setoptionsmonitoringActivities(
      monitoringActivities?.map((act) => ({
        value: act._id,
        label: act.activity,
      }))
    );
  }, [monitoringActivities]);

  useEffect(() => {
    dispatch(retrieveAllResultForms(page, rowsPerPage, true, null, searchValue));
  }, [dispatch, page, rowsPerPage, searchValue, refreshData]);

  const confirmDelete = (id) => {
    if (window.confirm("Are you sure you want to delete the Result Form ?")) {
      dispatch(deleteResultForm(id));

      setrefreshData(!refreshData);
    }
  };

  const toggleAdd = async () => {
    setAddModal(!addModal);
    // setStatus(true);
    reset();
  };

  const toggleResetEdit = () => {
    setEditModal(!editModal);
    reset();
  };

  const handleSubmitCreateResultForm = async ({
    monitoringActivity,
    formLimits,
    formSchema,
    formUISchema,
  }) => {
    // console.log(JSON.parse(JSON.stringify(formLimits)));
    // console.log("formLimits", formLimits);
    // console.log("formLimits obj", JSON.parse(JSON.stringify(formLimits)));

    const newData = {
      monitoringActivity: monitoringActivity.value,
      formLimits: JSON.parse(formLimits),
      formSchema: JSON.parse(formSchema),
      formUISchema: JSON.parse(formUISchema),
      monitActTitle: monitoringActivity.label,
      // isActive: true,
    };

    await dispatch(createResultForm(newData));
    setrefreshData(!refreshData);
    reset();
    setAddModal(!addModal);

    if (!ErrorAction) {
      setAddModal(!addModal);
    }
  };

  const toggleEdit = async (e, id) => {
    e.preventDefault();
    if (id) {
      const res = await dispatch(RetreiveOneResultForm(id));
      if (res) {
        setValue("monitoringActivity", {
          value: res?.monitoringActivity?._id,
          label: res?.monitoringActivity?.activity,
        });

        setValue("formLimits", JSON.stringify(res.formLimits));
        setValue("formSchema", JSON.stringify(res.formSchema));
        setValue("formUISchema", JSON.stringify(res.formUISchema));
      }
    }
    setEditModal(!editModal);
  };

  const handleUpdate = async ({
    monitoringActivity,
    formLimits,
    formSchema,
    formUISchema,
  }) => {
    const updatedData = {
      monitoringActivity: monitoringActivity.value,
      formLimits: JSON.parse(formLimits),
      formSchema: JSON.parse(formSchema),
      formUISchema: JSON.parse(formUISchema),
      monitActTitle: monitoringActivity.label,
    };

    await dispatch(updateResultForm(selectedresultForm._id, updatedData));

    setrefreshData(!refreshData);
    setEditModal(!editModal);
    reset();
    if (!error) {
      setrefreshData(!refreshData);
      setEditModal(!editModal);
    } else {
      //console.error(error);
    }
  };

  if (errors.monitoringActivity) {
    document.querySelector(".customerSelectType > div:nth-child(2)") &&
      document
        .querySelector(".customerSelectType > div:nth-child(2)")
        .classList.add("isInvalidSelect");
  }

  
  const [lpModal, setLpModal] = useState(false);
  const toggleLpModal = () => setLpModal(!lpModal);

  const [formLimits, setFormLimits] = useState(null);
  const [formSchema, setFormSchema] = useState(null);
  const [formUISchema, setFormUISchema] = useState(null);

  const [wrongJson, setWrongJson] = useState(false);


  const handleLivePreview = async ({formLimits, formSchema, formUISchema}) => {
    try{
      await setFormLimits(JSON.parse(formLimits));
      await setFormSchema(JSON.parse(formSchema));
      await setFormUISchema(JSON.parse(formUISchema));

      toggleLpModal();
      setWrongJson(false);
    }catch(err) {
      setWrongJson(true);
      console.error(err.message);
    }
  }

  const handleSearchQuerry = (e) => {
    makeDelay(() => {
      if (e.target.value.length >= 2) {
        setSearchValue(e.target.value);
        setPage(0);
      } else {
        setSearchValue("");
      }
    }, 750);
  };

  useEffect(() => {
    return () => clearTimeout(window.timerDelay);
  }, []);

   //Clear Data
   useEffect(() => {
    return () => {
      dispatch(clearResultForm());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
    <>
      <section className="avxWrpContentPage resultFormAPPage">
        <Row className="resultFormAPListHeader">
          <Col xs="2">
            <Button color="primary" onClick={toggleAdd}>
              Add Result Form
            </Button>
          </Col>
          <Col xs={{ size: 4, offset: 6 }}>
            <Input
              type="search"
              className="form-control"
              placeholder="Search By Monitoring Activity"
              onChange={handleSearchQuerry}
            />
          </Col>
        </Row>

        <div className="adminPanelChildListContent">
          <div
            id="resultFormAPListTCONT"
            className="avxTableContainer scrollTb"
          >
            <Table
              className="adminPanelChildListTable avxBsTable twoActs"
              striped
            >
              <thead>
                <tr>
                  <th>Result Form</th>

                  <th>
                    <span>
                      <BsPencil />
                    </span>
                  </th>
                  <th>
                    <span>
                      <BsTrash></BsTrash>
                    </span>
                  </th>
                </tr>
              </thead>

              {isloading === false ? (
                <tbody>
                  {resultForms && error === null ? (
                    <>
                      {resultForms
                        // .filter((val) => {
                        //   return val?.libelle
                        //     .toLowerCase()
                        //     .includes(searchTerm.toLowerCase());
                        // })
                        .map((rf) => (
                          <tr key={rf._id}>
                            <td>{rf.monitActTitle}</td>

                            <td>
                              <span
                                className="tbBtn"
                                onClick={(e) => toggleEdit(e, rf._id)}
                              >
                                <BsPencil></BsPencil>
                              </span>
                            </td>

                            <td>
                              <span
                                className="tbBtn"
                                onClick={() => confirmDelete(rf._id)}
                              >
                                <BsTrash></BsTrash>
                              </span>
                            </td>
                          </tr>
                        ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="3">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                          }}
                        >
                          <span> No Result Form Exist </span>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="3">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: 100,
                        }}
                      >
                        <Spinner color="dark" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </Table>
          </div>

          <TablePagination
            className="resultFormAPPaginate"
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            component="div"
            count={totalDocs ? totalDocs : 0}
            // count={ResultForm.length ? ResultForm.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>

        <Modal
          isOpen={addModal}
          toggle={toggleAdd}
          className="addAdminPanelChildModal addResFormModal"
          centered
          fade={false}
          backdrop="static"
          keyboard={true}
          size="xl"
        >
          <Form
            onSubmit={handleSubmit(handleSubmitCreateResultForm)}
            className="formAdminPanelChildModal"
          >
            <ModalHeader toggle={toggleAdd}>Add new Result Form</ModalHeader>
            <ModalBody className="modalBodyStyle">
              <FormGroup>
                <Label>
                  Monitoring Activity
                  <span className="requiredField"> *</span>
                </Label>
                <Controller
                  name="monitoringActivity"
                  control={control}
                  render={({ field: { ref, ...rest }, fieldState }) => (
                    <MySelect
                      className="customerSelectType"
                      placeholder="Select Monitoring Activity"
                      options={optionsmonitoringActivities}
                      {...rest}
                      {...fieldState}
                    />
                  )}
                />
                <small style={selectInvalidFeedback}>
                  {errors.monitoringActivity && (
                    <>{errors.monitoringActivity.value.message}</>
                  )}
                </small>
              </FormGroup>

              <FormGroup>
                <Label>
                  Form Limits
                  <span className="requiredField"> *</span>
                </Label>
                <input
                  type="text"
                  name="formLimits"
                  id="formLimits"
                  className={
                    "form-control" + (errors.formLimits ? " is-invalid" : "")
                  }
                  {...register("formLimits")}
                />
                <small className="invalid-feedback">
                  {errors.formLimits && <>{errors.formLimits.message}</>}
                </small>
              </FormGroup>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>
                      Form Schema
                      <span className="requiredField"> *</span>
                    </Label>
                    <textarea
                      type="textarea"
                      name="formSchema"
                      id="formSchema"
                      className={
                        "form-control" +
                        (errors.formSchema ? " is-invalid" : "")
                      }
                      {...register("formSchema")}
                    />
                    <small className="invalid-feedback">
                      {errors.formSchema && <>{errors.formSchema.message}</>}
                    </small>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>
                      Form UI Schema
                      <span className="requiredField"> *</span>
                    </Label>
                    <textarea
                      type="textarea"
                      name="formUISchema"
                      id="formUISchema"
                      className={
                        "form-control" +
                        (errors.formUISchema ? " is-invalid" : "")
                      }
                      {...register("formUISchema")}
                    />
                    <small className="invalid-feedback">
                      {errors.formUISchema && (
                        <>{errors.formUISchema.message}</>
                      )}
                    </small>
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <div>
                <Button color="primary" outline onClick={handleSubmit(handleLivePreview)}>Live preview</Button>
                {wrongJson && <span className="wrongFormatJSON">Wrong format for one or many objects</span>}
              </div>
              
              <div>
                <Button color="primary" type="submit">Add Result Form</Button>{" "}
                <Button color="secondary" onClick={toggleAdd}>Cancel</Button>
              </div>
            </ModalFooter>
          </Form>
        </Modal>

        <Modal
          isOpen={editModal}
          toggle={toggleEdit}
          className="editAdminPanelChildModal updateResFormModal"
          centered
          fade={false}
          backdrop="static"
          keyboard={true}
          size="xl"
        >
          <Form onSubmit={handleSubmit(handleUpdate)}>
            <ModalHeader toggle={toggleEdit}>Update Result Form</ModalHeader>
            <ModalBody className="modalBodyStyle">
              <FormGroup>
                <Label>
                  Monitoring Activity
                  <span className="requiredField"> *</span>
                </Label>
                <Controller
                  name="monitoringActivity"
                  control={control}
                  render={({ field: { ref, ...rest }, fieldState }) => (
                    <MySelect
                      className="customerSelectType"
                      placeholder="Select Monitoring Activity"
                      options={optionsmonitoringActivities}
                      {...rest}
                      {...fieldState}
                      isDisabled
                    />
                  )}
                />
                <small style={selectInvalidFeedback}>
                  {errors.monitoringActivity && (
                    <>{errors.monitoringActivity.value.message}</>
                  )}
                </small>
              </FormGroup>

              <FormGroup>
                <Label>
                  Form Limits
                  <span className="requiredField"> *</span>
                </Label>
                <input
                  type="text"
                  name="formLimits"
                  id="formLimits"
                  className={
                    "form-control" + (errors.formLimits ? " is-invalid" : "")
                  }
                  {...register("formLimits")}
                />
                <small className="invalid-feedback">
                  {errors.formLimits && <>{errors.formLimits.message}</>}
                </small>
              </FormGroup>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>
                      Form Schema
                      <span className="requiredField"> *</span>
                    </Label>
                    <textarea
                      type="textarea"
                      name="formSchema"
                      id="formSchema"
                      className={
                        "form-control" +
                        (errors.formSchema ? " is-invalid" : "")
                      }
                      {...register("formSchema")}
                    />
                    <small className="invalid-feedback">
                      {errors.formSchema && <>{errors.formSchema.message}</>}
                    </small>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>
                      Form UI Schema
                      <span className="requiredField"> *</span>
                    </Label>
                    <textarea
                      type="textarea"
                      name="formUISchema"
                      id="formUISchema"
                      className={
                        "form-control" +
                        (errors.formUISchema ? " is-invalid" : "")
                      }
                      {...register("formUISchema")}
                    />
                    <small className="invalid-feedback">
                      {errors.formUISchema && (
                        <>{errors.formUISchema.message}</>
                      )}
                    </small>
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <div>
                <Button color="primary" outline onClick={handleSubmit(handleLivePreview)}>Live preview</Button>
                {wrongJson && <span className="wrongFormatJSON">Wrong format for one or many objects</span>}
              </div>
              <div>
                <Button color="primary" type="submit">Save Changes</Button>{" "}
                <Button color="secondary" onClick={toggleResetEdit}>Cancel</Button>
              </div>
            </ModalFooter>
          </Form>
        </Modal>

        {formLimits && formSchema && formUISchema && (
          <ResultFormPreview lpModal={lpModal} toggleLpModal={toggleLpModal} 
            formLimits={formLimits} formSchema={formSchema} formUISchema={formUISchema}
            setFormLimits={setFormLimits} setFormSchema={setFormSchema} setFormUISchema={setFormUISchema}

          />
        )}


      </section>
    </>
  );
}

export default MonitoringActResultFormAP;
