import types from "./types";
import systemService from "../services/systemService";
import { message } from 'antd';

const {
  CREATE_SYSTEM_SUCCESS,
  CREATE_SYSTEM_FAILURE,
  LOAD_SYSTEM,
  RETRIEVE_ONE_SYSTEM_SUCCESS,
  RETRIEVE_ONE_SYSTEM_FAILURE,
  GET_CONTACTS_ASSIGNED_TO_SYSTEM_SUCCESS,
  GET_CONTACTS_ASSIGNED_TO_SYSTEM_FAILURE,
  RETRIEVE_SYSTEM_CATEGORIES_SUCCESS,
  RETRIEVE_SYSTEM_CATEGORIES_FAILURE,
  RETRIEVE_SYSTEM_TYPES_SUCCESS,
  RETRIEVE_SYSTEM_TYPES_FAILURE,
  UPDATE_SYSTEM_SUCCESS,
  UPDATE_SYSTEM_FAILURE,
  DELETE_SYSTEM_SUCCESS,
  DELETE_SYSTEM_FAILURE,
  // GET_SYSTEM_FORMAPS_SUCCESS,
  // GET_SYSTEM_FORMAPS_FAILURE
  GET_SYSTEMS_INFO_SUCCESS,
  GET_SYSTEMS_INFO_FAILURE,
  LOAD_SYSTEM_INFO,

  LOAD_SYSTEMS_BY_CUSTOMER_ID,
  RETRIEVE_SYSTEMS_BY_CUSTOMER_ID_SUCCESS,
  RETRIEVE_SYSTEMS_BY_CUSTOMER_ID_FAILURE,

  LOAD_ALL_THE_SYSTEMS,
  GET_ALL_THE_SYSTEMS_SUCCESS,
  GET_ALL_THE_SYSTEMS_FAILURE,
  CLEAR_SYSTEMS_INFO
} = types.systemTypes;

export const createSystem = (newSystem) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_SYSTEM,
    });
    const res = await systemService.create(newSystem);
    message.success("Create Successfully",3);
    dispatch({
      type: CREATE_SYSTEM_SUCCESS,
      payload: res.data.data,
    });
    return res;
  } catch (err) {
    message.error('An Error occurred while creating',3);  
    dispatch({
      type: CREATE_SYSTEM_FAILURE,
      payload: err,
    });
  }
};


// export const RetrieveSystemForMap = () => async (dispatch) => {
//   try {
//     const res = await systemService.getSystems();
//     dispatch({
//       type:  GET_SYSTEM_FORMAPS_SUCCESS,
//       payload: res.data.data,
//     });
//     return res.data.data;
//   } catch (err) {
//     dispatch({
//       type: GET_SYSTEM_FORMAPS_FAILURE,
//       payload: err.response.data.message,
//     });
//   }
// };

export const RetreiveOneSystem = (id) => async (dispatch) => {
  try {
    const res = await systemService.getOneSystem(id);
    dispatch({
      type: RETRIEVE_ONE_SYSTEM_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {
    dispatch({
      type: RETRIEVE_ONE_SYSTEM_FAILURE,
      payload: err.response.data.message,
    });
  }
};
export const getAssignedSystemContact = (systemId, searchValue) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_SYSTEM,
    });
    const res = await systemService.getCustomerContactAssigned(systemId, searchValue);
    dispatch({
      type: GET_CONTACTS_ASSIGNED_TO_SYSTEM_SUCCESS,
      payload: res.data.data,
    });
    return res;
  } catch (err) {
    dispatch({
      type: GET_CONTACTS_ASSIGNED_TO_SYSTEM_FAILURE,
      payload: err.response.data.message,
    });
  }
};

export const RetreiveSystemCategories = (customerId) => async (dispatch) => {
  try {
    const res = await systemService.getCategoriesByCustomerId(customerId);
    dispatch({
      type: RETRIEVE_SYSTEM_CATEGORIES_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {
    dispatch({
      type: RETRIEVE_SYSTEM_CATEGORIES_FAILURE,
      payload: err.response.data.message,
    });
  }
};
export const RetreiveSystemTypes =
  (customerId, categoryId) => async (dispatch) => {
    try {
      const res = await systemService.getTypesByCustomerIdByCategoryId(
        customerId,
        categoryId
      );
      dispatch({
        type: RETRIEVE_SYSTEM_TYPES_SUCCESS,
        payload: res.data.data,
      });
      return res.data.data;
    } catch (err) {
      dispatch({
        type: RETRIEVE_SYSTEM_TYPES_FAILURE,
        payload: err.response.data.message,
      });
    }
  };

export const UpdateSystem = (systemId, updateSystem) => async (dispatch) => {
  try {
    const res = await systemService.updateSystem(systemId, updateSystem);
    message.success("Updated Successfully",3);
    dispatch({
      type: UPDATE_SYSTEM_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {
    message.error('An Error occurred while updating',3);    
    dispatch({
      type: UPDATE_SYSTEM_FAILURE,
      payload: err.response,
    });
  }
};

export const deleteSystem = (systemId) => async (dispatch) => {
  try {
    const res = await systemService.deleteSystem(systemId);
message.success("Deleted Successfully",3);
    dispatch({
      type: DELETE_SYSTEM_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {
message.error('An Error occurred while deleting',3);    
    dispatch({
      type: DELETE_SYSTEM_FAILURE,
      payload: err.response,
    });
  }
};

export const RetreiveSystemsInfo = (customerId) => async (dispatch) => {
 
  try {
    dispatch({
      type: LOAD_SYSTEM_INFO,
    });
    const res = await systemService.getSystemsInfo(customerId);
    dispatch({
      type: GET_SYSTEMS_INFO_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {
    dispatch({
      type: GET_SYSTEMS_INFO_FAILURE,
      payload: err,
    });
  }
};

export const retreiveSystemsByCustomerId =
  (customerId) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_SYSTEMS_BY_CUSTOMER_ID,
      });
      const res = await systemService.getSystemsByCustomerID(customerId);
      dispatch({
        type: RETRIEVE_SYSTEMS_BY_CUSTOMER_ID_SUCCESS,
        payload: res.data.data.docs,
      });
    } catch (err) {
      dispatch({
        type: RETRIEVE_SYSTEMS_BY_CUSTOMER_ID_FAILURE,
        payload: err,
      });
    }
  };


export const retreiveAllTheSystems = (paginate, page, size) => async dispatch => {
  try{
    dispatch({
      type : LOAD_ALL_THE_SYSTEMS
    });    
    const response = await systemService.getAllTheSystems(paginate, page, size);
    dispatch({
      type : GET_ALL_THE_SYSTEMS_SUCCESS,
      payload : response.data.data.docs
    });
  }catch(error){
    dispatch({
      type : GET_ALL_THE_SYSTEMS_FAILURE,
      payload : error
    });
  }
}

//clear Data
export const clearSystemInfo = () =>  (dispatch) => {
  dispatch({
    type: CLEAR_SYSTEMS_INFO,
  })
}
