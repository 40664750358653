import http from "../api";

const addNewComment = (PostId,newComment) => {
  return http.post(
    `/api/comment/${PostId}/addComment/`,newComment,
    {
      headers: {
        "Content-type": "application/json",
        "x-auth-accessToken": localStorage.getItem("ma-access-token"),
        "x-auth-refreshToken": localStorage.getItem("ma-refresh-token"),
      },
    }
  );
};
const getAllCommentByPostId = (PostID) => {
    return http.get(
      `/api/comment/getCommentsByFeedID/${PostID}`,{
        headers: {
          "Content-type": "application/json",
          "x-auth-accessToken": localStorage.getItem("ma-access-token"),
          "x-auth-refreshToken": localStorage.getItem("ma-refresh-token"),
        },
      }
    );
  };
const deleteComment = (PostId) => {
  return http.patch(
    `/api/comment/${PostId}/delete`
  );
};
const updateComment = (commentId,newcomment) => {
  return http.post(
    `/api/comment/${commentId}/edit/`,newcomment,
  );
};

const blockComment = (PostId) => {
  return http.patch(`/api/comment/${PostId}/block`);
};

const unblockComment = (PostId) => {
  return http.patch(`/api/comment/unblock/${PostId}`);
};

const commentService = {
    addNewComment,
    getAllCommentByPostId,
    deleteComment,
    updateComment,
    blockComment,
    unblockComment

};
export default commentService;
