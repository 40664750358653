import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './Alert.css';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from "@material-ui/lab/Alert";

const AlertSnackbar = ({ alerts }) =>
  alerts.map((alert) => (
    <Snackbar open={true} autoHideDuration={2000}  >
          <Alert severity={alert.alertType}>{alert.msg}</Alert>
          </Snackbar>
          
    
  ));

  AlertSnackbar.propTypes = {
  alerts: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
  alerts: state.alert
});

export default connect(mapStateToProps)(AlertSnackbar);
