import types from "./types";
import clientService from "../services/clientService";
import { message } from 'antd';

const {
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_FAILURE,
  LOAD_CLIENT,
  GET_ALL_CLIENTS_SUCCESS,
  GET_ALL_CLIENTS_FAILURE,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILURE,
  RETRIEVE_ONE_CLIENT_SUCCESS,
  RETRIEVE_ONE_CLIENT_FAILURE,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  CLEAR_CLIENTS,
  CLEAR_ONE_CLIENT
} = types.clientTypes;

export const createClient = (newClient) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_CLIENT,
    });
    const res = await clientService.createClient(newClient);
    message.success("Created Successfully",3);
    dispatch({
      type: CREATE_CLIENT_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    message.error('An Error occurred while creating',3);
    dispatch({
      type: CREATE_CLIENT_FAILURE,
      payload: err,
    });
  }
};

export const retrieveClients = (system_Id, searchValue) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_CLIENT,
    });
    const res = await clientService.getAllClient(system_Id, searchValue);
    dispatch({
      type: GET_ALL_CLIENTS_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {
    dispatch({
      type: GET_ALL_CLIENTS_FAILURE,
      payload: err.response.data.message,
    });
  }
};

export const deleteClient = (id, systemId) => async (dispatch) => {
  try {
    const res = await clientService.deleteClient(id);
    dispatch({
      type: LOAD_CLIENT,
    });
    message.success("Deleted Successfully",3);
    dispatch({
      type: DELETE_CLIENT_SUCCESS,
      payload: res.data.data,
    });

    const resAll = await clientService.getAllClient(systemId);
    dispatch({
      type: GET_ALL_CLIENTS_SUCCESS,
      payload: resAll.data.data,
    });
  } catch (err) {
    //console.log(err);
    message.error('An Error occurred while deleting',3);
    dispatch({
      type: DELETE_CLIENT_FAILURE,
      payload: err.response.data.message,
    });
  }
};

export const RetreiveOneClient = (id) => async (dispatch) => {
  try {
    const res = await clientService.getOneClient(id);
    dispatch({
      type: RETRIEVE_ONE_CLIENT_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {
    dispatch({
      type: RETRIEVE_ONE_CLIENT_FAILURE,
      payload: err.response.data.message,
    });
  }
};

export const updateClient = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_CLIENT,
    });
    const res = await clientService.updateClient(id, data);
    message.success("Updated Successfully",3);
    dispatch({
      type: UPDATE_CLIENT_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    message.error('An Error occurred while updating',3);
    dispatch({
      type: UPDATE_CLIENT_FAILURE,
      payload: err?.response?.data?.message,
    });
  }
};

 //clear All
 export const clearClients = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_CLIENTS,
  })
}

//clear One 
export const clearOneClient = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_ONE_CLIENT,
  })
}
