import React, { useState } from "react";
import Select from "../../../shared/select/Select";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Form,
  Button,
  Label,
  Spinner,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { retrieveAllRole } from "../../../actions/roleAction";
import {
  RetreiveOneUser,
  updateUser,
} from "../../../actions/userManagementAction";
import { BsPencil } from "react-icons/bs";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./EditUserYupValidation";

export default function EditUser({ user, page, rowsPerPage, setrefrechData, refrechData, searchValue }) {
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // const optionRole=[{ value : "Global-Support",label : "Global-Support" },{ value : "Monitoring",label : "Monitoring" }]
  // const user = useSelector((state) => state.userManagement.user);
  // console.log("User selected for update =====>",user?._id);
  const error = useSelector((state) => state.userManagement.listError);
  const [optionsRoleUser, setoptionsRoleUser] = useState([
    {
      value: "none",
      label: "none",
    },
  ]);

  const [editModal, setEditModal] = useState(false);
  const toggleEdit = async (id) => {
    reset();
    if (id) {
      const optionsRoleUser = await dispatch(retrieveAllRole(0, 1000, false));
      if (optionsRoleUser)
        setoptionsRoleUser(
          optionsRoleUser.map((value) => ({
            value: value._id,
            label: value.label,
          }))
        );

      const res = await dispatch(RetreiveOneUser(id));

      let roleList = [];
      if (res) {
        setValue("firstName", res.firstName);
        setValue("lastName", res.lastName);
        for (let i = 0; i < res.Roles.length; i++) {
          roleList.push(res.Roles[i]);
        }
        const oldRoles = roleList.map((value) => ({
          value: value._id,
          label: value.label,
        }));
        setValue("roles", oldRoles);
      }
    }
    setEditModal(!editModal);
  };

  function handleEdit({firstName,lastName,roles}) {
    const dbRoles = roles.map((value) => value.value);
    const updatedData = {
      firstName: firstName,
      lastName: lastName,
      roles: dbRoles,
    };
    dispatch(updateUser(user._id, updatedData, page, rowsPerPage,searchValue));
    if (!error) {
      setEditModal(!editModal);
      setrefrechData(!refrechData);
    } else {
      console.error(error);
    }
  }
  function toggleClose() {
    setEditModal(!editModal);
  }

  const selectInvalidFeedback = {
    width: "100%",
    marginTop: ".25rem",
    fontSize: "80%",
    color: "#dc3545",
  };

  if (errors.roles) {
    document.querySelector(".customerSelectRoles > div:nth-child(2)") &&
      document
        .querySelector(".customerSelectRoles > div:nth-child(2)")
        .classList.add("isInvalidSelect");
  }

  return (
    <>
      <span className="tbBtn" onClick={() => toggleEdit(user?._id)}>
        <BsPencil />
      </span>
      <Modal
        isOpen={editModal}
        toggle={toggleClose}
        size="md"
        backdrop="static"
        keyboard={true}
        centered
        fade={false}
      >
        <Form onSubmit={handleSubmit(handleEdit)}>
          <ModalHeader toggle={toggleClose}>Edit User</ModalHeader>
          <ModalBody>
            {watch("firstName") && watch("lastName") ? (
              <>
                <FormGroup>
                  <Label>
                    First Name
                    <span className="requiredField"> *</span>
                  </Label>
                  <input
                    type="text"
                    className={
                      "form-control" + (errors.firstName ? " is-invalid" : "")
                    }
                    {...register("firstName")}
                    disabled
                  />
                  <small className="invalid-feedback">
                    {errors.firstName && <>{errors.firstName.message}</>}
                  </small>
                </FormGroup>

                <FormGroup>
                  <Label>
                    Last Name
                    <span className="requiredField"> *</span>
                  </Label>
                  <input
                    type="text"
                    className={
                      "form-control" + (errors.lastName ? " is-invalid" : "")
                    }
                    {...register("lastName")}
                    disabled
                  />
                  <small className="invalid-feedback">
                    {errors.lastName && <>{errors.lastName.message}</>}
                  </small>
                </FormGroup>

                <FormGroup>
                  <Label>
                    Roles
                    <span className="requiredField"> *</span>
                  </Label>
                  <Controller
                    name="roles"
                    control={control}
                    render={({ field: { ref, ...rest }, fieldState }) => (
                      <Select
                        className="customerSelectRoles"
                        options={optionsRoleUser}
                        isMulti={true}
                        {...rest}
                        {...fieldState}
                      />
                    )}
                  />
                  <small style={selectInvalidFeedback}>
                    {errors.roles && <>{errors.roles.message}</>}
                  </small>
                </FormGroup>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 100,
                }}
              >
                <Spinner color="dark" />
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Save Changes
            </Button>
            <Button onClick={() => setEditModal(!editModal)}>Cancel</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}
