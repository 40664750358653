import types from "../actions/types";
const {
  LOAD_MAP,
  GET_ALL_MAPS_SUCCESS,
  GET_ALL_MAPS_FAILURE,
  CREATE_MAP_SUCCESS,
  CREATE_MAP_FAILURE,

  RETRIEVE_ONE_MAP_SUCCESS,
  RETRIEVE_ONE_MAP_FAILURE,
  UPDATE_MAP_SUCCESS,
  UPDATE_MAP_FAILURE,

  DELETE_MAP_SUCCESS,
  DELETE_MAP_FAILURE,

  CLEAR_MAPS,
  CLEAR_ONE_MAP

} = types.mapTypes;

const initialState = {
  map: null,
  maps: [],
  isLoading: false,
  error: null,
  listError: null,
};

function userReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_MAPS_SUCCESS:
      return {
        ...state,
        maps: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case GET_ALL_MAPS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: payload,
      };

    case LOAD_MAP:
      return {
        ...state,
        isLoading: true,
      };

    case CREATE_MAP_SUCCESS:
      state.maps.docs.push(payload);
      return {
        ...state,
        maps: state.maps,
        isLoading: false,
        error: null,
        listError: null,
      };

    case CREATE_MAP_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };


    case RETRIEVE_ONE_MAP_SUCCESS:
      return {
        ...state,
        map: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case RETRIEVE_ONE_MAP_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

    case UPDATE_MAP_SUCCESS:
      const updatedIndex = state.maps.docs.map((map, index) => {
        if (payload._id === map._id) {
          return index;
        } else return null;
      });
      if (updatedIndex) state.maps.docs[updatedIndex] = payload;
      return {
        ...state,
        maps: state.maps,
        isLoading: false,
        error: null,
        listError: null,
      };

    case UPDATE_MAP_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

    case DELETE_MAP_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case DELETE_MAP_FAILURE:

      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };


    case CLEAR_MAPS:
    return {
      ...state,
      maps: [],
      isLoading: false,
      error: null,
      listError: null,
    };
    
    case CLEAR_ONE_MAP:
    return {
      ...state,
      map: null,
      isLoading: false,
      error: null,
      listError: null,
    };

    default:
      return state;
  }
}

export default userReducer;
