import types from './types';
import taskDynamicFormsService from '../services/taskDynamicFormsService';

const {
  LOAD_TASK_FORM,
  GET_TASK_FORM_SUCCESS,
  GET_TASK_FORM_FAILURE,
  RESET_TASK_FORM,
} = types.taskDynamicFormsTypes;

export const getFormSchema=(taskId) => async (dispatch) => {
  try{
    dispatch({
      type : LOAD_TASK_FORM,
    });
    const res = await taskDynamicFormsService.getTaskResultFormSchema(taskId);
    dispatch({
      type: GET_TASK_FORM_SUCCESS,
      payload: res.data.data
    });
  }catch(err){
    dispatch({
      type: GET_TASK_FORM_FAILURE,
      payload: err
    });
  }
}

export const resetFormSchema = () => async (dispatch) => {
  try{
    dispatch({
      type: RESET_TASK_FORM,
    });
  }catch(err){
    console.log('Error during form schema reset:',err );
  }
}