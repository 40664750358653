import React, { useState, useEffect } from "react";
import Select from "../../../shared/select/Select";
import { Badge, FormGroup, Spinner } from "reactstrap";
import "./TaskManagement.css";
import { useSelector, useDispatch } from "react-redux";
import {
  getSystemStats,
  getSystemStatsFilter,
} from "../../../actions/taskAction";
export default function SideMenuSystemStats({ applySideMenuHeight }) {
  const dispatch = useDispatch();
  const systemStats = useSelector((state) => state.taskReducer.systemStats);

  const isloadingSystemStats = useSelector(
    (state) => state.taskReducer.isloadingSystemStats
  );
  const timeZone = useSelector((state) => state.user.timeZone);
  const userId = useSelector((state) => state.user.user?._id);
  const [cust, setcust] = useState([]);
  const [customs, setcustoms] = useState();
  const customers = useSelector((state) => state.taskReducer.AllCustomers.docs);
  const isLoadingCustomer = useSelector(
    (state) => state.taskReducer.isLoadingCustomer
  );

  const [targetSum, setTargetSum] = useState(0);

  useEffect(() => {
    dispatch(getSystemStats(timeZone, userId));
  }, [dispatch, timeZone, userId]);


  useEffect(() => {
    if(systemStats.hasOwnProperty('statistics')){
      const sum = Object.values(systemStats.statistics).map( system => system.targetTasks )
      .reduce( (acc, systemTarget)=> acc + systemTarget, 0 );
      setTargetSum(sum);
    }
  }, [systemStats]);
  

  useEffect(() => {
    if (customers)
      setcust(
        customers?.map((cus) => ({
          value: cus._id,
          label: cus.label,
        }))
      );
  }, [customers]);

  function handlecustomers(e) {
    if (e) {
      setcustoms(e);
      dispatch(getSystemStatsFilter(timeZone, userId, e.value));
    } else {
      dispatch(getSystemStats(timeZone, userId));
      setcustoms(null);
    }
  }

  useEffect(() => {
    applySideMenuHeight();
    window.addEventListener("resize", applySideMenuHeight);
    return () => {
      window.removeEventListener("resize", applySideMenuHeight);
    };
  }, [applySideMenuHeight]);

 
  return (
    <div>
      <div id="SideMenuContent" className="scrollTb SystemStats">
        <FormGroup>
          <Select
            className="customerSelect"
            options={cust}
            value={customs}
            isLoading={isLoadingCustomer}
            placeholder="Customer"
            onChange={(e) => handlecustomers(e)}
            isClearable
          />
        </FormGroup>
        <div className="CreatedBadge">
          <Badge size="small" color="danger">
            {isloadingSystemStats === false ? 'Target '+ targetSum : 'Loading'}
          </Badge> 
        </div>
        <table className="tableStatistics">
          <thead>
            <tr>
              <th>
                <p className="StatusNumber">System</p>
              </th>
              <th>
                <p className="StatusNumber">Customer</p>
              </th>
              <th>
                <p className="StatusNumber">Created</p>
              </th>
              <th>
                <p className="StatusNumber">Target</p>
              </th>
            </tr>
          </thead>
          {isloadingSystemStats === false ? (
            <tbody>
              {systemStats.statistics?.length > 0 ? (
                <>
                  {systemStats.statistics.map((sys, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <Badge
                            color={
                              sys.createdTasks === sys.targetTasks
                                ? "success"
                                : sys.createdTasks > 0
                                ? "warning"
                                : "danger"
                            }
                          >
                            {sys.systemName}
                          </Badge>
                        </td>
                        <td className="NameSpan">
                          {sys.customerName}
                        </td>
                        <td>
                          <p className="StatusNumber">{sys.createdTasks}</p>
                        </td>
                        <td>
                          <p className="StatusNumber">{sys.targetTasks}</p>
                        </td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td>No Systems</td>
                </tr>
              )}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan="6">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: 100,
                    }}
                  >
                    <Spinner color="dark" />
                  </div>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
}
