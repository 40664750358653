import React, { useEffect } from "react";
import { Table, Spinner } from "reactstrap";
import { BsTrash, BsPencil } from "react-icons/bs";
import "./CustomerSystems.css";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  RetreiveSystemCategories,
  RetreiveSystemTypesTC,
  RetreiveSystemByCustomerByCatgeroyByTypesTC,
  clearSystemsTC
} from "../../../../actions/landscapeTCAction";
import { useDispatch, useSelector } from "react-redux";

const LandscapeDefaultTC = function ({
  customer_ID,
  avxToggle,
  setSelectedSys,
  setScrollPosition,
  handleOpenEditModal,
  confirmDelete,
  typeId,
  settypeId,
  setcategoryId,
}) {
  const dispatch = useDispatch();
  const landscapeTC = useSelector((state) => state.landscapeTC);
  const isLoading = useSelector((state) => state.landscapeTC.isLoading);

 

   //Clear Data
   useEffect(() => {
    return () => {
      dispatch(clearSystemsTC());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    if (customer_ID) dispatch(RetreiveSystemTypesTC(customer_ID));
  }, [dispatch, customer_ID]);

  function handletogglecategory(e, typeId) {
    //console.log(e);
    avxToggle(e);
    //console.log(e);
    //console.log(e.target);
    //console.log(e.currentTarget);
    settypeId(typeId);
    dispatch(RetreiveSystemCategories(customer_ID, typeId));
  }
  function handletoggleSystem(e, categoryId) {
    avxToggle(e);
    //console.log(e);
    //console.log(e.target);
    //console.log(e.currentTarget);
    setcategoryId(categoryId);
    dispatch(
      RetreiveSystemByCustomerByCatgeroyByTypesTC(
        customer_ID,
        categoryId,
        typeId
      )
    );
  }
  return (
    <div className="landscapeDefaultTC">
      {isLoading === false ? (
        <>
          {landscapeTC.SystemTypes.length > 0 ? (
            <>
              {" "}
              {landscapeTC.SystemTypes.map((sType) => (
                <div
                  key={"systemType" + sType._id}
                  className="sysParentContainOne sysType"
                >
                  <h3 onClick={(e) => handletogglecategory(e, sType._id)}>
                    <span>{sType.type}</span>
                  </h3>

                  <div className="sysChildrenContainOne sysChildHide">
                    {sType.category.isLoadingCategories === false ? (
                      <>
                        {sType.category.categories.length > 0 ? (
                          <>
                            {sType.category.categories.map((tCateg) => (
                              <div
                                key={
                                  "systemTypeCategory" +
                                  sType._id +
                                  "-" +
                                  tCateg._id
                                }
                                className="sysParentContainTwo sysCategory"
                              >
                                <h5
                                  onClick={(e) =>
                                    handletoggleSystem(e, tCateg._id)
                                  }
                                >
                                  <span>{tCateg.category}</span>
                                </h5>

                                <div className="sysChildrenContainTwo sysChildHide avxTableContainer">
                                  <Table
                                    className="customerSystemsTable avxBsTable avxBsTableSmall twoActs"
                                    striped
                                    hover
                                  >
                                    <thead>
                                      <tr>
                                        <th>System</th>
                                        <th>Class</th>
                                        <th>Deployment</th>
                                        <th>FQDN</th>
                                        <th>
                                          <span>
                                            <BsPencil></BsPencil>
                                          </span>
                                        </th>
                                        <th>
                                          <span>
                                            <BsTrash></BsTrash>
                                          </span>
                                        </th>
                                      </tr>
                                    </thead>
                                    {tCateg.systems.isSystemLoading ===
                                    false ? (
                                      <>
                                        {tCateg.systems.data.length > 0 ? (
                                          <tbody>
                                            {tCateg.systems.data.map((cSys) => (
                                              <tr
                                                key={
                                                  "system" +
                                                  sType._id +
                                                  "-" +
                                                  tCateg._id +
                                                  "-" +
                                                  cSys._id
                                                }
                                              >
                                                <td
                                                  onClick={() => {
                                                    setScrollPosition(
                                                      window.scrollY
                                                    );
                                                    setSelectedSys(cSys._id);
                                                  }}
                                                >
                                                  {cSys.name}
                                                </td>
                                                <td
                                                  onClick={() => {
                                                    setScrollPosition(
                                                      window.scrollY
                                                    );
                                                    setSelectedSys(cSys._id);
                                                  }}
                                                >
                                                  {cSys.class.libelle}
                                                </td>
                                                <td
                                                  onClick={() => {
                                                    setScrollPosition(
                                                      window.scrollY
                                                    );
                                                    setSelectedSys(cSys._id);
                                                  }}
                                                >
                                                  {cSys.deployDesc}
                                                </td>
                                                <td
                                                  onClick={() => {
                                                    setScrollPosition(
                                                      window.scrollY
                                                    );
                                                    setSelectedSys(cSys._id);
                                                  }}
                                                >
                                                  {cSys.fqdn}
                                                </td>
                                                <td>
                                                  <span
                                                    className="tbBtn"
                                                    onClick={(e) =>
                                                      handleOpenEditModal(
                                                        e,
                                                        cSys._id
                                                      )
                                                    }
                                                  >
                                                    <BsPencil></BsPencil>
                                                  </span>
                                                </td>
                                                <td>
                                                  <span
                                                    className="tbBtn"
                                                    onClick={(e) =>
                                                      confirmDelete(e, cSys._id)
                                                    }
                                                  >
                                                    <BsTrash></BsTrash>
                                                  </span>
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        ) : (
                                          <tbody>
                                            <tr>
                                              <td colSpan="6">
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  No system with this category !
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        )}
                                      </>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td colSpan="6">
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <Spinner color="dark" />
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </Table>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : (
                          <>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              No Category with this type !
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Spinner color="dark" />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="landscapTC_LoadingTypes_spinner">
              <span>No type appears here !</span>
            </div>
          )}
        </>
      ) : (
        <div className="landscapTC_LoadingTypes_spinner">
          <CircularProgress style={{ width: "80px", height: "80px" }} />
        </div>
      )}
    </div>
  );
};

export default LandscapeDefaultTC;
