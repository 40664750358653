const LOAD_MAP = "LOAD_MAP";
const GET_ALL_MAPS_SUCCESS = "GET_ALL_MAPS_SUCCESS";
const GET_ALL_MAPS_FAILURE = "GET_ALL_MAPS_FAILURE";
const CREATE_MAP_SUCCESS = "CREATE_MAP_SUCCESS";
const CREATE_MAP_FAILURE = "CREATE_MAP_FAILURE";
const RETRIEVE_ONE_MAP_SUCCESS = "RETRIEVE_ONE_MAP_SUCCESS";
const RETRIEVE_ONE_MAP_FAILURE = "RETRIEVE_ONE_MAP_FAILURE";
const UPDATE_MAP_SUCCESS = "UPDATE_MAP_SUCCESS";
const UPDATE_MAP_FAILURE = "UPDATE_MAP_FAILURE";
const DELETE_MAP_SUCCESS = "DELETE_MAP_SUCCESS";
const DELETE_MAP_FAILURE = "DELETE_MAP_FAILURE";
const CLEAR_MAPS = "CLEAR_MAPS";
const CLEAR_ONE_MAP = "CLEAR_ONE_MAP";


let mapTypes = {
  LOAD_MAP,
  GET_ALL_MAPS_SUCCESS,
  GET_ALL_MAPS_FAILURE,
  CREATE_MAP_SUCCESS,
  CREATE_MAP_FAILURE,
  RETRIEVE_ONE_MAP_SUCCESS,
  RETRIEVE_ONE_MAP_FAILURE,
  UPDATE_MAP_SUCCESS,
  UPDATE_MAP_FAILURE,
  DELETE_MAP_SUCCESS,
  DELETE_MAP_FAILURE,
  CLEAR_MAPS,
  CLEAR_ONE_MAP

};
export default mapTypes;
