import moment from 'moment';
import { FiAlertCircle } from 'react-icons/fi';
import { FcOk, FcHighPriority, FcExpired } from 'react-icons/fc';


export function getSystemIfHasResult(sysID, resultTab) {
  const output = resultTab.filter(
    ({ systemId }) => sysID === systemId)
  return output.length ? output[0] : false;
};

export const genPillColor = (task, date) => {
  if( moment(task.createdAt).format('YYYY-MM-DD') >= moment(date).add(-1, 'days').format('YYYY-MM-DD')){
    switch (task.globalStatus) {
      case "Good":
        return <FcOk/>
      case "Warning":
        return < FiAlertCircle style={{fill :"#FEC107" , color:"#fff" ,fontSize:"20.9px"}}/>
      case "Critical":
        return <FcHighPriority/>
      default: return <FcExpired/>;
    }
  }else{
    return <FcExpired/>
  }
};

export function mapExistHasStatus(chaine, tab) {
  const output = tab.result.filter(
    ({ mapId: mapIdA }) => chaine === mapIdA)
  return output.length ? output[0] : false;
};

export const resultIfExists = (dataReport, monitActivMapsIds, foundResult = false) => {
  dataReport.result.forEach(sResult => {
    if(monitActivMapsIds.includes(sResult.mapId)){
      foundResult = sResult;
      return false;
    }
  });
  return foundResult;
};