const CREATE_SYSTEM_SUCCESS = "CREATE_SYSTEM_SUCCESS";
const CREATE_SYSTEM_FAILURE = "CREATE_SYSTEM_FAILURE";
const LOAD_SYSTEM = "LOAD_SYSTEM";
const RETRIEVE_ONE_SYSTEM_FAILURE = "RETRIEVE_ONE_SYSTEM_FAILURE";
const RETRIEVE_ONE_SYSTEM_SUCCESS = "RETRIEVE_ONE_SYSTEM_SUCCESS";
const UPDATE_SYSTEM_FAILURE = "UPDATE_SYSTEM_FAILURE";
const UPDATE_SYSTEM_SUCCESS = "UPDATE_SYSTEM_SUCCESS";
const DELETE_SYSTEM_SUCCESS = "DELETE_SYSTEM_SUCCESS";
const DELETE_SYSTEM_FAILURE = "DELETE_SYSTEM_FAILURE";
const GET_SYSTEM_FORMAPS_SUCCESS = "GET_SYSTEM_FORMAPS_SUCCESS";
const GET_SYSTEM_FORMAPS_FAILURE = "GET_SYSTEM_FORMAPS_FAILURE";


const GET_CONTACTS_ASSIGNED_TO_SYSTEM_FAILURE =
  "GET_CONTACTS_ASSIGNED_TO_SYSTEM_FAILURE";
const GET_CONTACTS_ASSIGNED_TO_SYSTEM_SUCCESS =
  "GET_CONTACTS_ASSIGNED_TO_SYSTEM_SUCCESS";

const GET_SYSTEMS_INFO_SUCCESS = "GET_SYSTEMS_INFO_SUCCESS";
const GET_SYSTEMS_INFO_FAILURE = "GET_SYSTEMS_INFO_FAILURE";
const LOAD_SYSTEM_INFO = "LOAD_SYSTEM_INFO";

const LOAD_SYSTEMS_BY_CUSTOMER_ID = "LOAD_SYSTEMS_BY_CUSTOMER_ID";
const RETRIEVE_SYSTEMS_BY_CUSTOMER_ID_SUCCESS = "RETRIEVE_SYSTEMS_BY_CUSTOMER_ID_SUCCESS";
const RETRIEVE_SYSTEMS_BY_CUSTOMER_ID_FAILURE = "RETRIEVE_SYSTEMS_BY_CUSTOMER_ID_FAILURE";

const LOAD_ALL_THE_SYSTEMS = "LOAD_ALL_THE_SYSTEMS";
const GET_ALL_THE_SYSTEMS_SUCCESS = "GET_ALL_THE_SYSTEMS_SUCCESS";
const GET_ALL_THE_SYSTEMS_FAILURE = "GET_ALL_THE_SYSTEMS_FAILURE"
const CLEAR_SYSTEMS_INFO = "CLEAR_SYSTEMS_INFO";

let systemTypes = {
  CREATE_SYSTEM_SUCCESS,
  CREATE_SYSTEM_FAILURE,
  LOAD_SYSTEM,
  RETRIEVE_ONE_SYSTEM_SUCCESS,
  RETRIEVE_ONE_SYSTEM_FAILURE,
  UPDATE_SYSTEM_FAILURE,
  UPDATE_SYSTEM_SUCCESS,
  DELETE_SYSTEM_FAILURE,
  DELETE_SYSTEM_SUCCESS,
  GET_CONTACTS_ASSIGNED_TO_SYSTEM_SUCCESS,
  GET_CONTACTS_ASSIGNED_TO_SYSTEM_FAILURE,
  GET_SYSTEM_FORMAPS_SUCCESS,
  GET_SYSTEM_FORMAPS_FAILURE,
  GET_SYSTEMS_INFO_SUCCESS,
  GET_SYSTEMS_INFO_FAILURE,
  LOAD_SYSTEM_INFO,
  
  LOAD_SYSTEMS_BY_CUSTOMER_ID,
  RETRIEVE_SYSTEMS_BY_CUSTOMER_ID_SUCCESS,
  RETRIEVE_SYSTEMS_BY_CUSTOMER_ID_FAILURE,

  LOAD_ALL_THE_SYSTEMS,
  GET_ALL_THE_SYSTEMS_SUCCESS,
  GET_ALL_THE_SYSTEMS_FAILURE,
  CLEAR_SYSTEMS_INFO
};
export default systemTypes;
