import axios from "axios";
import dotenv from "dotenv";
dotenv.config();
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.defaults.headers.common["x-auth-accessToken"] =
  localStorage.getItem("ma-access-token");
instance.defaults.headers.common["x-auth-refreshToken"] =
  localStorage.getItem("ma-refresh-token");


instance.interceptors.request.use(
  (config) => {
    const brokerToken = window.localStorage.getItem('ma-broker-token');
    if(brokerToken) config.headers['x-auth-brokerToken'] = brokerToken;
    return config;
  }
);

export default instance;
