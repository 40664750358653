import React from "react";
import {
  Modal,
  Button,
  ModalHeader,
  Spinner,
  ModalFooter,
  ModalBody,
  FormGroup,
  Label,
  Badge,
  Table,
  Row,Col
} from "reactstrap";
import "./ModalTaskDetails.css";
import moment from "moment";

export default function ModalTaskDetails({
  toggleModalTaskDetails,
  modalTaskDetails,
  setModalTaskDetails,
  TaskData,
  system,
  oneResult,
  IsLoadingResult,
}) {

  const arrayKeys = oneResult && oneResult.hasOwnProperty('schema') && Object.keys(oneResult.schema).length ? Object.entries(oneResult.schema) : [];
  const arrayValues = oneResult && oneResult.hasOwnProperty('dataSet') && oneResult.dataSet.length ? oneResult.dataSet : [];
  
  return (
    <Modal className="Modal-details globalReportResultModal" isOpen={modalTaskDetails} size="xl" toggle={toggleModalTaskDetails} centered>
      <ModalHeader toggle={toggleModalTaskDetails}>{system}</ModalHeader>
      {IsLoadingResult === false ? (
        <>
          {oneResult?.gStatus ? (
            <ModalBody >
              <Row > 
                <Col sm="9"  className="CustomStyleGR">
                  <FormGroup>
                    <FormGroup>
                      <Badge className="task-label-right" style={{ fontSize: "14px" }}
                        color={
                          oneResult?.gStatus === "Good"
                            ? "success"
                            : oneResult?.gStatus === "Warning"
                            ? "warning"
                            : "danger"
                        }
                      >
                        {oneResult?.gStatus}
                      </Badge>

                      <Label>
                        <Badge className="task-label-right" color="primary" style={{ fontSize: "14px" }}>{TaskData.activity}</Badge>
                      </Label>
                    </FormGroup>
                    {
                      arrayKeys.length && arrayValues.length ? 
                      (
                        <Table className="GlobalReportTable avxBsTable" striped hover>
                          <thead>
                            <tr>
                              { arrayKeys.map( ([key, value], index) => <th style={{ whiteSpace: "nowrap" }} key={index}> {value} </th> ) }
                            </tr>
                          </thead>
                          <tbody className="forPointer">
                          {
                            arrayValues.map(
                              (dataLine, index) => (
                                <tr key={index}>
                                {
                                  arrayKeys.map(
                                    ([key, value], index) => <td key={index}> { dataLine[key] || '---' } </td>
                                  )
                                }
                                </tr>
                              )
                            )
                          }
                          </tbody>
                        </Table>
                      ) : "No details"
                    }
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="taskDraggerContainer">
                    <Label>Attached Files</Label>
                    {oneResult.files.length > 0 ? (
                      <>
                        {oneResult.files.map((oldFile, index) => (
                          <div key={oldFile.name}>
                            <a
                              href={`data:image/${oldFile.extension};base64,${oldFile.data}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {"File " + (index + 1)}
                            </a>
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyItems: "center",
                            height: "100%",
                            opacity: .5,
                          }}
                        >
                          No file exist !
                        </div>
                      </>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label className="CreationTime">
                  created Time:{" "}
                  {moment(oneResult?.createdAt).format("DD/MM/YYYY LT")}
                </Label>
              </FormGroup>
            </ModalBody>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              No result data Exist
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Spinner color="dark" />
        </div>
      )}
      <ModalFooter>
        <Button onClick={() => setModalTaskDetails(!modalTaskDetails)}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}
