import React, { useState } from "react";
import "../../adminPanel.css";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import SyncShiftLogAP from "./SyncShiftAPComponents/SyncShiftLogAP";

const SyncShiftAP = () => {
    const [activeTab, setActiveTab] = useState("SS");
    const toggleRender = (tab) => {
      activeTab !== tab && setActiveTab(tab);
    };
  
    return (
      <div className="userAP">
        {/* <Row className="adminPanelHeader"></Row> */}
  
        <div className="syncShiftAPBody">
          <Nav tabs className="adminPanelChildTabsNav childTabForSyncShift">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "SS" })}
                onClick={() => {
                  toggleRender("SS");
                }}
              >
                Sync Shift
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent className="adminPanelTabsContent">
            {
              {
                SS: (
                  <TabPane className="roleUserAPTab">
                    <SyncShiftLogAP />
                  </TabPane>
                ),
              }[activeTab]
            }
          </TabContent>
        </div>
      </div>
    );
}

export default SyncShiftAP
