import types from "./types";
import monitoringTypeService from "../services/monitoringTypeService";
import { message } from "antd";

const {
  GET_MONITORING_TYPES_SUCCESS,
  GET_MONITORING_TYPES_FAILURE,
  LOAD_MONITORING_TYPE,
  CREATE_MONITORING_TYPE_SUCCESS,
  CREATE_MONITORING_TYPE_FAILURE,
  RETRIEVE_ONE_MONITORING_TYPE_SUCCESS,
  RETRIEVE_ONE_MONITORING_TYPE_FAILURE,
  UPDATE_MONITORING_TYPE_SUCCESS,
  UPDATE_MONITORING_TYPE_FAILURE,
  CLEAR_MONITORING_TYPES
} = types.monitoringTypes;

export const getMonitoringType = (page, size, isActive,searchValue) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_MONITORING_TYPE,
    });
    const res = await monitoringTypeService.getAll(page, size, isActive,searchValue);
    // console.log("Res==>",res)
    dispatch({
      type: GET_MONITORING_TYPES_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_MONITORING_TYPES_FAILURE,
      payload: err.response.data.message,
    });
  }
};

export const createMonitoringType =
  (newMonitoringTypeService) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_MONITORING_TYPE,
      });
      const res = await monitoringTypeService.createMonitoringType(
        newMonitoringTypeService
      );
      message.success("Successfully created the Contact Type");
      dispatch({
        type: CREATE_MONITORING_TYPE_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      message.error("Failed to create the Contract Type");
      dispatch({
        type: CREATE_MONITORING_TYPE_FAILURE,
        payload: err,
      });
    }
  };

  export const RetreiveOneMonitoringType = (id) => async (dispatch) => {
    try {
      const res = await monitoringTypeService.getOneMonitoringType(id);
      dispatch({
        type: RETRIEVE_ONE_MONITORING_TYPE_SUCCESS,
        payload: res.data.data,
      });
      return res.data.data;
    } catch (err) {
      dispatch({
        type: RETRIEVE_ONE_MONITORING_TYPE_FAILURE,
        payload: err.response.data.message,
      });
    }
  };

  export const updateMonitoringType= (id, data) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_MONITORING_TYPE,
      });
      const res = await monitoringTypeService.updateMonitoringType(id, data);
      message.success("Successfully updated the Contact Type");
      dispatch({
        type: UPDATE_MONITORING_TYPE_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      message.error("Failed to update the Contract Type");
      dispatch({
        type: UPDATE_MONITORING_TYPE_FAILURE,
        payload: err?.response?.data?.message,
      });
    }
  };

   //clear All
 export const clearMonitoringTypes = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_MONITORING_TYPES,
  })
}
