import types from "../actions/types";
const {
  GET_ALL_ROLE_SUCCESS,
  GET_ALL_ROLE_FAILURE,
  LOAD_ROLE,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_FAILURE,
  RETRIEVE_ONE_ROLE_SUCCESS,
  RETRIEVE_ONE_ROLE_FAILURE,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAILURE,
  CLEAR_ROLES
} = types.roleTypes;
const initialState = {
  role: null,
  roles: [],
  UserRoles: [],
  isLoading: false,
  error: null,
  listError: null,
};

function userReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_ROLE_SUCCESS:
      return {
        ...state,
        roles: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case GET_ALL_ROLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: payload,
      };
    case LOAD_ROLE:
      return {
        ...state,
        isLoading: true,
      };

    case CREATE_ROLE_SUCCESS:
      state.roles.docs.push(payload);
      return {
        ...state,
        roles: state.roles,
        isLoading: false,
        error: null,
        listError: null,
      };
    case CREATE_ROLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

      case RETRIEVE_ONE_ROLE_SUCCESS:
        return {
          ...state,
          role: payload,
          isLoading: false,
          error: null,
          listError: null,
        };
      case RETRIEVE_ONE_ROLE_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: payload,
          listError: null,
        };
  
        case UPDATE_ROLE_SUCCESS:
          const updatedIndex = state.roles.docs.map((role, index) => {
            if (payload._id === role._id) {
              return index;
            } else return null;
          });
          if (updatedIndex) state.roles.docs[updatedIndex] = payload;
          return {
            ...state,
            roles: state.roles,
            isLoading: false,
            error: null,
            listError: null,
          };
    
        case UPDATE_ROLE_FAILURE:
          return {
            ...state,
            isLoading: false,
            error: payload,
            listError: null,
          };
  
        case CLEAR_ROLES:
          return {
            ...state,
            roles: [],
            role: null,
            isLoading: false,
            error: null,
            listError: null,
          };

    default:
      return state;
  }
}

export default userReducer;
