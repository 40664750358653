import React, { useState, useEffect } from "react";
import {
  Button,
  FormGroup,
  Label,
  Form,
  ModalFooter,
  ModalHeader,
  Modal,
  ModalBody,
  Row,
  Col
} from "reactstrap";
import "./planNewTask.css";
import {  Switch, DatePicker } from "antd";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./PlanNewTaskYupValidation";
import { v4 } from "uuid";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  retreiveSystemsForMaps,
  retreiveSystems,
  retreiveCustomer
} from "../../../../../actions/taskAction";
import {AddOneMTP} from "../../../../../actions/mtpAction";



export default function PlanNewTask(props) {
  const {
    refreshData,
    setrefreshData,
  } = props;

  const dispatch = useDispatch();
  const [modalAddplanNewTask, setmodalAddplanNewTask] = useState(false);
 
  const toggleModalAddplanNewTask = () => {
    setValue("description", "");
    reset();
    setmodalAddplanNewTask(!modalAddplanNewTask);
  }

  const timeZone = useSelector((state) => state.user.timeZone);
  const [optionsSystems, setoptionsSystems] = useState([]);
  const [optionsmonitoringActivities, setoptionsmonitoringActivities] = useState([]);
  const monitoringActivities = useSelector((state) => state.taskReducer.AllSystemsFormMap.docs);
  const systems = useSelector((state) => state.taskReducer.AllSystems);
  let isLoadingSystem = useSelector(state => state.taskReducer.isLoadingSystem)
  let [optionsCustomers, setoptionsCustomers] = useState([])
  let customers = useSelector(state => state.taskReducer.AllCustomers.docs)
  let  isloadingMaps = useSelector(state => state.taskReducer.isloadingMaps)
  const [compKeyCustomer, setcompKeyCustomer] = useState(v4());
  

  const {
   control,
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
    formState: { errors },
} = useForm({
    resolver: yupResolver(schema),
});

useEffect(() => {
  dispatch(retreiveCustomer(false, null, null, true))
 
}, [dispatch]);

const getSystem =  (selectedCustomerID) => {
  dispatch(retreiveSystems(selectedCustomerID))
}

useEffect(() => {
  setoptionsmonitoringActivities(
    monitoringActivities?.map((act) => ({
      value: act._id,
      label: act.monitoringAct.activity,
    }))
  );
  setoptionsSystems(systems?.map((sys)=>({
    value : sys._id,
    label : sys.name
  })))
}, [monitoringActivities,systems]);




function handletoggleModal() {
  //  const handletoggleModal = async () => {
  reset();

  setValue("isActive", true);

  setoptionsCustomers(customers?.map((cust)=>({
    value : cust._id,
    label : cust.label
  })))
  setmodalAddplanNewTask(!modalAddplanNewTask)
}



function handleCreateTask({
  isActive,
  description,
  estimatedStart,
  title,
  system,
  map,
 
}) {
  const formattedTime = moment(estimatedStart?._d).format("YYYY-MM-DD HH:mm");
  // console.log("estimatedStart", moment(estimatedStart?._d).format("YYYY-MM-DD HH:mm"));


  let newMTP = {};
  isActive === false
    ? (newMTP = {
        title: title,
        description: description,
        type: "Other",
        estimatedStart: formattedTime,
        
      })
    : (newMTP = {
        title: map.label,
        description: description,
        type: "Other Monitoring",
        estimatedStart: formattedTime,
        system: system.value,
        map: map.value,
        
      });
  dispatch(AddOneMTP(newMTP, timeZone));
  setrefreshData(!refreshData);
  setmodalAddplanNewTask(!modalAddplanNewTask);
  // reset();
}

if(errors.customer){
  document.querySelector('.customerSelectCustomer > div:nth-child(2)') &&
  document.querySelector('.customerSelectCustomer > div:nth-child(2)').classList.add('isInvalidSelect');
}

if(errors.system){
  document.querySelector('.customerSelectSystem > div:nth-child(2)') &&
  document.querySelector('.customerSelectSystem > div:nth-child(2)').classList.add('isInvalidSelect');
}

if(errors.map){
  document.querySelector('.customerSelectMap > div:nth-child(2)') &&
  document.querySelector('.customerSelectMap > div:nth-child(2)').classList.add('isInvalidSelect');
}

const selectInvalidFeedback = {
  width: "100%",
  marginTop: ".25rem",
  fontSize: "80%",
  color: "#dc3545",
};

const getMonitoringActivity = (selectedSystemID) => {
  dispatch(retreiveSystemsForMaps(selectedSystemID, timeZone));
};

// function disabledDate(current) {
//   // Can not select days before today and today
//   return current && current < moment().startOf('day');
// }

function disabledDate(current)  {
  let customDate = moment().format("YYYY-MM-DD HH:mm");
  return current && current < moment(customDate, "YYYY-MM-DD HH:mm");
} 



  return (
    <>
      <Button color="primary" onClick={handletoggleModal}>
        Plan new task
      </Button>
      <Modal
        className="Add-New-Task-Plan-Modal"
        centered
        backdrop="static" keyboard={true}
        isOpen={modalAddplanNewTask}
        size="lg"
        toggle={toggleModalAddplanNewTask}
        fade={false}
      >
        <Form onSubmit={handleSubmit(handleCreateTask)} >
        <ModalHeader toggle={toggleModalAddplanNewTask}>
          Plan new task
        </ModalHeader>
        <ModalBody>
          <Row className="planNewTaskSwitcherTime">
            <Col>
            <FormGroup>
                  <Label className="LabelMonitoringTask">
                    <span className="mr-3">Monitoring Task</span>
                    <Controller
                      control={control}
                      name="isActive"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Switch
                          onBlur={onBlur}
                          onChange={(e) => {
                            onChange(e); // on change mta3 el react hook form
                            if (e === true) {
                              setValue("title", undefined);
                            } else {
                              setValue("system", undefined);
                              setValue("map", undefined);
                            }
                          }}
                          checked={value}
                          defaultChecked
                        />
                      )}
                    />
                  </Label>
                </FormGroup>
            </Col>
            <Col xs="5">
              <FormGroup>
                  <Controller
                    name="estimatedStart"
                    control={control}
                    render={({ field: { ref, ...rest }, fieldState }) => (
                      <DatePicker
                      showTime  
                      disabledDate={disabledDate}
                      format="YYYY-MM-DD HH:mm"
                      className={ "form-control " + (errors.estimatedStart ? " is-invalid" : "") }
                       placeholder="Estimated start *"    {...rest}  {...fieldState}
                      />
                    )}
                  />
                <small style={selectInvalidFeedback} >
                  {errors.estimatedStart && <>{errors.estimatedStart.message}</>}
                </small>
              </FormGroup>
            </Col>
          </Row>

          {watch("isActive") === true ? 
            <FormGroup className='formGroupSelect'>
              <Row>

              <Col xs="4">
                <Controller
                  name="customer"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      className="customerSelectCustomer" 
                      placeholder="Select Customer *" 
                      options={optionsCustomers}
                      
                      onChange={
                        (e) => {
                          onChange(e); // on change mta3 el react hook form
                          getSystem(e.value);
                          setcompKeyCustomer(v4());
                          // setValue("system", {value: "", label:"Select System *"});
                        }
                      }
                      onBlur={onBlur}
                      selected={value}
                    />
                  )}
                />
                <small style={selectInvalidFeedback}>
                  {errors.customer && <>{errors.customer.value?.message}</>}
                </small>
              </Col>

              <Col xs="3">
                <Controller
                  name="system"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      className="customerSelectSystem" 
                      placeholder="Select System *" 
                      options={optionsSystems}
                      key={compKeyCustomer}
                      isLoading={isLoadingSystem}
                      isDisabled={watch("customer") === undefined}
                      onChange={
                        (e) => {
                          onChange(e); // on change mta3 el react hook form
                          getMonitoringActivity(e.value);
                          setValue("map", {value: "", label:"Select Monitoring Activity *"});
                        }
                      }
                      onBlur={onBlur}
                      selected={value}
                    />
                  )}
                />
                <small style={selectInvalidFeedback}>
                  {errors.system && <>{errors.system.value?.message}</>}
                </small>
              </Col>
              <Col xs="5">
                  <Controller
                    name="map"
                    control={control}
                    render={({ field: { ref, ...rest }, fieldState }) => (
                      <Select className="customerSelectMap"
                        placeholder="Select Monitoring Activity *"
                        options={optionsmonitoringActivities} 
                        isLoading={isloadingMaps}
                        
                        isDisabled={watch("system") === undefined}
                        {...rest}
                        {...fieldState}
                      />
                    )}
                  />
                  <small style={selectInvalidFeedback} >
                      {errors.map && <>{errors.map.value.message}</>}
                  </small>
              </Col>
              </Row>
            </FormGroup>
            : 
            <FormGroup>
                <Label>
                  Title<span className="requiredField"> *</span>
                </Label>
                <input
                  {...register("title")}
                  className={
                    "form-control" + (errors.title ? " is-invalid" : "")
                  }
                />
                <small className="invalid-feedback">
                  {errors.title && <>{errors.title.message}</>}
                </small>
              </FormGroup>
          }
          
          <FormGroup>
              <Label>
                Description <span className="requiredField"> *</span>
              </Label>
              <textarea rows="5"
                {...register("description")}
                className={
                  "form-control " + (errors.description ? " is-invalid" : "")
                }
              ></textarea>
              <small className="invalid-feedback ">
                {errors.description && <>{errors.description.message}</>}
              </small>
            </FormGroup>
          
           
        </ModalBody>
        <ModalFooter>
          <Button color="primary"  type="submit">Plan Task</Button>
          <Button onClick={toggleModalAddplanNewTask}>Cancel</Button>
        </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}
