const GET_ALL_RESULT_FORM_SUCCESS = "GET_ALL_RESULT_FORM_SUCCESS";
const GET_ALL_RESULT_FORM_FAILURE = "GET_ALL_RESULT_FORM_FAILURE";
const LOAD_RESULT_FORM = "LOAD_RESULT_FORM";
const CREATE_RESULT_FORM_SUCCESS = "CREATE_RESULT_FORM_SUCCESS";
const CREATE_RESULT_FORM_FAILURE = "CREATE_RESULT_FORM_FAILURE";
const RETRIEVE_ONE_RESULT_FORM_FAILURE = "RETRIEVE_ONE_RESULT_FORM_FAILURE";
const RETRIEVE_ONE_RESULT_FORM_SUCCESS = "RETRIEVE_ONE_RESULT_FORM_SUCCESS";
const UPDATE_RESULT_FORM_SUCCESS = "UPDATE_RESULT_FORM_SUCCESS";
const UPDATE_RESULT_FORM_FAILURE = "UPDATE_RESULT_FORM_FAILURE";
const DELETE_RESULT_FORM_SUCCESS = "DELETE_RESULT_FORM_SUCCESS";
const DELETE_RESULT_FORM_FAILURE = "DELETE_RESULT_FORM_FAILURE";

const GET_ALL_MONITORING_ACTIVITY_WITHOUT_RESULT_FORM_SUCCESS = "GET_ALL_MONITORING_ACTIVITY_WITHOUT_RESULT_FORM_SUCCESS";
const GET_ALL_MONITORING_ACTIVITY_WITHOUT_RESULT_FORM_FAILURE = "GET_ALL_MONITORING_ACTIVITY_WITHOUT_RESULT_FORM_FAILURE";
const LOAD_MONITORING_ACTIVITY = "LOAD_MONITORING_ACTIVITY";

const CLEAR_RESULT_FORM = "CLEAR_RESULT_FORM";

let resultFormTypes = {
    LOAD_RESULT_FORM,
    GET_ALL_RESULT_FORM_SUCCESS,
    GET_ALL_RESULT_FORM_FAILURE,
    CREATE_RESULT_FORM_SUCCESS,
    CREATE_RESULT_FORM_FAILURE,
    RETRIEVE_ONE_RESULT_FORM_SUCCESS,
    RETRIEVE_ONE_RESULT_FORM_FAILURE,
    UPDATE_RESULT_FORM_SUCCESS,
    UPDATE_RESULT_FORM_FAILURE,
    DELETE_RESULT_FORM_SUCCESS,
    DELETE_RESULT_FORM_FAILURE,

    GET_ALL_MONITORING_ACTIVITY_WITHOUT_RESULT_FORM_SUCCESS,
    GET_ALL_MONITORING_ACTIVITY_WITHOUT_RESULT_FORM_FAILURE,
    LOAD_MONITORING_ACTIVITY,

    CLEAR_RESULT_FORM,
  };
  export default resultFormTypes;