const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
const CREATE_CLIENT_FAILURE = "CREATE_CLIENT_FAILURE";
const LOAD_CLIENT = "LOAD_CLIENT";
const GET_ALL_CLIENTS_SUCCESS = "GET_ALL_CLIENTS_SUCCESS";
const GET_ALL_CLIENTS_FAILURE = "GET_ALL_CLIENTS_FAILURE";
const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
const DELETE_CLIENT_FAILURE = "DELETE_CLIENT_FAILURE";
const RETRIEVE_ONE_CLIENT_FAILURE = "RETRIEVE_ONE_CLIENT_FAILURE";
const RETRIEVE_ONE_CLIENT_SUCCESS = "RETRIEVE_ONE_CLIENT_SUCCESS";
const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
const UPDATE_CLIENT_FAILURE = "UPDATE_CLIENT_FAILURE";
const CLEAR_CLIENTS = "CLEAR_CLIENTS";
const CLEAR_ONE_CLIENT = "CLEAR_ONE_CLIENT";

let clientTypes = {
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_FAILURE,
  LOAD_CLIENT,
  GET_ALL_CLIENTS_SUCCESS,
  GET_ALL_CLIENTS_FAILURE,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILURE,
  RETRIEVE_ONE_CLIENT_SUCCESS,
  RETRIEVE_ONE_CLIENT_FAILURE,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  CLEAR_CLIENTS,
  CLEAR_ONE_CLIENT
};
export default clientTypes;
