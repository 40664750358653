import * as Yup from "yup";
const schema = Yup.object().shape({
  label: Yup.string().required("The Name is required."),
  address: Yup.string().required("The Address is required."),
  timeZone: Yup.array()
    .max(3, "The maximum is 3 Timezones.")
    .min(1, "TimeZone is Required.")
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required("Value of TimeZone is Required"),
      })
    )
    .nullable()
    .required("TimeZone is Required."),
  firstReport: Yup.string().required("The First Report is required."),
  status: Yup.boolean().required("The Status is required."),
  types: Yup.array().min(1, "You need to choose atleast one type.").nullable().required("You need to choose a type."),
});
export default schema;
