export const DoughnutFakeData = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

export const BarData = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'Dataset 1',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Dataset 2',
      data: [10, 11, 13, 15, 12, 13],
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

export const LineData = {
  labels : ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'Active users',
      data:  [12, 19, 3, 5, 2, 3],
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'total users',
      data:  [18, 9, 13, 15, 12, 9],
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

export const optionsBar = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',

    },
    title: {
      display: true,
      text: 'Bar chart here',
    },
  },
};

export const optionsDoughnut = {
  responsive: true,
  responsiveAnimationDuration:20,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Doghnut chart here',
    },
  },
};
export const optionsLine = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Avg. Response Time',
    },
  },
};

export const optionsLineAvRespTime = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Avg. Response Time',
    },
  },
};

export const optionsLineActiveUser = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Active User',
    },
  },
};

export const optionsLineDbGrowth = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Db Growth',
    },
  },
};