const GET_ALL_MODULE_SUCCESS = "GET_ALL_MODULE_SUCCESS";
const GET_ALL_MODULE_FAILURE = "GET_ALL_MODULE_FAILURE";
const LOAD_MODULE = "LOAD_MODULE";
const RETRIEVE_ONE_MODULE_FAILURE = "RETRIEVE_ONE_MODULE_FAILURE";
const RETRIEVE_ONE_MODULE_SUCCESS = "RETRIEVE_ONE_MODULE_SUCCESS";
const CREATE_MODULE_SUCCESS = "CREATE_MODULE_SUCCESS";
const CREATE_MODULE_FAILURE = "CREATE_MODULE_FAILURE";
const UPDATE_MODULE_SUCCESS = "UPDATE_MODULE_SUCCESS";
const UPDATE_MODULE_FAILURE = "UPDATE_MODULE_FAILURE";
const CLEAR_MODULE = "CLEAR_MODULE";

let moduleTypes = {
  LOAD_MODULE,
  GET_ALL_MODULE_SUCCESS,
  GET_ALL_MODULE_FAILURE,
  RETRIEVE_ONE_MODULE_SUCCESS,
  RETRIEVE_ONE_MODULE_FAILURE,
  CREATE_MODULE_SUCCESS,
  CREATE_MODULE_FAILURE,
  UPDATE_MODULE_SUCCESS,
  UPDATE_MODULE_FAILURE,
  CLEAR_MODULE,
};
export default moduleTypes;