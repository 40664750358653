import http from "../api";

const getAllResultForm = (page, size, paginate, isActive=null, searchValue) => {
    
   
     if (isActive!==null){
       return searchValue ? http.get(`/api/resultForm/getAllResultForm/?page=${page}&size=${size}&paginate=${paginate}&isActive=${isActive}&searchValue=${searchValue}`) : http.get(`/api/resultForm/getAllResultForm/?page=${page}&size=${size}&paginate=${paginate}&isActive=${isActive}`);}
       else{
         return  searchValue ? http.get(`/api/resultForm/getAllResultForm/?page=${page}&size=${size}&paginate=${paginate}&searchValue=${searchValue}`) : http.get(`/api/resultForm/getAllResultForm/?page=${page}&size=${size}&paginate=${paginate}`);
       }
   };

   const createResultForm = (resultForm) => {
    return http.post(`/api/resultForm/addFormSchema`, resultForm);
  };

  const getOneResultForm = (id) => {
    return http.get(`/api/resultForm/getResultFormById/${id}`);
  };
  
  const updateResultForm = (id, resultForm) => {
    return http.patch(`/api/resultForm/updateResultForm/${id}`, resultForm);
  };

  const deleteResultForm = (id) => {
    return http.patch(`/api/resultForm/deleteResultForm/${id}`);
  };

  const getMonitoringActWithoutResultForm = () => {
    return http.get(`/api/resultForm/getMonitoringActivitiesWithoutResultForm`);
  };



const resultFormService = {
    getAllResultForm,
    createResultForm,
    getOneResultForm,
    updateResultForm,
    deleteResultForm,
    getMonitoringActWithoutResultForm
    
  };
  export default resultFormService;