import React, {useEffect, useState, useRef} from 'react';
import {Row, Col, Badge, Button} from 'reactstrap';
import {BsChevronDoubleLeft} from "react-icons/bs";
import './MyTasksWorkflow.css';
import { processTableHeight } from '../../../assets/js/CoreJs';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Skeleton from 'react-loading-skeleton'
import ModalTaskDetails from './ModalTaskDetails';
import 'react-loading-skeleton/dist/skeleton.css'
import taskService from '../../../services/taskService'

import ReactDOMServer from 'react-dom/server';
import { message } from 'antd';

//khalil for dynamic modal
import {AddResultModal, UpdateResultModal} from './dynamic-forms/DynamicFormsModals';
import { closeTask, getTaskWorkFlow } from '../../../actions/workFlowTaskAction';

//socket client
import socketIOClient from "socket.io-client";
import dotenv from "dotenv";

const handleDragstate = (state, snapshot) => {
  switch(state){
    case "Pending": case "Canceled" : return true;
    case "Done": return true;
    case "In progress": return false;
    default: return false;
  }
}

//Main Workflow Component
const MyTasksWorkflow = ({closeWorkflow}) => {

  const dispatch = useDispatch();
  const currentTimeZone = useSelector((state) => state.user.timeZone);
  const taskWorkFlowReducer = useSelector(state => state.taskWorkFlowReducer.taskWorkFlow);
  const isLoading = useSelector(state => state.taskWorkFlowReducer.isLoading);
  const userId = useSelector(state => state.user.user._id);

  //socket
  const socket = useRef();
  dotenv.config();

  const [Loading, setLoading] = useState(true);
 
  const checkboxsRef = useRef([])
  const checkboxAllRef = useRef()
  
  //khalil for dynamic modal
  const [taskID, setTaskID] = useState(null);
  const [addResultModal, setAddResultModal] = useState(false);
  const toggleAddResultModal = () => setAddResultModal(!addResultModal);
  const [updateResultModal, setUpdateResultModal] = useState(false);
  const toggleUpdateResultModal = () => setUpdateResultModal(!updateResultModal);

  const [columns, setColumns] = useState([]);
  const [TaskData, setTaskData] = useState([])

  const applyColsHeight = () => {
    processTableHeight(["TitlePage", "myTasksWFHeader", "headColumnsTitles"], "wfParentRowTCONT", 25);
  }; 

  useEffect(() => {
    socket.current = socketIOClient(process.env.REACT_APP_SOCKET_SERVER_URL);
    socket.current.on(userId, (notifType, data) => {
      notifType === 'task-state-changed' && dispatch(getTaskWorkFlow(currentTimeZone,false))
    })
    return () => socket.current?.disconnect();
  }, [dispatch, userId, currentTimeZone]);

  useEffect(() => {
    applyColsHeight();
    window.addEventListener("resize", applyColsHeight);

    return () => {
      window.removeEventListener("resize", applyColsHeight);
    };
  }, []);

  //const [result, setResult] = useState()

  useEffect(() => {
    dispatch(getTaskWorkFlow(currentTimeZone))
  }, [dispatch,currentTimeZone]);

  useEffect(() => {
    setColumns(taskWorkFlowReducer)
    setLoading(isLoading)
  }, [taskWorkFlowReducer,isLoading]);


  const onDragEnd = async (result, columns, setColumns) => { //khalil edit
    if (!result.destination) return;
    const { source, destination } = result;
    //console.log(result); //khalil edit

    if((source.droppableId==="1" && destination.droppableId==="2") || (source.droppableId==="2" && destination.droppableId==="3")){
      if(source.droppableId ==="1" && destination.droppableId==="2"){
        await setTaskID(result.draggableId); //khalil edit
        toggleAddResultModal();
      }else{
        if((source.droppableId ==="2" && destination.droppableId==="3")) dispatch(closeTask(result.draggableId));
        if(source.droppableId !== destination.droppableId) {
          const sourceColumn = columns[source.droppableId];
          const destColumn = columns[destination.droppableId];
          const sourceItems = [...sourceColumn.items];
          const destItems = [...destColumn.items];
          const [removed] = sourceItems.splice(source.index, 1);
          destItems.splice(destination.index, 0, removed);
          setColumns({
            ...columns,
            [source.droppableId]: { ...sourceColumn, items: sourceItems },
            [destination.droppableId]: { ...destColumn, items: destItems }
          });
        }else{
          const column = columns[source.droppableId];
          const copiedItems = [...column.items];
          const [removed] = copiedItems.splice(source.index, 1);
          copiedItems.splice(destination.index, 0, removed);
          setColumns({
            ...columns,
            [source.droppableId]: { ...column, items: copiedItems }
          });
        }
      }
    }
  }//end onDragEnd

  const closeMultiTasks = async (e)=>{
    e.target.disabled = true;
    const allTasks = checkboxsRef.current.filter(ckb => ckb.element);
    const checkedTasks = allTasks.filter(ckb => ckb.element.checked);
    const closed = await taskService.closeMultiTask(checkedTasks.map(ckb => ckb.id));
    if(closed.status === 200){
      message.success('Tasks successfully moved to Done', 5);
      checkboxAllRef.current.indeterminate = checkboxAllRef.current.checked = false;
      renderMvButton(mvButtonContainerRef, false);
    } else message.error('An error has occurred', 5);
  };

  const checkMultiAction = (e)=>{
    checkboxsRef.current.forEach(ckb => {
      if(ckb.element) ckb.element.checked = e.target.checked;
    });
    const checkedTasks = checkboxsRef.current.filter(ckb => ckb.element?.checked);
    renderMvButton(mvButtonContainerRef, Boolean(checkedTasks.length));
  };

  const onCheckTask = ()=>{
    const allTasks = checkboxsRef.current.filter(ckb => ckb.element);
    const checkedTasks = allTasks.filter(ckb => ckb.element.checked);
    if(checkedTasks.length && checkedTasks.length < allTasks.length) checkboxAllRef.current.indeterminate = true;
    else if(checkedTasks.length && checkedTasks.length === allTasks.length){
      checkboxAllRef.current.indeterminate = false;
      checkboxAllRef.current.checked = true;
    }else checkboxAllRef.current.indeterminate = checkboxAllRef.current.checked = false;
    renderMvButton(mvButtonContainerRef, Boolean(checkedTasks.length));
  };

  const mvButtonContainerRef = useRef();

  const renderMvButton = (elt, isShown) => {
    if(isShown){
      elt.current.innerHTML = ReactDOMServer.renderToString(<Button color="primary" outline size="sm">Move to Done</Button>);
      elt.current.children[0].addEventListener('click', closeMultiTasks);
    }else{
      elt.current.children[0].removeEventListener('click', closeMultiTasks);
      elt.current.innerHTML = '';
    }
  };
  
  const renderColumnHead = (col) => {
    const columnsCatalog = {
      'Completed' : <>
        <h6>{col.name + ' ' + col.items.length}</h6>
        <div>
          <div className="mvButtonContainer" ref={mvButtonContainerRef}></div>
          <input type="checkbox" ref={checkboxAllRef} onClick={checkMultiAction} disabled={col.items.length === 0} />
        </div>
      </>
    };
    columnsCatalog['Pending'] = columnsCatalog['In progress'] = columnsCatalog['Done'] = columnsCatalog['Canceled'] = <h6>{col.name + ' ' + col.items.length}</h6>;
    return columnsCatalog[col.name];
  };

  return (
    <section className="avxWrpContentPage myTasksWorkflowPage">
      <header className="myTasksWFHeader">
        <span onClick={closeWorkflow}><BsChevronDoubleLeft/></span>
        {/* <Button color="primary" size="sm" outline onClick={toggleAddResultModal}>Test Add Result Modal</Button>{" "}
        <Button color="primary" size="sm" outline onClick={toggleUpdateResultModal}>Test Update Result Modal</Button> */}
      </header>
      <section className="myTasksWFContent">
        <div className="headColumnsTitles">
          { Object.entries(columns).map(([colKey, column]) => (
            <div key={colKey} className='titleCell'>
              <div key={colKey}>{renderColumnHead(column)}</div>
            </div>
          ))}
        </div>

        <div id="wfParentRowTCONT" className="wfParentContainer">
          <Row className="wfParentRow">
            <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)} >
              {Object.entries(columns).map(
                ([columnId, column], index) => 
                  <WFColumn columnId={columnId} key={columnId} column={column} TaskData={TaskData} setTaskData={setTaskData} Loading={Loading} checkboxsRef={checkboxsRef}
                    setTaskID={setTaskID} toggleAddResultModal={toggleAddResultModal} toggleUpdateResultModal={toggleUpdateResultModal}
                    onCheckTask={onCheckTask}
                  />
              )}
            </DragDropContext>
          </Row>
        </div>

        {/*khalil for dynamic modal*/}
        <AddResultModal taskID={taskID} setTaskID={setTaskID} addResultModal={addResultModal} toggleAddResultModal={toggleAddResultModal} />
        <UpdateResultModal taskID={taskID} setTaskID={setTaskID} updateResultModal={updateResultModal} toggleUpdateResultModal={toggleUpdateResultModal}
          taskData={TaskData} setTaskData={setTaskData}
        />

      </section>
    </section>
  )
};
export default MyTasksWorkflow;




//Workflow Column Component
const WFColumn = ({
  Loading, columnId, column, TaskData, setTaskData,
  setTaskID, toggleAddResultModal, toggleUpdateResultModal,
  checkboxsRef, onCheckTask
}) => { //khalil edit

  const [modalTaskDetails, setModalTaskDetails] = useState(false);

  function handleClearTaskDetails(){
    setTaskData();
    setTaskID(null);
    setModalTaskDetails(!modalTaskDetails);
  }

  function toggleModalTaskDetails(){
    setModalTaskDetails(!modalTaskDetails);
  }

  async function handleShowModalTask(task) { //khalil edit
    setTaskData(task);
    await setTaskID(task._id);
    (task.state === "Pending" || task.state==="Canceled") && toggleModalTaskDetails();
    task.state === "In progress" && toggleAddResultModal();
    (task.state === "Completed" || task.state === "Done") && toggleUpdateResultModal();
  }

  return (
    <Col id={"wfCol-" + columnId} className="wfColumn">

      <Droppable droppableId={columnId} key={columnId}>
        {(provided, snapshot) => {
          return(
            <div className="wfColumnInner" {...provided.droppableProps} ref={provided.innerRef}>
              <div className="wfTasksContainer">
                {Loading===false ? (
                  <>
                    {column?.items.map((task, index) =>
                      <Draggable key={task?._id} draggableId={task?._id} index={index} isDragDisabled={handleDragstate(task?.state,provided)}>
                        {(provided, snapshot) =>
                          <WFTask key={task?._id} task={task} columnId={columnId} provided={provided} index={index}
                            snapshot={snapshot} handleShowModalTask={handleShowModalTask} Loading={Loading} checkboxsRef={checkboxsRef}
                            onCheckTask={onCheckTask}
                          />
                        }
                      </Draggable>
                    )}
                    {provided.placeholder}
                  </>
                ) : (
                  <Skeleton count={5} style={{height:"102.35px"}} />
                )}
              </div>

              <ModalTaskDetails
                toggleModalTaskDetails={toggleModalTaskDetails}
                modalTaskDetails={modalTaskDetails}
                setModalTaskDetails={setModalTaskDetails}
                TaskData={TaskData}
                handleClearTaskDetails={handleClearTaskDetails}
              />
            </div>
          );
        }}
      </Droppable>
      
    </Col>
  );
};




//Workflow single task Component
const WFTask = ({task,provided,handleShowModalTask,index,checkboxsRef,
  onCheckTask}) => {

  const currentTimeZone = useSelector((state) => state.user.timeZone);
  const onCheckboxClick = (e)=>{
    e.stopPropagation();
  }

  const generateInputReference = (e) => {
    checkboxsRef.current[index] = {
      id: task._id,
      element: e
    };
  };
  
  return (
    <div id={"task-" + task?._id} className="wfTask" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
      onClick={()=>handleShowModalTask(task)}
    >
      <div>
        <header className="wfTaskHead">

          <h3>{task?.title}</h3>
          <span><Badge>{moment(task?.estimatedStart).tz(currentTimeZone).format("LT")}</Badge></span>
        </header>
        <div className="wfTaskBody">
          <h4>{task?.systemName}</h4>
          <p>{task?.description}</p>
        </div>
        <footer className="wfTaskFooter">
          <label>{task?.type}</label>
          
          {task.state ==="Completed" && <label onClick={onCheckboxClick}>
            <input type="checkbox" ref={generateInputReference} onClick={onCheckTask} />
          </label>}
        
          </footer>
      </div>
    </div>
  );
}