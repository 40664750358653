import http from "../api";

const createSla = (sla) => {
  return http.post(`/api/sla`, sla);
};

const getAllSla = (slaContract_Id, page, size, searchValue) => {
  if(searchValue){
    return http.get(
      `/api/sla/getbyslaContract/${slaContract_Id}?page=${page}&size=${size}&searchValue=${searchValue}`
    );
  }
  else{
    return http.get(
      `/api/sla/getbyslaContract/${slaContract_Id}?page=${page}&size=${size}`
    );
  }
};

const updateSla = (id, sla) => {
  return http.patch(`/api/sla/update/${id}`, sla);
};

const getOneSla = (id) => {
  return http.get(`/api/sla/getone/${id}`);
};

const deleteSla = (id) => {
  return http.patch(`/api/sla/delete/${id}`);
};

const slaService = {
  createSla,
  getAllSla,
  updateSla,
  getOneSla,
  deleteSla,
};
export default slaService;
