import { useState } from "react";
import { useSelector } from "react-redux";
import moment from 'moment';


export default function useItOperationsHelper(){
  const currentTimeZone = useSelector((state) => state.user.timeZone);
  moment.tz.setDefault(currentTimeZone);

  const defaultMonthStart = moment().startOf('month').valueOf();
  const defaultMonthEnd = moment().endOf('month').valueOf();

  const [timelineStart, setTimelineStart] = useState(defaultMonthStart);
  const [timelineEnd, setTimelineEnd] = useState(defaultMonthEnd);

  //timeline default config
  function processDefaultConfig(){
    return(
      {
        visibleTimeStart : timelineStart,
        visibleTimeEnd: timelineEnd,
        stackItems : false,
        canMove : false,
        canResize : false,
        minZoom : timelineEnd-timelineStart,
        maxZoom : timelineEnd-timelineStart,
        //timeSteps : { minute:60, hour:1, day:1, month:1, year:1 },
        onTimeChange : handleTimelineScroll
      }
    );
  }

  //limit timeline scroll area
  function handleTimelineScroll (visibleTimeStart, visibleTimeEnd) {
    if(visibleTimeStart < defaultMonthStart && visibleTimeEnd > defaultMonthEnd){
      setTimelineStart(defaultMonthStart);
      setTimelineEnd(defaultMonthEnd);
    } else if(visibleTimeStart < defaultMonthStart){
      setTimelineStart(defaultMonthStart);
      setTimelineEnd(defaultMonthStart + (visibleTimeEnd - visibleTimeStart));
    } else if(visibleTimeEnd > defaultMonthEnd){
      setTimelineStart(defaultMonthEnd - (visibleTimeEnd - visibleTimeStart));
      setTimelineEnd(defaultMonthEnd);
    } else if((visibleTimeEnd-visibleTimeStart) >= (1000 * 60 * 60 * 24)){
      setTimelineStart(visibleTimeStart);
      setTimelineEnd(visibleTimeEnd);
    }

    console.log(moment(defaultMonthStart).format('DD-MM-YYYY'));
  }


  return {
    currentTimeZone,
    processDefaultConfig
  }
}//end of useItOperationsHelper






//transform systems for timeline structure
export const structureSystems = (systemsList) => (
  systemsList.map(
    system => ({
      id : system._id,
      title : system.name
    })
  )
);

//transform operations for timeline structure
export function structureOperations(operationsList , timeZone){
  moment.tz.setDefault(timeZone);
  return(
    operationsList.map(
      operation => ({
        id : operation._id,
        group: operation.system,
        title : operation.title,
        start_time: moment(operation.startDate),
        end_time : moment(operation.endDate),
      })
    )
  );
}
