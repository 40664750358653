import statisticService from "../services/statisticService";
import types from "./types";
import { message } from 'antd';

const {
  GET_ACTIVE_USER_STAT_SUCCESS,
  GET_ACTIVE_USER_STAT_FAILURE,
  ACTIVE_USER_LOAD,
  CLEAR_STORE_STAT,
  GET_AV_RESPONSE_TIME_STAT_SUCCESS,
  GET_AV_RESPONSE_TIME_STAT_FAILURE,
  AV_RESPONSE_TIME_LOAD,
  GET_DBGROWTH_STAT_SUCCESS,
  GET_DBGROWTH_STAT_FAILURE,
  DBGROWTH_LOAD,
} = types.statisticTypes;

export const GetActiveUserStat = (customerID, startDate, endDate, systemId, timeZone) => async (dispatch) => {
  try {
    dispatch({
      type: ACTIVE_USER_LOAD,
    });
    const res = await statisticService.getActiveUsersStatistics(customerID, startDate, endDate, systemId, timeZone)
    message.success("Retreive Successfully", 3);
    dispatch({
      type: GET_ACTIVE_USER_STAT_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    message.error('An Error occurred while retreiving data', 3);
    dispatch({
      type: GET_ACTIVE_USER_STAT_FAILURE,
      payload: err,
    });
  }
};
export const clearStoreStat = () => async (dispatch) => {

  dispatch({
    type: CLEAR_STORE_STAT,
  });


};
export const getAvResponseTimeStat = (customerID, startDate, endDate, systemId, timeZone) => async (dispatch) => {
  try {
    dispatch({
      type: AV_RESPONSE_TIME_LOAD,
    });
    const res = await statisticService.getavgResponseTimeStatistics(customerID, startDate, endDate, systemId, timeZone)
    message.success("Retreive Successfully", 3);
    dispatch({
      type: GET_AV_RESPONSE_TIME_STAT_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    message.error('An Error occurred while retreiving data', 3);
    dispatch({
      type: GET_AV_RESPONSE_TIME_STAT_FAILURE,
      payload: err,
    });
  }
};
export const getDbGrowthStat = (customerID, startDate, endDate, systemId, timeZone) => async (dispatch) => {
  try {
    dispatch({
      type: DBGROWTH_LOAD,
    });
    const res = await statisticService.getDbGrowthStatistics(customerID, startDate, endDate, systemId, timeZone)
    message.success("Retreive Successfully", 3);
    dispatch({
      type: GET_DBGROWTH_STAT_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    message.error('An Error occurred while retreiving data', 3);
    dispatch({
      type: GET_DBGROWTH_STAT_FAILURE,
      payload: err,
    });
  }
};
