import http from "../api";

const getCustomerContactByCustomer = (idCustomer, page, rowsPerPage, searchValue) => {
  if(searchValue){
    return http.get(
      `/api/customerContact/byCustomer/${idCustomer}?page=${page}&size=${rowsPerPage}&searchValue=${searchValue}`
    );
  }
  else{
    return http.get(
      `/api/customerContact/byCustomer/${idCustomer}?page=${page}&size=${rowsPerPage}`
    );
  }
};
const createCustomerContact = (customerContact) => {
  return http.post(`/api/customerContact/`, customerContact);
};
const updateCustomerContact = (idContact, customerContact) => {
  return http.patch(`/api/customerContact/${idContact}`, customerContact);
};
const getOneCustomerContact = (idCustomerContact) => {
  return http.get(`/api/customerContact/${idCustomerContact}/`);
};
const deleteCustomerContact = (idCustomerContact) => {
  return http.patch(`/api/customerContact/delete/${idCustomerContact}`);
};
const addContactToSystem = (id, customerContactId) => {
  return http.post(`/api/customerContact/addToSystem/${id}`, customerContactId);
};
const unassignContactToSystem = (id, customerContactId) => {
  return http.patch(`/api/customercontact/deleteFromSystem/${id}/customerContact/${customerContactId}`);
};
const getCustomerContactUnassignedToSystem = (idCustomer,idSystem) => {
  return http.get(`/api/customercontact/UnAssignedCustomerContact/customer/${idCustomer}/system/${idSystem}`);
};
const CustomerContactService = {
  getCustomerContactByCustomer,
  createCustomerContact,
  updateCustomerContact,
  getOneCustomerContact,
  deleteCustomerContact,
  addContactToSystem,
  unassignContactToSystem,
  getCustomerContactUnassignedToSystem,
};
export default CustomerContactService;
