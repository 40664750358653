import React from 'react';
import useItOperations from '../logic/useItOperations';
import './ItOperations.css';

import Timeline from 'react-calendar-timeline'; // execute this on terminal to fix old naming -> npx react-codemod rename-unsafe-lifecycles CustomHeader js in pkg node module
import 'react-calendar-timeline/lib/Timeline.css';


const ItOperations = () => {

  const {systems, operations, defaultconfig} = useItOperations();

  return (
    <>
      <section className="TitlePage">IT Operations</section>

      <section className="avxWrpContentPage itOperationsPage">
        {defaultconfig && <Timeline groups={systems} items={operations} {...defaultconfig} />}
      </section>
    </>
  );
}

export default ItOperations;
