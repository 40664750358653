import types from "./types";
import commentService from "../services/commentService";

const {
    RETREIVE_COMMENT_SUCCESS,
    RETREIVE_COMMENT_FAILURE,
    ADD_COMMENT_SUCCESS,
    ADD_COMMENT_FAILURE,
    DELETE_COMMENT_SUCCESS,
    DELETE_COMMENT_FAILURE,
    UPDATE_COMMENT_SUCCESS,
    UPDATE_COMMENT_FAILURE,
    BLOCK_COMMENT_SUCCESS,
    BLOCK_COMMENT_FAILURE,
    UNBLOCK_COMMENT_SUCCESS,
    UNBLOCK_COMMENT_FAILURE,
    LOAD_COMMENT
    
} = types.feedTypes;

export const AddComment = (PostId,newcomment) => async (dispatch) => {
    try {
        const res = await commentService.addNewComment(PostId,newcomment)
        dispatch({
            type: ADD_COMMENT_SUCCESS,
            payload: res.data.data
        })
        return res.data.data;
    } catch (err) {
        dispatch({
            type: ADD_COMMENT_FAILURE,
            payload: err
        });
        return null;
    }
}
export const getAllCommentByPostID = (postId) => async (dispatch) => {
    try {
        dispatch({
            type: LOAD_COMMENT,
        })
        const res = await commentService.getAllCommentByPostId(postId);
        dispatch({
            type: RETREIVE_COMMENT_SUCCESS,
            payload: res.data.data
        })
     return res.data.data;
    } catch (err) {
        dispatch({
            type: RETREIVE_COMMENT_FAILURE,
            payload: err
        });
        return err;
    }
}

export const deleteComment = (commentId) => async (dispatch) => {
    try {
        const res = await commentService.deleteComment(commentId);
        dispatch({
            type: DELETE_COMMENT_SUCCESS,
            payload: res
        })
        return res.data.data;
    } catch (err) {
        dispatch({
            type: DELETE_COMMENT_FAILURE,
            payload: err
        });
        return err;
    }
}
export const updateComment = (commentId,newPost) => async (dispatch) => {
    try {
        const res = await commentService.updateComment(commentId,newPost);
        dispatch({
            type: UPDATE_COMMENT_SUCCESS,
            payload: res
        })
        return res.data.data;
    } catch (err) {
        dispatch({
            type: UPDATE_COMMENT_FAILURE,
            payload: err
        });
        return err;
    }
}

export const blockComment = (commentId) => async (dispatch) => {
    try {
        const res = await commentService.blockComment(commentId);
        dispatch({
            type: BLOCK_COMMENT_SUCCESS,
            payload: res
        })
        return res.data.data;
    } catch (err) {
        dispatch({
            type: BLOCK_COMMENT_FAILURE,
            payload: err
        });
        return err;
    }
}
export const unblockComment = (commentId) => async (dispatch) => {
    try {
        const res = await commentService.unblockComment(commentId);
        dispatch({
            type: UNBLOCK_COMMENT_SUCCESS,
            payload: res
        })
        return res.data.data;
    } catch (err) {
        dispatch({
            type: UNBLOCK_COMMENT_FAILURE,
            payload: err
        });
        return err;
    }
}

