import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import "./assets/css/App.css";

import "./assets/js/scripts";

import AppRouter from "./routes/AppRouter";
import Login from "./pages/login/Login.jsx";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import NotFound from "./layout/NotFound";

function App() {
  const loggedIn = useSelector((state) => state.authentication.loggedIn);
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    setPageLoading(false);
  }, []);

  if (pageLoading) return null;

  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return !loggedIn ? <Login /> : <Redirect to="/app/home" />;
          }}
        />
        <Route
          path="/app"
          render={() => {
            return !loggedIn ? <Login /> : <AppRouter />;
          }}
        />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
