import React from 'react';
import { Table } from 'reactstrap';

const DataSetHistory = ({schema, dataSet}) => {
  return (
    <Table className="avxBsTable avxBsTableSmall">
      <thead>
        <tr>
          { schema && Object.entries(schema).map(([key, value]) => <th key={key}>{value}</th>) }
        </tr>
      </thead>
      <tbody>
        {
          dataSet && dataSet.map((dsLine, index) => (
            <tr key={index}>
              {Object.keys(schema).map(
                key => <td key={key}>{dsLine[key] || "---"}</td>
              )}
            </tr>
          ))
        }
      </tbody>
    </Table>
  )
}

export default DataSetHistory;