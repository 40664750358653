const LOAD_CUSTOMERS_TASK = "LOAD_CUSTOMERS_TASK";
const RETRIEVE_CUSTOMERS_SUCCESS = "RETRIEVE_CUSTOMERS_SUCCESS";
const RETRIEVE_CUSTOMERS_FAILURE = "RETRIEVE_CUSTOMERS_FAILURE";

const LOAD_SYSTEMS_TASK = "LOAD_SYSTEMS_TASK";
const RETRIEVE_SYSTEMS_SUCCESS = "RETRIEVE_SYSTEMS_SUCCESS";
const RETRIEVE_SYSTEMS_FAILURE = "RETRIEVE_SYSTEMS_FAILURE";

const LOAD_CATEGORIES_TASK = "LOAD_CATEGORIES_TASK";
const RETRIEVE_CATEGORIES_SUCCESS = "RETRIEVE_CATEGORIES_SUCCESS";
const RETRIEVE_CATEGORIES_FAILURE = "RETRIEVE_CATEGORIES_FAILURE";

const LOAD_TYPES_TASK = "LOAD_TYPES_TASK";
const RETRIEVE_TYPES_SUCCESS = "RETRIEVE_TYPES_SUCCESS";
const RETRIEVE_TYPES_FAILURE = "RETRIEVE_TYPES_FAILURE";

const LOAD_MEMBERS_TASK = "LOAD_MEMBERS_TASK";
const RETRIEVE_MEMBERS_SUCCESS = "RETRIEVE_MEMBERS_SUCCESS";
const RETRIEVE_MEMBERS_FAILURE = "RETRIEVE_MEMBERS_FAILURE";

const LOAD_TASK = "LOAD_TASK";
const RETRIEVE_TASKS_SUCCESS = "RETRIEVE_TASKS_SUCCESS";
const RETRIEVE_TASKS_FAILURE = "RETRIEVE_TASKS_FAILURE";

const ADD_TASKS_SUCCESS = "ADD_TASKS_SUCCESS";
const ADD_TASKS_FAILURE = "ADD_TASKS_FAILURE";
const ADD_ONE_TASK_SUCCESS = "ADD_ONE_TASK_SUCCESS";
const ADD_ONE_TASK_FAILURE = "ADD_ONE_TASK_FAILURE";

const LOAD_SYSTEMS_FOR_MAPS = "LOAD_SYSTEMS_FOR_MAPS";
const RETRIEVE_SYSTEMS_FOR_MAPS_SUCCESS = "RETRIEVE_SYSTEMS_FOR_MAPS_SUCCESS";
const RETRIEVE_SYSTEMS_FOR_MAPS_FAILURE = "RETRIEVE_SYSTEMS_FOR_MAPS_FAILURE";

const GET_FILTRED_TASKS_SUCCESS = "GET_FILTRED_TASKS_SUCCESS";
const GET_FILTRED_TASKS_FAILURE = "GET_FILTRED_TASKS_FAILURE";
const LOAD_FILTRED_TASK = "LOAD_FILTRED_TASK";

const GET_TASKS_BY_USER_ID_SUCCESS = "GET_TASKS_BY_USER_ID_SUCCESS";
const GET_TASKS_BY_USER_ID_FAILURE = "GET_TASKS_BY_USER_ID_FAILURE";

const GET_FILTRED_TASKS_REQUEST_SUCCESS = "GET_FILTRED_TASKS_REQUEST_SUCCESS";
const GET_FILTRED_TASKS_REQUEST_FAILURE = "GET_FILTRED_TASKS_REQUEST_FAILURE";
const GET_CREATED_TASKS_SUCCESS = "GET_CREATED_TASKS_SUCCESS";
const GET_CREATED_TASKS_FAILURE = "GET_CREATED_TASKS_FAILURE";
const LOAD_CREATED_TASK = "LOAD_CREATED_TASK";

const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS";
const UPDATE_TASK_FAILURE = "UPDATE_TASK_FAILURE";

const GET_TASKS_REQUEST_BY_USER_ID_SUCCESS = "GET_TASKS_REQUEST_BY_USER_ID_SUCCESS";
const GET_TASKS_REQUEST_BY_USER_ID_FAILURE = "GET_TASKS_REQUEST_BY_USER_ID_FAILURE";


const LOAD_SYSTEM_STATS = "LOAD_SYSTEM_STATS";
const GET_SYSTEM_STATS_SUCCESS = "GET_SYSTEM_STATS_SUCCESS";
const GET_SYSTEM_STATS_FAILURE = "GET_SYSTEM_STATS_FAILURE";

const LOAD_SYSTEM_FOR_HIGHTLIGHT_STATS = "LOAD_SYSTEM_FOR_HIGHTLIGHT_STATS";
const GET_SYSTEM_STATS_FOR_HIGHTLIGHT_SUCCESS = "GET_SYSTEM_STATS_FOR_HIGHTLIGHT_SUCCESS";
const GET_SYSTEM_STATS_FOR_HIGHTLIGHT_FAILURE = "GET_SYSTEM_STATS_FOR_HIGHTLIGHT_FAILURE";

const GET_SYSTEM_STATS_FILTER_SUCCESS = "GET_SYSTEM_STATS_FILTER_SUCCESS";
const GET_SYSTEM_STATS_FILTER_FAILURE = "GET_SYSTEM_STATS_FILTER_FAILURE";

const GET_ONE_TASK_REQUEST_BY_USER_ID_SUCCESS = "GET_ONE_TASK_REQUEST_BY_USER_ID_SUCCESS";
const GET_ONE_TASK_REQUEST_BY_USER_ID_FAILURE = "GET_ONE_TASK_REQUEST_BY_USER_ID_FAILURE";

const LOAD_ONE_TASK = "LOAD_ONE_TASK";

const UPDATE_TASKS_REQUEST_BY_USER_ID_SUCCESS = "UPDATE_TASKS_REQUEST_BY_USER_ID_SUCCESS";
const UPDATE_TASKS_REQUEST_BY_USER_ID_FAILURE = "UPDATE_TASKS_REQUEST_BY_USER_ID_FAILURE";

const LOAD_WORKFLOW_TASK = "LOAD_WORKFLOW_TASK";
const RETRIEVE_WORKFLOW_TASKS_SUCCESS = "RETRIEVE_WORKFLOW_TASKS_SUCCESS";
const RETRIEVE_WORKFLOW_TASKS_FAILURE = "RETRIEVE_WORKFLOW_TASKS_FAILURE";



const WORKFLOW_CLOSE_TASKS_SUCCESS = "WORKFLOW_CLOSE_TASKS_SUCCESS";
const WORKFLOW_CLOSE_TASKS_FAILURE = "WORKFLOW_CLOSE_TASKS_FAILURE";

const GET_ALL_TASKS_BY_CRITERIA_SUCCESS = "GET_ALL_TASKS_BY_CRITERIA_SUCCESS";
const GET_ALL_TASKS_BY_CRITERIA_FAILURE = "GET_ALL_TASKS_BY_CRITERIA_FAILURE";
const LOAD_TASKS_BY_CRITERIA = "LOAD_TASKS_BY_CRITERIA";

const CLEAR_TASK_TRACKING = "CLEAR_TASK_TRACKING";

const CLEAR_MY_TASKS = "CLEAR_MY_TASKS";
const CLEAR_TASK_MANAGEMENT = "CLEAR_TASK_MANAGEMENT";

const GET_ALL_TASKS_ACTIVITY_LOG_SUCCESS = "GET_ALL_TASKS_ACTIVITY_LOG_SUCCESS";
const GET_ALL_TASKS_ACTIVITY_LOG_FAILURE = "GET_ALL_TASKS_ACTIVITY_LOG_FAILURE";
const LOAD_TASKS_ACTIVITY_LOG = "LOAD_TASKS_ACTIVITY_LOG";

const CREATE_SYNC_TASKS_ACTIVITY_LOG_SUCCESS = "CREATE_SYNC_TASKS_ACTIVITY_LOG_SUCCESS";
const CREATE_SYNC_TASKS_ACTIVITY_LOG_FAILURE = "CREATE_SYNC_TASKS_ACTIVITY_LOG_FAILURE";
const LOAD_SYNC_TASKS_ACTIVITY_LOG_FOR_CREATE = "LOAD_SYNC_TASKS_ACTIVITY_LOG_FOR_CREATE";

const CLEAR_TASKS_ACTIVITY_LOG = "CLEAR_TASKS_ACTIVITY_LOG";

let taskTypes = {
  RETRIEVE_CUSTOMERS_FAILURE,
  RETRIEVE_CUSTOMERS_SUCCESS,
  LOAD_CUSTOMERS_TASK,

  LOAD_SYSTEMS_TASK,
  RETRIEVE_SYSTEMS_SUCCESS,
  RETRIEVE_SYSTEMS_FAILURE,

  LOAD_CATEGORIES_TASK,
  RETRIEVE_CATEGORIES_SUCCESS,
  RETRIEVE_CATEGORIES_FAILURE,

  LOAD_TYPES_TASK,
  RETRIEVE_TYPES_SUCCESS,
  RETRIEVE_TYPES_FAILURE,

  LOAD_MEMBERS_TASK,
  RETRIEVE_MEMBERS_SUCCESS,
  RETRIEVE_MEMBERS_FAILURE,

  LOAD_TASK,
  RETRIEVE_TASKS_SUCCESS,
  RETRIEVE_TASKS_FAILURE,

  ADD_TASKS_SUCCESS,
  ADD_TASKS_FAILURE,

  ADD_ONE_TASK_SUCCESS,
  ADD_ONE_TASK_FAILURE,

  LOAD_SYSTEMS_FOR_MAPS,
  RETRIEVE_SYSTEMS_FOR_MAPS_SUCCESS,
  RETRIEVE_SYSTEMS_FOR_MAPS_FAILURE,

  LOAD_FILTRED_TASK, //need to hnage this

  GET_FILTRED_TASKS_SUCCESS,
  GET_FILTRED_TASKS_FAILURE,

  GET_TASKS_BY_USER_ID_SUCCESS,
  GET_TASKS_BY_USER_ID_FAILURE,

  GET_FILTRED_TASKS_REQUEST_SUCCESS,
  GET_FILTRED_TASKS_REQUEST_FAILURE,

  GET_TASKS_REQUEST_BY_USER_ID_SUCCESS,
  GET_TASKS_REQUEST_BY_USER_ID_FAILURE,
  GET_CREATED_TASKS_SUCCESS,
  GET_CREATED_TASKS_FAILURE,
  LOAD_CREATED_TASK,
  
  UPDATE_TASK_SUCCESS,
  UPDATE_TASK_FAILURE,


  LOAD_SYSTEM_STATS,
  GET_SYSTEM_STATS_SUCCESS,
  GET_SYSTEM_STATS_FAILURE,

  LOAD_SYSTEM_FOR_HIGHTLIGHT_STATS,
  GET_SYSTEM_STATS_FOR_HIGHTLIGHT_SUCCESS,
  GET_SYSTEM_STATS_FOR_HIGHTLIGHT_FAILURE,

  GET_SYSTEM_STATS_FILTER_SUCCESS,
  GET_SYSTEM_STATS_FILTER_FAILURE,

  GET_ONE_TASK_REQUEST_BY_USER_ID_SUCCESS,
  GET_ONE_TASK_REQUEST_BY_USER_ID_FAILURE,

  LOAD_ONE_TASK,

  UPDATE_TASKS_REQUEST_BY_USER_ID_SUCCESS,
  UPDATE_TASKS_REQUEST_BY_USER_ID_FAILURE,
  LOAD_WORKFLOW_TASK,
  RETRIEVE_WORKFLOW_TASKS_SUCCESS,
  RETRIEVE_WORKFLOW_TASKS_FAILURE,

  WORKFLOW_CLOSE_TASKS_SUCCESS,
  WORKFLOW_CLOSE_TASKS_FAILURE,

  GET_ALL_TASKS_BY_CRITERIA_SUCCESS,
  GET_ALL_TASKS_BY_CRITERIA_FAILURE,
  LOAD_TASKS_BY_CRITERIA,

  CLEAR_TASK_TRACKING,
  CLEAR_MY_TASKS,
  CLEAR_TASK_MANAGEMENT,

  GET_ALL_TASKS_ACTIVITY_LOG_SUCCESS,
  GET_ALL_TASKS_ACTIVITY_LOG_FAILURE,
  LOAD_TASKS_ACTIVITY_LOG,

  CREATE_SYNC_TASKS_ACTIVITY_LOG_SUCCESS,
  CREATE_SYNC_TASKS_ACTIVITY_LOG_FAILURE,
  LOAD_SYNC_TASKS_ACTIVITY_LOG_FOR_CREATE,

  CLEAR_TASKS_ACTIVITY_LOG




  
};
export default taskTypes;
