import React, { useState,useEffect } from "react";
import { FiPlusSquare } from "react-icons/fi";
import { useSelector,useDispatch } from "react-redux";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { processTableHeight } from "../../../assets/js/CoreJs";
import {
  Container,
  Row,
  Collapse,
  Col,
  Button,
  Card,
  CardBody,
  FormGroup,
  Form
} from "reactstrap";
import './Feed.css';
import FeedPic from '../../../assets/images/w-portrait.jpg';
import moment from "moment";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./compoenents/commentYupValidation"
import { dislike, getAllPosts, like, publishPost ,deletePost,updatePost, blockPost, unblockPost} from "../../../actions/feedAction";
import PostCard from "./compoenents/PostCard";
//! ////////////////////////////////////
////*           Const DATA         *////
//! ////////////////////////////////////

function Feed() {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
} = useForm({
    resolver: yupResolver(schema),
});
  const [isOpen, setIsOpen] = useState(false); // open and close add status
  const dispatch = useDispatch()
  const user = useSelector(state => state.user.user)
  const isLoadingPost = useSelector(state => state.feedReducer.isLoadingPost)
  let AllPosts = useSelector(state => state.feedReducer.AllPosts.docs)

  //!
  ///////////////////////////////*        Utils Functions       */////////////////////////////////////////////////////
  //!

  //********************** Like Button ************************ */

  const HandleLike=async(postId,index) => {
    if(user){  
if(existUSer(index)){
  await dispatch(dislike(user._id,postId))
}else{
  await dispatch(like(user._id,postId))
}
await dispatch(getAllPosts()) 
  }
  }

  const toggleAdd = () => {
    setIsOpen(!isOpen);
    reset()
  }

  const handlePublish=async({Comment})=> {
    if(user){
    const newPost = {
      user: user._id,
      title: user.Roles[0].label,
      text: Comment,
    };
    await dispatch(publishPost(newPost))
    await dispatch(getAllPosts())
    toggleAdd()
    reset()}
  }
  useEffect(() => {
  dispatch(getAllPosts()) 
}, [dispatch])


const existUSer=(index)=>{
  if(user){
    const output = AllPosts[index].likes.filter((element)=>element.user===user._id)
    return  output.length=== 0 ? false : true
  }
}

const handleBlock = async (postId) => {
  if(window.confirm("Do you want to block this post?")){
    await dispatch(blockPost(postId));
    await dispatch(getAllPosts());
  }
}

const handleUnblock = async(postId) => {
  if(window.confirm("Do you want to unblock this post?")){
    await dispatch(unblockPost(postId));
    await dispatch(getAllPosts());
  }
}

const Delete_Post=async(postId)=> {
  if(window.confirm("Do you want to delete this note ?")){
   await dispatch(deletePost(postId))
   await dispatch(getAllPosts())
  }
}
const applyTabHeight = () => {
   
  processTableHeight(
    ["feedHeader","collapssAdd"],
    "feedStreamsize",
     90 
  );
};
useEffect(() => {
  applyTabHeight();
  window.addEventListener("resize", applyTabHeight);
  return () => {
    window.removeEventListener("resize", applyTabHeight);
  }; 
}, []);
const isAdmin = user?.Roles?.some((role) => role.label.toString() === 'Administrator');
const filteredPosts = AllPosts && AllPosts.filter((f) => (isAdmin || f?.user?._id.toString() === user?._id ) ? true : f.isBlocked !== true);

  return (
    <div className="homeFeed">
      <Container>
        {/* ******************** Header Search and add button*************************** */}
    <Row className="feedHeader">
           <>
            <Col xs="2">
            <Button color="primary" onClick={toggleAdd}>
              <FiPlusSquare style={{height:"20px",width:"20px"}}/>
            </Button>
          </Col>
          <Col xs={{ size: 9, offset: 1 }}>
          {/* {AllPosts && AllPosts.length !== 0 && (
              <Row>
                <Col>
                  <Input placeholder="Search" style={{ border: "0" }} />
                  </Col>
              </Row>
          )} */}
          </Col>
          </>
        </Row>
        {/* ******************** Add Note field with publish button*************************** */}
        <Row className="feedAddBloc">
          <Col className="collapssAdd">
            <Collapse isOpen={isOpen} >
              <Card>
                <CardBody>
                  <Row>
                    <Col xs="2" className="cardPic">
                      <div className="newNotePic">
                        <img src={FeedPic} alt="" />
                      </div>
                    </Col>
                    <Col className="cardContent">
                      <FormGroup>
                      <Form>
                      <textarea
                                name="Comment"
                                placeholder={errors.Comment ? errors.Comment.message : "Enter comment..."}
                                className={
                                    "form-control" + (errors.Comment ? " is-invalid" : "")
                                }
                                {...register("Comment")}
                            />
                            <small className="invalid-feedback">
                                {errors.title && <>{errors.title.message}</>}
                            </small>
                      </Form>
                      <div style={{float:"right",marginTop:"12px"}}>
                        <Button
                          size="sm"
                          color="primary"
                          onClick={handleSubmit(handlePublish)}
                        >
                        Publish
                        </Button> <Button
                          size="sm"
                          onClick={toggleAdd}
                        >
                          Cancel
                        </Button>
                      </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Collapse>
      
          </Col>
        </Row>
        {/* ******************** List Post *************************** */}

        <Row className="feedStream" id="feedStreamsize">
          <Col>
            {isLoadingPost !== false ? (
              <>
                {[...Array(3)].map((e, i) =>
                  <div style={{ paddingBottom: "12px" }} key={i}>
                    <div className="status">
                      <Row className="statusTopper">
                        <Col>
                          <Skeleton width={200} count={1} />
                        </Col>
                      </Row>
                      <Row className="statusBody">
                        <Col className="cardPic" xs="3">
                          <Skeleton
                            style={{
                              height: "70px",
                              width: "70px",
                            }}
                            count={1}
                          />
                          <Skeleton width={68} count={1} />
                        </Col>
                        <Col className="cardContent">
                          <Skeleton count={3} />
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
              {filteredPosts&&filteredPosts.length !== 0 ? (
                <>{filteredPosts
                  .sort((a,b)=>moment(b.createdAt)-moment(a.createdAt))
                  .map((feed, index) => {
                  return (
                    <div key={index} className="singleFeed">
                    <PostCard
                    index={index}
                    feed={feed}
                    Delete_Post={Delete_Post}
                    FeedPic={FeedPic}
                    HandleLike={HandleLike}
                    existUSer={existUSer}
                    dispatch={dispatch}
                    updatePost={updatePost}
                    getAllPosts={getAllPosts}
                    AllPosts={filteredPosts}
                    handleBlock={handleBlock}
                    user={user}
                    handleUnblock={handleUnblock}
                    />
                    </div>
                  );
                })}</>) : (
                    <div className="EmptyContent">
                  <div>
                  <p>No note added during this period !</p>
                  <p>Click "+" to add one</p>
                  </div>
                  </div>
               
              )}</>

            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Feed;
