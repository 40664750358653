import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import "./TaskTracking.css";

import AllMonitoringActivitiesDetaills from "./components/MonitoringActivitiesDetaill/AllMonitoringActivitiesDetaills.jsx";
import SummaryStatistique from "./components/summary/SummaryStatistique.jsx"
import TaskPlanification from "./components/taskPlanification/TaskPlanification";

export default function TaskTracking() {
    const [activeTab, setActiveTab] = useState("TA");
    const toggle = (tab) => {
        if (tab === "TA") {
        }
        if (activeTab !== tab) setActiveTab(tab);
      };

  return (
    
    <>
    <section className="TitlePage">
      Task Tracking
      {/* <div className="Page_Description">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eligendi
        reiciendis veniam veli
      </div> */}
    </section>

    <section className="avxWrpContentPage taskTrackingPage">
      <Nav tabs className="taskTrackingTabsNav">
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "TA" })}
            onClick={() => {
              toggle("TA");
            }}
          >
            Tasks
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "SU" })}
            onClick={() => {
              toggle("SU");
            }}
          >
           Summary
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "TP" })}
            onClick={() => {
              toggle("TP");
            }}
          >
           Task Planification
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent className="taskTrackingTabsContent">
        {
          {
            TA: (
              <TabPane className="AllMonitoringActivitiesDetaillsTab">
                <AllMonitoringActivitiesDetaills />
              </TabPane>
            ),
            SU: (
              <TabPane className="SummaryStatistiqueTab">
                <SummaryStatistique />
              </TabPane>
            ),
            TP: (
              <TabPane>
              <TaskPlanification/>
              </TabPane>
            ),
          }[activeTab]
        }
      </TabContent>
    </section>
  </>
  )
}
