import http from "../api";

const addOneMTP = (newMTP,timeZone) => {
  const headers = {
    "Content-type": "application/json",
    "x-auth-accessToken": localStorage.getItem('ma-access-token'),
    "x-auth-refreshToken": localStorage.getItem('ma-refresh-token')
  }

  return http.post(`/api/mtp/?timeZone=${timeZone}`, newMTP, {headers});
};

  const getAllMTP = (page, size, isActive, searchValue) => {
    if (isActive){
        return searchValue ? http.get(`/api/mtp?page=${page}&size=${size}&isActive=${isActive}&searchValue=${searchValue}`) : http.get(`/api/mtp?page=${page}&size=${size}&isActive=${isActive}`);
    }
    else{
        return searchValue ? http.get(`/api/mtp?page=${page}&size=${size}&searchValue=${searchValue}`) : http.get(`/api/mtp?page=${page}&size=${size}`);
    }
  };

const deleteMTP = (id) => {
    return http.patch(`/api/mtp/delete/${id}`);
};

const getOneMTP = (id) => {
  return http.get(`/api/mtp/${id}`);
};

const updateMTP = (id,timeZone, mtp) => {
  const headers = {
    "Content-type": "application/json",
    "x-auth-accessToken": localStorage.getItem('ma-access-token'),
    "x-auth-refreshToken": localStorage.getItem('ma-refresh-token')
  }
  
  return http.patch(`/api/mtp/update/${id}?timeZone=${timeZone}`, mtp, {headers});
};

const taskService = {

    addOneMTP,
    getAllMTP,
    deleteMTP,
    getOneMTP,
    updateMTP
  
  };
  export default taskService;