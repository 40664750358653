import React, { useState, useEffect } from "react";
import "./userList.css";
import { BsPencil, BsTrash } from "react-icons/bs";
// import ReactCardFlip from "react-card-flip";
import { Switch } from "antd";
import { Row, Col, Input, Spinner, Button,Table,ButtonGroup } from "reactstrap";
import UserCard from "./components/UserCard";
import { TablePagination } from "@material-ui/core";

import { FiGrid } from "react-icons/fi";
import { FiList } from "react-icons/fi";

import { useDispatch, useSelector } from "react-redux";
import { retrieveUsers, clearUsers, clearOneUser } from "../../actions/userManagementAction";
import { clearRoles } from "../../actions/roleAction";
import { processTableHeight, makeDelay } from "../../assets/js/CoreJs";
import EditUser from "./components/EditUser";
//::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

//::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

import {
  changeStatusUser,
  deleteUser,
} from "../../actions/userManagementAction";

//! ////////////////////////////////////
////*           Const DATA         *////
//! ////////////////////////////////////

const UserList = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.userManagement.users.data);
  //console.log("List users ===>",users);
  const totalDocs = useSelector(
    (state) => state.userManagement.users.totalDocs
  );
  //console.log("Total Docs ===>",totalDocs)
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [page, setPage] = useState(0);
  const [refrechData, setrefrechData] = useState(false);
  // const [searchTerm, setSearchTerm] = useState("");
  const userLoading = useSelector((state) => state.userManagement.areLoading);
  const [searchValue, setSearchValue] = useState("");
  //const isLoading = useSelector((state) => state.userManagement.isLoading);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setrefrechData(!refrechData);
    applyTabHeight();
    window.addEventListener("resize", applyTabHeight);
    
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setrefrechData(!refrechData);
    applyTabHeight();
    window.addEventListener("resize", applyTabHeight);
    
  };

  useEffect(() => {
    dispatch(retrieveUsers(page, rowsPerPage, searchValue));
  }, [dispatch, page, rowsPerPage, searchValue, refrechData]);

  //******************************************************* */
  // function handleFlipCard(index) {
  //   const values = [...ListUser];
  //   values[index].isFlipped = !values[index].isFlipped;
  //   setListUser(values);
  // }
  //******************************************************* */

  //! ////////////////////////////////////
  ////*       Return Result JSX      *////
  //! ////////////////////////////////////
  const [ShowData, setShowData] = useState("Card")



const handelSearshQuerry = (e) => {
  makeDelay(() => {
    if (e.target.value.length >= 2) {
      setSearchValue(e.target.value);
      setPage(0);
    } else {
      setSearchValue("");
    }
  }, 750);
};

useEffect(() => {
  return () => clearTimeout(window.timerDelay);
}, []);


 const applyTabHeightCard = () => {
    processTableHeight(
      ["TitlePage", "searchBar","Pagination_User"],
      "UserManagementUserCard",
      5
    );
  };
  const applyTabHeight = () => {
    processTableHeight(
      ["TitlePage", "searchBar","Pagination_User"],
      "UserManagementUserTCONT",
      5
    );
  };
  useEffect(() => {
    applyTabHeight();
    applyTabHeightCard();
    window.addEventListener("resize", applyTabHeight);
    window.addEventListener("resize", applyTabHeightCard);
    return () => {
      window.removeEventListener("resize", applyTabHeight);
      window.removeEventListener("resize", applyTabHeightCard);
    };
  }, []);
  useEffect(() => {
    applyTabHeight();
    applyTabHeightCard();
    window.addEventListener("resize", applyTabHeight);
    window.addEventListener("resize", applyTabHeightCard);
    return () => {
      window.removeEventListener("resize", applyTabHeight);
      window.removeEventListener("resize", applyTabHeightCard);
    };
  }, [ShowData]);
  const confirmDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this user ?")) {
      dispatch(deleteUser(id, page, rowsPerPage));
      setrefrechData(!refrechData);
    }
    // applyTabHeight();
  };
  
  

  let r;
  function onChangeStatus(user,status) {
    r = window.confirm(
      "Are you sure you want to change status for this User ?"
    );
    if (r === true) {
      
      // setStatus(!status)
      dispatch(changeStatusUser(user._id, !status, page, rowsPerPage));
    }
  }

  //Clear Data 
  useEffect(() => {
    return () => {

      dispatch(clearUsers());
      dispatch(clearOneUser());
      dispatch(clearRoles());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return (
    <>
      <section className="TitlePage">
        Users Management
        {/* <div className="Page_Description">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eligendi
          reiciendis veniam veli
        </div> */}
      </section>

      <section className="avxWrpContentPage userListPage Container_User_List">
        <Row className="searchBar">
          <Col sm="1">
            <ButtonGroup className="buttonGroup">
              <Button
                outline
                color="primary"
                onClick={() => setShowData("Card")}
              >
                <FiGrid style={{ fontSize: "20px" }} />
              </Button>
              <Button
                outline
                color="primary"
                onClick={() => setShowData("Table")}
              >
                <FiList style={{ fontSize: "20px" }} />
              </Button>
            </ButtonGroup>
          </Col>
          <Col sm="4">
            <Input
              type="search"
              className="form-control"
              placeholder="Search by Name, email, role"
              onChange={handelSearshQuerry}
            />
          </Col>
        </Row>

        <div
          id="UserManagementUserCard"
          className={ShowData === "Card" ? "List_User_Content" : ""}
        >
          {userLoading ? (
        
              <div
              className="centerLoader"
            >
              <div>
              <Spinner color="dark" />
              </div>
            </div>
          
              
            
          ) : (
            <>
              {ShowData === "Card" ? (
                <Row>
                  {users?.length > 0 ? (
                    <>
                      {users?.map((user) => {
                        return (
                          <Col sm="3" className="mb-3" key={user._id}>
                            <UserCard
                              user={user}
                              page={page}
                              rowsPerPage={rowsPerPage}
                              retrieveUsers={retrieveUsers}
                              refrechData={refrechData}
                              setrefrechData={setrefrechData}
                              confirmDelete={confirmDelete}
                              searchValue={searchValue}
                            />
                          </Col>
                        );
                      })}
                    </>
                  ) : (
                    <div className="NoUsersFound">
                      <span>No Users Found</span>
                    </div>
                  )}
                </Row>
              ) : (
                <>
                  <div className="TableContainer">
                    <div
                      id="UserManagementUserTCONT"
                      className={
                        ShowData === "Card" ? "" : "avxTableContainer scrollTb"
                      }
                      style={{ height: "100%" }}
                    >
                      <Table className="avxBsTable twoActs userMgtTable" striped hover>
                        <thead className="headerTableListUser">
                          <tr>
                            <th>Status</th>
                            <th>First name</th>
                            <th>Last name</th>
                            <th>Email</th>
                            <th>Phone number</th>
                            <th>
                              <span>
                                <BsPencil />
                              </span>
                            </th>
                            <th>
                              <span>
                                <BsTrash />
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="">
                          {users?.length > 0 ? (
                            <>
                              {users.map((user, i) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      <span className="switchStatus">
                                        <Switch
                                          color={
                                            user.status ? "success" : "danger"
                                          }
                                          checked={user?.status}
                                          onChange={() =>
                                            onChangeStatus(user, user?.status)
                                          }
                                        />
                                      </span>
                                    </td>
                                    <td>{user.firstName}</td>
                                    <td>{user.lastName}</td>
                                    <td>{user.email}</td>
                                    <td>{user.phoneNumber}</td>
                                    <td>
                                      <EditUser
                                        user={user}
                                        page={page}
                                        rowsPerPage={rowsPerPage}
                                        setrefrechData={setrefrechData}
                                        refrechData={refrechData}
                                        searchValue={searchValue}
                                        //setSearchValue={setSearchValue}
                                      />
                                    </td>
                                    <td>
                                      <span
                                        className="tbBtn"
                                        onClick={() => confirmDelete(user._id)}
                                      >
                                        <BsTrash />
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </>
                          ) : (
                            <tr>
                              <td colSpan="7">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100%",
                                  }}
                                >
                                  <span> No users found </span>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>

        <TablePagination
          className="Pagination_User"
          rowsPerPageOptions={[12, 16, 20, 40, 80]}
          component="div"
          count={totalDocs ? totalDocs : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </section>
    </>
  );
};

export default UserList;
