import React, { useState, useEffect } from "react";
import { FiMail, FiPhone, FiMoreVertical } from "react-icons/fi";
import { Switch } from "antd";
import {
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardTitle,
  Dropdown,
  CardBody,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Spinner,
  Form,
  Button,
} from "reactstrap";
import Select from "../../../shared/select/Select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./EditUserYupValidation";


import {
  changeStatusUser,
} from "../../../actions/userManagementAction";
import { retrieveAllRole } from "../../../actions/roleAction";
import {
  RetreiveOneUser,
  updateUser,
} from "../../../actions/userManagementAction";

import { useDispatch, useSelector } from "react-redux";

export default function UserCard(props) {
  const { user, page, rowsPerPage, refrechData, setrefrechData ,confirmDelete,searchValue} = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [status, setStatus] = React.useState(user.status);

  const [role, setRole] = React.useState([]);
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  //! ////////////////////////////////////
  ////*        Utils Functions       *////
  //! ////////////////////////////////////
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  let r;
  function onChangeStatus() {
    r = window.confirm(
      "Are you sure you want to change status for this User ?"
    );
    if (r === true) {
      //setStatus((status) => !status);
      setStatus(!status)
      dispatch(changeStatusUser(user._id, !status, page, rowsPerPage));
    }
  }

  useEffect(() => {
    setRole(
      user?.Roles.map((role) => {
        return role?.role?.label;
      })
    );
  }, [user?.Roles]);

  //! ////////////////////////////////////
  ////*       Return Result JSX      *////
  //! ////////////////////////////////////
  const [editModal, setEditModal] = useState(false);

  const error = useSelector((state) => state.userManagement.listError);
  const [optionsRoleUser, setoptionsRoleUser] = useState([
    {
      value: "none",
      label: "none",
    },
  ]);

  const toggleEdit = async (e,id) => {
    e.preventDefault();
    reset();
    if (id) {
      const optionsRoleUser = await dispatch(retrieveAllRole(0, 1000,true, false));
      if (optionsRoleUser)
        setoptionsRoleUser(
          optionsRoleUser.map((value) => ({
            value: value._id,
            label: value.label,
          }))
        );

      const res = await dispatch(RetreiveOneUser(id));

      let roleList = [];
      if (res) {
        setValue("firstName", res.firstName);
        setValue("lastName", res.lastName);
        for (let i = 0; i < res.Roles.length; i++) {
          roleList.push(res.Roles[i]);
        }
        const oldRoles = roleList.map((value) => ({
          value: value._id,
          label: value.label,
        }));
        setValue("roles", oldRoles);
      }
    }
    setEditModal(!editModal);
  };

  function handleEdit({firstName,lastName,roles}) {
    const dbRoles = roles.map((value) => value.value);
    const updatedData = {
      firstName: firstName,
      lastName: lastName,
      roles: dbRoles,
    };
    dispatch(updateUser(user._id, updatedData, page, rowsPerPage,searchValue));
    if (!error) {
      setEditModal(!editModal);
      setrefrechData(!refrechData);
    } else {
      console.error(error);
    }
  }

  function toggleClose() {
    setEditModal(!editModal);
  }

  const selectInvalidFeedback = {
    width: "100%",
    marginTop: ".25rem",
    fontSize: "80%",
    color: "#dc3545",
  };

  if (errors.roles) {
    document.querySelector(".customerSelectRoles > div:nth-child(2)") &&
      document
        .querySelector(".customerSelectRoles > div:nth-child(2)")
        .classList.add("isInvalidSelect");
  }

  return (
    <>
      <Card key={user.id} className="singleUserCard" body>
        <div className="MenuIcon">
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle className="dropDownToggle" color="link">
              <FiMoreVertical />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={(e) => toggleEdit(e, user._id)}>
                Edit
              </DropdownItem>
              <DropdownItem onClick={() => confirmDelete(user._id)}>
                Delete{" "}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        <CardTitle>
          <span>
            {user?.firstName} {user?.lastName}
          </span>
        </CardTitle>
        <CardBody>
          <Row>
            <Col sm="9">
              <Row>
                <Col>
                  <FiPhone /> {user?.phoneNumber}
                </Col>
              </Row>
              <Row>
                <Col>
                  <FiMail /> {user?.email}
                </Col>
              </Row>
            </Col>
            <Col sm="3">
              <img
                className="img-circle img-responsive"
                src={
                  user.avatar
                    ? user.avatar
                    : "https://www.gravatar.com/avatar/1d2ab164559aaf8a30eebf516d2f63ad?s=200&r=pg&d=mm"
                }
                alt=""
              />
            </Col>
          </Row>
        </CardBody>

        <div className="Switch_State">
          <span className="brief">
            {" "}
            <Label>Roles : </Label> {role.join(" - ")}
          </span>
          <Switch checked={status} onChange={onChangeStatus} />
        </div>
      </Card>

      {/* Edit Modal */}

      <Modal
        isOpen={editModal}
        toggle={toggleClose}
        size="md"
        backdrop="static"
        keyboard={true}
        centered
        fade={false}
      >
        <Form onSubmit={handleSubmit(handleEdit)}>
          <ModalHeader toggle={toggleClose}>Edit User</ModalHeader>
          <ModalBody>
            {watch("firstName") && watch("lastName") ? (
              <>
                <FormGroup>
                  <Label>
                    First Name
                    <span className="requiredField"> *</span>
                  </Label>
                  <input
                    type="text"
                    className={
                      "form-control" + (errors.firstName ? " is-invalid" : "")
                    }
                    {...register("firstName")}
                    disabled
                  />
                  <small className="invalid-feedback">
                    {errors.firstName && <>{errors.firstName.message}</>}
                  </small>
                </FormGroup>

                <FormGroup>
                  <Label>
                    Last Name
                    <span className="requiredField"> *</span>
                  </Label>
                  <input
                    type="text"
                    className={
                      "form-control" + (errors.lastName ? " is-invalid" : "")
                    }
                    {...register("lastName")}
                    disabled
                  />
                  <small className="invalid-feedback">
                    {errors.lastName && <>{errors.lastName.message}</>}
                  </small>
                </FormGroup>

                <FormGroup>
                  <Label>
                    Roles
                    <span className="requiredField"> *</span>
                  </Label>
                  <Controller
                    name="roles"
                    control={control}
                    render={({ field: { ref, ...rest }, fieldState }) => (
                      <Select
                        className="customerSelectRoles"
                        options={optionsRoleUser}
                        isMulti={true}
                        {...rest}
                        {...fieldState}
                      />
                    )}
                  />
                  <small style={selectInvalidFeedback}>
                    {errors.roles && <>{errors.roles.message}</>}
                  </small>
                </FormGroup>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 100,
                }}
              >
                <Spinner color="dark" />
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Save Changes
            </Button>
            <Button onClick={() => setEditModal(!editModal)}>Cancel</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}
