import types from "./types";
import userManagementService from "../services/userManagementService";
import { message } from 'antd';
const {
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAILURE,
  LOAD_USERS,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_STATUS_FAILURE,
  LOAD_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  RETRIEVE_ONE_USER_SUCCESS,
  RETRIEVE_ONE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  CLEAR_USERS,
  CLEAR_ONE_USER
} = types.userManagementTypes;
export const retrieveUsers = (page, size, searchValue) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_USERS,
    });
    const res = await userManagementService.getAllUsers(page, size, searchValue);
    dispatch({
      type: GET_ALL_USERS_SUCCESS,
      payload: res.data,
    });
    //console.log("Data===>",res.data)
  } catch (err) {
    dispatch({
      type: GET_ALL_USERS_FAILURE,
      payload: err?.response?.data?.message,
    });
  }
};

export const changeStatusUser = (id, status) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_USER,
    });
    const res = await userManagementService.changeStatusUser(id, status);
    message.success("Updated Successfully",3);
    dispatch({
      type: UPDATE_USER_STATUS_SUCCESS,
      payload: res.data.data,
    });
    // if(res){
    //   const resAll = await userManagementService.getAllUsers(page, size);
    //   dispatch({
    //     type: GET_ALL_USERS_SUCCESS,
    //     payload: resAll.data,
    
    //   });
    // }else{
    //   //console.log("Error Update")
    // }
    // dispatch({
    //   type: LOAD_USERS,
    // });
  } catch (err) {
    message.error('An Error occurred while updating',3);
    dispatch({
      type: UPDATE_USER_STATUS_FAILURE,
      payload: err.response.data.message,
    });

    //return Promise.reject(err);
  }
};

export const deleteUser = (id, page, size) => async (dispatch) => {
  try {
    const res = await userManagementService.deleteUser(id);
    dispatch({
      type: LOAD_USER,
    });
      message.success("Deleted Successfully",3);
    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: res.data.data,
    });

    const resAll = await userManagementService.getAllUsers(page, size);
    dispatch({
      type: GET_ALL_USERS_SUCCESS,
      payload: resAll.data,
    });
  } catch (err) {
      message.error('An Error occurred while deleting',3);    
    dispatch({
      type: DELETE_USER_FAILURE,
      payload: err.response.data.message,
    });
  }
};

export const RetreiveOneUser = (id) => async (dispatch) => {
  try {
    const res = await userManagementService.getOneUser(id);
    dispatch({
      type: RETRIEVE_ONE_USER_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {
    dispatch({
      type: RETRIEVE_ONE_USER_FAILURE,
      payload: err.response.data.message,
    });
  }
};

export const updateUser = (id, data, page, size, searchValue) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_USERS,
    });

    const res = await userManagementService.updateUser(id, data);
    message.success("Updated Successfully",3);
    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: res.data.data,
    });
        if(res){
          
          const resAll = await userManagementService.getAllUsers(page, size, searchValue);
          dispatch({
            type: GET_ALL_USERS_SUCCESS,
            payload: resAll.data,

          });
        }else{
          //console.log("Error Update")
        }
    
  } catch (err) {
    message.error('An Error occurred while updating',3);
    //console.log(err)
    dispatch({
      type: UPDATE_USER_FAILURE,
      payload: err?.response?.data?.message,
    });
  }
};

//clear All
export const clearUsers = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_USERS,
  })
}

//clear One 
export const clearOneUser = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_ONE_USER,
  })
}
