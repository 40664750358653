import types from "./types";
import systemService from "../services/systemService";

const {
  RETRIEVE_SYSTEM_CATEGORIES_SUCCESS,
  RETRIEVE_SYSTEM_CATEGORIES_FAILURE,
  RETRIEVE_SYSTEM_TYPES_SUCCESS,
  RETRIEVE_SYSTEM_TYPES_FAILURE,
  LOAD_LANDSCAPE_TC,
  RETRIEVE_SYSTEMS_SUCCESS,
  RETRIEVE_SYSTEMS_FAILURE,
  LOAD_CATEGORIES_TC,
  LOAD_SYSTEM,
  CLEAR_SYSTEMS_TC
} = types.landscapeTCTypes;
export const RetreiveSystemTypesTC = (customerId) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_LANDSCAPE_TC,
    });
    const res = await systemService.retreiveTypesByCustomerId(customerId);
    dispatch({
      type: RETRIEVE_SYSTEM_TYPES_SUCCESS,
      payload: res.data.data,
    });
    return res;
  } catch (err) {
    dispatch({
      type: RETRIEVE_SYSTEM_TYPES_FAILURE,
      payload: err.response?.data.message,
    });
  }
};
export const RetreiveSystemCategories =
  (customerId, typeId) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_CATEGORIES_TC,
        payload: typeId,
      });
      const res = await systemService.retreiveCategoryByCustomerIdByTypeId(
        customerId,
        typeId
      );
      dispatch({
        type: RETRIEVE_SYSTEM_CATEGORIES_SUCCESS,
        payload: { data: res.data.data, typeId: typeId },
      });
      return res;
    } catch (err) {
      dispatch({
        type: RETRIEVE_SYSTEM_CATEGORIES_FAILURE,
        payload: typeId,
      });
    }
  };
export const RetreiveSystemByCustomerByCatgeroyByTypesTC =
  (customerId, categoryId, typeId) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_SYSTEM,
        payload: {
          categoryId: categoryId,
          typeId: typeId,
        },
      });
      const res =
        await systemService.getSystemsByCustomerIdByTypeIdByCategoryId(
          customerId,
          categoryId,
          typeId
        );
      dispatch({
        type: RETRIEVE_SYSTEMS_SUCCESS,
        payload: {
          data: res.data.data,
          categoryId: categoryId,
          typeId: typeId,
        },
      });
      return res;
    } catch (err) {
      dispatch({
        type: RETRIEVE_SYSTEMS_FAILURE,
        payload: {
          categoryId: categoryId,
          typeId: typeId,
        },
      });
    }
  };

  //clear All
 export const clearSystemsTC = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_SYSTEMS_TC,
  })
}
