import React, { useState } from "react";
import "../../adminPanel.css";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import MonitoringActResultFormAP from "./resultFormAPComponents/MonitoringActResultFormAP";

function ResultFormAP() {
    const [activeTab, setActiveTab] = useState("MaRF");
    const toggleRender = (tab) => {
      activeTab !== tab && setActiveTab(tab);
    };
  
    return (
      <div className="resultFormAP">
        {/* <Row className="adminPanelHeader"></Row> */}
  
        <div className="resultFormAPBody">
          <Nav tabs className="adminPanelChildTabsNav childTabForResultForm">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "MaRF" })}
                onClick={() => {
                  toggleRender("MaRF");
                }}
              >
                Result Form
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent className="adminPanelTabsContent">
            {
              {
                MaRF: (
                  <TabPane className="resultFormAPTab">
                    <MonitoringActResultFormAP />
                  </TabPane>
                ),
              }[activeTab]
            }
          </TabContent>
        </div>
      </div>
    );
}

export default ResultFormAP
