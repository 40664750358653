import types from "../actions/types";
const {
  CREATE_LICENCE_SUCCESS,
  GET_ALL_LICENCES_SUCCESS,
  UPDATE_LICENCE_SUCCESS,
  CREATE_LICENCE_FAILURE,
  GET_ALL_LICENCES_FAILURE,
  UPDATE_LICENCE_FAILURE,
  LOAD_LICENCE,
  RETRIEVE_ONE_LICENCE_FAILURE,
  RETRIEVE_ONE_LICENCE_SUCCESS,
  DELETE_LICENCE_SUCCESS,
  DELETE_LICENCE_FAILURE,
  CLEAR_LICENCES,
  CLEAR_ONE_LICENCE
} = types.licenceTypes;

const initialState = {
  licence: null,
  licences: [],
  isLoading: false,
  error: null,
  listError: null,
};

function userReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_LICENCE_SUCCESS:
      return {
        ...state,
        licences: state.licences.docs
          ? [...state.licences.docs, payload]
          : payload,
        isLoading: false,
      };
    case CREATE_LICENCE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case GET_ALL_LICENCES_SUCCESS:
      return {
        ...state,
        licences: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case GET_ALL_LICENCES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: payload,
      };
    case LOAD_LICENCE:
      return {
        ...state,
        isLoading: true,
      };

    case UPDATE_LICENCE_SUCCESS:
      return {
        ...state.licence.docs,
        licences: payload,
        loading: false,
      };

    case UPDATE_LICENCE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case RETRIEVE_ONE_LICENCE_SUCCESS:
      return {
        ...state,
        licence: payload,
        loading: false,
      };
    case RETRIEVE_ONE_LICENCE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case DELETE_LICENCE_SUCCESS:
      return {
        ...state,
        licences: payload,
        loading: false,
      };
    case DELETE_LICENCE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    
    case CLEAR_LICENCES:
      return {
        ...state,
        licences: [],
        isLoading: false,
        error: null,
        listError: null,
      };
    
    case CLEAR_ONE_LICENCE:
      return {
        ...state,
        licence: null,
        loading: false,
      };
    default:
      return state;
  }
}

export default userReducer;
