import types from "../actions/types";
const {
  LOAD_USER,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  UPDATE_INFO_USER_SUCCESS,
  UPDATE_INFO_USER_FAILURE,
  UPDATE_PASSWORD_USER_SUCCESS,
  UPDATE_PASSWORD_USER_FAILURE,
  CHANGE_TIME_ZONE,
  RESET_STATE_USER
} = types.userTypes;
const initialState = {
  user: null,
  users: [],
  isLoading: false,
  areLoading: false,
  error: null,
  timeZone: localStorage.getItem("app-timezone") ? localStorage.getItem("app-timezone") : 'UTC'
};

function userReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_USER:
      return {
        ...state,
        isLoading: true,
      };

    case GET_USER_SUCCESS:
      return {
        ...state,
        user: payload,
        isLoading: false,
       // error: payload,
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case UPDATE_INFO_USER_SUCCESS:
      return {
        ...state,
        //user: payload,
        phoneNumber: payload.phoneNumber,
        isLoading: false,
       // error: payload,
      };

    case UPDATE_INFO_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

      case UPDATE_PASSWORD_USER_SUCCESS:
      return {
        ...state,
        //user: payload,
        password: payload.password,
        isLoading: false,
        error: "password updated",
      };

    case UPDATE_PASSWORD_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

    case CHANGE_TIME_ZONE:
      return{
        ...state,
        timeZone: payload
      }
      case RESET_STATE_USER:
      return{
        ...state,
        user: null
      }
    default:
      return state;
  }
}

export default userReducer;
