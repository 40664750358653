import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  ModalHeader,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Badge,
  Input,
  Label,
} from "reactstrap";
import { Switch, TimePicker } from "antd";
import Select from "react-select";
import "./MyTaskRequests.css";
//import generatedTask from "../dumy-tasks";

import { v4 } from "uuid";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  retrieveTasksRequestByUserId,
  retreiveOneTask,
  retreiveSystemsForMaps,
  //retreiveSystems,
  updateTaskMember,
  retreiveCustomer,
  retrieveTasksByUserId
} from "../../../actions/taskAction";
import {retreiveSystemsByCustomerId, retreiveAllTheSystems} from "../../../actions/systemAction"
//import { message } from 'antd';

const MyTaskRequests = () => {
  const [ShowMyTaskModal, setShowMyTaskModal] = useState(false);
  const [toggleDetails, settoggleDetails] = useState(false);

  const toggleModalMyTask = () => {
    setShowMyTaskModal(!ShowMyTaskModal);
  };

  const toggleModalMyTaskCancel = () => {
    setShowMyTaskModal(!ShowMyTaskModal);
    settoggleDetails(false);
  };

  const [SwitchActivities, setSwitchActivities] = useState(true);
  const toggleSwitchActivities = () => setSwitchActivities(!SwitchActivities);
  // const [selectedTask, setselectedTask] = useState("");

  const dispatch = useDispatch();
  const currentTimeZone = useSelector((state) => state.user.timeZone);

  const tasks = useSelector((state) => state.taskReducer.tasksMemberRequest);

  const [state /*setState*/] = useState([
    "To be validated" /*, "Pending",
  "Done",
  "In progress",
"Canceled",*/,
  ]);


  const [type /*setType*/] = useState([
    "Other Monitoring",
    "Other",
    "Monitoring",
  ]);
  const [searchValue /*setSearchValue*/] = useState("");

  const taskReq = useSelector((state) => state.taskReducer.task);
  const isLoadingTaskReq = useSelector(
    (state) => state.taskReducer.isLoadingOneTaskReq
  );

  const [title, setTitle] = useState();
  const [time, setTime] = useState();
  const [description, setDescription] = useState();

  const [systemId, setsystemId] = useState();
  const [MonitoringActivity, setMonitoringActivity] = useState();
  const [optionsSystems, setoptionsSystems] = useState([]);
  const [optionsmonitoringActivities, setoptionsmonitoringActivities] =
    useState([]);
  const monitoringActivities = useSelector(
    (state) => state.taskReducer.AllSystemsFormMap.docs
  );

  const isloadingMaps = useSelector((state) => state.taskReducer.isloadingMaps);
  //const systems = useSelector((state) => state.taskReducer.AllSystems);

  const systems = useSelector((state) => state.system.AllSystemsByCustomerId);
  const isLoadingSystem = useSelector(
    (state) => state.taskReducer.isLoadingSystem
  );

  let [optionsCustomers, setoptionsCustomers] = useState([])

  let customers = useSelector(state => state.taskReducer.AllCustomers.docs)

  const allTheSystems = useSelector((state) => state.system.allTheSystems);

  const [compKey, setcompKey] = useState(v4());
  const [compKeyCustomer, setcompKeyCustomer] = useState(v4());
  const [refreshData, setrefreshData] = useState(false);
  const error = useSelector((state) => state.taskReducer.listError);

  const [systemSelected, setSystemSelected] = useState("");
  const [mapSelected, setMapSelected] = useState("");

  const [selectedCustomer, setSelectedCustomer] = useState({})

 const [eventCustomer, seteventCustomer] = useState();
 const [event, setevent] = useState();

//  useEffect(() => {

//   const state1 = ["Pending", "Done", "In progress", "Canceled", "Completed"];

//   const tabType = ["Monitoring", "Other Monitoring", "Other"];
  
//   dispatch(
//     retrieveTasksByUserId(
//       // JSON.stringify([type]),
//       JSON.stringify(tabType),
//       JSON.stringify(state1),
//       currentTimeZone,
      
//     )
//   );
//       //eslint-disable-next-line react-hooks/exhaustive-deps 
// }, [dispatch, currentTimeZone]);

  useEffect(() => {
    const state1 = ["Pending", "Done", "In progress", "Canceled", "Completed"];

  const tabType = ["Monitoring", "Other Monitoring", "Other"];

    ShowMyTaskModal &&
      dispatch(
        retrieveTasksRequestByUserId(
          JSON.stringify(type),
          JSON.stringify(state),
          currentTimeZone,
          searchValue
        )
      );

      dispatch(
        retrieveTasksByUserId(
          // JSON.stringify([type]),
          JSON.stringify(tabType),
          JSON.stringify(state1),
          currentTimeZone,
          
        )
      );

  }, [
    dispatch,
    type,
    state,
    currentTimeZone,
    searchValue,
    ShowMyTaskModal,
    refreshData,
  ]);

  //Retrieving Customers
  useEffect(() => {
    dispatch(retreiveCustomer(false, null, null, true))
   
  }, [dispatch]);

  // const getSystem =  (selectedCustomerID) => {
  //   dispatch(retreiveSystems(selectedCustomerID))
  // }

  //Retrieving Systems
  useEffect(() => {
    if (eventCustomer) {
    dispatch(retreiveSystemsByCustomerId(eventCustomer));
    }
  }, [dispatch, eventCustomer,event]);


  //set the init systems (after choosing customer)
  useEffect(() => {

    const currentSystem = allTheSystems?.find(system => system?._id.toString()===taskReq?.system?._id.toString());
    const customerObject = customers?.find(customer => customer?._id.toString()===currentSystem?.customer.toString());

    if(customerObject){
      setSelectedCustomer({
        value:customerObject?._id,
        label:customerObject?.label
      })
  
      dispatch(retreiveSystemsByCustomerId(customerObject?._id));
    }

  },[dispatch, allTheSystems, taskReq, customers])


  //set systems on change (by customer id)
  useEffect(() => {
    setoptionsSystems(systems?.map((sys)=>({
      value : sys._id,
      label : sys.name
    })))
  }, [systems]);

  //set maps on change (by system id)
  useEffect(() => {
    setoptionsmonitoringActivities(
      monitoringActivities?.map((act) => ({
        value: act._id,
        label: act.monitoringAct.activity,
      }))
    );
  }, [monitoringActivities]);

  function handletoggleModal() {
    setShowMyTaskModal(!ShowMyTaskModal);
    // setoptionsSystems(
    //   systems?.map((sys) => ({
    //     value: sys._id,
    //     label: sys.name,
    //   }))
    // );

    setoptionsCustomers(customers?.map((cust)=>({
      value : cust._id,
      label : cust.label
    })))
    //setmodalNewTaskRequest(!modalNewTaskRequest);
  }
  
  
  function handleSystem(e) {
    setevent(e.value);

    if (e) {
      setsystemId(e);

      setMapSelected(null);
      setcompKey(v4());
    } else {
      setsystemId(null);

      setMonitoringActivity(null);
    }
  }

  function handleCustomer(e) {
    seteventCustomer(e.value);
     if (e) {
        // getSystem(e.value);
        setSystemSelected(null);
        setMapSelected(null);
        setoptionsmonitoringActivities([]);
        setMonitoringActivity(null);
        setcompKeyCustomer(v4());
        setcompKey(v4());
     } else {
      //  getSystem(null);
     }
   }

  //by systemIDs by event

  useEffect(() => {
    if (event) {
      dispatch(retreiveSystemsForMaps(event,currentTimeZone));
    }
  }, [dispatch, event,currentTimeZone]);

  function handleMonitoringActivity(e) {
    if (e) {
      setMonitoringActivity(e);
    } else {
      setMonitoringActivity(null);
    }
  }

  const toggleEdit = async (e, id) => {
    setSwitchActivities(false);
    setTime("");
    setTitle("");

    await setDescription("");
    await settoggleDetails(true);


    await dispatch( retreiveAllTheSystems(false) );

    const res = await dispatch(retreiveOneTask(id));
    if (res) {
      if (res.type === "Other Monitoring") {
        setSwitchActivities(true);
        await setSystemSelected({
          value: res.system._id,
          label: res.system.name,
        });

        await dispatch(retreiveSystemsForMaps(res.system._id, currentTimeZone));
        await setMapSelected({
          value: res.map,
          label: res.title,
        });
      } else {
        setSwitchActivities(false);
        setTitle(res.title);
      }
      await setTime(moment(res.estimatedStart).tz(currentTimeZone));
      setDescription(res.description);
    }
  };

  // Edit Module
  async function handleEdit() {
    setTitle(title);
    setTime(time);
    setDescription(description);
    //  setsystemId(systemId);
    // setMonitoringActivity(MonitoringActivity);

    if (SwitchActivities === true) {
      const updatedData = {
        title: MonitoringActivity?.label,
        state: "To be validated",
        description: description,
        type: "Other Monitoring",
        estimatedStart: time,
        system: systemId?.value,
        map: MonitoringActivity?.value,
      };
      await dispatch(updateTaskMember(taskReq._id, updatedData));
      setrefreshData(!refreshData);
      settoggleDetails(!toggleDetails);
      //message.success(msg,3);
    } else {
      // setSwitchActivities(false);
      const updatedData = {
        title: title,
        state: "To be validated",
        description: description,
        type: "Other",
        estimatedStart: time,
        // system: " ",
        // map: " ",
      };
      await dispatch(updateTaskMember(taskReq._id, updatedData));
      setrefreshData(!refreshData);
      settoggleDetails(!toggleDetails);
      //message.success(msg,3);
    }
    settoggleDetails(false);
    if (!error) {
      //setrefreshData(!refreshData);
      //setEditModal(!editModal);
      //message.error('An Error occurred while updating',3);
    } else {
      //console.error(error);
      //message.error(msg,3);
    }
  }

  

  return (
    <>
      <Button color="primary" onClick={handletoggleModal}>
        My Task Requests
      </Button>
      <Modal
        className="MyTaskRequests-Modal"
        centered
        isOpen={ShowMyTaskModal}
        size="xl"
        toggle={toggleEdit}
        fade={false}
        //onClick={handletoggleModal}
      >
        <ModalHeader className="headerContent" toggle={toggleModalMyTask}>
          My Task Requests
        </ModalHeader>
        <ModalBody className="MyTaskRequests-ModalBody">
          <Row className="ModalTaskBody">
            <Col className="MyTask-CardSection" xs={toggleDetails ? "7" : "12"}>

          {tasks ? (
                <>
              {tasks?.map((task) => {
                return (
                  <Row
                    className="MyTask-Row"
                    key={task._id}
                    // onClick={() => toggleDetailsSection(task)}
                    onClick={(e) => toggleEdit(e, task._id)}
                  >
                    <Col>
                      <Badge color="info">{task.title}</Badge>
                    </Col>
                    <Col>{task.systemName}</Col>
                    <Col>
                      {moment(task.estimatedStart)
                        .tz(currentTimeZone)
                        .format("LT")}
                    </Col>
                  </Row>
                );
              })}

 </>
              ) : (
                <Row>
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <span> No Tasks </span>
                      {/* <span>{error} </span> */}
                    </div>
                  </Col>
                </Row>
              )}             
            </Col>

            {toggleDetails && time && (
              <Col className="MyTask-details-section" xs="5">
                {isLoadingTaskReq ? (
                  "Loading"
                ) : (
                  <div className="MyCardDetails">
                    <div>
                      <Row className="NewTaskRequestSwitch">
                        <Col>
                          <FormGroup>
                            <Label className="LabelMonitoringTask">
                              <Switch
                                checked={SwitchActivities}
                                onChange={toggleSwitchActivities}
                                disabled={true}
                              />
                              <span>Monitoring Task</span>
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col xs="4">
                          <FormGroup>
                            <TimePicker
                              className="TimePickerNewTaskRequest"
                              defaultValue={moment(time, "HH:mm")}
                              format="HH:mm"
                              onChange={(e) =>
                                setTime(
                                  moment(e?._d).format("YYYY-MM-DDTHH:mm")
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {SwitchActivities ? (
<>
                        <FormGroup>
                         <Select
                            className="NewTask_SelectTask"
                            options={optionsCustomers}
                            defaultValue={selectedCustomer}
                            onChange={(e) => handleCustomer(e)}
                          />
                        </FormGroup>
                        
                        <FormGroup className="MyTask_FormGroup">
                          
                          <Row className="spacingTask">
                            <Col>
                              <Select
                                className="NewTask_SelectTask"
                                options={optionsSystems}
                                isLoading={isLoadingSystem}
                                key={compKeyCustomer}
                                defaultValue={systemSelected}
                                onChange={(e) => handleSystem(e)}
                              />
                            </Col>

                            <Col>
                              <Select
                                className="NewTask_SelectTask"
                                isLoading={isloadingMaps}
                                options={optionsmonitoringActivities}
                                key={compKey}
                                defaultValue={mapSelected}
                                onChange={(e) => handleMonitoringActivity(e)}
                              />
                            </Col>
                          </Row>
                        </FormGroup>
                        </>
                      ) : (
                        <FormGroup>
                          {" "}
                          <Label>Title</Label>
                          <Input
                            type="text"
                            defaultValue={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </FormGroup>
                      )}

                      <FormGroup className="descriptionPart">
                        <Label>Description </Label>
                        <Input
                          className="textAreaTaskRequest"
                          type="textarea"
                          defaultValue={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </FormGroup>
                    </div>

                    <div className="card-task-request-footer">
                      <Button color="primary" onClick={() => handleEdit()}>
                        Save Changes
                      </Button>{" "}
                      <Button onClick={() => settoggleDetails(false)}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                )}
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => toggleModalMyTaskCancel()}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default MyTaskRequests;
