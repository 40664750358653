import React, { useState, useEffect } from "react";
import "../../../adminPanel.css";
import {
  Row,
  Col,
  Input,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Form,
  Spinner,
} from "reactstrap";

import { TablePagination } from "@material-ui/core";
import { BsFillCircleFill, BsPencil } from "react-icons/bs";
import { makeDelay, processTableHeight } from "../../../../../assets/js/CoreJs";
import { Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  retrieveType,
  createType,
  RetreiveOneType,
  updateType,
  clearTypes
} from "../../../../../actions/typeAction";
//import { TypeSystem } from "../../../adminPanel-dummy-data";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./typeSystemFormValidation";

function TypeSystemAP() {
  const [page, setPage] = React.useState(0);
  // const [searchTerm, setSearchTerm] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");


  // const toggleAdd = () => {
  //   setAddModal(!addModal);
  //   setStatus(true);
  // };

  const toggleAdd = () => {
    setAddModal(!addModal);
    // setStatus(true);
    reset();
    setValue("isActive", true);
  };

  const toggleResetEdit = () => {
    setEditModal(!editModal);
    reset();
  };
  //const toggleEdit = () => setEditModal(!editModal);
  //const [status, setStatus] = React.useState(TypeSystem.active);

  const [refreshData, setrefreshData] = useState(false);
  const dispatch = useDispatch();
  const types = useSelector((state) => state.typeSystem.types.docs);
  // console.log("Liste all types ====>",types)

  const isloading = useSelector((state) => state.typeSystem.isLoading);
  const error = useSelector((state) => state.typeSystem.listError);
  const ErrorAction = useSelector((state) => state.typeSystem.error);
  const totalDocs = useSelector((state) => state.typeSystem.types.totalDocs);
  const type = useSelector((state) => state.typeSystem.type);

  const applyTabHeightAdminPanelChildList = () => {
    processTableHeight(
      [
        "TitlePage",
        "adminPanelTabsNav",
        "adminPanelChildTabsNav",
        "typeSystemAPListHeader",
        "typeSystemAPPaginate",
      ],
      "typeSystemAPListTCONT",
      15
    );
  };

  useEffect(() => {
    applyTabHeightAdminPanelChildList();
    window.addEventListener("resize", applyTabHeightAdminPanelChildList);

    //return fn must be last in useEffect
    return () => {
      window.removeEventListener("resize", applyTabHeightAdminPanelChildList);
    };
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setrefreshData(!refreshData);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setrefreshData(!refreshData);
  };

  const {
    control,
    handleSubmit,
    register,
    setValue,
    //  watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    dispatch(retrieveType(page, rowsPerPage, null, searchValue));
  }, [dispatch, page, rowsPerPage, searchValue , refreshData]);

  // Type System Libelle
  // const [libelle, setLibelle] = useState("");
  // const handelLibelle = (e) => {
  //   setLibelle(e.target.value);
  //   //console.log(libelle);
  // };

  // Type System Status
  // const [status, setStatus] = useState(true);
  // const handelStatus = (e) => {
  //   setStatus(e);
  //   //console.log(e);
  // };

  // Type System Status for update
  // const [statusUpd, setStatusUpd] = useState();
  // const handelStatusUpd = (e) => {
  //   setStatusUpd(e);
  //   //console.log(statusUpd);
  // };

  //Add New Type System
  // function handleCreateType() {
  //   const newType = {
  //     type: libelle,
  //     isActive: status,
  //   };
  //   dispatch(createType(newType));
  //   setLibelle(null);
  //   setStatus(false);

  //   setrefreshData(!refreshData);
  //   if (!ErrorAction) {
  //     setAddModal(!addModal);
  //   }
  // }

  const handelAddFormSubmit = (TypeData) => {
    //console.log("TypeData ===>", TypeData);
    // TypeData.isActive=status

    dispatch(createType(TypeData));

    setrefreshData(!refreshData);
    setAddModal(!addModal);
    reset();
    if (!ErrorAction) {
      setAddModal(!addModal);
    }
  };

  const toggleEdit = async (e, id) => {
    e.preventDefault();
    if (id) {
      const res = await dispatch(RetreiveOneType(id));
      if (res) {
        // setLibelle(res.type);
        // setStatusUpd(res.isActive);
        setValue("type", res.type);
        setValue("isActive", res.isActive);
      }
    }
    setEditModal(!editModal);
  };

  

  ///****Update Contract Type*****///
  async function handelUpdateFormSubmit(updatedData) {
    // hostData.isActive=true
    // hostData.system=selectedSystemID
    //console.log("hostData====>",hostData);
    await dispatch(updateType(type._id, updatedData));
    // setHost(null);
    setrefreshData(!refreshData);
    setEditModal(!editModal);
    reset();
    if (!error) {
      setrefreshData(!refreshData);
      setEditModal(!editModal);
    } else {
      //console.error(error);
    }
  }
  const handleSearchQuerry = (e) => {
    makeDelay(() => {
      if (e.target.value.length >= 2) {
        setSearchValue(e.target.value);
        setPage(0);
      } else {
        setSearchValue("");
      }
    }, 750);
  };

  useEffect(() => {
    return () => clearTimeout(window.timerDelay);
  }, []);

   //Clear Data
   useEffect(() => {
    return () => {
      dispatch(clearTypes());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <>
      <section className="avxWrpContentPage typeSystemAPPage">
        <Row className="typeSystemAPListHeader">
          <Col xs="2">
            <Button color="primary" onClick={toggleAdd}>
              Add System Type
            </Button>
          </Col>
          <Col xs={{ size: 4, offset: 6 }}>
            <Input
              type="search"
              className="form-control"
              placeholder="Search By System type"
              onChange={handleSearchQuerry}
            />
          </Col>
        </Row>

        <div className="adminPanelChildListContent">
          <div
            id="typeSystemAPListTCONT"
            className="avxTableContainer scrollTb"
          >
            <Table
              className="adminPanelChildListTable avxBsTable oneActs"
              striped
            >
              <thead>
                <tr>
                  <th>System Type</th>

                  <th>
                    <span>
                      <BsPencil />
                    </span>
                  </th>
                </tr>
              </thead>

              {isloading === false ? (
                <tbody>
                  {types?.length > 0 && error === null ? (
                    <>
                      {types
                        // .filter((val) => {
                        //   return val?.libelle
                        //     .toLowerCase()
                        //     .includes(searchTerm.toLowerCase());
                        // })
                        .map((t) => (
                          <tr
                            key={t._id}
                            className={
                              "contractTypeRow " +
                              (t.isActive ? "activeC" : "disabledC")
                            }
                          >
                            <td>
                              <span className="stateFlag">
                                <BsFillCircleFill />
                              </span>
                              {t.type}
                            </td>

                            <td>
                              <span
                                className="tbBtn"
                                onClick={(e) => toggleEdit(e, t._id)}
                              >
                                <BsPencil></BsPencil>
                              </span>
                            </td>
                          </tr>
                        ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="2">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                          }}
                        >
                          <span> No Type Exist </span>
                          {/* <span>{error} </span> */}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="2">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: 100,
                        }}
                      >
                        <Spinner color="dark" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </Table>
          </div>

          <TablePagination
            className="typeSystemAPPaginate"
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            component="div"
            count={totalDocs ? totalDocs : 0}
            // rowsPerPage={rowsPerPage ? rowsPerPage : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>

        <Modal
          isOpen={addModal}
          toggle={toggleAdd}
          className="addAdminPanelChildModal"
          centered
          fade={false}
          backdrop="static"
          keyboard={true}
        >
          <Form
            onSubmit={handleSubmit(handelAddFormSubmit)}
            className="formAdminPanelChildModal"
          >
            <ModalHeader toggle={toggleAdd}>Add new System Type</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label>
                  System Type
                  <span className="requiredField"> *</span>
                </Label>
                <input
                  type="text"
                  name="typeName"
                  id="typeName"
                  className={
                    "form-control" + (errors.type ? " is-invalid" : "")
                  }
                  {...register("type")}
                />
                <small className="invalid-feedback">
                  {errors.type && <>{errors.type.message}</>}
                </small>
              </FormGroup>

              <FormGroup>
                <Label className="statusLabel">Active</Label>

                {/* <h1>{watch("isActive")+""}</h1> */}
                <Controller
                  control={control}
                  name="isActive"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Switch
                      onBlur={onBlur} // notify when input is touched
                      onChange={onChange} // send value to hook form
                      checked={value}
                      defaultChecked
                    />
                  )}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                type="submit"
                // onClick={() => handleCreateType()}
              >
                Add Type
              </Button>{" "}
              <Button color="secondary" onClick={toggleAdd}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>

        <Modal
          isOpen={editModal}
          toggle={toggleEdit}
          className="editAdminPanelChildModal"
          centered
          fade={false}
          backdrop="static"
          keyboard={true}
        >
          <Form
            onSubmit={handleSubmit(handelUpdateFormSubmit)}
            className="formAdminPanelChildModal"
          >
            <ModalHeader toggle={toggleEdit}>Update System Type</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label>
                  System Type
                  <span className="requiredField"> *</span>
                </Label>
                <input
                  type="text"
                  name="typeName"
                  id="typeName"
                  className={
                    "form-control" + (errors.type ? " is-invalid" : "")
                  }
                  {...register("type")}
                />
                <small className="invalid-feedback">
                  {errors.type && <>{errors.type.message}</>}
                </small>
              </FormGroup>

              <FormGroup>
                <Label className="statusLabel">Active</Label>

                {/* <h1>{watch("isActive")+""}</h1> */}
                <Controller
                  control={control}
                  name="isActive"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Switch
                      onBlur={onBlur} // notify when input is touched
                      onChange={onChange} // send value to hook form
                      checked={value}
                      defaultChecked
                    />
                  )}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                type="submit"
                //onClick={() => handleEdit()}
              >
                Save Changes
              </Button>{" "}
              <Button color="secondary" onClick={toggleResetEdit}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </section>
    </>
  );
}

export default TypeSystemAP;
