import types from '../actions/types';

const {
    LOAD_WORKFLOW_TASK,
    RETRIEVE_WORKFLOW_TASKS_SUCCESS,
    RETRIEVE_WORKFLOW_TASKS_FAILURE,

  WORKFLOW_CLOSE_TASKS_SUCCESS,
  WORKFLOW_CLOSE_TASKS_FAILURE
} = types.taskTypes;

const initialState = {
  taskWorkFlow: [
    { name: "Pending", items: [] },
    { name: "In progress", items: [] },
    { name: "Completed", items: [] },
    { name: "Done", items: [] },
    { name: "Canceled", items: [] }
  ],
  isLoading: false,
  error: null
};

function taskWorkFlowReducer(state=initialState, action){
  const { type, payload } = action;

  switch(type){

    case LOAD_WORKFLOW_TASK : return {
      ...state,
      isLoading: true
    };
  
    case RETRIEVE_WORKFLOW_TASKS_SUCCESS : return {
      ...state,
      taskWorkFlow: payload,
      isLoading: false,
      error: null
    };
    case RETRIEVE_WORKFLOW_TASKS_FAILURE : return {
      ...state,
      isLoading: false,
      error: payload
    };

    case WORKFLOW_CLOSE_TASKS_SUCCESS : return {
      ...state,
    };
    
    case WORKFLOW_CLOSE_TASKS_FAILURE : return {
      ...state,
      error: payload
    };

    default : return state;

  }
}

export default taskWorkFlowReducer;
