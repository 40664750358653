import http from '../api';

const getTaskResultFormSchema = (id) => {
  return http.get(`/api/resultForm/getFormSchemaByTaskId/${id}`);
};

const taskDynamicFormsService = {
  getTaskResultFormSchema
};

export default taskDynamicFormsService;