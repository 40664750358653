

const avxToggle = (e) => {
  //console.log(e);
  //console.log(e.target);
  //console.log(e.currentTarget);
  const targetz = e.currentTarget.nextElementSibling
  targetz && (
    targetz.classList.contains("sysChildHide") ? targetz.classList.remove("sysChildHide") : targetz.classList.add("sysChildHide")
  )
}

const outerHeight = (el) => {
  let height = 0
  if(el){
    height = el.offsetHeight
    const style = getComputedStyle(el)
    height += parseInt(style.marginTop) + parseInt(style.marginBottom)
  }
  return height
}

const processTableHeight = (substractClassesTab, targetID, additionPxs = 15) => {
  const winH = window.innerHeight
  const totalSubstract = substractClassesTab
    .map(classString => {
      //console.log(classString, outerHeight(document.querySelector('.'+classString)) )
      return outerHeight(document.querySelector('.'+classString)) 
    })
    .reduce((acc, singleH)=> acc+singleH, 0) + additionPxs;
  document.getElementById(targetID) && ( document.getElementById(targetID).style.height = (winH - totalSubstract)+'px' )
  //console.log(winH - totalSubstract)
}

//const applyTableHeight = (targetID, value) => document.getElementById(targetID).style.height = (value)+'px'

const makeDelay = (callback, ms) => {
  let timerDelay = 0;
  clearTimeout(timerDelay);
  timerDelay = setTimeout(callback, ms || 0);
}


module.exports = {
  avxToggle,
  outerHeight,
  processTableHeight,
  makeDelay
  //applyTableHeight
}