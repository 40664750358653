import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//import faker from "faker";
import { logout } from "../../../actions/auth.action";
import { Link } from "react-router-dom";
import { getCurrentUser, resetUser } from '../../../actions/userAction';
//import { login } from "../../../actions/auth.action";


const Sidebar = (props) => {
  const sidebare = useRef("");
  const btn = useRef("");
  const dispatch = useDispatch();

  //const authLoading = useSelector((state)=>state.authentication.isLoading);
  const user = useSelector((state) => state.user.user);

  //! ////////////////////////////////////
  ////*        Utils Functions       *////
  //! ////////////////////////////////////
  const sidebareAction = () => {
    const sidebar = sidebare.current;
    sidebar.classList.toggle("active");
    if (btn.current.classList.contains("bx-menu")) {
      // profilDetails.current.style.opacity = 1;
      btn.current.classList.replace("bx-menu", "bx-menu-alt-right");
    } else {
      // profilDetails.current.style.opacity = 0;
      btn.current.classList.replace("bx-menu-alt-right", "bx-menu");
    }
  };

  useEffect(() => {
    //dispatch(login());
    /*authLoading === false && */!user && dispatch(getCurrentUser());
  }, [dispatch, /*authLoading, */user]);

  function handleLogout() {
    dispatch(logout());
    dispatch(resetUser());
  }
  //! ////////////////////////////////////
  ////*       Return Result JSX      *////
  //! ////////////////////////////////////
  return (
    <div className="sidebar" ref={sidebare}>
      <div>
        <div className="logo_content">
          <div className="logo">
            {/* <i className="bx bxl-c-plus-plus"></i> */}
            <div className="logo_name">Avaxia</div>
          </div>
          <i
            className="bx bx-menu"
            id="btn"
            ref={btn}
            onClick={sidebareAction}
          ></i>
        </div>
        <ul className="nav_list">
          {props.navitems.map((item) => {
            //console.log("navinavi", props.navitems);
            if (item.icon) {
              return (
                <li key={item.id}>
                  <Link to={item.url}>
                    <i className={item.icon}></i>
                    <span className="links_name">{item.name}</span>
                  </Link>
                </li>
              );
            } else {
              return (
                <li key={item.id}>
                  <Link to={item.url}>
                    <i>{item.component}</i>
                    <span className="links_name">{item.name}</span>
                  </Link>
                </li>
              );
            }
          })}
        </ul>

        <div className="profile_content">
          <div className="profile">
            <Link to="/app/profile">
              <div className="profile_details">
                <img src={user?.avatar} alt="" />
                <div className="name_job">
                  <div className="name">{user?.firstName}</div>
                  <div className="job">consultant</div>
                </div>
              </div>
            </Link>
            <i
              alt="logout"
              onClick={() => handleLogout()}
              className="bx bx-log-out"
              id="log_out"
            ></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
