import React from 'react'
import {
    Modal,
    Button,
    ModalHeader,

    ModalFooter,
    ModalBody,
    FormGroup,
    Label,
    Badge,
  } from "reactstrap";
  import "./ModalTaskDetails.css"
  import moment from 'moment';
  import { FiClock } from "react-icons/fi";
export default function ModalTaskDetails({modalTaskDetails,setModalTaskDetails,TaskData,handleClearTaskDetails}) {

    return (
      <Modal
        isOpen={modalTaskDetails}
        size="sm"
        toggle={handleClearTaskDetails}
        centered
        fade={false}
      >
        <ModalHeader
          toggle={handleClearTaskDetails}
        >
        {" "}{TaskData?.title}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>{TaskData?.type}</Label>
            <Badge className="task-label-right" color="warning">{TaskData?.state}</Badge> 
          </FormGroup>
          <FormGroup>
            <Label>{TaskData?.description}</Label>
          </FormGroup>
          <FormGroup>
            <Label className="task-label-right"><FiClock/>{" "}{moment(TaskData?.estimatedStart).format("LT")}</Label>
            <Label><Badge color="primary" pill>{TaskData?.assigneeName}</Badge></Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => handleClearTaskDetails()}>Close</Button>
        </ModalFooter>
      </Modal>
    );
}
