const GET_MONITORING_TYPES_SUCCESS = "GET_MONITORING_TYPES_SUCCESS";
const GET_MONITORING_TYPES_FAILURE = "GET_MONITORING_TYPES_FAILURE";
const LOAD_MONITORING_TYPE = "LOAD_MONITORING_TYPE";
const CREATE_MONITORING_TYPE_SUCCESS = "CREATE_MONITORING_TYPE_SUCCESS";
const CREATE_MONITORING_TYPE_FAILURE = "CREATE_MONITORING_TYPE_FAILURE";
const RETRIEVE_ONE_MONITORING_TYPE_FAILURE = "RETRIEVE_ONE_MONITORING_TYPE_FAILURE";
const RETRIEVE_ONE_MONITORING_TYPE_SUCCESS = "RETRIEVE_ONE_MONITORING_TYPE_SUCCESS";
const UPDATE_MONITORING_TYPE_SUCCESS = "UPDATE_MONITORING_TYPE_SUCCESS";
const UPDATE_MONITORING_TYPE_FAILURE = "UPDATE_MONITORING_TYPE_FAILURE";
const CLEAR_MONITORING_TYPES = "CLEAR_MONITORING_TYPES";

let monitoringTypes = {
  GET_MONITORING_TYPES_SUCCESS,
  GET_MONITORING_TYPES_FAILURE,
  LOAD_MONITORING_TYPE,
  CREATE_MONITORING_TYPE_SUCCESS,
  CREATE_MONITORING_TYPE_FAILURE,
  RETRIEVE_ONE_MONITORING_TYPE_SUCCESS,
  RETRIEVE_ONE_MONITORING_TYPE_FAILURE,
  UPDATE_MONITORING_TYPE_SUCCESS,
  UPDATE_MONITORING_TYPE_FAILURE,
  CLEAR_MONITORING_TYPES,
};
export default monitoringTypes;