import types from "./types";
import { message } from 'antd';
import syncShiftService from "../services/syncShiftService";

const {
    CREATE_SYNC_SHIFT_SUCCESS,
    CREATE_SYNC_SHIFT_FAILURE,
    LOAD_SYNC_SHIFT,
    GET_ALL_SYNC_SHIFTS_SUCCESS,
    GET_ALL_SYNC_SHIFTS_FAILURE,
    LOAD_SYNC_SHIFT_FOR_CREATE,
    CLEAR_SYNC_SHIFTS
} = types.syncShiftTypes;



export const createSyncShift = () => async (dispatch) => {
  try {
    // dispatch({
    //   type: LOAD_SYNC_SHIFT,
    // });

    dispatch({
      type: LOAD_SYNC_SHIFT_FOR_CREATE,
    });

    // const successCallBack = async (msg)=>{
    // const resAll = await syncShiftService.getAllSyncShift();
    // dispatch({
    //   type: GET_ALL_SYNC_SHIFTS_SUCCESS,
    //   payload: resAll.data.data,
    // });
    
    // if(msg === "Shift sync success"){
    //     message.success(msg,5);
    // }else {
    //     message.error('Error Shift sync',5);
    // }
    //  // alert(msg)
    //  // message.success(msg,5);
    // }
    const res = await syncShiftService.createSyncShift();

    if(res.data.message === "Shift sync success"){
        message.success(res.data.message,5);
    }else {
        message.error('Error Shift sync',5);
    }
    
    dispatch({
      type: CREATE_SYNC_SHIFT_SUCCESS,
      payload: res.data.message,
    });
  } catch (err) {
    dispatch({
      type: CREATE_SYNC_SHIFT_FAILURE,
      payload: err,
    });
  }
};

export const retrieveSyncShifts = () => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_SYNC_SHIFT,
    });
    const res = await syncShiftService.getAllSyncShift();
   // console.log("I m Here")
    dispatch({
      type: GET_ALL_SYNC_SHIFTS_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {
    dispatch({
      type: GET_ALL_SYNC_SHIFTS_FAILURE,
      payload: err.response.data.message,
    });
  }
};


 //clear Data
 export const clearSyncShifts = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_SYNC_SHIFTS,
  })
}
