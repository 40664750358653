import types from "../actions/types";

const {
  LOAD_MONITORING_ACTIVITY,
  GET_ALL_MONITORING_ACTIVITY_BY_SYSTEM_SUCCESS,
  GET_ALL_MONITORING_ACTIVITY_BY_SYSTEM_FAILURE,
  GET_ALL_MONITORING_ACTIVITY_SUCCESS,
  GET_ALL_MONITORING_ACTIVITY_FAILURE,
  CREATE_MONITORING_ACTIVITY_SUCCESS,
  CREATE_MONITORING_ACTIVITY_FAILURE,
  RETRIEVE_ONE_MONITORING_ACTIVITY_SUCCESS,
  RETRIEVE_ONE_MONITORING_ACTIVITY_FAILURE,
  UPDATE_MONITORING_ACTIVITY_SUCCESS,
  UPDATE_MONITORING_ACTIVITY_FAILURE,
  CLEAR_MONITORING_ACTIVITY
} = types.monitoringActivityTypes;
const initialState = {
  monitoringAct: null,
  monitoringActs: [],
  isLoading: false,
  error: null,
  listError: null,
};

function userReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_MONITORING_ACTIVITY_BY_SYSTEM_SUCCESS:
      return {
        ...state,
        monitoringActs: payload,
        isLoading: false,
      };
    case GET_ALL_MONITORING_ACTIVITY_BY_SYSTEM_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case LOAD_MONITORING_ACTIVITY:
      return {
        ...state,
        isLoading: true,
      };

      case GET_ALL_MONITORING_ACTIVITY_SUCCESS:
      return {
        ...state,
        monitoringActs: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case GET_ALL_MONITORING_ACTIVITY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: payload,
      };
   
      case CREATE_MONITORING_ACTIVITY_SUCCESS:
      state.monitoringActs.docs.push(payload);
      return {
        ...state,
        monitoringActs: state.monitoringActs,
        isLoading: false,
        error: null,
        listError: null,
      };
    case CREATE_MONITORING_ACTIVITY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

      case RETRIEVE_ONE_MONITORING_ACTIVITY_SUCCESS:
      return {
        ...state,
        monitoringAct: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case RETRIEVE_ONE_MONITORING_ACTIVITY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

      case UPDATE_MONITORING_ACTIVITY_SUCCESS:
        const updatedIndex = state.monitoringActs.docs.map((monitoringAct, index) => {
          if (payload._id === monitoringAct._id) {
            return index;
          } else return null;
        });
        if (updatedIndex) state.monitoringActs.docs[updatedIndex] = payload;
        return {
          ...state,
          monitoringActs: state.monitoringActs,
          isLoading: false,
          error: null,
          listError: null,
        };
  
      case UPDATE_MONITORING_ACTIVITY_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: payload,
          listError: null,
        };

      case CLEAR_MONITORING_ACTIVITY:
        return {
          ...state,
          monitoringActs: [],
          monitoringAct: null,
          isLoading: false,
          error: null,
          listError: null,
    };

    default:
      return state;
  }
}

export default userReducer;
