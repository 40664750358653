import types from "../actions/types";
const {
  SET_ALERT,
  REMOVE_ALERT
} = types.alertTypes;
const initialState= [];
export default function alert(state = initialState, action){
    switch(action.type){
        case SET_ALERT:
            return [...state,action.payload];
        case REMOVE_ALERT:
            return state.filter(alert=>alert.id !== action.payload);
        default:
            return state; 
    }
}