import types from "./types";
import notifService from "../services/notifService";
const {
  GET_ALL_NOTIFICATION_SUCCESS,
  GET_ALL_NOTIFICATION_FAILURE,
  LOAD_ALL_NOTIFICATION
} = types.notificationTypes;

export const getAllNotification = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_ALL_NOTIFICATION,
    });
    const res = await notifService.getAllNotif(userId);
    dispatch({
      type: GET_ALL_NOTIFICATION_SUCCESS,
      payload: res.data,
    });
    return res.data.data;
  } catch (err) {
    dispatch({
      type: GET_ALL_NOTIFICATION_FAILURE,
      payload: err,
    });
  }
};

export const seeNotif = (userId) => async (dispatch) => {
  try {
    const resSeen = await notifService.seeNotif(userId);
    if(resSeen){
      const res = await notifService.getAllNotif(userId);
      dispatch({
        type: GET_ALL_NOTIFICATION_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ALL_NOTIFICATION_FAILURE,
      payload: err,
    });
  }
};
