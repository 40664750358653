import React, { useRef } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
//import Particles from "react-particles-js";
import { useDispatch, useSelector } from "react-redux";
import dotenv from "dotenv";
import { UserAgentApplication } from "msal";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import image from "../../assets/images/data.svg";
import "./login.css";
import { login, register, microsoftLogin } from "../../actions/auth.action";
import { getUserDetails } from "../../services/GraphService";

const RegisterSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .min(8, "Too Short!")
    .max(30, "Too Long!")
    .required("Required"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
  phoneNumber: Yup.string()
    .min(4, "Too Short!")
    .max(30, "Too Long!")
    .required("Required"),
});
const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .min(8, "Too Short!")
    .max(30, "Too Long!")
    .required("Required"),
});

const Login = () => {
  dotenv.config();
  const containerDiv = useRef("");
  const dispatch = useDispatch();
  const [authLoading, setAuthLoading] = React.useState(false);

  const signUpAction = () => {
    containerDiv.current.classList.add("right-panel-active");
  };
  const signInAction = () => {
    containerDiv.current.classList.remove("right-panel-active");
  };
  const userAgentApplication = new UserAgentApplication({
    auth: {
      clientId: process.env.REACT_APP_CLIENTID,
      redirectUri: process.env.REACT_APP_REDIRECT_URL,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
    },
  });
  const loginMicrosoft = async () => {
    try {
      await userAgentApplication.loginPopup({
        scopes: process.env.REACT_APP_SCOPES.split(" "),
        prompt: "select_account",
      });
      await getUserProfile();
    } catch (err) {}
  };

  const getUserProfile = async () => {
    setAuthLoading(true);
    try {
      let accessToken = await userAgentApplication.acquireTokenSilent({
        scopes: process.env.REACT_APP_SCOPES.split(" "),
      });

      if (accessToken) {
        // Get the user's profile from Graph
        let user = await getUserDetails(accessToken);

        dispatch(microsoftLogin(user.mail, accessToken));
      }
    } catch (err) {}
  };

  return (
    <div className="loginBody">
      {/*<div className="animation">
        <Particles
          className="animeParticle"
          params={{
            particles: {
              number: {
                value: 90,
              },
              size: {
                value: 2,
              },
              move: {
                enable: true,
                speed: 2,
                direction: "none",
                random: true,
                straight: false,
                out_mode: "out",
                bounce: false,
                attract: { enable: false, rotateX: 600, rotateY: 1200 },
              },

              line_linked: {
                color: "#8ca1bb",
                opacity: 1,
              },
            },
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: "repulse",
                },
              },
            },
          }}
        />
      </div>*/}
      <div className="container-login" id="container-login" ref={containerDiv}>
        <div className="form-container sign-up-container">
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              password: "",
              confirmPassword: "",
              phoneNumber: "",
            }}
            validationSchema={RegisterSchema}
            onSubmit={async (values, { resetForm }) => {
              dispatch(
                register(
                  values.firstName,
                  values.lastName,
                  values.email,
                  values.password,
                  values.phoneNumber
                )
              );
              resetForm({ values: "" });
            }}
          >
            <Form>
              <h1>Create Account</h1>
              <div className="form-input-container">
                <span>or use your email for registration</span>
                <Field
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="error"
                />
                <Field
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                />
                <ErrorMessage
                  name="lastName"
                  component="div"
                  className="error"
                />
                <Field
                  type="email"
                  id="email-register"
                  name="email"
                  placeholder="Email"
                />
                <ErrorMessage name="email" component="div" className="error" />
                <Field
                  type="password"
                  id="password-register"
                  name="password"
                  placeholder="Password"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error"
                />
                <Field
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="error"
                />
                <Field
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="Phone Number"
                />
                <ErrorMessage
                  name="phoneNumber"
                  component="div"
                  className="error"
                />
                <button type="submit">SignUp</button>
              </div>
            </Form>
          </Formik>
        </div>
        <div className="form-container sign-in-container">
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={LoginSchema}
            onSubmit={async (values, { resetForm }) => {
              dispatch(login(values.email, values.password));
              resetForm({ values: "" });
            }}
          >
            <Form>
              <h1>Sign In</h1>
              <div className="form-input-container">
                <div className="social-container-microsoft">
                  <a
                    href="#/"
                    onClick={() => {
                      loginMicrosoft();
                    }}
                    className="social"
                  >
                    <i className="fa fa-windows"></i>
                    <span>Using Microsoft Account</span>
                  </a>
                </div>
                <span>or use your account</span>
                <Field
                  type="email"
                  id="email-login"
                  name="email"
                  placeholder="Email"
                />
                <ErrorMessage name="email" component="div" className="error" />
                <Field
                  type="password"
                  id="password-login"
                  name="password"
                  placeholder="Password"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error"
                />
                <a href="#/" className="forgot-password">
                  Forgot Your Password
                </a>
                <button type="submit">
                  <i className="fa fa-sign-in" style={{ fontSize: "14px" }}></i>
                  Sign In
                </button>
                {useSelector((state) => state.authentication.isLoading) &&
                  authLoading && (
                    <div style={{ marginTop: "20px" }}>
                      <CircularProgress />
                    </div>
                  )}
              </div>
            </Form>
          </Formik>
        </div>
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-panel overlay-left">
              <h1>Welcome Back!</h1>
              <p>
                To keep connected with us please login with your personal info
              </p>
              <button className="ghost" id="signIn" onClick={signInAction}>
                Sign In
              </button>
            </div>
            <div className="overlay-panel overlay-right">
              <h1>Hello, Friend!</h1>
              <div className="img">
                <img src={image} alt="images" />
              </div>
              <p>Enter your details and start journey with us</p>
              <button className="ghost" id="signUp" onClick={signUpAction}>
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
