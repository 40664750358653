import React, { useEffect, useState } from 'react';
import { FiEdit } from "react-icons/fi";
import { FiTrash2 } from "react-icons/fi";
import { FiThumbsUp } from "react-icons/fi";
import { CgBlock, CgUnblock } from "react-icons/cg";
import { FiMessageSquare } from "react-icons/fi";
import { FiSave } from "react-icons/fi";
import { FiX } from "react-icons/fi";
import 'react-loading-skeleton/dist/skeleton.css'
import {
  Row,
  Col,
  Card,
  CardBody,
  Form
} from "reactstrap";
import { getAllCommentByPostID, AddComment } from '../../../../actions/commentAction';
import Comments from './Comments';
import commentService from '../../../../services/commentService';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./postYupValidatore"
import { ImEyeBlocked } from 'react-icons/im';

function PostCard({ index, feed, Delete_Post, FeedPic, HandleLike, user, existUSer, dispatch, updatePost, getAllPosts,AllPosts, handleBlock, handleUnblock}) {
  const [EditPost, setEditPost] = useState(false);
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors },
} = useForm({
    resolver: yupResolver(schema),
});
  const isAdmin = user?.Roles.some((role) => role?.label.toString() === 'Administrator');
  const isFeedOwner = feed?.user?._id.toString() === user?._id;

  const toggleEditPost = (feed) => {
    setEditPost(!EditPost)
    setValue("postText",feed.text)
  }
  const updatePostEventHandler = async ({postText}) => {
    const newPost = {
      text: postText,
    }
    if (window.confirm("Do you want to save changes ?")) {
      await dispatch(updatePost(feed._id, newPost,feed.user))
      await dispatch(getAllPosts())
    }
    reset()
  }
  const [OpenComment, setOpenComment] = useState(false)
  const toggleComment = () => {
    setOpenComment(!OpenComment)
  }
  const [commentCount, setcommentCount] = useState();
  const [blockedcommentCount,  setblockedcommentCount] = useState();
  const [unblockedCommentCount, setunblockedCommentCount] = useState();
  const [refreshComent, setrefreshComent] = useState(false);

useEffect(() => {
  let isApiSubscribed = true;
  const fetch=async()=>{
    if(isApiSubscribed){
  const res= await commentService.getAllCommentByPostId(feed._id)
  res?.data&&setcommentCount(res.data.data.length)
  res?.data&&setblockedcommentCount(res?.data?.data.filter((b) => b?.isBlocked === true)?.length);
 
}
if(commentCount - blockedcommentCount >= 0) {
  setunblockedCommentCount(commentCount- blockedcommentCount);
}else{
  setunblockedCommentCount(0);
}
} 
  fetch()
  return () => {
    isApiSubscribed=false
    fetch()
  };
}, [AllPosts,refreshComent,feed, commentCount, blockedcommentCount]);

  return (
    <div>
      <Card>
        <CardBody>
          <div className="Feed" key={index}>
            <div className="status">
              <Row className="statusTopper">
                <Col>
                  <h6 style={{ opacity: .5 }}>
                    <p>{feed.user.firstName} {" "}{feed.user.lastName} </p>
                  </h6>
                </Col>
                <div>{feed.isBlocked === true && (isAdmin || isFeedOwner) ? (<div className="comment_blocked"><ImEyeBlocked className='eye_blocked_on_comment'/><span className="comment_blocked_text_right">This Post is blocked</span></div>) : ""}</div>

                <div className="menuActionbutton">
                  <Col>
                    {!EditPost && (<> 
                    {(isFeedOwner || isAdmin) && <span className="button_update" onClick={() => toggleEditPost(feed)}>
                      <FiEdit style={{ height: "16px", width: "16px" }} />
                    </span>}

                    {(isFeedOwner || isAdmin) && <span className="button_delete" onClick={() => Delete_Post(feed._id)} >
                        <FiTrash2 style={{ height: "16px", width: "16px" }} />
                      </span> }
                     </>)}

                     {isAdmin && feed.isBlocked === false ? <span onClick={() => handleBlock(feed._id)} className='button_block'><CgBlock style={{ height: "18px", width: "18px" }}/></span> : isAdmin && feed.isBlocked === true && <span onClick={() => handleUnblock(feed._id)} className='button_block'><CgUnblock style={{ height: "16px", width: "16px" }}/></span>} 
                  </Col>
                </div>
              </Row>
              <Row className="statusBody">
                <Col className="cardPic" xs="3" style={{paddingBottom:"12px"}}>
                  <img src={FeedPic} alt="" />
                </Col>
                <Col className="cardContent">
                  {/* <CardText> */}
                    {EditPost ? (
                      <Form >
                      <textarea
                                name="postText"
                                placeholder={errors.postText ? errors.postText.message : "Edit post"}
                                className={
                                    "form-control" + (errors.postText ? " is-invalid" : "")
                                }
                                {...register("postText")}
                            />
                            <small className="invalid-feedback">
                                {errors.title && <>{errors.title.message}</>}
                            </small>
                            {EditPost && (
                    <span className='actionEditButton'>
                      <span className='btn_Action save' size='sm' color='primary'onClick={handleSubmit(updatePostEventHandler)} >
                        <FiSave style={{ fontSize: "18px" }} />
                      </span>{" "}{" "}<span className='btn_Action close' size='sm' onClick={() => setEditPost(!EditPost)} >
                        <FiX style={{ fontSize: "18px" }} />
                      </span>
                    </span>)}
                      </Form>) : (
                      <>{feed.text}
                      </>
                    )}
                  {/* </CardText> */}
                
                </Col>
              </Row>
            </div>

            <div className="feedReactions">
              <span onClick={() => HandleLike(feed._id, index)}>
                <FiThumbsUp fill={existUSer(index) ? "#007bff" : "white"} />{" "}Like{" "}
                {feed.likes.length}
              </span>
              <span onClick={(e) => toggleComment(e)}>
                <FiMessageSquare /> Reply  {(isAdmin || isFeedOwner) && commentCount ? commentCount : unblockedCommentCount}
                {/* {commentCount? commentCount  : 0 } */}
              </span>
            </div>
            {/* <span style={{float:"right"}}>{feed.createdAt === feed.updatedAt ? moment(feed.createdAt).tz(timeZone).format("lll") : moment(feed.updatedAt).tz(timeZone).format("lll")}</span> */}
            
          </div>
        </CardBody>
      </Card>
      <Comments
        dispatch={dispatch}
        getAllCommentByPostID={getAllCommentByPostID}
        feed={feed}
        AddComment={AddComment}
        FeedPic={FeedPic}
        OpenComment={OpenComment}
        setrefreshComent={setrefreshComent}
        refreshComent={refreshComent}
        isAdmin={isAdmin}
        isFeedOwner={isFeedOwner}
        user={user}
      />
    </div>
  )
}


export default PostCard;
