import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import moment from "moment";
import SlaContractDetails from "./SlaContractDetails";

import { TablePagination } from "@material-ui/core";
import { BsTrash, BsPencil } from "react-icons/bs";
import { DatePicker } from "antd";
import Select from "../../../../shared/select/Select";
import { processTableHeight, makeDelay } from "../../../../assets/js/CoreJs";
import "./SlaContracts.css";
import { useDispatch, useSelector } from "react-redux";

import {
  createSlaContract,
  deleteSlaConstract,
  RetreiveOneSlaContract,
  retrieveSlaContracts,
  updateSlaContract,
  clearSlaContracts,
} from "../../../../actions/slaContractAction";
import { retrieveSysClass } from "../../../../actions/sysClassAction";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./SlaContractsValidation";

const { RangePicker } = DatePicker;

const SlaContracts = function (props) {
  const { customer_ID } = props;
  const dispatch = useDispatch();
  const [refrechData, setrefrechData] = useState(false);
  const slaContracts = useSelector(
    (state) => state.slaContract.slaContracts.docs
  );
  const isloading = useSelector((state) => state.slaContract.isLoading);
  const totalDocs = useSelector(
    (state) => state.slaContract.slaContracts.totalDocs
  );
  const slaContract = useSelector((state) => state.slaContract.slaContract);
  const error = useSelector((state) => state.slaContract.listError);
  const ErrorAction = useSelector((state) => state.slaContract.error);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const selectInvalidFeedback = {
    width: "100%",
    marginTop: ".25rem",
    fontSize: "80%",
    color: "#dc3545",
  };

  if (errors.selectclass) {
    document.querySelector(".customerSelectTimeZone > div:nth-child(2)") &&
      document
        .querySelector(".customerSelectTimeZone > div:nth-child(2)")
        .classList.add("isInvalidSelect");
  }

  const applyTabHeightSLA = () => {
    processTableHeight(
      [
        "TitlePage",
        "customerDetailsTabsNav",
        // "sysDetailHeader",
        // "customerSystemDetailsTabsNav",
        "slaHeader",
        "slaPagination",
      ],
      "slaContractsTCONT",
      15
    );
  };

  useEffect(() => {
    return () => {

      dispatch(clearSlaContracts());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    applyTabHeightSLA();
    window.addEventListener("resize", applyTabHeightSLA);

    if (customer_ID) {
      dispatch(retrieveSlaContracts(customer_ID, page+1, rowsPerPage,searchValue));
    }

    //return fn must be last in useEffect
    return () => {
      window.removeEventListener("resize", applyTabHeightSLA);
     // dispatch(clearSlaContracts());
    };
  }, [dispatch, customer_ID, page, rowsPerPage, searchValue, refrechData]);

  const [selectedContract, setSelectedContract] = useState("");
  const handleGoBack = async () => {
    await setSelectedContract("");
    applyTabHeightSLA();
  };

  const [addModal, setAddModal] = useState(false);
  const [optionsClass, setoptionsClass] = useState([
    {
      value: "none",
      label: "none",
    },
  ]);

  const toggleAdd = async () => {
    const optionsClass = await dispatch(retrieveSysClass(0, 1000, true));
    if (optionsClass)
      setoptionsClass(
        optionsClass.map((value) => ({
          value: value._id,
          label: value.libelle,
        }))
      );
    setAddModal(!addModal);
    reset();
  };
  const [editModal, setEditModal] = useState(false);

  const toggleEdit = async (id) => {
    if (id) {
      const optionsClass = await dispatch(retrieveSysClass(0, 1000, true));
      if (optionsClass)
        setoptionsClass(
          optionsClass.map((value) => ({
            value: value._id,
            label: value.libelle,
          }))
        );

      const res = await dispatch(RetreiveOneSlaContract(id));
      if (res) {
        setValue("selectclass", {
          value: res.class._id,
          label: res.class.libelle,
        });
        setValue("duration", [
          moment.utc(res.startDate, dateFormat),
          moment.utc(res.endDate, dateFormat),
        ]);
      }
    }
    setEditModal(!editModal);
  };

  // const [searchTerm, setSearchTerm] = useState("");
  const handelSearshQuerry = (e) => {
    makeDelay(() => {
      if (e.target.value.length >= 2) {
        setSearchValue(e.target.value);
        setPage(0);
      } else {
        setSearchValue("");
      }
    }, 750);
  };

  useEffect(() => {
    return () => clearTimeout(window.timerDelay);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const confirmDelete = (id) => {
    if (window.confirm("Are you sure you want to delete the contract ?")) {
      dispatch(deleteSlaConstract(id));
      setrefrechData(!refrechData);
    }
  };
  // const [startDate, setstartDate] = useState("");
  // const [endDate, setendDate] = useState("");
  // const [Class, setClass] = useState("");
  // function handleEndStartDate(e) {
  //   if (e) {
  //     for (let i = 0; i < e.length; i++) {
  //       if (i === 0) setstartDate(moment(e[i]._d).format("YYYY-MM-DD"));
  //       if (i === 1) setendDate(moment(e[i]._d).format("YYYY-MM-DD"));
  //     }
  //   }
  // }
  function handleCreateSlaContract({ duration, selectclass }) {
    const newSlaConstract = {
      customer: customer_ID,
      startDate: moment.utc(duration[0], dateFormat),
      endDate: moment.utc(duration[1], dateFormat),
      class: selectclass.value,
    };
    dispatch(createSlaContract(newSlaConstract));

    setrefrechData(!refrechData);
    if (!ErrorAction) {
      setAddModal(!addModal);
      reset();
    }
  }
  const dateFormat = "YYYY/MM/DD";

  function handleEdit({ selectclass, duration }) {
    if (selectclass.value) {
      const updatedData = {
        startDate: moment.utc(duration[0], dateFormat),
        endDate: moment.utc(duration[1], dateFormat),
        class: selectclass.value,
      };
      dispatch(updateSlaContract(slaContract._id, updatedData));
    } else {
      const updatedData = {
        startDate: moment.utc(duration[0], dateFormat),
        endDate: moment.utc(duration[1], dateFormat),
        class: selectclass.value,
      };
      dispatch(updateSlaContract(slaContract._id, updatedData));
    }
    if (!error) {
      setrefrechData(!refrechData);
      setEditModal(!editModal);
      reset();
    } else {
      console.error(error);
    }
  }

  return selectedContract ? (
    <SlaContractDetails
      selectedCont={selectedContract}
      handleGoBack={handleGoBack}
    />
  ) : (
    <div className="customerSlaContractsList">
      <Row className="slaHeader">
        <Col xs="2">
          <Button color="primary" onClick={toggleAdd}>
            Add SLA Contract
          </Button>
        </Col>
        <Col xs={{ size: 4, offset: 6 }}>
          <Input
            type="search"
            className="form-control"
            placeholder="Search by Class"
            onChange={handelSearshQuerry}
          />
        </Col>
      </Row>
      <div className="slaContent">
        <div id="slaContractsTCONT" className="avxTableContainer scrollTb">
          <Table className="slaContractsTable avxBsTable twoActs" striped hover>
            <thead>
              <tr>
                <th>Class</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>
                  <span>
                    <BsPencil></BsPencil>
                  </span>
                </th>
                <th>
                  <span>
                    <BsTrash></BsTrash>
                  </span>
                </th>
              </tr>
            </thead>
            {isloading === false ? (
              <tbody>
                {slaContracts?.length>0 && error === null ? (
                  <>
                    {slaContracts
                      .map((sl) => (
                        <tr
                          key={sl._id}
                          className={
                            sl.isActive ? "activeContract" : "disabledContract"
                          }
                        >
                          <td
                            onClick={() => {
                              setSelectedContract(sl._id);
                            }}
                          >
                            {sl.class.libelle}
                          </td>
                          <td
                            onClick={() => {
                              setSelectedContract(sl._id);
                            }}
                          >
                            {sl.startDate.split("T")[0]}
                          </td>
                          <td
                            onClick={() => {
                              setSelectedContract(sl._id);
                            }}
                          >
                            {sl.endDate.split("T")[0]}
                          </td>
                          <td>
                            <span
                              className="tbBtn"
                              onClick={(e) => toggleEdit(sl._id)}
                            >
                              <BsPencil></BsPencil>
                            </span>
                          </td>
                          <td>
                            <span
                              className="tbBtn"
                              onClick={() => confirmDelete(sl._id)}
                            >
                              <BsTrash></BsTrash>
                            </span>
                          </td>
                        </tr>
                      ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan="5">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                        }}
                      >
                        <span>No SLA contract was found</span>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="5">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: 100,
                      }}
                    >
                      <Spinner color="dark" />
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
        <TablePagination
          className="slaPagination"
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          component="div"
          count={totalDocs ? totalDocs : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>

      <Modal
        isOpen={addModal}
        toggle={toggleAdd}
        className="addSlaContractModal"
        centered
        fade={false}
        backdrop="static"
        keyboard={true}
      >
        <Form
          onSubmit={handleSubmit(handleCreateSlaContract)}
          className="formModalAddCustomer"
        >
          <ModalHeader toggle={toggleAdd}>Add new SLA contract</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>
                Class
                <span className="requiredField"> *</span>
              </Label>
              <Controller
                name="selectclass"
                control={control}
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <Select
                    className="customerSelectTimeZone"
                    placeholder="Select class"
                    options={optionsClass}
                    {...rest}
                    {...fieldState}
                  />
                )}
              />
              <small style={selectInvalidFeedback}>
                {errors.selectclass && <>{errors.selectclass.value.message}</>}
              </small>
            </FormGroup>
            <FormGroup>
              <Label>
                Duration
                <span className="requiredField"> *</span>
              </Label>

              <Controller
                name="duration"
                control={control}
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <RangePicker
                    {...rest}
                    {...fieldState}
                    className={
                      "slaContractDatepicker " +
                      (errors.duration ? " isInvalidRange" : "")
                    }
                  />
                )}
              />
              <small style={selectInvalidFeedback}>
                {errors.duration && <>{errors.duration.message}</>}
              </small>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Add Contract
            </Button>{" "}
            <Button color="secondary" onClick={toggleAdd}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={editModal}
        // toggle={toggleEdit}
        className="editSlaContractModal"
        centered
        fade={false}
        backdrop="static"
        keyboard={true}
      >
        <Form
          onSubmit={handleSubmit(handleEdit)}
          className="formModalAddCustomer"
        >
          <ModalHeader toggle={toggleEdit}>Update SLA contract</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>
                Class
                <span className="requiredField"> *</span>
              </Label>
              <Controller
                name="selectclass"
                control={control}
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <Select
                    className="customerSelectTimeZone"
                    placeholder="Select class"
                    options={optionsClass}
                    {...rest}
                    {...fieldState}
                  />
                )}
              />
              <small style={selectInvalidFeedback}>
                {errors.selectclass && <>{errors.selectclass.value.message}</>}
              </small>
            </FormGroup>
            <FormGroup>
              <Label>
                Duration
                <span className="requiredField"> *</span>
              </Label>

              <Controller
                name="duration"
                control={control}
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <RangePicker
                    // defaultValue={[
                    //   moment( slaContract ? slaContract.startDate : startDate, ),
                    //   moment(slaContract ? slaContract.endDate : endDate, dateFormat),

                    // ]}
                    // format={dateFormat}
                    {...rest}
                    {...fieldState}
                    className={
                      "slaContractDatepicker " +
                      (errors.duration ? " isInvalidRange" : "")
                    }
                  />
                )}
              />
              <small style={selectInvalidFeedback}>
                {errors.duration && <>{errors.duration.message}</>}
              </small>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Save Changes
            </Button>{" "}
            <Button
              color="secondary"
              onClick={() => {
                setEditModal(!editModal);
                reset();
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default SlaContracts;
