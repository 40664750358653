import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {Modal,ModalHeader,ModalBody,ModalFooter,Button,FormGroup,Label,Card,Col,Badge,Row,Input,Spinner} from "reactstrap";
import "./TeamleadBulkAssignment.css";
import moment from "moment";
import MySelect from "../../../shared/select/Select";
import { DatePicker, message } from "antd";
import {retreiveAvailableShift,retreiveShiftMembers} from "../../../actions/shiftAction";
// import { retreiveAllNomenclature } from "../../../actions/nomenclatureAction";
import DisplayForPermission from "../../../shared/DisplayForPermission/DisplayForPermission";
import { AssigneeTeamlead, retreiveShiftCallendar } from "../../../actions/shiftCallendarAction ";


function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().startOf('day');
}


export default function TeamleadBulkAssignment({calRange}) {
  const [ModalBulkAssignee, setModalBulkAssignee] = useState(false);
  function toggleTeamleadAssignement() { setModalBulkAssignee(!ModalBulkAssignee);}
  const dispatch = useDispatch();
  const nomenclatures = useSelector(
    (state) => state.nomenclatureReducer.AllNomenclatures
  );
  const currentTimeZone = useSelector((state) => state.user.timeZone);
  const AllMembers = useSelector((state) => state.shiftReducer.AllMembers.docs);
  const isLoadingMembers = useSelector(
    (state) => state.shiftReducer.isLoadingMembers
  );
  const AvailableShift = useSelector(
    (state) => state.shiftReducer.AvailableShift.docs
  );
  const isLoadingAvailableShift = useSelector(
    (state) => state.shiftReducer.isLoadingAvailableShift
  );

  // USeState Hook
  const [Start_Time, setStart_Time] = useState();
  const [End_Time, setEnd_Time] = useState();
  const [shiftName, setshiftName] = useState();
  const [optionMember, setoptionMember] = useState([]);
  const [teamLeader, setteamLeader] = useState();
  const [selectedTask, setSelectedTask] = useState([]);

  //map nomenclatures to object value/label for select option
  const nomenclaturesOptions = nomenclatures?.map((nom) => ({
    value: nom._id,
    label: nom.name,
  }));
  function handleSyncBulkAssignee() {
    // dispatch(retreiveAllNomenclature())
    toggleTeamleadAssignement()
    setSelectedTask([])
    setteamLeader(null)
    setshiftName()
    setStart_Time()
    setEnd_Time()
  }
  function handleShiftName(e) {
    if (e) {
      setshiftName(e.label);
      setteamLeader(null);
      setSelectedTask([])
    } else {
      setshiftName();
      setteamLeader(null);
      setSelectedTask([])
    }
  }

  function ShiftHistory(member) {
    return (
      <>
        {member.user.firstName + " " + member.user.lastName + " "}
        <Badge color="primary">{member.total}</Badge>
      </>
    );
  }
  function handlePeriodTime(e) {
    if (e) {
      setStart_Time(moment(e[0]._d).format("YYYY-MM-DD"));
      setEnd_Time(moment(e[1]._d).format("YYYY-MM-DD"));
      setSelectedTask([])
    } else {
      setStart_Time();
      setEnd_Time();
      setteamLeader(null);
      setSelectedTask([])

    }
  }
  const handleSelectTask = (e, index) => {
    const values = [...selectedTask];
    if (selectedTask.includes(e.target.value)) {
      values.splice(selectedTask.indexOf(e.target.value), 1);
    } else {
      values.push(e.target.value);
    }

    setSelectedTask(values);
  };
  const handleSelectTaskFromDiv = (value, index) => {
    const values = [...selectedTask];
    if (selectedTask.includes(value)) {
      values.splice(selectedTask.indexOf(value), 1);
    } else {
      values.push(value);
    }

    setSelectedTask(values);
  };
  const handleSelectAllTask = () => {
    if (selectedTask?.length < AvailableShift?.length) {
      setSelectedTask(AvailableShift.map((shift) => shift.shift.startDate));
    } else {
      setSelectedTask([]);
    }
  };
  useEffect(() => {
    if (Start_Time && End_Time && shiftName) {
      dispatch(retreiveShiftMembers(Start_Time, End_Time, shiftName));
    }
  }, [dispatch, Start_Time, End_Time, shiftName]);
  function handleMembers() {
    if (Start_Time && End_Time && shiftName && !isLoadingMembers) {
      return false;
    } else {
      return true;
    }
  }
  useEffect(() => {
    if (AllMembers)
      setoptionMember(
        AllMembers.map((member) => ({
          value: member.user._id,
          label: ShiftHistory(member),
        }))
      );
  }, [AllMembers]);

  useEffect(() => {
    if (Start_Time && End_Time && shiftName&&teamLeader)
      dispatch(
        retreiveAvailableShift(
          Start_Time,
          End_Time,
          true,
          true,
          shiftName,
          teamLeader?.value,
          currentTimeZone
        )
      );
  }, [dispatch, Start_Time, End_Time, shiftName, teamLeader, currentTimeZone]);

  const handleAssignee=async()=> {
    const newValues = {
      user: teamLeader.value,
      shifts: selectedTask.map((startdate) => ({
        name: shiftName,
        startDate: startdate,
      })),
    };
    const res = await dispatch(AssigneeTeamlead(newValues))
    if(res?.status===200 ){
      calRange && dispatch( retreiveShiftCallendar(calRange.start, calRange.end, true, true, currentTimeZone) );
      setModalBulkAssignee(!ModalBulkAssignee)
      message.success("Teamleader successfully assigned",3);
    }else{
      message.error("An error has occured. Please try again",3);
    }
  
  }

  return (
    <div className="TeamLead-Bulk-Assignee-container">
      <DisplayForPermission permissionCode={1701}>
      <Button outline onClick={handleSyncBulkAssignee}>
        Teamlead Bulk Assign
      </Button>
      </DisplayForPermission>
      <Modal
        className="TeamLead-Bulk-Assignee-Modal"
        isOpen={ModalBulkAssignee}
        size="xl"
        toggle={toggleTeamleadAssignement}
        centered
        fade={false}
        backdrop="static"
        keyboard={true}
      >
        <ModalHeader toggle={toggleTeamleadAssignement}>
          Assign Team Leader
        </ModalHeader>
        <ModalBody className="TeamLead-Bulk-Assignee-ModalBody">
          <div className="handleTeam-section">
            <FormGroup>
              <Label>Shift</Label>
              <MySelect
                isClearable={true}
                options={nomenclaturesOptions}
                onChange={(e) => handleShiftName(e)}
              ></MySelect>
            </FormGroup>
            <FormGroup>
              <Label>Select Period</Label>
              <div>
                <DatePicker.RangePicker
                  className="TLassignDatePicker"
                  disabledDate={disabledDate}
                  onChange={(e) => handlePeriodTime(e)}
                />
              </div>
            </FormGroup>
              <FormGroup>
                <Label>Team Leader</Label>
                <MySelect
                  isClearable={true}
                  isDisabled={handleMembers()}
                  options={optionMember}
                  isLoading={isLoadingMembers}
                  value={teamLeader}
                  onChange={(e) => setteamLeader(e)}
                ></MySelect>
              </FormGroup>
          </div>
          <div className="Available-shift">
            {Start_Time && End_Time && shiftName && teamLeader  && ( <FormGroup className="Available-shift-header" inline check>
              <Label check>
                <Input
                  type="checkbox"
                  checked={selectedTask?.length === AvailableShift?.length}
                  onChange={() => handleSelectAllTask()}
                />
                Select all shifts
              </Label>
            </FormGroup> 
             )}
 
            <Card body className="Available-shift-list">
              {Start_Time && End_Time && shiftName && teamLeader && (
                <>
                  {isLoadingAvailableShift === false ? (
                    <>
                      {AvailableShift && AvailableShift.length > 0 ? (
                        <>
                          {AvailableShift
                          .map((shift, index) => {
                            return (
                              <div
                                key={index}
                                className={
                                  "ShiftCard" +
                                  " " +
                                  (shift.teamLeader
                                    ? "ShiftCardAssigned"
                                    : "ShiftCardunAssigned")
                                }
                                onClick={(e) =>
                                  handleSelectTaskFromDiv(shift.shift.startDate, index)
                                }
                              >
                                <Row 
                                key={index}
                              >
                                  <Col sm="1">
                                    <input
                                      type="checkbox"
                                      value={shift.shift.startDate}
                                      checked={selectedTask.includes(
                                        shift.shift.startDate
                                      )}
                                      name="shift"
                                      onChange={(e) =>
                                        handleSelectTask(e, index)
                                      }
                                    />
                                  </Col>
                                  <Col>
                                    {moment(shift.shift.startDate).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </Col>
                                  <Col>
                                    {moment(shift.shift.endDate).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </Col>
                                  <Col>
                                    <Badge
                                      pill
                                      color={
                                        shift.teamLeader ? "success" : "warning"
                                      }
                                    >
                                      {shift.teamLeader
                                        ? shift.teamLeader.firstName +
                                          " " +
                                          shift.teamLeader.lastName
                                        : "Unassigned"}
                                    </Badge>
                                  </Col>
                                </Row>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: 100,
                            opacity: .5,
                          }}
                        >
                          No Shift Available
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: 100,
                        }}
                      >
                        <Spinner color="dark" />
                      </div>
                    </>
                  )}
                </>
              )}
            </Card>
          </div>
        </ModalBody>

        <ModalFooter>
          {selectedTask?.length > 0 ? (
            <Button color="primary" onClick={handleAssignee}>
              Assign
            </Button>
          ) : (
            <Button color="primary" disabled>
              Assign
            </Button>
          )}
          <Button onClick={toggleTeamleadAssignement}>Close</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
