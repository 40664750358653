import types from "./types";
import moduleService from "../services/moduleService";
import { message } from "antd";
const {  
    LOAD_MODULE,
    GET_ALL_MODULE_SUCCESS,
    GET_ALL_MODULE_FAILURE,
    RETRIEVE_ONE_MODULE_SUCCESS,
    RETRIEVE_ONE_MODULE_FAILURE,
    CREATE_MODULE_SUCCESS,
    CREATE_MODULE_FAILURE,
    UPDATE_MODULE_SUCCESS,
    UPDATE_MODULE_FAILURE,
    CLEAR_MODULE
  } = types.moduleTypes;

  export const retrieveAllModule = (page, size, searchValue) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_MODULE,
      });
  
      const res = await moduleService.getAllModule(page, size, searchValue);
      dispatch({
        type: GET_ALL_MODULE_SUCCESS,
        payload: res.data.data,
      });
      return res.data.data.docs;
    } catch (err) {
      dispatch({
        type: GET_ALL_MODULE_FAILURE,
        payload: err,
      });
    }
  };

  export const retrieveAllBasic = () => async (dispatch) => {
    try {
     
  
      const res = await moduleService.getAllBasic(0, 1000);

      return res.data.data.docs;
    } catch (err) {
      dispatch({
        type: GET_ALL_MODULE_FAILURE,
        payload: err,
      });
    }
  };

  export const RetreiveOneModule = (id) => async (dispatch) => {
    try {
      // dispatch({
      //   type: LOAD_MODULE,
      // });
      const res = await moduleService.getOneModule(id);
      dispatch({
        type: RETRIEVE_ONE_MODULE_SUCCESS,
        payload: res.data.data,
      });
      return res.data.data;
    } catch (err) {
      dispatch({
        type: RETRIEVE_ONE_MODULE_FAILURE,
        payload: err.response.data.message,
      });
    }
  };

  export const createModule = (module) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_MODULE,
      });
      const res = await moduleService.createModule(module);
      message.success("Successfully created the module");
      dispatch({
        type: CREATE_MODULE_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      message.error("Failed to create the module");
      dispatch({
        type: CREATE_MODULE_FAILURE,
        payload: err,
      });
    }
  };

  export const updateModule= (id, data) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_MODULE,
      });
      const res = await moduleService.updateModule(id, data);
      message.success("Successfully updated the module");
      dispatch({
        type: UPDATE_MODULE_SUCCESS,
        payload: res.data.data,
      });
  
      // const resAll = await categoryService.getAll(page, size);
      // dispatch({
      //   type: GET_ALL_MODULE_SUCCESS,
      //   payload: resAll.data.data,
      // });
  
    } catch (err) {
      message.error("Failed to update the module");
      dispatch({
        type: UPDATE_MODULE_FAILURE,
        payload: err?.response?.data?.message,
      });
    }
  };

   //clear Data
 export const clearModule = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_MODULE,
  })
}