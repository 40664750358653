import { logout } from "../actions/auth.action";
import { resetUser } from "../actions/userAction";
import http from "../api";
import { message } from "antd";

const tokenInterceptor = (dispatch) => {
  http.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const statusCodeArray = [6010, 6011, 6012, 6013, 6014, 6015];
      const responseStatusCode = error.response.data.statusCode;
      if (
        error.response.status === 401
        && statusCodeArray.includes(responseStatusCode)
      ) {

        // prevent infinityLoop
        const failedUrl = error.response.config.url;
        if (failedUrl.includes("/public/auth/refreshToken")) {
          dispatch(logout());
          dispatch(resetUser());
          return Promise.reject(error);
        } else {
          const accessToken = localStorage.getItem("ma-access-token");
          const refreshToken = localStorage.getItem("ma-refresh-token");
          const refreshResponce = await http.post(
            "/public/auth/refreshToken",
            {},
            {
              headers: {
                "x-auth-accessToken": accessToken,
                "x-auth-refreshToken": refreshToken,
              },
            }
          );
          if (refreshResponce?.status === 200) {
            const newToken = refreshResponce.data.accessToken;
            localStorage.setItem("ma-access-token", newToken);
            http.defaults.headers.common["x-auth-accessToken"] = newToken;

            error.config.headers["x-auth-accessToken"] = newToken;

            const newRefreshToken = refreshResponce.data.refreshToken;
            localStorage.setItem("ma-refresh-token", newRefreshToken);
            http.defaults.headers.common["x-auth-refreshToken"] =
              newRefreshToken;

            error.config.headers["x-auth-refreshToken"] = newRefreshToken;

            return http(error.config);
          } else {
            message.error("Session expired, please login again");
            dispatch(logout());
            dispatch(resetUser());
            return;
          }
        }
      }
      return error;
    }
  );
};

export default tokenInterceptor;
