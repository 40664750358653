import * as Yup from "yup";
const schema = Yup.object().shape({
  name: Yup.string().required("The Name is required."),
  // eslint-disable-next-line no-useless-escape
  email: Yup.string().required("The Email is Required").matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Email is not valid"),
  phone: Yup.string()
      .required("The Phone is Required")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      ),
});
export default schema;
