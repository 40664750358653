import React, {useState, useEffect} from 'react';
import Form from '@rjsf/core';
import {Badge, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Table} from 'reactstrap';
import Select from '../../../../shared/select/Select';
import { Upload, message } from 'antd';
import 'antd/dist/antd.css';
import { BsTrash, BsCloudUpload } from 'react-icons/bs';
import './TaskResultModal.css';

//Sample data to be replaced with database data
//import { formLimits, formSchema, formUISchema } from './sample-schemas';
import { useSelector, useDispatch } from 'react-redux';
import { getFormSchema, resetFormSchema } from '../../../../actions/taskDynamicFormsAction';
import { createNewResult,GetLastResult, updateResult, getOneResult } from '../../../../actions/resultAction';



//Component Start - Adding Modal
const AddResultModal = ({taskID, setTaskID, addResultModal, toggleAddResultModal}) => {

  const dispatch      = useDispatch();
  const taskDetails   = useSelector(state => state.taskDynamicForm.taskDetails);
  const formConfig    = useSelector(state => state.taskDynamicForm.taskDetails?.resultFormConfig);
  const formLimits    = formConfig?.formLimits;
  const formSchema    = formConfig?.formSchema;
  const formUISchema  = formConfig?.formUISchema;
  const dataLoading   = useSelector(state => state.taskDynamicForm.isLoading);

  useEffect(() => {
    addResultModal && taskID ? dispatch( getFormSchema(taskID) ) : dispatch( resetFormSchema() );
  }, [dispatch, addResultModal, taskID]);

  const [propertiesObj, setPropertiesObj] = useState([]);
  const [dataSetArray, setDataSetArray] = useState([]);
  const [dataSetMsg, setDataSetMsg] = useState();

  useEffect(() => {
    if(addResultModal && taskID ){
      formSchema && setPropertiesObj(formSchema?.properties ? formSchema.properties : {...formSchema.oneOf[0].properties, ...formSchema.oneOf[1].properties});
    }else{
      setPropertiesObj([]);
    }
  }, [addResultModal, taskID, formSchema]);
  

  const formLayout = ( {idSchema, properties }) => {
    return (
      <fieldset id={idSchema.$id} className='row'>
        {properties.map(prop => {
          const uiSchema = prop.content.props.uiSchema
          const className = uiSchema['ui:column'] || 'col-12';
          return <div key={prop.content.key} className={className}>{prop.content}</div>
        })}
      </fieldset>
    )
  }
  
  const formErrors = (errors) => {
    errors.map((error) => {
      if(error.name==='required') error.message = 'Required';
      else if(error.name==='pattern') error.message = 'Invalid format';
      else if(error.name==='oneOf') error.message = '';
      return error;
    });
    return errors;
  }

  const formSubmit = ({formData}, e) => {
    setDataSetArray([...dataSetArray, formData]);
    setDataSetMsg();
  }

  const deleteFromDataSet = (idx) => setDataSetArray(dataSetArray.filter((line, index) => index!==idx));

  const [files, setFiles] = React.useState([]);
  //File dragger to configure
  const { Dragger } = Upload;
  const uploadProps = {
    name: "taskAttachements",
    multiple: true,
    onChange(info){
      const { status } = info.file;
      if(status!=='uploading') console.log(info.file, info.fileList);
      //if(status==='done') message.success(`${info.file.name} file uploaded successfully.`);
      //else if(status==='error') message.error(`${info.file.name} file upload failed.`);
    },
    //onDrop(e){ console.log('Dropped files', e.dataTransfer.files); }
  };

  const filesHandlerAdd = (file) => {
    setFiles(file.fileList);
  };

  const globalStatusOpts = [
    {value: "Critical", label: "Critical"},
    {value: "Warning", label: "Warning"},
    {value: "Good", label: "Good"}
  ];
  const [resultGolbalStatus, setResultGolbalStatus] = useState();
  const handleResGlobalstatus = (status) => setResultGolbalStatus(status);

  const handleResultSubmit = async () => {
    let resultOkay = true;
    const gsSelect = document.querySelector('#taskGlobalStatSelect > div:nth-child(2)');
    if(!resultGolbalStatus){gsSelect.classList.add('noValidSelect'); resultOkay = false;}
    else{ gsSelect.classList.remove('noValidSelect'); }
    if(formLimits.min>0 && dataSetArray.length<formLimits.min){ setDataSetMsg('Missing data!'); resultOkay = false; }
    else{ setDataSetMsg(); }

    if(resultOkay){
      const resultFormData = new FormData();
      if (files.length>0) {
        for (let i = 0; i < files.length; i++) {
          resultFormData.append("files", files[i].originFileObj);
        }      
      }
      resultFormData.set("gStatus", resultGolbalStatus.value);
      resultFormData.set("task", taskID);
      resultFormData.append("dataSet", JSON.stringify(dataSetArray));
      //console.log("dataSetArray", dataSetArray);
      const res = await dispatch(createNewResult(resultFormData));
      if(res){
        setDataSetArray([]);
        setResultGolbalStatus();
        setFiles([]);

        await setTaskID(null);
        toggleAddResultModal();
        message.success('Result submitted successfully');
      }else{
        message.error('An error has occured!');
      }
    }
  }

  const handleResultCancel = async() => {
    await setTaskID(null);
    setPropertiesObj([]);
    setDataSetMsg();
    toggleAddResultModal();
    clearLastResult(); // Fouad // => This line to clear LastResult.
  }
  

  //**********************************//Fouad Changes //************************************************* */
  const [ActiveGenerate, setActiveGenerate] = useState(false);
  const isloadingLastResult = useSelector(state => state.resultReducer.isLoadingLastResult);
  const IsExistResult = useSelector(state => state.resultReducer.error);

  const getLastResult= async() => {
    setActiveGenerate(true);
    const res = await dispatch(GetLastResult(taskID));
    if(res){
      setDataSetArray(res?.dataSet);
      setResultGolbalStatus({
        value : res?.gStatus,
        label : res?.gStatus 
      });
    }
  }

  const clearLastResult =()=>{
    setDataSetArray([]);
    setResultGolbalStatus(null);
    setActiveGenerate(false);
  }

  return(
    <Modal isOpen={addResultModal} toggle={toggleAddResultModal} className="taskResultModal addResultModal" backdrop="static" keyboard={true} size='xl' centered fade={false} >
      <ModalHeader toggle={handleResultCancel}>Add Task Result <Badge color="dark">{taskDetails?.title}</Badge></ModalHeader>
      <ModalBody>
      { 
        dataLoading 
        ? (<div className="formDataLoading"><Spinner color="secondary" /></div>)
        : !formConfig
          ? (<div className="formDataLoading"><span>Form configuration not found</span></div>) 
          : (
              <>
                <div className="dataSetContainer">
                  <FormGroup><Label>Prepare document</Label></FormGroup>
                  {( (formLimits.max===0) || (dataSetArray.length < formLimits.max && formLimits.max>0) ) && (
                    <Form id="taskResultDataSetForm" idPrefix={"dRS"} schema={formSchema} uiSchema={formUISchema} ObjectFieldTemplate={formLayout}
                      transformErrors={formErrors} noHtml5Validate={true} showErrorList={false} onError={()=>null} onSubmit={formSubmit}
                    >
                      <div className="dataSetSubmitContainer">
                        {dataSetMsg && (<p>{dataSetMsg}</p>)}
                        <button className="btn btn-info" type="submit">Add to Data Set</button>
                      </div>
                    </Form>
                  )}
                  {dataSetArray.length>0 && (
                    <Table className="avxBsTable small oneActs" responsive>
                      <thead>
                        <tr>
                          {Object.entries(propertiesObj).map((prop, indx)=>(<th key={indx}>{prop[1].title}</th>))}
                          <th><span><BsTrash /></span></th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                        dataSetArray.map((dataLine, idxd)=>(
                          <tr key={idxd}>
                            {Object.entries(propertiesObj).map((prop, indx)=>(<td key={indx}>{dataLine[prop[0]] || '-'}</td>))}
                            <td><span className="tbBtn" onClick={()=>deleteFromDataSet(idxd)}><BsTrash /></span></td>
                          </tr>
                        ))
                      }
                      </tbody>
                    </Table> 
                    )}
                </div>

                <div className="globalFormContainer">
                  <div className="globalFormInner">
                    <FormGroup className="taskDraggerContainer">
                      <Label>Attach files</Label>
                      <Dragger className="taskAttachDragger" {...uploadProps} name="file" onChange={(e)=>filesHandlerAdd(e)} >
                        <BsCloudUpload />
                        <p>Click or drag images or PDF here to upload</p>
                      </Dragger>
                    </FormGroup>
                    <FormGroup>
                      <Select id="taskGlobalStatSelect" placeholder="Global Status*" onChange={handleResGlobalstatus} value={resultGolbalStatus} options = {globalStatusOpts}/>
                    </FormGroup>
                  </div>
                </div>
              </>
          )
      }
      </ModalBody>
      <ModalFooter>
        <div className="generateLastResultButtonGroup">
          {taskDetails?.type === "Monitoring" && (
            <>
              {dataSetArray.length === 0 && (<Button color="primary" outline onClick={getLastResult}>Get latest result</Button>)}
              {ActiveGenerate === true && (
                isloadingLastResult === false 
                  ? (
                    IsExistResult 
                    ? <span className="Error-Message">No results found!</span>
                    : <Button color="danger"  outline onClick={clearLastResult}>Clear</Button>
                  ) 
                  : <Spinner size="sm" color="dark" />
              )}
            </>
          )}
        </div>
        <div>
          {formConfig && (<Button color="primary" onClick={handleResultSubmit}>Submit result</Button>)}{" "}
          <Button color="secondary" onClick={handleResultCancel}>Cancel</Button>
        </div>
      </ModalFooter>
    </Modal>
  )
};















//Component Start - Updating Modal
const UpdateResultModal = ({taskID, setTaskID, updateResultModal, toggleUpdateResultModal, taskData, setTaskData}) => {

  const dispatch      = useDispatch();
  const taskDetails   = useSelector(state => state.taskDynamicForm.taskDetails);
  const formConfig    = useSelector(state => state.taskDynamicForm.taskDetails?.resultFormConfig);
  const formLimits    = formConfig?.formLimits;
  const formSchema    = formConfig?.formSchema;
  const formUISchema  = formConfig?.formUISchema;
  const dataLoading   = useSelector(state => state.taskDynamicForm.isLoading);
  const resultDataLoading = useSelector(state => state.resultReducer.isLoadingOneResult);
  const oneResultData     = useSelector(state => state.resultReducer.oneResult);

  const disableFields = taskData && taskData.state === "Done" ? true : false;

  useEffect(() => {
    if(updateResultModal && taskID){
      dispatch( getFormSchema(taskID) );
      dispatch( getOneResult(taskID) );
    }else{
      dispatch( resetFormSchema() );
    }
  }, [dispatch, updateResultModal, taskID]);
  
  const [propertiesObj, setPropertiesObj] = useState([]);
  const [dataSetArray, setDataSetArray] = useState([]);
  const [dataSetMsg, setDataSetMsg] = useState();

  useEffect(() => {
    if(updateResultModal && taskID ){
      formSchema && setPropertiesObj(formSchema?.properties ? formSchema.properties : {...formSchema.oneOf[0].properties, ...formSchema.oneOf[1].properties});
    }else{
      setPropertiesObj([]);
    }
  }, [updateResultModal, taskID, formSchema]);

  const formLayout = ( {idSchema, properties }) => {
    return (
      <fieldset id={idSchema.$id} className='row'>
        {properties.map(prop => {
          const uiSchema = prop.content.props.uiSchema
          const className = uiSchema['ui:column'] || 'col-12';
          return <div key={prop.content.key} className={className}>{prop.content}</div>
        })}
      </fieldset>
    )
  }
  
  const formErrors = (errors) => {
    errors.map((error) => {
      if(error.name==='required') error.message = 'Required';
      else if(error.name==='pattern') error.message = 'Invalid format';
      return error;
    });
    return errors;
  }

  const formSubmit = ({formData}, e) => {
    setDataSetArray([...dataSetArray, formData]);
    setDataSetMsg();
  }

  const deleteFromDataSet = (idx) => setDataSetArray(dataSetArray.filter((line, index) => index!==idx));

  const [files, setFiles] = React.useState([]);
  const [oldFiles, setOldFiles] = React.useState([]);

  const swapFileContainers = () => {
    const nFilesCont = document.querySelector('.taskResultModal.updateResultModal .taskAttachDragger');
    const oFilesCont = document.querySelector('.taskResultModal.updateResultModal .oldFilesInner');
    const oFilesBSWT = document.querySelector('.taskResultModal.updateResultModal .filesSwitcher button');
    if(nFilesCont && oFilesCont){
      if(oFilesCont.classList.contains('fileContHide')){
        nFilesCont.classList.add('fileContHide');
        oFilesCont.classList.remove('fileContHide');
        oFilesBSWT.classList.add('isActive');
      }else{
        nFilesCont.classList.remove('fileContHide');
        oFilesCont.classList.add('fileContHide');
        oFilesBSWT.classList.remove('isActive');
      }
    }
  };

  const deleteFromOldFiles = (idx) => setOldFiles(oldFiles.filter((file, index) => index!==idx));

  // const open64 = (event, _base64Url) => {
  //   window.open(_base64Url, "_blank");
  // }


  //File dragger to configure
  const { Dragger } = Upload;
  const uploadProps = {
    name: "taskAttachements",
    multiple: true,
    onChange(info){
      const { status } = info.file;
      if(status!=='uploading') console.log(info.file, info.fileList);
    },
  };

  const filesHandlerAdd = (file) => {
    setFiles(file.fileList);
  };

  const globalStatusOpts = [
    {value: "Critical", label: "Critical"},
    {value: "Warning", label: "Warning"},
    {value: "Good", label: "Good"}
  ];
  const [resultGolbalStatus, setResultGolbalStatus] = useState();
  const handleResGlobalstatus = (status) => setResultGolbalStatus(status);

  useEffect(() => {
    if (oneResultData && !resultDataLoading) {
      setDataSetArray(oneResultData.dataSet);
      setResultGolbalStatus({
        value : oneResultData.gStatus,
        label : oneResultData.gStatus 
      });
      setOldFiles(oneResultData.files);
    }
  }, [oneResultData, resultDataLoading]);

  const handleResultSubmit = async () => {
    let resultOkay = true;
    const gsSelect = document.querySelector('#taskGlobalStatSelect > div:nth-child(2)');
    if(!resultGolbalStatus){gsSelect.classList.add('noValidSelect'); resultOkay = false;}
    else{ gsSelect.classList.remove('noValidSelect'); }
    if(formLimits.min>0 && dataSetArray.length<formLimits.min){ setDataSetMsg('Missing data!'); resultOkay = false; }
    else{ setDataSetMsg(); }

    if(resultOkay){
      const resultFormData = new FormData();
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          resultFormData.append("files", files[i].originFileObj);
        }
      }
      let oldFilesNames = oldFiles.map(oldFile => {
        return oldFile.name;
      })
      resultFormData.append("oldFiles", JSON.stringify(oldFilesNames));
      resultFormData.set("gStatus", resultGolbalStatus.value);
      resultFormData.set("task", taskID);
      resultFormData.append("dataSet", JSON.stringify(dataSetArray));
      const res = await dispatch(updateResult(taskID, resultFormData));
      if(res){
        setDataSetArray([]);
        setResultGolbalStatus();
        setFiles([]);
      }
      await setTaskID(null);
      toggleUpdateResultModal();
      message.success('Result updated successfully');
    }
  }

  const handleResultCancel = async() => {
    await setTaskID(null);
    await setTaskData();
    setPropertiesObj([]);
    setDataSetMsg();
    toggleUpdateResultModal();
  }


  return(
    <Modal isOpen={updateResultModal} toggle={toggleUpdateResultModal} className="taskResultModal updateResultModal" backdrop="static" keyboard={true} size='xl' centered fade={false} >
      <ModalHeader toggle={handleResultCancel}>Update Task Result <Badge color="dark">{taskDetails?.title}</Badge> {/*taskData?.state*/}</ModalHeader>
      <ModalBody>
      { 
        dataLoading || resultDataLoading
        ? (<div className="formDataLoading"><Spinner color="secondary" /></div>)
        : !formConfig
          ? (<div className="formDataLoading"><span>Form configuration not found</span></div>) 
          : (
              <>
                <div className="dataSetContainer">
                  <FormGroup><Label>{disableFields ? "Document Data set" : "Prepare document"}</Label></FormGroup>
                  {( !disableFields && ((formLimits.max===0) || (dataSetArray.length < formLimits.max && formLimits.max>0)) ) && (
                    <Form id="taskResultDataSetForm" idPrefix={"dRS"} schema={formSchema} uiSchema={formUISchema} ObjectFieldTemplate={formLayout}
                      transformErrors={formErrors} noHtml5Validate={true} showErrorList={false} onError={()=>null} onSubmit={formSubmit}
                    >
                      <div className="dataSetSubmitContainer">
                        {dataSetMsg && (<p>{dataSetMsg}</p>)}
                        <button className="btn btn-info" type="submit">Add to Data Set</button>
                      </div>
                    </Form>
                  )}
                  {dataSetArray.length>0 && (
                    <Table className={"avxBsTable small"+(!disableFields ? " oneActs" : "")} responsive>
                      <thead>
                        <tr>
                          {Object.entries(propertiesObj).map((prop, indx)=>(<th key={indx}>{prop[1].title}</th>))}
                          {!disableFields && <th><span><BsTrash /></span></th>}
                        </tr>
                      </thead>
                      <tbody>
                      {
                        dataSetArray.map((dataLine, idxd)=>(
                          <tr key={idxd}>
                            {Object.entries(propertiesObj).map((prop, indx)=>(<td key={indx}>{dataLine[prop[0]] || '-'}</td>))}
                            {!disableFields && <td><span className="tbBtn" onClick={()=>deleteFromDataSet(idxd)}><BsTrash /></span></td>}
                          </tr>
                        ))
                      }
                      </tbody>
                    </Table> 
                  )}
                </div>

                <div className="globalFormContainer">
                  <div className="globalFormInner">
                    <FormGroup className="taskDraggerContainer">
                      {!disableFields
                        ? <div className="filesSwitcher">
                            <Label>Attach new files</Label>
                            <Button outline size="sm" color="primary" onClick={swapFileContainers}>Attached Files</Button>
                          </div>
                        : <Label>Attached Files</Label>
                      }

                      {!disableFields &&
                        <Dragger className="taskAttachDragger" {...uploadProps} name="file" onChange={(e)=>filesHandlerAdd(e)} >
                          <BsCloudUpload />
                          <p>Click or drag images or PDF here to upload</p>
                        </Dragger>
                      }

                      <div className={"oldFilesInner"+(!disableFields ? " fileContHide" : '')}>
                      {oldFiles.map((oldFile, index) => (
                        <div key={oldFile.name}>
                          <a href={`data:image/jpeg;base64,${oldFile.data}`} target="_blank" rel="noreferrer">{'File '+(index+1)}</a>
                          {!disableFields && <span className="tbBtn" onClick={()=>deleteFromOldFiles(index)}><BsTrash /></span>}
                        </div>
                      ))}
                      </div>

                    </FormGroup>
                    <FormGroup>
                      <Select id="taskGlobalStatSelect" placeholder="Global Status*" onChange={handleResGlobalstatus} value={resultGolbalStatus} 
                      options = {globalStatusOpts} isDisabled={disableFields} />
                    </FormGroup>
                  </div>
                </div>
              </>
          )
      }
      </ModalBody>
      <ModalFooter>
        {formConfig && !disableFields && (<Button color="primary" onClick={handleResultSubmit}>Save changes</Button>)}{" "}
        <Button color="secondary" onClick={handleResultCancel}>{disableFields ? "Close" : "Cancel"}</Button>
      </ModalFooter>
    </Modal>
  )
};





export {
  AddResultModal,
  UpdateResultModal
}