import http from "../api";

// const getAll = (page, size) => {
//   return http.get(`/api/type?page=${page}&size=${size}`);
// };

const getAll = (page, size, isActive, searchValue) => {
  if (isActive){
  return searchValue ? http.get(`/api/type?page=${page}&size=${size}&isActive=${isActive}&searchValue=${searchValue}`) : http.get(`/api/type?page=${page}&size=${size}&isActive=${isActive}`) ;}
  else{
    return searchValue ? http.get(`/api/type?page=${page}&size=${size}&searchValue=${searchValue}`) : http.get(`/api/type?page=${page}&size=${size}`);
  }
};

const createType = (type) => {
  return http.post(`/api/type`, type);
};

const getOneType = (id) => {
  return http.get(`/api/type/${id}`);
};

const updateType = (id, type) => {
  return http.patch(`/api/type/${id}`, type);
};

const typeService = {
  getAll,
  createType,
  getOneType,
  updateType,
};
export default typeService;
