
var graph = require('@microsoft/microsoft-graph-client');
function getAuthenticatedClient(accessToken) {
    // Initialize Graph client
    const client = graph.Client.init({
      // Use the provided access token to authenticate
      // requests
      authProvider: (done) => {
        done(null, accessToken.accessToken);
      }
    });
  
    return client;
  }
  
  export async function getUserDetails(accessToken) {
    const client = getAuthenticatedClient(accessToken);
  
    const user = await client.api('/me').get();
    return user;
  }
 export async function  getShifts(accessToken) {
    const client =  getAuthenticatedClient(accessToken);
  const shifts = await client.api('/teams/d9f935aa-0d31-403d-9d4f-33728253b85a/schedule/shifts?$orderby=endDateTime desc') 
  .get();
    return shifts;
  }
  