import React, { useState,useEffect } from "react";
import "../../../adminPanel.css";
// import "./syncShiftLogAP.css"
import { Row, Col, Input, Button, Table, Spinner } from "reactstrap";
import { processTableHeight } from "../../../../../assets/js/CoreJs";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  retrieveSyncShifts,
  createSyncShift,
  clearSyncShifts
} from "../../../../../actions/syncShiftAction";

//import { SyncShiftLog } from "../../../adminPanel-dummy-data";

// import { useForm, Controller } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import schema from "./roleUserFormValidation";

const SyncShiftLogAP = () => {
  const dispatch = useDispatch();
  const shift = useSelector((state) => state.syncShiftReducer.shift);
  //console.log("shift ====> ", shift);
  const isloading = useSelector((state) => state.syncShiftReducer.isLoading);
  const isLoadingForCreation = useSelector((state) => state.syncShiftReducer.isLoadingForCreation);
  const error = useSelector((state) => state.syncShiftReducer.listError);
  // const ErrorAction = useSelector((state) => state.syncShiftReducer.error);
  const [refreshData, setrefreshData] = useState(false);
  const timeZone = useSelector((state) => state.user.timeZone);
  const applyTabHeightAdminPanelChildList = () => {
    processTableHeight(
      ["TitlePage", "adminPanelTabsNav", "syncShiftAPListHeader"],
      "syncShiftAPListTCONT",
      50
    );
  };
  useEffect(() => {
    applyTabHeightAdminPanelChildList();
    window.addEventListener("resize", applyTabHeightAdminPanelChildList);

    //return fn must be last in useEffect
    return () => {
      window.removeEventListener("resize", applyTabHeightAdminPanelChildList);
    };
  });

  // const {
  //   control,
  //   handleSubmit,
  //   register,
  //   setValue,
  //   //  watch,
  //   reset,
  //   formState: { errors },
  // } = useForm({
  //   resolver: yupResolver(schema),
  // });

  useEffect(() => {
    dispatch(retrieveSyncShifts());
  }, [dispatch, refreshData]);

  const handelAddFormSubmit = async () => {
    //dispatch(createSyncShift());

    if (window.confirm("Are you sure you want to Sync Shifts ?")) {
      await dispatch(createSyncShift());
      setrefreshData(!refreshData);
    }
  };

  //Clear Data
  useEffect(() => {
    return () => {
      dispatch(clearSyncShifts());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <>
      <section className="avxWrpContentPage syncShiftAPPage">
        <Row className="syncShiftAPListHeader">
          <Col xs="2">
            <Button color="primary" onClick={() => handelAddFormSubmit()}>
            Sync Shifts
            </Button>
            {isLoadingForCreation === false ? "": <Spinner className="marginRightSP" color="dark" /> }
          </Col>
          <Col xs={{ size: 4, offset: 6 }}>
            <Input
              type="search"
              className="form-control"
              placeholder="Search"
              //  onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Col>
        </Row>

        <div className="adminPanelChildListContent">
          <div id="syncShiftAPListTCONT" className="avxTableContainer scrollTb">
            <Table className="adminPanelChildListTable avxBsTable " striped>
              <thead>
                <tr>
                  <th>Imported Shifts</th>
                  <th>Errors</th>
                  <th>Sync Date</th>
                  <th>user</th>
                  <th>Method</th>
                </tr>
              </thead>

              {/* <tbody>
                {SyncShiftLog
                //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <tr key={index}>
                      <td>{row.syncedShifts}</td>
                      <td>{row.unSyncedShifts}</td>
                      <td>{row.shiftsSyncDate}</td>
                      <td>{row.user}</td>
                      
                    </tr>
                  ))}
              </tbody> */}

              {/* <tbody>
                {
                    <tr key={shift?._id}>
                      <td>{shift?.syncedShifts}</td>
                      <td>{shift?.unSyncedShifts}</td>
                      <td>{shift?.shiftsSyncDate}</td>
                      <td>{shift?.user}</td>
                      
                    </tr>
                  }
              </tbody> */}

              {isloading === false ? (
                <tbody>
                  {shift && shift.length>0 && error === null ? (
                    <>
                      {shift?.map((row) => (
                        <tr key={row._id}>
                          <td>{row.syncedShifts}</td>
                          <td>{row.unSyncedShifts}</td>
                          <td>{moment(row.shiftsSyncDate).tz(timeZone).format("DD-MM-YYYY - LT")}</td>
                          <td>{row?.user?.firstName ? row?.user?.firstName : "Not identified"} {row?.user?.lastName}</td>
                          <td>{row.methode ? row.methode : "Manual"}</td>
                          </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="4">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                          }}
                        >
                          <span> No Shift Exist {error} </span>
                          {/* <span>{error} </span> */}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="4">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: 100,
                        }}
                      >
                        <Spinner color="dark" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </Table>
          </div>
        </div>
      </section>
    </>
  );
};

export default SyncShiftLogAP;
