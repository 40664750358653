import React, { useState } from "react";
import "./adminPanel.css";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import CustomerAP from "./AdminPanelComponents/customerAP/CustomerAP";
import SystemAP from "./AdminPanelComponents/systemAP/SystemAP";
import UserAP from "./AdminPanelComponents/userAP/UserAP";
import ModuleManagementAP from "./AdminPanelComponents/moduleManagementAP/ModuleManagementAP";
import ResultFormAP from "./AdminPanelComponents/resultFormAP/ResultFormAP"
import SyncShiftAP from "./AdminPanelComponents/syncShift/SyncShiftAP"

export default function AdminPanel() {
  const [activeTab, setActiveTab] = useState("CU");

  const toggle = (tab) => {
    if (tab === "CU") {
    }
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      <section className="TitlePage">
        Admin Panel
        {/* <div className="Page_Description">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eligendi
          reiciendis veniam veli
        </div> */}
      </section>

      <section className="avxWrpContentPage adminPanelPage">
        <Nav tabs className="adminPanelTabsNav">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "CU" })}
              onClick={() => {
                toggle("CU");
              }}
            >
              Customer
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "SY" })}
              onClick={() => {
                toggle("SY");
              }}
            >
              System
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "US" })}
              onClick={() => {
                toggle("US");
              }}
            >
              User
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "MM" })}
              onClick={() => {
                toggle("MM");
              }}
            >
              Module Management
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "RF" })}
              onClick={() => {
                toggle("RF");
              }}
            >
              Result Forms
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "SS" })}
              onClick={() => {
                toggle("SS");
              }}
            >
              Sync Shift
            </NavLink>
          </NavItem>

        </Nav>

        <TabContent className="adminPanelTabsContent">
          {
            {
              CU: (
                <TabPane className="customerAPTab">
                  <CustomerAP />
                </TabPane>
              ),
              SY: (
                <TabPane className="systemAPTab">
                  <SystemAP />
                </TabPane>
              ),
              US: (
                <TabPane className="userAPTab">
                  <UserAP />
                </TabPane>
              ),
              MM: (
                <TabPane className="moduleManagementAPTab">
                  <ModuleManagementAP />
                </TabPane>
              ),
              RF: (
                <TabPane className="resultFormAPTab">
                  <ResultFormAP />
                </TabPane>
              ),
              SS: (
                <TabPane className="userAPTab">
                  <SyncShiftAP />
                </TabPane>
              ),
            }[activeTab]
          }
        </TabContent>
      </section>
    </>
  );
}
