import http from "../api";

const getAllRole = (page, size,isActive=null, paginate, searchValue) => {
  if (isActive){
  return searchValue ?  http.get(`/api/role?page=${page}&size=${size}&isActive=${isActive}&paginate=${paginate}&searchValue=${searchValue}`) : http.get(`/api/role?page=${page}&size=${size}&isActive=${isActive}&paginate=${paginate}`);
  }
  else{
    return searchValue ?  http.get(`/api/role?page=${page}&size=${size}&paginate=${paginate}&searchValue=${searchValue}`) : http.get(`/api/role?page=${page}&size=${size}&paginate=${paginate}`);
  }
  };

const createRole = (role) => {
  return http.post(`/api/role`, role);
};

const getOneRole = (id) => {
  return http.get(`/api/role/${id}`);
};

const updateRole = (id, role) => {
  return http.patch(`/api/role/update/${id}`, role);
};

const roleService = {
    getAllRole,
    createRole,
    getOneRole,
    updateRole,
};
export default roleService;