const GET_ALL_MONITORING_ACTIVITY_BY_SYSTEM_SUCCESS = "GET_ALL_MONITORING_ACTIVITY_BY_SYSTEM_SUCCESS";
const GET_ALL_MONITORING_ACTIVITY_BY_SYSTEM_FAILURE = "GET_ALL_MONITORING_ACTIVITY_BY_SYSTEM_FAILURE";
const LOAD_MONITORING_ACTIVITY = "LOAD_MONITORING_ACTIVITY";
const GET_ALL_MONITORING_ACTIVITY_SUCCESS = "GET_ALL_MONITORING_ACTIVITY_SUCCESS";
const GET_ALL_MONITORING_ACTIVITY_FAILURE = "GET_ALL_MONITORING_ACTIVITY_FAILURE";
const CREATE_MONITORING_ACTIVITY_SUCCESS = "CREATE_MONITORING_ACTIVITY_SUCCESS";
const CREATE_MONITORING_ACTIVITY_FAILURE = "CREATE_MONITORING_ACTIVITY_FAILURE";
const RETRIEVE_ONE_MONITORING_ACTIVITY_FAILURE = "RETRIEVE_ONE_MONITORING_ACTIVITY_FAILURE";
const RETRIEVE_ONE_MONITORING_ACTIVITY_SUCCESS = "RETRIEVE_ONE_MONITORING_ACTIVITY_SUCCESS";
const UPDATE_MONITORING_ACTIVITY_SUCCESS = "UPDATE_MONITORING_ACTIVITY_SUCCESS";
const UPDATE_MONITORING_ACTIVITY_FAILURE = "UPDATE_MONITORING_ACTIVITY_FAILURE";
const CLEAR_MONITORING_ACTIVITY = "CLEAR_MONITORING_ACTIVITY";

let monitoringActivityTypes = {
  LOAD_MONITORING_ACTIVITY,
  GET_ALL_MONITORING_ACTIVITY_BY_SYSTEM_SUCCESS,
  GET_ALL_MONITORING_ACTIVITY_BY_SYSTEM_FAILURE,
  GET_ALL_MONITORING_ACTIVITY_SUCCESS,
  GET_ALL_MONITORING_ACTIVITY_FAILURE,
  CREATE_MONITORING_ACTIVITY_SUCCESS,
  CREATE_MONITORING_ACTIVITY_FAILURE,
  RETRIEVE_ONE_MONITORING_ACTIVITY_SUCCESS,
  RETRIEVE_ONE_MONITORING_ACTIVITY_FAILURE,
  UPDATE_MONITORING_ACTIVITY_SUCCESS,
  UPDATE_MONITORING_ACTIVITY_FAILURE,
  CLEAR_MONITORING_ACTIVITY,
};
export default monitoringActivityTypes;
