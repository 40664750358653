import types from "./types";
import licenceService from "../services/licenceService";
import { message } from 'antd';
const {
  CREATE_LICENCE_SUCCESS,
  GET_ALL_LICENCES_SUCCESS,
  UPDATE_LICENCE_SUCCESS,
  CREATE_LICENCE_FAILURE,
  GET_ALL_LICENCES_FAILURE,
  UPDATE_LICENCE_FAILURE,
  LOAD_LICENCE,
  DELETE_LICENCE_FAILURE,
  DELETE_LICENCE_SUCCESS,
  RETRIEVE_ONE_LICENCE_FAILURE,
  RETRIEVE_ONE_LICENCE_SUCCESS,
  CLEAR_LICENCES,
  CLEAR_ONE_LICENCE
} = types.licenceTypes;

export const retrieveLicence =
  (customer_ID, page, size) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_LICENCE,
      });
      const res = await licenceService.getAllLicence(customer_ID, page, size);
      dispatch({
        type: GET_ALL_LICENCES_SUCCESS,
        payload: res.data.data,
      });
      return res.data.data;
    } catch (err) {
      dispatch({
        type: GET_ALL_LICENCES_FAILURE,
        payload: err.response?.data.message,
      });
    }
  };

export const createLicence =
  (customer, startDate, endDate) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_LICENCE,
      });
      const res = await licenceService.createLicence({
        startDate,
        endDate,
        customer,
      });
      message.success("Create Successfully",3);
      dispatch({
        type: CREATE_LICENCE_SUCCESS,
        payload: res.data.data,
      });
      return Promise.resolve(res.data.data);
    } catch (err) {
      message.error('An Error occurred while creating',3);      
      dispatch({
        type: CREATE_LICENCE_FAILURE,
        payload: err.response.data.message,
      });
    }
  };
export const deleteLicence = (id) => async (dispatch) => {
  try {
    const res = await licenceService.deleteLicence(id);
    message.success("Deleted Successfully",3);
    dispatch({
      type: DELETE_LICENCE_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    message.error('An Error occurred while deleting',3);    
    dispatch({
      type: DELETE_LICENCE_FAILURE,
      payload: err.response.data.message,
    });
  }
};
export const updateLicence = (id, data) => async (dispatch) => {
  try {
    const res = await licenceService.updateLicence(id, data);
    message.success("Updated Successfully",3);
    dispatch({
      type: UPDATE_LICENCE_SUCCESS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    message.error('An Error occurred while updating',3);   
    dispatch({
      type: UPDATE_LICENCE_FAILURE,
      payload: err.response.data.message,
    });
  }
};
export const RetreiveOneLicence = (id) => async (dispatch) => {
  try {
    const res = await licenceService.getOneLicence(id);

    dispatch({
      type: RETRIEVE_ONE_LICENCE_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {
    dispatch({
      type: RETRIEVE_ONE_LICENCE_FAILURE,
      payload: err.response.data.message,
    });
  }
};

//clear Licences
export const clearLicences = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_LICENCES,
  })
}

//clear One Licence
export const clearOneLicence = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_ONE_LICENCE,
  })
}
