import useItOperationsHelper, { structureSystems, structureOperations } from './useItOperationsHelper';


import { systemsList, operationsList } from '../data-sample';

const useItOperations = () => {

  //helper react utils
  const {currentTimeZone, processDefaultConfig} = useItOperationsHelper();

  const defaultconfig = processDefaultConfig();
  const systems = structureSystems(systemsList);
  const operations = structureOperations(operationsList , currentTimeZone);

  return {
    defaultconfig,
    systems,
    operations
  }
}

export default useItOperations;