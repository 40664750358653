import React, { useEffect,useState } from "react";
import { Row, Col, Card, CardTitle,Spinner } from "reactstrap";
import "./SystemStatus.css";
import {
  FcAcceptDatabase,
  FcDeleteDatabase,
  FcDataConfiguration,
} from "react-icons/fc";
import { FcDatabase } from "react-icons/fc";
import { useSelector, useDispatch } from "react-redux";
import { RetreiveSystemsInfo } from "../../../actions/systemAction";
import { Redirect } from "react-router-dom";

const SystemCard = (props) => {
  const { customerID } = props;
  const dispatch = useDispatch();
  const [redirect, setredirect] = useState(false)
  const systems = useSelector((state) => state.system.systemsInfo);
  const isLoadingSystemsInfo = useSelector((state) => state.system.isLoadingSystemsInfo);
  useEffect(() => {
    customerID && dispatch(RetreiveSystemsInfo(customerID));
  }, [dispatch, customerID]);
  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: "/app/globalReport/",
          data: { id: customerID },
        }}
      />
    );
  }
  return (
      <section className="systemCard">
        <div className="systemCardFooter">
        {!isLoadingSystemsInfo===false ? (
        <div
        className="centerLoader"
      >
        <div>
        <Spinner color="dark" />
        </div>
      </div>
    ) : (
        <>
        {systems && (
        <Row>
            {systems.map((CustomerSystem, IndexSystem) => {
              return (
                <Col xs="4" key={IndexSystem}>
                  <Card
                    className="singleSystemCard"
                    style={{
                      borderLeftStyle: "solid",
                      borderLeftColor:
                        CustomerSystem.Critical !== 0
                          ? "#d44344" //"#A74344"
                          : CustomerSystem.Warning !== 0 && CustomerSystem.Critical === 0
                          ? "#edb50c" // warning
                          : "#61a53c", //"#497233",
                    }}
                  >
                    <CardTitle style={{cursor: "pointer"}} tag="h6"onClick={()=>{ return setredirect(true) }}>
                      
                      {CustomerSystem.systemName}</CardTitle>
                    <div className="sysCardData">
                      <Row>
                        <Col>
                          <FcDatabase
                            style={{
                              fontSize: "23px",
                              fill: "#FA9F47",
                              color: "#886C3B",
                            }}
                          />
                          <label>
                            Warning <span>{CustomerSystem.Warning}</span>
                          </label>
                        </Col>
                        <Col>
                          <FcAcceptDatabase style={{ fontSize: "23px" }} />
                          <label>
                            Good <span>{CustomerSystem.Good}</span>
                          </label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FcDeleteDatabase style={{ fontSize: "23px" }} />
                          <label>
                            Critical <span>{CustomerSystem.Critical}</span>
                          </label>
                        </Col>
                        <Col>
                          <FcDataConfiguration style={{ fontSize: "23px" }} />
                          <label>
                            Maintenance <span></span>
                          </label>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
        )}         
        </>  
    )}       
        </div>
      </section>
  );
};

export default SystemCard;
