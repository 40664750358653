import * as Yup from "yup";
const schema = Yup.object().shape({
  description: Yup.string().required("The description is required"),
  estimatedStart: Yup.object()
    .required("You need to choose a Date and Time.")
    .nullable(),
  
  title: Yup.string().when("isActive", {
    is: false,
    then: Yup.string().required("The Title is required."),
  }),

  // title: Yup.string().required("The title is required"),
  
   




});
export default schema;
