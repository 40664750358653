import types from "../actions/types";

const {
    RETRIEVE_ALL_NOMENCLATURE_SUCCESS,
    RETRIEVE_ALL_NOMENCLATURE_FAILURE,
    LOAD_NOMENCLATURE
} = types.nomenclatureTypes;

const initialState = {
  AllNomenclatures: [],
  error: null,
  isLoadingNomenclature: false,
};

function nomenclatureReducer(state=initialState,action) {
const {type,payload}=action;

switch (type) {
    case RETRIEVE_ALL_NOMENCLATURE_SUCCESS:
        return {
         ...state,
         AllNomenclatures: payload,
         isLoadingNomenclature: false
        }
    case RETRIEVE_ALL_NOMENCLATURE_FAILURE:
      return {
         ...state,
         error: payload,
         isLoadingNomenclature: false
    };
    case LOAD_NOMENCLATURE:
        return {
          ...state,
          isLoadingNomenclature: true
        };
    default:
        return state;
}
    
}
export default nomenclatureReducer;
