import React,{useEffect} from "react";
import { clearCustomers } from "../../actions/customerAction";
import { clearSystemInfo } from "../../actions/systemAction";

import Feed from "../components/noteFeed/Feed";
import SystemeStatus from "../components/systemOverview/SystemeStatus";

import "./Home.css";
const Home = () => {
  //! ////////////////////////////////////
  ////*       Return Result JSX      *////
  //! ////////////////////////////////////
  useEffect(() => {
    return () => {  
      clearCustomers()
      clearSystemInfo()
      // setSelectedCustomer()
    }
});
  return (
    <>
      <section className="TitlePage">
        Dashboard
        {/* <div className="Page_Description">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eligendi
          reiciendis veniam veli
        </div> */}
      </section>
      <section className="avxWrpContentPage Home">
        <div className="feed">
          <Feed />
        </div>
        <div className="systemStatus">
          <SystemeStatus />
        </div>
      </section>
    </>
  );
};

export default Home;
