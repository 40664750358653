import React from "react";
import Select from "react-select";

/*************************** Demo ***************************************
 
* ************  https://react-select.com/home#getting-started  **************************************************
 
* ************************************************************/
export default function MySelect(props) {
  //   const { ...props } = props;
  return (
    <div>
      <Select {...props} />
    </div>
  );
}
