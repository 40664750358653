import types from "./types";
import CustomerService from "../services/customerService";
import SystemService from "../services/systemService";
import shiftService from "../services/shiftService";
import taskService from "../services/taskService";
import mapService from "../services/mapService";
import { message } from "antd";
const {
  RETRIEVE_CUSTOMERS_FAILURE,
  RETRIEVE_CUSTOMERS_SUCCESS,
  LOAD_CUSTOMERS_TASK,
  LOAD_SYSTEMS_TASK,
  RETRIEVE_SYSTEMS_SUCCESS,
  RETRIEVE_SYSTEMS_FAILURE,
  LOAD_CATEGORIES_TASK,
  RETRIEVE_CATEGORIES_SUCCESS,
  RETRIEVE_CATEGORIES_FAILURE,

  LOAD_TYPES_TASK,
  RETRIEVE_TYPES_SUCCESS,
  RETRIEVE_TYPES_FAILURE,

  LOAD_MEMBERS_TASK,
  RETRIEVE_MEMBERS_SUCCESS,
  RETRIEVE_MEMBERS_FAILURE,
  LOAD_TASK,
  RETRIEVE_TASKS_SUCCESS,
  RETRIEVE_TASKS_FAILURE,
  ADD_TASKS_SUCCESS,
  ADD_TASKS_FAILURE,
  ADD_ONE_TASK_SUCCESS,
  ADD_ONE_TASK_FAILURE,

  LOAD_SYSTEMS_FOR_MAPS,
  RETRIEVE_SYSTEMS_FOR_MAPS_SUCCESS,
  RETRIEVE_SYSTEMS_FOR_MAPS_FAILURE,

  /// Wael work
  LOAD_FILTRED_TASK,
  GET_FILTRED_TASKS_SUCCESS,
  GET_FILTRED_TASKS_FAILURE,

  GET_TASKS_BY_USER_ID_SUCCESS,
  GET_TASKS_BY_USER_ID_FAILURE,

  GET_FILTRED_TASKS_REQUEST_SUCCESS,
  GET_FILTRED_TASKS_REQUEST_FAILURE,

  GET_TASKS_REQUEST_BY_USER_ID_SUCCESS,
  GET_TASKS_REQUEST_BY_USER_ID_FAILURE,
  GET_CREATED_TASKS_SUCCESS,
  GET_CREATED_TASKS_FAILURE,
  LOAD_CREATED_TASK,
  UPDATE_TASK_SUCCESS,
  UPDATE_TASK_FAILURE,


  LOAD_SYSTEM_STATS,
  GET_SYSTEM_STATS_SUCCESS,
  GET_SYSTEM_STATS_FAILURE,

  LOAD_SYSTEM_FOR_HIGHTLIGHT_STATS,
  GET_SYSTEM_STATS_FOR_HIGHTLIGHT_SUCCESS,
  GET_SYSTEM_STATS_FOR_HIGHTLIGHT_FAILURE,

  GET_SYSTEM_STATS_FILTER_SUCCESS,
  GET_SYSTEM_STATS_FILTER_FAILURE,

  GET_ONE_TASK_REQUEST_BY_USER_ID_SUCCESS,
  GET_ONE_TASK_REQUEST_BY_USER_ID_FAILURE,

  LOAD_ONE_TASK,

  UPDATE_TASKS_REQUEST_BY_USER_ID_SUCCESS,
  UPDATE_TASKS_REQUEST_BY_USER_ID_FAILURE,

  GET_ALL_TASKS_BY_CRITERIA_SUCCESS,
  GET_ALL_TASKS_BY_CRITERIA_FAILURE,
  LOAD_TASKS_BY_CRITERIA,

  CLEAR_TASK_TRACKING,
  CLEAR_MY_TASKS,
  CLEAR_TASK_MANAGEMENT,

  GET_ALL_TASKS_ACTIVITY_LOG_SUCCESS,
  GET_ALL_TASKS_ACTIVITY_LOG_FAILURE,
  LOAD_TASKS_ACTIVITY_LOG,

  CREATE_SYNC_TASKS_ACTIVITY_LOG_SUCCESS,
  CREATE_SYNC_TASKS_ACTIVITY_LOG_FAILURE,
  LOAD_SYNC_TASKS_ACTIVITY_LOG_FOR_CREATE,
  CLEAR_TASKS_ACTIVITY_LOG


} = types.taskTypes;

export const retreiveCustomer = (paginate, page, size, isActive) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_CUSTOMERS_TASK,
    });
    const res = await CustomerService.getAll(paginate, page, size, isActive);
    dispatch({
      type: RETRIEVE_CUSTOMERS_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: RETRIEVE_CUSTOMERS_FAILURE,
      payload: err,
    });
  }
};

export const retreiveSystems =
  (customerId, typeId, categoryId, systemId) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_SYSTEMS_TASK,
      });
      const res = await SystemService.getAllSystem(
        customerId,
        typeId,
        categoryId,
        systemId
      );
      dispatch({
        type: RETRIEVE_SYSTEMS_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: RETRIEVE_SYSTEMS_FAILURE,
        payload: err,
      });
    }
  };
export const retreiveCategories = (customerId, typeId) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_CATEGORIES_TASK,
    });
    const res = await SystemService.retreiveCategoryByCustomerIdByTypeId(
      customerId,
      typeId
    );
    dispatch({
      type: RETRIEVE_CATEGORIES_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: RETRIEVE_CATEGORIES_FAILURE,
      payload: err,
    });
  }
};

export const retreiveTypes = (customerId) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_TYPES_TASK,
    });
    const res = await SystemService.retreiveTypesByCustomerId(customerId);
    dispatch({
      type: RETRIEVE_TYPES_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: RETRIEVE_TYPES_FAILURE,
      payload: err,
    });
  }
};
export const retreiveMembers = (userID) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_MEMBERS_TASK,
    });
    const res = await shiftService.getCurrentMembers(userID);
    dispatch({
      type: RETRIEVE_MEMBERS_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: RETRIEVE_MEMBERS_FAILURE,
      payload: err,
    });
  }
};

export const generateTasks =
  (
    customerId,
    typeId,
    categoryId,
    systemId,
    memberId,
    timeZone,
    teamLeaderId,
    startDate,
    endDate,
    isRefresh = false
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: LOAD_TASK,
      });
      const res = await taskService.generateTasks(
        customerId,
        typeId,
        categoryId,
        systemId,
        memberId,
        timeZone,
        teamLeaderId,
        startDate,
        endDate
      );
      if (!isRefresh && res.data.data.length === 0) {
        message.warning("No tasks to generate for the given criteria");
      }
      dispatch({
        type: RETRIEVE_TASKS_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      message.error("Failed to generate the tasks");
      dispatch({
        type: RETRIEVE_TASKS_FAILURE,
        payload: err,
      });
    }
  };
export const BulkAddTasks = (newTasks) => async (dispatch) => {
  try {

    dispatch({
      type: LOAD_SYSTEM_FOR_HIGHTLIGHT_STATS,
    });
    
    const res = await taskService.AddManyTasks(newTasks);
    message.success('Successfully created the Tasks.');
    dispatch({
      type: ADD_TASKS_SUCCESS,
      payload: res.data.data,
    });

   
  } catch (err) {
    message.error("Failed to create the tasks");
    dispatch({
      type: ADD_TASKS_FAILURE,
      payload: err,
    });
  }
};
export const AddOneTasks = (newTask, timeZone) => async (dispatch) => {
  try {
    const res = await taskService.AddOneTask(newTask, timeZone);
    message.success('Successfully added the task');
    dispatch({
      type: ADD_ONE_TASK_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    message.error('Failed to add the task');
    dispatch({
      type: ADD_ONE_TASK_FAILURE,
      payload: err,
    });
  }
};

export const retreiveSystemsForMaps = (system_Id,timeZone) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_SYSTEMS_FOR_MAPS,
    });
    const res = await mapService.getAllMap(system_Id,timeZone, 1, 10000000);
    //console.log("res in action ====>",res)
    dispatch({
      type: RETRIEVE_SYSTEMS_FOR_MAPS_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {
    dispatch({
      type: RETRIEVE_SYSTEMS_FOR_MAPS_FAILURE,
      payload: err,
    });
  }
};

export const retrieveFiltredTasks =
  (type, state, timeZone, searchValue) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_FILTRED_TASK,
      });

      const res = await taskService.getFiltredTasks(
        type,
        state,
        timeZone,
        searchValue
      );
      //console.log("state.toString()", state.toString())
      dispatch({
        type: GET_FILTRED_TASKS_SUCCESS,
        payload: res.data.data,
      });
      return res.data.data.docs;
    } catch (err) {
      dispatch({
        type: GET_FILTRED_TASKS_FAILURE,

        payload: err,
      });
    }
  };

  export const retrieveTasksByUserId =
  (type, state, timeZone, searchValue) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_FILTRED_TASK,
      });

      const res = await taskService.getTasksByUserId(
        type,
        state,
        timeZone,
        searchValue
      );
      // console.log("res retrieveTasksByUserId in action =====> ", res)
      //console.log("state.toString()", state.toString())
      dispatch({
        type: GET_TASKS_BY_USER_ID_SUCCESS,
        payload: res.data.data,
      });
      // console.log("res retrieveTasksByUserId in action 22 =====> ", res.data.data)
      return res.data.data;
      
    } catch (err) {
      dispatch({
        type: GET_TASKS_BY_USER_ID_FAILURE,

        payload: err,
      });
    }
  };


  export const retrieveFiltredTasksRequest =
  (type, state, timeZone, searchValue) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_FILTRED_TASK,
      });

      const res = await taskService.getFiltredTasks(
        type,
        state,
        timeZone,
        searchValue
      );
      //console.log("state.toString()", state.toString())
      dispatch({
        type: GET_FILTRED_TASKS_REQUEST_SUCCESS,
        payload: res.data.data,
      });
      return res.data.data.docs;
    } catch (err) {
      dispatch({
        type: GET_FILTRED_TASKS_REQUEST_FAILURE,

        payload: err,
      });
    }
  };

  export const retrieveTasksRequestByUserId =
  (type, state, timeZone, searchValue) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_FILTRED_TASK,
      });

      const res = await taskService.getTasksByUserId(
        type,
        state,
        timeZone,
        searchValue
      );
      //console.log("state.toString()", state.toString())
      dispatch({
        type: GET_TASKS_REQUEST_BY_USER_ID_SUCCESS,
        payload: res.data.data,
      });
      return res.data.data.docs;
    } catch (err) {
      dispatch({
        type: GET_TASKS_REQUEST_BY_USER_ID_FAILURE,

        payload: err,
      });
    }
  };


  export const getCreatedTask = (customerId,typeId,categoryId,systemId,memberId,timeZone,teamLeaderId,startDate,endDate) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_CREATED_TASK,
      });
      const res = await taskService.getCreatedTask(customerId,typeId,categoryId,systemId,memberId,timeZone,teamLeaderId,startDate,endDate);
      if (res.data.data.length === 0) {
        message.warning("No tasks found");
      }
      dispatch({
        type: GET_CREATED_TASKS_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: GET_CREATED_TASKS_FAILURE,
        payload: err,
      });
    }
  };

  export const getSystemStats = (timeZone,userId) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_SYSTEM_STATS,
      });
      const res = await taskService.getSystemStats(timeZone,userId);
      dispatch({
        type: GET_SYSTEM_STATS_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: GET_SYSTEM_STATS_FAILURE,
        payload: err,
      });
    }
  };


  export const getSystemStatsForHighlight = (timeZone,userId) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_SYSTEM_FOR_HIGHTLIGHT_STATS,
      });
      const res = await taskService.getSystemStats(timeZone,userId);
      dispatch({
        type: GET_SYSTEM_STATS_FOR_HIGHTLIGHT_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: GET_SYSTEM_STATS_FOR_HIGHTLIGHT_FAILURE,
        payload: err,
      });
    }
  };

  export const getSystemStatsFilter = (timeZone,userId,customerIDFilter) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_SYSTEM_STATS,
      });
      const res = await taskService.getSystemStatsFilter(timeZone,userId,customerIDFilter);
      dispatch({
        type: GET_SYSTEM_STATS_FILTER_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: GET_SYSTEM_STATS_FILTER_FAILURE,
        payload: err,
      });
    }
  };




   export const updateTask = (updatedTask) => async (dispatch) => {
    try { 
      dispatch({
        type: LOAD_SYSTEM_FOR_HIGHTLIGHT_STATS,
      });
      const res = await taskService.updateTask(updatedTask);
      dispatch({
        type: UPDATE_TASK_SUCCESS,
        payload: res.data.data,
      });
      if(res.status === 203){
        message.error(res.data?.message)
      } else {
        message.success("Successfully updated the task");
      }
    } catch (err) {
      message.error("Failed to update the task");
      dispatch({
        type: UPDATE_TASK_FAILURE,
        payload: err,
      });
    }
  };

  export const retreiveOneTask = (id) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_ONE_TASK,
      });
      const res = await taskService.getTaskById(id);
      dispatch({
        type: GET_ONE_TASK_REQUEST_BY_USER_ID_SUCCESS,
        payload: res.data.data,
      });
      return res.data.data;
    } catch (err) {
      dispatch({
        type: GET_ONE_TASK_REQUEST_BY_USER_ID_FAILURE,
        payload: err.response.data.message,
      });
    }
  };

  export const updateTaskMember= (id, data) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_FILTRED_TASK,
      });
      const res = await taskService.updateTaskMember(id, data);
      message.success("Updated Successfully",3)
      
      dispatch({
        type: UPDATE_TASKS_REQUEST_BY_USER_ID_SUCCESS,
        payload: res.data.data,
      });
  
      // const resAll = await categoryService.getAll(page, size);
      // dispatch({
      //   type: GET_ALL_MODULE_SUCCESS,
      //   payload: resAll.data.data,
      // });
  
    } catch (err) {
      
     message.error('An Error occurred while updating',3);     
      dispatch({
        type: UPDATE_TASKS_REQUEST_BY_USER_ID_FAILURE,
        payload: err?.response?.data?.message,
      });
    }
  };

  export const ApproveTask= (id, data) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_FILTRED_TASK,
      });
      const res = await taskService.validateTask(id, data);
      message.success("Task approved");
      dispatch({
        type: UPDATE_TASKS_REQUEST_BY_USER_ID_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      message.error("Failed to approve the task");
      dispatch({
        type: UPDATE_TASKS_REQUEST_BY_USER_ID_FAILURE,
        payload: err?.response?.data?.message,
      });
    }
  };
  export const RejectTask= (id, data) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_FILTRED_TASK,
      });
      const res = await taskService.updateTaskMember(id, data);
      message.success("Task rejected");
      dispatch({
        type: UPDATE_TASKS_REQUEST_BY_USER_ID_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      message.error("Failed to reject the task");
      dispatch({
        type: UPDATE_TASKS_REQUEST_BY_USER_ID_FAILURE,
        payload: err?.response?.data?.message,
      });
    }
  };

  export const retrieveAllTasksByCriteria =
  (page, size, paginate, searchValue, filter) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_TASKS_BY_CRITERIA,
      });

      const res = await taskService.getAllTasksByCriteria(page, size, paginate, searchValue, filter);
      //console.log("state.toString()", state.toString())
     // console.log("res in action", res.data.totalDocs)
      dispatch({
        type: GET_ALL_TASKS_BY_CRITERIA_SUCCESS,
        // payload: res.data.docs,
        payload: res.data,
      });
      //return res.data.data.docs;
      return res.data;
    } catch (err) {
      dispatch({
        type: GET_ALL_TASKS_BY_CRITERIA_FAILURE,

        payload: err,
      });
    }
  };

  //clear Data For Task Tracking
export const clearTasksTracking = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_TASK_TRACKING,
  })
}

  //clear Data For My Tasks
 export const clearTasks = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_MY_TASKS,
  })
}

//clear Data For Task Management
export const clearTasksManagement = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_TASK_MANAGEMENT,
  })
}

export const getAllTaskActivityLogs =
  (shiftName, searchValue, filter) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_TASKS_ACTIVITY_LOG,
      });

      const res = await taskService.getAllTaskActivityLogs(shiftName, searchValue, filter);
     
     
      dispatch({
        type: GET_ALL_TASKS_ACTIVITY_LOG_SUCCESS,
        // payload: res.data.docs,
        payload: res.data.data,
      });
      //return res.data.data.docs;
      return res.data.data;
    } catch (err) {
      dispatch({
        type: GET_ALL_TASKS_ACTIVITY_LOG_FAILURE,
        payload: err,
      });
    }
  };

  
  
  

  export const createSyncTaskActivityLog = () => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_SYNC_TASKS_ACTIVITY_LOG_FOR_CREATE,
      });
  
      const res = await taskService.createSyncTaskActivityLog();
      switch(res.status){
        case 200 : message.success("Summary successfully updated", 5); break;
        case 204 : message.warning("Summary is already up to date", 5); break;
        default : message.error("An error has occured", 5);
      }

      dispatch({
        type: CREATE_SYNC_TASKS_ACTIVITY_LOG_SUCCESS,
        payload: res.data.message,
      });
    } catch (err) {
      message.error("An error has occured", 5);
      dispatch({
        type: CREATE_SYNC_TASKS_ACTIVITY_LOG_FAILURE,
        payload: err,
      });
    }
  };

  //clear Data For Task Activity Log
export const clearTaskActivityLog = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_TASKS_ACTIVITY_LOG,
  })
}

 