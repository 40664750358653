import React, { useState, useEffect } from "react";
import "../../../adminPanel.css";
import {
  Row,
  Col,
  Input,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Form,
  Spinner,
} from "reactstrap";

import { TablePagination } from "@material-ui/core";
import { BsFillCircleFill, BsPencil } from "react-icons/bs";
import { makeDelay, processTableHeight } from "../../../../../assets/js/CoreJs";
import { Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  retrieveAllMonitoringActivity,
  createMonitoringActivity,
  RetreiveOneMonitoringActivity,
  updateMonitoringActivity,
  clearMonitoringActivity
} from "../../../../../actions/monitoringActivityAction";

//import { MonitoringActivity } from "../../../adminPanel-dummy-data";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./monitoringActivityFormValidation";

function MonitoringActivityAP() {
  const [page, setPage] = React.useState(0);
  //const [searchTerm, setSearchTerm] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  // const toggleAdd = () => {
  //   setAddModal(!addModal);
  //   setStatus(true);
  // };

  const toggleAdd = () => {
    setAddModal(!addModal);
    // setStatus(true);
    reset();
    setValue("isActive", true);
  };

  const toggleResetEdit = () => {
    setEditModal(!editModal);
    reset();
  };
  //const toggleEdit = () => setEditModal(!editModal);

  const [refreshData, setrefreshData] = useState(false);
  const dispatch = useDispatch();
  const monitoringActs = useSelector(
    (state) => state.monitoringActivityTypes.monitoringActs.docs
  );
  //console.log("Liste all monitoringActs ====>",monitoringActs)

  const isloading = useSelector(
    (state) => state.monitoringActivityTypes.isLoading
  );
  const error = useSelector((state) => state.monitoringActivityTypes.listError);
  const ErrorAction = useSelector(
    (state) => state.monitoringActivityTypes.error
  );
  const totalDocs = useSelector(
    (state) => state.monitoringActivityTypes.monitoringActs.totalDocs
  );
  const monitoringAct = useSelector(
    (state) => state.monitoringActivityTypes.monitoringAct
  );
  //console.log("Selected monitoringAct ====>",monitoringAct)
  const [searchValue, setSearchValue] = useState("");

  const applyTabHeightAdminPanelChildList = () => {
    processTableHeight(
      [
        "TitlePage",
        "adminPanelTabsNav",
        "adminPanelChildTabsNav",
        "monitoringActivityAPListHeader",
        "monitoringActivityAPPaginate",
      ],
      "monitoringActivityAPListTCONT",
      15
    );
  };

  useEffect(() => {
    applyTabHeightAdminPanelChildList();
    window.addEventListener("resize", applyTabHeightAdminPanelChildList);

    //return fn must be last in useEffect
    return () => {
      window.removeEventListener("resize", applyTabHeightAdminPanelChildList);
    };
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setrefreshData(!refreshData);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setrefreshData(!refreshData);
  };

  const {
    control,
    handleSubmit,
    register,
    setValue,
    //  watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    dispatch(retrieveAllMonitoringActivity(page, rowsPerPage, true, null, searchValue));
  }, [dispatch, page, rowsPerPage, searchValue, refreshData]);

  

  const handelAddFormSubmit = (newData) => {
    // console.log("newData ===>", newData);
    // newData.isActive=status

    dispatch(createMonitoringActivity(newData));

    setrefreshData(!refreshData);
    setAddModal(!addModal);
    reset();
    if (!ErrorAction) {
      setAddModal(!addModal);
    }
  };

  const toggleEdit = async (e, id) => {
    e.preventDefault();
    if (id) {
      const res = await dispatch(RetreiveOneMonitoringActivity(id));
      if (res) {
        // setActivity(res.activity);
        // setType(res.type);
        // setDescription(res.description);
        // setStatusUpd(res.isActive);

        setValue("activity", res.activity);
        setValue("type", res.type);
        setValue("description", res.description);
        setValue("isActive", res.isActive);
      }
    }
    setEditModal(!editModal);
  };

  

  //****Update Contract Type*****///
  async function handelUpdateFormSubmit(updatedData) {
    // hostData.isActive=true
    // hostData.system=selectedSystemID
    //console.log("hostData====>",hostData);
    await dispatch(updateMonitoringActivity(monitoringAct._id, updatedData));
    // setHost(null);
    setrefreshData(!refreshData);
    setEditModal(!editModal);
    reset();
    if (!error) {
      setrefreshData(!refreshData);
      setEditModal(!editModal);
    } else {
      //console.error(error);
    }
  }
  const handleSearchQuerry = (e) => {
    makeDelay(() => {
      if (e.target.value.length >= 2) {
        setSearchValue(e.target.value);
        setPage(0);
      } else {
        setSearchValue("");
      }
    }, 750);
  };

  useEffect(() => {
    return () => clearTimeout(window.timerDelay);
  }, []);

  //Clear Data
  useEffect(() => {
    return () => {
      dispatch(clearMonitoringActivity());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <>
      <section className="avxWrpContentPage monitoringActivityAPPage">
        <Row className="monitoringActivityAPListHeader">
          <Col xs="2">
            <Button color="primary" onClick={toggleAdd}>
              Add Monitoring Activity
            </Button>
          </Col>
          <Col xs={{ size: 4, offset: 6 }}>
            <Input
              type="search"
              className="form-control"
              placeholder="Search By Monitoring Activity"
              onChange={handleSearchQuerry}
              />
          </Col>
        </Row>

        <div className="adminPanelChildListContent">
          <div
            id="monitoringActivityAPListTCONT"
            className="avxTableContainer scrollTb"
          >
            <Table
              className="adminPanelChildListTable avxBsTable oneActs"
              striped
            >
              <thead>
                <tr>
                  <th>Monitoring Activity</th>
                  <th>Type</th>
                  <th>Description</th>

                  <th>
                    <span>
                      <BsPencil />
                    </span>
                  </th>
                </tr>
              </thead>

              {isloading === false ? (
                <tbody>
                  {monitoringActs?.length > 0 && error === null ? (
                    <>
                      {monitoringActs
                        
                        .map((ma) => (
                          <tr
                            key={ma._id}
                            className={
                              "contractTypeRow " +
                              (ma.isActive ? "activeC" : "disabledC")
                            }
                          >
                            <td>
                              <span className="stateFlag">
                                <BsFillCircleFill />
                              </span>
                              {ma.activity}
                            </td>
                            <td>{ma.type}</td>
                            <td>{ma.description}</td>
                            <td>
                              <span
                                className="tbBtn"
                                onClick={(e) => toggleEdit(e, ma._id)}
                              >
                                <BsPencil></BsPencil>
                              </span>
                            </td>
                          </tr>
                        ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="4">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                          }}
                        >
                          <span> No Monitoring Activity Exist </span>
                          {/* <span>{error} </span> */}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="4">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: 100,
                        }}
                      >
                        <Spinner color="dark" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </Table>
          </div>

          <TablePagination
            className="monitoringActivityAPPaginate"
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            component="div"
            count={totalDocs ? totalDocs : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>

        <Modal
          isOpen={addModal}
          toggle={toggleAdd}
          className="addAdminPanelChildModal"
          centered
          fade={false}
          backdrop="static"
          keyboard={true}
        >
          <Form
            onSubmit={handleSubmit(handelAddFormSubmit)}
            className="formAdminPanelChildModal"
          >
            <ModalHeader toggle={toggleAdd}>
              Add new Monitoring Activity
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label>
                  Monitoring Activity
                  <span className="requiredField"> *</span>
                </Label>
                <input
                  type="text"
                  name="monitoringActivityName"
                  id="monitoringActivityName"
                  className={
                    "form-control" + (errors.activity ? " is-invalid" : "")
                  }
                  {...register("activity")}
                />
                <small className="invalid-feedback">
                  {errors.activity && <>{errors.activity.message}</>}
                </small>
              </FormGroup>

              <FormGroup>
                <Label>
                  Type
                  <span className="requiredField"> *</span>
                </Label>
                <input
                  type="text"
                  name="typeName"
                  id="typeName"
                  className={
                    "form-control" + (errors.type ? " is-invalid" : "")
                  }
                  {...register("type")}
                />
                <small className="invalid-feedback">
                  {errors.type && <>{errors.type.message}</>}
                </small>
              </FormGroup>

              <FormGroup>
                <Label>
                  Description
                  <span className="requiredField"> *</span>
                </Label>
                <input
                  type="textarea"
                  name="descriptionName"
                  id="descriptionName"
                  className={
                    "form-control" + (errors.description ? " is-invalid" : "")
                  }
                  {...register("description")}
                />
                <small className="invalid-feedback">
                  {errors.description && <>{errors.description.message}</>}
                </small>
              </FormGroup>

              <FormGroup>
                <Label className="statusLabel">Active</Label>

                {/* <h1>{watch("isActive")+""}</h1> */}
                <Controller
                  control={control}
                  name="isActive"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Switch
                      onBlur={onBlur} // notify when input is touched
                      onChange={onChange} // send value to hook form
                      checked={value}
                      defaultChecked
                    />
                  )}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                type="submit"
                // onClick={() => handleCreateMonitoringActivity()}
              >
                Add Monitoring Activity
              </Button>{" "}
              <Button color="secondary" onClick={toggleAdd}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>

        <Modal
          isOpen={editModal}
          toggle={toggleEdit}
          className="editAdminPanelChildModal"
          centered
          fade={false}
          backdrop="static"
          keyboard={true}
        >
          <Form
            onSubmit={handleSubmit(handelUpdateFormSubmit)}
            className="formAdminPanelChildModal"
          >
            <ModalHeader toggle={toggleEdit}>
              Update Monitoring Activity
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label>
                  Monitoring Activity
                  <span className="requiredField"> *</span>
                </Label>
                <input
                  type="text"
                  name="monitoringActivityName"
                  id="monitoringActivityName"
                  className={
                    "form-control" + (errors.activity ? " is-invalid" : "")
                  }
                  {...register("activity")}
                />
                <small className="invalid-feedback">
                  {errors.activity && <>{errors.activity.message}</>}
                </small>
              </FormGroup>

              <FormGroup>
                <Label>
                  Type
                  <span className="requiredField"> *</span>
                </Label>
                <input
                  type="text"
                  name="typeName"
                  id="typeName"
                  className={
                    "form-control" + (errors.type ? " is-invalid" : "")
                  }
                  {...register("type")}
                />
                <small className="invalid-feedback">
                  {errors.type && <>{errors.type.message}</>}
                </small>
              </FormGroup>

              <FormGroup>
                <Label>
                  Description
                  <span className="requiredField"> *</span>
                </Label>
                <input
                  type="textarea"
                  name="descriptionName"
                  id="descriptionName"
                  className={
                    "form-control" + (errors.description ? " is-invalid" : "")
                  }
                  {...register("description")}
                />
                <small className="invalid-feedback">
                  {errors.description && <>{errors.description.message}</>}
                </small>
              </FormGroup>

              <FormGroup>
                <Label className="statusLabel">Active</Label>

                {/* <h1>{watch("isActive")+""}</h1> */}
                <Controller
                  control={control}
                  name="isActive"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Switch
                      onBlur={onBlur} // notify when input is touched
                      onChange={onChange} // send value to hook form
                      checked={value}
                      defaultChecked
                    />
                  )}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                type="submit"
                //onClick={() => handleEdit()}
              >
                Save Changes
              </Button>{" "}
              <Button color="secondary" onClick={toggleResetEdit}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </section>
    </>
  );
}

export default MonitoringActivityAP;
