import http from "../api";

const getMonitoringActBySystem = (system_Id) => {
  return http.get(`/api/monitoringActivity/bySystem/${system_Id}`);
};

const getAllMonitoringAct = (page, size, paginate, isActive=null, searchValue) => {
 // return http.get(`/monitoringActivity/?page=${page}&size=${size}&paginate=${paginate}&isActive=${isActive}`)`);

  if (isActive!==null){
    return searchValue ? http.get(`/api/monitoringActivity/?page=${page}&size=${size}&paginate=${paginate}&isActive=${isActive}&searchValue=${searchValue}`) : http.get(`/api/monitoringActivity/?page=${page}&size=${size}&paginate=${paginate}&isActive=${isActive}`);}
    else{
      return searchValue ? http.get(`/api/monitoringActivity/?page=${page}&size=${size}&paginate=${paginate}&searchValue=${searchValue}`) : http.get(`/api/monitoringActivity/?page=${page}&size=${size}&paginate=${paginate}`);
    }
};

const createMonitoringAct = (monitoringAct) => {
  return http.post(`/api/monitoringActivity`, monitoringAct);
};

const getOneMonitoringAct = (id) => {
  return http.patch(`/api/monitoringActivity/${id}`);
};

const updateMonitoringAct = (id, monitoringAct) => {
  return http.patch(`/api/monitoringActivity/${id}`, monitoringAct);
};


const monitoringActivityService = {
  getMonitoringActBySystem,
  getAllMonitoringAct,
  createMonitoringAct,
  getOneMonitoringAct,
  updateMonitoringAct,
};

export default monitoringActivityService;
