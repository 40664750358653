import React from "react";
import {
  Modal,
  Button,
  ModalHeader,
  Spinner,
  ModalFooter,
  ModalBody,
  FormGroup,
  Label,
  Badge,
  Table,
  Row,Col
} from "reactstrap";
import "./ModalTaskDetailsTaskTracking.css";
import moment from "moment";
export default function ModalTaskDetailsTaskTracking({
  toggleModalTaskDetails,
  modalTaskDetails,
  setModalTaskDetails,
  TaskData,
  system,
  oneResult,
  IsLoadingResult,
  TaskDataTitle
}) {
  const arrayKeys = oneResult?.dataSet?.length > 0 ? Object.keys(oneResult?.dataSet[0]) : [];
  const arrayValues = oneResult?.dataSet?.map((data, i) => {
    return Object.values(data);
  });
 
  return (
    <Modal
      isOpen={modalTaskDetails}
      size="xl"
      toggle={toggleModalTaskDetails}
      centered
      className="taskTrackingResultModal"
    >
      <ModalHeader toggle={toggleModalTaskDetails}>{system}</ModalHeader>
      {IsLoadingResult === false ? (
        <>
          {oneResult?.gStatus ? (
            <ModalBody  >
              <Row >
                <Col sm="9" className="customStyleTT">
                  <FormGroup >
                    <FormGroup>
                      <Badge
                        color={
                          oneResult?.gStatus === "Good"
                            ? "success"
                            : oneResult?.gStatus === "Warning"
                            ? "warning"
                            : "danger"
                        }
                        className="task-label-right"
                        style={{ fontSize: "14px" }}
                      >
                        {oneResult?.gStatus}
                      </Badge>

                      <Label>
                        <Badge
                          style={{ fontSize: "14px" }}
                          color="primary"
                          className="task-label-right"
                        >
                          {TaskDataTitle}
                        </Badge>
                      </Label>
                    </FormGroup>
                    {arrayKeys.length && arrayKeys.length > 0 && arrayValues.length > 0 ? (<Table
                      className="GlobalReportTable avxBsTable"
                      striped
                      hover
                    >
                      <thead>
                        <tr>
                          {arrayKeys?.map(function (data, i) {
                            return (
                              <th style={{ whiteSpace: "nowrap" }} key={i}>
                                {data}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody className="forPointer">
                        {arrayValues?.map((data, i) => {
                          return (
                            <tr key={i}>
                              {data.map((el, index) => {
                                return <td key={index}>{el}</td>;
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>): "No details"}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="taskDraggerContainer">
                    <Label>Attached Files</Label>
                    {oneResult.files.length > 0 ? (
                      <>
                        {oneResult.files.map((oldFile, index) => (
                          <div key={oldFile.name}>
                            <a
                              href={`data:image/${oldFile.extension};base64,${oldFile.data}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {"File " + (index + 1)}
                            </a>
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyItems: "center",
                            height: "100%",
                            opacity: .5,
                          }}
                        >
                          No file exist !
                        </div>
                      </>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label className="CreationTime">
                  created Time:{" "}
                  {moment(oneResult?.createdAt).format("DD/MM/YYYY LT")}
                </Label>
              </FormGroup>
            </ModalBody>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              No result data Exist
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Spinner color="dark" />
        </div>
      )}
      <ModalFooter>
        <Button onClick={() => setModalTaskDetails(!modalTaskDetails)}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}
