import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { BsChevronDoubleLeft } from "react-icons/bs";
import "./CustomerSystems.css";
import SystemMAPs from "./system-components/SystemMAPs";
import SystemHosts from "./system-components/SystemHosts";
import SystemClients from "./system-components/SystemClients";
import SystemContacts from "./system-components/SystemContacts";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
// import { CustomerSystems } from "./systems-dummy-data";

import { RetreiveOneSystem } from "../../../../actions/systemAction";

const CustomerSystemDetails = function ({
  selectedSysID,
  setSelectedSys,
  scrollPosition,
}) {
  //temporary to get index from fakeData json
  // const sysIndex = CustomerSystems.findIndex((s) => s.id === selectedSysID);
  // const sysRow = CustomerSystems[sysIndex];
  const dispatch = useDispatch();

  const selectedSystem = useSelector((state) => state.system.system);

  const handleBackAndScroll = () => {
    // temporary khalil fixed system ID
    new Promise((resolve) => {
      setSelectedSys("");
      resolve();
    }).then(() => {
      document.querySelector("html, body").scrollTop = scrollPosition;
    });
  };

  const [activeTab, setActiveTab] = useState("MAP");
  const toggleRender = (tab) => {
    activeTab !== tab && setActiveTab(tab);
  };

  useEffect(() => {
    dispatch(RetreiveOneSystem(selectedSysID));
  }, [dispatch, selectedSysID]);

  return (
    <div className="customerSystemDetails">
      <Row className="sysDetailHeader">
        <Col xs="4">
          <span onClick={handleBackAndScroll}>
            <BsChevronDoubleLeft></BsChevronDoubleLeft>
          </span>
          <h2>{selectedSystem?.name} System Details</h2>
        </Col>
        <Col xs="8">
          <div>
            <label>Class</label>
            <p>{selectedSystem?.class?.libelle}</p>
          </div>
          <div>
            <label>Type</label>
            <p>{selectedSystem?.type?.type}</p>
          </div>
          <div>
            <label>Category</label>
            <p>{selectedSystem?.category?.category}</p>
          </div>
          <div>
            <label>FQDN</label>
            <p>{selectedSystem?.fqdn}</p>
          </div>
          <div>
            <label>Deployment type</label>
            <p>{selectedSystem?.deployType}</p>
          </div>
          {selectedSystem?.deployDesc && (
            <div className="deplDesc">
              <label>Deployment description</label>
              <p>{selectedSystem?.deployDesc}</p>
            </div>
          )}
        </Col>
      </Row>

      <div className="sysDetailBody">
        <Nav tabs className="customerSystemDetailsTabsNav">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "MAP" })}
              onClick={() => {
                toggleRender("MAP");
              }}
            >
              Monitoring Activies
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "HOS" })}
              onClick={() => {
                toggleRender("HOS");
              }}
            >
              Hosts
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "CLI" })}
              onClick={() => {
                toggleRender("CLI");
              }}
            >
              Clients
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "CTS" })}
              onClick={() => {
                toggleRender("CTS");
              }}
            >
              System Contacts
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent className="customerSystemDetailsTabsContent">
          {
            {
              MAP: (
                <TabPane className="systemMapTab">
                  <SystemMAPs selectedSystemID={selectedSysID} />
                </TabPane>
              ),
              HOS: (
                <TabPane className="systemHostsTab">
                  <SystemHosts selectedSystemID={selectedSysID} />
                </TabPane>
              ),
              CLI: (
                <TabPane className="systemClientsTab">
                  <SystemClients selectedSystemID={selectedSysID} />
                </TabPane>
              ),
              CTS: (
                <TabPane className="systemContactsTab">
                  <SystemContacts selectedSystemID={selectedSysID} />
                </TabPane>
              ),
            }[activeTab]
          }
        </TabContent>
      </div>
    </div>
  );
};

export default CustomerSystemDetails;
