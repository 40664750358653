import types from '../actions/types';

const {
  LOAD_TASK_FORM,
  GET_TASK_FORM_SUCCESS,
  GET_TASK_FORM_FAILURE,
  RESET_TASK_FORM
} = types.taskDynamicFormsTypes;

const initialState = {
  taskDetails: null,
  isLoading: false,
  error: null
};

function taskDynamicForm(state=initialState, action){
  const { type, payload } = action;

  switch(type){

    case LOAD_TASK_FORM : return {
      ...state,
      isLoading: true
    };
  
    case GET_TASK_FORM_SUCCESS : return {
      ...state,
      taskDetails: payload,
      isLoading: false,
      error: null
    };
    
    case GET_TASK_FORM_FAILURE : return {
      ...state,
      isLoading: false,
      error: payload
    };

    case RESET_TASK_FORM : return {
      ...state,
      taskDetails: null,
      isLoading: false
    };

    default : return state;

  }
}

export default taskDynamicForm;
