import http from "../api";

const addResult = (newResult) => {
  return http.post("/api/result", newResult, {
    headers: {
      "Content-Type": undefined,
    }
  }
  );
};
const getLastResult = (TaskId) => {
  return http.get("/api/result/previousResult/" + TaskId)
};

const updateResult = (TaskId,updatedResult) => {
  return http.patch("/api/result/"+ TaskId, updatedResult, {
    headers: {
      "Content-Type": undefined,
    }
  }
  );
};

const getOneResult = (TaskId) => {
  return http.get("/api/result/" + TaskId)
};
const fetOneResultById = (resultId) => {
  return http.get("/api/result/byResultId/" + resultId)
};

const resultService = {
  addResult,
  getLastResult,
  updateResult,
  getOneResult,
  fetOneResultById
};
export default resultService;
