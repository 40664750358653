const AUTH_LOADING= 'AUTH_LOADING';
const LOGIN_SUCCESS= 'LOGIN_SUCCESS';
const LOGIN_FAILURE= 'LOGIN_FAILURE';
const MICROSOFT_LOGIN_SUCCESS= 'MICROSOFT_LOGIN_SUCCESS';
const MICROSOFT_LOGIN_FAILURE= 'MICROSOFT_LOGIN_FAILURE';
const REGISTER_SUCCESS= 'REGISTER_SUCCESS';
const REGISTER_FAILURE= 'REGISTER_FAILURE';
const GET_AUTHORIZATION_SUCCESS= 'GET_AUTHORIZATION_SUCCESS';
const GET_AUTHORIZATION_FAILURE= 'GET_AUTHORIZATION_FAILURE';
const LOGOUT= 'LOGOUT';
let authTypes={
    AUTH_LOADING,
    REGISTER_SUCCESS,
    REGISTER_FAILURE,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    MICROSOFT_LOGIN_FAILURE,
    MICROSOFT_LOGIN_SUCCESS,
    LOGOUT,
    GET_AUTHORIZATION_SUCCESS,
    GET_AUTHORIZATION_FAILURE
    };
export default authTypes
