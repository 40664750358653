import types from "./types";
import monitoringActivityService from "../services/monitoringActivityService";
import { message } from "antd";
const {
  LOAD_MONITORING_ACTIVITY,
  GET_ALL_MONITORING_ACTIVITY_BY_SYSTEM_SUCCESS,
  GET_ALL_MONITORING_ACTIVITY_BY_SYSTEM_FAILURE,
  GET_ALL_MONITORING_ACTIVITY_SUCCESS,
  GET_ALL_MONITORING_ACTIVITY_FAILURE,
  CREATE_MONITORING_ACTIVITY_SUCCESS,
  CREATE_MONITORING_ACTIVITY_FAILURE,
  RETRIEVE_ONE_MONITORING_ACTIVITY_SUCCESS,
  RETRIEVE_ONE_MONITORING_ACTIVITY_FAILURE,
  UPDATE_MONITORING_ACTIVITY_SUCCESS,
  UPDATE_MONITORING_ACTIVITY_FAILURE,
  CLEAR_MONITORING_ACTIVITY
} = types.monitoringActivityTypes;

export const retrieveMonitoringActivity = (system_Id) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_MONITORING_ACTIVITY,
    });
    const res = await monitoringActivityService.getMonitoringActBySystem(
      system_Id
    );
    dispatch({
      type: GET_ALL_MONITORING_ACTIVITY_BY_SYSTEM_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {
    dispatch({
      type: GET_ALL_MONITORING_ACTIVITY_BY_SYSTEM_FAILURE,
      payload: err.response.data.message,
    });
  }
};

export const retrieveAllMonitoringActivity = (page, size, paginate, isActive, searchValue) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_MONITORING_ACTIVITY,
    });
    const res = await monitoringActivityService.getAllMonitoringAct(page, size, paginate, isActive, searchValue);
    dispatch({
      type: GET_ALL_MONITORING_ACTIVITY_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data.docs;
  } catch (err) {
    dispatch({
      type: GET_ALL_MONITORING_ACTIVITY_FAILURE,
      payload: err,
    });
  }
};

export const createMonitoringActivity = (monitoringActivity) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_MONITORING_ACTIVITY,
    });
    const res = await monitoringActivityService.createMonitoringAct(monitoringActivity);
    message.success("Successfully created the monitoring activity");
    dispatch({
      type: CREATE_MONITORING_ACTIVITY_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    message.error("Failed to create the monitoring activity");
    dispatch({
      type: CREATE_MONITORING_ACTIVITY_FAILURE,
      payload: err,
    });
  }
};

export const RetreiveOneMonitoringActivity = (id) => async (dispatch) => {
  try {
    // dispatch({
    //   type: LOAD_MONITORING_ACTIVITY,
    // });
    const res = await monitoringActivityService.getOneMonitoringAct(id);
    dispatch({
      type: RETRIEVE_ONE_MONITORING_ACTIVITY_SUCCESS,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {
    dispatch({
      type: RETRIEVE_ONE_MONITORING_ACTIVITY_FAILURE,
      payload: err.response.data.message,
    });
  }
};

export const updateMonitoringActivity= (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_MONITORING_ACTIVITY,
    });
    const res = await monitoringActivityService.updateMonitoringAct(id, data);
    message.success("Successfully updated the monitoring activity");
    dispatch({
      type: UPDATE_MONITORING_ACTIVITY_SUCCESS,
      payload: res.data.data,
    });

    // const resAll = await categoryService.getAll(page, size);
    // dispatch({
    //   type: GET_ALL_MONITORING_ACTIVITY_SUCCESS,
    //   payload: resAll.data.data,
    // });

  } catch (err) {
    message.error("Failed to update the monitoring activity");
    dispatch({
      type: UPDATE_MONITORING_ACTIVITY_FAILURE,
      payload: err?.response?.data?.message,
    });
  }
};

 //clear Data
 export const clearMonitoringActivity = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_MONITORING_ACTIVITY,
  })
}

