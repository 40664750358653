import http from "../api";

// const getAll = (page, size) => {
//   return http.get(`/category?page=${page}&size=${size}`);
// };

const getAll = (page, size, isActive, searchValue) => {
  if (isActive){
  return searchValue ? http.get(`/api/category?page=${page}&size=${size}&isActive=${isActive}&searchValue=${searchValue}`) : http.get(`/api/category?page=${page}&size=${size}&isActive=${isActive}`);}
  else{
    return searchValue ? http.get(`/api/category?page=${page}&size=${size}&searchValue=${searchValue}`) : http.get(`/api/category?page=${page}&size=${size}`);
  }
};

const createCategory = (category) => {
  return http.post(`/api/category`, category);
};

const getOneCategory = (id) => {
  return http.get(`/api/category/${id}`);
};

const updateCategory = (id, category) => {
  return http.patch(`/api/category/${id}`, category);
};


const categoryService = {
  getAll,
  createCategory,
  getOneCategory,
  updateCategory,
};
export default categoryService;
