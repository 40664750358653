const ADD_ONE_MTP_SUCCESS = "ADD_ONE_MTP_SUCCESS";
const ADD_ONE_MTP_FAILURE = "ADD_ONE_MTP_FAILURE";
const GET_ALL_MTP_SUCCESS = "GET_ALL_MTP_SUCCESS";
const GET_ALL_MTP_FAILURE = "GET_ALL_MTP_FAILURE";
const LOAD_MTP = "LOAD_MTP";
const DELETE_MTP_SUCCESS = "DELETE_MTP_SUCCESS";
const DELETE_MTP_FAILURE = "DELETE_MTP_FAILURE";
const GET_ONE_MTP_SUCCESS = "GET_ONE_MTP_SUCCESS";
const GET_ONE_MTP_FAILURE = "GET_ONE_MTP_FAILURE";
const UPDATE_MTP_SUCCESS = "UPDATE_MTP_SUCCESS";
const UPDATE_MTP_FAILURE = "UPDATE_MTP_FAILURE";
const LOAD_MTP_ONE= "LOAD_MTP_ONE";
const CLEAR_MTP = "CLEAR_MTP";

let mtpTypes = {
  
    ADD_ONE_MTP_SUCCESS,
    ADD_ONE_MTP_FAILURE,
    GET_ALL_MTP_SUCCESS,
    GET_ALL_MTP_FAILURE,
    LOAD_MTP,
    DELETE_MTP_SUCCESS,
    DELETE_MTP_FAILURE,
    GET_ONE_MTP_SUCCESS,
    GET_ONE_MTP_FAILURE,
    UPDATE_MTP_SUCCESS,
    UPDATE_MTP_FAILURE,
    LOAD_MTP_ONE,
    CLEAR_MTP

  };
  export default mtpTypes;
  