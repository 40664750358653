import types from "../actions/types";

const {
  GET_MONITORING_TYPES_SUCCESS,
  GET_MONITORING_TYPES_FAILURE,
  LOAD_MONITORING_TYPE,
  CREATE_MONITORING_TYPE_SUCCESS,
  CREATE_MONITORING_TYPE_FAILURE,
  RETRIEVE_ONE_MONITORING_TYPE_SUCCESS,
  RETRIEVE_ONE_MONITORING_TYPE_FAILURE,
  UPDATE_MONITORING_TYPE_SUCCESS,
  UPDATE_MONITORING_TYPE_FAILURE,
  CLEAR_MONITORING_TYPES,
} = types.monitoringTypes;
const initialState = {
  monitoringType: null,
  monitoringTypes: [],
  error: null,
  isLoading: false,
  listError: null,
};

function monitoringTypeReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_MONITORING_TYPES_SUCCESS:
      return {
        ...state,
        monitoringTypes: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case GET_MONITORING_TYPES_FAILURE:
      return {
        ...state,
        error: payload,
        isLoading: false,
        listError: payload,
      };

    case LOAD_MONITORING_TYPE:
      return {
        ...state,
        isLoading: true,
      };

    case CREATE_MONITORING_TYPE_SUCCESS:
      state.monitoringTypes.docs.push(payload);
      return {
        ...state,
        monitoringTypes: state.monitoringTypes,
        isLoading: false,
        error: null,
        listError: null,
      };
    case CREATE_MONITORING_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

    case RETRIEVE_ONE_MONITORING_TYPE_SUCCESS:
      return {
        ...state,
        monitoringType: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case RETRIEVE_ONE_MONITORING_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

      case UPDATE_MONITORING_TYPE_SUCCESS:
        const updatedIndex = state.monitoringTypes.docs.map((monitoringType, index) => {
          if (payload._id === monitoringType._id) {
            return index;
          } else return null;
        });
        if (updatedIndex) state.monitoringTypes.docs[updatedIndex] = payload;
        return {
          ...state,
          monitoringTypes: state.monitoringTypes,
          isLoading: false,
          error: null,
          listError: null,
        };
  
      case UPDATE_MONITORING_TYPE_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: payload,
          listError: null,
        };
      
      case CLEAR_MONITORING_TYPES:
        return {
          ...state,
          monitoringTypes: [],
          monitoringType: null,
          isLoading: false,
          error: null,
          listError: null,
        };

    default:
      return state;
  }
}

export default monitoringTypeReducer;
