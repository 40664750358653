import http from "../api";

const getActiveUsersStatistics = (customerID,startDate,endDate,systemId, timeZone) => {
  let query =`/api/statistics/activeUsersStatistics?startDate=${startDate}&endDate=${endDate}&customerID=${customerID}&timeZone=${timeZone}`
  systemId && (query+=`&systemID=${systemId}`)
  return http.get(query);
};
const getavgResponseTimeStatistics = (customerID,startDate,endDate,systemId,timeZone) => {
  let query =`/api/statistics/avgResponseTime?startDate=${startDate}&endDate=${endDate}&customerID=${customerID}&timeZone=${timeZone}`
  systemId && (query+=`&systemID=${systemId}`)
  return http.get(query);
};

const getDbGrowthStatistics = (customerID,startDate,endDate,systemId, timeZone) => {
  let query =`/api/statistics/dbGrowthStatistics?startDate=${startDate}&endDate=${endDate}&customerID=${customerID}&timeZone=${timeZone}`
  systemId && (query+=`&systemID=${systemId}`)
  return http.get(query);
};

const statisticService = {
    getActiveUsersStatistics,
    getavgResponseTimeStatistics,
    getDbGrowthStatistics,
};

export default statisticService;
