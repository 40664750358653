import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "antd/dist/antd.css";
import "../../customer.css";
import { Upload } from "antd";
import { FiUploadCloud } from "react-icons/fi";
import moment from "moment-timezone";
import {
  Row,
  Col,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Form,
  Modal,
  Spinner
} from "reactstrap";
import { BsPencil } from "react-icons/bs";
import Select from "../../../../shared/select/Select";
import { getCustomer, updateCustomer } from "../../../../actions/customerAction";

import { getMonitoringType } from "../../../../actions/monitoringTypeAction";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./UpdateCustomerValidation";

const timeZones = moment.tz.names().map((timeZone) => ({
  value: timeZone,
  label: timeZone,
}));;

const UpdateCustomerModal = (props) => {
  const contractTypes = useSelector(
    (state) => state.monitoringType.monitoringTypes
  );
  const customer = useSelector((state) =>
    !state.customer.isLoading ? state.customer.customer : null
  );
  const currentTimeZone= useSelector((state) => state.user.timeZone);

  const isLoading = useSelector((state) => state.customer.isLoading);

  const dispatch = useDispatch();
  const [modalEdit, setModalEdit] = React.useState(false);
  const [listContractChecked, setListContractChecked] = React.useState([]);
  const [file, setFile] = React.useState(null);
  const [logo, setLogo] = React.useState(null);

  const { Dragger } = Upload;

  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  if(errors.timeZone){
    document.querySelector('.customerSelectTimeZone > div:nth-child(2)') &&
    document.querySelector('.customerSelectTimeZone > div:nth-child(2)').classList.add('isInvalidSelect');
  }

  const fileHandlerAdd = (file) => {
    setFile(file.fileList[0]);
  };


  useEffect(() => {
    if(contractTypes.docs && customer){
    let t = [];
    
    contractTypes.docs.forEach((c) => {
      let ok = false;
      if (customer.listMonitoringType != null){
        customer?.listMonitoringType?.forEach((m) => {
          if (c._id === m._id) {
            ok = true;
            t.push({ ...c, checked: true });
          }
        });
      }
      if (!ok) {
        t.push({ ...c, checked: false });
      }
    });
    setListContractChecked(t);
    setValue("label", customer?.label);
    setValue("address", customer?.address);
    const oldTimeZone = customer?.timeZone?.map((timeZone) => ({
      value: timeZone,
      label: timeZone,
    }));
    setValue("timeZone", oldTimeZone);
    //let firstReportFormat = moment.utc(customer?.firstReport, 'HH:mm').tz(currentTimeZone);
    setValue("firstReport", customer?.firstReport);
    setValue("status", customer?.isActive);
    if (customer.listMonitoringType != null){
      const oldTypes = customer?.listMonitoringType.map((m) => m._id);
      setValue("types", oldTypes);
    }
    if (customer?.isActive === true) {
      if (document.querySelector('#statusON') != null) {
        document.querySelector('#statusON').checked=true;
        document.querySelector('#statusOFF').checked=false;
      }
    }else {
      if (document.querySelector('#statusON') != null) {
        document.querySelector('#statusOFF').checked=true;
        document.querySelector('#statusON').checked=false;
      }
    }
    setLogo(customer?.logo)

  }
  }, [contractTypes, customer, setValue, currentTimeZone]);

  const toggleEdit = () => {
    if (!modalEdit) {
      dispatch(getMonitoringType(0,1000,true));
      dispatch(getCustomer(props.customerId, currentTimeZone));
    }
    setModalEdit(!modalEdit);
  };
     
  const handelUpdateFormSubmit = ({ label , address , firstReport , timeZone , status  , types }) => {
    const data = new FormData();
    if (file) {
      data.set("logo", file.originFileObj);
    }
    data.set("label", label);
    data.set("address", address);
    data.set("firstReport", firstReport);
    const timeZoneData = timeZone.map((timeZone) => timeZone.value);
    data.set("timeZone", timeZoneData);
    data.set("isActive", status);
    data.set("listMonitoringType", types);
    dispatch(updateCustomer(props.customerId,data, props.page, props.rowsPerPage, currentTimeZone));
    setModalEdit(!modalEdit);
    reset();
  };

  const propsUpload = {
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange(e) {
      fileHandlerAdd(e);
    },
    listType: "picture"
  };

  const selectInvalidFeedback = {
    width: "100%",
    marginTop: ".25rem",
    fontSize: "80%",
    color: "#dc3545",
  };

  return (
    <>
      <span className="tbBtn" onClick={toggleEdit}><BsPencil /></span>

      <Modal className="modalAddCustomer"  backdrop="static" keyboard={true}   centered isOpen={modalEdit} toggle={toggleEdit} fade={false} size="lg">
        <Form onSubmit={handleSubmit(handelUpdateFormSubmit)} className="formModalAddCustomer">
          <ModalHeader toggle={toggleEdit}>Update Customer</ModalHeader>
          <ModalBody>
          {!isLoading ? (
            <>
              <Row>
                <Col>
                <FormGroup>
                    <Label>
                      Name 
                      <span className="requiredField"> *</span>
                    </Label>
                    <input
                      type="text" name="customer" id="customer" placeholder="Customer"
                      className={ "form-control" + (errors.label ? " is-invalid" : "") }
                      {...register("label")}
                    />
                    <small className="invalid-feedback">
                      {errors.label && <>{errors.label.message}</>}
                    </small>
                  </FormGroup>

                  <FormGroup>
                    <Label>
                      Address 
                      <span className="requiredField"> *</span>
                    </Label>
                    <textarea
                      className={ "form-control" + (errors.address ? " is-invalid" : "") }
                      name="address" id="address" placeholder="Address"
                      {...register("address")}
                    />
                    <small className="invalid-feedback">
                      {errors.address && <>{errors.address.message}</>}
                    </small>
                </FormGroup>

                <FormGroup>
                  <Label>
                    Time Zones 
                    <span className="requiredField"> *</span>
                  </Label>
                  <Controller
                    name="timeZone"
                    control={control}
                    render={({ field: { ref, ...rest }, fieldState }) => (
                      <Select className="customerSelectTimeZone"
                        placeholder="Select time zones" options={timeZones} isMulti={true}
                        {...rest}
                        {...fieldState}
                      />
                    )}
                  />
                  <small style={selectInvalidFeedback} >
                      {errors.timeZone && <>{errors.timeZone.message}</>}
                  </small>
                </FormGroup>

                <FormGroup>
                  <Label>First Report 
                    <span className="requiredField"> *</span>
                  </Label>
                  <input
                    type="time" name="firstReport" id="firstReport"
                    className={ "form-control" + (errors.firstReport ? " is-invalid" : "") }
                    {...register("firstReport")}
                  />
                  <small className="invalid-feedback">
                    {errors.firstReport && <>{errors.firstReport.message}</>}
                  </small>
                </FormGroup>

                <FormGroup>
                  <div><Label className="liceLabel">License Status</Label></div>
                  <FormGroup check inline>
                    <Label check>
                      <input type="radio" id="statusON" name="status" {...register("status")} value={true}   /> Active
                    </Label>{' '}
                  </FormGroup>

                  <FormGroup check inline>
                    <Label check>
                      <input type="radio" id="statusOFF" name="status" {...register("status")}  value={false}  /> Disabled
                    </Label>
                  </FormGroup>
                </FormGroup>

                </Col>
                
                <Col>
                  <FormGroup className="updateCustomerLogoContainer">
                    <Label>Logo</Label>
                    <div className="custDragAndImgGroup">
                      <Dragger className="customerLogoDragger" maxCount={1} {...propsUpload} name="file" onChange={(e) => fileHandlerAdd(e)} >
                        <FiUploadCloud max={1} />
                        <p>Click or drag file to this area to upload</p>
                      </Dragger>
                      {logo && (
                        <div className="customerActualLogo">
                          <div style={ {backgroundImage:`url(data:image;base64,${logo})`} }></div>
                        </div>
                      )}
                    </div>
                  </FormGroup>

                <FormGroup>
                  <Label>Contract Types 
                    <span className="requiredField"> *</span>
                  </Label>
                  {listContractChecked.map((contractType) => (
                    <FormGroup key={contractType._id} className="singleContractTypeCheck" check>
                      <Label check>
                        <input className="form-check-input" type="checkbox"  {...register("types")}   value={contractType._id} />
                        {contractType.libelle}
                      </Label>
                    </FormGroup>
                  ))}
                  <small style={selectInvalidFeedback} >
                    {errors.types && <>{errors.types.message}</>}
                  </small>
                </FormGroup>
                </Col>
              </Row>    
            </>
          ) : (
            <div style={{display:"flex", alignItems:"center", justifyContent:"center", height:100}}>
              <Spinner color="dark" />
            </div>
          )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary"  type="submit">Save Changes</Button>{' '}
            <Button color="secondary" onClick={toggleEdit}>Cancel</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateCustomerModal;
