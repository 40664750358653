import React, { useState } from 'react';
import {
    Row,
    Col,
    Form,
} from "reactstrap";
import schema from "./commentYupValidation"
import { FiEdit } from "react-icons/fi";
import { FiTrash2 } from "react-icons/fi";
import { FiX } from "react-icons/fi";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CgBlock, CgUnblock } from "react-icons/cg";
import { ImEyeBlocked } from "react-icons/im";
export default function EditComment({ comment, DeleteComment, FeedPic, index,
    dispatch,setrefreshData,refreshData,updateComment, commentBlock, isFeedOwner, isAdmin, user, commentUnblock }) {
        const {
            handleSubmit,
            register,
            setValue,
            reset,
            formState: { errors },
        } = useForm({
            resolver: yupResolver(schema),
        });
    const [EditCommentOpen, setEditCommentOpen] = useState(false);
    const toggleEditComment = () => {
        setValue("Comment", comment.content);
        setEditCommentOpen(!EditCommentOpen);
    };
    const handleUpdateComment = async ({Comment}) => {
        const newComment = {
            content: Comment,
            isActive: true
        }
        if (window.confirm("Do You want to save changes ?")) {
            await dispatch(updateComment(comment._id, newComment))
            await setrefreshData(!refreshData)
            await setEditCommentOpen(!EditCommentOpen)
            
        }
        
        reset()
    };
    const isCommentOwner = comment?.user?._id.toString() === user?._id;
    return ( 
        <div className="listReplies" key={index}>
            {EditCommentOpen ? (
                <>
                    <Row className='RowEdit'> 
                        <Col sm="2">
                        <img
                            src={FeedPic}
                            alt=""
                            style={{ height: "35px", width: "35px" }}
                            />
                        </Col>
                        <Col>
                            <Form onSubmit={handleSubmit(handleUpdateComment)}>
                            <input
                                name="Comment"
                                placeholder={errors.Comment ? errors.Comment.message : "Enter comment..."}
                                className={
                                    "form-control" + (errors.Comment ? " is-invalid" : "")
                                }
                                {...register("Comment")}
                            />
                            <small className="invalid-feedback">
                                {errors.title && <>{errors.title.message}</>}
                            </small>
                            </Form>                        
                        </Col>
                        <Col sm="1">
                            <div className='actionButtonForReply'>
                                <span className='btn_Action close' size='sm' onClick={() => setEditCommentOpen(!EditCommentOpen)} >
                                    <FiX style={{ fontSize: "18px" }} />
                                </span>
                            </div>
                        </Col>
                    </Row>
                </>
            ) : (
                <Row className="OneRowReply">
                    <Col className="cardPic" xs="2">
                        <img
                            src={FeedPic}
                            alt=""
                            style={{ height: "35px", width: "35px" }}
                        />
                    </Col>
                    <Col className="cardContent replyContent" sm="8">
                        <h6>
                        {comment.user.firstName} {" "} {comment.user.lastName}
                        </h6>
                        <p>{comment.content}</p>
                        <div>{comment.isBlocked === true && (isAdmin || isFeedOwner) ? (<><div className="comment_blocked"><ImEyeBlocked className='eye_blocked_on_comment'/><span className="comment_blocked_text">This comment is blocked</span></div></>) : ""}</div>
                        
                    </Col>
                    <Col sm="2">
                    {!EditCommentOpen && (
                        <>
                        {(isAdmin || isCommentOwner) && <span
                                className="button_update"
                                onClick={() => toggleEditComment()}>
                                <FiEdit style={{ height: "15px", width: "15px" }}/>
                            </span>}
                            {(isAdmin || isCommentOwner) && <span
                                className="button_delete"
                                onClick={() => DeleteComment(comment)}
                            >
                                <FiTrash2
                                    style={{ height: "15px", width: "15px" }}
                                />
                            </span>}
                            {isAdmin && comment.isBlocked === false ? <span onClick={() => commentBlock(comment)} className='button_block'><CgBlock style={{ height: "18px", width: "18px" }}/></span> : isAdmin && comment.isBlocked === true && <span onClick={() => commentUnblock(comment)} className='button_block'><CgUnblock style={{ height: "16px", width: "16px" }}/></span>} 
                        </>
                    )}
                    </Col>
                </Row>
            )}
        </div>);
}
