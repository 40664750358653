import types from "../actions/types";
const { GET_ALL_CATEGORY_SUCCESS, GET_ALL_CATEGORY_FAILURE, LOAD_CATEGORY, CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAILURE,
  RETRIEVE_ONE_CATEGORY_SUCCESS,
  RETRIEVE_ONE_CATEGORY_FAILURE,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILURE,
  CLEAR_CATEGORYS
} =
  types.categoryTypes;
const initialState = {
  category: null,
  categorys: [],
  SystemsCategories: [],
  isLoading: false,
  error: null,
  listError: null,
};

function userReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_CATEGORY_SUCCESS:
      return {
        ...state,
        categorys: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case GET_ALL_CATEGORY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: payload,
      };
    case LOAD_CATEGORY:
      return {
        ...state,
        isLoading: true,
      };

      case CREATE_CATEGORY_SUCCESS:
      state.categorys.docs.push(payload);
      return {
        ...state,
        categorys: state.categorys,
        isLoading: false,
        error: null,
        listError: null,
      };
    case CREATE_CATEGORY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

      case RETRIEVE_ONE_CATEGORY_SUCCESS:
      return {
        ...state,
        category: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case RETRIEVE_ONE_CATEGORY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

      case UPDATE_CATEGORY_SUCCESS:
        const updatedIndex = state.categorys.docs.map((category, index) => {
          if (payload._id === category._id) {
            return index;
          } else return null;
        });
        if (updatedIndex) state.categorys.docs[updatedIndex] = payload;
        return {
          ...state,
          categorys: state.categorys,
          isLoading: false,
          error: null,
          listError: null,
        };
  
      case UPDATE_CATEGORY_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: payload,
          listError: null,
        };

      case CLEAR_CATEGORYS:
        return {
          ...state,
          categorys: [],
          category: null,
          isLoading: false,
          error: null,
          listError: null,
        };

    default:
      return state;
  }


}

export default userReducer;
