import types from "../actions/types";
const {
  GET_ALL_TYPE_SUCCESS,
  GET_ALL_TYPE_FAILURE,
  LOAD_TYPE,
  CREATE_TYPE_SUCCESS,
  CREATE_TYPE_FAILURE,
  RETRIEVE_ONE_TYPE_SUCCESS,
  RETRIEVE_ONE_TYPE_FAILURE,
  UPDATE_TYPE_SUCCESS,
  UPDATE_TYPE_FAILURE,
  CLEAR_TYPES
} = types.typeTypes;
const initialState = {
  type: null,
  types: [],
  isLoading: false,
  error: null,
  listError: null,
};

function typeReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_TYPE_SUCCESS:
      return {
        ...state,
        types: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case GET_ALL_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: payload,
      };
    case LOAD_TYPE:
      return {
        ...state,
        isLoading: true,
      };

      case CREATE_TYPE_SUCCESS:
      state.types.docs.push(payload);
      return {
        ...state,
        types: state.types,
        isLoading: false,
        error: null,
        listError: null,
      };
    case CREATE_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

      case RETRIEVE_ONE_TYPE_SUCCESS:
      return {
        ...state,
        type: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case RETRIEVE_ONE_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

      case UPDATE_TYPE_SUCCESS:
        const updatedIndex = state.types.docs.map((type, index) => {
          if (payload._id === type._id) {
            return index;
          } else return null;
        });
        if (updatedIndex) state.types.docs[updatedIndex] = payload;
        return {
          ...state,
          types: state.types,
          isLoading: false,
          error: null,
          listError: null,
        };
  
      case UPDATE_TYPE_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: payload,
          listError: null,
        };
      
      case CLEAR_TYPES:
        return {
          ...state,
          types: [],
          type: null,
          isLoading: false,
          error: null,
          listError: null,
        };

    default:
      return state;
  }
}

export default typeReducer;
