import types from "./types";
import CustomerContactService from "../services/customerContactService";
import systemService from "../services/systemService";
import { message } from 'antd';

const {
  CREATE_CUSTOMER_CONTACT_SUCCESS,
  CREATE_CUSTOMER_CONTACT_FAILURE,
  GET_CUSTOMER_CONTACTS_BY_CUSTOMER_SUCCESS,
  GET_CUSTOMER_CONTACTS_BY_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_CONTACT_SUCCESS,
  UPDATE_CUSTOMER_CONTACT_FAILURE,
  GET_ONE_CUSTOMER_CONTACT_FAILURE,
  GET_ONE_CUSTOMER_CONTACT_SUCCESS,
  DELETE_CUSTOMER_CONTACT_SUCCESS,
  DELETE_CUSTOMER_CONTACT_FAILURE,
  ADD_CUSTOMER_CONTACT_TO_SYSTEM_SUCCESS,
  ADD_CUSTOMER_CONTACT_TO_SYSTEM_FAILURE,
  GET_CUSTOMER_CONTACTS_BY_CUSTOMER_UNASSIGNED_SUCCESS,
  GET_CUSTOMER_CONTACTS_BY_CUSTOMER_UNASSIGNED_FAILURE,
  UNASSIGN_CUSTOMER_CONTACT_TO_SYSTEM_FAILURE,
  UNASSIGN_CUSTOMER_CONTACT_TO_SYSTEM_SUCCESS,
  LOAD_CUSTOMER_CONTACT,
  CLEAR_CUSTOMER_CONTACTS,
  CLEAR_ONE_CUSTOMER_CONTACT,
  CLEAR_CUSTOMER_CONTACTS_TO_SYSTEM
} = types.customerContactTypes;

const {
  LOAD_SYSTEM,
  GET_CONTACTS_ASSIGNED_TO_SYSTEM_SUCCESS,
} = types.systemTypes;
export const createCustomerContact = (customerContact) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_CUSTOMER_CONTACT,
    });
    const res = await CustomerContactService.createCustomerContact(
      customerContact
    );
    message.success("Create Successfully",3);
    dispatch({
      type: CREATE_CUSTOMER_CONTACT_SUCCESS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);
  } catch (err) {
    message.error('An Error occurred while creating',3);  
    dispatch({
      type: CREATE_CUSTOMER_CONTACT_FAILURE,
      payload: err.response.data.message,
    });
  }
};

export const getCustomerContactByCustomer =
  (idCustomer, page, rowsPerPage, searchValue) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_CUSTOMER_CONTACT,
      });
      const res = await CustomerContactService.getCustomerContactByCustomer(
        idCustomer,
        page,
        rowsPerPage,
        searchValue
      );
      dispatch({
        type: GET_CUSTOMER_CONTACTS_BY_CUSTOMER_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: GET_CUSTOMER_CONTACTS_BY_CUSTOMER_FAILURE,
        payload: err.response.data.message,
      });
    }
  };

export const updateCustomerContact =
  (id, customerContact) => async (dispatch) => {
    try {
      const res = await CustomerContactService.updateCustomerContact(
        id,
        customerContact
      );
      dispatch({
        type: LOAD_CUSTOMER_CONTACT,
      });
      message.success("Updated Successfully",3);
      dispatch({
        type: UPDATE_CUSTOMER_CONTACT_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      message.error('An Error occurred while updating',3);      
      dispatch({
        type: UPDATE_CUSTOMER_CONTACT_FAILURE,
        payload: err.response.data.message,
      });
    }
  };

export const RetreiveOneCustomerContact =
  (idCustomerContact) => async (dispatch) => {
    try {
      const res = await CustomerContactService.getOneCustomerContact(
        idCustomerContact
      );
      dispatch({
        type: GET_ONE_CUSTOMER_CONTACT_SUCCESS,
        payload: res.data.data,
      });
      return res.data.data;
    } catch (err) {
      dispatch({
        type: GET_ONE_CUSTOMER_CONTACT_FAILURE,
        payload: err.response.data.message,
      });
    }
  };
// export const deleteCustomerContact =
//   (idCustomerContact) => async (dispatch) => {
//     try {
//       const res = await CustomerContactService.deleteCustomerContact(
//         idCustomerContact
//       );
//       dispatch({
//         type: LOAD_CUSTOMER_CONTACT,
//       });
//       dispatch({
//         type: DELETE_CUSTOMER_CONTACT_SUCCESS,
//         payload: res.data.data,
//       });
//       return Promise.resolve(res.data);
//     } catch (err) {
//       dispatch({
//         type: DELETE_CUSTOMER_CONTACT_FAILURE,
//         payload: err.response.data.message,
//       });
//       console.log(err);
//     }
//   };

export const deleteCustomerContact = (id,idCustomer, page, rowsPerPage) => async (dispatch) => {
  try {
    const res = await CustomerContactService.deleteCustomerContact(id);
    dispatch({
      type: LOAD_CUSTOMER_CONTACT,
    });
message.success("Deleted Successfully",3);
    dispatch({
      type: DELETE_CUSTOMER_CONTACT_SUCCESS,
      payload: res.data.data,
    });

    const resAll = await CustomerContactService.getCustomerContactByCustomer(
      idCustomer,
      page,
      rowsPerPage
    );
    dispatch({
      type: GET_CUSTOMER_CONTACTS_BY_CUSTOMER_SUCCESS,
      payload: resAll.data.data,
    });
  } catch (err) {
message.error('An Error occurred while deleting',3);    
    dispatch({
      type: DELETE_CUSTOMER_CONTACT_FAILURE,
      payload: err.response.data.message,
    });
  }
};


export const addCustomerContactToSystem =
  (id, customerContactId) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_CUSTOMER_CONTACT,
      });
      const res = await CustomerContactService.addContactToSystem(
        id,
        customerContactId
      );
      message.success("Contact Assigned Successfully",3);
      dispatch({
        type: ADD_CUSTOMER_CONTACT_TO_SYSTEM_SUCCESS,
        payload: res.data.data.listCustomerContact,
      });

      dispatch({
        type: LOAD_SYSTEM,
      });
      const resAssigned = await systemService.getCustomerContactAssigned(id);
      dispatch({
        type: GET_CONTACTS_ASSIGNED_TO_SYSTEM_SUCCESS,
        payload: resAssigned.data.data,
      });
    
    } catch (err) {
      message.error('An Error occurred while assigning',3);
      dispatch({
        type: ADD_CUSTOMER_CONTACT_TO_SYSTEM_FAILURE,
        payload: err.response,
      });
    }
  };
export const getCustomerContactUnassignedToSystem =
  (idCustomer,idSystem) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_CUSTOMER_CONTACT,
      });
      const res =
        await CustomerContactService.getCustomerContactUnassignedToSystem(
          idCustomer,
          idSystem
        );
      dispatch({
        type: GET_CUSTOMER_CONTACTS_BY_CUSTOMER_UNASSIGNED_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: GET_CUSTOMER_CONTACTS_BY_CUSTOMER_UNASSIGNED_FAILURE,
        payload: err.response.data.message,
      });
    }
  };

  export const unassignCustomerContactFromSystem =
  (id, customerContactId) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_CUSTOMER_CONTACT,
      });
      const res = await CustomerContactService.unassignContactToSystem(
        id,
        customerContactId
      );
      message.success("Unassigning Contact Successfully",3);
      dispatch({
        type: UNASSIGN_CUSTOMER_CONTACT_TO_SYSTEM_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: LOAD_SYSTEM,
      });
      const resAssigned = await systemService.getCustomerContactAssigned(id);
      dispatch({
        type: GET_CONTACTS_ASSIGNED_TO_SYSTEM_SUCCESS,
        payload: resAssigned.data.data,
      });
    
    } catch (err) {
    message.error('An Error occurred while unassigning contact',3);  
      dispatch({
        type: UNASSIGN_CUSTOMER_CONTACT_TO_SYSTEM_FAILURE,
        payload: err.response,
      });
    }
  };

  //clear Customer Contacts
export const clearCustomerContacts = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_CUSTOMER_CONTACTS,
  })
}

//clear One Customer Contact
export const clearOneCustomerContact = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_ONE_CUSTOMER_CONTACT,
  })
}

//clear Customer Contacts To System
export const clearCustomerContactsToSystem = () =>  (dispatch) => {
  dispatch({
      type: CLEAR_CUSTOMER_CONTACTS_TO_SYSTEM,
  })
}