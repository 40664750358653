import types from "../actions/types";

const {
  RETRIEVE_ALL_SHIFT_REPORT_SUCCESS,
  RETRIEVE_ALL_SHIFT_REPORT_FAILURE,
  LOAD_SHIFT_REPORT,
  RETRIEVE_ALL_MEMBERS_REPORT_SUCCESS,
RETRIEVE_ALL_MEMBERS_REPORT_FAILURE,
LOAD_MEMBERS,
LOAD_AVAILABLE_SHIFT,
RETRIEVE_AVAILABLE_SHIFT_SUCCESS,
RETRIEVE_AVAILABLE_SHIFT_FAILURE,
LOAD_MEMBERS_BY_SHIFTS,
RETRIEVE_MEMBERS_BY_SHIFTS_SUCCESS,
RETRIEVE_MEMBERS_BY_SHIFTS_FAILURE,
CLEAR_UPCOMING_SHIFTS
} = types.shiftTypes;

const initialState = {
  AllshiftReport: [],
  AllMembers:[],
  MembersByShifts:[],
  error: null,
  isLoadingReport: false,
  isLoadingMembers :false,
  isLoadingMembersByShifts:false,
  isLoadingAvailableShift :false,
  AvailableShift :[],

};

function shiftReducer(state=initialState,action) {
const {type,payload}=action;

switch (type) {
    case RETRIEVE_ALL_SHIFT_REPORT_SUCCESS:
        return {
         ...state,
         AllshiftReport: payload,
         isLoadingReport: false
        }
    case RETRIEVE_ALL_SHIFT_REPORT_FAILURE:
      return {
         ...state,
         error: payload,
         isLoadingReport: false
    };
    case LOAD_SHIFT_REPORT:
        return {
          ...state,
          isLoadingReport: true
        };
    case RETRIEVE_AVAILABLE_SHIFT_SUCCESS:
        return {
         ...state,
         AvailableShift: payload,
         isLoadingAvailableShift: false
        }
    case RETRIEVE_AVAILABLE_SHIFT_FAILURE:
      return {
         ...state,
         error: payload,
         isLoadingAvailableShift: false
    };
    case LOAD_AVAILABLE_SHIFT:
        return {
          ...state,
          isLoadingAvailableShift: true
        };




        case RETRIEVE_ALL_MEMBERS_REPORT_SUCCESS:
        return {
         ...state,
         AllMembers: payload,
         isLoadingMembers: false
        }
    case RETRIEVE_ALL_MEMBERS_REPORT_FAILURE:
      return {
         ...state,
         error: payload,
         isLoadingMembers: false
    };
    case LOAD_MEMBERS:
        return {
          ...state,
          isLoadingMembers: true
        };
        //GETTING CURRENT MEMBERS
        case RETRIEVE_MEMBERS_BY_SHIFTS_SUCCESS:
        return {
         ...state,
         MembersByShifts: payload,
         isLoadingMembersByShifts: false
        }
        
    case RETRIEVE_MEMBERS_BY_SHIFTS_FAILURE:
      return {
         ...state,
         error: payload,
         isLoadingMembersByShifts: false
    };
    case CLEAR_UPCOMING_SHIFTS:
          return {
           ...state,
           MembersByShifts: [],
           isLoadingMembersByShifts: false
          }
    case LOAD_MEMBERS_BY_SHIFTS:
        return {
          ...state,
          isLoadingMembersByShifts: true
        };
    default:
        return state;
}
    
}
export default shiftReducer;
