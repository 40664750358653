import React, { useState, useEffect } from "react";
import "../../../adminPanel.css";
import {
  Row,
  Col,
  Input,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Form,
  Spinner,
} from "reactstrap";
import MySelect from "../../../../../shared/select/Select";
import { TablePagination } from "@material-ui/core";
import { BsFillCircleFill, BsPencil } from "react-icons/bs";
import { makeDelay, processTableHeight } from "../../../../../assets/js/CoreJs";
import { Switch } from "antd";

import { useDispatch, useSelector } from "react-redux";
import {
  retrieveAllRole,
  createRole,
  RetreiveOneRole,
  updateRole,
  clearRoles
} from "../../../../../actions/roleAction";
import { retrieveAllModule } from "../../../../../actions/moduleAction";

//import { Role } from "../../../adminPanel-dummy-data";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./roleUserFormValidation";

function RoleUserAP() {
  const [page, setPage] = React.useState(0);
  // const [searchTerm, setSearchTerm] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  // const toggleAdd = () => setAddModal(!addModal);
  //const toggleEdit = () => setEditModal(!editModal);
  //const [status, setStatus] = React.useState(Role.isActive);

  const [refreshData, setrefreshData] = useState(false);
  const dispatch = useDispatch();
  const roles = useSelector((state) => state.roleReducer.roles.docs);
  //console.log("Liste all roles ====>",roles)
  const isloading = useSelector((state) => state.roleReducer.isLoading);
  const error = useSelector((state) => state.roleReducer.listError);
  const ErrorAction = useSelector((state) => state.roleReducer.error);
  const totalDocs = useSelector((state) => state.roleReducer.roles.totalDocs);
  const selectedRole = useSelector((state) => state.roleReducer.role);
  //console.log("Selected role ====>",selectedRole)

  const [optionsModule, setOptionsModule] = useState([
    {
      value: "none",
      label: "none",
    },
  ]);
  const [searchValue, setSearchValue] = useState("");

  const applyTabHeightAdminPanelChildList = () => {
    processTableHeight(
      [
        "TitlePage",
        "adminPanelTabsNav",
        "roleUserAPListHeader",
        "roleUserAPPaginate",
      ],
      "roleUserAPListTCONT",
      15
    );
  };

  useEffect(() => {
    applyTabHeightAdminPanelChildList();
    window.addEventListener("resize", applyTabHeightAdminPanelChildList);

    //return fn must be last in useEffect
    return () => {
      window.removeEventListener("resize", applyTabHeightAdminPanelChildList);
    };
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setrefreshData(!refreshData);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setrefreshData(!refreshData);
  };

  const {
    control,
    handleSubmit,
    register,
    setValue,
    //  watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const selectInvalidFeedback = {
    width: "100%",
    marginTop: ".25rem",
    fontSize: "80%",
    color: "#dc3545",
  };

  useEffect(() => {
    dispatch(retrieveAllRole(page, rowsPerPage,null, true, searchValue));
  }, [dispatch, page, rowsPerPage, searchValue , refreshData]);

 

  const toggleAdd = async () => {
    setAddModal(!addModal);
    // setStatus(true);
    reset();
    setValue("isActive", true);
    const optionsModule = await dispatch(retrieveAllModule(0, 1000));
    // console.log("optionsModule===>",optionsModule)
    if (optionsModule)
      setOptionsModule(
        optionsModule.map((value) => ({
          value: value._id,
          label: value.title,
        }))
      );
  };

  const toggleResetEdit = () => {
    setEditModal(!editModal);
    reset();
  };

  

  const handleSubmitCreateRole = async ({ label, rank, isActive, listModule }) => {
    // console.log(listModule);
    // console.log(label)
    const savedList = listModule.map((module) => module.value);
    
    const newData = {
      label: label,
      listModule: savedList,
      rank: rank,
      isActive: isActive,
     
    };
   
    await dispatch(createRole(newData));
    setrefreshData(!refreshData);
      reset();
      setAddModal(!addModal);

      if (!ErrorAction) {
            setAddModal(!addModal);
          }
  };


  // const [moduleEdit, setModuleEdit] = useState([]);
  const toggleEdit = async (e, id) => {
    e.preventDefault();
    if (id) {
      const optionsModule = await dispatch(retrieveAllModule(0, 1000));
      //console.log("optionsModule===>",optionsModule)
      if (optionsModule)
        setOptionsModule(
          optionsModule.map((value) => ({
            value: value._id,
            label: value.title,
          }))
        );

      const res = await dispatch(RetreiveOneRole(id));
      // let listModuleUpd = [];
      if (res) {
        // setRole(res.label);
        // setRank(res.rank);
        // setStatusUpd(res.isActive);

        setValue("label",res.label);
        setValue("rank",res.rank);
        setValue("isActive",res.isActive);
       // console.log(res.listModule);
        const oldListModule = res.listModule.map((value) => ({
          value: value._id,
          label: value.title,
        }))
       // console.log(oldListModule);
        setValue("listModule",oldListModule);

        // for (let i = 0; i < res.listModule.length; i++) {
        //   listModuleUpd.push(res.listModule[i]);
        // }

        // setModuleEdit(listModuleUpd);
      }
    }
    setEditModal(!editModal);
  };

 

  const handleUpdate = async ({ label, rank, isActive, listModule  }) => {
    const savedList2 = listModule.map((module) => module.value);
    const updatedData = {
      label: label,
      listModule: savedList2,
      rank: rank,
      isActive: isActive,
    };
    
    await dispatch(updateRole(selectedRole._id, updatedData));
     
     setrefreshData(!refreshData);
     setEditModal(!editModal);
     reset();
     if (!error) {
       setrefreshData(!refreshData);
       setEditModal(!editModal);
     } else {
       //console.error(error);
     }
  
  };

  if (errors.listModule) {
    document.querySelector(".customerSelectType > div:nth-child(2)") &&
      document
        .querySelector(".customerSelectType > div:nth-child(2)")
        .classList.add("isInvalidSelect");
  }

  const handleSearchQuerry = (e) => {
    makeDelay(() => {
      if (e.target.value.length >= 2) {
        setSearchValue(e.target.value);
        setPage(0);
      } else {
        setSearchValue("");
      }
    }, 750);
  };

  useEffect(() => {
    return () => clearTimeout(window.timerDelay);
  }, []);

   //Clear Data
   useEffect(() => {
    return () => {
      dispatch(clearRoles());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <>
      <section className="avxWrpContentPage roleUserAPPage">
        <Row className="roleUserAPListHeader">
          <Col xs="2">
            <Button color="primary" onClick={toggleAdd}>
              Add Role
            </Button>
          </Col>
          <Col xs={{ size: 4, offset: 6 }}>
            <Input
              type="search"
              className="form-control"
              placeholder="Search By Role"
              onChange={handleSearchQuerry}
            />
          </Col>
        </Row>

        <div className="adminPanelChildListContent">
          <div id="roleUserAPListTCONT" className="avxTableContainer scrollTb">
            <Table
              className="adminPanelChildListTable avxBsTable oneActs"
              striped
            >
              <thead>
                <tr>
                  <th>Role</th>

                  <th>
                    <span>
                      <BsPencil />
                    </span>
                  </th>
                </tr>
              </thead>

             

              {isloading === false ? (
                <tbody>
                  {roles?.length > 0 && error === null ? (
                    <>
                      {roles
                        
                        .map((ro) => (
                          <tr
                            key={ro._id}
                            className={
                              "contractTypeRow " +
                              (ro.isActive ? "activeC" : "disabledC")
                            }
                          >
                            <td>
                              <span className="stateFlag">
                                <BsFillCircleFill />
                              </span>
                              {ro.label}
                            </td>

                            <td>
                              <span
                                className="tbBtn"
                                onClick={(e) => toggleEdit(e, ro._id)}
                              >
                                <BsPencil></BsPencil>
                              </span>
                            </td>
                          </tr>
                        ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="2">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                          }}
                        >
                          <span> No Role Exist </span>
                         
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="2">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: 100,
                        }}
                      >
                        <Spinner color="dark" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </Table>
          </div>

          <TablePagination
            className="roleUserAPPaginate"
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            component="div"
            count={totalDocs ? totalDocs : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>

        <Modal
          isOpen={addModal}
          toggle={toggleAdd}
          className="addAdminPanelChildModal"
          centered
          fade={false}
          backdrop="static"
          keyboard={true}
        >
           <Form
            onSubmit={handleSubmit(handleSubmitCreateRole)}
            className="formAdminPanelChildModal"
          >
          <ModalHeader toggle={toggleAdd}>Add new Role</ModalHeader>
          <ModalBody>
           

            <FormGroup>
                <Label>
                Role
                  <span className="requiredField"> *</span>
                </Label>
                <input
                  type="text"
                  name="label"
                  id="label"
                  className={
                    "form-control" + (errors.label ? " is-invalid" : "")
                  }
                  {...register("label")}
                />
                <small className="invalid-feedback">
                  {errors.label && <>{errors.label.message}</>}
                </small>
              </FormGroup>

           

            <FormGroup>
                <Label>
                Rank
                  <span className="requiredField"> *</span>
                </Label>
                <input
                  type="number"
                  name="rank"
                  id="rank"
                  className={
                    "form-control" + (errors.rank ? " is-invalid" : "")
                  }
                  {...register("rank")}
                />
                <small className="invalid-feedback">
                  {errors.rank && <>{errors.rank.message}</>}
                </small>
              </FormGroup>

           

            <FormGroup>
                <Label className="statusLabel">Active</Label>

                {/* <h1>{watch("isActive")+""}</h1> */}
                <Controller
                  control={control}
                  name="isActive"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Switch
                      onBlur={onBlur} // notify when input is touched
                      onChange={onChange} // send value to hook form
                      checked={value}
                      defaultChecked
                    />
                  )}
                />
              </FormGroup>

           

            <FormGroup>
                  <Label>
                  Add Role Modules
                    <span className="requiredField"> *</span>
                  </Label>
                  <Controller
                    name="listModule"
                    control={control}
                    render={({ field: { ref, ...rest }, fieldState }) => (
                      <MySelect
                        className="customerSelectType"
                        placeholder="Select role"
                        options={optionsModule}
                        {...rest}
                        {...fieldState}
                        isMulti
                      />
                    )}
                  />
                  <small style={selectInvalidFeedback}>
                    {errors.listModule && <>{errors.listModule.message}</>}
                  </small>
                </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" 
           type="submit"
          // onClick={() => handleCreateRole()}
            >
              Add Role
            </Button>{" "}
            <Button color="secondary" onClick={toggleAdd}>
              Cancel
            </Button>
          </ModalFooter>
          </Form>
        </Modal>

        <Modal
          isOpen={editModal}
          toggle={toggleEdit}
          className="editAdminPanelChildModal"
          centered
          fade={false}
          backdrop="static"
          keyboard={true}
        >
           <Form onSubmit={handleSubmit(handleUpdate)}>
          <ModalHeader toggle={toggleEdit}>Update Role</ModalHeader>
          <ModalBody>
           

            <FormGroup>
                <Label>
                Role
                  <span className="requiredField"> *</span>
                </Label>
                <input
                  type="text"
                  name="label"
                  id="label"
                  className={
                    "form-control" + (errors.label ? " is-invalid" : "")
                  }
                  {...register("label")}
                />
                <small className="invalid-feedback">
                  {errors.label && <>{errors.label.message}</>}
                </small>
              </FormGroup>

              <FormGroup>
                <Label>
                Rank
                  <span className="requiredField"> *</span>
                </Label>
                <input
                  type="number"
                  name="rank"
                  id="rank"
                  className={
                    "form-control" + (errors.rank ? " is-invalid" : "")
                  }
                  {...register("rank")}
                />
                <small className="invalid-feedback">
                  {errors.rank && <>{errors.rank.message}</>}
                </small>
              </FormGroup>

              <FormGroup>
                <Label className="statusLabel">Active</Label>

                {/* <h1>{watch("isActive")+""}</h1> */}
                <Controller
                  control={control}
                  name="isActive"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Switch
                      onBlur={onBlur} // notify when input is touched
                      onChange={onChange} // send value to hook form
                      checked={value}
                      defaultChecked
                    />
                  )}
                />
              </FormGroup>

              <FormGroup>
                  <Label>
                  Add Role Modules
                    <span className="requiredField"> *</span>
                  </Label>
                  <Controller
                    name="listModule"
                    control={control}
                    render={({ field: { ref, ...rest }, fieldState }) => (
                      <MySelect
                        className="customerSelectType"
                        placeholder="Select role"
                        options={optionsModule}
                       
                        {...rest}
                        {...fieldState}
                        isMulti
                      />
                    )}
                  />
                  <small style={selectInvalidFeedback}>
                    {errors.listModule && <>{errors.listModule.message}</>}
                  </small>
                </FormGroup>


          </ModalBody>
          <ModalFooter>
            <Button color="primary" 
            type="submit"
            //onClick={() => handleEdit()}
            >
              Save Changes
            </Button>{" "}
            <Button color="secondary" onClick={toggleResetEdit}>
              Cancel
            </Button>
          </ModalFooter>
          </Form>
        </Modal>
      </section>
    </>
  );
}

export default RoleUserAP;
