import types from "../actions/types";
const {
  CREATE_CUSTOMER_SUCCESS,
  GET_ALL_CUSTOMERS_SUCCESS,
  UPDATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAILURE,
  GET_ALL_CUSTOMERS_FAILURE,
  UPDATE_CUSTOMER_FAILURE,
  LOAD_CUSTOMER,
  LOAD_CUSTOMERS,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAILURE,

  GET_CUSTOMERS_SYSTEMS_INFO_SUCCESS,
  GET_CUSTOMERS_SYSTEMS_INFO_FAILURE,
  LOAD_CUSTOMERS_SYSTEM_INFO,
  CLEAR_CUSTOMERS

} = types.customerTypes;
const initialState = {
  customer: null,
  customers: [],
  isLoading: false,
  areLoading: false,
  error: null,

  customersSystemsInfo: [],
  isLoadingCustomersSystemsInfo: false,
};

function userReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    // case CREATE_CUSTOMER_SUCCESS:
    //   return {
    //     ...state,
    //     customers: [...state.customers, payload],
    //     isLoading: false,
    //   };

    case CREATE_CUSTOMER_SUCCESS:
      state.customers.docs.push(payload);
      return {
        ...state,
        customers: state.customers,
        isLoading: false,
       
      };
    case CREATE_CUSTOMER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case GET_ALL_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: payload,
        areLoading: false,
      };
    case GET_ALL_CUSTOMERS_FAILURE:
      return {
        ...state,
        areLoading: false,
        error: payload,
      };
    case LOAD_CUSTOMER:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_CUSTOMERS:
      return {
        ...state,
        areLoading: true,
      };

    case UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: payload,
        loading: false,
      };

    case UPDATE_CUSTOMER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: payload,
        isLoading: false,
        error: payload,
      };
    case GET_CUSTOMER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

      case GET_CUSTOMERS_SYSTEMS_INFO_SUCCESS:
        return {
          ...state,
          customersSystemsInfo: payload,
          isLoadingCustomersSystemsInfo: false,
          error: null,
          listError: null,
        };
      case GET_CUSTOMERS_SYSTEMS_INFO_FAILURE:
        return {
          ...state,
          isLoadingCustomersSystemsInfo: false,
          error: payload,
          listError: null,
          };
      
      case LOAD_CUSTOMERS_SYSTEM_INFO:
        return {
          ...state,
          isLoadingCustomersSystemsInfo: true,
        };
    
      case CLEAR_CUSTOMERS:
        return {
          ...state,
          customers: [],
          customersSystemsInfo: [],
          areLoading: false,
        };
        
    default:
      return state;
  }
}

export default userReducer;
