import types from "./types";
import shiftService from "../services/shiftService"

const {
RETRIEVE_ALL_SHIFT_CALLENDAR_SUCCESS,
RETRIEVE_ALL_SHIFT_CALLENDAR_FAILURE,
LOAD_SHIFT_CALLENDAR,
ASSIGNEE_TEAMLEAD_SUCCESS,
ASSIGNEE_TEAMLEAD_FAILURE,
CLEAR_STORE_CALLENDAR
}= types.shiftCallendarTypes;

export const retreiveShiftCallendar=(startDate, endDate,assigned,unassigned,timeZone)=> async (dispatch)=> {
    try {
        dispatch({
            type : LOAD_SHIFT_CALLENDAR,
        })
        const res = await shiftService.getAllShiftReport(startDate, endDate,assigned,unassigned,timeZone);
        dispatch({
            type: RETRIEVE_ALL_SHIFT_CALLENDAR_SUCCESS,
            payload: res.data.data
        })
    } catch (err) {
        dispatch({
            type: RETRIEVE_ALL_SHIFT_CALLENDAR_FAILURE,
            payload: err,
          });
    }
}

export const AssigneeTeamlead=(newShift)=> async (dispatch)=> {
    try {
        const res = await shiftService.assignTeamLeader(newShift);
        dispatch({
            type: ASSIGNEE_TEAMLEAD_SUCCESS,
            payload: res.data.data
        })
        return res
    } catch (err) {
        dispatch({
            type: ASSIGNEE_TEAMLEAD_FAILURE,
            payload: err,
          });
    }
}

export const ClearCallendarShift=()=> async (dispatch)=> {
    dispatch({
        type : CLEAR_STORE_CALLENDAR,
    })
}
