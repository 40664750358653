import http from "../api";

// let token = localStorage.getItem('ma-access-token');
const addNewFeed = (newFeed) => {
  return http.post(
    `/api/newsFeed/`,newFeed
  );
};
const getAllPost = () => {
  return http.get(
    `/api/newsFeed/getAllPosts?paginate=false`
  );
};
const putLike = (userId,postId) => {
  return http.patch(
    `/api/newsFeed/like/${postId}`,{userId:userId}
  );
}; 
const putDislike = (userId,postId) => {
  return http.patch(
    `/api/newsFeed/unlike/${postId}`,{userId:userId}
  );
};  
const deletePost = (postId) => {
  return http.patch(
    `/api/newsFeed/deletePost/${postId}`
  );
};  
const updatePost = (postId,newPost) => {
  return http.patch(
    `/api/newsFeed/updatePost/${postId}`,newPost
  );
};

const blockPost = (postId) => {
  return http.patch(`/api/newsFeed/blockNews/${postId}`);
}

const unblockPost = (postId) => {
  return http.patch(`/api/newsFeed/unblockNews/${postId}`);
}

const feedService = {
    addNewFeed,
    getAllPost,
    putLike,
    putDislike,
    deletePost,
    updatePost,
    blockPost,
    unblockPost
};
export default feedService;
