import types from "../actions/types";

const {
  RETRIEVE_SYSTEM_CATEGORIES_SUCCESS,
  RETRIEVE_SYSTEM_CATEGORIES_FAILURE,
  RETRIEVE_SYSTEM_TYPES_SUCCESS,
  RETRIEVE_SYSTEM_TYPES_FAILURE,
  LOAD_LANDSCAPE_TC,
  RETRIEVE_SYSTEMS_SUCCESS,
  RETRIEVE_SYSTEMS_FAILURE,
  LOAD_CATEGORIES_TC,
  LOAD_SYSTEM,
  CLEAR_SYSTEMS_TC
} = types.landscapeTCTypes;

const initialState = {
  SystemTypes: [],
  isLoading: false,
  error: null,
  listError: null,
};

function userReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_LANDSCAPE_TC:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_CATEGORIES_TC: {
      const newpayloadCategoriesLoading = state.SystemTypes;
      var indextTypeCategory;
      if (payload) {
        for (let i = 0; i < newpayloadCategoriesLoading.length; i++) {
          if (newpayloadCategoriesLoading[i]._id === payload)
            indextTypeCategory = i;
        }
        if (indextTypeCategory !== "")
          newpayloadCategoriesLoading[
            indextTypeCategory
          ].category.isLoadingCategories = true;
      }
      return {
        ...state,
        SystemTypes: newpayloadCategoriesLoading,
      };
    }
    case LOAD_SYSTEM:
      const newpaySystemsLoad = state.SystemTypes;

      var indexTypeSystemLoad;
      var indexCategorySystemLoad;

      if (payload.categoryId && payload.typeId) {
        for (let i = 0; i < newpaySystemsLoad.length; i++) {
          if (newpaySystemsLoad[i]._id === payload.typeId)
            indexTypeSystemLoad = i;
        }
        for (
          let i = 0;
          i < newpaySystemsLoad[indexTypeSystemLoad].category.categories.length;
          i++
        ) {
          if (
            newpaySystemsLoad[indexTypeSystemLoad].category.categories[i]
              ._id === payload.categoryId
          )
            indexCategorySystemLoad = i;
        }
        if (indexTypeSystemLoad !== "" && indexCategorySystemLoad !== "") {
          newpaySystemsLoad[indexTypeSystemLoad].category.categories[
            indexCategorySystemLoad
          ].systems.isSystemLoading = true;
        }
      }
      return {
        ...state,
        SystemTypes: newpaySystemsLoad,
      };
    case RETRIEVE_SYSTEM_TYPES_SUCCESS: {
      const newpayloadtype = [];
      for (let i = 0; i < payload.length; i++) {
        newpayloadtype.push({
          _id: payload[i]._id,
          type: payload[i].type,
          category: { categories: [], isLoadingCategories: false },
        });
      }
      return {
        ...state,
        SystemTypes: newpayloadtype,
        isLoading: false,
      };
    }
    case RETRIEVE_SYSTEM_TYPES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };
    case RETRIEVE_SYSTEM_CATEGORIES_SUCCESS:
      const newpayloadCategories = state.SystemTypes;
      var indexType;
      if (payload.typeId) {
        for (let i = 0; i < newpayloadCategories.length; i++) {
          if (newpayloadCategories[i]._id === payload.typeId) indexType = i;
        }
        if (indexType !== "") {
          newpayloadCategories[indexType].category.categories =
            payload.data.map((category) => {
              return {
                _id: category._id,
                category: category.category,
                systems: {
                  data: [],
                  isSystemLoading: false,
                },
              };
            });
          newpayloadCategories[indexType].category.isLoadingCategories = false;
        }
      }
      return {
        ...state,
        SystemTypes: [...newpayloadCategories],
      };
    case RETRIEVE_SYSTEM_CATEGORIES_FAILURE:
      const newpayloadCategriesfail = state.SystemTypes;
      var indexTypefail;
      if (payload) {
        for (let i = 0; i < newpayloadCategriesfail.length; i++) {
          if (newpayloadCategriesfail[i]._id === payload) indexTypefail = i;
        }
        if (indexTypefail !== "")
          newpayloadCategriesfail[
            indexTypefail
          ].category.isLoadingCategories = false;
      }
      return {
        ...state,
        SystemTypes: newpayloadCategriesfail,
      };
    case RETRIEVE_SYSTEMS_SUCCESS:
      const newpaySystems = state.SystemTypes;

      var indexTypeSystem;
      var indexCategorySystem;
      if (payload.categoryId && payload.typeId) {
        for (let i = 0; i < newpaySystems.length; i++) {
          if (newpaySystems[i]._id === payload.typeId) indexTypeSystem = i;
        }
        for (
          let i = 0;
          i < newpaySystems[indexTypeSystem].category.categories.length;
          i++
        ) {
          if (
            newpaySystems[indexTypeSystem].category.categories[i]._id ===
            payload.categoryId
          )
            indexCategorySystem = i;
        }
        if (indexTypeSystem !== "" && indexCategorySystem !== "") {
          newpaySystems[indexTypeSystem].category.categories[
            indexCategorySystem
          ].systems.data = [...payload.data];
          newpaySystems[indexTypeSystem].category.categories[
            indexCategorySystem
          ].systems.isSystemLoading = false;
        }
      }

      return {
        ...state,
        SystemTypes: newpaySystems,
        error: null,
        listError: null,
      };
    case RETRIEVE_SYSTEMS_FAILURE: {
      const newpaySystemsfail = state.SystemTypes;

      var indexTypeSystemfail = "";
      var indexCategorySystemfail = "";
      if (payload.categoryId && payload.typeId) {
        for (let i = 0; i < newpaySystemsfail.length; i++) {
          if (newpaySystemsfail[i]._id === payload.typeId) {
            indexTypeSystemfail = i;
            console.info(indexTypeSystemfail);
          }
        }

        if (indexTypeSystemfail !== "")
          for (
            let i = 0;
            i <
            newpaySystemsfail[indexTypeSystemfail].category.categories.length;
            i++
          ) {
            if (
              newpaySystemsfail[indexTypeSystemfail].category.categories[i]
                ._id === payload.categoryId
            )
              indexCategorySystemfail = i;
          }
        if (indexTypeSystemfail !== "" && indexCategorySystemfail !== "") {
          newpaySystemsfail[indexTypeSystemfail].category.categories[
            indexCategorySystemfail
          ].systems.isSystemLoading = false;
        }
      }
      return {
        ...state,
        SystemTypes: newpaySystemsfail,
      };
    }

    case CLEAR_SYSTEMS_TC:
        return {
          ...state,
          SystemTypes: [],
          isLoading: false,
          error: null,
          listError: null,
        };
    default:
      return state;
  }
}

export default userReducer;
