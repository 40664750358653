import customerTypes from "./customerTypes";
import alertTypes from "./alert.types";
import authTypes from "./auth.types";
import licenceTypes from "./licenceTypes";
import monitoringTypes from "./monitoringTypes";
import slaContractTypes from "./slaContractTypes";
import sysClassTypes from "./sysClassTypes";
import customerContactTypes from "./customerContactTypes";
import categoryTypes from "./categoryTypes";
import typeTypes from "./typeTypes";
import systemTypes from "./systemTypes";
import slaTypes from "./slaTypes";
import hostTypes from "./hostTypes";
import clientTypes from "./clientTypes";
// import taskTypes from "./taskTypes";
import mapTypes from "./mapTypes";
import monitoringActivityTypes from "./monitoringActivityTypes";
import landScapCtTypes from "./landScapeCtTypes";
import landscapeTCTypes from "./landscapeTCTypes";
import userTypes from "./userTypes";
import userManagementTypes from "./userManagementTypes";
import roleTypes from "./roleTypes";
import shiftTypes from "./shiftTypes";
import nomenclatureTypes from "./nomenclatureTypes" ;
import shiftCallendarTypes from "./shiftCallendarTypes";
import moduleTypes from "./moduleTypes";
import taskTypes from "./taskTypes";
import taskDynamicFormsTypes from './taskDynamicFormsTypes';
import resultFormTypes from './resultFormTypes';
import resultTypes from "./resultTypes"
import syncShiftTypes from "./syncShiftTypes"
import globalReportTypes from "./globalReportTypes"
import feedTypes from "./feedTypes"
import statisticTypes from "./statisticTypes"
import notificationTypes from "./notificationTypes"
import mtpTypes from "./mtpTypes"

const types = {
  customerContactTypes,
  customerTypes,
  alertTypes,
  taskTypes,
  authTypes,
  licenceTypes,
  monitoringTypes,
  slaContractTypes,
  sysClassTypes,
  categoryTypes,
  typeTypes,
  systemTypes,
  slaTypes,
  hostTypes,
  clientTypes,
  mapTypes,
  monitoringActivityTypes,
  landScapCtTypes,
  landscapeTCTypes,
  userTypes,
  userManagementTypes,
  roleTypes,
  shiftTypes,
  nomenclatureTypes,
  shiftCallendarTypes,
  moduleTypes,
  taskDynamicFormsTypes,
  resultFormTypes,
  resultTypes,
  syncShiftTypes,
  globalReportTypes,
  feedTypes,
  statisticTypes,
  notificationTypes,
  mtpTypes

};
export default types;
