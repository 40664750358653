import { DatePicker } from "antd";
import React, { useState, useEffect } from "react";

import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Badge,
  Spinner,
} from "reactstrap";
import "./ShiftsAssignmentReports.css";
import { retreiveShiftReport } from "../../../actions/shiftAction";

const ShiftsAssignmentReports = function ({displayRange}){

  const currentTimeZone = useSelector(state => state.user.timeZone);

  const [ModalReport, setModalReport] = useState(false);
  const toggleModalReport = function(){
    setModalReport(!ModalReport);
  }

  const [Start_Date, setStart_Date] = useState(displayRange.start);
  const [End_Date, setEnd_Date] = useState(displayRange.end);
  const [assigned, setassigned] = useState(true);
  const [unassigned, setunassigned] = useState(true);
  const dispatch = useDispatch();
  const shiftReport = useSelector(
    (state) => state.shiftReducer.AllshiftReport.docs
  );
  const isLoading = useSelector((state) => state.shiftReducer.isLoadingReport);


  function handlePeriodTime(e) {
    if (e) {
      setStart_Date(moment(e[0]._d).format("YYYY-MM-DD"));
      setEnd_Date(moment(e[1]._d).format("YYYY-MM-DD"));
    } else {
      setStart_Date(displayRange.start);
      setEnd_Date(displayRange.end);
    }
  }

  useEffect(() => {
    dispatch(
      retreiveShiftReport(
        Start_Date,
        End_Date,
        assigned,
        unassigned,
        currentTimeZone
      )
    );
  }, [dispatch, Start_Date, End_Date, assigned, unassigned, currentTimeZone]);
  const dateFormat = 'YYYY-MM-DD';
  
  return (
    <div className="shiftAssignmentContainer">
      <Button outline onClick={toggleModalReport}>
        Shift Reporting
      </Button>
      <Modal
        className="ModalReport"
        centered
        isOpen={ModalReport}
        size="lg"
        toggle={toggleModalReport}
        fade={false}
        backdrop="static"
        keyboard={true}
      >
        <ModalHeader toggle={toggleModalReport}>Shift Reporting</ModalHeader>
        <ModalBody className="Modal_Body_Report">
          <FormGroup>
            <Label>Select a Period</Label>
            <DatePicker.RangePicker
            defaultValue={[moment(Start_Date, dateFormat), moment(End_Date, dateFormat)]}
              className="inputDatePicker"
              onChange={(e) => handlePeriodTime(e)}
            />
          </FormGroup>

          <>
            <FormGroup>
              <FormGroup inline check>
                <Label check>
                  <Input
                    type="checkbox"
                    value={unassigned}
                    checked={unassigned}
                    onChange={() => setunassigned(!unassigned)}
                  />
                  Unassigned
                </Label>
              </FormGroup>
              <FormGroup inline check>
                <Label check>
                  <Input
                    type="checkbox"
                    value={assigned}
                    checked={assigned}
                    onChange={() => setassigned(!assigned)}
                  />
                  Assigned
                </Label>
              </FormGroup>
            </FormGroup>

            <div className="listShift">
              {isLoading === false ? (
                <>
                  {shiftReport && (
                    <>
                      {shiftReport.length === 0 ? (
                        <>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            No Shift available
                          </span>
                        </>
                      ) : (
                        <>
                          {shiftReport
                          .map((shift, index) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  borderLeft: "solid",
                                  backgroundColor: shift.teamLeader
                                    ? "#DEF0D8"
                                    : "#FAF8E6",
                                  borderLeftColor: shift.teamLeader
                                    ? "green"
                                    : "#FFC107",
                                  borderRadius: "2px",
                                }}
                              >
                                <Row
                                  className="Card_shift"
                                  key={shift.shift._id}
                                >
                                  <Col>
                                    <Badge
                                      style={{
                                        backgroundColor: shift.shift.color
                                          ? shift.shift.color
                                          : "default",
                                      }}
                                    >
                                      {shift.shift.name}
                                    </Badge>
                                  </Col>
                                  <Col>
                                    {moment(shift.shift.startDate).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </Col>
                                  <Col>
                                    {moment(shift.shift.startDate)
                                      .tz(currentTimeZone)
                                      .format("LT")}
                                  </Col>
                                  <Col>
                                    {moment(shift.shift.endDate)
                                      .tz(currentTimeZone)
                                      .format("LT")}
                                  </Col>
                                  <Col>
                                    <Badge
                                      color={
                                        shift.teamLeader ? "success" : "warning"
                                      }
                                    >
                                      {shift.teamLeader
                                        ? shift.teamLeader.firstName +
                                          " " +
                                          shift.teamLeader.lastName
                                        : "Unassigned"}
                                    </Badge>
                                  </Col>
                                </Row>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spinner color="dark" />
                </div>
              )}
            </div>
          </>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ShiftsAssignmentReports;
