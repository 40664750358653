import http from "../apiNotifService";

const getAllNotif = (userId) => {
 return http.get(`/api/notif/all/${userId}`);
};

const seeNotif = (userId) => {
  return http.patch(`/api/notif/see/${userId}`);
};

const hostService = {
  getAllNotif,
  seeNotif
};

export default hostService;
