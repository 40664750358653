import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Table,
  Input,
  Button,
  Label,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Form
} from "reactstrap";
import { TablePagination } from "@material-ui/core";
import { BsTrash, BsPencil } from "react-icons/bs";
import { processTableHeight, makeDelay } from "../../../../assets/js/CoreJs";
import "./contactCustomer.css";
import {
  createCustomerContact,
  getCustomerContactByCustomer,
  RetreiveOneCustomerContact,
  updateCustomerContact,
  deleteCustomerContact,
  clearCustomerContacts,
  clearOneCustomerContact
} from "../../../../actions/customerContactAction";
import schema from "./AddNewContactValidation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";



const ContactCustomer = function (props) {
  const { customer_ID } = props;
  // console.log("customer_ID",customer_ID)
  const dispatch = useDispatch();

  const [refreshData, setrefreshData] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  // const [searchTerm, setSearchTerm] = useState("");
  const [searchValue, setSearchValue] = React.useState("");
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const toggleAdd = () => {
    setAddModal(!addModal)
    reset();
  };

  const customerContacts = useSelector(
    (state) => state.customerContact.customerContacts.docs
  );

  const contact = useSelector((state) => state.customerContact.customerContact);

  const totalDocs = useSelector(
    (state) => state.customerContact.customerContacts.totalDocs
  );

  const isloading = useSelector((state) => state.customerContact.isLoading);
  const error = useSelector((state) => state.customerContact.listError);
  const ErrorAction = useSelector((state) => state.customerContact.error);

  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setrefreshData(!refreshData);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setrefreshData(!refreshData);
  };

  const applyTabHeight = () => {
    processTableHeight(
      [
        "TitlePage",
        "customerDetailsTabsNav",
        "sysDetailHeader",
        "customerSystemDetailsTabsNav",
        "contactHeader",
        "contactPagination",
      ],
      "customerContactsTCONT",
      15
    );
  };
  useEffect(() => {
    applyTabHeight();
    window.addEventListener("resize", applyTabHeight);

    //return fn must be last in useEffect
    return () => {
      window.removeEventListener("resize", applyTabHeight);
    };
  }, []);

  useEffect(() => {
    if (customer_ID) {
      dispatch(getCustomerContactByCustomer(customer_ID, page, rowsPerPage, searchValue));
    }
   
  }, [dispatch, customer_ID, page, rowsPerPage,searchValue, refreshData]);

  //Clear Data
  useEffect(() => {
    return () => {

      dispatch(clearCustomerContacts());
      dispatch(clearOneCustomerContact());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const confirmDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this contact ?")) {
      dispatch(deleteCustomerContact(id, customer_ID, page, rowsPerPage));
      setrefreshData(!refreshData);
    }
  };
  //Contact Name
  // const [name, setName] = useState("");
  //Contact Email
  // const [email, setEmail] = useState("");
  //Contact Phone
  // const [phone, setPhone] = useState("");
  //Add New Contact
  const handleCreateContact = ({ name, email, phone }) => {
    const newContact = {
      lastName: name,
      customer: customer_ID,
      mail: email,
      phoneNumber: phone,
    };
    dispatch(createCustomerContact(newContact));
    setrefreshData(!refreshData);
    if (!ErrorAction) {
      setAddModal(!addModal);
    }
    reset();
  }

  

  const toggleEdit = async (e, id) => {
    e.preventDefault();
    if (id) {
      const res = await dispatch(RetreiveOneCustomerContact(id));
      if (res) {
        setValue("name", res.lastName);
        setValue("email", res.mail);
        setValue("phone", res.phoneNumber);
      }
    }
    setEditModal(!editModal);
  };

  // Edit CONTACT

  const handleEdit = ({ name, email, phone }) =>{
    // setName(name);
    // setEmail(email);
    // setPhone(phone);

    if (name) {
      const updatedData = {
        lastName: name,
        mail: email,
        phoneNumber: phone,
      };
      dispatch(updateCustomerContact(contact._id, updatedData));
    } else {
      const updatedData = {
        lastName: name,
        mail: email,
        phoneNumber: phone,
      };
      dispatch(updateCustomerContact(contact._id, updatedData));
    }

    if (!error) {
      setrefreshData(!refreshData);
      setEditModal(!editModal);
    } else {
      //console.error(error);
    }
  }
  const handelSearshQuerry = (e) => {
    makeDelay(() => {
      if (e.target.value.length >= 2) {
        setSearchValue(e.target.value);
        setPage(0);
      } else {
        setSearchValue("");
      }
    }, 750);
  };

  useEffect(() => {
    return () => clearTimeout(window.timerDelay);
  }, []);

  return (
    <div className="customerContactList">
      <Row className="contactHeader">
        <Col xs="2">
          <Button color="primary" onClick={toggleAdd}>
            Add Contact
          </Button>
        </Col>
        <Col xs={{ size: 4, offset: 6 }}>
          <Input
            type="search"
            className="form-control"
            placeholder="Search by Contact name"
            onChange={handelSearshQuerry}
            />
        </Col>
      </Row>
      <div className="contactContent">
        <div id="customerContactsTCONT" className="avxTableContainer scrollTb">
          <Table className="contactsTable avxBsTable twoActs" striped>
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone Number</th>
                <th>Mail</th>
                <th>
                  <span>
                    <BsPencil></BsPencil>
                  </span>
                </th>
                <th>
                  <span>
                    <BsTrash></BsTrash>
                  </span>
                </th>
              </tr>
            </thead>

            {isloading === false ? (
              <tbody>
                {customerContacts?.length >0 && error === null ? (
                  <>
                    {customerContacts
                      .map((c) => (
                        <tr key={c._id}>
                          <td>{c.lastName}</td>
                          <td>{c.phoneNumber}</td>
                          <td>{c.mail}</td>
                          <td>
                            <span
                              className="tbBtn"
                              onClick={(e) => toggleEdit(e, c._id)}
                            >
                              <BsPencil></BsPencil>
                            </span>
                          </td>
                          <td>
                            <span
                              className="tbBtn"
                              onClick={() => confirmDelete(c._id)}
                            >
                              <BsTrash></BsTrash>
                            </span>
                          </td>
                        </tr>
                      ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan="5">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                        }}
                      >
                        <span>No Contact Exist For this Customer</span>
                        {/* <span>{error} </span> */}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="5">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: 100,
                      }}
                    >
                      <Spinner color="dark" />
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
        <TablePagination
          className="contactPagination"
          rowsPerPageOptions={[10, 20, 50, 100]}
          component="div"
          count={totalDocs ? totalDocs : 0}
          rowsPerPage={rowsPerPage ? rowsPerPage : 0}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>

      <Modal
        isOpen={addModal}
        toggle={toggleAdd}
        className="addContactModal"
        centered
        fade={false}
        backdrop="static"
        keyboard={true}
      >
      <Form onSubmit={handleSubmit(handleCreateContact)} className="formModalAddCustomer">
        <ModalHeader toggle={toggleAdd}>Add new contact</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Name
            <span className="requiredField"> *</span>
            </Label>
            <input 
            type="text" 
            name="name"
             id="name"
            className={ "form-control" + (errors.name ? " is-invalid" : "") }
            {...register("name")} 

           />
          <small className="invalid-feedback">
            {errors.name && <>{errors.name.message}</>}
          </small>
          </FormGroup>
          <FormGroup>
            <Label>Email
            <span className="requiredField"> *</span>
            </Label>
            <input
            type="text"
            name="email"
            id="email"
            className={ "form-control" + (errors.email ? " is-invalid" : "") }
            {...register("email")} 
             />
            <small className="invalid-feedback">
            {errors.email && <>{errors.email.message}</>}
          </small>
          </FormGroup>
          <FormGroup>
            <Label>Phone
            <span className="requiredField"> *</span>
            </Label>
            <input 
            type="text" 
            name="phone"
            id="phone"
            className={ "form-control" + (errors.phone ? " is-invalid" : "") }
            {...register("phone")} 
            />
             <small className="invalid-feedback">
            {errors.phone && <>{errors.phone.message}</>}
          </small>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
        {/* onClick={() => handleCreateContact()} */}
          <Button color="primary"  type="submit">
            Add Contact
          </Button>{" "}
          <Button color="secondary" onClick={toggleAdd}>
            Cancel
          </Button>
        </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={editModal}
        toggle={toggleEdit}
        className="editContactModal"
        centered
        fade={false}
        backdrop="static"
        keyboard={true}
      >
        <Form onSubmit={handleSubmit(handleEdit)} className="formModalAddCustomer">
        <ModalHeader toggle={toggleEdit}>Update Contact</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Name
            <span className="requiredField"> *</span>
            </Label>
            <input 
            type="text" 
            name="name"
             id="name"
            className={ "form-control" + (errors.name ? " is-invalid" : "") }
            {...register("name")} 

           />
            <small className="invalid-feedback">
            {errors.name && <>{errors.name.message}</>}
          </small>
          </FormGroup>
          <FormGroup>
            <Label>Email
            <span className="requiredField"> *</span>
            </Label>
            <input 
            type="text" 
            name="email"
            id="email"
            className={ "form-control" + (errors.email ? " is-invalid" : "") }
            {...register("email")} 

           />
            <small className="invalid-feedback">
            {errors.email && <>{errors.email.message}</>}
          </small>
          </FormGroup>
          <FormGroup>
            <Label>Phone
            <span className="requiredField"> *</span>
            </Label>
            <input 
            type="text" 
            name="phone"
             id="phone"
            className={ "form-control" + (errors.phone ? " is-invalid" : "") }
            {...register("phone")} 

           />
            <small className="invalid-feedback">
            {errors.phone && <>{errors.phone.message}</>}
          </small>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit">
            Save Changes
          </Button>{" "}
          <Button color="secondary" onClick={toggleEdit}>
            Cancel
          </Button>
        </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default ContactCustomer;
