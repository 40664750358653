import types from "../actions/types";

const {
  RETRIEVE_SYSTEM_CATEGORIES_SUCCESS,
  RETRIEVE_SYSTEM_CATEGORIES_FAILURE,
  RETRIEVE_SYSTEM_TYPES_SUCCESS,
  RETRIEVE_SYSTEM_TYPES_FAILURE,
  LOAD_LANDSCAPE_CT,
  LOAD_TYPES_CT,
  LOAD_SYSTEM,
  RETRIEVE_SYSTEMS_SUCCESS,
  RETRIEVE_SYSTEMS_FAILURE,
  CLEAR_SYSTEMS_CT
} = types.landScapCtTypes;

const initialState = {
  SystemCategories: [],
  isLoading: false,

  error: null,
  listError: null,
};

function userReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_LANDSCAPE_CT:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_TYPES_CT:
      const newpayloadTypesLoading = state.SystemCategories;
      var indexCategorytypes;
      if (payload) {
        for (let i = 0; i < newpayloadTypesLoading.length; i++) {
          if (newpayloadTypesLoading[i]._id === payload) indexCategorytypes = i;
        }
        if (indexCategorytypes !== "")
          newpayloadTypesLoading[indexCategorytypes].type.isLoadingTypes = true;
      }
      return {
        ...state,
        SystemCategories: newpayloadTypesLoading,
      };
    case LOAD_SYSTEM:
      const newpaySystemsLoad = state.SystemCategories;

      var indexCategorySystemLoad;
      var indexTypeSystemLoad;

      if (payload.categoryId && payload.typeId) {
        for (let i = 0; i < newpaySystemsLoad.length; i++) {
          if (newpaySystemsLoad[i]._id === payload.categoryId)
            indexCategorySystemLoad = i;
        }
        for (
          let i = 0;
          i < newpaySystemsLoad[indexCategorySystemLoad].type.types.length;
          i++
        ) {
          if (
            newpaySystemsLoad[indexCategorySystemLoad].type.types[i]._id ===
            payload.typeId
          )
            indexTypeSystemLoad = i;
        }
        if (indexCategorySystemLoad !== "" && indexTypeSystemLoad !== "") {
          newpaySystemsLoad[indexCategorySystemLoad].type.types[
            indexTypeSystemLoad
          ].systems.isSystemLoading = true;
        }
      }
      return {
        ...state,
        SystemCategories: newpaySystemsLoad,
      };

    case RETRIEVE_SYSTEM_CATEGORIES_SUCCESS: {
      const newpayloadCategory = [];
      for (let i = 0; i < payload.length; i++) {
        newpayloadCategory.push({
          _id: payload[i]._id,
          category: payload[i].category,
          type: { types: [], isLoadingTypes: false },
        });
      }
      return {
        ...state,
        SystemCategories: newpayloadCategory,
        isLoading: false,
        error: null,
        listError: null,
      };
    }
    case RETRIEVE_SYSTEM_CATEGORIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };
    case RETRIEVE_SYSTEM_TYPES_SUCCESS:
      const newpayloadTypes = state.SystemCategories;
      var indexCategory;
      if (payload.categoryId) {
        for (let i = 0; i < newpayloadTypes.length; i++) {
          if (newpayloadTypes[i]._id === payload.categoryId) indexCategory = i;
        }
        if (indexCategory !== "")
          newpayloadTypes[indexCategory].type.types = payload.data.map(
            (type) => {
              return {
                _id: type._id,
                type: type.type,
                systems: {
                  data: [],
                  isSystemLoading: false,
                },
              };
            }
          );
        newpayloadTypes[indexCategory].type.isLoadingTypes = false;
      }

      return {
        ...state,
        SystemCategories: newpayloadTypes,
        error: null,
        listError: null,
      };
    case RETRIEVE_SYSTEM_TYPES_FAILURE:
      const newpayloadTypesfail = state.SystemCategories;
      var indexCategoryfail;
      if (payload) {
        for (let i = 0; i < newpayloadTypesfail.length; i++) {
          if (newpayloadTypesfail[i]._id === payload) indexCategoryfail = i;
        }
        if (indexCategoryfail !== "")
          newpayloadTypesfail[indexCategoryfail].type.isLoadingTypes = false;
      }
      return {
        ...state,
        SystemCategories: newpayloadTypesfail,
      };
    case RETRIEVE_SYSTEMS_SUCCESS:
      const newpaySystems = state.SystemCategories;

      var indexCategorySystem;
      var indexTypeSystem;
      if (payload.categoryId && payload.typeId) {
        for (let i = 0; i < newpaySystems.length; i++) {
          if (newpaySystems[i]._id === payload.categoryId)
            indexCategorySystem = i;
        }

        for (
          let i = 0;
          i < newpaySystems[indexCategorySystem].type.types.length;
          i++
        ) {
          if (
            newpaySystems[indexCategorySystem].type.types[i]._id ===
            payload.typeId
          )
            indexTypeSystem = i;
        }
        if (indexCategorySystem !== "" && indexTypeSystem !== "") {
          newpaySystems[indexCategorySystem].type.types[
            indexTypeSystem
          ].systems.data = [...payload.data];
          newpaySystems[indexCategorySystem].type.types[
            indexTypeSystem
          ].systems.isSystemLoading = false;
        }
      }

      return {
        ...state,
        SystemCategories: newpaySystems,
        error: null,
        listError: null,
      };
    case RETRIEVE_SYSTEMS_FAILURE:
      const newpaySystemsfail = state.SystemCategories;

      var indexCategorySystemfail = "";
      var indexTypeSystemfail = "";
      if (payload.categoryId && payload.typeId) {
        for (let i = 0; i < newpaySystemsfail.length; i++) {
          if (newpaySystemsfail[i]._id === payload.categoryId)
            indexCategorySystemfail = i;
        }

        for (
          let i = 0;
          i < newpaySystemsfail[indexCategorySystemfail].type.types.length;
          i++
        ) {
          if (
            newpaySystemsfail[indexCategorySystemfail].type.types[i]._id ===
            payload.typeId
          )
            indexTypeSystemfail = i;
        }
        if (indexCategorySystemfail !== "" && indexTypeSystemfail !== "") {
          newpaySystemsfail[indexCategorySystemfail].type.types[
            indexTypeSystemfail
          ].systems.isSystemLoading = false;
        }
      }
      return {
        ...state,
        SystemCategories: newpaySystemsfail,
      };

      case CLEAR_SYSTEMS_CT:
        return {
          ...state,
          SystemCategories: [],
          isLoading: false,
          error: null,
          listError: null,
        };

    default:
      return state;
  }
}

export default userReducer;
