import http from "../api";

const create = (system) => {
  return http.post(`/api/system`, system);
};

const getOneSystem = (id) => {
  return http.get(`/api/system/${id}`);
};

const getCustomerContactAssigned = (systemId,searchValue) => {
  const apiURL = searchValue ? `/api/system/contactList/${systemId}?searchValue=${searchValue}` : `/api/system/contactList/${systemId}`;
  return http.get(apiURL);
};
const getCategoriesByCustomerId = (customerId) => {
  return http.get(`/api/system/SystemsCategoriesByCustomer/${customerId}`);
};
const getTypesByCustomerIdByCategoryId = (customerId, categoryId) => {
  return http.get(
    `/api/system/systemTypes/customer/${customerId}/category/${categoryId}`
  );
};

const getSystemsByCustomerIdByTypeIdByCategoryId = (
  customerId,
  categoryId,
  typeId
) => {
  return http.get(
    `/api/system/getSystemsByCustomerIdByTypeIdByCategoryId/${customerId}/type/${typeId}/category/${categoryId}`
  );
};
const retreiveTypesByCustomerId = (customerId) => {
  return http.get(`/api/system/getTypesbyCustomerId/${customerId}`);
};
const retreiveCategoryByCustomerIdByTypeId = (customerId, typeId) => {
  return http.get(
    `/api/system/getCategoriesByTypeByCustomerId/${customerId}/type/${typeId}`
  );
};
const updateSystem = (systemId, updateSystem) => {
  return http.patch(`/api/system/update/${systemId}`, updateSystem);
};
const deleteSystem = (systemId) => {
  return http.patch(`/api/system/delete/${systemId}`);
};
const getAllSystem = (customerId,typeId,categoryId) => {
  let queryPath = '/api/system/forMaps?';
  customerId && (queryPath+='&customerId='+customerId);
  typeId && (queryPath+='&typeId='+typeId);
  categoryId && (queryPath+='&categoryId='+categoryId);
  return http.get(queryPath);
};

const getSystemsInfo = (customerId) => {
  return http.get(`/api/system/systems-info/${customerId}`);
};

const getSystemsByCustomerID = (customerId) => http.get(`/api/system/SystemsByCustomer/${customerId}`);

const getAllTheSystems = (paginate, page = null, size = null) => {
  let query = `/api/system?paginate=${paginate}`;
  if(paginate && page!=null && size) query += `&page=${page}&size=${size}`;

  // let query = '/system?page=0&size=10000';
  return http.get(query);
}

const SystemService = {
  getOneSystem,
  create,
  getCustomerContactAssigned,
  retreiveTypesByCustomerId,
  retreiveCategoryByCustomerIdByTypeId,
  getCategoriesByCustomerId,
  getTypesByCustomerIdByCategoryId,
  getSystemsByCustomerIdByTypeIdByCategoryId,
  updateSystem,
  deleteSystem,
  getAllSystem,
  getSystemsInfo,
  getSystemsByCustomerID,
  getAllTheSystems
};
export default SystemService;
