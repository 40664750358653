import types from "../actions/types";
const {
  CREATE_SLACONTRACT_SUCCESS,
  GET_ALL_SLACONTRACTS_SUCCESS,
  UPDATE_SLACONTRACT_SUCCESS,
  CREATE_SLACONTRACT_FAILURE,
  GET_ALL_SLACONTRACTS_FAILURE,
  DELETE_SLACONTRACT_SUCCESS,
  DELETE_SLACONTRACT_FAILURE,
  UPDATE_SLACONTRACT_FAILURE,
  RETRIEVE_ONE_SLACONTRACT_SUCCESS,
  RETRIEVE_ONE_SLACONTRACT_FAILURE,
  LOAD_SLACONTRACT,
  CLEAR_ALL_SLACONTRACTS
} = types.slaContractTypes;
const initialState = {
  slaContract: null,
  slaContracts: [],
  isLoading: false,
  error: null,
  listError: null,
};

function userReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_SLACONTRACT_SUCCESS:
      state.slaContracts.docs.push(payload);
      return {
        ...state,
        slaContracts: state.slaContracts,
        isLoading: false,
        error: null,
        listError: null,
      };
    case CREATE_SLACONTRACT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };
    case GET_ALL_SLACONTRACTS_SUCCESS:
      return {
        ...state,
        slaContracts: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case GET_ALL_SLACONTRACTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: payload,
      };
    case LOAD_SLACONTRACT:
      return {
        ...state,
        isLoading: true,
      };

    case UPDATE_SLACONTRACT_SUCCESS:
      const updatedIndex = state.slaContracts.docs.map((contract, index) => {
        if (payload._id === contract._id) {
          return index;
        } else return null;
      });
      if (updatedIndex) state.slaContracts.docs[updatedIndex] = payload;
      return {
        ...state,
        slaContracts: state.slaContracts,
        isLoading: false,
        error: null,
        listError: null,
      };

    case UPDATE_SLACONTRACT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };
    case DELETE_SLACONTRACT_SUCCESS:
      const Index = state.slaContracts.docs.map((contract, index) => {
        if (payload._id === contract._id) {
          return index;
        } else return null;
      });
      if (Index) state.slaContracts.docs[Index] = payload;
      return {
        ...state,
        slaContracts: state.slaContracts,
        isLoading: false,
        error: null,
        listError: null,
      };
    case DELETE_SLACONTRACT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };
    case RETRIEVE_ONE_SLACONTRACT_SUCCESS:
      return {
        ...state,
        slaContract: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case RETRIEVE_ONE_SLACONTRACT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };
    
    case CLEAR_ALL_SLACONTRACTS:
      return {
        ...state,
        slaContracts:[],
        slaContract: null,
        isLoading: false,
        error: null,
        listError: null,
      };

    default:
      return state;
  }
}

export default userReducer;
