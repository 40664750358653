import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
    deleteComment,
    getAllCommentByPostID,
    updateComment,
    blockComment,
    unblockComment
} from "../../../../actions/commentAction";
import EditComment from "./EditComment";
export default function CommentsList({
    FeedPic,
    feed,
    refreshData,
    setrefreshData,
    setValue,
    handleSubmit,
    register,
    errors,
    reset,
    isFeedOwner,
    isAdmin,
    user
}) {
    const [Allcomments, setAllcomments] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        async function fetchData() {
            const res = await dispatch(getAllCommentByPostID(feed._id));
            await setAllcomments(res);
          }
            fetchData();
          return () => {
            setAllcomments([])
        }
    }, [dispatch,feed, refreshData]);

    const DeleteComment = async (comment) => {
        if (window.confirm("Do you want to delete this comment ?")) {
            await dispatch(deleteComment(comment._id));
            await setrefreshData(!refreshData);
        }
    };

    const commentBlock = async(comment) => {
        if (window.confirm("Do you want to block this comment ?")) {
            await dispatch(blockComment(comment._id));
            await setrefreshData(!refreshData);
        }
    }
    const commentUnblock = async(comment) => {
        if (window.confirm("Do you want to unblock this comment ?")) {
            await dispatch(unblockComment(comment._id));
            await setrefreshData(!refreshData);
        }
    }
    const filteredComments =Allcomments && Allcomments.filter((f) => (isAdmin || isFeedOwner) ? true : f.isBlocked !== true);

    return (
        <>
            {filteredComments && filteredComments.length > 0 && (
                <>
                    {filteredComments.sort(
                        (a, b) => moment(b.createdAt) - moment(a.createdAt)
                    ).map((comment, index) => {
                        return (
                                <div key={index}>
                                    <EditComment
                                        comment={comment}
                                        FeedPic={FeedPic}
                                        DeleteComment={DeleteComment}
                                        index={index}
                                        dispatch={dispatch}
                                        setrefreshData={setrefreshData}
                                        refreshData={refreshData}
                                        updateComment={updateComment}
                                        setValue={setValue}
                                        handleSubmit={handleSubmit}
                                        register={register}
                                        errors={errors}
                                        reset={reset}
                                        commentBlock={commentBlock}
                                        isFeedOwner={isFeedOwner}
                                        isAdmin={isAdmin}
                                        user={user}
                                        commentUnblock={commentUnblock}


                                    />
                                </div>
                        );
                    })}{" "}
                </>
            )}
        </>
    );
}
