import React, { useEffect, useState } from 'react';
// import { retrieveCustomers } from "../../../actions/customerAction"
import { GetActiveUserStat } from "../../../actions/statisticAction";
import { useSelector, useDispatch } from "react-redux"
import { Line } from "react-chartjs-2";
import { Select } from 'antd';
import { DatePicker } from "antd";
import {
    optionsLineActiveUser,
} from "./FakeData";
import {
    Col,
    FormGroup,
    Spinner,
} from "reactstrap";
import moment from 'moment';
import "../ReportingChart.css"
import CustomerService from '../../../services/customerService';
import SystemService from '../../../services/systemService';
const { Option } = Select;
function ActiveUsersChart() {
    const [customerID, setcustomerID] = useState();
    const [customers, setcustomers] = useState([]);
    const [systems, setsystems] = useState([]);
    const [systemId, setsystemId] = useState();
    const ActiveUserStat = useSelector(state => state.statisticReducer.ActiveUserStat)
    const isLoadingActiveUser = useSelector(state => state.statisticReducer.isLoadingActiveUser)
    const dispatch = useDispatch()
    const [Start_Date, setStart_Date] = useState();
    const [End_Date, setEnd_Date] = useState();
    const [customerOptions, setcustomerOptions] = useState();
    const [systemsOptions, setsystemsOptions] = useState();
    const [Totl, setTotl] = useState({ labels: [], datasets: [] });
    const timeZone = useSelector((state) => state.user.timeZone);
    //********************** useEffect:  fetch  function get All customer  *********************************** */
    useEffect(() => {
        async function fetchData() {
            const res = await CustomerService.getAll(false, null, null, true)
            if (res.data.data.docs.length > 0) {
                setcustomers(res.data.data.docs)
            }
        }
        fetchData();
    }, []);
    //********************** useEffect:  fetch  function get All system by customer  *********************************** */
    useEffect(() => {
        if (customerID) {
            async function fetchData() {
                const res = await SystemService.getAllSystem(customerID)
                if (res.data.data.length > 0) {
                    await setsystems(res.data.data)
                }
            }
            fetchData();
        } else {
            setsystems([])
        }
    }, [customerID]);
    //************************************************************************************************ */
    //********************** Map select options for customers *********************************** */
    useEffect(() => {
        customers && setcustomerOptions(customers.map((value) => ({
            value: value._id,
            label: value.label
        })))

        // return () => {
        //     setcustomerOptions()
        // };
    }, [customers]);
    //************************************************************************************************ */
    //********************** Map select options for systems *********************************** */
    useEffect(() => {
        systems && setsystemsOptions(systems.map((value) => ({
            value: value._id,
            label: value.name
        })))
        // return () => {
        //     setsystemsOptions()
        // };
    }, [systems]);
    //************************************************************************************************ */
    //********************** get all stats Active user data*********************************** */
    useEffect(() => {
        if (customerID && Start_Date && End_Date && systemId) { dispatch(GetActiveUserStat(customerID, Start_Date, End_Date, systemId, timeZone)) }
        else {
            // dispatch(clearStoreStat())
        }
        // return () => {
        //     dispatch(clearStoreStat())

        // };
    }, [dispatch, customerID, Start_Date, End_Date, systemId, timeZone]);
    //************************************************************************************************ */
    //********************** handle fucntion for start date and end date *********************************** */
    function handlePeriodTime(e) {
        if (e) {
            setStart_Date(moment(e[0]._d).format("YYYY-MM-DD"));
            setEnd_Date(moment(e[1]._d).format("YYYY-MM-DD"));
        } else {
            setStart_Date(null);
            setEnd_Date(null);
        }
    }
    //********************** structure data to display on in chart  *********************************** */
    useEffect(() => {
        const totalusers = [];
        const ActiveUser = [];
        const totalUserStructureLine = {
            labels: [],
            datasets: [],
        };
        if (ActiveUserStat) {
            ActiveUserStat.data.forEach((data) => {
                totalusers.push({ createdAt: data.createdAt, totalusers: data.totalusers })
                ActiveUser.push({ createdAt: data.createdAt, ActiveUser: data.activeusers })
            })
            totalUserStructureLine.labels = totalusers.map((tot) => {
                return moment(tot.createdAt).format("ll");
            })
            totalUserStructureLine.datasets = [
                {
                    label: 'Active users',
                    data: ActiveUser.map((tot) => tot.ActiveUser),
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
                {
                    label: 'total users',
                    data: totalusers.map((tot) => tot.totalusers),
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                }
            ]
        } else {
            setTotl({
                labels: [],
                datasets: [],
            })
        }
        setTotl(totalUserStructureLine)
        return () => {
            setTotl({
                labels: [],
                datasets: [],
            })
        };
    }, [ActiveUserStat]);
    const dateFormat = 'YYYY-MM-DD';
    //************************************************************************************************ */

    //********************** handle fucntion for start customerID *********************************** */

    function handlechangesSelect(value) {
        setsystemId(null)
        value ? setcustomerID(value) : setcustomerID(null)
    }
    //************************************************************************************************ */
    //********************** handle fucntion for start systemId *********************************** */

    function handlechangesSelectSystem(value) {
        value ? setsystemId(value) : setsystemId(null)
    }
    return (
        <Col sm="6">
            <div className='FilterSection'>
                <FormGroup>
                    <Select
                        className="SelectInput"
                        placeholder="customer"
                        onChange={handlechangesSelect}
                    >
                        {customerOptions?.map((value) => {
                            return (
                                <Option key={value.value} value={value.value}>{value.label}</Option>
                            )
                        })}
                    </Select>
                </FormGroup>
                <FormGroup>
                    <DatePicker.RangePicker
                        format={dateFormat}
                        // value={[moment(Start_Date, dateFormat), moment(End_Date, dateFormat)]}
                        className="inputDatePicker"
                        onChange={(e) => handlePeriodTime(e)}
                    />
                </FormGroup>
                <FormGroup>
                    <Select
                        className="SelectInput"
                        placeholder="System"
                        onChange={handlechangesSelectSystem}
                        disabled={customerID ? false : true}
                        value={systemId}
                    >
                        {systemsOptions?.map((value) => {
                            return (
                                <Option key={value.value} value={value.value}>{value.label}</Option>
                            )
                        })}
                    </Select>
                </FormGroup>
            </div>
            <div className="ChartSection">
                {isLoadingActiveUser === false ? (
                    <>
                        {ActiveUserStat && ActiveUserStat.data.length > 0 ? (
                            <>
                                <Line data={Totl} options={optionsLineActiveUser} />
                            </>
                        ) : (
                            <div>
                                {customerID && Start_Date && End_Date ? (
                                    <div className='chartTitle'>
                                        <p> No Data Durring this period</p>
                                    </div>
                                ) : (
                                    <div className='chartTitle'>
                                        <p>Active User</p>
                                        <q> select customer and date !</q>
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                ) : (
                    <div className='chartTitle'>
                        <Spinner color="primary" />
                    </div>
                )}
            </div>
        </Col>

    )
}

export default ActiveUsersChart;
