import * as Yup from "yup";
const schema = Yup.object().shape({
  title: Yup.string().required("The Title is required."),
  path: Yup.object().shape({
    label: Yup.string().required(),
    value: Yup.string().required("Basic Module is required."),
  }),
  listModulePermission: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required("Value of Permissions is Required"),
      })
    )
    .nullable(),
});
export default schema;
