const GET_ALL_NOTIFICATION_SUCCESS = "GET_ALL_NOTIFICATION_SUCCESS";
const GET_ALL_NOTIFICATION_FAILURE = "GET_ALL_NOTIFICATION_FAILURE";
const LOAD_ALL_NOTIFICATION = "LOAD_ALL_NOTIFICATION";

let notificationTypes = {
  GET_ALL_NOTIFICATION_SUCCESS,
  GET_ALL_NOTIFICATION_FAILURE,
  LOAD_ALL_NOTIFICATION
};
export default notificationTypes;
