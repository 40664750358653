import React, { useEffect, useState } from "react";
import { Row, Col, Input, Table, FormGroup, Label, Badge, Spinner, Button, } from "reactstrap";
import Select from "react-select";
import "./SummaryStatistique.css";

import { DatePicker } from "antd";
import { processTableHeight,makeDelay } from "../../../../assets/js/CoreJs";

import { useSelector, useDispatch } from "react-redux";
import {
  getAllTaskActivityLogs,
  createSyncTaskActivityLog,
  clearTaskActivityLog,
} from "../../../../actions/taskAction";
import { retreiveAllNomenclature } from "../../../../actions/nomenclatureAction";

export default function SummaryStatistique() {
 
  const customStyles = {
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: "100%",
      display: "flex",
      height: 35,
      borderRadius: "4px",
      border: "1px solid #CED4DA",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      return { ...provided, opacity, transition };
    },
  };

  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [date, setDate] = useState();
  const [shiftName, setshiftName] = useState();
  const [refreshData, setrefreshData] = useState(false);
  const currentTimeZone = useSelector((state) => state.user.timeZone);
  const taskActivityLogs = useSelector((state) => state.taskReducer.taskActivityLogs);
  const isLoadingTaskActivityLogs = useSelector((state) => state.taskReducer.isLoadingTaskActivityLogs);
  const error = useSelector((state) => state.taskReducer.listError);
  const nomenclatures = useSelector((state) => state.nomenclatureReducer.AllNomenclatures);
  const isLoadingForCreationSyncLog = useSelector((state) => state.taskReducer.isLoadingForCreationSyncLog);

  const applyTabHeight = () => {
    processTableHeight(
      [
        "TitlePage",
        "taskTrackingTabsNav",
        "SummaryStatistiqueHeader"
      ],
      "SummaryStatistiqueListTableTCONT",
      15
    );
  };

  useEffect(() => {
    dispatch(retreiveAllNomenclature())
    applyTabHeight();
    window.addEventListener("resize", applyTabHeight);

    return () => {
      window.removeEventListener("resize", applyTabHeight);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filter = {
      timeZone: currentTimeZone,
      date: date,
    };
    shiftName && date && dispatch( getAllTaskActivityLogs(shiftName, searchValue, filter) );
  }, [dispatch, currentTimeZone, date, shiftName, searchValue, refreshData] );

  const handelSyncTaskLog = async () => {
    if (window.confirm("Are you sure you want to Sync Task Log ?")) {
      await dispatch( createSyncTaskActivityLog() );
      setrefreshData(!refreshData);
    }
  };

  const handelSearshQuerry = (e) => {
    makeDelay(() => {
      if (e.target.value.length >= 2) {
        setSearchValue(e.target.value);
        setrefreshData(!refreshData);
      } else {
        setSearchValue("");
      }
    }, 750);
  };

  //map nomenclatures to object value/label for select option
  const nomenclaturesOptions = nomenclatures?.map((nom) => ({
    value: nom._id,
    label: nom.name,
  }));

  function handleShiftName(e) {
    if (e) {
      //console.log(e.label);
      setshiftName(e.label);
    } else {
      setshiftName(null);
    }
  }

  function handleDate(e) {
    if (e) {
      setDate(e.format("YYYY-MM-DD"));
    } else {
      setDate(null);
    }
  }

  const sumTasks = {
    target: 0,
    created: 0,
    pending: 0,
    inProgress: 0,
    completed: 0,
    done: 0,
    canceled: 0
  }

  const tasksLog = taskActivityLogs?.map(task => {
    const targetTasks = task.hasOwnProperty('targetTasks') ? parseInt(task.targetTasks) : 0;
    const createdTasks = task.hasOwnProperty('createdTasks') ? parseInt(task.createdTasks) : 0;;
    const pendingTasks = task.hasOwnProperty('pendingTasks') ? parseInt(task.pendingTasks) : 0;
    const inProgressTasks = task.hasOwnProperty('inProgressTasks') ? parseInt(task.inProgressTasks) : 0;
    const completedTasks = task.hasOwnProperty('completedTasks') ? parseInt(task.completedTasks) : 0;
    const doneTasks =  task.hasOwnProperty('doneTasks') ? parseInt(task.doneTasks) : 0;
    const canceledTasks =  task.hasOwnProperty('canceledTasks') ? parseInt(task.canceledTasks) : 0;

    sumTasks.target += targetTasks;
    sumTasks.created += createdTasks;
    sumTasks.pending += pendingTasks;
    sumTasks.inProgress += inProgressTasks;
    sumTasks.completed += completedTasks;
    sumTasks.done += doneTasks;
    sumTasks.canceled += canceledTasks;

    return {
      ...task,
      targetTasks,
      createdTasks,
      pendingTasks,
      inProgressTasks,
      completedTasks,
      doneTasks,
      canceledTasks
    }
  });


  //Clear Data
  useEffect(() => {
    return () => { dispatch(clearTaskActivityLog()); };
  //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <>
      <section className="SummaryStatistique">
        <Row>
          <Col xs="10">
            <Row className="SummaryStatistiqueHeader ">
              <Col xs="6">
                <div className="divCustomStyleCol1">
                  <DatePicker className="DateRangePicker" format="YYYY-MM-DD"  onChange={(e) => handleDate(e)}/>

                  <Select
                    className="SummarySelectShift"
                    styles={customStyles}
                    placeholder="Select Shift"
                    options={nomenclaturesOptions}
                    onChange={(e) => handleShiftName(e)}
                  />
                </div>
              </Col>
              <Col xs={{ size: 5, offset: 1 }}>
                <Input
                  type="search"
                  className="searchBar"
                  placeholder=" Search by customer, system "
                  onChange={handelSearshQuerry}
                />
              </Col>
            </Row>

            <div className="TableContainer">
              <div
                id="SummaryStatistiqueListTableTCONT"
                className="avxTableContainer scrollTb"
              >
                <Table
                  // id="resize"
                  className="SummaryStatistiqueListTable avxBsTable"
                  striped
                  // hover
                >
                  <thead>
                    <tr>
                      <th>System</th>
                      <th>Customer</th>
                      <th>Pending</th>
                      <th>In Progress</th>
                      <th>Completed</th>
                      <th>Done</th>
                      <th>Canceled</th>
                      <th>Total</th>
                      <th>Target</th>
                    </tr>
                  </thead>

                  {isLoadingTaskActivityLogs === false ? (
                    <tbody className="forPointer">
                      {tasksLog && error === null ? (
                        <>
                          {tasksLog.map((task, index) => (
                            <tr key={index}>
                              <td>{task.systemName}</td>
                              <td>{task.customerName}</td>
                              <td>{task.pendingTasks}</td>
                              <td>{task.inProgressTasks}</td>
                              <td>{task.completedTasks}</td>
                              <td>{task.doneTasks}</td>
                              <td>{task.canceledTasks}</td>
                              <td>{task.createdTasks}</td>
                              <td>{task.targetTasks}</td>

                            </tr>
                          ))}
                        </>
                      ) : (
                        <tr>
                          <td colSpan="9">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                              }}
                            >
                              <span> No Tasks </span>
                              {/* <span>{error} </span> */}
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan="8">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: 100,
                            }}
                          >
                            <Spinner color="dark" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </div>
            </div>
          </Col>

          <Col xs="2">
            <div className="SummaryStatistiqueHighlights">
              <h5 className="SummaryStatistiqueHighlightsTitle">Highlights</h5>
              <div className="CreatedBadge">
                <Badge size="small" color="secondary">
                  {" "}
                  Created {sumTasks.created}/{sumTasks.target}{" "}
                </Badge>
              </div>
              <div className="SummaryStatistiqueHighlightsInsideContent">
                <table className="tableHighlight">
                  <tbody>
                    <tr>
                      <td>
                        <FormGroup check inline>
                          <Label check>
                            <Badge color="warning" pill>
                              Pending
                            </Badge>
                          </Label>
                        </FormGroup>
                      </td>
                      <td>
                        <p className="StatusNumber">{sumTasks.pending}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FormGroup check inline>
                          <Label check>
                            <Badge color="primary" pill>
                              In progress
                            </Badge>
                          </Label>
                        </FormGroup>
                      </td>
                      <td>
                        <p className="StatusNumber">{sumTasks.inProgress}</p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <FormGroup check inline>
                          <Label check>
                            <Badge color="info" pill>
                              Completed
                            </Badge>
                          </Label>
                        </FormGroup>
                      </td>
                      <td>
                        <p className="StatusNumber">{sumTasks.completed}</p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <FormGroup check inline>
                          <Label check>
                            <Badge color="success" pill>
                              Done
                            </Badge>
                          </Label>
                        </FormGroup>
                      </td>
                      <td>
                        <p className="StatusNumber">{sumTasks.done}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FormGroup check inline>
                          <Label check>
                            <Badge color="danger" pill>
                              Canceled
                            </Badge>
                          </Label>
                        </FormGroup>
                      </td>
                      <td>
                        <p className="StatusNumber">{sumTasks.canceled}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
             
             <Row>
              <div>
              <Button className="StyleBtnSync" color="primary" outline onClick={() => handelSyncTaskLog()}>Get latest updates</Button>
              </div>
              <div>
              {isLoadingForCreationSyncLog === false ? "": <Spinner className="marginRightSP" color="dark" /> }
              </div>
              </Row>
              
            </div>
            
          </Col>
        </Row>
      </section>
    </>
  );
}
