import React, { useState, useEffect } from "react";
import SystemCard from "./SystemCard";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { RetreiveCustomersSystemsInfo } from "../../../actions/customerAction";
import { Badge, TabContent, Nav, NavItem, NavLink, Spinner } from "reactstrap";
import "./SystemStatus.css";

function SystemeStatus() {
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.customer.customersSystemsInfo);
  const customerLoading = useSelector((state) => state.customer.isLoadingCustomersSystemsInfo);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const toggleSwitch = (tab, customerId) => {
    if (activeTab !== tab) setActiveTab(tab);
    setSelectedCustomer(customerId);
  };
  useEffect(() => {
    let isApiSubscribed = true;
      async function fetchData() {
        if(isApiSubscribed){
        const  res = await dispatch(RetreiveCustomersSystemsInfo());
        if (res) {
          setSelectedCustomer(res[0] ? res[0].customerID :null)
        }
        }else{
          setSelectedCustomer()
        }
      }
      fetchData();
      return () => {  
     
        isApiSubscribed=false
        fetchData()
       
      }
  }, [dispatch]);

  return (
    <>
      <section className="systemStatusPage">
        
          {customerLoading ? (
            <div className="centerLoader">
              <div>
                <Spinner color="dark" />
              </div>
            </div>
          ) : (
            <>
            {customers.length>0 ? ( <> <Nav tabs>
              {customers.map((customer, index) => {
                return (
                  <NavItem key={index}>
                    <NavLink
                      key={index}
                      className={classnames({ active: activeTab === index })}
                      onClick={() => {
                        toggleSwitch(index, customer?.customerID);
                      }}
                    >
                      <span className="sysCustomer">
                        {customer.customerName}
                      </span>
                      {customer.Critical !== 0 && customer.Warning === 0 ? (
                        <Badge pill color="danger">
                          {customer.Critical}
                        </Badge>
                      ) : (
                        ""
                      )}
                      {customer?.Critical === 0 && customer?.Warning !== 0 ? (
                        <Badge pill color="warning">
                          {customer.Warning}
                        </Badge>
                      ) : (
                        ""
                      )}
                      {customer.Critical !== 0 && customer.Warning !== 0 ? (
                        <>
                          <Badge pill color="warning">
                            {customer?.Warning}
                          </Badge>
                          <Badge pill color="danger">
                            {customer?.Critical}
                          </Badge>
                        </>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </NavItem>
                );
              })}
              </Nav>
              
              <TabContent activeTab={activeTab}>
          <SystemCard customerID={selectedCustomer} />
        </TabContent>
              </>):(<>
                <div className="centerLoader">
              <div>
              <span style={{opacity:.5}}>No data</span>
              </div>
            </div>
              </>)}
            </>
          )}
        
     
      </section>
    </>
  );
}

export default SystemeStatus;
