import * as Yup from "yup";
const schema = Yup.object().shape({
   firstName: Yup.string().required("The First Name is required."), 
   lastName: Yup.string().required("The Last Name is required."), 
   roles: Yup.array()
   .min(1, " Choose atleast one role.")
   .of(
     Yup.object().shape({
       label: Yup.string().required(),
       value: Yup.string().required("Value of role is Required."),
     })
   )
   .nullable()
   .required("Choose atleast one role."),


});
export default schema;
