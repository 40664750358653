const GET_USER_SUCCESS = "GET_USER_SUCCESS";
const GET_USER_FAILURE = "GET_USER_FAILURE";
const LOAD_USER = "LOAD_USER";
const UPDATE_INFO_USER_SUCCESS = "UPDATE_INFO_USER_SUCCESS";
const UPDATE_INFO_USER_FAILURE = "UPDATE_INFO_USER_FAILURE";
const UPDATE_PASSWORD_USER_SUCCESS = "UPDATE_PASSWORD_USER_SUCCESS";
const UPDATE_PASSWORD_USER_FAILURE = "UPDATE_PASSWORD_USER_FAILURE";
const CHANGE_TIME_ZONE = 'CHANGE_TIME_ZONE'; 
const RESET_STATE_USER = 'RESET_STATE_USER'; 
let userTypes = {
    LOAD_USER,
    GET_USER_SUCCESS,
    GET_USER_FAILURE,
    UPDATE_INFO_USER_SUCCESS,
    UPDATE_INFO_USER_FAILURE,
    UPDATE_PASSWORD_USER_SUCCESS,
    UPDATE_PASSWORD_USER_FAILURE,
    CHANGE_TIME_ZONE,
    RESET_STATE_USER
  };
  export default userTypes;