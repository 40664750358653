import * as Yup from "yup";
const schema = Yup.object().shape({

    monitoringActivity: Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required("The Monitoring Activity is required."),
      }),

      formLimits: Yup.string().required("The form Limits is Required."),
      formSchema: Yup.string().required("The form Schema is Required."),
      formUISchema: Yup.string().required("The form UI Schema is Required."),

});
export default schema;
