import React from 'react';
import Select from 'react-select';
import { Row, Col, Table, Spinner } from 'reactstrap';
import "./ResultHistory.css";
import useResultHistory from '../logic/useResultHistory';
import {genResultPill} from '../logic/resultHistoryHelper';
import DataSetHistory from './DataSetHistory';

const ResultHistory = () => {
  const {
    moment,
    RangePicker,
    AllResultHistory,
    isLoadingRH,
    error,
    monitoringActivities,
    handleMonitoringActivity,
    MapId,
    handleChangeDate,
    getMonitoringActivity,
    getSystemByCustomerID,
    handleCustomer,
    handleSystem,
    customerId,
    systemId,
    systems,
    customers,
    compKeyCustomer,
    isLoadingSystem,
    isloadingMaps,
    resultMAP
  } = useResultHistory();

  return (
    <section className="ResultHistoryPage">
      <Row className="ResultHistoryHeader">
        <Col xs="4">
          <RangePicker className="DateRangePicker" format="YYYY-MM-DD HH:mm" 
            showTime={{ defaultValue: [moment("00:00:00", "HH:mm:ss"), moment("00:00:00", "HH:mm:ss")] }}
            onChange={(e) => handleChangeDate(e)}
          />
        </Col>
        <Col xs={{size:3, offset:1}} >
          <Select className="ResultHistory_Select" options={customers} value={customerId} isClearable placeholder="Select Customer"
            onChange={(e) => {handleCustomer(e); getSystemByCustomerID(e?.value);}}
          />
        </Col>
        <Col xs="2">
          <Select className="ResultHistory_Select" options={systems} value={systemId} key={compKeyCustomer} isLoading={isLoadingSystem} placeholder="Select System"
            onChange={(e) => { handleSystem(e);getMonitoringActivity(e?.value); }} isClearable
          />
        </Col>
        <Col xs="2">
          <Select className="ResultHistory_Select" options={monitoringActivities} isDisabled={systemId ? false : true} value={MapId} isLoading={isloadingMaps} 
            onChange={(e) => handleMonitoringActivity(e)} isClearable placeholder="Select Monitoring Activity"
          />
        </Col>
      </Row>

      <div className="TableContainer">
        <div id="ResultHistoryListTableTCONT" className="avxTableContainer scrollTb" >
          <Table className="ResultHistoryListTable avxBsTable">
            <tbody>
              {
                !isLoadingRH ? (
                  resultMAP && resultMAP.length > 0 && error === null ? (
                    resultMAP.map((result, index) => (
                      <tr key={index}>
                        <td>
                          {genResultPill(result.gStatus)}
                          <p>{result.createdAt}</p>
                        </td>
                        <td>
                          {
                            AllResultHistory.hasOwnProperty('schema') && result.hasOwnProperty('result') && result.result.length 
                            ? <DataSetHistory schema={AllResultHistory.schema} dataSet={result.result} />
                            : ''
                          }
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2"><div className="tbInfoCell">No Result History</div></td>
                    </tr>
                  )
                ) : (
                  <tr>
                    <td colSpan="2"><div className="tbInfoCell"><Spinner color="dark" /></div></td>
                  </tr>
                )
              }
            </tbody>
          </Table>
        </div>
      </div>

    </section>
  );
};

export default ResultHistory;
