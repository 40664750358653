import React, { useState } from "react";
import {
  ButtonGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
} from "reactstrap";
import Select from "../../../../shared/select/Select";
import { avxToggle } from "../../../../assets/js/CoreJs";
import "./CustomerSystems.css";
import LandscapeDefaultTC from "./LandscapeDefaultTC";
import LandscapeAddonCT from "./LandscapeAddonCT";
import CustomerSystemDetails from "./CustomerSystemDetails";
import classnames from "classnames";
import { retrieveSysClass } from "../../../../actions/sysClassAction";
import { retrieveCategory } from "../../../../actions/categoryAction";
import { retrieveType } from "../../../../actions/typeAction";
import {
  RetreiveSystemByCustomerByCatgeroyByTypesCT,
  RetreiveSystemCategoriesCT,
} from "../../../../actions/landScapeCTAction";
import {
  RetreiveSystemByCustomerByCatgeroyByTypesTC,
  RetreiveSystemTypesTC,
} from "../../../../actions/landscapeTCAction";
import {
  createSystem,
  deleteSystem,
  RetreiveOneSystem,
  UpdateSystem,
} from "../../../../actions/systemAction";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./CustomerSystemsValidation";

// const optionsType = [
//   { value: "DEV", label: "DEV" },
//   { value: "QAS", label: "QAS" },
//   { value: "PRD", label: "PRD" },
// ];

// const optionsCategory = [
//   { value: "SAP - ECC", label: "SAP - ECC" },
//   { value: "SAP - BI/BW", label: "SAP - BI/BW" },
//   { value: "SAP - ADS", label: "SAP - ADS" },
// ];

// const optionsClass = [
//   { value: "SAP", label: "SAP" },
//   { value: "BOOMI", label: "BOOMI" },
// ];

const optionsDeploy = [
  { value: "Cloud", label: "Cloud" },
  { value: "On-Premise", label: "On-Premise" },
];

const CustomerSystems = function (props) {
  const { customer_ID } = props;
  //console.log("Customer ID in System Components", customer_ID);

  const [landscape, setLandscape] = useState("TC");

  //khalil temporary fixed  system ID
  const [selectedSystem, setSelectedSystem] = useState("");
  //const [selectedSystem, setSelectedSystem] = useState('61540c9adc0963494418542d')

  const [scrollPosition, setScrollPosition] = useState("");

  const [addModal, setAddModal] = useState(false);
  //const toggleAdd = () => setAddModal(!addModal);
  const [editModal, setEditModal] = useState(false);
  // const [SystemTobeUpdated, setSystemTobeUpdated] = useState("");
  const [system_Id, setsystem_Id] = useState("");
  const [categoryId, setcategoryId] = useState("");
  const [typeId, settypeId] = useState("");
  const [refresh, setrefresh] = useState(false);
  const toggleEdit = () => {
    setEditModal(!editModal);
  };

  const {
    control,
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleOpenEditModal = async (e, systemId) => {
    e.preventDefault();
    const systemUpdated = await dispatch(RetreiveOneSystem(systemId));
    syncOption();
    if (editModal === false) {
      if (systemUpdated) {
        setValue("name",systemUpdated.name);
        setValue("type",{
          value: systemUpdated.type._id,
          label: systemUpdated.type.type,
        });
        setValue("classe",{
          value: systemUpdated.class._id,
          label: systemUpdated.class.libelle,
        });
        setValue("deployType",{
          value: systemUpdated.deployType,
          label: systemUpdated.deployType,
        });
        setValue("category",{
          value: systemUpdated.category._id,
          label: systemUpdated.category.category,
        });
        setValue("description",systemUpdated.deployDesc);
        setValue("fqdn",systemUpdated.fqdn);
        setsystem_Id(systemId);
      }
    }
    setEditModal(!editModal);
  };
  const dispatch = useDispatch();
  const [optionsClass, setoptionsClass] = useState([
    {
      value: "none",
      label: "none",
    },
  ]);

  const [optionsCategory, setoptionsCategory] = useState("");

  const [optionsType, setoptionsType] = useState("");

  const syncOption = async () => {
    const optionsClass = await dispatch(retrieveSysClass(0, 1000, true));
    if (optionsClass)
      setoptionsClass(
        optionsClass.map((value) => ({
          value: value._id,
          label: value.libelle,
        }))
      );

    const optionsCategory = await dispatch(retrieveCategory(0, 1000, true));
    if (optionsCategory)
      setoptionsCategory(
        optionsCategory.map((value) => ({
          value: value._id,
          label: value.category,
        }))
      );

    const optionsType = await dispatch(retrieveType(0, 1000, true));
    if (optionsType)
      setoptionsType(
        optionsType.map((value) => ({
          value: value._id,
          label: value.type,
        }))
      );
  };
  const toggleAdd = async () => {
    const optionsClass = await dispatch(retrieveSysClass(0, 1000, true));
    if (optionsClass)
      setoptionsClass(
        optionsClass.map((value) => ({
          value: value._id,
          label: value.libelle,
        }))
      );

    const optionsCategory = await dispatch(retrieveCategory(0, 1000, true));
    if (optionsCategory)
      setoptionsCategory(
        optionsCategory.map((value) => ({
          value: value._id,
          label: value.category,
        }))
      );

    const optionsType = await dispatch(retrieveType(0, 1000, true));
    if (optionsType)
      setoptionsType(
        optionsType.map((value) => ({
          value: value._id,
          label: value.type,
        }))
      );

    setAddModal(!addModal);
  };

  const handleCreateSystem = async ({ name, type, category, classe, deployType, description, fqdn }) => {
    const newSystem = {
      name: name,
      type: type.value,
      category: category.value,
      customer: customer_ID,
      class: classe.value,
      deployType: deployType.value,
      deployDesc: description,
      fqdn: fqdn,
    };
    if (!description) {
      delete newSystem.deployDesc;
    }
    if (!fqdn) {
      delete newSystem.fqdn;
    }
    const res = await dispatch(createSystem(newSystem));
    if (res.status === 201) {
      reset();
      setAddModal(!addModal);
      if (categoryId && typeId) {
        if (landscape === "TC") {
          const resaddTC = await dispatch(
            RetreiveSystemByCustomerByCatgeroyByTypesTC(
              customer_ID,
              category.value,
              type.value
            )
          );
          if (resaddTC) setAddModal(!addModal);
        } else {
          const resaddCT = dispatch(
            RetreiveSystemByCustomerByCatgeroyByTypesCT(
              customer_ID,
              category.value,
              type.value
            )
          );
          if (resaddCT) {
            setAddModal(!addModal);
            reset();
          }
        }
      }
    }
  };

  const confirmDelete = async (e, systemId) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete the system ?")) {
      const res = await dispatch(deleteSystem(systemId));
      if (res) {
        if (landscape === "TC") {
          dispatch(
            RetreiveSystemByCustomerByCatgeroyByTypesTC(
              customer_ID,
              categoryId,
              typeId
            )
          );
          //console.info(restc);
        } else {
          dispatch(
            RetreiveSystemByCustomerByCatgeroyByTypesCT(
              customer_ID,
              categoryId,
              typeId
            )
          );
          //console.info(resct);
        }
      }
    }
  };
  const handleUpdate = async ({ name, type, category, classe, deployType, description, fqdn }) => {
    const updateSystem = {
      name: name,
      type: type.value,
      category: category.value,
      customer: customer_ID,
      class: classe.value,
      deployType: deployType.value,
      deployDesc: description,
      fqdn: fqdn,
    };
    if (!description) {
      delete updateSystem.deployDesc;
    }
    if (!fqdn) {
      delete updateSystem.fqdn;
    }
    const res = await dispatch(UpdateSystem(system_Id, updateSystem));

    if (res) {
      if (res._id === system_Id) {
        if (landscape === "TC") {
          const resCT = await dispatch(
            RetreiveSystemByCustomerByCatgeroyByTypesTC(
              customer_ID,
              categoryId,
              typeId
            )
          );

          if (resCT) {
            reset();
            setEditModal(!editModal);
            if (refresh) {
              dispatch(RetreiveSystemTypesTC(customer_ID));
              setrefresh(false);
            }
          }
        } else {
          const restTC = await dispatch(
            RetreiveSystemByCustomerByCatgeroyByTypesCT(
              customer_ID,
              categoryId,
              typeId
            )
          );

          if (restTC) {
            setEditModal(!editModal);
            reset();
            if (refresh) {
              dispatch(RetreiveSystemCategoriesCT(customer_ID));
              setrefresh(false);
            }
          }
        }
      }
    }
  };
  if (errors.type) {
    document.querySelector(".customerSelectType > div:nth-child(2)") &&
      document
        .querySelector(".customerSelectType > div:nth-child(2)")
        .classList.add("isInvalidSelect");
  }
  if (errors.category) {
    document.querySelector(".customerSelectCategory > div:nth-child(2)") &&
      document
        .querySelector(".customerSelectCategory > div:nth-child(2)")
        .classList.add("isInvalidSelect");
  }
  if (errors.classe) {
    document.querySelector(".customerSelectClass > div:nth-child(2)") &&
      document
        .querySelector(".customerSelectClass > div:nth-child(2)")
        .classList.add("isInvalidSelect");
  }
  if (errors.deployType) {
    document.querySelector(".customerSelectDeployType > div:nth-child(2)") &&
      document
        .querySelector(".customerSelectDeployType > div:nth-child(2)")
        .classList.add("isInvalidSelect");
  }

  const selectInvalidFeedback = {
    width: "100%",
    marginTop: ".25rem",
    fontSize: "80%",
    color: "#dc3545",
  };
  const closeAddModal = () => {
    setAddModal(!addModal);
    reset();
  };  
  const closeEditModal = () => {
    setEditModal(!editModal);
    reset();
  };

  return (
    <div className="customerSystems">
      <div className="custoSysHeader">
        <Button color="primary" onClick={toggleAdd}>
          Add System
        </Button>
        <div className="custoSysSwitch">
          <ButtonGroup>
            <Button
              outline
              color="info"
              onClick={() => setLandscape("TC")}
              active={landscape === "TC"}
            >
              Types {">"} Categories
            </Button>
            <Button
              outline
              color="info"
              onClick={() => setLandscape("CT")}
              active={landscape === "CT"}
            >
              Categories {">"} Types
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <div
        className={
          "custoSysLandscapes " + classnames({ hideLandscape: selectedSystem })
        }
      >
        {landscape === "TC" ? (
          <LandscapeDefaultTC
            customer_ID={customer_ID}
            avxToggle={avxToggle}
            setSelectedSys={setSelectedSystem}
            setScrollPosition={setScrollPosition}
            toggleEdit={toggleEdit}
            handleOpenEditModal={handleOpenEditModal}
            confirmDelete={confirmDelete}
            typeId={typeId}
            settypeId={settypeId}
            categoryId={categoryId}
            setcategoryId={setcategoryId}
          ></LandscapeDefaultTC>
        ) : (
          <LandscapeAddonCT
            customer_ID={customer_ID}
            avxToggle={avxToggle}
            setSelectedSys={setSelectedSystem}
            setScrollPosition={setScrollPosition}
            toggleEdit={toggleEdit}
            confirmDelete={confirmDelete}
            handleOpenEditModal={handleOpenEditModal}
            typeId={typeId}
            settypeId={settypeId}
            categoryId={categoryId}
            setcategoryId={setcategoryId}
          ></LandscapeAddonCT>
        )}
      </div>

      <div
        className={
          "custoSelectedSystem " +
          classnames({ hideSelectedSystem: selectedSystem === "" })
        }
      >
        {selectedSystem === "" ? (
          "No system selected"
        ) : (
          <CustomerSystemDetails
            selectedSysID={selectedSystem}
            setSelectedSys={setSelectedSystem}
            scrollPosition={scrollPosition}
            customerId={customer_ID}
          ></CustomerSystemDetails>
        )}
      </div>

      <Modal
        isOpen={addModal}
        toggle={toggleAdd}
        className="addSystemModal"
        centered
        backdrop="static"
        keyboard={true}
        fade={false}
        size="lg"
      >
        <Form onSubmit={handleSubmit(handleCreateSystem)}>
          <ModalHeader toggle={toggleAdd}>Add new System</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>
                Name
                <span className="requiredField"> *</span>
              </Label>
              <input
                type="text"
                className={"form-control" + (errors.name ? " is-invalid" : "")}
                placeholder="System name"
                {...register("name")}
              />
              <small className="invalid-feedback">
                {errors.name && <>{errors.name.message}</>}
              </small>
            </FormGroup>
            <Row>
              <Col>
                <FormGroup>
                  <Label>
                    Type
                    <span className="requiredField"> *</span>
                  </Label>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field: { ref, ...rest }, fieldState }) => (
                      <Select
                        className="customerSelectType"
                        placeholder="Select type"
                        options={optionsType}
                        {...rest}
                        {...fieldState}
                      />
                    )}
                  />
                  <small style={selectInvalidFeedback}>
                    {errors.type && <>{errors.type.value.message}</>}
                  </small>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>
                    Category
                    <span className="requiredField"> *</span>
                  </Label>
                  <Controller
                    name="category"
                    control={control}
                    render={({ field: { ref, ...rest }, fieldState }) => (
                      <Select
                        className="customerSelectCategory"
                        placeholder="Select category"
                        options={optionsCategory}
                        {...rest}
                        {...fieldState}
                      />
                    )}
                  />
                  <small style={selectInvalidFeedback}>
                    {errors.category && <>{errors.category.value.message}</>}
                  </small>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>
                    Class
                    <span className="requiredField"> *</span>
                  </Label>
                  <Controller
                    name="classe"
                    control={control}
                    render={({ field: { ref, ...rest }, fieldState }) => (
                      <Select
                        className="customerSelectClass"
                        placeholder="Select class"
                        options={optionsClass}
                        {...rest}
                        {...fieldState}
                      />
                    )}
                  />
                  <small style={selectInvalidFeedback}>
                    {errors.classe && <>{errors.classe.value.message}</>}
                  </small>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>
                    Deployment Type
                    <span className="requiredField"> *</span>
                  </Label>
                  <Controller
                    name="deployType"
                    control={control}
                    render={({ field: { ref, ...rest }, fieldState }) => (
                      <Select
                        className="customerSelectDeployType"
                        placeholder="Select deployment type"
                        options={optionsDeploy}
                        {...rest}
                        {...fieldState}
                      />
                    )}
                  />
                  <small style={selectInvalidFeedback}>
                    {errors.deployType && (
                      <>{errors.deployType.value.message}</>
                    )}
                  </small>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label>Deployment description</Label>
              <textarea
                className={
                  "form-control" + (errors.description ? " is-invalid" : "")
                }
                {...register("description")}
                placeholder="Enter deployment description"
              />
              <small className="invalid-feedback">
                {errors.description && <>{errors.description.message}</>}
              </small>
            </FormGroup>
            <FormGroup>
              <Label>FQDN</Label>
              <input
                type="text"
                {...register("fqdn")}
                className={"form-control" + (errors.fqdn ? " is-invalid" : "")}
                placeholder="Enter FQDN"
              />
              <small className="invalid-feedback">
                {errors.fqdn && <>{errors.fqdn.message}</>}
              </small>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Add System
            </Button>{" "}
            <Button color="secondary" onClick={closeAddModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={editModal}
        // toggle={toggleEdit}
        backdrop="static"
        keyboard={true}
        className="editSystemModal"
        centered
        fade={false}
        size="lg"
      >
        <Form onSubmit={handleSubmit(handleUpdate)}>
          <ModalHeader toggle={toggleEdit}>Update System</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>
                Name
                <span className="requiredField"> *</span>
              </Label>
              <input
                type="text"
                className={"form-control" + (errors.name ? " is-invalid" : "")}
                placeholder="System name"
                {...register("name")}
              />
              <small className="invalid-feedback">
                {errors.name && <>{errors.name.message}</>}
              </small>
            </FormGroup>
            <Row>
              <Col>
                <FormGroup>
                  <Label>
                    Type
                    <span className="requiredField"> *</span>
                  </Label>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field: { ref, ...rest }, fieldState }) => (
                      <Select
                        className="customerSelectType"
                        placeholder="Select type"
                        options={optionsType}
                        {...rest}
                        {...fieldState}
                      />
                    )}
                  />
                  <small style={selectInvalidFeedback}>
                    {errors.type && <>{errors.type.value.message}</>}
                  </small>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>
                    Category
                    <span className="requiredField"> *</span>
                  </Label>
                  <Controller
                    name="category"
                    control={control}
                    render={({ field: { ref, ...rest }, fieldState }) => (
                      <Select
                        className="customerSelectCategory"
                        placeholder="Select category"
                        options={optionsCategory}
                        {...rest}
                        {...fieldState}
                      />
                    )}
                  />
                  <small style={selectInvalidFeedback}>
                    {errors.category && <>{errors.category.value.message}</>}
                  </small>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>
                    Class
                    <span className="requiredField"> *</span>
                  </Label>
                  <Controller
                    name="classe"
                    control={control}
                    render={({ field: { ref, ...rest }, fieldState }) => (
                      <Select
                        className="customerSelectClass"
                        placeholder="Select class"
                        options={optionsClass}
                        {...rest}
                        {...fieldState}
                      />
                    )}
                  />
                  <small style={selectInvalidFeedback}>
                    {errors.classe && <>{errors.classe.value.message}</>}
                  </small>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>
                    Deployment Type
                    <span className="requiredField"> *</span>
                  </Label>
                  <Controller
                    name="deployType"
                    control={control}
                    render={({ field: { ref, ...rest }, fieldState }) => (
                      <Select
                        className="customerSelectDeployType"
                        placeholder="Select deployment type"
                        options={optionsDeploy}
                        {...rest}
                        {...fieldState}
                      />
                    )}
                  />
                  <small style={selectInvalidFeedback}>
                    {errors.deployType && (
                      <>{errors.deployType.value.message}</>
                    )}
                  </small>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label>Deployment description</Label>
              <textarea
                className={
                  "form-control" + (errors.description ? " is-invalid" : "")
                }
                {...register("description")}
                placeholder="Enter deployment description"
              />
              <small className="invalid-feedback">
                {errors.description && <>{errors.description.message}</>}
              </small>
            </FormGroup>
            <FormGroup>
              <Label>FQDN</Label>
              <input
                type="text"
                {...register("fqdn")}
                className={"form-control" + (errors.fqdn ? " is-invalid" : "")}
                placeholder="Enter FQDN"
              />
              <small className="invalid-feedback">
                {errors.fqdn && <>{errors.fqdn.message}</>}
              </small>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Save Changes
            </Button>{" "}
            <Button color="secondary" onClick={closeEditModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default CustomerSystems;
