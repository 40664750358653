import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from "../pages/home/Home.jsx";
import Customer from "../pages/customer/Customer";
import MyOperation from "../pages/itOperations/view/ItOperations";
import TeamLeading from "../pages/teamLead2/TeamLeading";
import UserList from "../pages/userManagement/UserList";
import CustomerDetails from "../pages/customerDetails/CustomerDetails";
import TaskManagement from "../pages/taskManagement2/TaskManagement";
import MyTasks from '../pages/MyTasks/MyTasks';
import AdminPanel from "../pages/adminPanel/AdminPanel";
import UserProfile from "../pages/profile/UserProfile.jsx";

import ProtectedRoute from "./PrivateRoute";
import GlobalReport from "../pages/globalReport/view/GlobalReport.jsx";
import { useEffect, useState } from "react";
import navitem from "./Routes";
import "../assets/css/App.css";
//////! Bootstrap 5 Scoped in SCSS File
// import "./assets/css/bootstrap-scope.scss";
import "../assets/js/scripts";
import { useDispatch, useSelector } from "react-redux";

import Alert from "../layout/Alert";

////* PAGES IMPORT
////* PAGES IMPORT
import Sidebar from "../pages/components/sidebare/Sidebar";
import Topbar from "../pages/components/topbar/Topbar";
// import ShiftMenu from "../pages/components/shiftMenu/ShiftMenu";

import { getAuthorization } from "../actions/auth.action";
import Reporting from "../pages/reporting/Reporting.jsx";

// import TaskTraking from "../pages/taskTracking/AllMonitoringActivitiesDetaills.jsx";
import TaskTraking from "../pages/taskTracking/TaskTracking.jsx";

function AppRouter() {
  const dispatch = useDispatch();
  const authLoading = useSelector((state) => state.authentication.isLoading);
  const { listModules: modules } = useSelector(
    ({ authentication }) => authentication
  );
  const [navList, setNavList] = useState([]);

  useEffect(() => {
    if (!modules) {
      dispatch(getAuthorization());
    } else {
      let filtredPages = [];
      navitem.forEach((page) => {
        if (modules.includes(page.url.replace("/app", ""))) {
          filtredPages.push(page);
        }
      });
      setNavList(filtredPages);
    }
  }, [dispatch, modules]);

  if (authLoading) return null;

  return (
    <>
      <Alert />
      <Router>
        <Topbar />
        <Sidebar navitems={[...navList]} />
        {/* <ShiftMenu /> */}
        <div className="home_content">
          <Switch>
            <Route path="/app/home" exact component={Home} />

            <ProtectedRoute
              exact
              path="/app/customers"
              modules={modules}
              component={Customer}
            />

            <ProtectedRoute
              exact
              path="/app/userManagement"
              modules={modules}
              component={UserList}
            />

            <ProtectedRoute
              exact
              path="/app/teamLeading"
              modules={modules}
              component={TeamLeading}
            />

            <ProtectedRoute
              exact
              path="/app/operationManagement"
              modules={modules}
              component={MyOperation}
            />

            <ProtectedRoute
              exact
              path="/app/taskManagment"
              modules={modules}
              component={TaskManagement}
            />

            <ProtectedRoute
              exact
              path="/app/monitoring"
              modules={modules}
              component={MyTasks}
            />

            <ProtectedRoute
              exact
              path="/app/adminPanel"
              modules={modules}
              component={AdminPanel}
            />

            <ProtectedRoute
              exact
              path="/app/customerDetails/:id"
              modules={modules}
              component={CustomerDetails}
            />

            <ProtectedRoute
              exact
              path="/app/profile"
              modules={modules}
              component={UserProfile}
            />

            <ProtectedRoute
              exact
              path="/app/globalReport"
              modules={modules}
              component={GlobalReport}
            />
            
             <ProtectedRoute
              exact
              path="/app/taskTraking"
              modules={modules}
              component={TaskTraking}
            />

            <ProtectedRoute
              exact
              path="/app/statistics"
              modules={modules}
              component={Reporting}
            />
            
          </Switch>
        </div>
      </Router>
    </>
  );
}

export default AppRouter;
