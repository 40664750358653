
const GET_POST_SUCCESS = "GET_POST_SUCCESS";
const GET_POST_FAILURE = "GET_POST_FAILURE";
const LOAD_POST = "LOAD_POST";

const LIKE_POST_SUCCESS = "LIKE_POST_SUCCESS";
const LIKE_POST_FAILURE = "LIKE_POST_FAILURE";
const DISLIKE_POST_SUCCESS = "DISLIKE_POST_SUCCESS";
const DISLIKE_POST_FAILURE = "DISLIKE_POST_FAILURE";

const PUBLISH_POST_SUCCESS = "PUBLISH_POST_SUCCESS";
const PUBLISH_POST_FAILURE = "PUBLISH_POST_FAILURE";

const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
const DELETE_POST_FAILURE = "DELETE_POST_FAILURE";


const UPDATE_POST_SUCCESS = "UPDATE_POST_SUCCESS";
const UPDATE_POST_FAILURE = "UPDATE_POST_FAILURE";

const BLOCK_POST_SUCCESS = "BLOCK_POST_SUCCESS";
const BLOCK_POST_FAILURE = "BLOCK_POST_FAILURE";

const UNBLOCK_POST_SUCCESS = "UNBLOCK_POST_SUCCESS";
const UNBLOCK_POST_FAILURE = "UNBLOCK_POST_FAILURE";


//********************************************************************* *//
//******************* Comment *************************************************** */
//************************************************************************************* */
const RETREIVE_COMMENT_SUCCESS = "RETREIVE_COMMENT_SUCCESS";
const RETREIVE_COMMENT_FAILURE = "RETREIVE_COMMENT_FAILURE";
const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
const ADD_COMMENT_FAILURE = "ADD_COMMENT_FAILURE";
const LOAD_COMMENT = "LOAD_COMMENT";
const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
const DELETE_COMMENT_FAILURE = "DELETE_COMMENT_FAILURE";
const UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS";
const UPDATE_COMMENT_FAILURE = "UPDATE_COMMENT_FAILURE";
const BLOCK_COMMENT_SUCCESS = "BLOCK_COMMENT_SUCCESS";
const BLOCK_COMMENT_FAILURE = "BLOCK_COMMENT_SUCCESS";
const UNBLOCK_COMMENT_SUCCESS = "UNBLOCK_COMMENT_SUCCESS";
const UNBLOCK_COMMENT_FAILURE = "UNBLOCK_COMMENT_FAILURE";

let feedTypes = {
    GET_POST_SUCCESS,
    GET_POST_FAILURE,
    LOAD_POST,
    PUBLISH_POST_SUCCESS,
    PUBLISH_POST_FAILURE,
    LIKE_POST_SUCCESS,
    LIKE_POST_FAILURE,
    DISLIKE_POST_SUCCESS,
    DISLIKE_POST_FAILURE,
    DELETE_POST_SUCCESS,
    DELETE_POST_FAILURE,
    UPDATE_POST_SUCCESS,
    UPDATE_POST_FAILURE,
    RETREIVE_COMMENT_SUCCESS,
    RETREIVE_COMMENT_FAILURE,
    ADD_COMMENT_SUCCESS,
    ADD_COMMENT_FAILURE,
    DELETE_COMMENT_SUCCESS,
    DELETE_COMMENT_FAILURE,
    UPDATE_COMMENT_SUCCESS,
    UPDATE_COMMENT_FAILURE,
    LOAD_COMMENT,
    BLOCK_POST_SUCCESS,
    BLOCK_POST_FAILURE,
    UNBLOCK_POST_SUCCESS,
    UNBLOCK_POST_FAILURE,
    BLOCK_COMMENT_SUCCESS,
    BLOCK_COMMENT_FAILURE,
    UNBLOCK_COMMENT_SUCCESS,
    UNBLOCK_COMMENT_FAILURE

};
export default feedTypes;
