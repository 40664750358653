import types from "./resultHistoryTypes";

const {
    GET_RESULT_HISTORY_SUCCESS,
    GET_RESULT_HISTORY_FAILURE,
    CLEAR_RESULT_HISTORY,
    RESULT_HISTORY_LOAD
} = types;

const initialState = {
    ResultHistory: null,
    isLoadingResultHistory: false,
    error: null,
};

function resultHistoryReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case RESULT_HISTORY_LOAD:
            return {
                ...state,
                isLoadingResultHistory: true,
            };
        case GET_RESULT_HISTORY_SUCCESS:
            return {
                ...state,
                ResultHistory: payload,
                isLoadingResultHistory: false,
                error: null,
            };
        case GET_RESULT_HISTORY_FAILURE:
            return {
                ...state,
                isLoadingResultHistory: false,
                error: payload,
            };

        case CLEAR_RESULT_HISTORY:
            return {
                ...state,
                ResultHistory: null,
                isLoadingResultHistory: false,
                error: null,
            };
        default:
            return state;
    }
}

export default resultHistoryReducer;
