import React, { useState, useEffect } from "react";
//rct-bs removed //khalil
import "./customerdetails.css";
import ProfileCustomer from "./components/profile-customer/ProfileCustomer";
import LicenseHistory from "./components/license-history/LicenseHistory";
import SlaContracts from "./components/sla-contracts/SlaContracts";
import CustomerSystems from "./components/customer-systems/CustomerSystems";
import ContactCustomer from "./components/contact-customer/ContactCustomer";
// import CustomerSystemsOld from './components/system/CustomerSystems'
import { useDispatch, useSelector } from "react-redux";

import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { retrieveLicence } from "../../actions/licenceAction";
export default function CustomerDetails(props) {
  const [activeTab, setActiveTab] = useState("PR");
  const [customer_ID, setcustomer_ID] = useState("");

  const toggle = (tab) => {
    setcustomer_ID(props.match.params.id);
    if (tab === "LI") {
      setrefreshData(!refreshData);
    }
    if (activeTab !== tab) setActiveTab(tab);
  };

  ///////////////////////////////////////////////////////
  // Handle License History ***************************
  /////////////////////////////////////////////////////
  const dispatch = useDispatch();

  const [refreshData, setrefreshData] = useState(false);
  const Listlicense = useSelector((state) => state.licences.licences);
  //console.log("Listlicense =====> ", Listlicense);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  // Retreive all Licences *********************************

  useEffect(() => {
    if (customer_ID) {
      dispatch(retrieveLicence(customer_ID, page, rowsPerPage));
    }
  }, [dispatch, page, rowsPerPage, customer_ID, refreshData]);

  return (
    <>
      <section className="TitlePage">
        Customer Details
        {/* <div className="Page_Description">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eligendi
          reiciendis veniam veli
        </div> */}
      </section>

      <section className="avxWrpContentPage customerDetailsPage">
        <Nav tabs className="customerDetailsTabsNav">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "PR" })}
              onClick={() => {
                toggle("PR");
              }}
            >
              Profile
            </NavLink>
          </NavItem>


          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "CC" })}
              onClick={() => {
                toggle("CC");
              }}
            >
              Contacts
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "LI" })}
              onClick={() => {
                toggle("LI");
              }}
            >
              Licenses History
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "SL" })}
              onClick={() => {
                toggle("SL");
              }}
            >
              SLA Contracts
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "SY" })}
              onClick={() => {
                toggle("SY");
              }}
            >
              Systems
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent className="customerDetailsTabsContent">
          {
            {
              'PR' :  <TabPane className="customerProfileTab">
                        <ProfileCustomer customer_ID={props.match.params.id} />
                      </TabPane>,

              'CC' :  <TabPane className="customerContactTab">
                        <ContactCustomer customer_ID={props.match.params.id} />
                      </TabPane>,

              'LI' :  <TabPane className="customerLicenseTab">
                        <LicenseHistory
                          customer_ID={props.match.params.id}
                          setRowsPerPage={setRowsPerPage}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          setPage={setPage}
                         // Listlicense={Listlicense.licences.licences}
                          //totalDocs={Listlicense.licences.totalDocs}
                          refreshData={refreshData}
                          loading={Listlicense.loading}
                          setrefreshData={setrefreshData}
                          oneLicence={Listlicense.licence}
                        />
                      </TabPane>,
              'SL' :  <TabPane className="customerSlaTab">
                        <SlaContracts
                          customer_ID={customer_ID}
                          setrefreshData={setrefreshData}
                          refreshData={refreshData}
                        />
                      </TabPane>,
              'SY' :  <TabPane className="customerSysTab">
                        <CustomerSystems customer_ID={props.match.params.id} />
                        {/* <CustomerSystemsOld></CustomerSystemsOld> */}
                      </TabPane>
            }[activeTab]
          }
        </TabContent>
      </section>
    </>
  );
}
