import * as Yup from "yup";
const schema = Yup.object().shape({
  name: Yup.string().required("The Name is required."),
  type: Yup.object().shape({
    label: Yup.string().required(),
    value: Yup.string().required("The Type is required."),
  }),
  category: Yup.object().shape({
    label: Yup.string().required(),
    value: Yup.string().required("The Category is required."),
  }),
  classe: Yup.object().shape({
    label: Yup.string().required(),
    value: Yup.string().required("The Class is required."),
  }),
  deployType: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string().required("The deploy type is required."),
  }),
  description: Yup.string(),
  fqdn: Yup.string(),
});
export default schema;
