import types from "./types";
import feedService from "../services/feedService";

const {
    GET_POST_SUCCESS,
    GET_POST_FAILURE,
    LOAD_POST,
    PUBLISH_POST_SUCCESS,
    PUBLISH_POST_FAILURE,
    LIKE_POST_SUCCESS,
    LIKE_POST_FAILURE,
    DISLIKE_POST_SUCCESS,
    DISLIKE_POST_FAILURE,
    DELETE_POST_SUCCESS,
    DELETE_POST_FAILURE,
    UPDATE_POST_SUCCESS,
    UPDATE_POST_FAILURE,
    BLOCK_POST_SUCCESS,
    BLOCK_POST_FAILURE,
    UNBLOCK_POST_SUCCESS,
    UNBLOCK_POST_FAILURE

} = types.feedTypes;

export const publishPost = (newPost) => async (dispatch) => {
    try {
        const res = await feedService.addNewFeed(newPost);
        dispatch({
            type: PUBLISH_POST_SUCCESS,
            payload: res.data.data
        })
        return res.data.data;
    } catch (err) {
        dispatch({
            type: PUBLISH_POST_FAILURE,
            payload: err
        });
        return null;
    }
}
export const getAllPosts = () => async (dispatch) => {
    try {
        dispatch({
            type: LOAD_POST,
        })
        const res = await feedService.getAllPost();
        dispatch({
            type: GET_POST_SUCCESS,
            payload: res.data.data
        })
        // return res.data.data;
    } catch (err) {
        dispatch({
            type: GET_POST_FAILURE,
            payload: err
        });
        return null;
    }
}
export const like = (userId,postId) => async (dispatch) => {
    try {
        const res = await feedService.putLike(userId,postId);
        // const load = await feedService.getAllPost();

        dispatch({
            type: LIKE_POST_SUCCESS,
            payload: res
        })
        return res.data.data;
    } catch (err) {
        dispatch({
            type: LIKE_POST_FAILURE,
            payload: err
        });
        return err;
    }
}
export const dislike = (userId,postId) => async (dispatch) => {
    try {
        const res = await feedService.putDislike(userId,postId);
        dispatch({
            type: DISLIKE_POST_SUCCESS,
            payload: res
        })
        return res.data.data;
    } catch (err) {
        dispatch({
            type: DISLIKE_POST_FAILURE,
            payload: err
        });
        return err;
    }
}
export const deletePost = (postId) => async (dispatch) => {
    try {
        const res = await feedService.deletePost(postId);
        dispatch({
            type: DELETE_POST_SUCCESS,
            payload: res
        })
        return res.data.data;
    } catch (err) {
        dispatch({
            type: DELETE_POST_FAILURE,
            payload: err
        });
        return err;
    }
}
export const updatePost = (postId,newPost) => async (dispatch) => {
    try {
        const res = await feedService.updatePost(postId,newPost);
        dispatch({
            type: UPDATE_POST_SUCCESS,
            payload: res
        })
        return res.data.data;
    } catch (err) {
        dispatch({
            type: UPDATE_POST_FAILURE,
            payload: err
        });
        return err;
    }
}

export const blockPost = (postId) => async (dispatch) => {
    try {
        const res = await feedService.blockPost(postId);
        dispatch({
            type: BLOCK_POST_SUCCESS,
            payload: res
        })
        return res.data.data;
    } catch (err) {
        dispatch({
            type: BLOCK_POST_FAILURE,
            payload: err
        });
        return err;
    }
}

export const unblockPost = (postId) => async (dispatch) => {
    try {
        const res = await feedService.unblockPost(postId);
        dispatch({
            type: UNBLOCK_POST_SUCCESS,
            payload: res
        })
        return res.data.data;
        
    } catch (err) {
        dispatch({
            type: UNBLOCK_POST_FAILURE,
            payload: err
        });
        return err;
    }
}