const selectViewOptions = [
  {value:'month', label:'Month'},
  {value:'week', label:'Week'},
  {value:'day', label:'Day'}
];

const CalendarCommonOptions = {
  defaultView       : 'month',
  taskView          : false,
  scheduleView      : ['time'],
  disableClick      : true,
  disableDblClick   : true,
  month             : {startDayOfWeek:1, isAlways6Week:false, visibleScheduleCount:4},
  week              : {startDayOfWeek:1},
  useCreationPopup  : false,
  useDetailPopup    : false,
  usageStatistics   : false,
  template          : {
    time: function(schedule) {
      let noTLicon = schedule.title === 'No Team Leader' ? '<i class="fa fa-exclamation-triangle"></i>' : '';
      return  '<div class="customShiftTitle" style="color:'+schedule.color+';background-color:'+schedule.bgColor+'">'
                +noTLicon+schedule.title
              +'</div>';
    },
    allday: function(schedule) {
      let noTLicon = schedule.title === 'No Team Leader' ? '<i class="fa fa-exclamation-triangle"></i>' : '';
      return noTLicon+schedule.title
    },
  } 
}

const shiftNamingStructure = (shiftNamingsTab) => (
  shiftNamingsTab.map(
    (shiftNaming) => ({
      id          : shiftNaming._id,
      name        : shiftNaming.name,
      color       : '#FFFFFF',
      msTheme     : shiftNaming.theme,
      bgColor     : shiftNaming.color,
      borderColor : shiftNaming.color,
      dragBgColor : shiftNaming.color
    })
  )
);

const shiftStructure = (shiftTab) => (
  shiftTab.map(
    (singleSh) => ({
      id          : singleSh.shift._id,
      calendarId  : singleSh.shift.typeId,
      title       : singleSh.teamLeader ? singleSh.teamLeader.firstName+' '+singleSh.teamLeader.lastName : 'No Team Leader',
      category    : 'time',
      start       : singleSh.shift.startDate/*.replace('.000Z', TZoffset)*/,
      end         : singleSh.shift.endDate/*.replace('.000Z', TZoffset)*/,
      attendees   : singleSh.members,
      raw         : {
        shiftName   : singleSh.shift.name,
        teamLeader  : !singleSh.teamLeader ? null : {
          _id : singleSh.teamLeader._id,
          name: singleSh.teamLeader.firstName+' '+singleSh.teamLeader.lastName
        }
      }
    })
  )
);
  

module.exports = {
  selectViewOptions,
  CalendarCommonOptions,
  shiftNamingStructure,
  shiftStructure
};