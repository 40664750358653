import http from "../api";
import { message } from "antd";

const register = (user) => {
  return http.post(`/public/auth/register`, user);
};
const login = async (credintials) =>  {
  let result;
  try {
     result= await http.post(`/public/auth/login`, credintials)
  } catch (error) {
    message.error(capitalize(error.response.data.message));
  }
  return result;
};
const capitalize =(string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
const microsoftLogin = async (credintials) => {
  let result;
  try {
     result= await http.post(`/public/auth/microsoftLogin`, credintials);
  } catch (error) {
    message.error(capitalize(error.response.data.message));
  }
  return result;
};
const getAuthorization = () => {
  return http.get(`/api/auth`,{
    headers: {
      "Content-type": "application/json",
      "x-auth-accessToken": localStorage.getItem('ma-access-token'),
      "x-auth-refreshToken": localStorage.getItem('ma-refresh-token')
    }});
};
const authService = {
 register,
 login,
 microsoftLogin,
 getAuthorization
};
export default authService;
