import http from "../api";

const generateTasks = (
  customerId,
  typeId,
  categoryId,
  systemId,
  memberId,
  timeZone,
  teamLeaderId,
  startDate,
  endDate
) => {
  let queryPath = "/api/task/getTasksByFilter?";
  customerId && (queryPath += "&customerId=" + customerId);
  typeId && (queryPath += "&typeId=" + typeId);
  categoryId && (queryPath += "&categoryId=" + categoryId);
  systemId && (queryPath += "&systemId=" + systemId);
  memberId && (queryPath += "&memberId=" + memberId);
  timeZone && (queryPath += "&timeZone=" + timeZone);
  teamLeaderId && (queryPath += "&teamLeaderId=" + teamLeaderId);
  startDate && (queryPath += "&startDate=" + startDate);
  endDate && (queryPath += "&endDate=" + endDate);
  return http.get(queryPath);
};

const AddManyTasks = (newtasks) => {
  const headers = {
    "Content-type": "application/json",
    "x-auth-accessToken": localStorage.getItem('ma-access-token'),
    "x-auth-refreshToken": localStorage.getItem('ma-refresh-token')
  };

  return http.post("/api/task/addMany", newtasks, { headers });
};

const AddOneTask = (newtask,timeZone) => {
  const headers = {
    "Content-type": "application/json",
    "x-auth-accessToken": localStorage.getItem('ma-access-token'),
    "x-auth-refreshToken": localStorage.getItem('ma-refresh-token')
  };
  
  return http.post(`/api/task/addOne?timeZone=${timeZone}`, newtask , {headers} );
};

const getFiltredTasks = (type, state, timeZone, searchValue) => {
  const headers = {
    "Content-type": "application/json",
    "x-auth-accessToken": localStorage.getItem('ma-access-token'),
    "x-auth-refreshToken": localStorage.getItem('ma-refresh-token')
  };

  if (searchValue) {
    return http.get(
      `/api/task/getFiltredTasks/?type=${type}&state=${state}&timeZone=${timeZone}&searchValue=${searchValue}`,
      { headers }
    );
  } else {
    return http.get(
      `/api/task/getFiltredTasks/?type=${type}&state=${state}&timeZone=${timeZone}`,
      { headers }
    );
  }
};

const getTasksByUserId = (type, state, timeZone, searchValue) => {
  const headers = {
    "Content-type": "application/json",
    "x-auth-accessToken": localStorage.getItem('ma-access-token'),
    "x-auth-refreshToken": localStorage.getItem('ma-refresh-token')
  };

  if (searchValue) {
    return http.get(
      `/api/task/getTAsksByUserId/?type=${type}&state=${state}&timeZone=${timeZone}&searchValue=${searchValue}`,
      { headers }
    );
  } else {
    return http.get(
      `/api/task/getTAsksByUserId/?type=${type}&state=${state}&timeZone=${timeZone}`,
      { headers }
    );
  }
};
const getCreatedTask = (
  customerId,
  typeId,
  categoryId,
  systemId,
  memberId,
  timeZone,
  teamLeaderId,
  startDate,
  endDate
) => {
  let queryPath = "/api/task/getCreatedTasks?";
  customerId && (queryPath += "&customerId=" + customerId);
  typeId && (queryPath += "&typeId=" + typeId);
  categoryId && (queryPath += "&categoryId=" + categoryId);
  systemId && (queryPath += "&systemId=" + systemId);
  memberId && (queryPath += "&memberId=" + memberId);
  timeZone && (queryPath += "&timeZone=" + timeZone);
  teamLeaderId && (queryPath += "&teamLeaderId=" + teamLeaderId);
  startDate && (queryPath += "&startDate=" + startDate);
  endDate && (queryPath += "&endDate=" + endDate);
  return http.get(queryPath);
};



const updateTask = (updatedTasks) => {
  const headers = {
    "Content-type": "application/json",
    "x-auth-accessToken": localStorage.getItem('ma-access-token'),
    "x-auth-refreshToken": localStorage.getItem('ma-refresh-token')
  };

  return http.patch("/api/task/updateAssignee", updatedTasks, {headers} );
};


const getSystemStats = (timeZone,userId) => {
  return http.get(`/api/task/system/getSystemStats?timeZone=${timeZone}&userId=${userId}`);
}; 
const getSystemStatsFilter = (timeZone,userId,customerIDFilter) => {
  return http.get(`/api/task/system/getSystemStats?timeZone=${timeZone}&userId=${userId}&customerIDFilter=${customerIDFilter}`);
}; 

const getTaskById = (id) => {
  return http.get(`/api/task/${id}`);
};

const updateTaskMember = (id, task) => {
  const headers = {
    "Content-type": "application/json",
    "x-auth-accessToken": localStorage.getItem('ma-access-token'),
    "x-auth-refreshToken": localStorage.getItem('ma-refresh-token')
  };

  return http.patch(`/api/task/${id}`, task, { headers } );
};


const validateTask = (id, task) => {
  const headers = {
    "Content-type": "application/json",
    "x-auth-accessToken": localStorage.getItem('ma-access-token'),
    "x-auth-refreshToken": localStorage.getItem('ma-refresh-token')
  };

  return http.put(`/api/task/validateTask/${id}`, task, { headers} );
}


const getTaskWorkFlow = (timeZone) => {
  const headers = {
    "Content-type": "application/json",
    "x-auth-accessToken": localStorage.getItem('ma-access-token'),
    "x-auth-refreshToken": localStorage.getItem('ma-refresh-token')
  };

  return http.get(`/api/task/getworkflowTasks?timeZone=${timeZone}`,
    { headers }
  );
};




const closeTask = (taskId) => {
  const headers = {
    "Content-type": "application/json",
    "x-auth-accessToken": localStorage.getItem('ma-access-token'),
    "x-auth-refreshToken": localStorage.getItem('ma-refresh-token')
  };

  return http.put(`/api/task/closeTask/${taskId}`, {}, {headers} );
};
const closeMultiTask = (ids)=>{
  const headers = {
    "Content-type": "application/json",
    "x-auth-accessToken": localStorage.getItem('ma-access-token'),
    "x-auth-refreshToken": localStorage.getItem('ma-refresh-token')
  };

  return http.put(`/api/task/closeMultipleTask`, {ids}, {headers} );
}



const getAllTasksByCriteria = (page, size, paginate, searchValue, filter) => {
  if (searchValue) {
    return http.post(
      `/api/task/allTasksByCriteria/?page=${page}&size=${size}&paginate=${paginate}&searchValue=${searchValue}`, filter);
  } else {
    return http.post(
      `/api/task/allTasksByCriteria/?page=${page}&size=${size}&paginate=${paginate}`, filter);
  }
};

const getAllTaskActivityLogs = (shiftName, searchValue, filter) => {
  if (searchValue) {
    return http.post(
      `/api/taskactivitylog/?shiftName=${shiftName}&searchValue=${searchValue}`, filter);
  } else {
    return http.post(
      `/api/taskactivitylog/?shiftName=${shiftName}`, filter);
  }
};

const createSyncTaskActivityLog = async (cb) => {
  const headers = {
    "Content-type": "application/json",
    "x-auth-accessToken": localStorage.getItem('ma-access-token'),
    "x-auth-refreshToken": localStorage.getItem('ma-refresh-token')
  };

  const httpReq = await http.patch(`/api/taskactivitylog/sync`, { headers } );
  return httpReq;
};







const taskService = {
  generateTasks,
  AddManyTasks,
  AddOneTask,
  getFiltredTasks,
  getTasksByUserId,
  getCreatedTask,
  updateTask,
  getSystemStats,
  getSystemStatsFilter,
  getTaskById,
  updateTaskMember,
  validateTask,
  getTaskWorkFlow,
  closeTask,
  closeMultiTask,
  getAllTasksByCriteria,
  getAllTaskActivityLogs,
  createSyncTaskActivityLog

};
export default taskService;
