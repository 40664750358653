import types from './types';
import taskService from '../services/taskService';

const {
    LOAD_WORKFLOW_TASK,
    RETRIEVE_WORKFLOW_TASKS_SUCCESS,
    RETRIEVE_WORKFLOW_TASKS_FAILURE,
    WORKFLOW_CLOSE_TASKS_SUCCESS,
    WORKFLOW_CLOSE_TASKS_FAILURE
} = types.taskTypes;

export const getTaskWorkFlow=(timeZone,loader=true) => async (dispatch) => {
  try{
if(loader){
    dispatch({
      type : LOAD_WORKFLOW_TASK,
    });
  }
    const res = await taskService.getTaskWorkFlow(timeZone);
    dispatch({
      type: RETRIEVE_WORKFLOW_TASKS_SUCCESS,
      payload: res.data.data
    });
  }catch(err){
    dispatch({
      type: RETRIEVE_WORKFLOW_TASKS_FAILURE,
      payload: err
    });
  }
}

// export const resetFormSchema = () => async (dispatch) => {
//   try{
//     dispatch({
//       type: RESET_TASK_FORM,
//     });
//   }catch(err){
//     console.log('Error during form schema reset:',err );
//   }
// }

export const closeTask= (taskId) => async (dispatch) => {
  try {
    const res = await taskService.closeTask(taskId);
    dispatch({
      type: WORKFLOW_CLOSE_TASKS_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: WORKFLOW_CLOSE_TASKS_FAILURE,
      payload: err?.response?.data?.message,
    });
  }
};