import types from "../actions/types";
const {
    GET_ACTIVE_USER_STAT_SUCCESS,
    GET_ACTIVE_USER_STAT_FAILURE,
    ACTIVE_USER_LOAD,
    CLEAR_STORE_STAT,
    GET_AV_RESPONSE_TIME_STAT_SUCCESS,
    GET_AV_RESPONSE_TIME_STAT_FAILURE,
    AV_RESPONSE_TIME_LOAD,
    CLEAR_STORE_AV_RESPONSE_TIME,
    GET_DBGROWTH_STAT_SUCCESS,
    GET_DBGROWTH_STAT_FAILURE,
    DBGROWTH_LOAD,
    CLEAR_STORE_DBGROWTH,
    RESULT_HISTORY_LOAD,
} = types.statisticTypes;
const initialState = {
    ActiveUserStat: null,
    isLoadingActiveUser: false,
    AvResponseTimeStat: null,
    isLoadingAvResponseTime: false,
    DbGrowthStat: null,
    isLoadingDbGrowth: false,
    error: null,
    isLoadingResultHistory: false,
    ResultHistory: null
};

function statisticReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case ACTIVE_USER_LOAD:
            return {
                ...state,
                isLoadingActiveUser: true,
            };
        case GET_ACTIVE_USER_STAT_SUCCESS:
            return {
                ...state,
                ActiveUserStat: payload,
                isLoadingActiveUser: false,
                error: null,
            };
        case GET_ACTIVE_USER_STAT_FAILURE:
            return {
                ...state,
                isLoadingActiveUser: false,
                error: payload,
            };
        case CLEAR_STORE_STAT:
            return {
                ActiveUserStat: null,
                isLoadingActiveUser: false,
                AvResponseTimeStat: null,
                isLoadingAvResponseTime: false,
                DbGrowthStat: null,
                isLoadingDbGrowth: false,
                error: null,
        }
            case AV_RESPONSE_TIME_LOAD:
                return {
                    ...state,
                    isLoadingAvResponseTime: true,
                };
            case GET_AV_RESPONSE_TIME_STAT_SUCCESS:
                return {
                    ...state,
                    AvResponseTimeStat: payload,
                    isLoadingAvResponseTime: false,
                    error: null,
                };
            case GET_AV_RESPONSE_TIME_STAT_FAILURE:
                return {
                    ...state,
                    isLoadingAvResponseTime: false,
                    error: payload,
                };
            case CLEAR_STORE_AV_RESPONSE_TIME:
                return {
                    AvResponseTimeStat: null,
                    isLoadingAvResponseTime: false,
                }
            case DBGROWTH_LOAD:
                    return {
                        ...state,
                        isLoadingDbGrowth: true,
                    };
            case GET_DBGROWTH_STAT_SUCCESS:
                    return {
                        ...state,
                        DbGrowthStat: payload,
                        isLoadingDbGrowth: false,
                        error: null,
                    };
            case GET_DBGROWTH_STAT_FAILURE:
                    return {
                        ...state,
                        isLoadingDbGrowth: false,
                        error: payload,
                    };
            case RESULT_HISTORY_LOAD:
                    return {
                        ...state,
                        isLoadingResultHistory: true,
                    };

            case CLEAR_STORE_DBGROWTH:
                    return {
                        DbGrowthStat: null,
                        isLoadingDbGrowth: false,
                    }
        default:
            return state;
    }
}

export default statisticReducer;
