import React, { useEffect, useRef } from "react";
import { Row, Col,  } from "reactstrap";
import { useSelector } from "react-redux";
import "./Notification.css";
import moment from 'moment';

const Notification = ({ setOpen, dropdownIconRef }) => {
//click outside to close Notif dropDown
const notificationResult = useSelector((state) => state.notificationReducer.allNotif);
// console.log("notificationResult ------> ",notificationResult.Result)
// const notificationLoading = useSelector((state) => state.notificationReducer.allNotifLoading);
  let dropdownRef = useRef();
  // const [notifList, setNotifList] = useState([])
  useEffect(() => {
    let handler = (event) => {
      if (!dropdownRef.current?.contains(event.target)&& !dropdownIconRef.current?.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   if(!notificationLoading && notificationResult.length>0){
  //     setNotifList(notificationResult.Result.map(item=>({
  //       title: item.title,
  //       description: item.description,
  //       time: moment(item.createdDate).fromNow()
  //     })))
  //   }
  // }, [notificationLoading, notificationResult]);
    return (
      <div className="notifDropdown" ref={dropdownRef}>
        <div className="notification_title">Notification Center</div>
        <hr></hr>
        {notificationResult?.Result?.map((notif,index)=>{
          return(
           
            
            <Row key={notif._id} className="menu-item">
           
            <Col xs="7">
            <div className="icon-button">{notif?.title}</div>
            <div className="description-Notif">{notif?.description}</div>
            </Col>
            <Col xs="5">
            <div className="icon-right">{moment(notif?.createdDate).format("DD-MM-YYYY - LT")}</div>
            </Col>
           
            </Row>
           
         
          )
        })}
      </div>
    );
};

export default Notification;
