import http from "../api";

const createHost = (host) => {
  return http.post(`/api/host`, host);
};

const getAllHost = (system_Id,searchValue) => {
 return searchValue ? http.get(`/api/host/getBySystem/${system_Id}?searchValue=${searchValue}`) : http.get(`/api/host/getBySystem/${system_Id}`);
  
};

const deleteHost = (id) => {
  return http.patch(`/api/host/delete/${id}`);
};

const getOneHost = (id) => {
  return http.get(`/api/host/${id}`);
};

const updateHost = (id, host) => {
  return http.patch(`/api/host/update/${id}`, host);
};

const hostService = {
  createHost,
  getAllHost,
  deleteHost,
  getOneHost,
  updateHost,
};

export default hostService;
