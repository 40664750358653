import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import {
  Row,
  Col,
  Table,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Label,
  Spinner,
} from "reactstrap";
import { TablePagination } from "@material-ui/core";
import { BsTrash, BsPencil } from "react-icons/bs";
import { DatePicker } from "antd";
import { processTableHeight } from "../../../../assets/js/CoreJs";
import "./LicenseHistory.css";
import {
  createLicence,
  deleteLicence,
  RetreiveOneLicence,
  updateLicence,
  retrieveLicence,
  clearLicences,
  clearOneLicence
} from "../../../../actions/licenceAction";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./LicenseHistoryValidation";

const { RangePicker } = DatePicker;

const LicenseHistory = function (props) {
  const {
    customer_ID,
    // Listlicense,
    // totalDocs,
    // page,
    // setPage,
    // rowsPerPage,
    // setRowsPerPage,
    // refreshData,
    // setrefreshData,
  } = props;

  const dispatch = useDispatch();
  const [addModal, setAddModal] = useState(false);
  const [licence_Id, setlicence_Id] = useState("");
  //const [searchTerm, setSearchTerm] = useState("");

  const toggleAdd = () => {
    setAddModal(!addModal);
    reset();
  };
  const [editModal, setEditModal] = useState(false);
  const [refrechData, setrefrechData] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const totalDocs = useSelector((state) => state.licences.licences.totalDocs);
  //console.log("totalDocs ===> ", totalDocs);
  const licences = useSelector((state) => state.licences.licences.docs);
  // console.log("Liste licences ===> ", licences);
  // console.log("customer_ID -----> :", customer_ID);

  const isloading = useSelector((state) => state.licences.isLoading);
  const error = useSelector((state) => state.licences.listError);

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (customer_ID) {
     
      dispatch(retrieveLicence(customer_ID, page, rowsPerPage));
    }
    
  }, [dispatch, page, rowsPerPage, customer_ID, refrechData]);

  useEffect(() => {
    return () => {
      dispatch(clearLicences());
      dispatch(clearOneLicence());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const toggleEdit = async (e, id) => {
    e.preventDefault();
    if (id) {
      const res = await dispatch(RetreiveOneLicence(id));
      if (res) {
        setlicence_Id(id);
        setValue("range", [
          moment.utc(res.startDate, dateFormat),
          moment.utc(res.endDate, dateFormat),
        ]);
        // setstartDate(res.startDate);
        // setendDate(res.endDate);
      }
    }else{
      console.log("dispatch ClearLicence");
    }
    setlicence_Id(id);
    setEditModal(!editModal);
  };

 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setrefrechData(!refrechData);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setrefrechData(!refrechData);
  };

  const confirmDelete = (id) => {
    if (window.confirm("Are you sure you want to delete the license ?")) {
      dispatch(deleteLicence(id));
      setrefrechData(!refrechData);
    }
  };
  // const [startDate, setstartDate] = useState("");
  // const [endDate, setendDate] = useState("");

  // function handleEndStartDate(e) {
  //   if (e) {
  //     for (let i = 0; i < e.length; i++) {
  //       if (i === 0) setstartDate(moment(e[i]._d).format("YYYY-MM-DD"));
  //       if (i === 1) setendDate(moment(e[i]._d).format("YYYY-MM-DD"));
  //     }
  //   }
  // }

  const handleCreateLicence = async ({ range }) => {
    let res = await dispatch(
      createLicence(
        customer_ID,
        moment.utc(range[0], dateFormat),
        moment.utc(range[1], dateFormat)
      )
    );
    if (res) {
      setrefrechData(!refrechData);
      setAddModal(!addModal);
    }
  };
  const handleUpdateLicence = async ({ range }) => {
    const data = {
      startDate: moment.utc(range[0], dateFormat),
      endDate: moment.utc(range[1], dateFormat),
    };
    let res = await dispatch(updateLicence(licence_Id, data));
    if (res) {
      setrefrechData(!refrechData);
      setEditModal(!editModal);
    }
  };
  const dateFormat = "YYYY/MM/DD";

  const applyTabHeight = () => {
    processTableHeight(
      [
        "TitlePage",
        "customerDetailsTabsNav",
        "sysDetailHeader",
        "customerSystemDetailsTabsNav",
        "liceHeader",
        "lisensePagination",
      ],
      "customerLisensesTCONT",
      15
    );
  };
  useEffect(() => {
    applyTabHeight();
    window.addEventListener("resize", applyTabHeight);

    //return fn must be last in useEffect
    return () => {
      window.removeEventListener("resize", applyTabHeight);
    };
  }, []);

  const selectInvalidFeedback = {
    width: "100%",
    marginTop: ".25rem",
    fontSize: "80%",
    color: "#dc3545",
  };
  return (
    <div className="customerLicenseList">
      <Row className="liceHeader">
        <Col xs="2">
          <Button color="primary" onClick={toggleAdd}>
            Add License
          </Button>
        </Col>
        <Col xs={{ size: 4, offset: 6 }}>
          <Input
            type="search"
            className="form-control"
            placeholder="Search"
            //onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Col>
      </Row>
      <div className="liceContent">
        <div id="customerLisensesTCONT" className="avxTableContainer scrollTb">
          <Table className="licensesTable avxBsTable twoActs" striped>
            <thead>
              <tr>
                <th>Key</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>
                  <span>
                    <BsPencil></BsPencil>
                  </span>
                </th>
                <th>
                  <span>
                    <BsTrash></BsTrash>
                  </span>
                </th>
              </tr>
            </thead>
           

            {isloading === false ? (
              <tbody>
                {licences?.length > 0 && error === null ? (
                  <>
                    {licences.map((lc) => (
                      <tr key={lc._id}>
                        <td>{lc._id}</td>
                        <td>{moment(lc.startDate).format("YYYY/MM/DD")}</td>
                        <td>{moment(lc.endDate).format("YYYY/MM/DD")}</td>
                        <td>
                          <span
                            className="tbBtn"
                            onClick={(e) => toggleEdit(e, lc._id)}
                          >
                            <BsPencil></BsPencil>
                          </span>
                        </td>
                        <td>
                          <span
                            className="tbBtn"
                            onClick={() => confirmDelete(lc._id)}
                          >
                            <BsTrash></BsTrash>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan="5">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                        }}
                      >
                        <span> No Licence Exist with this Customer </span>
                        {/* <span>{error} </span> */}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="5">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: 100,
                      }}
                    >
                      <Spinner color="dark" />
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
        <TablePagination
          className="lisensePagination"
          rowsPerPageOptions={[10, 20, 50, 100]}
          component="div"
          count={totalDocs ? totalDocs : 0}
          rowsPerPage={rowsPerPage ? rowsPerPage : 0}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>

      <Modal
        isOpen={addModal}
        toggle={toggleAdd}
        className="addLicenseModal"
        centered
        fade={false}
        backdrop="static"
        keyboard={true}
      >
        <Form
          onSubmit={handleSubmit(handleCreateLicence)}
          className="formModalAddCustomer"
        >
          <ModalHeader toggle={toggleAdd}>Add new license</ModalHeader>
          <ModalBody>
            <Label>
              Range
              <span className="requiredField"> *</span>
            </Label>
            <Controller
              name="range"
              control={control}
              render={({ field: { ref, ...rest }, fieldState }) => (
                <RangePicker
                  {...rest}
                  {...fieldState}
                  className={
                    "liceDatepicker " + (errors.range ? " isInvalidRange" : "")
                  }
                />
              )}
            />
            <small style={selectInvalidFeedback}>
              {errors.range && <>{errors.range.message}</>}
            </small>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Add License
            </Button>{" "}
            <Button color="secondary" onClick={toggleAdd}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={editModal}
        toggle={toggleEdit}
        className="editLicenseModal"
        centered
        fade={false}
        backdrop="static"
        keyboard={true}
      >
        <Form
          onSubmit={handleSubmit(handleUpdateLicence)}
          className="formModalAddCustomer"
        >
          <ModalHeader toggle={toggleEdit}>Update license</ModalHeader>
          <ModalBody>
            {/* <RangePicker
            defaultValue={[
              moment(startDate, dateFormat),
              moment(endDate, dateFormat),
            ]}
            onChange={(e) => handleEndStartDate(e)}
            format={dateFormat}
            className="liceDatepicker"
          ></RangePicker> */}
            <Label>
              Range
              <span className="requiredField"> *</span>
            </Label>
            <Controller
              name="range"
              control={control}
              render={({ field: { ref, ...rest }, fieldState }) => (
                <RangePicker
                  {...rest}
                  {...fieldState}
                  // defaultValue={[
                  //   moment(startDate, dateFormat),
                  //   moment(endDate, dateFormat),
                  // ]}
                  format={dateFormat}
                  className={
                    "liceDatepicker " + (errors.range ? " isInvalidRange" : "")
                  }
                />
              )}
            />
            <small style={selectInvalidFeedback}>
              {errors.range && <>{errors.range.message}</>}
            </small>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Save Changes
            </Button>
            <Button color="secondary" onClick={toggleEdit}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  ); //enf of return
};

export default LicenseHistory;
