import types from "../actions/types";

const {
  RETRIEVE_ALL_SHIFT_CALLENDAR_SUCCESS,
  RETRIEVE_ALL_SHIFT_CALLENDAR_FAILURE,
  LOAD_SHIFT_CALLENDAR,
  ASSIGNEE_TEAMLEAD_SUCCESS,
ASSIGNEE_TEAMLEAD_FAILURE,
CLEAR_STORE_CALLENDAR
} = types.shiftCallendarTypes;

const initialState = {
  AllshiftCallendar: [],
  error: null,
  isLoadingCallendar: true,
};

function shiftCallendarReducer(state=initialState,action) {
const {type,payload}=action;

switch (type) {
    case RETRIEVE_ALL_SHIFT_CALLENDAR_SUCCESS:
        return {
         ...state,
         AllshiftCallendar: payload,
         isLoadingCallendar: false
        }
    case RETRIEVE_ALL_SHIFT_CALLENDAR_FAILURE:
      return {
         ...state,
         error: payload,
         isLoadingCallendar: false
    };
    case LOAD_SHIFT_CALLENDAR:
        return {
          ...state,
          isLoadingCallendar: true
        };
        case ASSIGNEE_TEAMLEAD_SUCCESS:
          return {
           ...state,
           isLoadingCallendar: false
          }
      case ASSIGNEE_TEAMLEAD_FAILURE:
        return {
           ...state,
           error: payload,
           isLoadingCallendar: false
      };
  case CLEAR_STORE_CALLENDAR:
        return {
          AllshiftCallendar: [],
          error: null,
          isLoadingCallendar: true,
          //  isLoadingCallendar: false
      };




    default:
        return state;
}
    
}
export default shiftCallendarReducer;
