import * as Yup from "yup";
const schema = Yup.object().shape({
  monitoringAct: Yup.object().shape({
    label: Yup.string().required(),
    value: Yup.string().required("You need to choose an activity."),
  }),
  periodicity : Yup.string().required("You need to choose a periodicity.").nullable(),
  // timeZone : Yup.string().required("You need to choose a timeZone.").nullable(),
  startTime : Yup.object().required("You need to choose a starting from.").nullable(),
  estimation : Yup.object().required("You need to choose an estimation.").nullable(),
});
export default schema;
