import http from "../api";

const createClient = (client) => {
  return http.post(`/api/client`, client);
};

const getAllClient = (system_Id, searchValue) => {
  return searchValue ? http.get(`/api/client/AllBySystemID/${system_Id}?searchValue=${searchValue}`) : http.get(`/api/client/AllBySystemID/${system_Id}`) ;
};

const deleteClient = (id) => {
  return http.patch(`/api/client/delete/${id}`);
};

const getOneClient = (id) => {
  return http.get(`/api/client/${id}`);
};

const updateClient = (id, client) => {
  return http.patch(`/api/client/update/${id}`, client);
};

const clientService = {
  createClient,
  getAllClient,
  deleteClient,
  getOneClient,
  updateClient,
};

export default clientService;
