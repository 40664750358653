import React, { useEffect, useState } from "react";
import { Input, Table, Spinner } from "reactstrap";
import "./TaskPlanification.css";
import { BsTrash, BsPencil,BsClockHistory } from "react-icons/bs";

import { TablePagination } from "@material-ui/core";
import { makeDelay, processTableHeight } from "../../../../assets/js/CoreJs";
import moment from "moment";
import NewTaskPlanification from "./components/PlanNewTask";
// import { generatedTasks } from "./dumy-tasks";
import EditPlanTask from "./components/EditTaskPlan";
import { useSelector, useDispatch } from "react-redux";
import { retrieveAllMTP,deleteMTP,clearMTP } from "../../../../actions/mtpAction";
export default function TaskPlanification() {
  const dispatch = useDispatch();
  const [refreshData, setrefreshData] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchValue, setSearchValue] = useState("");
  const currentTimeZone = useSelector((state) => state.user.timeZone);
  const mtps = useSelector((state) => state.mtpReducer.mtps?.docs);
  // console.log("mtps ===> ", mtps);
  const totalDocs = useSelector((state) => state.mtpReducer.mtps?.totalDocs);
  // console.log("totalDocs ===> ", totalDocs);
  const isLoading = useSelector((state) => state.mtpReducer.isLoading);
  const error = useSelector((state) => state.mtpReducer.listError);

  // const applyTabHeight = () => {
  //     processTableHeight(
  //       ["searchInput"],
  //       "TaskPlanification-Table",
  //       250
  //     );
  //   };

  const applyTabHeight = () => {
    processTableHeight(
      [
        "TitlePage",
        "taskTrackingTabsNav",
        "TaskPlanification-header",
        "TaskPlanificationPaginate",
      ],
      "MyTasksListTableTCONT",
      15
    );
  };

  useEffect(() => {
    applyTabHeight();
    window.addEventListener("resize", applyTabHeight);
    return () => {
      window.removeEventListener("resize", applyTabHeight);
    };
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setrefreshData(!refreshData);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setrefreshData(!refreshData);
  };
  useEffect(() => {
    dispatch(retrieveAllMTP(page+1, rowsPerPage, true, searchValue));
  }, [dispatch, page, rowsPerPage, refreshData, searchValue]);

  const confirmDelete = (id) => {
    if (window.confirm("Are you sure you want to delete the MTP ?")) {
      dispatch(deleteMTP(id));
      setrefreshData(!refreshData);
    }
  };

  const handleSearchQuerry = (e) => {
    makeDelay(() => {
      if (e.target.value.length >= 2) {
        setSearchValue(e.target.value);
        setPage(0);
      } else {
        setSearchValue("");
      }
    }, 750);
  };

  //Clear Data
  useEffect(() => {
    return () => {
      dispatch(clearMTP());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

const [TaskId, setTaskId] = useState()
  return (
    <div className="TaskPlanification">
      <div className="TaskPlanification-header">
        <NewTaskPlanification refreshData={refreshData} setrefreshData={setrefreshData} />
        <Input type="text" placeholder="Search by Task, System, Customer..." onChange={handleSearchQuerry} />
      </div>
      <div id="MyTasksListTableTCONT" className="avxTableContainer scrollTb">
        <Table className="TaskPlanification-Table-Content avxBsTable twoActs" striped>
          <thead>
            <tr>
              <th>Task</th>
              <th>System</th>
              <th>Customer</th>
              <th>start Date</th>
              <th><span><BsPencil></BsPencil></span></th>
              <th><span><BsTrash></BsTrash></span></th>
            </tr>
          </thead>

          {isLoading === false ? (
            <tbody className="forPointer">
              {mtps !== null && error === null ? (
                <>
                  {mtps?.map((mtp) => (
                    <tr key={mtp._id} className={"mtpRow " + (mtp.isCreated ? "disabledC" : "activeC")}>
                      <td>
                      {(() => {
                        switch (mtp.type) {
                          case "Other Monitoring": return <>{mtp.title}{" "} <span className="typeStyle">CM</span></>;
                          case "Other": return <>{mtp.title}{" "} <span className="typeStyle">CO</span></>;
                          default: return mtp.title;
                        }
                      })()}
                      </td>
                      <td>{mtp.systemName ? mtp.systemName : <span>---</span>}</td>
                      <td>{mtp.customerName ? mtp.customerName : <span>---</span>}</td>
                      <td>{moment(mtp.estimatedStart).tz(currentTimeZone).format("YYYY-MM-DD LT")}</td>
                      <td>
                        {mtp.isCreated
                          ? <span className="tbBtnNoClick" ><BsClockHistory></BsClockHistory></span>
                          : <span className="tbBtn" onClick={() => setTaskId(mtp._id)}><BsPencil></BsPencil></span>
                        }
                      </td>
                      <td>
                        {mtp.isCreated
                          ? <span className="tbBtnNoClick"><BsClockHistory></BsClockHistory></span>
                          : <span className="tbBtn" onClick={() => confirmDelete(mtp._id)}><BsTrash></BsTrash></span>
                        }
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan="5">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <span> No Tasks </span>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan="5">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: 100,
                    }}
                  >
                    <Spinner color="dark" />
                  </div>
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>
      <EditPlanTask setTaskId={setTaskId} mtpID={TaskId}
        refreshData={refreshData}
        setrefreshData={setrefreshData}
      />

      <TablePagination
        className="TaskPlanificationPaginate"
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        component="div"
        count={totalDocs ? totalDocs : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
