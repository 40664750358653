import {
  FiBriefcase,
  FiClipboard,
  FiList,
  FiMap,
  FiMonitor,
  FiPieChart,
  FiRefreshCcw,
  FiUser,
  FiActivity
} from "react-icons/fi";
import { RiAdminLine } from "react-icons/ri";

const navitem = [
  { id: 1, name: "Home", url: "/app/home", icon: "bx bx-grid-alt" },
  {
    id: 2,
    name: "Customers",
    url: "/app/customers",
    component: <FiBriefcase style={{ fontSize: "18px" }} />,
  },
  {
    id: 4,
    name: "Users Management",
    url: "/app/userManagement",
    component: <FiUser style={{ fontSize: "18px" }} />,
  },
  {
    id: 5,
    name: "Team Leading",
    url: "/app/teamLeading",

    component: <FiMap style={{ fontSize: "18px" }} />,
  },
  // {
  //   id: 6,
  //   name: "System Report",
  //   url: "/app/systemReport",

  //   component: <FiClipboard style={{ fontSize: "18px" }} />,
  // },
  
  {
    id: 7,
    name: "Statistics",
    url: "/app/statistics",
    component: <FiPieChart style={{ fontSize: "18px" }} />,
  },

  {
    id: 71,
    name: "Operations",
    url: "/app/operationManagement",
    component: <FiRefreshCcw style={{ fontSize: "18px" }} />,
  },

  {
    id: 8,
    name: "Task Tracking",
    url: "/app/taskTraking",
    component: <FiActivity style={{ fontSize: "18px" }} />,
  },
  
  {
    id: 9,
    name: "Task Management",
    url: "/app/taskManagment",
    component: <FiList style={{ fontSize: "18px" }} />,
  },
  {
    id: 10,
    name: "My Tasks",
    url: "/app/monitoring",
    component: <FiMonitor style={{ fontSize: "18px" }} />,
  },
  {
    id: 11,
    name: "Global Report",
    url: "/app/globalReport",
    component: <FiClipboard style={{ fontSize: "18px" }} />,
  },
  {
    id: 12,
    name: "AdminPanel",
    url: "/app/adminPanel",
    component: <RiAdminLine style={{ fontSize: "18px" }} />,
  },
  
];
export default navitem;
