import types from "../actions/types";

const {
  CREATE_SYSTEM_SUCCESS,
  CREATE_SYSTEM_FAILURE,
  LOAD_SYSTEM,
  RETRIEVE_ONE_SYSTEM_SUCCESS,
  RETRIEVE_ONE_SYSTEM_FAILURE,
  UPDATE_SYSTEM_FAILURE,
  UPDATE_SYSTEM_SUCCESS,
  DELETE_SYSTEM_SUCCESS,
  DELETE_SYSTEM_FAILURE,
  GET_CONTACTS_ASSIGNED_TO_SYSTEM_SUCCESS,
  GET_CONTACTS_ASSIGNED_TO_SYSTEM_FAILURE,
  GET_SYSTEMS_INFO_SUCCESS,
  GET_SYSTEMS_INFO_FAILURE,
  LOAD_SYSTEM_INFO,

  LOAD_SYSTEMS_BY_CUSTOMER_ID,
  RETRIEVE_SYSTEMS_BY_CUSTOMER_ID_SUCCESS,
  RETRIEVE_SYSTEMS_BY_CUSTOMER_ID_FAILURE,

  LOAD_ALL_THE_SYSTEMS,
  GET_ALL_THE_SYSTEMS_SUCCESS,
  GET_ALL_THE_SYSTEMS_FAILURE,
  CLEAR_SYSTEMS_INFO

} = types.systemTypes;

const initialState = {
  system: null,
  systems: [],
  customerContactsAssigned: [],
  isLoading: false,
  error: null,
  listError: null,

  systemsInfo: null,
  isLoadingSystemsInfo: false,

  AllSystemsByCustomerId: [],
  isLoadingSystemByCustomerId: false,

  allTheSystems : [],
  isLoadingAllTheSystems : false
};

function userReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_SYSTEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case CREATE_SYSTEM_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case LOAD_SYSTEM:
      return {
        ...state,
        isLoading: true,
      };

    case RETRIEVE_ONE_SYSTEM_SUCCESS:
      return {
        ...state,
        system: payload,
        isLoading: false,
        error: null,
        listError: null,
      };
    case RETRIEVE_ONE_SYSTEM_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        listError: null,
      };

    case GET_CONTACTS_ASSIGNED_TO_SYSTEM_SUCCESS:
      return {
        ...state,
        customerContactsAssigned: payload,
        isLoading: false,
      };
    case GET_CONTACTS_ASSIGNED_TO_SYSTEM_FAILURE:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case UPDATE_SYSTEM_SUCCESS:
      return {
        ...state,
        systems: state.systems,
        isLoading: false,
      };
    case UPDATE_SYSTEM_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case DELETE_SYSTEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case DELETE_SYSTEM_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case GET_SYSTEMS_INFO_SUCCESS:
      return {
        ...state,
        systemsInfo: payload,
        isLoadingSystemsInfo: false,
        error: null,
        listError: null,
      };
    case GET_SYSTEMS_INFO_FAILURE:
      return {
        ...state,
        isLoadingSystemsInfo: false,
        error: payload,
        listError: null,
        };
    
    case LOAD_SYSTEM_INFO:
      return {
        ...state,
        isLoadingSystemsInfo: true,
      };

    case RETRIEVE_SYSTEMS_BY_CUSTOMER_ID_SUCCESS:
      return {
        ...state,
        AllSystemsByCustomerId: payload,
        isLoadingSystemByCustomerId: false,
      };
    case RETRIEVE_SYSTEMS_BY_CUSTOMER_ID_FAILURE:
      return {
        ...state,
        error: payload,
        isLoadingSystemByCustomerId: false,
      };
    case LOAD_SYSTEMS_BY_CUSTOMER_ID:
      return {
        ...state,
        isLoadingSystemByCustomerId: true,
      };

    
      
    case LOAD_ALL_THE_SYSTEMS : return {
      ...state,
      isLoadingAllTheSystems: true,
    };
    
    case GET_ALL_THE_SYSTEMS_SUCCESS : return {
      ...state,
      allTheSystems: payload,
      isLoadingAllTheSystems: false,
    };
    
    case GET_ALL_THE_SYSTEMS_FAILURE : return {
      ...state,
      error: payload,
      isLoadingAllTheSystems: false,
    };

    case CLEAR_SYSTEMS_INFO: return {
      ...state,
      systemsInfo: [],
      isLoadingSystemsInfo: false,
      error: null,
      listError: null
    };

    default: return state;
  }
}

export default userReducer;
