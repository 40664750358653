import React, { useState,useEffect } from "react";
import Select from "../../../shared/select/Select";
import "./TaskManagementMAPmodal.css";
import { TimePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {Row,Col,Input,Spinner,Button,ModalHeader,ModalBody,ModalFooter,FormGroup,Label,Modal,Badge,} from "reactstrap";
import { BulkAddTasks, generateTasks, retreiveCategories, retreiveCustomer, retreiveMembers, retreiveSystems, 
  retreiveTypes, retrieveFiltredTasks, getSystemStatsForHighlight } from "../../../actions/taskAction";
import moment from "moment";
import { BsClockHistory } from "react-icons/bs";

const TaskManagementMAPmodal = ({type,state,currentTimeZone,searchValue}) => {
  const dispatch = useDispatch();
  let [ShowModal, setShowModal] = useState(false);
  let [selectedTask, setSelectedTask] = useState([]);
  let [optionsCustomers, setoptionsCustomers] = useState([])
  let [optionsSystems, setoptionsSystems] = useState([])
  let [customerId, setcustomerId] = useState(null)
  let [typeId, settypeId] = useState(null)
  let [categoryId, setcategoryId] = useState(null)
  let [systemId, setsystemId] = useState(null)
  let [AssigneeId, setAssigneeId] = useState()
  let [optionTypes, setoptionTypes] = useState([])
  let [optionCategories, setoptionCategories] = useState([])
  let [endDate, setendDate] = useState(null)
  let [startDate, setstartDate] = useState(null)
  let customer = useSelector(state => state.taskReducer.AllCustomers.docs)
  let isLoadingCustomer = useSelector(state => state.taskReducer.isLoadingCustomer)
  let systems = useSelector(state => state.taskReducer.AllSystems)
  let isLoadingSystem = useSelector(state => state.taskReducer.isLoadingSystem)
  let AllCategories = useSelector(state => state.taskReducer.AllCategories)
  let isLoadingCategories = useSelector(state => state.taskReducer.isLoadingCategories)
  let AllTypes = useSelector(state => state.taskReducer.AllTypes)
  let isLoadingTypes = useSelector(state => state.taskReducer.isLoadingTypes)
  let members = useSelector(state => state.taskReducer.AllMembers)
  let isLoadingMembers = useSelector(state => state.taskReducer.isLoadingMembers)
  let [optionMembers, setoptionMembers] = useState([])
  let teamLeaderId = useSelector(state => state.user.user?._id)
  let timeZone = useSelector(state => state.user.timeZone)
  let Tasks = useSelector(state => state.taskReducer.AllTasks)
  let isloadingTasks = useSelector(state => state.taskReducer.isloadingTasks)
  let isloadingSystemStatsForHighlight = useSelector(state => state.taskReducer.isloadingTasks)
  const [refreshData, setrefreshData] = useState(false);

  const userId = useSelector((state) => state.user.user?._id);

  const [AllTasks, setAllTasks] = useState([])
  const toggleAdd = () => {
        if (window.confirm("Are you sure you want to leave?")) 
        setShowModal(!ShowModal);
  };

  const handleSelectTask = (data) => {
    const values = [...selectedTask];
    const foundIndex = selectedTask.findIndex(
      (ts) => {
        const mapTitleEquity = data?.map ? (ts?.map === data?.map) : (ts?.title === data?.title);
        return mapTitleEquity && (ts?.estimatedStart === data?.estimatedStart);
      }
    );

    if (foundIndex !== -1) { values.splice(foundIndex , 1); }
    else { values.push(data); }
    setSelectedTask(values);
  };
  
  const handleSelectAllTask = () => {
    const values = [...Tasks]
    if (selectedTask.length < Tasks.length) {
      setSelectedTask(values);
    } else {
      setSelectedTask([]);
    }
  };

  useEffect(() => {
    if(ShowModal===false){
    setSelectedTask([])
    setoptionsCustomers()
    setoptionsSystems()
    setcustomerId()
    settypeId()
    setcategoryId()
    setsystemId()
    setAssigneeId()
    setoptionTypes()
    setoptionCategories()
    setendDate()
    setstartDate()
    setAllTasks([])
    }
  }, [ShowModal])
  
  useEffect(() => {
    dispatch(retreiveCustomer(false, null, null, true))
    dispatch(retreiveSystems())
    teamLeaderId && dispatch(retreiveMembers(teamLeaderId));
  }, [dispatch, teamLeaderId]);

useEffect(() => {
  dispatch(retreiveSystems(customerId?.value,typeId?.value,categoryId?.value,systemId?.value))
}, [dispatch,customerId,typeId,categoryId,systemId])

useEffect(() => {
  if(customerId&&typeId) dispatch(retreiveCategories(customerId?.value,typeId?.value))
}, [dispatch,customerId,typeId])
useEffect(() => {
  customerId&& dispatch(retreiveTypes(customerId?.value))
}, [dispatch,customerId])
function handleToggleModal() {
  setShowModal(!ShowModal)
  setoptionsCustomers(customer?.map((cust)=>({
    value : cust._id,
    label : cust.label
  })))
  setoptionsSystems(systems?.map((sys)=>({
    value : sys._id,
    label : sys.name
  })))
  setoptionMembers(members?.map((mem)=>({
    value:mem?._id,
    label : mem?.firstName + " " + mem?.lastName
  })))
}
useEffect(() => {
 setoptionTypes(AllTypes.map((type)=>({
  value: type._id, 
  label :type.type
 })))
 setoptionCategories(AllCategories.map((cat)=>({
   value: cat._id,
   label: cat.category
 })))
 setoptionsCustomers(customer?.map((cust)=>({
  value : cust._id,
  label : cust.label
})))
setoptionsSystems(systems?.map((sys)=>({
  value : sys._id,
  label : sys.name
})))
}, [AllTypes,AllCategories,customer,systems])
function handleCustomer(e) {
  if(e){
    setcustomerId(e)
  
    if(customerId && e.value !== customerId.value){
      settypeId(null)
      setsystemId(null)
      setcategoryId(null)
      setSelectedTask([]);
      setAllTasks([])
    }
    
  }else{
    setcustomerId(null)
    settypeId(null)
    setsystemId(null)
    setcategoryId(null)
    setSelectedTask([]);
    setAllTasks([])
  }
}
function handleAssignee(e) {

  if(e){
    setAssigneeId(e)

    if(AssigneeId && e.value !== AssigneeId.value) {
      setSelectedTask([])
      setAllTasks([])
    }

  }else{
    setAssigneeId()
    setSelectedTask([])
    setAllTasks([])
  }
}
function handleType(e) {
  if(e){
    settypeId(e)
    
    if(typeId && e.value !== typeId.value){
      setsystemId(null)
      setcategoryId(null)
      setSelectedTask([])
      setAllTasks([])
    }
  }else{
    settypeId(null)
    setsystemId(null)
    setcategoryId(null)
    setSelectedTask([])
    setAllTasks([])
  }
}
function handleCategory(e) {
  if(e){
    setcategoryId(e)

    if(categoryId && e.value !== categoryId.value){
      setsystemId(null)
      setSelectedTask([])
      setAllTasks([])
    }
  }else{
    setcategoryId()
    setsystemId(null)
    setSelectedTask([])
    setAllTasks([])
  }
}

const handleSystem = (e) => {
  if(e){
    setsystemId(e)

    if(systemId && e.value !== systemId.value){
      setSelectedTask([])
      setAllTasks([])
    }
  } else {
    setsystemId()
    setSelectedTask([])
    setAllTasks([])
  }
}

function handleStartDate(e) {
  setSelectedTask([])
  setAllTasks([])

  if(e){
    setstartDate(moment(e?._d).format("YYYY-MM-DDTHH:mm"))
  }else{
    setstartDate()
  }
}
function handleEndDate(e) {
  setSelectedTask([])
  setAllTasks([])
  
  if(e){
    setendDate(moment(e?._d).format("YYYY-MM-DDTHH:mm"))
  }else{
    setendDate()
  }
}
const handleGenerateTasks = ()=>{
  setSelectedTask([])
  setAllTasks([])
  dispatch(generateTasks(customerId?.value,typeId?.value,categoryId?.value,systemId?.value,AssigneeId?.value,timeZone,teamLeaderId,startDate,endDate))
}
const [isloading, setisloading] = useState(false)
useEffect(() => {
  setAllTasks(Tasks)
  setisloading(isloadingTasks)
}, [Tasks,isloadingTasks])

const handleCreateTasks = async (e)=>{
  e.target.disabled = true;
  const newTasks = selectedTask.map((task)=>{
    let taskObject = {
      title:task.title,
      description:task.description,
      type:task.type,
      priority:task.priority ? task.priority :0,
      estimatedStart:task.estimatedStart,
      timeSpent:task.timeSpent,
      state:task.state,
      assignee:task.assignee,
      resultat:task.resultat,
      globalStatus:task.globalStatus
    }
    if(task.map && task.system){
      taskObject = {
        ...taskObject,
        map:task.map,
        system:task.system._id
      }
    }
    if(task._id){
      taskObject = {
        ...taskObject,
        mtpId:task._id
      }
    }
    return taskObject;    
  });

  await dispatch(BulkAddTasks(newTasks));
  await setSelectedTask([]);
  await dispatch(generateTasks(customerId?.value,typeId?.value,categoryId?.value,systemId?.value,AssigneeId?.value,timeZone,teamLeaderId,startDate,endDate, true));
  await dispatch(
    retrieveFiltredTasks(
      JSON.stringify([type]),
      JSON.stringify(state),
      currentTimeZone,
      searchValue
    )
  );
  dispatch(getSystemStatsForHighlight(timeZone, userId));
  setrefreshData(!refreshData);
}

function handleCheckbox(data) {
  for(let i = 0; i < selectedTask.length; i++){
    const mapTitleEquity = data?.map ? (selectedTask[i]?.map === data?.map ) : (selectedTask[i]?.title === data?.title )
    if( mapTitleEquity && (selectedTask[i]?.estimatedStart === data?.estimatedStart) ){
      return true
    }
  }
  return false
}

useEffect(() => {
  const state1 = [
    "Pending",
    "Done",
    "In progress",
    "Canceled",
    "Completed",
  ];
  const tabType=[
    "Monitoring",
    "Other Monitoring",
    "Other"
  ]
  dispatch(
    retrieveFiltredTasks(
      // JSON.stringify(type),
      JSON.stringify(tabType),
      JSON.stringify(state1),
      timeZone,
      //searchValue
    )
  );
   // eslint-disable-next-line react-hooks/exhaustive-deps
}, [dispatch, timeZone, refreshData]);
  return (
    <>
      <Button
        color="primary"
        onClick={() => {
          handleToggleModal();
        }}
      >
        Create Tasks
      </Button>

      <Modal
        centered
        isOpen={ShowModal}
        toggle={toggleAdd}
        backdrop="static"
        keyboard={true}
        size="xl"
        fade={false}
        className="TaskManagementMAPmodal"
      >
        <ModalHeader toggle={toggleAdd}>
          Create Tasks
          <Row className="modalRow">
            <Col xs="4">
              <FormGroup>
                <Select
                  isClearable
                  isLoading={isLoadingCustomer}
                  value={customerId}
                  options={optionsCustomers}
                  placeholder="Customer"
                  onChange={(e) => handleCustomer(e)}
                />
              </FormGroup>
            </Col>
            <Col xs="2">
              <FormGroup>
                <Select
                  isClearable
                  value={typeId}
                  isDisabled={customerId ? false : true}
                  isLoading={isLoadingTypes}
                  options={optionTypes}
                  placeholder="Type"
                  onChange={(e) => handleType(e)}
                />
              </FormGroup>
            </Col>
            <Col xs="3">
              <FormGroup>
                <Select
                  isClearable
                  isDisabled={typeId ? false : true}
                  isLoading={isLoadingCategories}
                  options={optionCategories}
                  placeholder="Category"
                  value={categoryId}
                  onChange={(e) => handleCategory(e)}
                />
              </FormGroup>
            </Col>
            <Col xs="3">
              <FormGroup>
                <Select
                  isClearable
                  options={optionsSystems}
                  isLoading={isLoadingSystem}
                  value={systemId}
                  placeholder="System"
                  onChange={handleSystem}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="modalRow">
            <Col xs="2">
              <FormGroup>
                <TimePicker
                  className="TimePickerWidth"
                  placeholder="Start Time"
                  format="HH:mm"
                  onChange={(e) => handleStartDate(e)}
                />
              </FormGroup>
            </Col>
            <Col xs="2">
              <FormGroup>
                <TimePicker
                  className="TimePickerWidth"
                  placeholder="End Time"
                  format="HH:mm"
                  onChange={(e) => handleEndDate(e)}
                />
              </FormGroup>
            </Col>
            <Col xs="5">
              <FormGroup>
                <Select
                  isClearable
                  placeholder="Assignee"
                  isLoading={isLoadingMembers}
                  value={AssigneeId}
                  options={optionMembers}
                  onChange={(e) => handleAssignee(e)}
                />
              </FormGroup>
            </Col>
            <Col xs="3">
              <FormGroup>
                <Button
                  disabled={
                    (AssigneeId && customerId) || (AssigneeId && systemId)
                      ? false
                      : true
                  }
                  className="generateTasksButton"
                  color="info"
                  outline
                  onClick={handleGenerateTasks}
                >
                  Generate Tasks
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </ModalHeader>

        <ModalBody className="ModalBodyScrollable">
          <div className="selectAllCheckbox">
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  checked={selectedTask?.length === Tasks?.length}
                  onChange={() => handleSelectAllTask()}
                />
                Select All
              </Label>
            </FormGroup>
          </div>

          <div className="TaskManagementList">
            {isloading === false ? (
              <>
                {AllTasks && AllTasks?.length > 0 ? (
                  <>
                    {AllTasks.map((data, index) => {
                      return (
                        <div className={data.isMTP === true ? "isMTP_Tasks" : "tasksColor"} key={index}>
                          <Row
                            className="Card_task"
                            key={index}
                            onClick={(e) => handleSelectTask(data)}
                          >
                            <Col>
                              <FormGroup inline check>
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    checked={handleCheckbox(data)}
                                    onChange={(e) =>
                                      handleSelectTask(data)
                                    }
                                  />
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col><Badge>{data.title}</Badge> </Col>
                            <Col>{data.system?.customer?.label.length > 0 ? data.system.customer.label : "--" }</Col>
                            <Col>{data.system?.name.length > 0  ? data.system.name : "--"}</Col>
                            <Col>{data.description}</Col>
                            <Col>{moment(data.estimatedStart).tz(timeZone).format("LT")}</Col>
                            <Col className="task_mngt_timer">{data.isMTP === true && <BsClockHistory />}</Col>
                           
                          </Row>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <span className="NoTasksAvailable">Tasks to create</span>
                )}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 100,
                }}
              >
                <Spinner color="dark" />
              </div>
            )}
          </div>
        </ModalBody>

        <ModalFooter>
          <Button color="primary" disabled={(selectedTask.length<=0 || isloadingSystemStatsForHighlight) ? true : false} onClick={handleCreateTasks}>Create Tasks</Button>
          <Button color="secondary" onClick={toggleAdd}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default TaskManagementMAPmodal;
