const LOAD_TASK_FORM = 'LOAD_TASK_FORM';
const GET_TASK_FORM_SUCCESS = 'GET_TASK_FORM_SUCCESS';
const GET_TASK_FORM_FAILURE = 'GET_TASK_FORM_FAILURE';
const RESET_TASK_FORM = 'RESET_TASK_FORM';

const taskDynamicFormsTypes = {
  LOAD_TASK_FORM,
  GET_TASK_FORM_SUCCESS,
  GET_TASK_FORM_FAILURE,
  RESET_TASK_FORM
}

export default taskDynamicFormsTypes;