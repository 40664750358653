import React, { useState } from "react";
import "../../adminPanel.css";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import RoleUserAP from "./userAPComponents/RoleUserAP";

function UserAP() {
  const [activeTab, setActiveTab] = useState("RO");
  const toggleRender = (tab) => {
    activeTab !== tab && setActiveTab(tab);
  };

  return (
    <div className="userAP">
      {/* <Row className="adminPanelHeader"></Row> */}

      <div className="userAPBody">
        <Nav tabs className="adminPanelChildTabsNav childTabForUser">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "RO" })}
              onClick={() => {
                toggleRender("RO");
              }}
            >
              Role
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent className="adminPanelTabsContent">
          {
            {
              RO: (
                <TabPane className="roleUserAPTab">
                  <RoleUserAP />
                </TabPane>
              ),
            }[activeTab]
          }
        </TabContent>
      </div>
    </div>
  );
}

export default UserAP;
