import React, {useState, useEffect} from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Table} from 'reactstrap';
import Select from "../../../../../shared/select/Select";
import { Upload, message } from "antd";
import "antd/dist/antd.css";
import { FiUploadCloud } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";

import './ResultFormPreview.css';

import Form from '@rjsf/core';
import classNames from 'classnames';

const ResultFormPreview = ({lpModal, toggleLpModal, formLimits, formSchema, formUISchema, setFormLimits, setFormSchema, setFormUISchema}) => {

  const [formConfig, setFormConfig] = useState(false);

  useEffect(() => {
    formLimits?.hasOwnProperty('min') && formLimits?.hasOwnProperty('max') && typeof(formLimits?.min)==='number' && typeof(formLimits?.max)==='number'
    && formSchema?.type && formSchema?.type==='object' && (formSchema?.properties || formSchema?.oneOf)
    && formUISchema 
    
    && setFormConfig(true);

    setPropertiesObj(formSchema.properties ? formSchema.properties : {...formSchema.oneOf[0].properties, ...formSchema.oneOf[1].properties});
    return () => setFormConfig(false);
  }, [formLimits, formSchema, formUISchema]);

  const [propertiesObj, setPropertiesObj] = useState();
  const [dataSetArray, setDataSetArray] = useState([]);
  const [dataSetMsg, setDataSetMsg] = useState();

  const formLayout = ( {idSchema, properties }) => {
    return (
      <fieldset id={idSchema.$id} className='row'>
        {properties.map(prop => {
          const uiSchema = prop.content.props.uiSchema
          const className = classNames(uiSchema['ui:column'] || 'col-12' )
          return <div key={prop.content.key} className={className}>{prop.content}</div>
        })}
      </fieldset>
    )
  }
  
  const formErrors = (errors) => {
    errors.map((error) => {
      if(error.name==='required') error.message = 'Required';
      else if(error.name==='pattern') error.message = 'Invalid format';
      else if(error.name==='oneOf') error.message = '';
      return error;
    });
    return errors;
  }

  const formSubmit = ({formData}, e) => {
    setDataSetArray([...dataSetArray, formData]);
    setDataSetMsg();
  }

  const deleteFromDataSet = (idx) => setDataSetArray(dataSetArray.filter((line, index) => index!==idx));

  const { Dragger } = Upload;
  const uploadProps = {
    name: "taskAttachements",
    multiple: true,
    onChange(info){
      const { status } = info.file;
      if(status!=='uploading') console.log(info.file, info.fileList);
      if(status==='done') message.success(`${info.file.name} file uploaded successfully.`);
      else if(status==='error') message.error(`${info.file.name} file upload failed.`);
    },
    onDrop(e){ console.log('Dropped files', e.dataTransfer.files); }
  };

  const [resultGolbalStatus, setResultGolbalStatus] = useState();
  const handleResGlobalstatus = (status) => setResultGolbalStatus(status);

  const handleResultSubmit = () => {
    let resultOkay = true;
    const gsSelect = document.querySelector('#taskGlobalStatSelect > div:nth-child(2)');
    if(!resultGolbalStatus){gsSelect.classList.add('noValidSelect'); resultOkay = false;}
    else{ gsSelect.classList.remove('noValidSelect'); }
    if(formLimits.min>0 && dataSetArray.length<formLimits.min){ setDataSetMsg('Some data are missing!'); resultOkay = false; }
    else{ setDataSetMsg(); }

    if(resultOkay){
      message.success('Form is working correctly');
      setDataSetArray([]);
      setResultGolbalStatus();
      toggleLpModal();
    }else{
      return false;
    }
  }

  const resetLivePreview = async () => {
    await setFormLimits(null);
    await setFormSchema(null);
    await setFormUISchema(null);
    await setDataSetMsg();

    toggleLpModal();
  }

  return(
    <Modal isOpen={lpModal} toggle={toggleLpModal} className="resultLivePreviewModal" backdrop="static" keyboard={true} size='xl' centered fade={false} >
      <ModalHeader toggle={toggleLpModal}>Task Result Form Live preview</ModalHeader>
      <ModalBody>
        {!formConfig
          ? (<div className="formDataLoading"><span>Bad form configuration or missing data</span></div>)
          : (<>
              <div className="dataSetContainer">
                <FormGroup><Label>Prepare document</Label></FormGroup>

                {( (formLimits.max===0) || (dataSetArray.length < formLimits.max && formLimits.max>0) ) && (
                  <Form id="taskResultDataSetForm" idPrefix={"dRS"} schema={formSchema} uiSchema={formUISchema} ObjectFieldTemplate={formLayout}
                    transformErrors={formErrors} noHtml5Validate={true} showErrorList={false} onError={()=>null} onSubmit={formSubmit}
                  >
                    <div className="dataSetSubmitContainer">
                      {dataSetMsg && (<p>{dataSetMsg}</p>)}
                      <button className="btn btn-info" type="submit">Add to Data Set</button>
                    </div>
                  </Form>
                )}

                {dataSetArray.length>0 && (
                  <Table className="avxBsTable small oneActs" responsive>
                    <thead>
                      <tr>
                        {Object.entries(propertiesObj).map((prop, indx)=>(<th key={indx}>{prop[1].title}</th>))}
                        <th><span><BsTrash></BsTrash></span></th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      dataSetArray.map((dataLine, idxd)=>(
                        <tr key={idxd}>
                          {Object.entries(propertiesObj).map((prop, indx)=>(<td key={indx}>{dataLine[prop[0]] || '-'}</td>))}
                          <td><span className="tbBtn" onClick={()=>deleteFromDataSet(idxd)}><BsTrash></BsTrash></span></td>
                        </tr>
                      ))
                    }
                    </tbody>
                  </Table> 
                  )}
              </div>

              <div className="globalFormContainer">
                <div className="globalFormInner">
                  <FormGroup className="taskDraggerContainer">
                    <Label>Attach files</Label>
                    <Dragger className="taskAttachDragger" {...uploadProps} >
                      <FiUploadCloud />
                      <p>Click or drag file to this area to upload</p>
                    </Dragger>
                  </FormGroup>
                  <FormGroup>
                    <Select id="taskGlobalStatSelect" placeholder="Global Status*" onChange={handleResGlobalstatus} value={resultGolbalStatus}
                      options = {[
                        {value: "Critical", label: "Critical"},
                        {value: "Warning", label: "Warning"},
                        {value: "Good", label: "Good"}
                      ]}
                    />
                  </FormGroup>
                </div>
              </div>
            </>)}
      </ModalBody>
      <ModalFooter>
        {formConfig && <Button color="primary" onClick={handleResultSubmit}>Simulate Result Submit</Button>}
        <Button color="secondary" onClick={resetLivePreview}>Close</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ResultFormPreview
