import React, {useState/*, useEffect*/} from 'react';
import CalendarTUI from './tl-components/CalendarTUI';
import ShiftsAssignmentReports from './tl-components/ShiftsAssignmentReports';

import TeamleadBulkAssignment from './tl-components/TeamleadBulkAssignment';

const TeamLeading = function(){
  const [displayRange, setDisplayRange] = useState();
  const [calRange, setCalRange] = useState();

  // useEffect(() => {
  //   //displayStart && displayEnd && dispatch( retreiveShiftCallendar(displayStart, displayEnd, true, true, currentTimeZone) );
  //   //console.log('start', displayStart, 'end', displayEnd);
  //   console.log('start', displayRange?.start, 'end', displayRange?.end);
  // //}, [dispatch, displayStart, displayEnd, currentTimeZone]);
  // }, [displayRange]);

  return(
    <>
      <section className="TitlePage teamLeadingPageTitle">Team Leading</section>
      <section className="avxWrpContentPage teamLeadingPage">
        {displayRange && ( <ShiftsAssignmentReports displayRange={displayRange} /> )}
        <TeamleadBulkAssignment calRange={calRange} />
        <CalendarTUI calRange={calRange} setCalRange={setCalRange} setDisplayRange={setDisplayRange} />
      </section>
    </>
  );
}

export default TeamLeading;